import { GithubOutlined, LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Layout, Row } from 'antd'
import Axios from 'axios'
import Cookie from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CLIENT_ID_GITHUB, OTORISASI_API_URL } from '../../../helpers/Constant'
import { routingFlow } from '../../../helpers/RoutingFlow'

const GithubAuth: React.FC = () => {
  const history = useHistory()
  const [info, setInfo] = useState<string | null>()

  const _routingFlow = useCallback(async (user?: any) => {
    const params = new URLSearchParams(window.location.search)
    await routingFlow(history, params.get('state') ? new URLSearchParams(decodeURIComponent(params.get('state') as string)) : null, user)
  }, [history])

  useEffect(() => {
    (async () => {
      if (!window.location.search) {
        return history.push('/auth/login')
      }
      const params = new URLSearchParams(window.location.search)
      if (!params.get('code')) {
        return history.push('/auth/login')
      }

      try {
        const token = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/github/authorize`, {
          code: params.get('code'),
          clientId: CLIENT_ID_GITHUB
        }, { withCredentials: true })
        if (!token.data.auth.need2fa) {
          Cookie.set('authorized', 'true')
        }
        const state = new URLSearchParams(decodeURIComponent(params.get('state') as string))
        if (!state.get('installationId') && !state.get('clientId') && state.get('redirectUrl')) {
          setInfo(`Redirecting to ${state.get('ref')}...`)
        }
        Cookie.set('loggedIn', 'true')
        localStorage.setItem('login', Date.now().toString())
        return _routingFlow({ isEnabled2fa: token.data.auth.need2fa })
      } catch (error) {
        return setInfo('Something error, please try again')
      }
    })()
  }, [history, _routingFlow])

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 6, offset: 9 }} md={{ span: 12, offset: 6 }}>
          <Card title={<><GithubOutlined /> Github Authorization</>}>
            { info ? <p>{info}</p> : <p>Please wait... <LoadingOutlined /></p> }
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default GithubAuth