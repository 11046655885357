import { Version } from 'db'
import { PublicConfiguration } from 'swr/dist/types'
import { Error } from '../../../types'
import useFetch from '../../useFetch'


type Result = {
  revalidateVersions: () => void,
  versions?: Version[],
  fetchingAllVersions: boolean,
  errorFetchAllVersion?: Error
}

export default function useAllVersion(applicationId?: number | string, config?: Partial<PublicConfiguration<any>>): Result {
  const url = applicationId ? `/application/${applicationId}/versions` : null
  const { data, error, isValidating, revalidateFetch } = useFetch<{ versions: Version[] }>(url, {}, { revalidateOnFocus: true, ...config })

  return {
    revalidateVersions: () => revalidateFetch(url),
    versions: data?.versions,
    fetchingAllVersions: isValidating,
    errorFetchAllVersion: error
  }
}
