import { DownOutlined } from '@ant-design/icons'
import { Col, Dropdown, Empty, Input, Menu, MenuItemProps, Row } from 'antd'
import { FC, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { tableStateScenarioTemplates } from '../../../../components/pages/app/templateScenario/editor/recoil/scenario'
import TemplateScenarioItem from '../../../../components/pages/app/templateScenario/prebuiltTemplates/TemplateScenarioItem'
import TemplateScenarioItemSkeleton from '../../../../components/pages/app/templateScenario/prebuiltTemplates/TemplateScenarioItemSkeleton'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import useCreateTemplateScenario from '../../../../hooks/scenario/template/useCreateTemplateScenario'
import usePublicScenarios from '../../../../hooks/scenario/usePublicScenarios'
import PageHeader from '../../../components/PageHeader'
import { StyledAlert, StyledButton, StyledDescription } from '../../../components/StyledComponents'

const PrebuiltTemplates: FC = () => {
  const [loggedInUser] = useLoggedInUser()
  const [tableState, setTableState] = useRecoilState(tableStateScenarioTemplates)
  const { scenarios, fetchingScenarios, scenariosLength } = usePublicScenarios(tableState)
  const { createTemplateScenario, creatingTemplateScenario } = useCreateTemplateScenario()

  useEffect(() => {
    setTableState({
      ...tableState,
      filter: {
        ...tableState.filter,
        published: loggedInUser?.isAdmin ? null : ['1'],
      }
    })
  }, [loggedInUser])

  const onClickCreateScenarioTemplate = () => {
    createTemplateScenario().then((scenario)=> {
      if (scenario?.id) {
        onClickEditScenarioTemplate(scenario.id)
      }
    })
  }

  const onClickEditScenarioTemplate = (id: number) => {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = `/app/template-scenario/editor/${id}`
    a.rel = 'noopener noreferrer'
    a.click()
  }

  const onClickMenuSort: MenuItemProps['onClick'] = ({ key }) => {
    setTableState(prevState => ({
      ...prevState,
      sorter: [{ field: key, order: 'descend' }, { field: 'createdAt', order: 'descend' }]
    }))
  }

  const onSearch = (value: string) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const onLoadMore = () => {
    setTableState(prevState => ({ ...prevState, pagination: { ...prevState.pagination, current: (prevState.pagination?.current || 1) + 1 }  }))
  }

  const nFilter = Object.keys(tableState.filter || {}).filter(key => tableState.filter?.[key]).length

  return (
    <StyledWrapperPrebuiltTemplates>
      <PageHeader
        style={{ padding: '8px 20px' }}
        title="Pre-built Template"
        subTitle={loggedInUser?.isAdmin ? 'Manage and Create Prebuilt Template that are useful to users.' : 'Explore Template that are curated by Otomatis Team.'}
      />
      {
        loggedInUser?.isAdmin ?
          <StyledAlert
            closable
            className="mx-5 mb-4"
            message={<>
              <h3>Important Informations on Pre-built Template:</h3>
            </>}
            description={<>
              <ol className="pl-4">
                <li>Template including Premium Apps will be locked to users with Starter {'&'} Grow price plan.</li>
                <li>Template must use public apps that are accessible to users.</li>
              </ol>
            </>}
            type="info"
            showIcon
          /> :
          null
      }
      <Row gutter={[16, 16]} className="px-5" justify="space-between">
        <Col flex="auto">
          <Input.Search allowClear size="small" placeholder="Search Scenario" onSearch={onSearch} />
        </Col>
        <Col flex={0}>
          <Dropdown
            overlay={(<Menu>
              <Menu.Item onClick={onClickMenuSort} key="createdAt" >Recently Added</Menu.Item>
              <Menu.Item onClick={onClickMenuSort} key="numberOfTemplateUses">Most Used</Menu.Item>
            </Menu>)}
          >
            <StyledButton size="small">
              {tableState.sorter?.[0]?.field === 'createdAt' ? 'Recently Added' : 'Most Used'} <DownOutlined />
            </StyledButton>
          </Dropdown>
        </Col>
        {
          loggedInUser?.isAdmin ?
            <Col>
              <StyledButton size="small" loading={creatingTemplateScenario} onClick={onClickCreateScenarioTemplate} type="primary" className="float-right" >Create New Template</StyledButton>
            </Col> :
            <>
            </>
        }
        {/* <Col span={12}>
          <Row gutter={[16, 16]} justify={loggedInUser?.isAdmin ? 'start' : 'end'}>
            <Col flex={0}>
              <Dropdown
                overlay={(<Menu>
                  <Menu.Item onClick={onClickMenuSort} key="createdAt" >Recently Added</Menu.Item>
                  <Menu.Item onClick={onClickMenuSort} key="numberOfTemplateUses">Most Used</Menu.Item>
                </Menu>)}
              >
                <StyledButton size="small">
                  {tableState.sorter?.[1]?.field === 'createdAt' ? 'Recently Added' : 'Most Used'} <DownOutlined />
                </StyledButton>
              </Dropdown>
            </Col>
            <Col flex={0}>
              <Dropdown
                overlay={(<Menu>
                  <Menu.Item>All Roles</Menu.Item>
                </Menu>)}
              >
                <StyledButton>
              All Roles <DownOutlined />
                </StyledButton>
              </Dropdown>
            </Col>
          </Row>
        </Col> */}
      </Row>
      <StyledWrapperItems className="p-5">
        {
          fetchingScenarios && (!scenarios || scenarios?.length === 0) ?
            [1, 2, 3].map((key) => <TemplateScenarioItemSkeleton key={key} />) :
            null
        }
        {
          !fetchingScenarios && scenarios?.length === 0 ?
            <Empty
              className="pt-4"
              image={nFilter > 0 ? '/illustrations/notfound.svg' : '/illustrations/start.svg'}
              description={nFilter > 0 ?
                <>
                  <h5>Sorry, no result found</h5>
                  <StyledDescription>What you searched was unfortunately not found or doesn't exist</StyledDescription>
                </> :
                <>
                  <h5>There isn't any scenario here yet</h5>
                  <StyledDescription>Start creating template scenario with easy steps in just a few minutes</StyledDescription>
                  <StyledButton type="primary" onClick={onClickCreateScenarioTemplate} >Create Template Scenario</StyledButton>
                </>}
            /> :
            null
        }
        {
          scenarios?.map((scenario, idx) => {
            return (
              <TemplateScenarioItem scenario={scenario} key={idx} />
            )
          })
        }
        {
          !fetchingScenarios && scenarios && scenarios?.length > 0 && scenarios?.length !== scenariosLength ?
            <StyledButton type="primary" ghost loading={fetchingScenarios} onClick={() => onLoadMore()}>Load More...</StyledButton> :
            null
        }
      </StyledWrapperItems>
    </StyledWrapperPrebuiltTemplates>
  )
}

export default PrebuiltTemplates

const StyledWrapperItems = styled.div`
  display: grid;
  overflow-y: auto;
`

const StyledWrapperPrebuiltTemplates = styled.div`
  display: grid;
  overflow-y: hidden;
  grid-template-rows: repeat(4, minmax(auto, max-content));
`