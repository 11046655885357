import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import DataSuggestionContextProvider from '../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useTrigger from '../../../../../../hooks/application/trigger/useTrigger'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { useSharedValueTrigger } from '../components/SharedValueTriggerContextProvider'
import TriggerSteps from '../components/TriggerStep'

const CreateTrigger: FC = () => {
  const [values, setValues] = useSharedValueTrigger()
  const { applicationId } = useParams<{ applicationId: string}>()
  const [version] = useApplicationVersionContext()
  const { trigger } = useTrigger(applicationId, values?.id, version?.id)

  useEffect(() => {
    if (trigger) {
      setValues(trigger)
    }
  }, [trigger])

  return (
    <DataSuggestionContextProvider>
      <TriggerSteps type="create" />
    </DataSuggestionContextProvider>
  )
}

export default CreateTrigger