import { Row } from 'antd'
import { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import useCreateScenarioFromTemplate from '../../../../../hooks/scenario/template/useCreateScenarioFromTemplate'
import useAScenario from '../../../../../hooks/useAScenario'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import Notification from '../../../../../util/Notification'

const EditorFooter: FC = () => {
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const { createScenarioFromTemplate, creatingScenarioFromTemplate } = useCreateScenarioFromTemplate()
  const { push } = useHistory()
  const { fetchingScenario } = useAScenario(scenarioId ? parseInt(scenarioId) : undefined, true)

  const onClickCreateScenarioFromTemplate = () => {
    createScenarioFromTemplate(parseInt(scenarioId)).then((scenario) => {
      if (scenario) {
        push(`/app/scenario/${scenario.id}/edit`)
      }
    })
  }

  const onSaveTemplate = () => {
    if (!fetchingScenario) {
      Notification.success({
        message: 'Template saved'
      })
    }
  }

  return (
    <StyledLayoutFooter justify="center">
      <StyledButton onClick={onClickCreateScenarioFromTemplate} loading={creatingScenarioFromTemplate} type="primary" ghost className="mr-4">Create Scenario using this Template</StyledButton>
      <StyledButton disabled={fetchingScenario} onClick={onSaveTemplate} type="primary">Save Template</StyledButton>
    </StyledLayoutFooter>
  )
}

export default EditorFooter

const StyledLayoutFooter = styled(Row)`
  width: 100%;
  z-index: 2;
  background: #FFF;
  padding: 16px;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
`