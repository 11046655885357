import { FC } from 'react'
import { useParams } from 'react-router'
import DetailJob from './DetailJob'
import ListJobExecuted from './ListJobExecuted'

const JobExecutedHistory: FC = () => {
  const { id } = useParams<{ id?: string }>()

  return (
    // <StyledJobExecutedHistory id={id}>
    <>
      {
        id ?
          <DetailJob /> :
          <ListJobExecuted />
      }
    </>
    // </StyledJobExecutedHistory>
  )
}

export default JobExecutedHistory

// const StyledJobExecutedHistory = styled.div<{ id?: string }>`
//   margin: ${({ id }) => id ? '16px' : 'unset' };;
//   background: ${({ id }) => id ? '#FFF' : 'unset' };
// `