import { Card, Col, Row, Table, Typography } from 'antd'
import { FC } from 'react'
import { useHitRequests } from 'src/hooks/dataLoader/useHitRequest'
import { StyledButton } from 'src/pages/components/StyledComponents'
import MonacoEditor from 'src/pages/developer/components/form/MonacoEditor'

interface Props {
  url?: string,
  parsedData: { [key: string]: any }[] | undefined
}

const Step4Execute: FC<Props> = ({ url, parsedData }) => {
  const { rowsError, rowsExecuted, rowsSucceed, responses, hitRequests, fetchingRequests } = useHitRequests()

  const onExecuteAllRow = () => {
    if (url && parsedData) {
      hitRequests(url, parsedData)
    }
  }

  if (!url || !parsedData) return null
  return (
    <>
      <StyledButton onClick={onExecuteAllRow} loading={fetchingRequests} >Execute All Rows</StyledButton>
      <br /><br />
      <Card>
        <Row>
          <Col flex={1}>
            <Typography.Title level={5}>Total Rows Executed</Typography.Title>
            <Typography.Text>{(rowsExecuted)}</Typography.Text>
          </Col>
          <Col flex={1}>
            <Typography.Title level={5}>Success</Typography.Title>
            <Typography.Text>{rowsSucceed}</Typography.Text>
          </Col>
          <Col flex={1}>
            <Typography.Title level={5}>Error</Typography.Title>
            <Typography.Text>{rowsError}</Typography.Text>
          </Col>
        </Row>
      </Card>
      <br />
      <Card>
        <Table
          columns={[
            { title: 'Status', width: '50px', dataIndex: 'status' },
            { title: 'Reason', width: '400px', dataIndex: 'reason', render: (_, response) => response?.data?.error ? JSON.stringify(response?.data?.error) : null  },
            { title: 'API Result', width: '400px', dataIndex: 'data', render: (_data, response) => response ? <MonacoEditor defaultLanguage="json" height="100px" maxHeight="360px" options={{ readOnly: true }} value={JSON.stringify(response, null, 2)} /> : null },
            { title: 'Data Row', width: '400px', render: (_, response) => response?.config?.data ? <MonacoEditor defaultLanguage="json" height="100px" maxHeight="360px" options={{ readOnly: true }} value={JSON.stringify(JSON.parse(response?.config?.data), null, 2)} /> : null }
          ]}
          dataSource={responses}
          scroll={{ x: true }}
        />
      </Card>
    </>
  )
}

export default Step4Execute