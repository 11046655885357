import { CrownFilled } from '@ant-design/icons'
import { Card, Col, Row, Select, Space, Tooltip } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useAllAction from '../../../../../hooks/application/action/useAllAction'
import usePublicApplications from '../../../../../hooks/application/main/usePublicApplications'
import useStatusPayment from '../../../../../hooks/payment/useStatusPayment'
import useCreateAScenarioState from '../../../../../hooks/scenario/state/useCreateAScenarioState'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import { StyledBoxIcon, StyledButton } from '../../../../../pages/components/StyledComponents'
import CustomForm from '../../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../../pages/developer/components/form/FormItem'
import { orange, primary65 } from '../../../../../util/colors'
import { sortApplications, sortTriggerActions } from '../../../../../util/functions'
import { StyledBadgeOtomatis, StyledBadgePremium, StyledBadgePrivate } from '../create/StepOne/SearchApplication'
import { ChildStateType } from './StateTestDataContext'

interface ChooseActionAppAndEventProps {
  childrenStateIds?: number[] | null,
  parentStateId?: number,
  applicationId?: number,
  stateConditionId?: number, // untuk stateConditionId
  isLastState?: boolean,
  nextStateIds?: ChildStateType[],
  onCancel: () => void,
  onSuccessCreateNewState: () => void,
  order: number,
  scenarioId: number
}

const ChooseActionAppAndEvent: FC<ChooseActionAppAndEventProps> = ({ parentStateId, childrenStateIds, applicationId, stateConditionId, nextStateIds, order, scenarioId, onSuccessCreateNewState, onCancel }) => {
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const { publicApplications, fetchingPublicApplications } = usePublicApplications({ hasAction: 'true', expands: 'action' })
  const [selectedApplicationId, setSelectedApplicationId] = useState<number>()
  const { actions, fetchingAllActions } = useAllAction(selectedApplicationId)
  const [form] = useForm()
  const { createAScenarioState, creatingAScenarioState, createdAScenarioState } = useCreateAScenarioState()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const [updatingOrderStates, setUpdatingOrderStates] = useState(false)
  const history = useHistory()
  const { plan } = useStatusPayment()

  const onValuesChange = (value: any) => {
    const keys = Object.keys(value)
    if (keys.includes('applicationId')) {
      setSelectedApplicationId(value.applicationId)
      form.setFieldsValue({
        actionId: null
      })
    }
  }

  useEffect(() => {
    if (publicApplications && !form.getFieldValue('applicationId') && applicationId) {
      form.setFieldsValue({
        applicationId: applicationId
      })
      setSelectedApplicationId(applicationId)
    }
  }, [publicApplications])

  const onFinish = async (values: any) => {
    const newStateId = await createAScenarioState(scenarioId, {
      name: actions?.find(action => action.id === values.actionId)?.name,
      parentId: parentStateId,
      childrenIds: childrenStateIds,
      stateConditionId: stateConditionId,
      order: order + 1, // increment new state order
      actionId: values.actionId
    }).then(({ data }) => {
      setUpdatingOrderStates(true)
      return data.state.id
    })
    if (parentStateId && newStateId) {
      updateAScenarioState(scenarioId, parentStateId, {
        childrenIds: [newStateId]
      }).then(() => {
        revalidateAScenarioState(scenarioId, parentStateId)
      })
    }
    if (nextStateIds && newStateId) {
      await Promise.all(
        nextStateIds.map(async (state) =>
          await updateAScenarioState(scenarioId, state.id, {
            parentId: childrenStateIds?.includes(state.id) ? newStateId : undefined,
            order: state.order + 1,
            conditionGroupOrder: state.conditionGroupOrder ? state.conditionGroupOrder + 1 : state.conditionGroupOrder
          }).then(({ data }) => data)
        )
      ).then(() => {
        setUpdatingOrderStates(false)
      })
    }
  }

  useEffect(() => {
    // new state created
    if (createdAScenarioState && !creatingAScenarioState && !updatingOrderStates) {
      // console.log('new state: ', createdAScenarioState)
      revalidateAScenario(scenarioId) // revalidate the scenario to fetch the new state
      if (nextStateIds) {
        // revalidate all next states
        for (const state of nextStateIds) {
          revalidateAScenarioState(scenarioId, state.id)
        }
      }
      history.replace(`/app/scenario/${scenarioId}/edit/${createdAScenarioState.id}`)
      onSuccessCreateNewState()
    }
  }, [updatingOrderStates])

  return (
    <StyledCardCreateStateActionForm>
      <Row>
        <Col flex="auto">
          <CustomForm onValuesChange={onValuesChange} onFinish={onFinish} form={form}>
            <Row justify="space-between" align="middle" gutter={[16, 16]} >
              <Col>
                <h4 style={{ color: primary65 }}>Add Action</h4>
              </Col>
              <Col>
                <Space>
                  <StyledButton htmlType="button" onClick={onCancel}>Cancel</StyledButton>
                  <StyledButton loading={creatingAScenarioState || updatingOrderStates} type="primary" htmlType="submit">Save</StyledButton>
                </Space>
              </Col>
            </Row>
            <FormItem label="App" name="applicationId" notUseInfoRequired rules={[{ required: true, message: 'App is required' }]}>
              <Select
                filterOption={(input, option) =>
                  option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select App"
                loading={fetchingPublicApplications}
                disabled={creatingAScenarioState || updatingOrderStates}
                showSearch
              >
                {
                  publicApplications?.filter(app => app.actions && app.actions.length > 0 && app.actions.filter(action => action.type === 'path').length === 0)
                    .sort(sortApplications)
                    .map(application =>{
                      const { id, logoUrl, name, isPremiumApp } = application
                      return (
                        <Select.Option key={id} value={id} title={name} disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                          <Tooltip placement="topLeft" title={!plan?.isUsingPremiumApps && isPremiumApp ? `Upgrade to Professional plan to use ${name}` : ''}>
                            <Space>
                              <CustomSmallStyledBoxIcon disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                                <img src={logoUrl || '/placeholder.png'} />
                              </CustomSmallStyledBoxIcon>
                              {name}
                              {
                                !application.public ?
                                  <StyledBadgePrivate ellipsis title={'Private'}>Private</StyledBadgePrivate> :
                                  application.isPremiumApp ?
                                    <StyledBadgePremium ellipsis><CrownFilled style={{ color: orange[4], padding: 0, margin: 0 }} /> Premium</StyledBadgePremium> :
                                    application.isNativeApp ?
                                      <StyledBadgeOtomatis>Otomatis</StyledBadgeOtomatis> :
                                      null
                              }
                            </Space>
                          </Tooltip>
                        </Select.Option>
                      )
                    })
                }
              </Select>
            </FormItem>
            <FormItem label="Event" name="actionId" notUseInfoRequired rules={[{ required: true, message: 'Event is required' }]}>
              <Select
                disabled={!selectedApplicationId || creatingAScenarioState || updatingOrderStates}
                filterOption={(input, option) =>
                  option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select Event"
                loading={fetchingAllActions}
                showSearch
              >
                {
                  actions?.sort(sortTriggerActions).map(action =>{
                    const { id, name } = action
                    return (
                      <Select.Option key={id} value={id} title={name}>
                        {name}
                      </Select.Option>
                    )
                  })
                }
              </Select>
            </FormItem>
          </CustomForm>
        </Col>
      </Row>
    </StyledCardCreateStateActionForm>
  )
}

const StyledCardCreateStateActionForm = styled(Card)`
  width: 100%;
  margin-top: 12px;
`
const CustomSmallStyledBoxIcon = styled(StyledBoxIcon)`
  width: 20px;
  height: 20px;
  img {
    width: 17px;
    height: 17px;
  }
`

export default ChooseActionAppAndEvent