import { EllipsisOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Dropdown, DropDownProps, Menu, MenuItemProps } from 'antd'
import { FC, Key } from 'react'
import styled from 'styled-components'

interface Props extends DropDownProps {
  listMenu?: (MenuItemProps & { key: Key })[],
  buttonProps?: ButtonProps
}

const ButtonActionMore: FC<Props> = ({ listMenu, buttonProps, overlay, ...propsDropdown }) => {
  return (
    <Dropdown
      overlay={
        listMenu ?
          <Menu>
            {
              listMenu?.map(menu => <Menu.Item {...menu} />)
            }
          </Menu> :
          overlay
      }
      trigger={['click']}
      placement='bottomRight'
      {...propsDropdown}
    >
      <ActionsButton onClick={(e) => e.stopPropagation()} type='text' icon={
        <EllipsisOutlined style={{ color: 'rgba(0, 0, 0, .45)', verticalAlign: 'middle', textAlign: 'center' }} />
      } {...buttonProps}/>
    </Dropdown>
  )
}

export default ButtonActionMore

const ActionsButton = styled(Button)`
  border: 0;

  &:hover {
    background-color: rgba(0, 0, 0, .06);
  }
`