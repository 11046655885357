import { LogJob } from 'db'
import { useState } from 'react'
import { TableState } from '../../types'
import useLogJobs from './useLogJobs'
import useRetryLogJob from './useRetryLogJob'

type RetryLogJobs = {
  retryingLogJobs: boolean,
  retryLogJobs: (logJobs: LogJob[]) => Promise<{ logJob: LogJob }[]>
}

export default function useRetryLogJobs(tableState: TableState<LogJob>): RetryLogJobs {
  const { retryJob } = useRetryLogJob()
  const [retryingLogJobs, setRetryingLogJobs] = useState(false)
  const { revalidateLogJobs } = useLogJobs(tableState)

  const retryLogJobs = (logJobs: LogJob[]) => {
    setRetryingLogJobs(true)
    const retrys = Promise.all(logJobs.map(async (logJob) => {
      const { id } = logJob
      return retryJob(id)
        .then(({ data }) => data)
    }))

    retrys.then(() => {
      revalidateLogJobs?.()
      setRetryingLogJobs(false)
    })

    return retrys
  }

  return {
    retryingLogJobs,
    retryLogJobs
  }
}