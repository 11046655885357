import { State } from 'db'
import React, { FC } from 'react'
import ReactFlow, {
  addEdge, Background, Controls, EdgeTypes, MarkerType, MiniMap, NodeTypes, ReactFlowProps, updateEdge, useEdgesState, useNodesState
} from 'react-flow-renderer'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { selectedStateAtomFamily } from './recoil'
import StateEdge from './StateEdge'
import StateNode, { isValidConnection } from './StateNode'
import { useSetFlowsFromScenario } from './useSetFlowsFromScenario'
import useSyncConnectionStates from './useSyncConnectionStates'

const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance)

const nodeTypes: NodeTypes = {
  state: StateNode,
}

const edgeTypes: EdgeTypes = {
  state: StateEdge
}

const FlowDiagramEditor: FC = () => {
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const setSelected = useSetRecoilState(selectedStateAtomFamily({ scenarioId: scenarioId }))
  const [nodes, setNodes, onNodesChange] = useNodesState<State>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const { onDeleteNodes, onAddEdge, onDeleteEdges, onUpdateEdge } = useSyncConnectionStates()
  const onConnect: ReactFlowProps['onConnect'] = (params) => {
    setEdges((eds) => addEdge({ ...params,
      type: 'state',
      markerEnd: {
        type: MarkerType.Arrow,
      }, }, eds))
    onAddEdge(params, edges, nodes)
  }

  const onEdgeUpdate: ReactFlowProps['onEdgeUpdate'] = (oldEdge, newConnection) => {
    if (isValidConnection(newConnection, nodes, edges, oldEdge)) {
      setEdges((els) => updateEdge(oldEdge, newConnection, els))
      onUpdateEdge(oldEdge, newConnection, edges, nodes)
    }
  }


  useSetFlowsFromScenario({ setEdges, setNodes })

  return (
    <StyledReactFlow
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onEdgesDelete={(deletedEdges) => onDeleteEdges(deletedEdges, edges, nodes)}
      onConnect={onConnect}
      onNodesDelete={(deletedNodes) => onDeleteNodes(deletedNodes, edges, nodes)}
      onEdgeUpdate={onEdgeUpdate}
      onInit={onInit}
      onPaneClick={() => setSelected(undefined)}
      fitView
      attributionPosition="top-right"
    >
      <MiniMap
        // style={{ right: 'unset', left: 60 }}
        nodeStrokeColor={(n) => {
          if (n.style?.background) return n.style.background as string
          if (n.type === 'input') return '#0041d0'
          if (n.type === 'output') return '#ff0072'
          if (n.type === 'default' || n.type === 'state') return '#1a192b'

          return '#eee'
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background as string

          return '#fff'
        }}
        nodeBorderRadius={2}
      />
      <Controls style={{ right: 25, bottom: 180, left: 'unset' }} />
      {/* <StateDetailAndForm /> */}
      <Background color="#aaa" gap={16} />
    </StyledReactFlow>
  )
}

export default FlowDiagramEditor

const StyledReactFlow = styled(ReactFlow)`
  .react-flow__edge-path {
    cursor: pointer;
    stroke-width: 2px;
    & + .edgebutton-foreignobject {
      &:hover {
        visibility: visible;
      }
      visibility: hidden;
    }
    &:hover {
      stroke: green;
      & + .edgebutton-foreignobject {
        visibility: visible;
      }
    }
  }
`