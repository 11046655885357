import { BranchesOutlined, CreditCardFilled, CreditCardOutlined, ExperimentFilled, ExperimentOutlined, GlobalOutlined, HomeOutlined, TableOutlined, ThunderboltFilled, ThunderboltOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { FC, useEffect, useState } from 'react'
import { matchPath, useHistory, useLocation, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { ReactComponent as BlogIcon } from '../../../../assets/blog-icon.svg'
import { ReactComponent as OtomatisWhiteLogotype } from '../../../../assets/otomatis-logo (white).svg'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import { BLOG_URL, HELP_URL } from '../../../../util/Constant'
import AnchorWrapper from '../../../AnchorWrapper'
import CustomSiderDivider from './CustomSiderDivider'
import CustomSiderMenuItemLink from './CustomSiderMenuItemLink'
import ProfileMenuSider from './ProfilemenuSider'
import UserBillingPlanSider from './UserBillingPlanSider'

const { Sider } = Layout
const CustomSider: FC = () => {
  const [user] = useLoggedInUser()
  const { path } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)

  const mainSiderMenu: { label: string, path: string, icon?: JSX.Element, isAdminOnly?: boolean }[] = [
    {
      label: 'Create Scenario',
      path: `${path}/create-scenario`,
      icon: location.pathname.includes('/create-scenario') ? <ThunderboltFilled /> : <ThunderboltOutlined />
    },
    {
      label: 'My Scenario',
      path: `${path}/scenario`,
      icon: <BranchesOutlined />
    },
    {
      label: 'Scenario Templates',
      path: `${path}/template-scenario`,
      icon: location.pathname.includes('/template-scenario') ? <ExperimentFilled /> : <ExperimentOutlined />
    },
    {
      label: 'Data Loader',
      path: `${path}/data-loader`,
      icon: <TableOutlined />
    },
    {
      label: 'Explore',
      path: HELP_URL,
      icon: <GlobalOutlined />
    },
    {
      label: 'Blog',
      path: BLOG_URL,
      icon: <BlogIcon width="16" height={16} />
    },
    {
      label: 'Billing and Usage',
      path: `${path}/billing-usage`,
      icon: location.pathname.includes('/billing-usage') ? <CreditCardFilled /> : <CreditCardOutlined />
    }
  ]

  const settingsSiderMenu: { label: string, path: string, icon?: JSX.Element, isAdminOnly?: boolean }[] = [
    {
      label: 'My Profile',
      path: `${path}/settings/profile`,
      icon: undefined
    },
    {
      label: 'Security',
      path: `${path}/settings/security`,
      icon: undefined
    },
    {
      label: 'Admin Dashboard',
      path: `${path}/settings/admin`,
      isAdminOnly: true,
      icon: undefined
    }
  ]

  const getListMenu = (pathname: string) => {
    return pathname.includes('settings') ? settingsSiderMenu :
      pathname.includes('privacy-policy') ? [] :
        pathname.includes('term-of-service') ? [] :
          mainSiderMenu
  }

  const whichMenuHasActivePath = (pathname: string) => {
    const listMenu = getListMenu(pathname)
    return listMenu
      .filter(m => !!matchPath(pathname, m.path))
      .reduce((acc, m) => [...acc, m.path], [] as string[])
  }

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setCollapsed(false)
    } else {
      setCollapsed(true)
    }
  }, [location])

  const toHomeEndUserApp = () => {
    history.push('/app/create-scenario')
  }

  if (!user) {
    return null
  }

  return (
    <CustomSiderContainer
      // trigger={collapsed ? <MenuUnfoldOutlined style={{ color: '#FFF' }} /> : <MenuFoldOutlined style={{ color: '#FFF' }} />}
      collapsible
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      collapsed={collapsed}
      breakpoint={location.pathname.includes('edit') ? 'xxl' : 'md'}
      // collapsedWidth="0"
      iseditpage={`${location.pathname.includes('edit')}`}
      zeroWidthTriggerStyle={{ backgroundColor: '#F3AD3D', color: '#000' }}
    >
      <TopNavSider>
        <AnchorWrapper href='/app/create-scenario'>
          <OtomatisLogo onClick={toHomeEndUserApp}>
            {
              collapsed ?
                <HomeOutlined style={{ fontSize: 25, width: '100%', color: 'white' }} />:
                <OtomatisWhiteLogotype style={{ width: '100%', height: '90%' }} />
            }
          </OtomatisLogo>
        </AnchorWrapper>
        <CustomSiderDivider />
        <CustomSiderMenu
          mode="inline"
          selectedKeys={whichMenuHasActivePath(location.pathname)}>
          {
            getListMenu(location.pathname).map(m =>
              !user?.isAdmin && m.isAdminOnly ? null :
                <CustomSiderMenuItemLink
                  key={m.path}
                  label={m.label}
                  to={m.path}
                  icon={m.icon}
                />
            )
          }
        </CustomSiderMenu>
        {/* {
            text ?
              <ReactMarkdownHeading ulClassName="rmh-ul ant-menu ant-menu-root ant-menu-inline ant-menu-light" liClassName="rmh-li ant-menu-item" markdown={text} hyperlink={true} /> : null
          } */}
      </TopNavSider>
      {
        location.pathname.includes('settings') || getListMenu(location.pathname).length === 0 ?
          null:
          <BottomNavSider>
            {
              !collapsed ?
                <UserBillingPlanSider /> :
                null
            }

            <CustomSiderDivider />

            <ProfileMenuSider setCollapsed={setCollapsed} user={user} collapsed={collapsed} />
          </BottomNavSider>
      }
    </CustomSiderContainer>

  )
}

const CustomSiderContainer = styled(Sider)<{ iseditpage?: string }>`
  background-color: #3E73E3;
  min-width: ${({ collapsed }) => collapsed ? '60px !important' : undefined };
  max-width: ${({ collapsed }) => collapsed ? '60px !important' : undefined };
  width: ${({ collapsed }) => collapsed ? '60px !important' : undefined };

  .ant-menu-title-content {
    display: ${({ collapsed }) => collapsed ? 'none !important' : undefined };
  }

  .ant-menu-item-icon {
    display: ${({ collapsed }) => collapsed ? 'grid !important' : undefined };
    margin: ${({ collapsed }) => collapsed ? 'auto !important' : undefined };
  }
  &.ant-layout-sider-has-trigger {
    padding-bottom: 0;
  }

  .ant-layout-sider-trigger {
    background-color: #3E73E3; 
    display: none;
  }
  .ant-layout-sider-zero-width-trigger {
    top: 0;
    right: -24px;
    line-height: 25px;
    height: 27px;
    width: 24px;
    z-index: ${props => props.iseditpage === 'true' ? '3' : '0'};
    display: ${props => props.iseditpage === 'true' ? 'block' : 'none'};
    @media (max-width: 768px) {
      z-index: 3;
      display: block;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
  }

  .rmh-ul {
    background-color: transparent;
    color: #FFF;
    background-color: inherit;
    border: 0;
    max-height: 88vh;
    overflow-y: auto;

    & > .ant-menu-item-active {
      color: #FFF;
      background-color: #749BEB;
    }
    .rmh-li {
      height: fit-content;
      min-height: 40px;
      width: 100%;
      padding: 0 12px !important;
      display: flex;
      align-items: center;
      white-space: pre-wrap;

      &:hover {
        background-color: #749BEB;
      }

      a {
        color: white;
        max-width: 178px;
        &:active, &:hover {
          font-weight: bold;
        }
      }

      .ant-menu-inline-collapsed > & {
        display: none;
      }

      .ant-menu-inline > & {
        width: 100%;
      }

      &::after {
        border-right-color: transparent !important;
      }

      &.ant-menu-item:hover, &.ant-menu-item:active {
        color: #FFF;
      }
    }
  }
`

const OtomatisLogo = styled.div`
  height: 32px;
  margin: 12px;
  cursor: pointer;
`

const CustomSiderMenu = styled(Menu)`
  background-color: transparent;
  color: #FFF;
  background-color: inherit;
  border: 0;

  & > .ant-menu-item-active {
    color: #FFF;
    background-color: #749BEB;
  }
`

const TopNavSider = styled.div``

const BottomNavSider = styled.div``

export default CustomSider