import { State } from 'db'
import { useState } from 'react'
import { useParams } from 'react-router'
import useUpdateAScenarioState from '../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../hooks/useFetch'

type MoveStateHook = {
  onDrop: (fromState: State, state: State) => void,
  updatingOrderStates: boolean
}


type UpdateStateNeeded = Pick<State, 'childrenIds' | 'parentId'  | 'conditionGroupOrder' | 'stateConditionId' | 'name'>

export default function useMoveState(states: State[] | undefined): MoveStateHook {
  const [updatingOrderStates, setUpdatingOrderStates] = useState(false)
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const { revalidateAScenario } = useRevalidateFetch()


  const drop = (fromState: State, toState: State) => {
    if (states && fromState.id !== toState.id && fromState.parentId !== toState.id) {
      setUpdatingOrderStates(true)
      console.table(states?.map(s => ({ ...s, childrenIds: s.childrenIds?.join(','), name: s.name || (s.trigger || s.action)?.name })), ['name','id', 'order', 'conditionGroupOrder', 'parentId', 'childrenIds', 'stateConditionId'])
      const statesObject: Record<number, State> | undefined = states?.reduce((res, state) => {
        return {
          ...res,
          [state.id]: state as State
        }
      }, {})
      const fromParentState = fromState.parentId ? statesObject[fromState.parentId] : undefined

      const newFromState: UpdateStateNeeded = {
        parentId: toState.id,
        stateConditionId: toState.conditionGroupOrder ? toState.id : toState.stateConditionId || null,
        childrenIds: fromState.conditionGroupOrder ? undefined : toState.childrenIds || null
      }
      const newToState: UpdateStateNeeded = {
        stateConditionId: toState.parentId === fromState.id ? fromState.stateConditionId : undefined,
        parentId: toState.parentId === fromState.id ? fromState.parentId : toState.parentId,
        childrenIds: fromState.conditionGroupOrder && toState.childrenIds ? [...toState.childrenIds, fromState.id] : [fromState.id]
      }

      // update stateConditionId, parentId and childrenIds
      const triggerState = states?.find(state => state.triggerId)
      let connectorUpdated: Record<number, UpdateStateNeeded> = {}
      const { parentId: fromParentId, childrenIds: fromChildrenIds  } = fromState
      const updateConnector = (id: State['id'] ,params: UpdateStateNeeded) => {
        connectorUpdated = {
          ...connectorUpdated,
          [id]: params
        }

        params.childrenIds?.map(id => {
          const currState = statesObject[id]
          const { childrenIds, parentId } = currState
          if (id === fromState.id) {
            updateConnector(id, newFromState)
          } else if (id === toState.id) {
            updateConnector(id, newToState)
          } else {
            const newChildForPath = [...fromParentState?.childrenIds?.filter(id => id !== fromState.id) || []]
            updateConnector(id, {
              childrenIds:  id === fromParentId ?
                fromState.conditionGroupOrder ?
                  newChildForPath.length === 0 ? null : newChildForPath
                  : fromChildrenIds
                : childrenIds,
              parentId: parentId === fromState.id ? fromParentId :
                parentId === toState.id ? fromState.id : parentId,
            })
          }
        })
      }

      if (triggerState) {
        const newChildForPath = [...fromParentState?.childrenIds?.filter(id => id !== fromState.id) || []]
        updateConnector(triggerState.id, triggerState.id === toState.id ? newToState : {
          childrenIds:  triggerState.id === fromParentId ?
            fromState.conditionGroupOrder ?
              newChildForPath.length === 0 ? null : newChildForPath
              :
              fromChildrenIds : triggerState.childrenIds,
        })
      }
      const updatedParentIdAndChildrenIds = connectorUpdated

      // console.log(`move ${fromState.name || (fromState.trigger || fromState.action)?.name} to ${toState.name || (toState.trigger || toState.action)?.name}`, fromState.id, toState.id)
      console.table(Object.keys(updatedParentIdAndChildrenIds).map(key => ({ id: key, ...updatedParentIdAndChildrenIds[key], childrenIds: updatedParentIdAndChildrenIds[key].childrenIds?.join(','), })))

      // update order rekursif based on childrenIds
      let newStates: Record<number, Pick<State, 'order'> & UpdateStateNeeded> = {}

      const updateOrder = (stateId: number, newOrder: number) => {
        newStates = {
          ...newStates,
          [stateId]: {
            name: statesObject?.[stateId].name || (statesObject?.[stateId].trigger || statesObject?.[stateId].action)?.name,
            ...updatedParentIdAndChildrenIds[stateId],
            order: newOrder,
            conditionGroupOrder: statesObject?.[stateId].conditionGroupOrder ? newOrder : undefined
          }
        }
        const childrenIds = updatedParentIdAndChildrenIds[stateId]?.childrenIds !== undefined ? updatedParentIdAndChildrenIds[stateId]?.childrenIds : statesObject?.[stateId].childrenIds
        childrenIds?.map((id) => {
          updateOrder(id, newOrder + 1)
        })
      }

      // const triggerState = states?.find(state => state.triggerId)
      if (triggerState) {
        updateOrder(triggerState.id, 0)
        // console.table(Object.keys(newStates).map(key => ({ id: key, ...newStates[key] })).sort((d1, d2) => d1.order - d2.order))
      }
      const needUpdated = Object.keys(newStates).reduce((res, stateId) => {
        const newState: Partial<State> = newStates[stateId]
        const oldState: Partial<State> = statesObject[stateId]
        if (newState.order !== oldState.order ||
          newState.childrenIds !== undefined && newState.childrenIds !== oldState.childrenIds ||
          newState.parentId !== undefined && newState.parentId !== oldState.parentId ||
          newState.stateConditionId !== undefined && newState.stateConditionId !== oldState.stateConditionId
        ) {
          return {
            ...res,
            [stateId]: newState
          }
        }
        return res
      }, {})
      console.table(Object.keys(needUpdated).map(key => ({ id: key, ...needUpdated[key], childrenIdsString: needUpdated[key].childrenIds?.join(','), })).sort((d1, d2) => d1.order - d2.order))
      Promise.all(Object.keys(needUpdated).map(async (stateId) => {
        const payload: UpdateStateNeeded = needUpdated[stateId]
        const { name: _, ...payloadData } = payload
        return updateAScenarioState(parseInt(scenarioId), parseInt(stateId), payloadData)
          .then(({ data }) => data)
      }))
        .then(() => {
          revalidateAScenario(parseInt(scenarioId))
          setUpdatingOrderStates(false)
        })
        .catch(() => {
          setUpdatingOrderStates(false)
        })
    }
  }

  return {
    updatingOrderStates,
    onDrop: drop
  }
}