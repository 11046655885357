import { UploadOutlined } from '@ant-design/icons'
import { Input, Upload } from 'antd'
import { FormInstance, FormItemProps } from 'antd/lib/form'
import { UploadListType } from 'antd/lib/upload/interface'
import { FC, useEffect, useState } from 'react'
import { Implikasi } from '../../../../service/Implikasi'
import { IMPLIKASI_API_URL } from '../../../../util/Constant'
import Notification from '../../../../util/Notification'
import { StyledButton } from '../../../components/StyledComponents'
import FormItem from './FormItem'

type Props = FormItemProps & {
  description?: string,
  listType?: UploadListType,
  existed?: string,
  form: FormInstance<any>
}

const UploadImageField: FC<Props> = ({ form, existed, description, listType, ...formItemProps }) => {
  const { name, label } = formItemProps
  const [fileList, setFileList] = useState<any>()
  const [loading, setLoading] = useState(false)

  const normFile = (info: any) => {
    let fileList = [...info.fileList]
    // Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1)

    return fileList
  }

  useEffect(() => {
    if (!fileList && (existed || name && form.getFieldValue(name))) {
      setFileList([{
        uidstorage: '0',
        status: 'done',
        name: 'image.png',
        url: existed || name && form.getFieldValue(name),
      }])
    }
  }, [existed])

  const handleUpload = async () => {
    if (fileList[0].url) {
      return
    }
    setLoading(true)
    const formData = new FormData()
    formData.append('upload', fileList[0])
    Implikasi.post('/file', formData, {
      headers : {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        'type': 'formData'
      }
    }).then(({ data }) => {
      if (data?.file?.url) {
        form.setFieldsValue({
          [name as string]: data?.file?.url
        })
      }
    }).catch(({ response }) => {
      Notification.error({
        message: 'Something\'s Wrong',
        description: `Failed upload image, ${response?.data?.error}`
      })
      setFileList([])
    })
    setLoading(false)
  }

  useEffect(() => {
    if (fileList && fileList[0]) {
      handleUpload()
    } else {
      form.setFieldsValue({
        [name as string]: null
      })
    }
  }, [fileList])

  // const onChange = (info: any) => {
  //   const fileList = info.fileList.slice(-1)
  //   setFileList(fileList)
  //   form.setFieldsValue({
  //     image: fileList
  //   })
  //   if (name && fileList[0]?.response?.file?.location) {
  //     form.setFieldsValue({
  //       [name as string]: fileList[0]?.response?.file?.location
  //     })
  //   } else {
  //     form.setFieldsValue({
  //       [name as string]: null
  //     })
  //   }
  // }
  return (
    <>
      <FormItem
        name={`${name}-image`}
        label={label || `Upload Image for ${name}`}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        description={description}
      >
        {/* <ImgCrop fillColor="transparent" aspect={512 / 512} minZoom={0} cropperProps={{ restrictPosition: false }} grid> */}
        <Upload
          defaultFileList={existed ? [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: existed || form.getFieldValue(formItemProps.name || ''),
          }] : undefined}
          showUploadList={{ showPreviewIcon: false }}
          withCredentials action={`${IMPLIKASI_API_URL}/api/v2/file`}
          multiple={false}
          fileList={fileList}
          beforeUpload={file => {
            setFileList([file])
            return false
          }}
          name="upload"
          listType={listType || 'picture'}
          onRemove={() => {
            setFileList([])
          }}
          // onChange={onChange}
        >
            Upload: <StyledButton loading={loading}><UploadOutlined /> Select Image</StyledButton>
        </Upload>
        {/* </ImgCrop> */}
      </FormItem>
      <FormItem {...formItemProps} label={`${formItemProps.label || 'Logo'} URL`}>
        <Input />
      </FormItem>
    </>
  )
}

export default UploadImageField