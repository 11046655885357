import { Tabs } from 'antd'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { StyledButton } from '../../../../../../pages/components/StyledComponents'
import { DataSuggestion } from '../../../../developer/formItems/DataPickerPopper'
import DynamicOptionsDataPicker from './DynamicOptionsDataPicker'
import StateTestDataPicker from './StateTestDataPicker'

export type DualModeDataPickerPopperProps = {
  pagination?: {
    isUsingPagination?: boolean,
    onLoadMore?: () => void,
    isNoLoadMore?: boolean
  },
  mode: 'select' | 'custom',
  loading?: boolean,
  selectedValue: string,
  hiddenTriggerId?: number,
  statesWithTestData: Record<string, DataSuggestion> | null, // trigger and trigger.application should be expanded
  options?: { label: string, value: string }[],
  onChangeOption: (selected: { label: string, value: string }) => void,
  onTagClick: (tagValue: string) => void,
  onModeChange: (mode: 'select' | 'custom') => void
}

const DualModeDataPickerPopper = forwardRef<HTMLDivElement, DualModeDataPickerPopperProps>(({ pagination, loading, mode, selectedValue, hiddenTriggerId, options, statesWithTestData, onChangeOption, onTagClick, onModeChange }, ref) => {
  if (hiddenTriggerId === undefined && options === undefined) {
    throw new Error('When using DynamicOptionsDataPickerPopper, it is mandatory to pass id and hiddenTriggerId (or options) as its props')
  }

  const { TabPane } = Tabs

  // dual mode data picker
  return (
    <DataPickerPopperContainer ref={ref}>
      <DualModeTabsDataPicker onChange={(activeKey: string) => onModeChange(activeKey as 'select' | 'custom')} activeKey={mode}>
        <TabPane tab="Select Data" key="select">
          <DynamicOptionsDataPicker
            loading={loading}
            selectedValue={selectedValue || ''}
            options={options}
            hiddenTriggerId={hiddenTriggerId}
            onChangeOption={onChangeOption}
          />
          {pagination?.isUsingPagination ? <StyledButton style={{ width: '100%' }} type="link" disabled={pagination.isNoLoadMore} onClick={pagination.onLoadMore}>Load More</StyledButton> : null}
        </TabPane>
        <TabPane tab="Custom" key="custom">
          <StateTestDataPicker
            statesWithTestData={statesWithTestData}
            onTagClick={onTagClick}
          />
        </TabPane>
      </DualModeTabsDataPicker>
    </DataPickerPopperContainer>
  )
})

export default DualModeDataPickerPopper

export const DataPickerPopperContainer = styled.div`
  display: flex;
  z-index: 100;
  flex-direction: column;
  border-radius: 4px;

  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  transition: all .24s;
`

export const DualModeTabsDataPicker = styled(Tabs)`
  & .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
  }

  & > .ant-tabs-nav {
    margin: 0;
  }

  & > .ant-tabs-content-holder {
    background: #FFF;
  }

  & > .ant-tabs-nav > .ant-tabs-nav-wrap {
    font-family: 'DM Sans', sans-serif;
    background: #F3AD3D;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    color: #000;

    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        background: #2EB67D;
        height: 3px;
      }

      .ant-tabs-tab {
        font-weight: 200;
        padding: 8px 16px;
      }

      .ant-tabs-tab:hover {
        font-weight: 400;
        color: #000;
        background: #ffc76d;
      }

      .ant-tabs-tab-active:hover {
        background: #F3AD3D;
      }

      .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
        color: #000;
        font-weight: 400;
      }

      .ant-tabs-tab.ant-tabs-tab-active {
        font-weight: 400;

        .ant-tabs-tab-btn {
          color: #000;
          font-weight: 600;
        }
      }
    }
  }
`

export const CustomDualModeInputSearchData = styled.div`
  & .ant-input-affix-wrapper {
    background: #F5F5F5;
    margin-bottom: 12px;

    * {
      color: #8C8C8C;
    }

    border: none;
    outline: none;

    & .ant-input-affix-wrapper:focus {
      box-shadow: none;
    }

    input {
      background: rgba(186, 139, 66, 0);
      color: #8C8C8C;
      caret-color: #8C8C8C;
    }

    input::placeholder {
      color: #8C8C8C;
      font-weight: 500;
      font-size: 12px;
    }

  }
`