import { AxiosPromise } from 'axios'
import { State } from 'db'
import { useSetRecoilState } from 'recoil'
import { updatingScenarioOrState } from '../../../components/pages/app/scenario/edit/recoil'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  updatedAScenarioState?: State,
  updatingAScenarioState: boolean,
  errorUpdateAScenarioState?: Error,
  updateAScenarioState: (scenarioId: number, stateId: number, payload: Partial<State>) => AxiosPromise<{ state: State }>
}

export default function useUpdateAScenarioState(): Result {
  const { data, error, isValidating, call } = useApi<{ state: State }>()
  const setUpdatingAScenarioState = useSetRecoilState(updatingScenarioOrState)

  return {
    updatedAScenarioState: data?.state,
    updatingAScenarioState: isValidating,
    errorUpdateAScenarioState: error,
    updateAScenarioState: (scenarioId, stateId, payload) => {
      setUpdatingAScenarioState(prevState => ({ counter: prevState.counter + 1, isUpdating: true }))
      return call({
        url: `/scenario/${scenarioId}/state/${stateId}`,
        method: 'patch',
        data: {
          state: payload
        }
      })
    }
  }
}