import { Form, FormInstance, FormProps } from 'antd'
import { FieldSchema } from 'db'
import { debounce } from 'debounce'
import isEqual from 'lodash.isequal'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useGetParamSchema from '../../../../../../hooks/application/main/useGetParamSchema'
import useUpdateAScenarioState from '../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../hooks/useFetch'
import { StyledAlert } from '../../../../../../pages/components/StyledComponents'
import FormItem from '../../../../../../pages/developer/components/form/FormItem'
import { convertFormValueToParams, convertParamsToFormValue } from '../../../../../../util/formatter'
import { updatingScenarioOrState } from '../recoil'
import { useThisStateStaticValue } from '../ThisStateStaticValueContext'
import StateFormItemMapping from './StateFormItemMapping'

interface Props {
  isEnableAuthentication?: boolean,
  formAntProps?: FormProps,
  setOnRefetchOptions?: (isRefetch?: boolean) => void,
  counterRefetch?: number,
  form: FormInstance<any>,
  initialParamSchema?: FieldSchema[],
  scenarioId: number,
  bundleId?: number,
  bundles?: any,
  triggerId?: number,
  actionId?: number,
  stateId: number,
  applicationId?: number
}

const StateForm: FC<Props> = ({ isEnableAuthentication, scenarioId, stateId, setOnRefetchOptions, form, formAntProps, counterRefetch, initialParamSchema, children, ...props }) => {
  const { revalidateAScenarioState } = useRevalidateFetch()
  const isNoDynamic = initialParamSchema?.filter(field => field.paramSchemaFunc || field.getValueFunc && field.allowEditByUser).length === 0
  const { paramSchema, getParamSchema, fetchingParamSchema } = useGetParamSchema({ ...props, initialParamSchema }, !isNoDynamic && (isEnableAuthentication && (props.bundleId || props.bundles) || !isEnableAuthentication))
  const { updateAScenarioState } = useUpdateAScenarioState()
  const [thisStateStaticValue] = useThisStateStaticValue()
  const stateParams = thisStateStaticValue?.params
  const [isMouseLeavingForm, setIsMouseLeavingForm] = useState<boolean>()
  const { bundleId, bundles, actionId, triggerId } = props
  const [savedParamSchema, setSavedParamSchema] = useState<FieldSchema[]>()
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)

  useEffect(() => {
    if (paramSchema && initialParamSchema) {
      form.setFieldsValue(convertParamsToFormValue(convertFormValueToParams({ ...stateParams, ...form.getFieldsValue() }), paramSchema))
      setSavedParamSchema(paramSchema)
    }
  }, [paramSchema])

  useEffect(() => {
    if (setOnRefetchOptions) {
      setOnRefetchOptions(fetchingParamSchema)
    }
  }, [fetchingParamSchema])

  useEffect(() => {
    if (!isEnableAuthentication || isEnableAuthentication && (bundleId || bundles)){
      // getAllOptionsHitRequest(form?.getFieldsValue())
    }
    if (counterRefetch && !getParamSchema(form?.getFieldsValue())) {
      setOnRefetchOptions?.(false)
    }
  }, [counterRefetch])

  useEffect(() => {
    // handle existed value from state.params that cant get from form.getFieldsValue
    if (stateParams !== undefined && (!isEqual(stateParams, form.getFieldsValue()) || counterRefetch === 0)) {
      if (!isEnableAuthentication || isEnableAuthentication && (bundleId || bundles)){
        // getAllOptionsHitRequest(stateParams)
        // console.log('disini', stateParams, form.getFieldsValue())
        if (!getParamSchema(stateParams)) {
          setOnRefetchOptions?.(false)
        }
      }
    }
  }, [stateParams, bundles, bundleId])

  const onValuesChange = (value: any, _: any) => {
    const changedKey = Object.keys(value)[0]
    const fieldChanged = initialParamSchema?.find(field => field.key === changedKey)
    // fetch dynamic field if changed field is not from dynamic field
    if (fieldChanged) {
      // debounce(() => {
      //   if (!getParamSchema({ ...form.getFieldsValue() })) {
      //     setOnRefetchOptions?.(false)
      //   }
      //   // getAllOptionsHitRequest({ ...form.getFieldsValue() }, indexFieldChanged)
      // }, 1000)()
    }
  }
  const onMouseEnter = () => setIsMouseLeavingForm(false)
  const onMouseLeave = () => setIsMouseLeavingForm(true)

  const updateStateParams = useCallback((newParamsValue: Record<string, any>) => {
    const converted = convertFormValueToParams(newParamsValue)
    if (!isEqual(converted, stateParams)) {
      updateAScenarioState(scenarioId, stateId, { params: converted })
        .then(() => {
          setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
          revalidateAScenarioState(scenarioId, stateId)
        })
    }
  }, [scenarioId, stateId, updateAScenarioState, stateParams])

  useEffect(() => {
    let debounceUpdateState: any

    if (isMouseLeavingForm) {
      const formValue = form.getFieldsValue()
      const filteredFormValue = Object.keys(formValue)
        .filter(fieldKey => formValue[fieldKey] !== undefined)
        .reduce((acc, fieldKey) => ({ ...acc, [fieldKey]: formValue[fieldKey] }), {})

      if (Object.keys(filteredFormValue).length > 0 && !isEqual(filteredFormValue, stateParams)) {
        debounceUpdateState = debounce(() => updateStateParams(form.getFieldsValue()), 1000)

        debounceUpdateState()
      }

      return () => {
        if (debounceUpdateState) {
          debounceUpdateState.clear()
        }
      }
    }
  }, [isMouseLeavingForm])

  const onFocusOut = (key: string) => {
    // const fieldChanged = initialParamSchema?.find(field => field.key === key)
    // fetch dynamic field if changed field is not from dynamic field
    // if (form.getFieldValue(key) && form.getFieldValue(key) !== stateParams?.[key]) {
    if (form.getFieldValue(key)) {
      if (!getParamSchema({ ...form.getFieldsValue() })) {
        setOnRefetchOptions?.(false)
      }
    }
  }

  return (
    form ?
      <Form requiredMark="optional" layout="vertical" form={form} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onValuesChange={onValuesChange} {...formAntProps}>
        {
          !isNoDynamic && !actionId && !triggerId ?
            <>
              <StyledAlert type="info" message="Have dynamic fields, can't see dynamic field preview"></StyledAlert>
            </>
            : null
        }
        {
          (savedParamSchema || initialParamSchema)?.map((param, idx) => {
            return (
              <StateFormItemMapping
                actionId={actionId}
                bundleId={bundleId}
                fetchingParamSchema={fetchingParamSchema}
                form={form}
                idx={idx}
                onFocusOut={onFocusOut}
                param={param}
                stateId={stateId}
                triggerId={triggerId}
                initialParamSchema={initialParamSchema}
                key={param.key}
              />
            )
          })}
        {children}
      </Form> : null
  )
}

export default StateForm

// eslint-disable-next-line
export const StyledFormItem = styled(({ description, children, ...nativeProps }) => <FormItem description={description} {...nativeProps}>{children}</FormItem>)<{ description: string }>`
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 16px;

  .ant-checkbox-wrapper, .ant-radio-wrapper, .ant-form-item-extra {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
  &.with-extra {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: ${props => props.description ? 0 : '5px'};
  }
  .ant-form-item-label > label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-form-item-optional, label.ant-form-item-required::before {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    text-transform: capitalize;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: '(Required)';
    color: ${'#9C3930'};
    margin-left: 4px;
  }

  &.no-info .ant-form-item-label > label.ant-form-item-required::before {
    content: '';
  }

  .ant-form-item-label > label.ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .ant-form-item-extra {
    padding-bottom: 5px;
  }

  .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-success {
    position: absolute;
    bottom: -8px;
  }

  label {
    padding-bottom: 0
  }

`