import { FC } from 'react'
import { useParams } from 'react-router'
import CustomCodeLogs from '../../../../../../components/pages/developer/customCodeLogs'
import useAction from '../../../../../../hooks/application/action/useAction'
import useActionTestLogs from '../../../../../../hooks/application/codeLogs/useActionTestLogs'
import useApplication from '../../../../../../hooks/application/main/useApplication'
import Header from '../../../../../components/Header'

interface Props {

}
const ActionLogs: FC<Props> = () => {
  const { applicationId, actionId, versionId } = useParams<{ applicationId: string, actionId: string, versionId: string }>()
  const { action } = useAction(applicationId, actionId, parseInt(versionId))
  const { application } = useApplication(applicationId)
  const { getLatestLog, uniqueLogs, loadMore, fetchingTestLogs } = useActionTestLogs({ applicationId, actionId, versionId })
  const versionName = application?.versions?.find((version) => parseInt(versionId) === version.id)?.name

  return (
    <>
      <Header />
      <CustomCodeLogs
        getLatestLog={getLatestLog}
        fetchingUniqueLogs={fetchingTestLogs}
        uniqueLogs={uniqueLogs}
        loadMore={loadMore}
        title={`Logs for "${application?.name}" - Action "${action?.name}" - Version "${versionName}"`}
      />
    </>
  )
}

export default ActionLogs