import { Invitation } from 'db'
import { Error } from '../../../types'
import useFetch from '../../useFetch'

type Result = {
  revalidateInvitations: () => void,
  invitations?: Invitation[],
  fetchingAllInvitations: boolean,
  errorFetchAllInvitation?: Error
}

export default function useAllInvitation(applicationId?: number | string): Result {
  const url = applicationId ? `/application/${applicationId}/invitations` : null
  const { data, error, isValidating, revalidateFetch } = useFetch<{ invitations: Invitation[] }>(url, {}, { revalidateOnFocus: true })

  return {
    revalidateInvitations: () => revalidateFetch(url),
    invitations: data?.invitations,
    fetchingAllInvitations: isValidating,
    errorFetchAllInvitation: error
  }
}