import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Card, Col, Input, Layout, Modal, Row, Select, Space, Table, Tag } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { SelectValue } from 'antd/lib/select'
import { ColumnType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { Application, Version } from 'db'
import React, { KeyboardEvent, MouseEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import AnchorWrapper from '../../../../components/AnchorWrapper'
import ButtonActionMore from '../../../../components/ButtonActionMore'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import useArchiveApplication from '../../../../hooks/application/main/useArchiveApplication'
import useMyApplications from '../../../../hooks/application/main/useMyApplications'
import { TableState } from '../../../../types'
import { blue } from '../../../../util/colors'
import { PAGE_SIZE_OPTIONS } from '../../../../util/Constant'
import { getInitialName } from '../../../../util/formatter'
import Notification from '../../../../util/Notification'
import Header from '../../../components/Header'
import { StyledButton } from '../../../components/StyledComponents'
import { baseApplicationRoute } from '../routes'
import ModalImportApp from './ModalImportApp'

const View: React.FC = () => {
  const [tableState, setTableState] = useState<TableState<Application>>({
    pagination: {
      pageSize: 30
    },
    sorter: { field: 'name', order: 'ascend' }
  })
  const history = useHistory()
  const [user] = useLoggedInUser()
  const { applications, fetchingApplications, revalidateApplications, applicationsLength } = useMyApplications(user?.id, tableState)
  const { archiveApplication, archivingApplication, archivedapplication } = useArchiveApplication()
  const { confirm } = Modal
  const [visible, setVisible] = useState(false)

  const handleChange = (pagination: TablePaginationConfig, filter: Record<string, FilterValue | null>, sorter: SorterResult<Application> | SorterResult<Application>[], extra: TableCurrentDataSource<Application>) => {
    setTableState(prevState => ({ ...prevState, pagination, filter: { ...prevState.filter, ...filter }, sorter, extra }))
  }

  const openModalImport = () => {
    setVisible(true)
  }

  const goToDashboard = async (applicationId: number, versionName: string | number) => {
    return history.push(`${baseApplicationRoute}/${applicationId}/${versionName}/main`)
  }

  const goToCreate = () => {
    return history.push(`${baseApplicationRoute}/create`)
  }

  const columns: ColumnType<Application>[] = [
    { title: 'ID', dataIndex: 'id', render: (id) => <AnchorWrapper style={{ color: blue[4] }} href={`${baseApplicationRoute}/${id}/main`}>{id}</AnchorWrapper> },
    { title: 'Logo', dataIndex: 'logoUrl', render: (logoUrl, record) => logoUrl ? <img src={logoUrl} width="30px" /> : <Avatar icon={getInitialName(record.name)} /> },
    { title: 'Name', dataIndex: 'name', sorter: true, defaultSortOrder: 'ascend'  },
    { title: 'Version', dataIndex: 'versions', render: (versions?: Version[]) => versions ? versions[0]?.name : null },
    { title: 'Status', dataIndex: 'public', render: (isPublic) => <Tag color={isPublic ? 'geekblue' : 'orange'}>{isPublic ? 'public' : 'private'}</Tag> },
    { title: 'Action', dataIndex: 'id', render: (id) => <Space>
      <ButtonActionMore
        overlay={<></>}
        listMenu={[
          {
            key: 'delete',
            onClick: (info) => onDelete(info.domEvent, id),
            children: 'Delete'
          },
          {
            key: 'edit',
            onClick: (info) => onEdit(info.domEvent, id),
            children: <AnchorWrapper href={`${baseApplicationRoute}/${id}/main/general`} >Edit General Info</AnchorWrapper>
          }
        ]}
      />
    </Space> },
  ]

  const onEdit = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> , id: number) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`${baseApplicationRoute}/${id}/main/general`)
  }

  const onDelete = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> | undefined, id: number) => {
    e?.preventDefault()
    e?.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this application?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action can not be undone',
      onOk() {
        archiveApplication(id)
      }
    })
  }

  useEffect(() => {
    if (archivedapplication) {
      Notification.success({
        message: `Application "${archivedapplication.name}" deleted succcesfully`
      })
      revalidateApplications()
    }
  }, [archivedapplication])

  const { Search } = Input

  const onSearch = (value: string) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const onChangeApplicationStatusFilter = (value: SelectValue) => {
    let filter: { public?: boolean } = {  }
    if (value === 'public') {
      filter = { public: true }
    } else if (value === 'private') {
      filter = { public: false }
    } else {
      filter = { public: undefined }
    }
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, ...filter }, pagination: { ...prevState.pagination, current: 1 } }))
  }

  return (
    <StyledView>
      <Header />
      <Row style={{ padding: 16 }} gutter={[16, 16]}>
        <Col span={24} lg={{ span: 16, offset: 4 }} style={{ marginTop: '20px' }}>
          <StyledCard>
            <Row align="middle" justify="space-between">
              <Col flex={0}>
                <Space direction="vertical">
                  <h3>Build your{applications?.length === 0 ? ' First' : ''} Otomatis Application</h3>
                  <p>Connect your API to the Indonesian’s top apps.</p>
                </Space>
              </Col>
              <Col flex={0}>
                <Row gutter={[16, 16]}>
                  <Col>
                    <AnchorWrapper href={`${baseApplicationRoute}/create`}>
                      <StyledButton type="primary" onClick={goToCreate}>
                        {applications?.length === 0 ? 'Start an' : 'Create New'} Otomatis Application
                      </StyledButton>
                    </AnchorWrapper>
                  </Col>
                  <Col>
                    <StyledButton onClick={openModalImport}>
                    Import App
                    </StyledButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </StyledCard>
        </Col>
        <Col lg={{ span: 5, offset: 9 }} xl={{ span: 4, offset: 10 }} md={{ span: 12 }}>
          <Select
            showSearch
            style={{ minWidth: 200 }}
            placeholder="All Applications"
            onChange={onChangeApplicationStatusFilter}
            allowClear
            options={[{ value: 'public', label: 'Public Applications' }, { value: 'private', label: 'Private Applications' }]}
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
          </Select>
        </Col>
        <Col lg={{ span: 6 }} xl={{ span: 6 }} md={{ span: 12 }} >
          <Search placeholder="search application..." onSearch={onSearch} allowClear  />
        </Col>
        <Col span={24} lg={{ span: 16, offset: 4 }}>
          <StyledCard>
            <Table
              onChange={handleChange}
              pagination={{
                current: tableState.pagination?.current,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                showSizeChanger: true,
                total: applicationsLength,
                pageSize: tableState.pagination?.pageSize
              }}
              onRow={(record) => ({
                onClick: () => goToDashboard(record.id, record.versions?.[0]?.name || '')
              })}
              loading={fetchingApplications || archivingApplication}
              rowKey="id"
              scroll={{ x: true }}
              dataSource={applications}
              columns={columns}
            />
          </StyledCard>
        </Col>
      </Row>
      <ModalImportApp visible={visible} setVisible={setVisible} />
    </StyledView>
  )
}

export default View

const StyledView = styled(Layout.Content)`
  .ant-list {
    background: #FFF;
  }
  overflow-x: hidden;
`
const StyledCard = styled(Card)`
  .ant-table-row {
    cursor: pointer;
  }
  h3 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
  background: #FFFFFF;
  /* Blue/1 */
  border: 1px solid #DCE6FA;
  box-sizing: border-box;
  border-radius: 4px;
`