import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Skeleton, Typography } from 'antd'
import { Scenario, State } from 'db'
import React, { FC } from 'react'
import styled from 'styled-components'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import useUpdateAScenario from '../../../../../hooks/useUpdateAScenario'

const EditScenarioNamePageHeader: FC<{ scenario: Scenario | undefined, state?: State }> = ({ state, scenario }) => {
  const { revalidateAScenario } = useRevalidateFetch()
  const { updateScenarioName, updatingScenario } = useUpdateAScenario()
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()

  return (
    <div>
      {
        !scenario && !state ?
          <Skeleton.Input style={{ width: 200, height: '38px', display: 'block' }} active /> :
          <StyledTitle
            level={4}
            disabled={updatingScenario || updatingAScenarioState}
            editable={{
              tooltip: 'edit scenario name',
              icon: updatingScenario ? <LoadingOutlined spin style={{ fontSize: 18 }} /> : <EditOutlined />,
              onChange: async (input) => {
                if (input !== (scenario || state)?.name) {
                  if (scenario) {
                    updateScenarioName(scenario.id, input)
                  }
                  if (state) {
                    await updateAScenarioState(state.scenarioId, state.id, {
                      name: input
                    })
                    revalidateAScenario(state.scenarioId)
                  }
                }
              }
            }}
          >
            {(scenario || state)?.name}
          </StyledTitle>
      }

      {/* <StyledP>
        Follow these instructions to {scenario ? 'create your scenario' : 'add a scenario for this path'}.
      </StyledP> */}
    </div>
  )
}

export default EditScenarioNamePageHeader

const StyledTitle = styled(Typography.Title)`
  /* H3 - 24 */
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* Character/Primary .65 */

  color: rgba(0, 0, 0, 0.65);
  margin: 0 !important;
`
const _StyledP = styled(Typography.Paragraph)`
    /* P1 - 14 */
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* Character/Title .85 */
  color: rgba(0, 0, 0, 0.85);
`