import { FC } from 'react'
import { useParams } from 'react-router'
import CustomCodeLogs from '../../../../../../components/pages/developer/customCodeLogs'
import useApplication from '../../../../../../hooks/application/main/useApplication'
import useTrigger from '../../../../../../hooks/application/trigger/useTrigger'
import useTriggerTestLogs from '../../../../../../hooks/application/codeLogs/useTriggerTestLogs'
import Header from '../../../../../components/Header'

interface Props {

}
const TriggerLogs: FC<Props> = () => {
  const { applicationId, triggerId, versionId } = useParams<{ applicationId: string, triggerId: string, versionId: string }>()
  const { trigger } = useTrigger(applicationId, triggerId, parseInt(versionId))
  const { application } = useApplication(applicationId)
  const { getLatestLog, uniqueLogs, loadMore, fetchingTestLogs } = useTriggerTestLogs({ applicationId, triggerId, versionId })
  const versionName = application?.versions?.find((version) => parseInt(versionId) === version.id)?.name
  return (
    <>
      <Header />
      <CustomCodeLogs
        getLatestLog={getLatestLog}
        fetchingUniqueLogs={fetchingTestLogs}
        uniqueLogs={uniqueLogs}
        loadMore={loadMore}
        title={`Logs for "${application?.name}" - Trigger "${trigger?.name}" - Version "${versionName}"`}
      />
    </>
  )
}

export default TriggerLogs
