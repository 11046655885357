import { PlusOutlined } from '@ant-design/icons'
import { ButtonProps } from 'antd'
import { Scenario, State } from 'db'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { useLoggedInUser } from '../../../../../contexts/LoggedInUserContextProvider'
import useAPricingPlan from '../../../../../hooks/pricingPlan/useAPricingPlan'
import { StyledSecondaryButton } from '../../../../../pages/components/StyledComponents'
import ChooseActionAppAndEvent from './ChooseActionAppAndEvent'
import ChooseConditionEvent from './ChooseConditionEvent'
import ChooseNewActionTypeModal from './ChooseNewActionTypeModal'
import { ChildStateType, useStateChildsData } from './StateTestDataContext'

interface AddNewActionButtonModalProps extends ButtonProps {
  state: State | undefined,
  scenario: Scenario | undefined
}

type ActionType = 'app' | 'condition' | 'transform' | 'delay' | number

const AddNewAction: FC<AddNewActionButtonModalProps> = ({ state, scenario, ...otherProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newActionType, setNewActionType] = useState<ActionType>() // default value will be undefined
  const [nextStateIdsAll] = useStateChildsData()
  const [loggedInUser] = useLoggedInUser()
  const { pricingPlan } = useAPricingPlan(loggedInUser?.pricingPlanId)

  const nexts: ChildStateType[] | undefined = nextStateIdsAll &&  state?.id ? nextStateIdsAll[state.id] : undefined

  const openChooseNewActionTypeModal = () => setIsModalOpen(true)

  const close = () => setIsModalOpen(false)

  const resetActionType = () => setNewActionType(undefined) // reset with undefined 👍

  const onSubmitNewActionType = async (values: any) => {
    setNewActionType(values.actionType)
    close()
  }

  const isLastState = scenario?.states?.reduce((res, s, _, states) => {
    if (s.id === state?.id && !states.find(currState => currState.order > s.order && (!currState.stateConditionId || (currState.stateConditionId === s.stateConditionId || currState.stateConditionId === s.id)))) {
      return true
    }
    return res
  }, false) && pricingPlan?.isConditionalStep

  return (
    <>
      {
        state ?
          <AddActionButton
            onClick={openChooseNewActionTypeModal}
            icon={<PlusOutlined />}
            {...otherProps}
          >
            {isLastState ? 'Add action/condition' : 'Add Action'}
          </AddActionButton> :
          <AddActionButton
            icon={<PlusOutlined />}
            disabled
          >
            {isLastState ? 'Add action/condition' : 'Add Action'}
          </AddActionButton>
      }

      {
        newActionType === 'app' && state && scenario ?
          <>
            <ChooseActionAppAndEvent
              stateConditionId={state.conditionGroupOrder ? state.id : state.stateConditionId ? state.stateConditionId : undefined}
              order={state.order}
              parentStateId={state.id}
              childrenStateIds={state.childrenIds}
              nextStateIds={nexts}
              scenarioId={scenario.id}
              onCancel={resetActionType}
              onSuccessCreateNewState={resetActionType}
            />

            {/* not-usable-ghost button just to make sure the visual aligned */}
            <AddActionButton
              icon={<PlusOutlined />}
              type="primary"
              ghost
              disabled
            >
              {isLastState ? 'Add action/condition' : 'Add Action'}
            </AddActionButton>
          </> : null
      }

      {
        newActionType === 'condition' && state && scenario ?
          <>
            <ChooseConditionEvent
              stateConditionId={state.conditionGroupOrder ? state.id : state.stateConditionId ? state.stateConditionId : undefined}
              states={scenario.states}
              state={state}
              scenarioId={scenario.id}
              onSuccessCreateNewState={resetActionType}
            />
          </> : null
      }

      {
        typeof newActionType === 'number' && state && scenario ?
          <>
            <ChooseActionAppAndEvent
              parentStateId={state.id}
              childrenStateIds={state.childrenIds}
              applicationId={newActionType}
              stateConditionId={state.conditionGroupOrder ? state.id : state.stateConditionId ? state.stateConditionId : undefined}
              order={state.order}
              nextStateIds={nexts}
              scenarioId={scenario.id}
              onCancel={resetActionType}
              onSuccessCreateNewState={resetActionType}
            />

            {/* not-usable-ghost button just to make sure the visual aligned */}
            <AddActionButton
              icon={<PlusOutlined />}
              type="primary"
              ghost
              disabled
            >
              {isLastState ? 'Add action/condition' : 'Add Action'}
            </AddActionButton>
          </> : null
      }

      {/* Pop-up modal to ask customer which type of action/condition they want to add */}
      <ChooseNewActionTypeModal isLastState={isLastState} visible={isModalOpen} onClose={close} onSubmit={onSubmitNewActionType} />
    </>
  )
}

export default AddNewAction

const AddActionButton = styled(StyledSecondaryButton)`
  margin-top: 12px;
  background: #FFF !important;
  &[disabled] {
    background: #f5f5f5 !important
  }
  &:hover {
    outline: 1.5px solid;
  }
`