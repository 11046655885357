import { SettingOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, message, Popconfirm, Row, Space, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { OTORISASI_API_URL } from '../../../helpers/Constant'

interface UrlProps extends RouteComponentProps<{ id: string }> {}

const ViewApp: React.FC<UrlProps> = ({ match }) => {
  const history = useHistory()
  const [form] = useForm()
  const [user, setUser] = useState<any>()
  const [client, setClient] = useState<any>()
  const [clientSecret, setClientSecret] = useState<string>('*******-**********-****-**********')
  const [waitingTimeGenerateIssue, setWaitingTimeGenerateIssue] = useState<number>(0)

  useEffect(() => {
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/user/me`, { withCredentials: true })
      .then(({ data }) => setUser(data?.user))
      .catch(() => history.replace('/auth/login'))
  }, [history])

  useEffect(() => {
    (async () => {
      if (client === undefined) {
        try {
          const { data } = await Axios.get(`${OTORISASI_API_URL}/api/auth/v1/client/${match.params.id}`, { withCredentials: true })
          setClient({
            ...data.client,
            credentials: data.client.credentials ? JSON.stringify(data.client.credentials) : undefined
          })
          form.setFieldsValue({
            name: data.client?.name,
            description: data.client?.description,
            clientId: data.client?.clientId,
            installationId: data.client?.installationId,
            iconUrl: data.client?.iconUrl,
            redirectUrl: data.client?.redirectUrl,
            domain: data.client?.domain,
            // isNeedCodeVerification: data.client?.isNeedCodeVerification,
            integration: data.client?.integration,
            credentials: data.client?.credentials ? JSON.stringify(data.client?.credentials) : '',
          })
        } catch (error) {
          return message.error(error?.response?.data?.error || 'Something error')
        }
      }
    })()
  }, [history, client, match, form])

  useEffect(() => {
    if (waitingTimeGenerateIssue > 0) {
      setTimeout(() => setWaitingTimeGenerateIssue(waitingTimeGenerateIssue - 1), 1000)
    } else {
      setClientSecret('*******-**********-****-**********')
    }
  }, [waitingTimeGenerateIssue])

  const updateClient = async () => {
    try {
      const { data } = await Axios.patch(`${OTORISASI_API_URL}/api/auth/v1/client/${client?.id}`, {
        client: { ...client, ...client?.credentials ? { credentials: JSON.parse(client.credentials) } : {} }
      }, { withCredentials: true })
      setClient({
        ...data.client,
        credentials: data.client.credentials ? JSON.stringify(data.client.credentials) : undefined
      })
      form.setFieldsValue({
        name: data.client?.name,
        description: data.client?.description,
        clientId: data.client?.clientId,
        installationId: data.client?.installationId,
        iconUrl: data.client?.iconUrl,
        redirectUrl: data.client?.redirectUrl,
        domain: data.client?.domain,
        // isNeedCodeVerification: data.client?.isNeedCodeVerification,
        integration: data.client?.integrationId,
        credentials: data.client?.credentials ? JSON.stringify(data.client?.credentials) : '',
      })
      return message.success('Your app updated successfully')
    } catch (error) {
      console.error(error)
      return message.error(error?.response?.data?.error || 'Something error')
    }
  }

  const regenerateSecret = async () => {
    try {
      const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/client/${client?.id}/regenerateSecret`, {}, { withCredentials: true })
      setClientSecret(data.client.clientSecret)
      form.setFieldsValue({ clientSecret: data.client.clientSecret })
      message.success('Secret is regenerated! Please keep it safe')
      return setWaitingTimeGenerateIssue(10)
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error')
    }
  }

  const remove = async () => {
    try {
      await Axios.delete(`${OTORISASI_API_URL}/api/auth/v1/client/${client?.id}`, { withCredentials: true })
      message.success('Deleted successfully')
      return history.push('/apps')
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error')
    }
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <Card title={client?.name || 'Please wait...'}>
            <Form onFinish={updateClient} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>

              {/* <Form.Item
                name="installationId"
                label="Installation ID">
                <Input disabled />
              </Form.Item> */}

              <Form.Item
                name="clientId"
                label="Client ID">
                <Input disabled />
              </Form.Item>

              <Form.Item
                name="clientSecret"
                label="Client Secret">
                <>
                  <Input disabled value={clientSecret} />
                  { waitingTimeGenerateIssue > 0 ? <Typography.Text type="secondary">secret will be hidden in {waitingTimeGenerateIssue}s...</Typography.Text> :
                    <Popconfirm
                      title="Want to regenerate the secret?"
                      onConfirm={regenerateSecret}>
                      <Button type="link">Forgot?</Button>
                    </Popconfirm>
                  }
                </>
              </Form.Item>

              <Divider />

              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name cannot be blank' }]}>
                <Input onChange={e => setClient({ ...client, name: e.target.value })} />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Description cannot be blank' }]}>
                <Input.TextArea onChange={e => setClient({ ...client, description: e.target.value })} />
              </Form.Item>

              <Form.Item
                name="redirectUrl"
                label="Redirect URL"
                rules={[{ required: true, message: 'Redirect URL cannot be blank' }, { type: 'url', message: 'Redirect URL is not a valid URL' }]}>
                <Input onChange={e => setClient({ ...client, redirectUrl: e.target.value })} />
              </Form.Item>

              <Form.Item wrapperCol={{ sm: { span: 18, offset: 6 } }}>
                { client?.iconUrl ? <Avatar size="large" shape="square" src={client?.iconUrl} /> : <Avatar size="large" shape="square" icon={<SettingOutlined />} /> }
              </Form.Item>

              <Form.Item
                name="iconUrl"
                label="Icon URL"
                rules={[{ type: 'url', message: 'Icon URL is not a valid URL' }]}>
                <Input type="url" onChange={e => setClient({ ...client, iconUrl: e.target.value })} />
              </Form.Item>

              { user?.isAdmin ?
                <>
                  <Form.Item
                    name="integration"
                    label="Integration ID">
                    <Input onChange={e => setClient({ ...client, integration: e.target.value })} />
                  </Form.Item>

                  <Form.Item
                    name="credentials"
                    label="Credentials">
                    <Input.TextArea onChange={e => setClient({ ...client, credentials: e.target.value })} />
                  </Form.Item>

                  <Form.Item
                    name="isNeedCodeVerification" wrapperCol={{ md: { span: 18, offset: 6 } }}>
                    <Checkbox checked={client?.isNeedCodeVerification} onChange={(e => setClient({ ...client, isNeedCodeVerification: e.target.checked }))}>Is Need Code Verification</Checkbox>
                  </Form.Item>
                </>
                : '' }

              <Form.Item wrapperCol={{ span: 24 }} style={{ float: 'right' }}>
                <Space>
                  <Popconfirm title="Are you sure want to delete this?" onConfirm={remove}>
                    <Button danger>Delete</Button>
                  </Popconfirm>
                  <Button type="primary" htmlType="submit">Update</Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default ViewApp