import { Scenario, State } from 'db'
import { Error } from '../../../types'
import useCreateScenario from '../useCreateScenario'

type Result = {
  createTemplateScenario: (payload?: Partial<Scenario>) => Promise<Scenario | undefined>,
  createdTemplateScenario?: Scenario,
  creatingTemplateScenario: boolean,
  errorCreateTemplateScenario?: Error
}

export default function useCreateTemplateScenario(): Result {
  const { createScenario, creatingScenario, createdScenario, errorCreateScenario } = useCreateScenario()

  const createTemplateScenario = (payload?: Partial<Scenario>) => {
    return createScenario({
      name: 'Template Scenario',
      public: true,
      states: [{}, {}] as State[],
      ...payload
    })
  }
  return {
    createTemplateScenario,
    createdTemplateScenario: createdScenario,
    creatingTemplateScenario: creatingScenario,
    errorCreateTemplateScenario: errorCreateScenario
  }
}