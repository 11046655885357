import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { Application, FieldSchema, State } from 'db'

export type ThisStateStaticData = Pick<State, 'id' | 'order' | 'scenarioId' | 'bundleId' | 'bundle' | 'executionType'> & {
  triggerActionName: string,
  stateName?: string,
  application?: Application,
  type: 'trigger' | 'action',
  params: Record<string, any> | null,
  paramSchema: FieldSchema[]
}

type ContextValueType = ThisStateStaticData | null

const ThisStateStaticValueContext = createContext<ContextValueType>(null)
const ThisStateStaticValueSetterContext = createContext<Dispatch<SetStateAction<ContextValueType>>>(() => null)

type Props = {
  children: ReactNode,
  value?: ContextValueType
}

const ThisStateStaticValueContextProvider: FC<Props> = ({ children, value }) => {
  const [thisStateStaticValue, setThisStateStaticValue] = useState<ContextValueType>(value || null)

  useEffect(() => {
    if (value) {
      setThisStateStaticValue(value)
    }
  }, [value])

  return (
    <ThisStateStaticValueContext.Provider value={thisStateStaticValue}>
      <ThisStateStaticValueSetterContext.Provider value={setThisStateStaticValue}>
        {children}
      </ThisStateStaticValueSetterContext.Provider>
    </ThisStateStaticValueContext.Provider>
  )
}


export function useThisStateStaticValue(): [ContextValueType, Dispatch<SetStateAction<ContextValueType>>] {
  const valueContext = useContext(ThisStateStaticValueContext)
  const setterContext = useContext(ThisStateStaticValueSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useThisStateStaticValue must be used on child component of ThisStateStaticValueContextProvider component'
  }

  return [valueContext, setterContext]
}

export default ThisStateStaticValueContextProvider