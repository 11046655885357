import { LogCustomCode } from 'db'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { Implikasi } from '../../../service/Implikasi'
import { Error, TableState } from '../../../types'
import { DEFAULT_PAGE_CURRENT } from '../../../util/Constant'
import useFetch from '../../useFetch'

interface ParamsActionTestLogs {
  versionId?: number | string,
  applicationId?: number | string,
  actionId?: number | string
}

export interface ResponseEndpointTestLogs {
  customCodeInvocations: LogCustomCode[],
  success: boolean,
  totalPage: number,
  totalItem: number,
  page: number
}

export interface TestLogs  {
  getLatestLog: () => void,
  fetchingTestLogs: boolean,
  errorFetchTestLogs?: Error,
  uniqueLogs?: Record<string, LogCustomCode>,
  loadMore?: () => void
}

export default function useActionTestLogs(params: ParamsActionTestLogs): TestLogs {
  const { push } = useHistory()
  const routeMatch = useRouteMatch()
  const { applicationId, actionId, versionId } = params
  const [tableState, setTableState] = useState<TableState<LogCustomCode>>({
    pagination: {
      current: 1
    }
  })
  const key = applicationId && actionId ? `/actions/test/logs${JSON.stringify({ ...params, ...tableState })}` : null
  const url = `/application/${applicationId}/action/${actionId}/test/logs`

  const [uniqueLogs, setUniqueLogs] = useState<Record<string, LogCustomCode>>()

  const [uuidNotInList, setUuidNotInList] = useState<string>()
  const { search } = useLocation()
  const keySearchByUuid = uuidNotInList ? `${JSON.stringify(params)}-${uuidNotInList}` : null

  const [counterRefetchLatest, setCounterRefetchLatest] = useState(0)
  const keyRefetchLatest = counterRefetchLatest ? `${JSON.stringify(params)}-refetchLatest${counterRefetchLatest}` : null

  const pageSize = tableState?.pagination?.pageSize || 10
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT

  const { data, error, isValidating } = useFetch<ResponseEndpointTestLogs>(key, {}, {
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          versionId,
          page: currentPage,
          limit: pageSize
        },
      })
      return res.data
    },
    revalidateOnFocus: false
  })

  const fetchLogsByUuid = useFetch<ResponseEndpointTestLogs>(keySearchByUuid, {}, {
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          versionId,
          uuid: uuidNotInList,
          page: DEFAULT_PAGE_CURRENT,
          limit: pageSize
        },
      })
      return res.data
    },
    revalidateOnFocus: false
  })

  const fetchLatestLog = useFetch<ResponseEndpointTestLogs>(keyRefetchLatest, {}, {
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          versionId,
          page: 1,
          limit: 1
        },
      })
      return res.data
    },
    revalidateOnFocus: false
  })

  useEffect(() => {
    if (data?.customCodeInvocations) {
      // search by uuid always have one result
      const resultSearchByUuid = fetchLogsByUuid.data?.customCodeInvocations?.[0]
      const resultLatestLog = fetchLatestLog.data?.customCodeInvocations?.[0]
      let resultFromSearch = {}
      if (resultSearchByUuid) {
        resultFromSearch = {
          [resultSearchByUuid.uuid]: resultSearchByUuid
        }
      }
      if (resultLatestLog && !fetchLatestLog.isValidating) {
        resultFromSearch = {
          ...resultFromSearch,
          [resultLatestLog.uuid]: resultLatestLog
        }
        const params = {
          invocation: `${resultLatestLog.uuid}`
        }
        push({
          pathname: routeMatch.url,
          search: qs.stringify(params)
        })
      }
      const resultLogs = data.customCodeInvocations.reduce((res, curr) => {
        return {
          ...res,
          [curr.uuid]: curr
        }
      }, {})
      const mergeLogs = {
        ...uniqueLogs,
        ...resultFromSearch,
        ...resultLogs,
      }
      setUniqueLogs(mergeLogs)
      const params = qs.parse(search)
      if (params.invocation) {
        const uuid = params.invocation as string
        if (!mergeLogs[uuid]) {
          setUuidNotInList(uuid)
        }
      }
    }
  }, [data?.customCodeInvocations, fetchLogsByUuid.data?.customCodeInvocations, fetchLatestLog.data?.customCodeInvocations, fetchLatestLog.isValidating])

  const loadMore = () => {
    setTableState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: (prevState.pagination?.current || 1) + 1
      }
    }))
  }

  const getLatestLog = () => {
    setCounterRefetchLatest(counterRefetchLatest + 1)
  }

  return {
    getLatestLog,
    fetchingTestLogs: isValidating || fetchLogsByUuid.isValidating || fetchLatestLog.isValidating,
    errorFetchTestLogs: error,
    uniqueLogs,
    loadMore: (tableState.pagination?.current || 1) < (data?.totalPage || 1) ? loadMore : undefined
  }

}