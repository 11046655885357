import { Folder, Scenario } from 'db'
import { RefObject, useRef } from 'react'
import { ConnectDropTarget, useDrop } from 'react-dnd'
import useFolders from '../../../../../../hooks/folder/useFolders'
import useUpdateOrderingFolders from '../../../../../../hooks/folder/useUpdateOrderingFolders'
import useMoveScenarios from '../../../../../../hooks/scenario/useMoveScenarios'

type DropFolderCustomHooks = {
  ref: RefObject<HTMLDivElement>,
  handlerId: any,
  drop: ConnectDropTarget,
  canDrop: boolean,
  isOver: boolean,
  isFolderDragging: boolean,
  movingToFolder: boolean
}

interface DragItem {
  scenarios?: Scenario[],
  folder?: {
    index: number,
    id: number,
    type: string
  }
}

type ParamsForDragFolder = {
  index: number
}

export default function useDropFolder(folderId: number, afterMovingScenarios: () => void, { index }: ParamsForDragFolder): DropFolderCustomHooks {
  const { moveScenarios, movingScenarios } = useMoveScenarios()
  const ref = useRef<HTMLDivElement>(null)
  const { folders } = useFolders()
  const { updateOrderingFolders, isUpdatingOrder } = useUpdateOrderingFolders()

  const onDropFunction = (droppedItem: DragItem) => {
    if (droppedItem.scenarios) {
      moveScenarios(droppedItem.scenarios, folderId || null).finally(() => afterMovingScenarios())
    } else {
      // console.log(index, droppedItem.folder?.index)
      if (!droppedItem.folder || index === droppedItem.folder?.index || index === droppedItem.folder.index - 1) {
        return
      }
      const droppedFolder = folders?.find(folder => folder.id === droppedItem.folder?.id)
      const thisFolder = folders?.find(folder => folder.id === folderId)
      if (droppedItem.folder && droppedFolder && folders) {
        let newFolders: Folder[] = []
        if (!thisFolder) {
          newFolders = [{ ...droppedFolder, displayOrder: 1 } as Folder, ...folders.sort((f1, f2) => (f1.displayOrder || 0) - (f2.displayOrder || 0)).reduce((res: Folder[], curr, index) => {
            if (curr.id !== droppedFolder.id) {
              return [...res, {
                ...curr,
                displayOrder: index + 2
              } as Folder]
            }
            return res
          }, [])]
        } else {
          const dropedOrder = droppedFolder.displayOrder || 0
          const thisOrder = thisFolder.displayOrder || 0
          const isTopToBottom = dropedOrder < thisOrder

          newFolders = folders.sort((f1, f2) => (f1.displayOrder || 0) - (f2.displayOrder || 0)).reduce((res: Folder[], curr, index) => {
            const currentOrder = curr.displayOrder || index + 1
            if (currentOrder > dropedOrder && currentOrder > thisOrder) {
              return res
            }
            if (currentOrder < dropedOrder && currentOrder <= thisOrder) {
              return res
            }
            const displayOrder = curr.id === droppedFolder.id ? isTopToBottom ? thisOrder : thisOrder + 1 :
              currentOrder > thisOrder ? currentOrder + 1 : currentOrder - 1
            return [
              ...res,
              { ...curr, displayOrder } as Folder
            ]
          }, [])
        }
        updateOrderingFolders(newFolders)
        // console.log({
        //   folders,
        //   newFolders
        // })
      }
    }
  }

  const [{ itemType, isOver, canDrop, handlerId }, drop] = useDrop({
    accept: ['folder', 'scenario'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
      itemType: monitor.getItemType()
    }),
    drop: onDropFunction
  })

  return {
    drop, canDrop, isOver, handlerId, ref,
    isFolderDragging: itemType === 'folder',
    movingToFolder: movingScenarios || isUpdatingOrder
  }
}