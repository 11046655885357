import { Layout, Spin } from 'antd'
import React, { FC, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import styled from 'styled-components'
import CustomSider from '../../components/pages/app/index/CustomSider'
import StateTestDataContextProvider from '../../components/pages/app/scenario/edit/StateTestDataContext'
import { useLoggedInUser } from '../../contexts/LoggedInUserContextProvider'
import BillingAndUsagePage from './billingAndUsage'
import DataLoaderPage from './dataLoader'
import ListScenarioPage from './scenario'
import CreateScenarioPage from './scenario/create'
import EditScenarioPage from './scenario/edit'
import FlowScenarioPage from './scenario/flow'
import SettingsPage from './settings'
import TemplateScenarioPage from './templateScenario'

const { Content } = Layout

const EndUserApp: FC = () => {
  const [user] = useLoggedInUser()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { boot } = useIntercom()
  // const [text, setText] = useState<string>('')

  // useEffect(() => {
  //   const path = location.pathname.includes('privacy-policy') ? require('./privacyPolicy/otomatis_privacy_policy.md') :
  //     location.pathname.includes('term-of-service') ? require('./termOfService/otomatis_tos.md') : null
  //   if (path) {
  //     const readmePath = path
  //     fetch(readmePath.default)
  //       .then(response => {
  //         return response.text()
  //       })
  //       .then(text => {
  //         setText(text)
  //       })
  //   } else {
  //     setText('')
  //   }
  // }, [location])

  // add/edit/create sider main menu here

  useEffect(() => {
    if (user && !user.favouriteApps) {
      history.push('/tell-us')
    } else if (user) {
      boot({ customAttributes: { name: user.name } })
    }
  }, [user])

  if (!user) {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin spinning />
      </div>
    )
  }

  return (
    <Layout>
      <CustomSider />
      <Layout>
        <StyledContent>
          {/* /app here */}
          <Switch>
            <Redirect exact from={path} to={`${path}/create-scenario`} />
            <Redirect exact from={`${path}/scenario`} to={`${path}/scenario/all`} />

            <Route path={`${path}/template-scenario`} component={TemplateScenarioPage} />
            <Route path={`${path}/create-scenario`} component={CreateScenarioPage} />
            <Route path={`${path}/data-loader`} component={DataLoaderPage} />
            <Route path={`${path}/scenario/all`} component={ListScenarioPage} />
            <Route path={`${path}/scenario/:scenarioId/edit/:stateId?/:isPath?`}
              render={() =>
                <StateTestDataContextProvider>
                  <EditScenarioPage />
                </StateTestDataContextProvider>}
            // component={EditScenarioPage}
            />
            <Route path={`${path}/scenario/:scenarioId/flow/:stateId?/:isPath?`}
              render={() =>
                <StateTestDataContextProvider>
                  <FlowScenarioPage />
                </StateTestDataContextProvider>}
            // component={EditScenarioPage}
            />
            {/* <Route path={`${path}/pricing-plan`} component={PricingPlanPage} /> */}
            <Route path={`${path}/billing-usage`} component={BillingAndUsagePage} />
            <Route path={`${path}/profile`} />
            <Route path={`${path}/settings`} component={SettingsPage} />
            <Redirect from={`${path}/privacy-policy`} to={'/legal/privacy-policy'} />
            <Redirect path={`${path}/term-of-services`} to={'/legal/term-of-services'} />
          </Switch>
        </StyledContent>
      </Layout>
    </Layout>
  )
}

export default EndUserApp

const StyledContent = styled(Content)`
  max-height: 100vh;
  overflow-y: auto;
`
