import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import Notification from '../../../util/Notification'
import { useRevalidateFetch } from '../../useFetch'

type Result = {
  importingApplication: boolean,
  importApplication: (file?: any, afterSuccess?: () => void) => Promise<any>
}

export default function useImportApplication(userId?: number): Result {
  const [loading, setLoading] = useState(false)
  const { revalidateMyApplications } = useRevalidateFetch()

  return {
    importingApplication: loading,
    importApplication: (file, afterSuccess) => {
      return new Promise((resolve, reject) => {
        setLoading(true)
        if (!file) {
          setLoading(false)
          return
        }
        const formData = new FormData()
        formData.append('upload', file)
        Implikasi.post('/application/import',  formData, {
          headers : {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'type': 'formData'
          }
        })
          .then(() => {
            setLoading(false)
            Notification.success({
              message: 'You have successfully import the application',
            })
            revalidateMyApplications(userId)
            resolve(null)
            afterSuccess?.()
          })
          .catch((e) => {
            setLoading(false)
            reject(e)
          })
      })
    }
  }

}