import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Layout, Row, Spin } from 'antd'
import Axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { OTORISASI_API_URL } from '../../helpers/Constant'

const Logout: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    const loggingOut = async () => {
      await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/logout`, {}, { withCredentials: true })

      // trigger 'logout' key event so event listener can trigger syncLogout on any active tab
      window.localStorage.setItem('logout', Date.now().toString())

      removeCookies()

      history.push('/auth/login')
    }

    loggingOut()
  }, [history])

  return (
    <Layout.Content>
      <Spin spinning>
        <Row>
          <Col span={24} lg={{ span: 6, offset: 9 }} md={{ span: 12, offset: 6 }}>
            <Card title="Logging out">
              Logging out... <LoadingOutlined />
            </Card>
          </Col>
        </Row>
      </Spin>
    </Layout.Content>
  )
}

export default Logout

export const removeCookies = (): void => {
  Cookies.remove('authorization')
  Cookies.remove('authorized')
  Cookies.remove('loggedIn')
}