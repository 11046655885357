import { AxiosPromise } from 'axios'
import { GlobalVariable } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  updatedGlobalVariable?: GlobalVariable,
  updatingGlobalVariable: boolean,
  errorUpdateGlobalVariable?: Error,
  updateGlobalVariable: (applicationId: number, globalVariableId: number, payload: Partial<GlobalVariable>, versionId?: number) => AxiosPromise<{ globalVariable: GlobalVariable }>
}

export default function useUpdateGlobalVariable(): Result {
  const { data, error, isValidating, call } = useApi<{ globalVariable: GlobalVariable }>()

  return {
    updatedGlobalVariable: data?.globalVariable,
    updatingGlobalVariable: isValidating,
    errorUpdateGlobalVariable: error,
    updateGlobalVariable: async (applicationId, globalVariableId, payload, versionId) => {
      const { type, globalVariableValue } = payload
      return await call({
        url: `/application/${applicationId}/globalVariable/${globalVariableId}`,
        method: 'patch',
        params: {
          versionId,
        },
        data: {
          globalVariable: {
            ...payload,
            versionId,
            globalVariableValue: type === 'number' && typeof globalVariableValue?.constant === 'string' ? {
              constant: parseInt(globalVariableValue.constant)
            } : globalVariableValue,
          }
        }
      })
    }
  }
}