import { Col, Input, Row, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { Scenario } from 'db'
import { Dispatch, FC, SetStateAction } from 'react'
import { StyledP1Strong } from '../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../types'
// import { JOBROLES } from '../../../../../util/Constant'

interface Props {
  onSearch: (value: string) => void,
  setTableState: Dispatch<SetStateAction<TableState<Scenario>>>
}

const ScenarioFilterButtons: FC<Props> = ({ setTableState, onSearch }) => {
  // const { publicApplications } = usePublicApplications()

  // wait api
  // const onChangeApplicationFilter = (value: SelectValue) => {
  //   console.log(value)
  //   setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter,
  //     applicationId : value ? [value as Key] : null,
  //   } }))
  // }

  const onChangeScenarioStatusFilter = (value: SelectValue) => {
    let filter: { active?: number[] | null, deletedAt?: number[] | null } = { active: null, deletedAt: null }
    if (value === 'active') {
      filter = { active: [1], deletedAt: null }
    } else if (value === 'inactive') {
      filter = { active: [0], deletedAt: null }
    } else if (value === 'archived') {
      filter = { deletedAt: [1], active: null }
    }
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, ...filter } }))
  }

  // const onChangeJobRoleFilter = (value: SelectValue) => {
  //   console.log(value)
  //   // todo
  // }

  // const onChangeSort = (value: SelectValue) => {
  //   const fieldsMapping = {
  //     recentlyJobsDone: 'lastExecuted',
  //     // mostJobsDone: 'jobCount'
  //   }
  //   setTableState(prevState => {
  //     const sorters = prevState.sorter as SorterResult<Scenario>[] | undefined
  //     const addSorter: SorterResult<Scenario>[] = value ? [{ field: fieldsMapping[value as Key], order: 'descend' }] : []
  //     return {
  //       ...prevState,
  //       sorter: sorters ?
  //         [...sorters.filter(sorter => sorter.field !== 'lastExecuted'), ...addSorter] :
  //         [{ field: fieldsMapping[value as string] || 'createdAt', order: 'descend' }]
  //     }
  //   })
  // }

  return (
    <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '12px' }}>
      <Col flex="auto">
        <Input.Search placeholder="search scenario..." onSearch={onSearch} allowClear  />
      </Col>
      <Col><StyledP1Strong>Filter:</StyledP1Strong></Col>
      {/* <Col>
        <Select
          showSearch
          onChange={onChangeApplicationFilter}
          style={{ width: 200 }}
          placeholder="All Applications"
          allowClear
          options={publicApplications?.map(application => ({ value: application.id, label: application.name }))}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
        </Select>
      </Col> */}
      <Col>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="All Scenario"
          onChange={onChangeScenarioStatusFilter}
          allowClear
          options={[{ value: 'active', label: 'Active Scenarios' }, { value: 'inactive', label: 'Inactive Scenario' }, { value: 'archived', label: 'Archived Scenario' }]}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
        </Select>
      </Col>
      {/* <Col>
        <Select
          showSearch
          style={{ width: 140 }}
          placeholder="All Job Role"
          onChange={onChangeJobRoleFilter}
          allowClear
          options={JOBROLES}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
        </Select>
      </Col> */}
      {/* <Col><div /></Col>
      <Col><StyledP1Strong>Sort:</StyledP1Strong></Col>
      <Col>
        <Select
          showSearch
          style={{ width: 200 }}
          onChange={onChangeSort}
          // placeholder="Recently Added"
          allowClear
          options={[{ value: 'recentlyJobsDone', label: 'Recently Jobs Done' },
            // { value: 'mostJobsDone', label: 'Most Jobs Done' }
          ]}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
        </Select>
      </Col> */}
    </Row>
  )
}

export default ScenarioFilterButtons