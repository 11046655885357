import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import { Error } from '../../../types'
import Notification from '../../../util/Notification'

type Result = {
  authUrlAUthentication: (payload?: any) => Promise<{ url: string }> | undefined,
  result?: { url: string },
  authUrlingAuthentication: boolean,
  errorAuthUrlAuthentication?: Error
}

export default function useAuthenticationAuthUrl(applicationId?: number | string, authenticationId?: number | string): Result {
  const url = authenticationId && applicationId ? `/application/${applicationId}/authentication/${authenticationId}/authUrl` : null
  const [data, setData] = useState()
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState<Error>()

  const post = async (payload?: any) => {
    setIsValidating(true)
    if (url) {
      const authUrl = await Implikasi.post(url, payload)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          Notification.error({
            message: 'Something\'s wrong',
            description: response?.data?.error
          })
          setError(response?.data?.error)
        })
      setIsValidating(false)
      setData(authUrl)
      return authUrl
    }
    setIsValidating(false)
  }

  return {
    result: data,
    authUrlingAuthentication: isValidating,
    errorAuthUrlAuthentication: error,
    authUrlAUthentication: post
  }
}