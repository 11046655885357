import { Invitation } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  updatedInvitation?: Invitation,
  updatingInvitation: boolean,
  errorUpdateInvitation?: Error,
  updateInvitation: (applicationId: number, invitationId: number, payload: Partial<Invitation>) => void
}

export default function useUpdateInvitation(): Result {
  const { data, error, isValidating, call } = useApi<{ invitation: Invitation }>()

  return {
    updatedInvitation: data?.invitation,
    updatingInvitation: isValidating,
    errorUpdateInvitation: error,
    updateInvitation: (applicationId, invitationId, payload) => {
      call({
        url: `/application/${applicationId}/invitation/${invitationId}`,
        method: 'patch',
        data: {
          invitation: payload
        }
      })
    }
  }
}