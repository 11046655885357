import { LogJob, LogTask } from 'db'
import { Error } from '../../../types'
import useFetch from '../../useFetch'


type Result = {
  logTask?: LogTask | LogJob,
  fetchingLogTask: boolean,
  errorFetchLogTasks?: Error
}

export default function useLogTask(params: {logJobId?: number | string, logTaskId?: number | string, expands?: string}): Result {
  const { logJobId, logTaskId, expands } = params
  const url = logTaskId !== undefined && logJobId ?
    logTaskId ?
      `/logJob/${logJobId}/logTask/${logTaskId}?withDeleted=true${expands ? `&expands=${expands}` : ''}` :
      `/logJob/${logJobId}?withDeleted=true${expands ? `&expands=${expands}` : ''}`
    : null
  const { data, error, isValidating } = useFetch<{ logTask?: LogTask, logJob?: LogJob }>(url, {}, { revalidateOnFocus: false })

  return {
    logTask: data?.logTask || data?.logJob,
    fetchingLogTask: isValidating,
    errorFetchLogTasks: error
  }
}