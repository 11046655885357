import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function useSyncLoggedIn(): void {
  const { replace } = useHistory()

  const syncLogin = (event: any) => {
    if (event.key === 'login') {
      console.log('An active tab just triggering sync login... Login from this tab too')
      replace('/app')
    }
  }

  useEffect(() => {
    // event listener for sync logout
    // lostening for event with key 'logout' from storage
    window.addEventListener('storage', syncLogin)

    // clean up the listener
    return () => {
      window.removeEventListener('storage', syncLogin)
      window.localStorage.removeItem('login')
    }
  }, [])

}