import { Error } from '../../types'
import useFetch from '../useFetch'


type Result = {
  url?: string,
  fetchingWebhookUrl: boolean,
  errorFetchWebhookUrl?: Error
}

export default function useWebhookUrl(scenarioId?: number): Result {
  const url = scenarioId ? `/scenario/${scenarioId}/webhookUrl` : null
  const { data, error, isValidating } = useFetch<{ url: string }>(
    url,
    {},
    { revalidateOnFocus: false }
  )

  return {
    url: data?.url,
    fetchingWebhookUrl: isValidating,
    errorFetchWebhookUrl: error
  }
}