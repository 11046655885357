import { MoreOutlined } from '@ant-design/icons'
import { ButtonProps } from 'antd'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { StyledButton } from '../pages/components/StyledComponents'

interface Props extends ButtonProps {

}

const ButtonDragHandle = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <StyledButtonDrag {...props} ref={ref}>
      <StyledMoreOutline />
      <StyledMoreOutline />
    </StyledButtonDrag>
  )
})

export default ButtonDragHandle

const StyledMoreOutline = styled(MoreOutlined)`
  /* transform: rotate(180deg); */
`
const StyledButtonDrag = styled(StyledButton)`
  padding: 0;
  border: 0;
  background: transparent !important;
  cursor: move;
  /* margin-bottom: 5px; */

  & > span + .anticon {
    margin: -7px;
  }
`