import { Invitation, Member } from 'db'
import { useEffect, useState } from 'react'
import { Error } from '../../../types'
import useAllInvitation from '../invitation/useAllInvitation'
import useAllMember from './useAllMember'

export type MemberAndInvitation = Partial<Member> & Partial<Invitation>
type Result = {
  revalidateMembersAndInvitation: () => void,
  membersAndInvitations?: MemberAndInvitation[],
  fetchingAllMembersAndInvitation: boolean,
  errorFetchAllMemberAndInvitation?: Error
}

export default function useAllMemberAndIvitation(applicationId?: number | string): Result {
  const [membersAndInvitations, setMembersAndInvitations] = useState<MemberAndInvitation[]>()
  const { members, fetchingAllMembers, errorFetchAllMember, revalidateMembers } = useAllMember(applicationId)
  const { invitations, fetchingAllInvitations, errorFetchAllInvitation, revalidateInvitations } = useAllInvitation(applicationId)

  useEffect(() => {
    if (members && invitations) {
      setMembersAndInvitations([...members.map(member => ({ ...member, email: member.user?.email })), ...invitations.map(invitation => ({ ...invitation, userId: 0 }))])
    }
  }, [members, invitations])

  return {
    revalidateMembersAndInvitation: () => {
      revalidateInvitations()
      revalidateMembers()
    },
    membersAndInvitations: membersAndInvitations,
    fetchingAllMembersAndInvitation: fetchingAllInvitations || fetchingAllMembers,
    errorFetchAllMemberAndInvitation: errorFetchAllInvitation || errorFetchAllMember
  }
}