import { Dispatch, SetStateAction, useState } from 'react'

type OpenCloseModal = {
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>,
  openModal: () => void,
  closeModal: () => void
}

export default function useOpenCloseModal(): OpenCloseModal {
  const [visible, setVisible] = useState(false)

  return {
    visible,
    setVisible,
    openModal: () => setVisible(true),
    closeModal: () => setVisible(false)
  }
}