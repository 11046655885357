import { FC } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { StyledButton } from '../../../../../../../../pages/components/StyledComponents'
import { aNextStateIdOfThisState, isLastStateSelectorFamily } from '../../../recoil/scenario'
import { isStateActiveAtomFamily, stateStatus } from '../../../recoil/state'
import { useStateValue } from '../../StateValueContextProvider'

const ButtonContinueOrEnd: FC = () => {
  const [stateValue] = useStateValue()
  const { id, scenarioId } = stateValue || {}
  const isLastState = useRecoilValue(isLastStateSelectorFamily(id))
  const setIsActive = useSetRecoilState(isStateActiveAtomFamily(id))
  const nextStateId = useRecoilValue(aNextStateIdOfThisState(id))
  const history = useHistory()
  const status = useRecoilValue(stateStatus(id))
  const { chooseAccount, testTriggerActionSuccess } = status
  const disableContinue = !chooseAccount || !testTriggerActionSuccess

  const onContinue = () => {
    if (nextStateId) {
      history.push(`/app/template-scenario/editor/${scenarioId}/${nextStateId}`)
    }
  }

  return (
    <>
      {
        isLastState ?
          <StyledButton style={{ float: 'right' }} onClick={() => setIsActive(false)}>Close</StyledButton> :
          <StyledButton onClick={onContinue} style={{ float: 'right' }} htmlType="button" type="primary" disabled={disableContinue}>Continue</StyledButton>
      }
    </>
  )
}

export default ButtonContinueOrEnd