import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import useWebhookUrl from '../../../../../../../../hooks/scenario/useWebhookUrl'
import { StyledButton, StyledInfo, StyledLabel, StyledText } from '../../../../../../../../pages/components/StyledComponents'
import { success } from '../../../../../../../../util/colors'
import MarkdownRenderer from '../../../../../../../MarkdownRenderer'
import { stateEventAsTrigger } from '../../../recoil/state'
import { useStateValue } from '../../StateValueContextProvider'


interface Props {
  positionContent: 'top' | 'after-fields-before-test' | 'after-fields-after-test-error' | 'after-fields-after-test-success' | 'test-no-matter'
}

const TestGuidelineContent: FC<Props> = ({ positionContent }) => {
  const [stateValue] = useStateValue()
  const { triggerType, subscribeHttpRequestId, testGuideline: defaultTestGuideline, id, scenarioId } = stateValue || {}
  const event = useRecoilValue(stateEventAsTrigger(id))
  const type = event ? event.type : triggerType
  const testGuideline = event ? event.testGuideline : defaultTestGuideline
  const hasSubscribeHttpRequestId = event ? event.subscribeHttpRequestId : subscribeHttpRequestId
  const { url: webhookUrl } = useWebhookUrl(type === 'hook' ? scenarioId : undefined)

  const stopEventPropagation = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  if (positionContent === 'top') {
    return (
      <>
        {
          webhookUrl ?
            <StyledContainerGuideline onMouseEnter={stopEventPropagation}>
              {
                hasSubscribeHttpRequestId && !testGuideline ?
                  null :
                  <>
                    <StyledLabel className="no-status">Webhook URL for Otomatis Webhook</StyledLabel>
                    <br />
                    <StyledTextUrl
                      copyable={{
                        icon: [<StyledButton icon={<CopyOutlined />}>Copy</StyledButton>, <StyledButton icon={<CheckOutlined style={{ color: success }} />}>Copied to Clipboard</StyledButton>],
                      }}
                    >{webhookUrl}</StyledTextUrl>
                    <br />
                  </>
              }
            </StyledContainerGuideline> : null
        }
      </>
    )
  }
  return (
    <>
      {
        webhookUrl ?
          positionContent === 'after-fields-after-test-success' ?
            <p>This request was sent to {webhookUrl}</p> :
            <>
              {positionContent === 'after-fields-before-test' ? <h5>Guide</h5> : null}
              {
                testGuideline ?
                  <StyledTestGuideline>
                    <MarkdownRenderer>{testGuideline}</MarkdownRenderer>
                  </StyledTestGuideline> :
                  positionContent === 'after-fields-before-test' ?
                    <StyledOl>
                      <StyledLi>Send a request to this webhook URL: <b>{webhookUrl}</b></StyledLi>
                      <StyledLi>Then, test your trigger</StyledLi>
                    </StyledOl> :
                    positionContent === 'after-fields-after-test-error' ?
                      <StyledOl>
                        <StyledLi>Send a new request to this webhook URL: <b>{webhookUrl}</b></StyledLi>
                        <StyledLi>Test your trigger again</StyledLi>
                      </StyledOl> :
                      null
              }
            </>
          :
          testGuideline && positionContent === 'test-no-matter' ?
            <>
              <StyledInfo
                message={
                  <>
                    <h5>Guide</h5>
                    <StyledTestGuideline>
                      <MarkdownRenderer>{testGuideline}</MarkdownRenderer>
                    </StyledTestGuideline>
                  </>
                }
              />
              <br />
            </> : null
      }
    </>
  )

}

export default TestGuidelineContent

const StyledContainerGuideline = styled.div`
  padding: 0 24px;
`
const StyledTextUrl = styled(StyledText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  .ant-typography-copy {
    border-left: 1px solid #D9D9D9;
  }
  /* &::before {
    content: '';
    width: 10px;
    height: 2px;
    background: blue;
  } */
  background: #FFFFFF;
  /* Neutral/5 */
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
`
const StyledOl = styled.ol``

const StyledLi = styled.li`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* Character/Primary .65 */
  color: rgba(0, 0, 0, 0.65);
`

const StyledTestGuideline = styled.div`
  font-family: 'DM Sans', sans-serif;
  /* font-style: normal; */
  /* font-weight: normal; */
  font-size: 14px;
  line-height: 22px;
  /* Character/Primary .65 */
  color: rgba(0, 0, 0, 0.65);

  /* white-space: pre-line; */
  padding-left: 10px;
`
