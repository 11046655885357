import { AxiosPromise } from 'axios'
import { Bundle } from 'db'
import { Error } from '../../types'
import { useApi } from '../useFetch'

type Result = {
  createdBundle?: Bundle,
  creatingBundle: boolean,
  errorCreateBundles?: Error,
  createBundle: (payload: Partial<Bundle>) => AxiosPromise<{ bundle: Bundle }>
}

export default function useCreateABundle(): Result {
  const { data, error, isValidating, call } = useApi<{ bundle: Bundle }>()

  return {
    createdBundle: data?.bundle,
    creatingBundle: isValidating,
    errorCreateBundles: error,
    createBundle: (payload: Partial<Bundle>) => {
      return call({
        url: '/bundle',
        method: 'post',
        data: {
          bundle: payload
        }
      })
    }
  }
}