import { ReloadOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FieldSchema } from 'db'
import { FC, useEffect, useState } from 'react'
import useGetParamSchema from 'src/hooks/application/main/useGetParamSchema'
import { StyledSecondaryButton } from 'src/pages/components/StyledComponents'
import CustomForm from 'src/pages/developer/components/form/CustomForm'
import { convertFormValueToParams, convertParamsToFormValue } from 'src/util/formatter'
import TestSection from '../TestSection'
import useGetState from '../useGetState'
import WebhookURLPart from '../WebhookURLPart'
import StateFormItemMapping from './StateFormItemMapping'

const ParamsField: FC = () => {
  const [form] = useForm()
  const [isOnRefresh, setIsOnrefresh] = useState(false)
  const { state } = useGetState()
  const initialParamSchema = (state?.trigger || state?.action)?.paramSchema
  const stateParams = state?.params
  const isNoDynamic = state?.trigger?.paramSchema?.filter(field => field.paramSchemaFunc || field.getValueFunc && field.allowEditByUser).length === 0
  const isValidAuthentication = (state?.trigger || state?.action)?.authenticationId ? !!state.bundleId : true
  const { paramSchema, getParamSchema, fetchingParamSchema } = useGetParamSchema({
    actionId: state?.actionId,
    triggerId: state?.triggerId,
    applicationId: (state?.trigger || state?.action)?.applicationId,
    bundleId: state?.bundleId || undefined,
    initialParamSchema
  }, !isNoDynamic && isValidAuthentication)
  const [activeParams, setActiveParams] = useState<FieldSchema[] | undefined>()

  useEffect(() => {
    if (state?.id) {
      getParamSchema(stateParams)
      setActiveParams(initialParamSchema)
      form.setFieldsValue(convertParamsToFormValue(convertFormValueToParams({ ...stateParams, ...form.getFieldsValue() }), paramSchema || initialParamSchema))
    }
  }, [stateParams, state?.id])

  useEffect(() => {
    if (paramSchema) {
      form.setFieldsValue(convertParamsToFormValue(convertFormValueToParams({ ...stateParams, ...form.getFieldsValue() }), paramSchema || initialParamSchema))
      setActiveParams(paramSchema || initialParamSchema)
    }
  }, [paramSchema])

  useEffect(() => {
    if (isOnRefresh) {
      setIsOnrefresh(fetchingParamSchema)
    }
  }, [fetchingParamSchema])

  const onFocusOut = (key: string) => {
    // const fieldChanged = initialParamSchema?.find(field => field.key === key)
    // fetch dynamic field if changed field is not from dynamic field
    // if (form.getFieldValue(key) && form.getFieldValue(key) !== stateParams?.[key]) {
    if (form.getFieldValue(key)) {
      if (!getParamSchema({ ...form.getFieldsValue() })) {
        setIsOnrefresh(false)
      }
    }
  }

  return (
    <>
      <WebhookURLPart />
      <CustomForm form={form}>
        <Spin spinning={fetchingParamSchema}>
          {
            activeParams?.map((param, idx) => {
              return (
                <StateFormItemMapping
                  actionId={state?.actionId}
                  bundleId={state?.bundleId || undefined}
                  fetchingParamSchema={fetchingParamSchema}
                  form={form}
                  idx={idx}
                  onFocusOut={onFocusOut}
                  param={param}
                  stateId={state?.id}
                  triggerId={state?.triggerId}
                  initialParamSchema={initialParamSchema}
                  key={param.key}
                />
              )
            })}
        </Spin>
        {
          paramSchema && paramSchema?.filter(field => field.paramSchemaFunc).length > 0 ?
            <StyledSecondaryButton
              htmlType="button"
              onClick={() => {
                setIsOnrefresh(true)
                if (!getParamSchema(form?.getFieldsValue())) {
                  setTimeout(() => {
                    setIsOnrefresh(false)
                  }, 200)
                }
              }}
              icon={<ReloadOutlined />}
              loading={isOnRefresh}
            >
              Refresh Data
            </StyledSecondaryButton> :
            <div />
        }
        <TestSection form={form} type={'params'} />
      </CustomForm>
    </>
  )
}

export default ParamsField