import { CheckOutlined, CopyOutlined, ReloadOutlined } from '@ant-design/icons'
import { Form, Spin } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { useForm } from 'antd/lib/form/Form'
import { Application, FieldSchema } from 'db'
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useStateTest, { ResponseTest } from '../../../../../hooks/scenario/state/useStateTest'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import useAScenarioState from '../../../../../hooks/useAScenarioState'
import { StyledAlert, StyledButton, StyledFooterForm, StyledLabel, StyledSecondaryButton, StyledText } from '../../../../../pages/components/StyledComponents'
import CustomForm from '../../../../../pages/developer/components/form/CustomForm'
import { success } from '../../../../../util/colors'
import { ENABLE_IS_ERROR_STATE } from '../../../../../util/Constant'
import { convertFormValueToParams, convertParamsToFormValue, getFlatDataTest } from '../../../../../util/formatter'
import { isValidArrayMapItem } from '../../../../../util/functions'
import MarkdownRenderer from '../../../../MarkdownRenderer'
import DataResponse, { StyledCard } from '../../history/job/DataResponse'
import { updatingScenarioOrState } from './recoil'
import StateForm from './StateForm'
import { useStateTestData } from './StateTestDataContext'
import TestDataArray from './TestDataArray'
import { useThisStateStaticValue } from './ThisStateStaticValueContext'

interface StateContentFormProps {
  isEnableAuthentication?: boolean,
  hasSubscribeHttpRequestId?: boolean,
  hasDummyHttpRequestId?: boolean,
  isLastState?: boolean,
  toggleStateFormBody: () => void,
  scenarioId: number,
  webhookUrl?: string,
  actionId?: number,
  triggerId?: number,
  testGuideline?: string,
  stateId: number,
  nextStateId?: number,
  stateOrder?: number,
  triggerActionName?: string,
  application?: Application,
  params?: any,
  // setValuesForm: Dispatch<SetStateAction<any | undefined>>,
  examplePayload?: any,
  type: 'trigger' | 'action',
  paramSchema?: FieldSchema[],
  applicationId?: number,
  bundleId?: number,
  setupActionStatus: { chooseAccount: boolean, testTriggerAction: boolean },
  setSetupActionStatus: Dispatch<SetStateAction<{ chooseAccount: boolean, testTriggerAction: boolean, testTriggerActionSuccess: boolean }>>
}
const StateContentForm: FC<StateContentFormProps> = ({ toggleStateFormBody, isLastState, nextStateId, testGuideline, setupActionStatus, isEnableAuthentication, stateId, stateOrder, triggerActionName, application, hasSubscribeHttpRequestId, scenarioId, webhookUrl, paramSchema, params, applicationId, bundleId, type, triggerId, actionId, setSetupActionStatus }) => {
  // initiate ant form
  const [form] = useForm()
  // we need this ref for calling form method inside useEffect, so that form is not necessary as useeffect dependency
  // reference:
  // - https://github.com/ant-design/ant-design/issues/18983 (issue page)
  // - https://github.com/ant-design/ant-design/issues/18983#issuecomment-840816962 (solution)
  const mainForm = useRef(form)
  // set up ref mainform every time form changes (which is every fcking render the form is always re-created by Ant and this should be Ant bug)
  // so that mainForm can be use inside useEffect and the useEffect not running every render
  useEffect(function setMainFormRef() {
    mainForm.current = form
  }, [form])
  // the shit I do for you Ant *sigh... aight let's move on

  // update form value if paramSchema has default value, execute when params doesn't have value yet
  useEffect(() => {
    if (paramSchema && !params) {
      mainForm.current.setFieldsValue(convertParamsToFormValue(params, paramSchema))
    }
  }, [paramSchema, params, bundleId])

  // initializa form value
  useEffect(() => {
    if (params) {
      mainForm.current.setFieldsValue(convertParamsToFormValue(params, paramSchema))
    }
  }, [params, bundleId])

  const [stateTestData, setStateTestData] = useStateTestData()
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const { state } = useAScenarioState(scenarioId, stateId, true)
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)
  const [_, setIsFormValid] = useState<boolean>(false)
  const [counterRefetch, setCounterRefetch] = useState(0)
  const [counterTest, setCounterTest] = useState(0)
  const [isOnRefresh, setIsOnRefresh] = useState(false)
  const history = useHistory()
  const { testState, testingState, errorTestState, resultDataTest } = useStateTest()
  const [thisStaticValue] = useThisStateStaticValue()
  const stateName = thisStaticValue?.stateName

  const onContinue = () => {
    if (nextStateId) {
      history.push(`/app/scenario/${scenarioId}/edit/${nextStateId}`)
    }
  }

  const getAllOptions = () => {
    setCounterRefetch(counterRefetch + 1)
    setIsOnRefresh(true)
  }

  const validateFormFields = () => setIsFormValid(!form.getFieldsError().some(item => item.errors.length > 0))

  const saveTestData = (testData: ResponseTest) => {
    if (testData) {
      const rawTestData: Record<string, any> | (Record<string, any>)[] = typeof testData.error === 'string' ? testData.data || testData : testData.error || testData.data || testData
      let triggerActionTestResult: Record<string, any>

      if (Array.isArray(rawTestData)) {
        triggerActionTestResult = { ...rawTestData[0] }
      } else {
        triggerActionTestResult = { ...rawTestData }
      }

      setStateTestData(prevState => {
        if (!triggerActionTestResult || !stateId || stateOrder === undefined || !triggerActionName || !application) { // meh, eslint go angry without this check
          return { ...prevState }
        }

        const prevStateWithTestData = prevState && prevState[`${stateId}`] ?
          prevState[`${stateId}`] :
          {
            id: stateId,
            order: stateOrder,
            triggerActionName: triggerActionName,
            stateName,
            application: application
          }

        // const stateFlattenTestData: Record<string, any> = flatten(triggerActionTestResult)
        // const flattenTestDataSafe: Record<string, any> = flatten(triggerActionTestResult, { safe: true })

        // const flatTestDataSafe = Object.keys(flattenTestDataSafe).reduce((acc, dataKey) => ({
        //   ...acc,
        //   [dataKey]: {
        //     sample: truncateLongData(flattenTestDataSafe[dataKey]),
        //     value: `${stateId}.${dataKey}`,
        //     label: `${stateOrder + 1}. ${dataKey} ${Array.isArray(flattenTestDataSafe[dataKey]) ? '(array)': ''}`,
        //     appLogoUrl: application?.logoUrl
        //   }
        // }), {})

        // const flattenCodeMirrorTagData = Object.keys(stateFlattenTestData)
        //   .filter(dataKey => typeof stateFlattenTestData[dataKey] !== 'object')
        //   .reduce((acc, dataKey) => ({
        //     ...acc,
        //     [dataKey]: {
        //       sample: truncateLongData(stateFlattenTestData[dataKey]),
        //       value: `${stateId}.${dataKey}`,
        //       label: `${stateOrder + 1}. ${dataKey}`,
        //       appLogoUrl: application?.logoUrl
        //     }
        //   }), flatTestDataSafe)

        // console.log('update test data: ', { [`${stateId}`]: { ...prevStateWithTestData, testData: { ...triggerActionTestResult }, flatTestData: { ...flattenCodeMirrorTagData } } })

        return {
          ...prevState,
          [`${stateId}`]: {
            ...prevStateWithTestData,
            realTestData: {
              ...testData,
              success: !testData.error
            },
            testData: triggerActionTestResult,
            flatTestData: getFlatDataTest({ testData: triggerActionTestResult, stateId, stateOrder, application })
          }
        }
      })
    }
  }


  const onTest = async () => {
    // update state first before doing testing
    updateAScenarioState(scenarioId, stateId, { params: convertFormValueToParams(form.getFieldsValue()) })
      .then(async () => {
        const test = await testState(scenarioId, stateId).then(({ data }) => data)
        setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
        setCounterTest(counted => counted + 1)
        if (test) {
          // console.log('test data on statecontentform: ', test)
          saveTestData(test)
          if (test.success === false) {
            setSetupActionStatus(prevState => ({ ...prevState, testTriggerAction: true, testTriggerActionSuccess: false }))
          } else {
            setSetupActionStatus(prevState => ({ ...prevState, testTriggerAction: true, testTriggerActionSuccess: true }))
          }
        }
        // revalidateAScenarioState(scenarioId, stateId)
      })
  }

  const stopEventPropagation = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onRefetchOptions = (isRefetch?: boolean) => {
    if (!isRefetch && counterRefetch > 0 && isOnRefresh) {
      setIsOnRefresh(false)
    }
  }


  return (
    <>
      {
        webhookUrl ?
          <StyledForm onMouseEnter={stopEventPropagation}>
            {
              hasSubscribeHttpRequestId && !testGuideline ?
                null :
                <>
                  <StyledLabel className="no-status">Webhook URL for Otomatis Webhook</StyledLabel>
                  <br />
                  <StyledTextUrl
                    copyable={{
                      icon: [<StyledButton icon={<CopyOutlined />}>Copy</StyledButton>, <StyledButton icon={<CheckOutlined style={{ color: success }} />}>Copied to Clipboard</StyledButton>],
                    }}
                  >{webhookUrl}</StyledTextUrl>
                  <br />
                </>
            }
          </StyledForm> : null
      }
      {
        form ?
          <Spin spinning={isOnRefresh}>
            <StateForm
              setOnRefetchOptions={onRefetchOptions}
              isEnableAuthentication={isEnableAuthentication}
              form={form}
              triggerId={triggerId}
              actionId={actionId}
              applicationId={applicationId}
              bundleId={bundleId}
              counterRefetch={counterRefetch}
              initialParamSchema={paramSchema}
              scenarioId={scenarioId}
              stateId={stateId}
              formAntProps={{
                name: `${stateId}`, // form name will use state.id
                // onClick: stopEventPropagation,
                style: { padding: '0 24px' },
                onFieldsChange: validateFormFields,
                // onFinish: onTest
              }}
            >
              {
                webhookUrl ?
                  <>
                    {
                      counterTest == 0 ?
                        <StyledInfo
                          message={
                            <>
                              <h5>Guide</h5>
                              {
                                testGuideline ?
                                  <StyledTestGuideline>
                                    <MarkdownRenderer>{testGuideline}</MarkdownRenderer>
                                  </StyledTestGuideline> :
                                  <StyledOl>
                                    <StyledLi>Send a request to this webhook URL: <b>{webhookUrl}</b></StyledLi>
                                    <StyledLi>Then, test your trigger</StyledLi>
                                  </StyledOl>
                              }
                            </>
                          }
                        />
                        : null
                    }
                    {
                      // if success
                      counterTest > 0 && resultDataTest?.success === true ?
                        <StyledAlert
                          className="mb-4"
                          type="success"
                          message={
                            <>
                              <h5>We found a request!</h5>
                              <p>This request was sent to {webhookUrl}</p>
                            </>
                          } />
                        :
                        // if error
                        counterTest > 0 && (resultDataTest?.error || resultDataTest?.success === false || errorTestState) ?
                          <StyledAlert
                            className="mb-4"
                            type="error"
                            message={
                              <>
                                <h5>We couldn’t find a request</h5>
                                {
                                  testGuideline ?
                                    <StyledTestGuideline>
                                      <MarkdownRenderer>{testGuideline}</MarkdownRenderer>
                                    </StyledTestGuideline> :
                                    <StyledOl>
                                      <StyledLi>Send a new request to this webhook URL: <b>{webhookUrl}</b></StyledLi>
                                      <StyledLi>Test your trigger again</StyledLi>
                                    </StyledOl>
                                }
                              </>
                            }
                          /> : null
                    }
                  </>
                  :
                  <>
                    {
                      testGuideline ?
                        <>
                          <StyledInfo
                            message={
                              <>
                                <h5>Guide</h5>
                                <StyledTestGuideline>
                                  <MarkdownRenderer>{testGuideline}</MarkdownRenderer>
                                </StyledTestGuideline>
                              </>
                            }
                          />
                          <br />
                        </> : null
                    }
                  </>
              }
              <CustomStyledFooterForm>
                <Form.Item shouldUpdate>
                  {
                    paramSchema && paramSchema?.filter(field => field.paramSchemaFunc).length > 0 ?
                      <StyledSecondaryButton
                        htmlType="button"
                        onClick={getAllOptions}
                        icon={<ReloadOutlined />}
                        loading={isOnRefresh}
                      >
                        Refresh Data
                      </StyledSecondaryButton> :
                      <div />
                  }
                </Form.Item>
                <Form.Item shouldUpdate>
                  {({ getFieldsError }) => {
                    const errors = getFieldsError().reduce((res, curr) => {
                      const errorExceptRequired = curr.errors.filter(err => !err.includes('required'))
                      return {
                        ...res,
                        [curr.name[0]]: [...res[curr.name[0]] || [], ...errorExceptRequired]
                      }
                    }, {})
                    const isFormValid = Object.keys(errors).filter(key => errors[key].length > 0).length === 0

                    return (
                      stateTestData?.[stateId]?.testData ?
                        <StyledAlert
                          action={
                            <StyledButton size="small" onClick={onTest} disabled={(() => {
                              if (paramSchema) {
                                return !isFormValid || paramSchema
                                  .filter(field => !field.getValueFunc && !field.allowEditByUser)
                                  .reduce((acc: boolean, ps) => {
                                    const notValidArrayMap = !isValidArrayMapItem({ param: ps, value: form.getFieldValue(ps.key), stateTestData: stateTestData })
                                    // console.log('tes', notValidArrayMap)
                                    return ps.required && form.getFieldValue(ps.key) === '' || notValidArrayMap || acc
                                  }, false)
                              }
                              return false // by default false
                            })()}
                            loading={testingState || updatingAScenarioState} htmlType="button"
                            >
                              Retest
                            </StyledButton>
                          }
                          type={stateTestData?.[stateId]?.realTestData?.success === false ? 'error' : 'success'}
                          showIcon
                          message={`${type === 'action' ? 'Action' : 'Trigger'} test ${stateTestData?.[stateId]?.realTestData?.success === false ? 'error' : 'success'}`} /> :
                        <StyledButton
                          htmlType="button"
                          type="primary"
                          onClick={onTest}
                          loading={testingState || updatingAScenarioState}
                          disabled={(() => {
                            if (paramSchema) {
                              return !isFormValid || paramSchema
                                .filter(field => !field.getValueFunc && !field.allowEditByUser)
                                .reduce((acc: boolean, ps) => ps.required && form.getFieldValue(ps.key) === '' ||
                                !isValidArrayMapItem({ param: ps, value: form.getFieldValue(ps.key), stateTestData: stateTestData }) || acc, false)
                            }
                            return false // by default false
                          })()}
                        >
                          Test {type === 'trigger' ? 'Trigger' : 'Action'}
                        </StyledButton>
                    )

                  }}
                </Form.Item>
              </CustomStyledFooterForm>
            </StateForm>

            <StyledContainerTestSection onClick={stopEventPropagation}>
              {
                Array.isArray(stateTestData?.[stateId]?.realTestData?.data) && !(state?.trigger && state.trigger.type === 'schedule') ?
                  <TestDataArray /> :
                  stateTestData?.[stateId]?.testData ?
                    <StyledCard>
                      <DataResponse number={stateOrder !== undefined ? stateOrder + 1 : undefined} data={stateTestData?.[stateId]?.testData} logoUrl={application?.logoUrl} />
                    </StyledCard> :
                    null
              }
              <br />

              <div className="float-right flx items-center">
                {
                  !ENABLE_IS_ERROR_STATE || type === 'trigger' ? null :
                    <>
                      Is Error State:
                      <Checkbox className="mx-3" defaultChecked={state?.isErrorState} onChange={(e) => {
                        updateAScenarioState(scenarioId, stateId, { isErrorState: e.target.checked }).then(() => {
                          setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
                        })
                      }} />
                    </>

                }
                {
                  isLastState ?
                    <StyledButton style={{ float: 'right' }} onClick={toggleStateFormBody}>Close</StyledButton> :
                    <StyledButton onClick={onContinue} style={{ float: 'right' }} htmlType="button" type="primary" disabled={!setupActionStatus || !stateTestData?.[stateId]?.testData}>Continue</StyledButton>
                }
              </div>
            </StyledContainerTestSection>
          </Spin> : null
      }
    </>
  )
}

export default StateContentForm

const CustomStyledFooterForm = styled(StyledFooterForm)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

const StyledForm = styled(CustomForm)`
  padding: 0 24px;
  overflow: hidden;
`

const StyledContainerTestSection = styled.div`
  padding: 0 24px;
  overflow: hidden;
`

const StyledTextUrl = styled(StyledText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  .ant-typography-copy {
    border-left: 1px solid #D9D9D9;
  }
  /* &::before {
    content: '';
    width: 10px;
    height: 2px;
    background: blue;
  } */
  background: #FFFFFF;
  /* Neutral/5 */
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
`
const StyledInfo = styled(StyledAlert)`
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  margin-bottom: 16px;
`
const StyledOl = styled.ol``

const StyledLi = styled.li`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* Character/Primary .65 */
  color: rgba(0, 0, 0, 0.65);
`

const StyledTestGuideline = styled.div`
  font-family: 'DM Sans', sans-serif;
  /* font-style: normal; */
  /* font-weight: normal; */
  font-size: 14px;
  line-height: 22px;
  /* Character/Primary .65 */
  color: rgba(0, 0, 0, 0.65);

  /* white-space: pre-line; */
  padding-left: 10px;
`
