import { Collapse } from 'antd'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useAScenarioState from 'src/hooks/useAScenarioState'
import styled from 'styled-components'
import ChooseStateBundle from '../../../edit/ChooseStateBundle'
import ThisStateStaticValueContextProvider from '../../../edit/ThisStateStaticValueContext'
import { selectedStateAtomFamily } from '../recoil'
import ConditionField from './ConditionField'
import EventForm from './EventForm'
import ParamsField from './ParamsField'

interface Props {
}

const StateDetailAndForm: FC<Props> = () => {
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const stateRecoil = useRecoilValue(selectedStateAtomFamily({ scenarioId }))
  const { state } = useAScenarioState(Number(scenarioId), stateRecoil?.id, true)
  const authenticationId = (state?.trigger || state?.action)?.authenticationId
  const bundleId = state?.bundleId

  if (!stateRecoil) {
    return null
  }
  return (
    <Container>
      <Collapse accordion>
        <Collapse.Panel header="Event Form" key="1">
          <Wrapper>
            <EventForm />
          </Wrapper>
        </Collapse.Panel>
        {
          state && state.id && authenticationId ?
            <Collapse.Panel header="Account Form" key="2">
              <ChooseStateBundle openStateForm={() => {}} scenarioId={Number(scenarioId)} selectedAccountId={bundleId || undefined} state={state}
                stateId={state?.id} applicationId={(state?.trigger || state?.action)?.applicationId}
              />
            </Collapse.Panel> :
            null
        }
        {
          bundleId || !authenticationId ?
            <Collapse.Panel header="Config Form" key="3">
              <Wrapper>
                {
                  state ?
                    <ThisStateStaticValueContextProvider value={{ ...state,
                      triggerActionName: (state.trigger || state.action)?.name || '',
                      stateName: state.name,
                      application: (state.trigger || state.action)?.application,
                      type: state.action ? 'action' : 'trigger',
                      params: state.params || null,
                      paramSchema: (state.trigger || state.action)?.paramSchema || []
                    }}>
                      {
                        state?.action?.type === 'path' || state.action?.type === 'filter' ?
                          <ConditionField /> :
                          <ParamsField />
                      }
                    </ThisStateStaticValueContextProvider>
                    :
                    null
                }
              </Wrapper>
            </Collapse.Panel> :
            null
        }

      </Collapse>
    </Container>
  )
}

export default StateDetailAndForm

const Container = styled.div`
  position: absolute;
  left: 10px;
  top: 0;
  padding-top: 10px;
  z-index: 100;
  overflow-y: auto;
  max-height: calc(100vh);
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`
const Wrapper = styled.div`
  position: relative;
  padding: 8px;
  border: 1px solid gray;
  border-radius: 8px;
  margin-bottom: 8px;
  background: #FFF;
  resize: both;
  overflow: auto;
`