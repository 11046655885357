import { Authentication, FieldSchema } from 'db'
import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'


type ContextValueType = Partial<Authentication> & {
  activeEditFieldForm?: FieldSchema & {
    idx?: number
  },
  onAddFieldForm?: boolean
}

const SharedValuesContext = createContext<ContextValueType | null>(null)
const SharedValuesSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode
}

const SharedValuesContextProvider: FC<Props> = ({ children }) => {
  const [sharedValues, setSharedValues] = useState<ContextValueType | null>(null)

  return (
    <SharedValuesContext.Provider value={sharedValues}>
      <SharedValuesSetterContext.Provider value={setSharedValues}>
        {children}
      </SharedValuesSetterContext.Provider>
    </SharedValuesContext.Provider>
  )
}


export function useSharedValues(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(SharedValuesContext)
  const setterContext = useContext(SharedValuesSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useSharedValues must be used on child component of SharedValuesContextProvider component'
  }

  return [valueContext, setterContext]
}

export default SharedValuesContextProvider