import { useState } from 'react'
import { ChildStateType } from '../../../components/pages/app/scenario/edit/StateTestDataContext'
import useAScenarioState from '../../useAScenarioState'
import { useRevalidateFetch } from '../../useFetch'
import useCreateAScenarioState from './useCreateAScenarioState'
import useUpdateAScenarioState from './useUpdateAScenarioState'

type DuplicateState = {
  duplicateAScenarioState: () => void,
  duplicatingAScenarioState: boolean
}
export default function useDuplicateAScenarioState(scenarioId: number, stateId: number, nextStateIds?: ChildStateType[]): DuplicateState {
  const { createAScenarioState } = useCreateAScenarioState()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const [duplicatingAScenarioState, setIsDuplicatingAScenarioState] = useState(false)
  const { state } = useAScenarioState(scenarioId, stateId, true)

  const duplicateAScenarioState = () => {
    if (!state) {
      return
    }
    const { action, triggerId, actionId, order, name, bundleId, params, conditionGroupOrder, conditions, stateConditionId, id, childrenIds, testData } = state
    setIsDuplicatingAScenarioState(true)
    createAScenarioState(scenarioId, {
      triggerId,
      actionId,
      order: order + 1,
      name: `Copy of ${name || action?.name}`,
      bundleId,
      params,
      conditionGroupOrder,
      conditions,
      stateConditionId,
      parentId: id,
      childrenIds,
      testData
    }).then(({ data }) => {
      const newStateId = data.state.id
      updateAScenarioState(scenarioId, id, {
        childrenIds: [newStateId]
      }).then(() => {
        if (nextStateIds && newStateId) {
          Promise.all(
            nextStateIds.map(async (state) =>
              await updateAScenarioState(scenarioId, state.id, {
                parentId: childrenIds?.includes(state.id) ? newStateId : undefined,
                order: state.order + 1,
                conditionGroupOrder: state.conditionGroupOrder ? state.conditionGroupOrder + 1 : state.conditionGroupOrder
              }).then(({ data }) => data)
            )
          ).then(() => {
            revalidateAScenario(scenarioId)
            revalidateAScenarioState(scenarioId, stateId)
            for (const state of nextStateIds) {
              revalidateAScenarioState(scenarioId, state.id)
            }
          }).finally(() => {
            setIsDuplicatingAScenarioState(false)
          })
        }
      }).catch(() => {
        setIsDuplicatingAScenarioState(false)
      })
    }).catch(() => {
      setIsDuplicatingAScenarioState(false)
    })
  }

  return {
    duplicateAScenarioState,
    duplicatingAScenarioState
  }
}