import { Divider, Row, Space } from 'antd'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { StyledAlert, StyledButton, StyledSecondaryButton } from '../../../../../pages/components/StyledComponents'
import DataResponse, { StyledCard } from '../../history/job/DataResponse'
import { useStateTestData } from './StateTestDataContext'

interface Props {
  stateId?: number,
  loading?: boolean,
  stateOrder?: number,
  logoApplicationUrl?: string,
  onClickTest: () => void
}
const StateTestSection: FC<Props> = ({ stateId, loading, onClickTest, stateOrder, logoApplicationUrl }) => {
  const [stateTestData] = useStateTestData()
  const [showTest, setShowTest] = useState(false)

  return (
    <>
      <Divider />
      <StyledContainerTestButton gutter={[16, 16]} justify={'space-between'} align="middle">
        {
          stateId && stateTestData?.[stateId]?.testData ?
            <StyledSecondaryButton onClick={() => setShowTest(!showTest)}>{showTest ? 'Hide Sample' : 'Show Sample'}</StyledSecondaryButton> :
            <StyledSecondaryButton>Skip Test</StyledSecondaryButton>
        }
        <Space>
          <p style={{ margin: 0 }}>Let’s test this code</p>
          {
            stateId && stateTestData?.[stateId]?.testData ?
              <StyledAlert
                action={
                  <StyledButton size="small" onClick={onClickTest}
                    loading={loading} className="test"
                  >
                    Retest
                  </StyledButton>
                }
                style={{ float: 'right' }}
                type={stateTestData?.[stateId]?.testData?.error ? 'error' : 'success'}
                showIcon
                message={stateTestData?.[stateId]?.testData?.error ? 'Test Code Failed' : 'Test Code Success'} /> :
              <StyledButton
                style={{ float: 'right' }}
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={onClickTest}
                className="test"
              >
                Test
              </StyledButton>
          }
        </Space>
      </StyledContainerTestButton>
      {
        stateId && showTest ?
          <>
            <br />
            <StyledCard>
              <DataResponse number={stateOrder !== undefined ? stateOrder + 1 : undefined} data={stateTestData?.[stateId]?.testData} logoUrl={logoApplicationUrl} />
            </StyledCard>
          </>
          :
          null
      }
    </>
  )
}

export default StateTestSection

const StyledContainerTestButton = styled(Row)`
  padding-left: 8px;
  padding-right: 8px;
`