import { Col, Row, Tabs } from 'antd'
import { FC } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import useInvoices from '../../../../hooks/payment/useInvoices'
import useStatusPayment from '../../../../hooks/payment/useStatusPayment'
import BarNavigation from './BarNavigation'
import LogJobTable from './LogJobTable'
import SummaryBillingCard from './SummaryBillingCard'
import SummaryTable from './SummaryTable'
import TaskTable from './TaskTable'
import moment from 'moment'

const UsageHistory: FC = () => {
  const { invoices } = useInvoices()
  const { invoiceId } = useParams<{ invoiceId?: string }>()
  const { result } = useStatusPayment()

  const active = invoiceId ? invoices?.find(invoice => invoice.id === invoiceId) : undefined
  const start = invoiceId ? (active?.period_start || 0) * 1000 : (result?.subscription?.latestInvoice.period_end || 0) * 1000
  const end = invoiceId ? (active?.period_end || 0) * 1000 : moment((result?.subscription?.latestInvoice?.period_end || 0) * 1000).add({ month: 1 }).valueOf()

  return (
    <StyledUsageHistory>
      <BarNavigation />
      <SummaryBillingCard active={active} />
      <StyledRow gutter={[16, 16]}>
        <Col span={24}>
          <Tabs>
            <Tabs.TabPane key="1" tab="Job Executed">
              <LogJobTable start={start} end={end} />
            </Tabs.TabPane>
            <Tabs.TabPane key="2" tab="Task Used">
              <TaskTable start={start} end={end} />
            </Tabs.TabPane>
            <Tabs.TabPane key="3" tab="Summary">
              <SummaryTable active={active} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </StyledRow>
    </StyledUsageHistory>
  )
}

export default UsageHistory

const StyledUsageHistory = styled.div`
  padding: 16px 24px;
`

const StyledRow = styled(Row)`
  padding: 16px;
  background: #FFF;
  margin-top: 8px;
`