import { Select, SelectProps, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import useDynamicOptions, { ParamsDynamicOption } from '../../../../hooks/application/main/useDynamicOptions'
import useTrigger from '../../../../hooks/application/trigger/useTrigger'

interface Props extends SelectProps<any> {
  paramsDynamicOption: ParamsDynamicOption,
  disabled?: boolean
}

const SelectWithDynamicOption: FC<Props> = ({ paramsDynamicOption, disabled, ...props }) => {
  const { fetchDynamicOption, fetchingDynamicOptions, dynamicOptions } = useDynamicOptions()
  const [page, setPage] = useState(0)
  const [options, setOptions] = useState<any>({})
  const [isNoLoadMore, setIsNoLoadMore] = useState(false)
  const { trigger } = useTrigger(paramsDynamicOption.applicationId, paramsDynamicOption.triggerId)
  const isUsingPagination = trigger?.isUsingPagination

  useEffect(() => {
    if (dynamicOptions) {
      if (isUsingPagination) {
        if (dynamicOptions.length === 0) {
          setIsNoLoadMore(true)
        } else {
          setIsNoLoadMore(false)
        }
        const newOptions = dynamicOptions.reduce((acc, curr: any) => {
          return {
            ...acc,
            [curr.value]: {
              value: curr.value,
              label: curr.label
            }
          }
        }, options)
        setOptions(newOptions)
      } else {
        setOptions(dynamicOptions)
      }
    }
  }, [dynamicOptions])

  const onFocus = () => {
    fetchDynamicOption({
      ...paramsDynamicOption,
      bundles: {
        ...paramsDynamicOption.bundles,
        meta: {
          page: page
        }
      }
    })
  }

  const onClickLoadMore = () => {
    fetchDynamicOption({
      ...paramsDynamicOption,
      bundles: {
        ...paramsDynamicOption.bundles,
        bundle: {
          ...paramsDynamicOption.bundles.bundle,
          meta: {
            page: page + 1
          }
        }
      }
    })
    setPage(page + 1)
  }

  return (
    <Select
      {...props}
      dropdownRender={(menu) => {
        return (
          <Spin spinning={fetchingDynamicOptions}>
            {menu}
            {isNoLoadMore ? null : isUsingPagination ? <a style={{ paddingLeft: 12 }} onClick={onClickLoadMore}>Load More</a> : null}
          </Spin>
        )
      }}
      onFocus={onFocus}
      disabled={disabled} options={Object.keys(options).map((key) => ({ value: options[key]?.value || key, label: options[key]?.label }))} loading={fetchingDynamicOptions} showSearch filterOption={(input, option) =>
        (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } />
  )
}

export default SelectWithDynamicOption