import { Tooltip } from 'antd'
import { Trigger } from 'db'
import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { ResponseTest } from '../../../../../../hooks/scenario/state/useStateTest'
import { StyledAlert } from '../../../../../../pages/components/StyledComponents'
import { executionTypeAtomFamily } from './testDataArrayRecoil'

interface Props {
  thisStateRealTestDataFromDB?: ResponseTest,
  triggerType?: Trigger['type'],
  stateId?: number
}
const AlertArrayInfo: FC<Props> = ({ thisStateRealTestDataFromDB, triggerType, stateId }) => {
  const executionType = useRecoilValue(executionTypeAtomFamily(stateId || 0))
  return (
    Array.isArray(thisStateRealTestDataFromDB?.data) && triggerType !== 'schedule' && (executionType === 'default' || !executionType) ?
      <Tooltip overlayInnerStyle={{ width: 'max-content', maxWidth: '50vw' }} placement="top" title="When you return an array of items out of trigger/action, next actions will be executed separately for each of items">
        <StyledAlert
          style={{ fontSize: '14px', marginTop: '12px' }}
          className="w-full text-center"
          type="info"
          message={
            <>
              <strong>Multi-item Execution ✅ </strong>
              <span>Otomatis will execute next actions for each of Item in the array as separated actions</span>
            </>
          }
        />
      </Tooltip>
      :
      null

  )
}

export default AlertArrayInfo