import { State } from 'db'
import { useState } from 'react'
import { useParams } from 'react-router'
import useUpdateAScenarioState from '../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../hooks/useFetch'
import { useStateChildsData } from '../StateTestDataContext'

type MoveStateHook = {
  onDrop: (fromState: State, state: State) => void,
  updatingOrderStates: boolean
}

type PositionChecking = {
  isMoveFromNoBranchToBranch: boolean,
  isMoveFromBranchToBranch: boolean,
  isMoveFromBranchToNoBranch: boolean,
  isFromStateAreChildsOfToState: boolean,
  isToStateAreChildsOfFromState: boolean,
  isChildOfOther: boolean
}

export default function useMoveState(states: State[] | undefined): MoveStateHook {
  const [updatingOrderStates, setUpdatingOrderStates] = useState(false)
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const [nextStatesAll] = useStateChildsData()

  const checkPositionOfFromAndToState = (fromState: State, toState: State): PositionChecking => {
    const { id: fromId, conditionGroupOrder: fromConditionGroupOrder, stateConditionId: fromStateConditionId } = fromState
    const { id, conditionGroupOrder, stateConditionId } = toState

    const allChildsOfFromState = nextStatesAll?.[fromId]
    const allChildsOfToState = nextStatesAll?.[id]

    const isFromStateAreChildsOfToState = allChildsOfToState?.findIndex(currState => currState.id === fromId) !== -1
    const isToStateAreChildsOfFromState = allChildsOfFromState?.findIndex(currState => currState.id === id) !== -1

    const compareIdState = conditionGroupOrder ? id : stateConditionId
    const compareIdFROM = fromConditionGroupOrder ? fromState.id : fromStateConditionId

    const isMoveFromBranchToNoBranch = Boolean(!compareIdState && compareIdFROM)
    const isMoveFromBranchToBranch = Boolean(compareIdState && compareIdFROM && compareIdFROM !== compareIdState)
    const isMoveFromNoBranchToBranch = Boolean(compareIdState && !compareIdFROM)

    return {
      isMoveFromNoBranchToBranch,
      isMoveFromBranchToBranch,
      isMoveFromBranchToNoBranch,
      isFromStateAreChildsOfToState,
      isToStateAreChildsOfFromState,
      isChildOfOther: isFromStateAreChildsOfToState || isToStateAreChildsOfFromState
    }
  }

  const updatesOntoChilds = (params: PositionChecking & { states: State[], toChilds: number[] | undefined | null, toState: State, fromState: State
  }) => {
    const { toState, toChilds, fromState, states, isMoveFromBranchToBranch, isMoveFromBranchToNoBranch, isToStateAreChildsOfFromState } = params
    const { id: toStateId, order: toOrder } = toState
    const { id: fromStateId, parentId: fromParentId, order: fromOrder, childrenIds: fromChildrenIds } = fromState

    // console.log('update on to state\'s childs', toChilds)

    if (toChilds && toChilds?.length > 0) {
      const result = toChilds?.reduce((res: Record<number, Partial<State>>, stateId) => {
        const currState = states.find(s => stateId === s.id)
        if (currState) {
          const { id, order, conditionGroupOrder, parentId, childrenIds } = currState
          if (id === fromStateId) {
            return res
          }
          const newOrder = isMoveFromBranchToBranch && !isToStateAreChildsOfFromState ? order + 1 :
            fromOrder < order && toOrder < order && isToStateAreChildsOfFromState ? order :
              fromOrder < toOrder ? order -1 :
                order + 1

          let childs = {}
          if (currState.id === fromStateId || order > toOrder && order > fromOrder && !isMoveFromBranchToBranch && !isMoveFromBranchToNoBranch) {
            childs = {}
          } else {
            childs = {
              ...updatesOntoChilds({ ...params, toChilds: childrenIds })
            }
          }

          return {
            ...res,
            [stateId]: {
              childrenIds: id === fromParentId ? fromChildrenIds : childrenIds,
              parentId: parentId === fromStateId ? fromParentId :
                parentId === toStateId ? fromStateId : parentId,
              order: newOrder,
              conditionGroupOrder: conditionGroupOrder ? newOrder : conditionGroupOrder
            },
            ...childs
          }
        }
        return res
      }, {})

      return {
        ...result,
      }
    }
  }

  const updatesOnChildsOfFromState = (params: PositionChecking &{ states: State[], toChilds: number[] | undefined | null, toState: State, fromState: State  }) => {
    const { toState, toChilds, fromState, states, isMoveFromBranchToBranch, isMoveFromNoBranchToBranch, isFromStateAreChildsOfToState } = params
    const { id: toStateId, order: toOrder } = toState
    const { id: fromStateId, parentId: fromParentId, order: fromOrder, childrenIds: fromChildrenIds } = fromState
    // console.log('update on from state\'s childs', toChilds)
    if (toChilds && toChilds?.length > 0) {
      const result = toChilds?.reduce((res: Record<number, Partial<State>>, stateId) => {
        const currState = states.find(s => stateId === s.id)
        if (currState) {
          const { id, order, conditionGroupOrder, parentId, childrenIds } = currState
          if (id === toStateId || id === fromParentId) {
            return res
          }
          const newOrder = !isFromStateAreChildsOfToState && (isMoveFromBranchToBranch || isMoveFromNoBranchToBranch) ? order - 1 :
            fromOrder < order && toOrder < order || isFromStateAreChildsOfToState ? order :
              fromOrder < toOrder ? order - 1 : order + 1

          let childs = {}

          if (stateId === toStateId || order > toOrder && order > fromOrder && !isMoveFromBranchToBranch && !isMoveFromNoBranchToBranch) {
            childs = {}
          } else {
            childs = {
              ...updatesOnChildsOfFromState({ ...params, toChilds: childrenIds })
            }
          }
          return {
            ...res,
            [stateId]: {
              childrenIds: id === fromParentId ? fromChildrenIds : childrenIds,
              parentId: parentId === fromStateId ? fromParentId :
                parentId === toStateId ? fromStateId : parentId,
              order: newOrder,
              conditionGroupOrder: conditionGroupOrder ? newOrder : conditionGroupOrder
            },
            ...childs
          }
        }
        return res
      }, {})

      return {
        ...result,
      }
    }
  }

  const updatesFromStateChildsPath = (params: PositionChecking & { baseOrder: number, states: State[], childs: number[] | undefined | null, toState: State, fromState: State  }) => {
    const { toState, childs, fromState, states, baseOrder, isMoveFromBranchToBranch } = params
    const { id: toStateId, order: toOrder } = toState
    const { id: fromStateId, parentId: fromParentId, order: fromOrder } = fromState
    // console.log('update on to state\'s path childs', childs)
    if (childs && childs?.length > 0) {
      const result = childs?.reduce((res: Record<number, Partial<State>>, stateId) => {
        const currState = states.find(s => stateId === s.id)
        if (currState) {
          const { id, order, childrenIds, parentId } = currState
          if (id === toStateId || id === fromParentId) {
            return res
          }
          let childs = {}
          if (stateId === fromStateId || order > toOrder && order > fromOrder && !isMoveFromBranchToBranch && parentId === toStateId) {
            childs = {}
          } else {
            childs = {
              ...updatesFromStateChildsPath({ ...params, childs: childrenIds })
            }
          }
          return {
            ...res,
            [stateId]: {
              order: order + (baseOrder - fromOrder)
            },
            ...childs
          }
        }
        return res
      }, {})

      return {
        ...result,
      }
    }
  }

  const drop = (fromState: State, toState: State) => {
    setUpdatingOrderStates(true)
    const { id: fromId, order: fromOrder, conditionGroupOrder: fromConditionGroupOrder,
      parentId: fromParentId, childrenIds: fromChildrenIds, stateConditionId: fromStateConditionId
    } = fromState
    const { id: toId, order: toOrder, childrenIds: toChildrenIds, stateConditionId: toStateConditionId,
      conditionGroupOrder: toConditionGroupOrder, parentId: toParentId
    } = toState
    const { isToStateAreChildsOfFromState } = checkPositionOfFromAndToState(fromState, toState)
    // console.log('from', fromState.name || (fromState.trigger || fromState.action)?.name, fromState.id)
    // console.log('to', toState?.name || (toState?.trigger || toState?.action)?.name, toState?.id)
    // console.table(states?.map(s => ({
    //   ...s,
    //   childrenIds: s.childrenIds?.join(','),
    //   name: s.name || (s.trigger || s.action)?.name
    // })), ['name','order', 'conditionGroupOrder','id', 'parentId', 'childrenIds', 'stateConditionId'])
    let needUpdate: Record<number, Partial<State>> = {}
    const positions = checkPositionOfFromAndToState(fromState, toState)
    const { isMoveFromBranchToBranch } = positions

    if (states && fromId !== toId && fromParentId !== toId) {

      const isFromStateOrderLessThanTOStateOrder = fromOrder < toOrder

      const newOrderOfToState = isToStateAreChildsOfFromState ? toOrder -1 : isMoveFromBranchToBranch || !isFromStateOrderLessThanTOStateOrder ? toOrder :
        toOrder
      const newOrderOfFromState = isFromStateOrderLessThanTOStateOrder && (!isMoveFromBranchToBranch || isToStateAreChildsOfFromState) ? toOrder : toOrder + 1
      // bukan pindah path
      if (!fromConditionGroupOrder) {
        needUpdate = {
          [fromId]: {
            order: newOrderOfFromState,
            stateConditionId: toConditionGroupOrder ? toId : toStateConditionId || null,
            conditionGroupOrder: fromConditionGroupOrder ? newOrderOfFromState : fromConditionGroupOrder,
            parentId: toId,
            childrenIds: toChildrenIds
          },
          [toId]: {
            conditionGroupOrder: toConditionGroupOrder ? newOrderOfToState : toConditionGroupOrder,
            parentId: toParentId === fromId ? fromParentId : toParentId,
            order: newOrderOfToState,
            childrenIds: [fromId]
          },
          ... fromParentId ? { [fromParentId]: {
            childrenIds: fromChildrenIds
          } } : {},
        }
        const doSomethingOnTOChilds = updatesOntoChilds({ ...positions, states: states, toChilds: toChildrenIds, fromState: fromState, toState: toState })
        const doSomethingOnChildsOfFromState = updatesOnChildsOfFromState({ ...positions, toChilds: fromChildrenIds, toState: toState, fromState: fromState, states: states })

        // console.log('fromChilds', doSomethingOnChildsOfFromState)
        // console.log('toChilds', doSomethingOnTOChilds)
        needUpdate = {
          ...needUpdate,
          ...doSomethingOnChildsOfFromState,
          ...doSomethingOnTOChilds
        }
      } else if (fromParentId && toStateConditionId !== fromId) {
        // TODO pindah path
        const parentFrom = states.find(state => state.id === fromParentId)
        const baseOrder = toOrder + 1
        const newChildsForParent = [...parentFrom?.childrenIds?.filter(childId => childId !== fromId) || []]
        needUpdate = {
          [fromId]: {
            order: toOrder + 1,
            conditionGroupOrder: toOrder + 1,
            parentId: toId,
            stateConditionId: toConditionGroupOrder ? toId : toStateConditionId
          },
          [toId]: {
            parentId: toParentId === fromId ? fromParentId : undefined,
            conditionGroupOrder: toParentId === fromId ? fromConditionGroupOrder : undefined,
            order: toParentId === fromId ? fromOrder : undefined,
            stateConditionId: toParentId === fromId ? fromStateConditionId : undefined,
            childrenIds: toChildrenIds ? [...toChildrenIds, fromId] : [fromId]
          },
          [fromParentId]: {
            childrenIds: newChildsForParent.length === 0 ? null : newChildsForParent
          },
          ...updatesFromStateChildsPath({ ...positions, baseOrder: baseOrder, states: states, fromState: fromState, toState: toState, childs: fromChildrenIds })
        }
      }

    }
    // console.table(Object.keys(needUpdate).sort((id1, id2) => {
    //   const order1 = needUpdate[id1].order || states?.find(s => s.id.toString() === id1)?.order
    //   const order2 = needUpdate[id2].order || states?.find(s => s.id.toString() === id2)?.order
    //   return order1 - order2
    // }).map(stateId => {
    //   const state = states?.find(s => s.id.toString() === stateId)
    //   return {
    //     name: state?.name || (state?.trigger || state?.action)?.name,
    //     // order: state?.order,
    //     id: stateId,
    //     ...needUpdate[stateId],
    //     childrenIds: (needUpdate[stateId]?.childrenIds || []).join(', '),
    //     stateConditionId: `${needUpdate[stateId].stateConditionId}`,
    //     parentId: `${needUpdate[stateId].parentId}`
    //   }
    // }))
    Promise.all(Object.keys(needUpdate).map(async (stateId) => {
      return updateAScenarioState(parseInt(scenarioId), parseInt(stateId), needUpdate[stateId])
        .then(({ data }) => data)
    }))
      .then(() => {
        revalidateAScenario(parseInt(scenarioId))
        setUpdatingOrderStates(false)
        setTimeout(() => {
          Object.keys(needUpdate).map((stateId) => {
            revalidateAScenarioState(parseInt(scenarioId), parseInt(stateId))
          })
        }, 400)

      })
      .catch(() => {
        setUpdatingOrderStates(false)
      })
  }

  return {
    updatingOrderStates,
    onDrop: drop
  }
}