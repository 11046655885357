import { FC } from 'react'
import { useHistory } from 'react-router'
import useStatusPayment from '../../../../hooks/payment/useStatusPayment'
import { StyledAlert, StyledButton } from '../../../../pages/components/StyledComponents'

interface Props {
  margin?: string | number
}

const UserBillingAlert: FC<Props> = ({ margin }) => {
  const statusPayment = useStatusPayment()
  const history = useHistory()

  const onCheckPlan = () => {
    history.push('/app/pricing-plan')
  }

  const { maxScenario, scenariosCount } = statusPayment
  // const overdueDay = Math.floor((new Date().getTime() - (result?.subscription?.latestInvoice.due_date || 0) * 1000) / 86400000)
  // const dueDate = (result?.subscription?.latestInvoice.due_date || 0) * 1000
  // const finalizeAt = (result?.subscription?.latestInvoice.status_transitions.finalized_at || 0) * 1000
  // const maxDay = Math.ceil((dueDate - finalizeAt) / 86400000)

  const getError = () => {
    if (maxScenario && scenariosCount && maxScenario !== 0 && scenariosCount >= maxScenario) {
      return <>You already hit your <b>Scenario Limit.</b> You would no longer able to create another scenario.</>
    // } else if (maxTask && totalUsage && totalUsage >= maxTask) {
    //   return <><p>You already hit your <b>Monthly Executed Task Limit.</b></p>
    //     <p>Task activated after this will be charged at your next billing payment.</p></>
    // } else if (status === 'past_due') {
    //   return <><p>You have missed the payment date and the invoice is now overdue <b>{overdueDay} days</b>. (max. {maxDay} days)</p>
    //     {overdueDay > maxDay ? <p>After {maxDay} days all scenario will no longer be able to use.</p> : null}</>
    // } else if (status === 'unpaid') {
    //   return <>You have'nt paid your invoice yet, please pay first</>
    } else {
      return null
    }
  }


  if (getError()) {
    return (
      <StyledAlert
        style={{ margin }}
        type="error"
        message={getError()}
        action={<StyledButton type="link" danger onClick={onCheckPlan}>Check Subscrption Plan</StyledButton>}
      />
    )
  }
  return null
}

export default UserBillingAlert