import { Alert, Col, ConfigProvider, Empty, Row, Space, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { ColumnsType } from 'antd/lib/table'
import { DateTime } from 'luxon'
import React, { FC, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useInvoices, { Invoice } from '../../../hooks/payment/useInvoices'
import { currencyFormatterIDR } from '../../../util/formatter'
import { StyledDescription } from '../../components/StyledComponents'

const TransactionHistory: FC = () => {
  const [value, setValue] = useState<string>()
  const { invoices, errorFetchInvoices, fetchingInvoices } = useInvoices()
  const history = useHistory()

  const cols: ColumnsType<Invoice> = [
    {
      title: 'Invoice Number',
      render: (_, record) => record.number
    },
    {
      title: 'Amount',
      render: (_, record) => currencyFormatterIDR(record.total/100)
    },
    {
      title: 'Date and Time',
      dataIndex: 'created',
      defaultSortOrder: 'descend',
      sorter: (p1, p2) => new Date(p1.created).getTime() - new Date(p2.created).getTime(),
      render: (_, record) => DateTime.fromJSDate(new Date(record.created * 1000)).toFormat('DD T')
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => <Space>
        <a onClick={() => window.open(record.invoice_pdf)}>See Invoice</a>
        <a onClick={() => history.push(`/app/billing-usage/usage-history/${record.id}`)} >See Detail</a>
      </Space>
    }
  ]

  return (
    <ScenarioListContainer>
      <Row style={{ marginBottom: '12px' }}>
        <Col span={6} offset={18}>
          <Search placeholder="Search Transaction..." onSearch={setValue} allowClear  />
        </Col>
      </Row>
      {
        errorFetchInvoices ?
          <Alert message="Error fetching transaction history. Please refresh this page." type="error" /> :
          <ConfigProvider
            renderEmpty={() => {
              return <Empty
                imageStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 200
                }}
                image={fetchingInvoices ? null : value ? '/illustrations/notfound.svg' : '/illustrations/start-history.svg'}
                description={fetchingInvoices ? null : value ?
                  <>
                    <h5>Sorry, no result found</h5>
                    <StyledDescription>What you searched was unfortunately not found or doesn't exist</StyledDescription>
                  </> :
                  <>
                    <h5>There isn't any history here yet</h5>
                  </>}
              />
            }}
          >
            <Table
              columns={cols}
              dataSource={invoices?.filter((payment, idx, invoices) => !invoices[idx + 1] ? false : value ? payment.number.toLowerCase().includes(value.toLowerCase()) : true)}
              rowKey={record => record.id}
              loading={fetchingInvoices}
              scroll={{ x: true }}
            />
          </ConfigProvider>
      }
    </ScenarioListContainer>
  )
}

export default TransactionHistory

const ScenarioListContainer = styled.div`
  padding: 24px;
`