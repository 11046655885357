import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import useCreateOrUpdateAuthentication from '../../../../../../hooks/application/authentication/useCreateUpdateAuthentication'
import { getRedirectOAuthUrl } from '../../../../../../util/formatter'
import { StyledAlert, StyledButton, StyledCard, StyledDescription, StyledFooterForm, StyledText } from '../../../../../components/StyledComponents'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { PropsContentStep } from '../create/data'
import { useSharedValues } from './SharedValuesContextProvider'

const RedirectUrl: FC<PropsContentStep> = ({ onContinue, isActive, info, onPrevious, isSaveOnContinue }) => {
  const { applicationId } = useParams<{ applicationId: string }>()
  const uuid = uuidv4()
  const [values, setValues] = useSharedValues()
  const [version] = useApplicationVersionContext()
  const { createOrUpdateAuthentication, creatingAuthentication } = useCreateOrUpdateAuthentication()
  const [redirectUrl, setRedirectUrl] = useState<string>()

  useEffect(() => {
    if (values && !values.uuid) {
      setValues(prevState => ({ ...prevState, uuid: uuid }))
    }
    if (values?.uuid) {
      setRedirectUrl(getRedirectOAuthUrl(applicationId, values?.uuid || uuid))
    }
  }, [uuid, values?.uuid])

  const onSaveAndContinue = async () => {
    if (isSaveOnContinue) {
      const create = await createOrUpdateAuthentication(applicationId, { ...values, isDraft: false, versionId: values?.versionId || version?.id })
      if (create) {
        setValues({ ...values, ...create })
      }
      onContinue()
    } else {
      const create = await createOrUpdateAuthentication(applicationId, { ...values, isDraft: values?.isDraft === undefined ? true : values?.isDraft, versionId: values?.versionId || version?.id })
      if (create) {
        setValues({ ...values, ...create })
      }
      onContinue()
    }
  }
  return (
    <StyleRedirectUrl className={isActive ? 'show' : undefined}>
      {info ? <><StyledAlert message={<div dangerouslySetInnerHTML={{ __html: info }} />} type="info" /><br/></> : null}
      <StyledDescription>Connect to your app using the above settings with a personal or testing account, and Otomatis will use this connection to test API calls as you build the application.</StyledDescription>
      <StyledText copyable>{redirectUrl}</StyledText>
      <br /><br />
      <StyledFooterForm>
        { onPrevious ? <StyledButton onClick={onPrevious}>Previous</StyledButton> : null }
        <StyledButton type="primary" loading={creatingAuthentication} onClick={onSaveAndContinue}>Continue</StyledButton>
      </StyledFooterForm>
    </StyleRedirectUrl>
  )
}

export default RedirectUrl

const StyleRedirectUrl = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;
  ${StyledText} {
    display: flex;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    padding: 5px 13px;
    svg {
      color: #D9D9D9;
    }
  }
`