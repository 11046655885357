import { FC, useEffect, useState } from 'react'
import MarkdownRenderer from '../../../components/MarkdownRenderer'

const TermOfService: FC = () => {
  const [text, setText] = useState<string>('')
  useEffect(() => {
    const readmePath = require('./otomatis_tos.md')
    fetch(readmePath.default)
      .then(response => {
        return response.text()
      })
      .then(text => {
        setText(text)
      })
  }, [])

  if (!text) {
    return null
  }
  return (
    <div className="p-6">
      <h3>Term of Services</h3>
      <MarkdownRenderer
        components={{
          h2({ children }) {
            return <h4 id={String(children).replaceAll(' ', '-')}>{children}</h4>
          },
          p({ children }) {
            return <p className="my-3" >{children}</p>
          }
        }}
        className="p-4 rounded bg-white">
        {text}
      </MarkdownRenderer>
    </div>
  )
}

export default TermOfService