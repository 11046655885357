import { AxiosPromise } from 'axios'
import { Settings } from 'db'
import { useApi } from '../useFetch'

interface UpdateUserSettings {
  updateUserSettings: (settingsId: number, payload: Partial<Settings>) => AxiosPromise<{ setting: Settings }>,
  updatingUserSettings: boolean,
  updatedUserSettings?: Settings
}

export default function useUpdateUserSettings(): UpdateUserSettings {
  const { call, data, isValidating } = useApi<{ setting: Settings }>()

  const updateUserSettings = (settingsId: number, payload: Partial<Settings>) => {
    return call({
      method: 'patch',
      url: `/setting/${settingsId}`,
      data: {
        setting: payload
      }
    })
  }
  return {
    updateUserSettings,
    updatingUserSettings: isValidating,
    updatedUserSettings: data?.setting
  }
}