import { AxiosPromise } from 'axios'
import { useEffect, useState } from 'react'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'


type Result = {
  fetchDynamicOption: (params: ParamsDynamicOption) => AxiosPromise<any[]> | undefined,
  dynamicOptions?: { value: string, label: string }[],
  fetchingDynamicOptions: boolean,
  errorFetchDynamicOptions?: Error
}

export type ParamsDynamicOption = {
  formValue?: any,
  applicationId?: number,
  triggerId?: number,
  bundleId?: number,
  bundles?: any
}

export default function useDynamicOptions(): Result {
  const [dynamicOptions, setDynamicOptions] = useState<{ value: string, label: string }[]>()

  const { data, isValidating, error, call } = useApi<any[]>()

  useEffect(() => {
    if (Array.isArray(data)) {
      setDynamicOptions(data?.map((opt: any) => ({ value: opt.key, label: opt.label })))
    }
  }, [data])

  return {
    fetchDynamicOption: (params: ParamsDynamicOption) => {
      const { applicationId, triggerId, bundleId, bundles, formValue } = params

      const valid = applicationId && triggerId && (bundleId || bundles)
      const url = `/application/${applicationId}/trigger/${triggerId}/hitRequest${bundleId ? `?bundleId=${bundleId}` : ''}`
      if (!valid) {
        return
      }
      return call({
        hideNotifError: true,
        url: url,
        method: 'post',
        data: {
          params: {
            ...formValue
          },
          ...bundles
        }
      })
    },
    dynamicOptions: dynamicOptions,
    fetchingDynamicOptions: isValidating,
    errorFetchDynamicOptions: error
  }
}

