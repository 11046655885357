import { Spin } from 'antd'
import { FC, useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { useIntercom } from 'react-use-intercom'
import { useLoggedInUser } from '../../../contexts/LoggedInUserContextProvider'
import { DISABLE_DEV_MODE } from '../../../util/Constant'
import NotFound from '../../error/NotFound'
import CreateApplication from './Create'
import Main from './main'
import ActionLogs from './main/action/logs'
import TriggerLogs from './main/trigger/logs'
import ReviewApp from './reviewApp'
import UpdateApplication from './Update'
import View from './View'

const Application: FC = () => {
  const { path } = useRouteMatch()
  const [user] = useLoggedInUser()
  const { boot } = useIntercom()

  useEffect(() => {
    if (user) {
      boot({ customAttributes: { name: user.name } })
    }
  }, [user])

  if (!user) {
    return <Spin spinning={true} className={'h-auto m-auto'} />
  }

  return (
    <>
      <Switch>
        <Route exact path={path} component={View}>
          {
            DISABLE_DEV_MODE && !(user?.email.includes('@bahasa.ai') || user?.isAdmin) ?
              <Redirect to={'404'} /> :
              <Redirect to={`${path}/me`} />
          }
        </Route>
        <Route exact path={`${path}/me`} component={View} />
        <Route exact path={`${path}/create`} component={CreateApplication} />
        {/* <Route path={`${path}/:applicationId/test/auth/:authenticationId`} component={TestAuth} /> */}
        <Route path={`${path}/:applicationId/edit`} exact component={UpdateApplication}/>
        <Route path={`${path}/:applicationId/:versionName/main/trigger/:triggerId/v/:versionId/logs`} component={TriggerLogs} />
        <Route path={`${path}/:applicationId/:versionName/main/action/:actionId/v/:versionId/logs`} component={ActionLogs} />
        <Route path={`${path}/:applicationId/:versionName/main/:page?/:subPage?`} component={Main}>
        </Route>
        <Route path={`${path}/:applicationId//main/:page?/:subPage?`} component={Main} />
        {
          user?.isAdmin ?
            <>
              <Route exact path={`${path}/review-app`} component={ReviewApp} />
            </>:
            null

        }
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default Application