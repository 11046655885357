import { Col, Row, Space } from 'antd'
import { Application } from 'db'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { StyledButton, StyledDescription } from '../../../../../../../../pages/components/StyledComponents'
import AvatarApplicationGroups from '../../../../../AvatarApplicationGroups'
import { stateNameAtom } from '../../../recoil/state'
import StateContextMenu from '../../StateContextMenu'

interface Props {
  id: number,
  onEdit: () => void,
  applicationChilds?: (Application | undefined)[],
  name?: string,
  nSteps: number
}
const StatePath: FC<Props> = ({ name, id, nSteps, applicationChilds, onEdit }) => {
  const [thisName, _setThisName] = useRecoilState(stateNameAtom(id))

  // useEffect(() => {
  //   setThisName(name)
  // }, [name])

  return (
    <StyledRow  gutter={[16, 16]} align="middle">
      <Col flex="auto">
        <Space align="center" >
          <div>
            <StyledH5>{thisName || name || 'Path'}</StyledH5>
            <StyledP>{nSteps} step{nSteps > 1 ? 's' : ''}</StyledP>
          </div>
          <AvatarApplicationGroups applications={applicationChilds} />
        </Space>
      </Col>
      <Col>
        <Space>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StateContextMenu
            onClickEdit={onEdit}
            type={'path'}
            id={id}
          />
        </Space>
      </Col>
    </StyledRow>

  )
}

export default StatePath

const StyledRow = styled(Row)`
  background: #FFFFFF;
  /* Neutral/4 */
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 12px;
  margin-bottom: 12px;
`
const StyledP = styled(StyledDescription)`
  margin: 0;
  padding: 0;
`
const StyledH5 = styled.h5`
  margin: 0;
`