import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useUpdateAScenarioState from '../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { updatingScenarioOrState } from '../../../../scenario/edit/recoil'
import { useStateTestData } from '../../../../scenario/edit/StateTestDataContext'
import { stateBundleIdAtomFamily, stateEventAtom } from '../../recoil/state'
import { useStateValue } from '../StateValueContextProvider'

interface ChangeEvent {
  isOnEdit: boolean,
  isAdd: boolean | undefined,
  onCancel: () => void,
  onClickEdit: (isAdd?: boolean) => void,
  updatingEvent: boolean,
  onChangeEventId: (eventId?: number, name?: string) => Promise<void> | undefined
}

export default function useChangeEvent(): ChangeEvent {
  const [stateValue] = useStateValue()
  const { triggerId, actionId, scenarioId, id, type } = stateValue || {}
  const event = useRecoilValue(stateEventAtom(id))
  const [isOnEdit, setIsOnEdit] = useState(false)
  const [isAdd, setIsAdd] = useState<boolean | undefined>(false)
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const setUpdatingAScenarioState = useSetRecoilState(updatingScenarioOrState)
  const setBundleId = useSetRecoilState(stateBundleIdAtomFamily(id))
  const [, setStateTestData] = useStateTestData()

  const onCancel = () => setIsOnEdit(false)

  const onChangeEventId = (eventId?: number, name?: string) => {
    if (scenarioId && id) {
      return updateAScenarioState(scenarioId, id, {
        name: name,
        triggerId: type === 'trigger' ? eventId : undefined,
        testData: null,
        realTestData: null,
        actionId: type === 'action' ? eventId : undefined
      }).then(() => {
        setIsOnEdit(false)
        setIsAdd(false)
        setUpdatingAScenarioState({ counter: 2, isUpdating: false })
        setBundleId(undefined)
        setStateTestData(prevState => ({
          ...prevState,
          [id]: {
            ...prevState?.[id],
            realTestData: null,
            testData: null,
            flatTestData: null
          }
        }))
      })
    }
  }

  useEffect(() => {
    if (!triggerId && !actionId && !event) {
      setIsOnEdit(true)
      setIsAdd(true)
    }
  }, [triggerId, actionId])

  const onClickEdit = (isAdd?: boolean) => {
    setIsOnEdit(true)
    if (isAdd) {
      setIsAdd(true)
    }
  }

  return {
    updatingEvent: updatingAScenarioState,
    onClickEdit,
    isOnEdit,
    isAdd,
    onCancel,
    onChangeEventId
  }
}