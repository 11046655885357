import { FC } from 'react'
import { Dropdown, Menu } from 'antd'
import styled from 'styled-components'
import { useRouteMatch, useParams } from 'react-router'
import useDeleteFolder from '../../../../../../hooks/folder/useArchiveFolder'
import useCreateFolder from '../../../../../../hooks/folder/useCreateFolder'
import useDuplicateFolder from '../../../../../../hooks/folder/useDuplicateFolder'
import useFolders from '../../../../../../hooks/folder/useFolders'
import CustomModalMethod from '../../../../../../util/CustomModalMethod'
import useNavigateTabLeftPane from './useNavigateTabLeftPane'
import useFolderActiveKey from './useFolderActiveKey'
import { StyledButton } from '../../../../../../pages/components/StyledComponents'
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons'

const FolderDropdownMenu: FC<{ id: number, name: string, index: number, onClickRenameFolder: (e) => void }> = ({ id, name, onClickRenameFolder }) => {
  const { path } = useRouteMatch()
  const { folders } = useFolders()
  const { folderId } = useParams<{ folderId?: string }>()
  const { navigateTabLeftPane: navigateTab } = useNavigateTabLeftPane()
  const { deleteFolder, deletingFolder } = useDeleteFolder()
  const { createFolder, creatingFolder } = useCreateFolder()
  const { duplicateFolder, duplicatingFolder } = useDuplicateFolder()
  const activeKey = useFolderActiveKey()

  const onClickCreateFolder = () => {
    createFolder({
      displayOrder: folders ? folders.length + 1 : 0
    })
  }

  const onClickDeleteFolder = (id: number) => () => {
    CustomModalMethod.dangerConfirm({
      title: 'Are you sure you want to delete this folder?',
      content: 'All scenario inside this folder will still available on all scenario',
      onOk: async () => {
        return new Promise((resolve, reject) => {
          deleteFolder(id).then(() => {
            if (folderId === id.toString()) {
              navigateTab(`${path.replace(activeKey, '')}`)
            }
            return resolve(true)
          }).catch(() => reject('error'))
        }).catch(() => console.log('Oops errors!'))
      },
    })
  }

  const onClickDuplicateFolder = (id: number, name: string, activeKey: string) => () => {
    duplicateFolder(id, name).then(({ newFolder }) => {
      if (newFolder) {
        navigateTab(`${path.replace(activeKey, '')}/${newFolder.id}`)
      }
    })
  }

  return (
    <div onClick={e => e.stopPropagation()}> {/** Jundi's note: stop Ant Design Dropdown propagation, that damn Dropdown can't have onClick props ffs */}
      <StyledDropdown
        trigger={['click']}
        overlay={
          <Menu onMouseDown={(e) => e.stopPropagation()}>
            <Menu.Item key="create" onClick={onClickCreateFolder}>Create New Folder</Menu.Item>
            <Menu.Item key="duplicate" onClick={onClickDuplicateFolder(id, name, activeKey)}>Duplicate Folder</Menu.Item>
            <Menu.Item key="rename" onClick={onClickRenameFolder}>Rename Folder</Menu.Item>
            <Menu.Item key="delete" onClick={onClickDeleteFolder(id)}>Delete</Menu.Item>
          </Menu>
        }>
        <StyledButtonDetail className="folder-context-menu-btn" icon={deletingFolder || creatingFolder || duplicatingFolder ? <LoadingOutlined /> : <MoreOutlined />} />
      </StyledDropdown>
    </div>
  )
}

export default FolderDropdownMenu

const StyledDropdown = styled(Dropdown)`
  background: inherit !important;
  transition: unset;
`

const StyledButtonDetail = styled(StyledButton)`
  opacity: 0;
  box-shadow: unset;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%) rotate(90deg);
  border: 0;
  background: inherit;
  &:hover, &:focus {
    background: inherit;
  }
`