import { PlusOutlined } from '@ant-design/icons'
import { Input, Modal, Radio } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useCreateInvitation from '../../../../../../hooks/application/invitation/useCreateInvitation'
import useUpdateInvitation from '../../../../../../hooks/application/invitation/useUpdateInvitation'
import { MemberAndInvitation } from '../../../../../../hooks/application/member/useAllMemberAndInvitation'
import useUpdateMember from '../../../../../../hooks/application/member/useUpdateMember'
import Notification from '../../../../../../util/Notification'
import { StyledButton } from '../../../../../components/StyledComponents'
import CustomForm from '../../../../components/form/CustomForm'
import FormItem from '../../../../components/form/FormItem'

type Props = {
  type: 'create' | 'update',
  afterSubmit?: (values: MemberAndInvitation) => void,
  onCancel?: () => void,
  onEdit?: Partial<MemberAndInvitation>
}

const MemberFormModal: FC<Props> = ({ type, onEdit, afterSubmit, onCancel }) => {
  const { applicationId } = useParams<{ applicationId: string }>()
  const [visible, setVisible] = useState(false)
  const [form] = useForm()
  const { createInvitation, creatingInvitation, createdInvitation } = useCreateInvitation()
  const { updateMember, updatingMember, updatedMember } = useUpdateMember()
  const { updateInvitation, updatingInvitation, updatedInvitation } = useUpdateInvitation()

  const contents = {
    create: {
      title: 'Invite Team Member',
      okText: 'Send Invite'
    },
    update: {
      title: 'Update Role Team Member',
      okText: 'Update'
    }
  }

  useEffect(() => {
    if (onEdit) {
      open()
      form.setFieldsValue({
        email: onEdit.email,
        role: onEdit.role
      })
    }
  }, [onEdit])

  const open = () => {
    setVisible(true)
  }

  const close = () => {
    setVisible(false)
    if (onCancel) {
      onCancel()
    }
  }

  const openNotif = (type: 'success' | 'error', title: string, description: string) => {
    Notification[type]({
      message: title,
      description: description
    })
  }

  const onSubmit = async (values: any) => {
    if (onEdit) {
      if (onEdit.userId) {
        updateMember(Number(applicationId), Number(onEdit.id), { role: values.role })
      } else {
        updateInvitation(Number(applicationId), Number(onEdit.id), values)
      }
    } else {
      const emails = values.email.replace(/\s+/g, '').split(',')
      await Promise.all(emails.map(async (email) => {
        return await createInvitation(Number(applicationId), { email: email, role: values.role })
      }))

    }
  }

  useEffect(() => {
    if (createdInvitation) {
      if (afterSubmit) {
        afterSubmit({ ...createdInvitation })
      }
      openNotif('success', 'Success', createdInvitation.email ? `Invitation email sent to ${createdInvitation.email || 'user'}` : 'Member added')
      form.resetFields()
      close()
    }
  }, [createdInvitation])

  useEffect(() => {
    if (updatedInvitation) {
      if (afterSubmit) {
        afterSubmit({ ...updatedInvitation, userId: 0 })
      }
      openNotif('success', 'Success', `Invitation updated for ${updatedInvitation.email}`)
      form.resetFields()
      close()
    }
  }, [updatedInvitation])

  useEffect(() => {
    if (updatedMember) {
      if (afterSubmit) {
        afterSubmit({ ...updatedMember, email: updatedMember.user?.email })
      }
      openNotif('success', 'Success', 'Member updated')
      form.resetFields()
      close()
    }
  }, [updatedMember])

  return (
    <>
      {type === 'create' ? <StyledButton type="primary" onClick={open} icon={<PlusOutlined />} >Add Team Member</StyledButton> : null}
      <Modal
        visible={visible}
        title={contents[type].title}
        okText={contents[type].okText}
        cancelText="Cancel"
        confirmLoading={creatingInvitation || updatingInvitation || updatingMember}
        onCancel={close}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values)
            })
            .catch(() => {
              // console.log('Validate Failed:', info)
            })
        }}
      >
        <CustomForm form={form} initialValues={{ email: onEdit?.email, role: onEdit?.role }}>
          <FormItem
            description={type === 'create' ? 'You can invite more than one email with delimiter ,' : undefined}
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input the email!' }]}
          >
            <Input disabled={type === 'update'} />
          </FormItem>
          <FormItem
            name="role"
            label="Role"
            rules={[{ required: true, message: 'Please choose the role!' }]}
          >
            <Radio.Group>
              <Radio value="admin">Admin</Radio>
              <Radio value="viewer">Viewer</Radio>
            </Radio.Group>
          </FormItem>
        </CustomForm>
      </Modal>
    </>
  )
}

export default MemberFormModal