import { Col, Modal, Row, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { FieldSchema } from 'db'
import { FC, useEffect, useState } from 'react'
import useAllAction from '../../../../hooks/application/action/useAllAction'
import useAllTrigger from '../../../../hooks/application/trigger/useAllTrigger'
import { StyledButton } from '../../../../pages/components/StyledComponents'
import { useApplicationVersionContext } from '../../../../pages/developer/application/contexts/ApplicationVersionContextProvider'
import MonacoEditor from '../../../../pages/developer/components/form/MonacoEditor'
import Notification from '../../../../util/Notification'

interface Props {
  onImport: (fields: FieldSchema[] | undefined) => void,
  existedFields?: FieldSchema[]
}

interface Data extends FieldSchema {
  triggerActionName: string,
  idx?: number
}

const ImportFields: FC<Props> = ({ onImport, existedFields }) => {
  const [visible, setVisible] = useState(false)
  const [version] = useApplicationVersionContext()
  const { triggers, fetchingAllTriggers } = useAllTrigger(visible ? version?.applicationId : undefined, version?.id)
  const { actions, fetchingAllActions } = useAllAction(visible ? version?.applicationId : undefined, version?.id)
  const [fields, setFields] = useState<Data[]>()
  const [selectedFields, setSelectedFields] = useState<Data[]>()
  const [showCode, setShowCode] = useState<string>()
  const [value, setValue] = useState<string>()

  useEffect(() => {
    if (triggers && actions) {
      const mergeFields = [
        ...triggers.reduce((acc: Data[], trigger) =>[...acc, ...trigger.paramSchema?.map(field => ({ ...field, triggerActionName: `Trigger: ${trigger.name}` })) || []], []),
        ...actions.reduce((acc: Data[], action) => [...acc, ...action.paramSchema?.map(field => ({ ...field, triggerActionName: `Action: ${action.name}` })) || []], []),
      ].map((field, idx) => ({ ...field, idx }))

      setFields(mergeFields)
    }
  }, [triggers, actions])

  const onClose = () => setVisible(false)

  const onOpen = () => setVisible(true)

  const onCloseCodeModal = () => setShowCode(undefined)

  const onOk = () => {
    if (selectedFields?.length !== 0) {
      onImport(selectedFields?.map(field => {
        const { triggerActionName: _, idx: _idx, ...originalField } = field

        return originalField
      }))
    }
    onClose()
  }

  const onSearch = (value: string) => setValue(value)

  return (
    <>
      <StyledButton type="link" onClick={onOpen}>Import Fields</StyledButton>
      <Modal
        width="75vw"
        visible={visible}
        onCancel={onClose}
        okText={'Import'}
        title="Import fields"
        onOk={onOk}
        okButtonProps={{
          disabled: selectedFields?.length === 0
        }}
      >
        <Row className="mb-3">
          <Col span={6} offset={18}>
            <Search placeholder="search scenario..." onSearch={onSearch} allowClear  />
          </Col>
        </Row>
        <Table
          dataSource={fields?.filter(field => value ? field.triggerActionName.toLowerCase().includes(value?.toLowerCase()) || field.key.toLowerCase().includes(value?.toLowerCase()) || field.label.toLowerCase().includes(value?.toLowerCase()) : true)}
          rowKey="idx"
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: selectedFields?.map(field => field?.idx || 0),
            onSelect: (record, isSelected, selectedRows) => {
              if (isSelected) {
                if (record.key && existedFields?.find(field => field.key === record.key)) {
                  Notification.warning({
                    message: 'Warning: Duplicate keys',
                    description: `You have field with key ${record.key} in your fields`
                  })
                  setSelectedFields(prevState => prevState?.filter(row => row.key !== record.key) || [])
                } else {
                  setSelectedFields(selectedRows)
                }
              } else {
                setSelectedFields(selectedRows)
              }
            }
          }}
          loading={fetchingAllActions || fetchingAllTriggers}
          columns={[
            { title: 'Trigger/Action Name', dataIndex: 'triggerActionName' },
            { title: 'Label', dataIndex: 'label' },
            { title: 'Key', dataIndex: 'key',
              render: (val, record) => val ? val : <StyledButton onClick={() => setShowCode(record.paramSchemaFunc)}>Show code</StyledButton>
            },
            { title: 'Type', dataIndex: 'type' }
          ]}
        />
      </Modal>
      <Modal
        width="50vw"
        title="Code"
        visible={!!showCode}
        onOk={onCloseCodeModal}
        onCancel={onCloseCodeModal}
        cancelButtonProps={{ hidden: true }}
      >
        <div>
          <MonacoEditor value={showCode} defaultLanguage={'javascript'} />
        </div>
      </Modal>
    </>
  )
}

export default ImportFields