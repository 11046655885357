import { CloseOutlined } from '@ant-design/icons'
import { Col, Form, Row, Select, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { LogicalOperatos } from 'db'
import { FC, Fragment, useEffect } from 'react'
import { StyledButton } from 'src/pages/components/StyledComponents'
import CustomForm from 'src/pages/developer/components/form/CustomForm'
import { StyledFormItem } from '../../../../edit/RichKeyValueInput'
import RichTextInput from '../../../../edit/RichTextInput'
import TestSection from '../TestSection'
import useGetState from '../useGetState'

const ConditionField: FC = () => {
  const [form] = useForm()
  const { state } = useGetState()
  const conditions = state?.conditions

  useEffect(() => {
    if (!conditions) {
      form.setFieldsValue({
        or: [{ and: [{}] }]
      })
    } else {
      // setCounterChange(counterChange + 1)
      const value = conditions.map(statement => ({
        and: statement.map(clause => ({ field: clause[0], operator: clause[1], value: clause[2] }))
      }))
      form.setFieldsValue({
        or: value
      })
    }
  }, [conditions])
  return (
    <>
      <CustomForm form={form}
        // onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onValuesChange={onValuesChange}
      >
        <Form.List name="or">
          {(fieldsOr, { add, remove }) =>
            <>
              {fieldsOr.map((fieldOr, idxOr) =>
                <Fragment key={idxOr}>
                  <h5>{
                    idxOr === 0 ? 'Only continue if..' : 'Or continue if..'
                  }</h5>
                  <Form.List name={[fieldOr.name, 'and']}>
                    {(fieldsAnd, andOperation) =>
                      <>
                        {fieldsAnd.map((fieldAnd, idxAnd) =>
                          <Row gutter={8} key={`${idxOr}-${idxAnd}`}>
                            <Col lg={23} xl={23} md={23} sm={20} xs={20} >
                              <div style={{ position: 'relative' }}>
                                <Row gutter={8} align="top">
                                  <Col lg={10} xl={10} md={10} sm={24} xs={24} >
                                    <StyledFormItem
                                      small
                                      name={[fieldAnd.name, 'field']}
                                      fieldKey={[fieldAnd.fieldKey, 'field']}
                                    >
                                      {
                                        // eslint-disable-next-line
                                        // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                                        <RichTextInput placeholder="Choose Field" id={`text-input-${idxOr}-${idxAnd}`} />
                                        // <RichTextInput onChange={() => setCounterChange(counterChange + 1)} placeholder="Choose Field" id={`text-input-${idxOr}-${idxAnd}`} />
                                      }
                                    </StyledFormItem>
                                  </Col>
                                  <Col lg={4} xl={4} md={4} sm={24} xs={24}>
                                    <StyledFormItem
                                      name={[fieldAnd.name, 'operator']}
                                      fieldKey={[fieldAnd.fieldKey, 'operator']}
                                    >
                                      <Select
                                        // onChange={() => setCounterChange(counterChange + 1)}
                                        placeholder="Choose Condition"
                                        showSearch
                                        filterOption={(input, option) =>
                                          (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={Object.keys(LogicalOperatos).map(o => ({ value: o, label: (LogicalOperatos as any)[o]?.label }))} />
                                    </StyledFormItem>
                                  </Col>
                                  <Col lg={10} xl={10} md={10} sm={24} xs={24}>
                                    <StyledFormItem shouldUpdate={(prev, next) => prev?.or?.[idxOr]?.and?.[idxAnd]?.operator !== next?.or?.[idxOr]?.and?.[idxAnd]?.operator}>
                                      {
                                        ({ getFieldsValue }) => {
                                          const thisOperator = getFieldsValue()?.or?.[idxOr]?.and?.[idxAnd]?.operator
                                          return <StyledFormItem
                                            hidden={!LogicalOperatos?.[thisOperator]?.isNeedComparisonValue}
                                            name={[fieldAnd.name, 'value']}
                                            fieldKey={[fieldAnd.fieldKey, 'value']}
                                            style={{ position: 'unset' }}
                                          >
                                            {
                                              // eslint-disable-next-line
                                              // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                                              <RichTextInput placeholder="Enter or Select Value" style={{ position: 'unset' }} id={`text-input-${idxOr}-${idxAnd}`} />
                                              // <RichTextInput onChange={() => setCounterChange(counterChange + 1)} placeholder="Enter or Select Value" style={{ position: 'unset' }} id={`text-input-${idxOr}-${idxAnd}`} />
                                            }
                                          </StyledFormItem>
                                        }
                                      }
                                    </StyledFormItem>
                                  </Col>
                                </Row>
                              </div>

                            </Col>
                            <Col span={1} style={{ paddingTop: 4, textAlign: 'center' }}>
                              <CloseOutlined onClick={() => {
                                andOperation.remove(idxAnd)
                                if (idxAnd === 0) {
                                  remove(idxOr)
                                }
                              }}/>
                            </Col>
                          </Row>
                        )}
                        <Space style={{ marginBottom: '20px' }}>
                          <StyledButton onClick={() => andOperation.add({})}>And</StyledButton>
                          {fieldsOr[idxOr + 1] ? null :
                            <StyledButton onClick={() => add({ and: [{}] })}>Or</StyledButton>
                          }
                        </Space>
                      </>
                    }
                  </Form.List>
                </Fragment>
              )}
            </>
          }
        </Form.List>
      </CustomForm>
      <TestSection form={form} type="conditions" />
    </>
  )
}

export default ConditionField