import { Radio } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useAddGlobalVariableToDataSuggestions, useUpdateDataSuggestions } from '../../../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import RichTextInput from '../../../../../../../../components/pages/developer/formItems/RichTextInput'
import { useLoggedInUser } from '../../../../../../../../contexts/LoggedInUserContextProvider'
import useCreateOrUpdateAction from '../../../../../../../../hooks/application/action/useCreateOrUpdateAction'
import useAllAuthentication from '../../../../../../../../hooks/application/authentication/useAllAuthentication'
import useAuthenticationSchema from '../../../../../../../../hooks/authenticationSchema/useAuthenticationSchema'
import { IMPLIKASI_URL } from '../../../../../../../../util/Constant'
import { StyledButton, StyledCard, StyledFooterForm, StyledLabel, StyledTag } from '../../../../../../../components/StyledComponents'
import CustomForm from '../../../../../../components/form/CustomForm'
import FormItem from '../../../../../../components/form/FormItem'
import FormRequestItem from '../../../../../../components/form/FormRequestItem'
import MonacoEditor from '../../../../../../components/form/MonacoEditor'
import { useApplicationVersionContext } from '../../../../../contexts/ApplicationVersionContextProvider'
import { PropsContentStep } from '../../data'
import { useSharedValueAction } from '../../SharedValueActionContextProvider'

const Config: FC<PropsContentStep> = ({ isActive, onPrevious, onContinue }) => {
  const [form] = useForm()
  const [values, setValues] = useSharedValueAction()
  const { applicationId } = useParams<{ applicationId: string }>()
  const [version] = useApplicationVersionContext()
  const { authentications } = useAllAuthentication(applicationId, version?.id)
  const authenticationId = authentications ? authentications[0]?.id : null
  const { authenticationSchema } = useAuthenticationSchema(authentications ? authentications[0]?.authenticationSchemaId : undefined)
  const { createOrUpdateAction, creatingAction } = useCreateOrUpdateAction()
  const [valuesForm, setValuesForm] = useState<any>()
  const [httpRequest, setHttpRequest] = useState<any>()
  const [user] = useLoggedInUser()
  const authentication = authentications?.[0]

  useUpdateDataSuggestions(undefined, authentication, undefined, values?.paramSchema)
  useAddGlobalVariableToDataSuggestions(applicationId, version?.id)


  useEffect(() => {
    if (values?.type) {
      form.setFieldsValue({
        type: values.type
      })
    } else {
      setValues(prevState => ({ ...prevState, type: 'http' }))
    }
  }, [values?.type])

  useEffect(() => {
    form.setFieldsValue({
      stateKeyMap: values?.stateKeyMap
    })
  }, [values?.stateKeyMap])

  useEffect(() => {
    if (!values || !values?.id) {
      if (authenticationId) {
        setValues(prevState => ({ ...prevState, authenticationId, type: 'http' }))
      } else {
        setValues(prevState => ({ ...prevState, type: 'http' }))
      }
    }
  }, [values?.id, authenticationId, user])

  useEffect(() => {
    if (!values?.id) {
      form.resetFields()
      form.setFieldsValue({
        type: 'http'
      })
    }
  }, [values?.id])

  useEffect(() => {
    form.setFieldsValue({
      isUseAuthentication: values?.authenticationId || !values?.id ? true : false
    })
  }, [values?.authenticationId])

  useEffect(() => {
    form.setFieldsValue({
      idempotencyKeyMap: values?.idempotencyKeyMap
    })
  }, [values?.idempotencyKeyMap])

  useEffect(() => {
    if (!isActive) {
      if (httpRequest) {
        setValues(prevState => ({ ...prevState, idempotencyKeyMap: form.getFieldValue('idempotencyKeyMap'),
          stateKeyMap: form.getFieldValue('stateKeyMap'), [httpRequest.id]: httpRequest.values }))
      }
    }
  }, [isActive])

  const onChangeUseAuthentication = (e: CheckboxChangeEvent) => {
    setValues(prevState => ({ ...prevState, authenticationId: e.target.checked ? authenticationId : null }))
  }

  const onSaveAndContinue = async () => {
    let payload = { ...values, idempotencyKeyMap: valuesForm?.idempotencyKeyMap }
    if (httpRequest) {
      payload = { ...payload,  httpRequest: { ...values?.httpRequest, ...httpRequest } }
    }
    const create = await createOrUpdateAction(applicationId, { ...payload, isDraft: values?.isDraft === undefined ? false : values.isDraft, stateKeyMap: form.getFieldValue('stateKeyMap'), type: values?.type || 'http', versionId: values?.versionId || version?.id })
    if (create) {
      setValues(prevState => ({ ...prevState, ...payload, stateKeyMap: form.getFieldValue('stateKeyMap'), ...create }))
    }
    onContinue()
  }

  const onValuesChange = (_: any, allValues: any) => {
    setValuesForm(allValues)
  }

  const onChangeRequest = (_: string, values: any) => {
    setHttpRequest(values)
  }

  const onChangeType  = (e: any) => {
    setValues(prevState => ({ ...prevState, type: e.target.value }))
  }

  return (
    <StyledConfigForm className={isActive ? 'show' : undefined}>
      <CustomForm form={form} onValuesChange={onValuesChange}>
        <FormItem name="type" label="Type" rules={[{ required: true }]}>
          <Radio.Group onChange={onChangeType}>
            <Radio value={'http'}>HTTP</Radio>
            <Radio value={'script'}>Script</Radio>
            {
              user?.isAdmin ?
                <>
                  <Radio value={'db'}>Database</Radio>
                  <Radio value={'code'}>Code</Radio>
                  <Radio value={'delay'}>Delay</Radio>
                  <Radio value={'path'}>Path</Radio>
                  <Radio value={'filter'}>Filter</Radio>
                </> :
                null
            }
          </Radio.Group>
        </FormItem>
        <StyledLabel className="no-status">Authentication</StyledLabel>
        <div style={{ padding: 8, border: '1px solid #d9d9d9', borderRadius: 2, marginTop: 12 }}>
          <h5 style={{ margin: 0 }}>{authenticationSchema?.name || '-'}</h5>
        </div>
        <FormItem name='isUseAuthentication' valuePropName="checked" >
          <Checkbox onChange={onChangeUseAuthentication} >Use Authentication</Checkbox>
        </FormItem>
        <FormItem name='idempotencyKeyMap' label="Idempotency Key Map" description="Idempotency Key">
          {
          // eslint-disable-next-line
          // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
            <RichTextInput />
          }
        </FormItem>
        <FormItem shouldUpdate={(prev, next) => prev.type !== next.type}>
          {
            ({ getFieldValue }) => {
              if (getFieldValue('type') === 'http') {
                return <FormRequestItem
                  onChange={onChangeRequest}
                  valuesForm={valuesForm}
                  key="httpRequest"
                  existedValue={values?.httpRequest}
                  isRequired={true}
                  form={form}
                  id="httpRequest"
                  label="Endpoint"
                />
              }
              return null
            }
          }
        </FormItem>
        <FormItem required={values?.type === 'script'} className="no-padding-extra" name="stateKeyMap" label="Perform" description={
          <>
            <p>The perform function is called each time a new hook is received. Make any needed changes to the data before it's returned to the Connection. This request must return something. <a target="_blank" href={`${IMPLIKASI_URL}/private/docs/docs/build-apps/action#api-configuration`}>Click here to more info</a></p>
            <MonacoEditor height="50px" noResize options={{ readOnly: true, lineNumbers: 'off', overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true, scrollBeyondLastLine: false, lineHeight: 15,
              scrollbar: {
                vertical: 'hidden'
              },
              overviewRulerBorder: false, }} defaultLanguage="javascript" value={'\n// Example: ({data}) => data.result.sort((a, b) => a.message.date - b.message.date) \n'} />
          </>}
        >
          <MonacoEditor defaultLanguage="javascript"  />
        </FormItem>
        <br/>
        <FormItem shouldUpdate>
          {({ getFieldValue }) => {
            const currType = getFieldValue('type')
            return (
              <StyledFooterForm>
                <StyledButton type="primary" ghost onClick={onPrevious}>Previous</StyledButton>
                <StyledButton type="primary"
                  disabled={
                    currType === 'http' && !httpRequest?.url ||
                  currType === 'script' && !getFieldValue('stateKeyMap')}
                  onClick={onSaveAndContinue}
                  loading={creatingAction}
                >
                  {'Save & Continue'}
                </StyledButton>
              </StyledFooterForm>
            )
          }}
        </FormItem>
      </CustomForm>
    </StyledConfigForm>
  )
}

export default Config

const StyledConfigForm = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;

  ${StyledTag} {
    color: rgba(0, 0, 0, 0.65);
  }
`