import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import Notification from '../../../util/Notification'
import { useRevalidateFetch } from '../../useFetch'

type Result = {
  importingTriggerAction: boolean,
  importTriggerAction: (applicationId: number, versionId: number, file?: any, afterSuccess?: () => void) => Promise<any>
}

export default function useImportTriggerAction(): Result {
  const [loading, setLoading] = useState(false)
  const { revalidateAllTrigger, revalidateAllAction } = useRevalidateFetch()

  return {
    importingTriggerAction: loading,
    importTriggerAction: (applicationId, versionId, file, afterSuccess) => {
      return new Promise((resolve, reject) => {
        setLoading(true)
        if (!file) {
          setLoading(false)
          return
        }
        const formData = new FormData()
        formData.append('upload', file)
        Implikasi.post(`/application/import?applicationId=${applicationId}&versionId=${versionId}`,  formData, {
          headers : {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'type': 'formData'
          }
        })
          .then(() => {
            setLoading(false)
            Notification.success({
              message: 'You have successfully import your file',
            })
            revalidateAllTrigger(applicationId, versionId)
            revalidateAllAction(applicationId, versionId)
            resolve(null)
            afterSuccess?.()
          })
          .catch((e) => {
            setLoading(false)
            reject(e)
          })
      })
    }
  }

}