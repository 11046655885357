import { Modal, Radio, Space, Tooltip } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { useLoggedInUser } from '../../../../../contexts/LoggedInUserContextProvider'
import usePublicApplications from '../../../../../hooks/application/main/usePublicApplications'
import useAPricingPlan from '../../../../../hooks/pricingPlan/useAPricingPlan'
import { StyledBoxIcon, StyledButton, StyledFooterForm } from '../../../../../pages/components/StyledComponents'
import CustomForm from '../../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../../pages/developer/components/form/FormItem'
import { primary65 } from '../../../../../util/colors'
import { compareString } from '../../../../../util/functions'

type ChooseNewActionTypeModalProps = {
  isLastState?: boolean,
  onClose: () => void,
  visible: boolean,
  onSubmit: (values: any) => void
}

const ChooseNewActionTypeModal: FC<ChooseNewActionTypeModalProps> = ({ isLastState, visible, onClose, onSubmit }) => {
  const [form] = useForm()
  const [user] = useLoggedInUser()
  const { pricingPlan } = useAPricingPlan(user?.pricingPlanId)
  const { publicApplications } = usePublicApplications({ isNativeApp: 'true', expands: 'action' })
  const getWidth = () => {
    const prevWidth = localStorage.getItem('widthStateForms')
    if (prevWidth) {
      return parseInt(prevWidth)
    }
    return undefined
  }
  const width = getWidth()

  useEffect(() => {
    form.setFieldsValue({ actionType: 'app' }) // set default form value to 'app'
  }, [])

  return (
    <StyledModal
      width={(width ? width > 800 ? 800 : width : 800) - 100}
      style={{
        top: '14vw',
        marginLeft: `calc(${width}px / 5)`
      }}
      closable
      onCancel={onClose}
      visible={visible}
      footer={null}
      forceRender={true} // to make error go away. Reference: https://github.com/ant-design/pro-blocks/issues/205#issuecomment-632121328
    >
      <h5 style={{ color: primary65 }}>What happens here?</h5>
      <CustomForm onFinish={onSubmit} form={form}>
        <FormItem
          name="actionType"
          extra={<p style={{ color: primary65 }}>Select <strong>one</strong> of the above</p>}
        >
          <StyledRadioGroup>
            <StyledRadioButton value="app" >
              <Space direction="vertical">
                <CustomBoxIcon size="large">
                  <img src={'/apps-logo/action-in-app.svg'} />
                </CustomBoxIcon>
                Action is an App
              </Space>
            </StyledRadioButton>
            {/* TODO: enable when we need to implement condition/path */}
            {
              publicApplications?.filter(app => app.actions && app.actions.length > 0)
                .sort((app1, app2) => compareString(app1.name, app2.name))
                .map((application) => {
                  const { id, actions, logoUrl, name } = application

                  const values = actions?.find(action => action.type === 'path') ? { type: 'condition', name: 'IF/ELSE Condition' } : { applicationId: id, name: name }

                  if (values?.type === 'transform') {
                    return null
                  }

                  return (
                    <Tooltip key={id} title={values?.type === 'condition' ? !pricingPlan?.isConditionalStep ? 'Upgrade to Grow plan to use Conditional path' : !isLastState ? 'Conditional path only available on the last step of scenario or path' : '' : ''}>
                      <StyledRadioButton key={id}  value={values?.applicationId || values?.type} disabled={values?.type === 'condition' && (!isLastState || !pricingPlan?.isConditionalStep)}>
                        <Space direction="vertical">
                          <CustomBoxIcon>
                            <img src={logoUrl || '/placeholder.png'} />
                          </CustomBoxIcon>
                          {values?.name}
                        </Space>
                      </StyledRadioButton>
                    </Tooltip>
                  )
                })
            }
          </StyledRadioGroup>
        </FormItem>
        <StyledFooterForm>
          <StyledButton onClick={onClose} htmlType="button">Cancel</StyledButton>
          <StyledButton type="primary" htmlType="submit">Add</StyledButton>
        </StyledFooterForm>
      </CustomForm>
    </StyledModal>
  )
}

export default ChooseNewActionTypeModal

const StyledRadioGroup = styled(Radio.Group)`
  /* Blue/1 */
  background: #F0FBFF;
  /* Blue/4 */
  border: 0.5px dashed #3E73E3;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: max-content;
  flex-wrap: wrap;
  padding: 20px 10px 10px;
`

const CustomBoxIcon = styled(StyledBoxIcon)`
  background: #FFF;
  width: 53px;
  height: 53px;
  margin: auto;
  svg {
    font-size: 28px;
  }
  img {
    width: 40px;
    height: 40px;
  }
`

const StyledRadioButton = styled(Radio.Button)`
  background: none !important;
  border: none !important;
  outline: none !important;
  width: 100px;
  height: 100px;
  margin: auto;

  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 15px;
  /* or 125% */
  text-align: center;
  img {
    filter: grayscale(1)
  }
  color: ${primary65};
  &:hover, &:visited, &:active, &:focus-within {
    /* Blue/4 */
    color: #3E73E3;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    ${CustomBoxIcon} {
      border-color: #3E73E3;
    }
    img {
      filter: none;
    }
  }
  &.ant-radio-button-wrapper::before {
    height: 0;
  }
  &.ant-radio-button-wrapper:first-child {
    border: none;
  }
  &.ant-radio-button-wrapper-disabled {
    :hover {
      background: none;
      border: none;
      ${CustomBoxIcon} {
        border-color: #D9D9D9;
      }
    }
    img {
      filter: grayscale(1) opacity(0.5);
    }
    color: rgba(0, 0, 0, 0.25);
  }
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    &, &:hover, &:visited, &:active, &:focus-within {
      /* Blue/4 */
      color: #3E73E3;
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
      ${CustomBoxIcon} {
        border-color: #3E73E3;
      }
      img {
        filter: none;
      }
    }
  }
`

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 50px;
  }
`