import { main } from '@popperjs/core'
import { FormInstance } from 'antd'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/selection/selection-pointer'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Controlled as Codemirror } from 'react-codemirror2'
import ReactDOMServer from 'react-dom/server'
import OutsideClickHandler from 'react-outside-click-handler'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import useDynamicOptions from '../../../../../hooks/application/main/useDynamicOptions'
import useTrigger from '../../../../../hooks/application/trigger/useTrigger'
import { orange } from '../../../../../util/colors'
import { formatterValueBracket } from '../../../../../util/formatter'
import truncateLongData from '../../../../../util/truncateLongData'
import { DataSuggestion } from '../../../developer/formItems/DataPickerPopper'
import DualModeDataPickerPopper from './DataPickerPopper/DualModeDataPickerPopper'
import { StyledCloseCircleRichInput } from './RichTextInput'
import { useValueArray } from './StateForm/ArrayMapStateFormItem/ValueArrayContext'
import { useStateTestData } from './StateTestDataContext'
import { useThisStateStaticValue } from './ThisStateStaticValueContext'




const observeReferenceModifier = {
  // size change observer for the codemirror textarea
  // so that the popper can update data picker position
  name: 'observeReferenceModifier',
  enabled: true,
  phase: main,
  effect: (effectArg: any) => {
    const { state, instance } = effectArg
    const RO_PROP = '__popperjsRO__'
    const { reference } = state.elements

    reference[RO_PROP] = new ResizeObserver(() => {
      instance.update()
    })

    reference[RO_PROP].observe(reference)
    return () => {
      reference[RO_PROP].disconnect()
      delete reference[RO_PROP]
    }
  }
}

type CodeMirrorPosition = { line: number, start: number, stop: number, tag: string }

export type RichFormItemProps = {
  id: string,
  value: string, // Ant Form need this for custom input
  onChange: (value: string) => void, // Ant Form need this for custom input
  hiddenTriggerId?: number,
  form?: FormInstance,
  onFocusOut?: () => void,
  bundleId?: number,
  options: { label: string, value: string }[]
}

const RichSelectInput: FC<RichFormItemProps> = ({ id, onFocusOut, value, onChange, hiddenTriggerId, bundleId, form, ...otherProps }) => {
  const cm = useRef<any>(null)
  const [stateTestDataValue] = useStateTestData()
  const [arrayMap] = useValueArray()

  const stateTestData = arrayMap?.dataSuggestion || stateTestDataValue
  const cmContainerElement = useRef<HTMLDivElement>(null)
  const dataPickerElement = useRef<HTMLDivElement>(null)
  const [showDataPicker, setShowDataPicker] = useState<boolean>(false)
  const [listOfMarkedText, setListOfMarkedText] = useState<any[]>([])
  const [cmReadOnlyOption, setCmReadOnlyOption] = useState<boolean | 'nocursor'>()
  const [thisStateStaticValue] = useThisStateStaticValue()
  const paramsDynamicOptions = useRef({
    applicationId: thisStateStaticValue?.application?.id,
    bundleId: bundleId,
    formValue: thisStateStaticValue?.params,
    triggerId: hiddenTriggerId
  })
  const { dynamicOptions, fetchDynamicOption, fetchingDynamicOptions } = useDynamicOptions()
  const [page, setPage] = useState(0)
  const [isNoLoadMore, setIsNoLoadMore] = useState(false)
  const { trigger } = useTrigger(thisStateStaticValue?.application?.id, hiddenTriggerId)
  const isUsingPagination = trigger?.isUsingPagination

  const [options, setOptions] = useState<{ value: string, label: string }[]>(otherProps.options)

  useEffect(() => {
    paramsDynamicOptions.current = {
      applicationId: thisStateStaticValue?.application?.id,
      bundleId: bundleId,
      formValue: thisStateStaticValue?.params,
      triggerId: hiddenTriggerId
    }
  }, [thisStateStaticValue?.application, bundleId, thisStateStaticValue?.params])

  useEffect(() => {
    if (dynamicOptions && paramsDynamicOptions.current.bundleId) {
      // every time user change bundleId
      // clear the editor content, marked text, and history
      cm.current.setValue('')
      cm.current.clearHistory()
      listOfMarkedText.forEach(markedText => markedText.clear())
    }
  }, [paramsDynamicOptions.current.bundleId])

  useEffect(() => {
    if (value && !dynamicOptions && hiddenTriggerId) {
      fetchDynamicOption({
        ...paramsDynamicOptions.current,
        bundles: {
          bundle: {
            meta: {
              page: page
            }
          }
        }
      })
    }
  }, [value, bundleId])

  useEffect(() => {
    if (dynamicOptions) {
      if (isUsingPagination) {
        if (dynamicOptions.length === 0) {
          setIsNoLoadMore(true)
        } else {
          setIsNoLoadMore(false)
        }
        const newOptionsObject = [...options, ...dynamicOptions].reduce((acc, curr: any) => {
          return {
            ...acc,
            [curr.value]: {
              value: curr.value,
              label: curr.label
            }
          }
        }, {})
        const newOptions = Object.keys(newOptionsObject).map((key) => ({ value: key, label: newOptionsObject[key]?.label }))
        setOptions(newOptions)
      } else {
        setOptions(dynamicOptions)
      }
    }
  }, [dynamicOptions])

  const [dataPickerMode, setDataPickerMode] = useState<'select' | 'custom'>()
  useEffect(() => {
    if (value !== undefined && dataPickerMode === undefined) {
      const valueIsAnOption = options.filter(o => o.value === value).length > 0
      const valueHasCustomTag = getTagPositions(value).length > 0

      if (valueIsAnOption) setDataPickerMode('select')
      else if (valueHasCustomTag) setDataPickerMode('custom')
      else setDataPickerMode('select')
    }
  }, [value, dataPickerMode, options])
  // console.log({ value, options, stateTestData, dataPickerMode })

  const { styles, attributes } = usePopper(cmContainerElement.current, dataPickerElement.current, {
    strategy: 'absolute',
    placement: 'bottom-start',
    modifiers: [
      observeReferenceModifier,
      { name: 'flip', enabled: false } // disabled flip modifiers so the popper will stay on the bottom
    ]
  })

  const getTagPositions = (text: string) => {
    const lines = text.split('\n')
    const re = /\{\{\{.+?\}\}\}/g
    const positions: { line: number, start: any, stop: any, tag: string }[] = []
    let m

    for (let i = 0; i < lines.length; i++) {
      while ((m = re.exec(lines[i])) !== null) {
        const tag = m[0].substring(3, m[0].length - 3).replace(/\[.+?\]/gi, (v) => v.substr(1, v.length - 2))
        positions.push({
          line: i,
          start: m.index,
          stop: m.index + m[0].length,
          tag,
        })
      }
    }
    return positions
  }

  const transformToVisualTag = (cm: any, positions: CodeMirrorPosition[], stateTestData: Record<string, DataSuggestion>) => {
    positions.forEach(position => {
      const markedText = cm.markText(
        { line: position.line, ch: position.start },
        { line: position.line, ch: position.stop },
        {
          replacedWith: createCustomDataTagVisual(position, stateTestData)
        }
      )

      setListOfMarkedText(prevValue => [...prevValue, markedText])
    })
  }

  const transformSelectToVisualTag = (cm: any, options: { label: string, value: string }[]) => {
    const value = cm.getValue()
    const selectedValueObj = options.filter(o => o.value.toString() === value)[0]

    if (value !== '' && value !== undefined && selectedValueObj) {
      // console.log({ value, selectedValueObj })
      // important!
      // this marking will only works on top of the assumption that the value of CM is a one-liner when mode='select'
      const markedText = cm.markText(
        { line: 0, ch: 0 },
        { line: 0, ch: value.length },
        {
          replacedWith: createSelectDataTagVisual(selectedValueObj)
        }
      )

      setListOfMarkedText(prevValue => [...prevValue, markedText])
    }
  }

  const insertTag = (keyData: string, editor: any) => {
    if (editor) {
      editor.replaceRange(formatterValueBracket(`{{{${keyData}}}}`), editor.getCursor())
      editor.focus()
    }
  }

  /**
   * @param {String} HTML representing a single element
   * @return {Element}
   *
   * credit to mark-amery https://stackoverflow.com/a/35385518
   */
  const htmlToElement = (html: string) => {
    const template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
  }

  const createCustomDataTagVisual = (position: { line: number, start: number, stop: number, tag: string }, stateWithTestData: Record<string, DataSuggestion>) => {
    const tagStateId = position.tag.split('.')[0]
    const tagDataKey = position.tag.split('.').slice(1).join('.')
    const selectedState = stateWithTestData[tagStateId]

    if (selectedState && stateWithTestData[tagStateId].flatTestData?.[tagDataKey]) {
      const selectedTagTestData = selectedState.flatTestData[tagDataKey]
      const sample = selectedTagTestData.sample
      const truncatedSample = truncateLongData(sample, 40) // template literal is necessary to forces boolean parsed into a string

      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          {
            selectedTagTestData.appLogoUrl ?
              <img src={selectedTagTestData.appLogoUrl || '/placeholder.png'} alt=" " /> :
              null
          }
          <b>{selectedTagTestData.label}:</b> {truncatedSample}
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    }else if (selectedState?.flatTestData?.[position.tag]) {
      const selectedTagTestData = selectedState.flatTestData[position.tag]
      const truncatedSample = truncateLongData(selectedTagTestData.sample, 40) // template literal is necessary to forces boolean parsed into a string
      const label = `${selectedTagTestData.label.replace(/\(array\)|\(object\b\)/g, (v) => `<span style="color: ${orange[5]}" >${v}</span>`)}:`
      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          {
            selectedTagTestData.appLogoUrl ?
              <img src={selectedTagTestData.appLogoUrl || '/placeholder.png'} alt=" " /> :
              null
          }
          <b dangerouslySetInnerHTML={{ __html: label }}></b> {truncatedSample}
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    }  else {
      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          <b>{position.tag}: </b> <i>no data</i>
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    }
  }

  const createSelectDataTagVisual = (selectedValue: { label: string, value: string}) => {
    const CodeMirrorSelectTag = () =>
      <CodeMirrorSelectValueTagContainer>
        {selectedValue.label}
      </CodeMirrorSelectValueTagContainer>

    return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorSelectTag />))
  }

  const onBeforeChange = (_editor: any, _data: any, value: string) => {
    onChange?.(value)
  }

  const onEditorDidMount = (editor: any) => {
    cm.current = editor

    if (dataPickerMode === 'custom' && stateTestData) {
      transformToVisualTag(editor, getTagPositions(editor.getValue()), stateTestData)
    } else if (dataPickerMode === 'select' && options) {
      transformSelectToVisualTag(editor, options)
    }
  }

  const onCodeMirrorValueChange = (editor: any) => {
    if (dataPickerMode === 'custom') {
      if (stateTestData) {
        transformToVisualTag(editor, getTagPositions(editor.getValue()), stateTestData)
      }
    } else if (dataPickerMode === 'select') {
      if (options.length > 0) {
        transformSelectToVisualTag(editor, options)
      }
    } else {
      // do nothing
    }
  }

  const onCmContainerClick = useCallback(() => {
    // console.log('onCmContainerClick')
    if (dataPickerMode === 'select') {
      // styled the wrapper when textarea is focused
      const wrapper = cmContainerElement.current

      if (wrapper) {
        wrapper.style.boxShadow = '0 0 0 2px rgba(243, 173, 61, 20%)'
        wrapper.style.borderColor = '#F3AD3D'

        // show data picker
        // setIsComponentVisible(true)
      }
    }

    fetchDynamicOption({
      ...paramsDynamicOptions.current,
      formValue: form?.getFieldsValue(),
      bundles: {
        bundle: {
          meta: {
            page: page
          }
        }
      }
    })
    setShowDataPicker(true)
    // else do nothing
  }, [dataPickerMode])

  useEffect(() => {
    if (!showDataPicker) {
      onFocusOut?.()
    }
  }, [showDataPicker])

  const onLoadMore = () => {
    fetchDynamicOption({
      ...paramsDynamicOptions.current,
      formValue: form?.getFieldsValue(),
      bundles: {
        bundle: {
          meta: {
            page: page + 1
          }
        }
      }
    })
    setPage(page + 1)
  }

  const onFocus = () => {
    // styled the wrapper when textarea is focused
    const wrapper = cmContainerElement.current

    if (wrapper) {
      wrapper.style.boxShadow = '0 0 0 2px rgba(243, 173, 61, 20%)'
      wrapper.style.borderColor = '#F3AD3D'

      // show data picker
      // setIsComponentVisible(true)
      // console.log('params')
      // fetchDynamicOption({
      //   ...paramsDynamicOptions.current,
      //   formValue: form?.getFieldsValue(),
      // })
      setShowDataPicker(true)
    }
  }

  const onBlur = () => {
    // unstyled the wrapper when mouse leave focus
    const wrapper = cmContainerElement.current
    if (wrapper) {
      wrapper.style.boxShadow = 'none'
      wrapper.style.borderColor = '#d9d9d9'
    }
  }

  useEffect(() => {
    if (dataPickerMode === 'select') {
      setCmReadOnlyOption('nocursor')
      cm.current.setOption('selectionPointer', 'pointer')
    } else if (dataPickerMode === 'custom') {
      cm.current.setOption('selectionPointer', 'text')

      if (showDataPicker) {
        cm.current.focus()
      }

      setCmReadOnlyOption(false)
    } else {
      // do nothing
    }
  }, [dataPickerMode])

  useEffect(() => {
    if (stateTestData && cm.current && dataPickerMode === 'custom') {
      // reset all previous marked text
      listOfMarkedText.forEach(markedText => markedText.clear())
      // transform tag to visual if there is any change on test data
      transformToVisualTag(cm.current, getTagPositions(cm.current.getValue()), stateTestData)
    }
  }, [stateTestData, dataPickerMode])

  useEffect(() => {
    if (options.length > 0 && cm.current && dataPickerMode === 'select') {
      // reset all previous marked text
      listOfMarkedText.forEach(markedText => markedText.clear())
      // transform tag to visual if there is any change on test data
      transformSelectToVisualTag(cm.current, options)
    }
  }, [options, dataPickerMode, thisStateStaticValue, bundleId])

  const onDataPickerModeChange = (mode: 'select' | 'custom', cm: any) => {
    setDataPickerMode(mode)

    // every time user change data picker mode
    // clear the editor content, marked text, and history
    cm.setValue('')
    cm.clearHistory()
    listOfMarkedText.forEach(markedText => markedText.clear())
  }

  const onTagClick = (tagValue: string) => insertTag(tagValue, cm.current)

  const onChangeOption = (selected: { label: string, value: string }) => {
    cm.current.setValue(`${selected.value}`) // make sure value is string, codemirror angy when it's not

    // unstyled the wrapper when mouse leave focus
    const wrapper = cmContainerElement.current
    if (wrapper) {
      wrapper.style.boxShadow = 'none'
      wrapper.style.borderColor = '#d9d9d9'
    }

    // close data picker after user select an option
    setShowDataPicker(false)
  }

  const onOutsideContainerClick = () => {
    setShowDataPicker(false)

    const wrapper = cmContainerElement.current
    if (wrapper) {
      wrapper.style.boxShadow = 'none'
      wrapper.style.borderColor = '#d9d9d9'
    }
  }

  const onClear = () => cm.current?.setValue('')

  const showClearButton = (cm.current?.getValue()?.length || 0) > 0

  return (
    <RichFormItemContainer id={id}>
      <OutsideClickHandler onOutsideClick={onOutsideContainerClick}>
        <CodeMirrorContainer ref={cmContainerElement} dataPickerMode={dataPickerMode || 'select'} onClick={onCmContainerClick}>
          <Codemirror
            value={value || ''}
            onBeforeChange={onBeforeChange}
            onChange={onCodeMirrorValueChange}
            editorDidMount={onEditorDidMount}
            onFocus={onFocus}
            onBlur={onBlur}
            options={{
              mode: null,
              lineNumbers: false,
              lineWrapping: true,
              scrollbarStyle: null,
              autoRefresh: true,
              readOnly: cmReadOnlyOption, // because default data picker will be 'select' mode
              // selectionPointer: 'pointer' // default cursor: pointer, data picker will be 'select' by default
            }}
          />
          {showClearButton ? <StyledCloseCircleRichInput onClick={onClear} /> : null}
        </CodeMirrorContainer>
        <PopperComponent
          isVisible={showDataPicker}
          ref={dataPickerElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {
            stateTestData && showDataPicker ?
              <DualModeDataPickerPopper
                pagination={{
                  isNoLoadMore: isNoLoadMore,
                  onLoadMore: onLoadMore,
                  isUsingPagination: isUsingPagination
                }}
                loading={fetchingDynamicOptions}
                mode={dataPickerMode || 'select'}
                selectedValue={cm.current?.getValue() || ''}
                hiddenTriggerId={hiddenTriggerId}
                options={options}
                statesWithTestData={stateTestData}
                onTagClick={onTagClick}
                onChangeOption={onChangeOption}
                onModeChange={(mode) => onDataPickerModeChange(mode, cm.current)}
              />

              : null
          }
        </PopperComponent>
      </OutsideClickHandler>
    </RichFormItemContainer>
  )
}

export default RichSelectInput

export const RichFormItemContainer = styled.div`
  position: relative;
`

export const PopperComponent = styled.div<{ isVisible: boolean }>`
  width: 100%;
  display: block;
  z-index: 99;
  position: relative;
  top: 0;
  transition: all .24s;
  visibility: hidden;
  opacity: 0;

  ${props => props.isVisible ?
    `
      top: 4px !important;
      visibility: visible !important;
      opacity: 1 !important;
    ` : ''}
`

export const CodeMirrorContainer = styled.div<{ dataPickerMode: 'select' | 'custom' }>`
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition-property: top, visibility, opacity;
  transition-duration: .3s;
  margin-bottom: 16px;
  cursor: ${props => props.dataPickerMode === 'select' ? 'pointer' : 'text'};

  &:hover {
    border-color: #F3AD3D !important;
  }

  & .CodeMirror {
    font-family: 'DM Sans', sans-serif;
    height: fit-content;
    min-height: 36px;
    padding: 4px 11px;
    color: rgba(0,0,0,.85);
    background-color: #fff;
    background-image: none;
    transition: all .3s;
  }

  & .CodeMirror-scroll {
    overflow: auto !important;
  }

  & .CodeMirror-scroll .CodeMirror-sizer {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  & .CodeMirror-scroll .CodeMirror-sizer .CodeMirror-lines {
    padding: 0;
    cursor: ${props => props.dataPickerMode === 'select' ? 'pointer' : 'text'};
  }

  & .CodeMirror-scroll .CodeMirror-sizer .CodeMirror-lines .CodeMirror-code {
    line-height: 1.8rem;
  }
`

export const CodeMirrorDataTagContainer = styled.span`
  border: 1px solid #d9d9d9;
  background-color: #F5F5F5;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  display: inline-flex;
  align-items: center;
  line-height: 1rem !important;
  vertical-align: middle;
  font-size: 12px;
  margin-top: -2px;

  & b {
    font-size: 12px;
  }

  & img {
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`

export const CodeMirrorSelectValueTagContainer = styled.span`
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  display: inline-flex;
  align-items: center;
  line-height: 1rem !important;
  vertical-align: middle;
  margin-top: -2px;
`