import { Col, Row, Space } from 'antd'
import { State } from 'db'
import { FC } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useCreateAScenarioState from '../../../../../hooks/scenario/state/useCreateAScenarioState'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import { StyledButton, StyledDescription } from '../../../../../pages/components/StyledComponents'
import AvatarApplicationGroups from '../../AvatarApplicationGroups'
import StateContextMenu from './StateContextMenu'
import { useStateChildsData } from './StateTestDataContext'

interface Props {
  scenarioId: number,
  pathStates: State[],
  states?: State[]
}
const StateContentPaths: FC<Props> = ({ pathStates, states, scenarioId }) => {
  const { revalidateAScenario } = useRevalidateFetch()
  const [stateChilds] = useStateChildsData()
  const { createAScenarioState, creatingAScenarioState } = useCreateAScenarioState()
  const history = useHistory()
  const { updateAScenarioState } = useUpdateAScenarioState()

  const onAddPath = async (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    // A = 65 in ascii
    const baseCharAsNumber = 64
    const state = pathStates[0]
    const nPathState = states?.filter(state => state.conditionGroupOrder).length || 0
    const parentId = state?.parentId
    // create  path state
    const newStateId = await createAScenarioState(state.scenarioId, {
      parentId: parentId,
      actionId: state.actionId,
      order: state.order,
      stateConditionId: state.stateConditionId,
      conditionGroupOrder: state.order,
      name: `Path ${String.fromCharCode(baseCharAsNumber + nPathState + 1)}`
    }).then(({ data }) => data.state.id)

    if (newStateId && parentId) {
      updateAScenarioState(state.scenarioId, parentId, {
        childrenIds: [...pathStates.map(s => s.id), newStateId]
      }).then(() => {
        revalidateAScenario(state.scenarioId)
      })
    }
  }

  const onEdit = (stateId: number) => {
    history.replace(`/app/scenario/${scenarioId}/edit/${stateId}`)
  }

  return (
    <>
      {
        pathStates.sort((s1, s2) => s1.id - s2.id).map(pathState => {
          const { id, scenarioId, name } = pathState
          const nSteps = stateChilds?.[id] ? stateChilds[id].length + 1 : 1
          const applicationChilds = stateChilds?.[id]?.sort((s1, s2) => s1.order - s2.order).map(stateChild => stateChild.application)
          return (
            <StyledRow key={id} gutter={[16, 16]} align="middle">
              <Col flex="auto">
                <Space align="center" >
                  <div>
                    <StyledH5>{name || 'Path'}</StyledH5>
                    <StyledP>{nSteps} step{nSteps > 1 ? 's' : ''}</StyledP>
                  </div>
                  <AvatarApplicationGroups applications={applicationChilds} />
                </Space>
              </Col>
              <Col>
                <Space>
                  <StyledButton onClick={() => onEdit(id)}>Edit</StyledButton>
                  <StateContextMenu
                    parentStateId={pathState.parentId}
                    childrenStateIds={pathStates.filter(s => s.id !== pathState.id).map(s => s.id)}
                    nextStateIds={stateChilds? stateChilds[id] : undefined}
                    onClickEdit={() => onEdit(id)}
                    disabled={false}
                    scenarioId={scenarioId}
                    stateId={id}
                    type={'path'}
                  />
                </Space>
              </Col>
            </StyledRow>
          )
        })
      }
      <StyledButton onClick={onAddPath} loading={creatingAScenarioState}>Add Path</StyledButton>
    </>
  )
}

export default StateContentPaths

const StyledRow = styled(Row)`
  background: #FFFFFF;
  /* Neutral/4 */
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 12px;
  margin-bottom: 12px;
`
const StyledP = styled(StyledDescription)`
  margin: 0;
  padding: 0;
`
const StyledH5 = styled.h5`
  margin: 0;
`