import { MenuOutlined } from '@ant-design/icons'
import { Input, Popconfirm, Space, Table, Tag, Typography } from 'antd'
import arrayMove from 'array-move'
import { FieldSchema } from 'db'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { SortableContainer as sortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import styled from 'styled-components'
import { useDataSuggestion } from '../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useCreateOrUpdateAuthentication from '../../../../../../hooks/application/authentication/useCreateUpdateAuthentication'
import { useRevalidateFetch } from '../../../../../../hooks/useFetch'
import { StyledAlert, StyledButton, StyledCard, StyledFooterForm, StyledLabel } from '../../../../../components/StyledComponents'
import CustomForm from '../../../../components/form/CustomForm'
import FormItem from '../../../../components/form/FormItem'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { PropsContentStep } from '../create/data'
import FieldForm from './FieldForm'
import { useSharedValues } from './SharedValuesContextProvider'

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableContainer = sortableContainer((props: any)=> <tbody {...props} />)

const FieldSchemaForm: FC<PropsContentStep> = ({ isRequired, onContinue, isActive, info, isSaveOnContinue }) => {
  const { revalidateAllAuthentication } = useRevalidateFetch()
  const [values] = useSharedValues()
  const fields = values?.fieldSchema

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => creatingAuthentication ? null: <DragHandle />,
    },
    { title: 'Label', dataIndex: 'label' },
    { title: 'Key', dataIndex: 'key' },
    { title: 'Type', dataIndex: 'type' },
    { title: 'Tag', dataIndex: 'required', render: (required: boolean) => required ? <Tag color="red">Required</Tag> : null },
    { title: 'Action', dataIndex: 'key', render: (_: any, record: FieldSchema) => {
      return (
        <Space>
          <Typography.Link onClick={() => edit({ ...record, idx: fields?.findIndex(field => field.key === record.key) })}>
            Edit
          </Typography.Link>
          <Popconfirm title="Sure to cancel?" onConfirm={() => onDelete(record)}>
            <a>Delete</a>
          </Popconfirm>
        </Space>
      )
    }, },
  ]

  const { applicationId } = useParams<{ applicationId: string }>()
  const [version] = useApplicationVersionContext()
  const { createOrUpdateAuthentication, creatingAuthentication } = useCreateOrUpdateAuthentication()
  const [_, setDataSuggestion] = useDataSuggestion()
  const [onAddFieldForm, setOnAddFieldForm] = useState(false)
  const [activeEditFieldForm, setActiveEditFieldForm] = useState<FieldSchema & { idx?: number }>()
  const isFormActive  = onAddFieldForm || activeEditFieldForm
  const [isOnDragging, setIsOnDragging] = useState(false)

  useEffect(() => {
    if (fields && fields.length > 0) {
      const flatTestData= fields.reduce((acc, field) => ({
        ...acc,
        [`authData.${field.key}`]: {
          sample: field.default,
          value: `authData.${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      const testData = fields.reduce((acc, field) => ({
        ...acc,
        [`${field.key}`]: {
          sample: field.default,
          value: `${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      setDataSuggestion(prevState => ({
        ...prevState,
        authData: {
          flatTestData,
          order: 0,
          testData: testData,
          name: 'Auth Data',
          application: undefined
        }
      }))
    }
  }, [fields])

  const edit = (record: FieldSchema & { key: React.Key, idx?: number }) => {
    setActiveEditFieldForm(record)
  }

  const onDelete = async (record: Partial<FieldSchema> & { key: React.Key }) => {
    const temp = values?.fieldSchema?.filter(field => record.key !== field.key)
    const newAuth = await createOrUpdateAuthentication(applicationId, { ...values, fieldSchema: temp, isDraft: values?.isDraft === undefined ? true : values?.isDraft, versionId: values?.versionId || version?.id })
    if (newAuth) {
      revalidateAllAuthentication(applicationId, values?.versionId || version?.id)
    }

  }

  const onClickAdd = () => {
    setOnAddFieldForm(true)
  }

  const onSaveAndContinue = async (allValues: any) => {
    if (isSaveOnContinue) {
      const create = await createOrUpdateAuthentication(applicationId, { ...values, ...allValues, isDraft: false, versionId: values?.versionId || version?.id })
      if (create) {
        revalidateAllAuthentication(applicationId, values?.versionId || version?.id)
      }
      onContinue()
    } else {
      const create = await createOrUpdateAuthentication(applicationId, { ...values, ...allValues, isDraft: values?.isDraft === undefined ? true : values?.isDraft, versionId: values?.versionId || version?.id })
      if (create) {
        revalidateAllAuthentication(applicationId, values?.versionId || version?.id)
      }
      onContinue()
    }
  }

  const onCancel = () => {
    setOnAddFieldForm(false)
    setActiveEditFieldForm(undefined)
  }

  const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const temp: any[] = []
      const newData = arrayMove(temp.concat(fields), oldIndex, newIndex).filter(el => !!el).map(field => ({ ...field, idx: undefined }))
      setIsOnDragging(true)
      const newAuth = await createOrUpdateAuthentication(values?.applicationId || 0,
        { ...values, fieldSchema: newData, isDraft: values?.isDraft === undefined ? true : values.isDraft,
          versionId: values?.versionId })

      if (newAuth) {
        revalidateAllAuthentication(values?.applicationId, values?.versionId)
      }
      setIsOnDragging(false)
    }
  }


  const DraggableContainer = (props: any) =>
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />


  const DraggableBodyRow = (props: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = fields?.findIndex(x => x.key === props['data-row-key'])
    return <SortableItem index={index} {...props} />
  }

  return (
    <StyledFieldSchema className={isActive ? 'show' : undefined}>
      {
        isFormActive ? null :
          <>
            {info ? <><StyledAlert message={<div dangerouslySetInnerHTML={{ __html: info }} />} type="info" /><br/></> : null}
            <StyledLabel className={isRequired ? 'required' : undefined}>Authentication Fields</StyledLabel>
            <br />
            <p>Build a form with fields for each item your API requires for authentication, including a field for your API key and additional field for any other data needed. Otomatis does not include any fields by default.</p>
            <br />
            {
              isRequired && (fields?.length === 0 || !fields) ?
                <StyledAlert type="warning" showIcon closable message="You must define at least one field where your users can enter API credentials. Your authentication configuration is not complete" />
                :
                null
            }
            <br />
          </>
      }
      {
        isFormActive ?
          <FieldForm activeEditFieldForm={activeEditFieldForm} onCancel={onCancel} /> :
          <>
            <Table
              locale={{ emptyText: '' }}
              rowKey='key'
              scroll={{ x: true }}
              loading={isOnDragging}
              dataSource={fields}
              columns={columns}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
            <br />
            <CustomForm onFinish={onSaveAndContinue} initialValues={{
              helpText: values?.helpText
            }}>
              <FormItem name="helpText" label="Help Text" description="Add general help text for user to fill those fields">
                <Input.TextArea />
              </FormItem>
              <StyledFooterForm>
                <StyledButton htmlType="button" onClick={onClickAdd}>Add Fields</StyledButton>
                <StyledButton type="primary" htmlType="submit" disabled={isRequired && (fields?.length === 0 || !fields)} loading={creatingAuthentication}>Continue</StyledButton>
              </StyledFooterForm>
            </CustomForm>
          </>

      }
    </StyledFieldSchema>
  )
}

export default FieldSchemaForm

const StyledFieldSchema = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;
`