import { InputNumber } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import useApplication from 'src/hooks/application/main/useApplication'
import useUpdateApplication from 'src/hooks/application/main/useUpdateApplication'
import { StyledButton } from '../../../../pages/components/StyledComponents'
import CustomForm from '../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../pages/developer/components/form/FormItem'
import Notification from '../../../../util/Notification'

const TaskTimeoutSettings: FC = () => {
  const [form] = useForm()
  const { updateApplication, updatingApplication } = useUpdateApplication()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { application } = useApplication(applicationId)

  useEffect(() => {
    if (application) {
      const { timeOut } = application
      form.setFieldsValue({
        timeOut: timeOut ? timeOut / 1000 : 30
      })
    }
  }, [application])

  const onSubmit = (values: any) => {
    if (application) {
      updateApplication(application?.id, {
        timeOut: Number(values.timeOut) * 1000
      }).then(() => {
        Notification.success({
          message: 'Settings updated'
        })
      })
    }
  }
  return (
    <>
      <h4>Task Timeout Settings</h4>
      <CustomForm form={form} onFinish={onSubmit}>
        <FormItem name="timeOut" label="Timeout in seconds" description="How long Otomatis should wait for App response. Default: 30 seconds" >
          <InputNumber type="number" min={1} max={60} />
        </FormItem>
        <FormItem shouldUpdate>
          {
            ({ getFieldValue, getFieldsError }) => {
              const isHasError = getFieldsError().reduce((acc, field) => {
                if (field.errors.length > 0) {
                  return true
                }
                return acc
              }, false)
              return (
                <StyledButton
                  type="primary"
                  style={{ float: 'right' }}
                  htmlType="submit"
                  disabled={!application || updatingApplication || isHasError || getFieldValue('timeOut') == application.timeOut}
                  loading={updatingApplication}
                >
                  Save
                </StyledButton>
              )
            }
          }
        </FormItem>
      </CustomForm>
    </>
  )
}

export default TaskTimeoutSettings