import { Member } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  deletedMember?: Member,
  deletingMember: boolean,
  errorDeleteMember?: Error,
  deleteMember: (applicationId: number, memberId: number) => void
}

export default function useDeleteMember(): Result {
  const { data, error, isValidating, call } = useApi<{ member: Member }>()

  return {
    deletedMember: data?.member,
    deletingMember: isValidating,
    errorDeleteMember: error,
    deleteMember: (applicationId, memberId) => {
      call({
        url: `/application/${applicationId}/member/${memberId}`,
        method: 'delete',
      })
    }
  }
}