import { AxiosPromise } from 'axios'
import { Action } from 'db'
import { Error } from '../../../types'
import useFetch, { useApi } from '../../useFetch'


type Result = {
  action?: Action,
  fetchingAction: boolean,
  errorFetchAction?: Error
}

export default function useAction(applicationId?: number | string, actionId?: number | string, versionId?: number): Result {
  const url = actionId && applicationId && versionId ? `/application/${applicationId}/action/${actionId}${versionId ? `?versionId=${versionId}` : ''}` : null
  const { data, error, isValidating } = useFetch<{ action: Action }>(url, {}, { revalidateOnFocus: false })

  return {
    action: data?.action,
    fetchingAction: isValidating,
    errorFetchAction: error
  }
}

interface GetAction extends Result {
  getAction: (params: { applicationId: number, versionId?: number, actionId: number }) => AxiosPromise<{ action: Action }>
}
export function useGetAction(): GetAction {
  const { call, data, isValidating, error } = useApi<{ action: Action }>()

  const getAction = ({ applicationId, actionId, versionId }: { applicationId: number, versionId?: number, actionId: number }) => {
    return call({
      method: 'get',
      url: `/application/${applicationId}/action/${actionId}${versionId ? `?versionId=${versionId}` : ''}`
    })
  }
  return {
    getAction,
    action: data?.action,
    fetchingAction: isValidating,
    errorFetchAction: error
  }
}