import React, { FC, useEffect, useState } from 'react'
import { Scenario } from 'db'
import { Space, Dropdown, Menu, Switch, Button, Modal, Tooltip } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import useArchiveAScenario from '../../../../../hooks/useArchiveAScenario'
import useDisableAScenario from '../../../../../hooks/useDisableAScenario'
import useEnableAScenario from '../../../../../hooks/useEnableAScenario'
import useResetAScenario from '../../../../../hooks/scenario/useResetAScenario'
import useStatusPayment from '../../../../../hooks/payment/useStatusPayment'
import { TableState } from '../../../../../types'
import { danger } from '../../../../../util/colors'
import useDuplicateAScenario from '../../../../../hooks/scenario/useDuplicateAScenario'
import useExportScenario from '../../../../../hooks/scenario/useExportScenario'
import useCreateTemplateScenarioFromScenario from '../../../../../hooks/scenario/useCreateTemplateScenarioFromAScenario'
import { useLoggedInUser } from '../../../../../contexts/LoggedInUserContextProvider'
import { openNewTabOnClick } from '../../../../../util/functions'

export interface ScenarioTableActionsProps {
  scenario: Scenario, onSuccessArchivedScenario?: () => void, tableState?: TableState<Scenario>
}
const ScenarioTableActions: FC<ScenarioTableActionsProps> = ({ scenario, onSuccessArchivedScenario, tableState }) => {
  const { archiveScenario, archivingScenario, isSuccessArchivingScenario } = useArchiveAScenario(tableState)
  const { disableScenario, disablingScenario } = useDisableAScenario(tableState)
  const { enableScenario, enablingScenario } = useEnableAScenario(tableState)
  const { duplicateAScenario, isDuplicatingScenario } = useDuplicateAScenario(tableState)
  const { resetScenario, resetingScenario } = useResetAScenario()
  const { maxScenario, maxTask, totalUsage, scenariosCount, status } = useStatusPayment()
  const [errorPayment, setErrorPayment] = useState<string>()
  const { exportScenario, exporting } = useExportScenario()
  const [loggedInUser] = useLoggedInUser()
  const { createATemplateScenario, isCreatingTemplateScenario } = useCreateTemplateScenarioFromScenario()

  const isActionsDisabled = archivingScenario || disablingScenario || enablingScenario || resetingScenario || isCreatingTemplateScenario

  const toggleScenario = (scenario: Scenario) => {
    if (scenario.active) {
      disableScenario(scenario.id)
    } else {
      enableScenario(scenario.id)
    }
  }

  useEffect(() => {
    if (isSuccessArchivingScenario && onSuccessArchivedScenario) {
      onSuccessArchivedScenario()
    }
  }, [isSuccessArchivingScenario])

  useEffect(() => {
    if (maxScenario && scenariosCount && maxScenario !== 0 && scenariosCount >= maxScenario) {
      setErrorPayment('You already hit your scenario limit')
    } else if (maxTask && totalUsage && totalUsage >= maxTask) {
      setErrorPayment(undefined)
    // } else if (status === 'past_due') {
    //   setErrorPayment('your scenarios are stopped until you complete your payment')
    // } else if (status === 'unpaid') {
    //   setErrorPayment('your scenarios are stopped until you complete your payment')
    } else {
      setErrorPayment(undefined)
    }
  }, [maxScenario, maxTask, totalUsage, scenariosCount, status])

  const onClickArchive = () => {
    Modal.confirm({
      title: `Are you sure you want to archive "${scenario.name}" scenario?`,
      content: 'You can\'t access your scenario again. This action cannot be undone.',
      onOk() {
        archiveScenario(scenario.id)
      }
    })
  }
  return (
    <Space>
      <Tooltip title={errorPayment}>
        <CustomSwitch
          checkedChildren={<div style={{ width: '24px' }}>ON</div>}
          unCheckedChildren={<div style={{ width: '24px' }}>OFF</div>}
          checked={scenario.active}
          onChange={() => toggleScenario(scenario)}
          loading={disablingScenario || enablingScenario}
          disabled={isActionsDisabled || !!errorPayment}
        />
      </Tooltip>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key={0} onClick={onClickArchive} disabled={scenario.active} style={{ color: danger }}>
              <Tooltip title={scenario.active ? 'You can\'t archive this scenario, please stop the scenario first to archive this scenario' : ''}>
                Archive
              </Tooltip>
            </Menu.Item>
            <Menu.Item key={1} onClick={() => resetScenario(scenario.id)}>Reset</Menu.Item>
            <Menu.Item key={2} onClick={() => duplicateAScenario(scenario.id)}>Duplicate</Menu.Item>
            <Menu.Item key={3} onClick={() => exportScenario(scenario)}>Export</Menu.Item>
            {
              loggedInUser?.isAdmin ?
                <Menu.Item key={4} onClick={() => createATemplateScenario(scenario.id).then(() => {
                  openNewTabOnClick('/app/template-scenario/list/prebuilt')
                })}>Create Template from this scenario</Menu.Item>
                :
                null
            }
          </Menu>
        }
        trigger={['click']}
        placement='bottomRight'
        disabled={isActionsDisabled}
      >
        <ScenarioMoreActionsButton loading={isDuplicatingScenario || exporting || isCreatingTemplateScenario} disabled={isActionsDisabled} type='text' icon={
          <EllipsisOutlined style={{ color: 'rgba(0, 0, 0, .45)', verticalAlign: 'middle', textAlign: 'center' }} />
        } />
      </Dropdown>
    </Space>
  )

}

export default ScenarioTableActions

const ScenarioMoreActionsButton = styled(Button)`
  border: 0;

  &:hover {
    background-color: rgba(0, 0, 0, .06);
  }
`

const CustomSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #96C443;
  }
`