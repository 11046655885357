import { User } from 'db'
import Cookie from 'js-cookie'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useLoggedInUser } from '../../contexts/LoggedInUserContextProvider'
import { Implikasi } from '../../service/Implikasi'
// import Notification from '../../util/Notification'



export default function useUser(params?: { notProtected?: boolean }): [User | null | undefined, Dispatch<SetStateAction<User | null | undefined>>] {
  const [user, setUser] = useState<User | null | undefined>(null)
  const [_, setLoggedInUser] = useLoggedInUser()
  const { url } = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    let cancelEffect = false
    if (!Cookie.get('authorization') && url.includes('login')) {
      return
    }
    Implikasi.get('/user/me')
      .then(({ data }) => {
        if (!cancelEffect) {
          setUser(data.user)
          setLoggedInUser(data.user)
        }
      })
      .catch(() => {
        if (!params?.notProtected) {
          history.replace('/auth/login')
        }

        // return Notification.error({
        //   message: 'Something\'s Wrong',
        //   description: 'Failed on get user detail'
        // })
      })
    return () => {
      cancelEffect = true
    }
  }, [])

  return [user, setUser]
}