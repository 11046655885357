import { Divider, Space, Spin, Typography } from 'antd'
import { Application } from 'db'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useCreateScenarioFromTemplate from 'src/hooks/scenario/template/useCreateScenarioFromTemplate'
import useAScenario from 'src/hooks/useAScenario'
import { StyledBoxIcon, StyledButton } from 'src/pages/components/StyledComponents'
import { getDistinctApplications, openNewTabOnClick } from 'src/util/functions'
import styled from 'styled-components'

const ScenarioInfo: FC = () => {
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const { scenario, fetchingScenario } = useAScenario(scenarioId ? parseInt(scenarioId) : undefined, true)
  const [appsUsed, setAppsUsed] = useState<Application[]>()
  const { createScenarioFromTemplate, creatingScenarioFromTemplate } = useCreateScenarioFromTemplate()

  const onClickCreateScenarioFromTemplate = () => {
    createScenarioFromTemplate(parseInt(scenarioId)).then((scenario) => {
      if (scenario) {
        openNewTabOnClick(`/app/scenario/${scenario.id}/edit`)
      }
    })
  }

  useEffect(() => {
    setAppsUsed(getDistinctApplications(scenario?.states))
  }, [scenario?.states])

  if (fetchingScenario && !scenario) {
    return (

      <Container className="bg-white max-h-screen overflow-y-hidden p-3 w-64 flex flex-col">
        <Spin spinning >
          <div className="h-screen" />
        </Spin>
      </Container>
    )
  }

  return (
    <Container className="bg-white max-h-screen overflow-y-hidden p-3 w-64 flex flex-col justify-between">
      <div className="overflow-y-auto">
        <Typography.Title level={4}>{scenario?.name}</Typography.Title>
        <StyledDivider />
        <div >
          {
            scenario?.templateMetadata?.description ?
              <>
                <Typography.Paragraph>
                  {scenario?.templateMetadata?.description}
                </Typography.Paragraph>
                <StyledDivider />
              </>:
              null
          }
          <Typography.Paragraph>
            {'This is a predefined template that will make the creation of your scenario much easier. Of course, you can always expand and customize the scenario to meet your own individual needs.'}
          </Typography.Paragraph>
          <StyledDivider />
          <Space>
            {appsUsed?.map((application) => {
              return (
                <StyledBoxIcon>
                  <img src={application?.logoUrl  || '/placeholder.png'} />
                </StyledBoxIcon>
              )
            })}
          </Space>
        </div>
        <StyledDivider />
      </div>
      <div className="flex flex-col space-y-2" >
        {
          scenario?.templateMetadata?.blogUrl ?
            <StyledButton onClick={() => scenario?.templateMetadata?.blogUrl && openNewTabOnClick(scenario?.templateMetadata?.blogUrl)} htmlType="button" >See Blog Related</StyledButton> :
            null
        }
        {
          scenario?.templateMetadata?.videoUrl ?
            <StyledButton onClick={() => scenario?.templateMetadata?.videoUrl && openNewTabOnClick(scenario?.templateMetadata?.videoUrl)} htmlType="button">See Video Demo</StyledButton> :
            null
        }
        <StyledButton onClick={onClickCreateScenarioFromTemplate} loading={creatingScenarioFromTemplate} htmlType="button" type="primary">Create Scenario</StyledButton>
      </div>
    </Container>
  )
}

export default ScenarioInfo

const Container = styled.div`
  box-shadow: 1px 0px 0px #F0F0F0;
  z-index: 3;
`
const StyledDivider = styled(Divider)`
  background: rgba(0, 0, 0, 0.45);
  margin: 16px 0;
`