import { CrownFilled } from '@ant-design/icons'
import { Card, Col, Row, Select, Skeleton, Space, Tooltip } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Action, Trigger } from 'db'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import useAllAction from '../../../../../hooks/application/action/useAllAction'
import usePublicApplications from '../../../../../hooks/application/main/usePublicApplications'
import useAllTrigger from '../../../../../hooks/application/trigger/useAllTrigger'
import useStatusPayment from '../../../../../hooks/payment/useStatusPayment'
import { StyledBoxIcon, StyledButton } from '../../../../../pages/components/StyledComponents'
import CustomForm from '../../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../../pages/developer/components/form/FormItem'
import { orange, primary65 } from '../../../../../util/colors'
import { sortApplications, sortTriggerActions } from '../../../../../util/functions'
import { StyledBadgeOtomatis, StyledBadgePremium, StyledBadgePrivate } from '../create/StepOne/SearchApplication'

export interface EditStateEventProps {
  addTrigger?: boolean,
  actionId?: number,
  triggerId?: number,
  applicationId?: number,
  submitting?: boolean,
  onCancel?: () => void,
  onChangeEventId?: (eventId?: number, name?: string) => void
}

const EditStateEvent: FC<EditStateEventProps> = ({ addTrigger, actionId, triggerId, applicationId, onCancel, onChangeEventId, submitting }) => {
  const params: { [key: string]: string } | undefined = actionId ? { expands: 'action' } : triggerId ? { expands: 'trigger' } : undefined
  const { publicApplications, fetchingPublicApplications } = usePublicApplications(params)
  const [selectedApplicationId, setSelectedApplicationId] = useState<number>()
  const { triggers, fetchingAllTriggers } = useAllTrigger(triggerId || addTrigger ? selectedApplicationId : undefined)
  const { actions, fetchingAllActions } = useAllAction(actionId ? selectedApplicationId : undefined)
  const [form] = useForm()
  const [options, setOptions] = useState<(Trigger | Action)[]>()
  const { plan } = useStatusPayment()

  useEffect(() => {
    form.setFieldsValue({
      applicationId,
      eventId: addTrigger ? null : triggerId || actionId
    })
  }, [triggerId, applicationId, actionId, addTrigger])

  useEffect(() => {
    if (triggers) {
      setOptions(triggers.filter(trigger => trigger.visibility === 'visible'))
    }
    if (actions) {
      setOptions(actions.filter(action => !action.isDraft))
    }
  }, [actions, triggers])

  useEffect(() => {
    setSelectedApplicationId(applicationId)
  }, [applicationId])

  const onValuesChange = (value: any) => {
    const keys = Object.keys(value)
    if (keys.includes('applicationId')) {
      setSelectedApplicationId(value.applicationId)
      form.setFieldsValue({
        eventId: null
      })
    }
  }

  const onFinish = (values: any) => {
    if (values.eventId && onChangeEventId) {
      onChangeEventId(values.eventId, triggers?.find(trigger => trigger.id === values.eventId)?.name || actions?.find(action => action.id === values.eventId)?.name)
    }
  }

  return (
    <StyledCardEditStateEvent>
      <Row>
        <Col flex="auto">
          <CustomForm onValuesChange={onValuesChange} onFinish={onFinish} form={form}>
            <Row justify="space-between" align="middle" gutter={[16, 16]} >
              <Col>
                <h4 style={{ color: primary65 }}>{addTrigger ? 'Add Trigger' : `Edit ${actionId ? 'Action' : 'Trigger'}`}</h4>
              </Col>
              <Col>
                <Space>
                  <StyledButton htmlType="button" onClick={onCancel}>Cancel</StyledButton>
                  <StyledButton type="primary" htmlType="submit" loading={submitting}>Save</StyledButton>
                </Space>
              </Col>
            </Row>
            <FormItem notUseInfoRequired label="App" name="applicationId" rules={[{ required: true, message: 'App is required' }]}>
              {
                publicApplications ?
                  <Select
                    filterOption={(input, option) =>
                      option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="Select App" loading={fetchingPublicApplications}>
                    {
                      publicApplications?.filter(app =>
                        triggerId ?
                          app.triggers && app.triggers.length > 0
                          :
                          app.actions && app.actions.length > 0 && app.actions.filter(action => action.type === 'path').length === 0
                      ).sort(sortApplications).map(application =>{
                        const { id, logoUrl, name, isPremiumApp } = application
                        return (
                          <Select.Option key={id} value={id} title={name}  disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                            <Tooltip placement="topLeft" title={!plan?.isUsingPremiumApps && isPremiumApp ? `Upgrade to Professional plan to use ${name}` : ''}>
                              <Space style={{ width: '100%' }}>
                                <StyledBoxIcon size="small" disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                                  <img src={logoUrl || '/placeholder.png'} />
                                </StyledBoxIcon>
                                {name}
                                {
                                  !application.public ?
                                    <StyledBadgePrivate ellipsis title={'Private'}>Private</StyledBadgePrivate> :
                                    application.isPremiumApp ?
                                      <StyledBadgePremium ellipsis><CrownFilled style={{ color: orange[4] }} /> Premium</StyledBadgePremium> :
                                      application.isNativeApp ?
                                        <StyledBadgeOtomatis>Otomatis</StyledBadgeOtomatis> :
                                        null
                                }
                              </Space>
                            </Tooltip>
                          </Select.Option>
                        )
                      }
                      )
                    }
                  </Select>
                  :
                  <Skeleton.Input active />
              }
            </FormItem>
            <FormItem notUseInfoRequired label="Event" name="eventId" rules={[{ required: true, message: 'Event is required' }]}>
              {
                options ?
                  <Select
                    disabled={!selectedApplicationId}
                    filterOption={(input, option) =>
                      option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="Select Event"
                    loading={fetchingAllActions || fetchingAllTriggers}
                  >
                    {
                      options?.sort(sortTriggerActions).filter((option: Trigger | Action) => option.id).map((option: Trigger | Action) => {
                        const { id, name } = option
                        return (
                          <Select.Option key={id} value={id} title={name}>
                            {name}
                          </Select.Option>
                        )
                      })
                    }
                  </Select>
                  :
                  <Skeleton.Input active />
              }
            </FormItem>
          </CustomForm>
        </Col>
      </Row>
    </StyledCardEditStateEvent>
  )
}

export default EditStateEvent

const StyledCardEditStateEvent = styled(Card)`
  width: 100%;
  margin-top: 12px;
  .ant-skeleton-element {
    width: 100%;
  }
`