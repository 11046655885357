import { CrownFilled } from '@ant-design/icons'
import { SelectProps, Select, Tooltip, Space } from 'antd'
import { Application } from 'db'
import { FC } from 'react'
import styled from 'styled-components'
import useStatusPayment from '../../../hooks/payment/useStatusPayment'
import { StyledBoxIcon, StyledText } from '../../../pages/components/StyledComponents'
import { orange } from '../../../util/colors'
import { sortApplications } from '../../../util/functions'

interface Props extends SelectProps<any> {
  applications?: Application[]
}

const ApplicationSelectField: FC<Props> = ({ applications, ...selectProps }) => {
  const { plan } = useStatusPayment()
  return (
    <Select
      filterOption={(input, option) =>
        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder="Select App"
      showSearch
      {...selectProps}
    >
      {
        applications?.sort(sortApplications).map(application =>{
          const { id, logoUrl, name, isPremiumApp } = application
          return (
            <Select.Option key={id} value={id} title={name} disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
              <Tooltip placement="topLeft" title={!plan?.isUsingPremiumApps && isPremiumApp ? `Upgrade to Professional plan to use ${name}` : ''}>
                <Space>
                  <CustomSmallStyledBoxIcon disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                    <img src={logoUrl || '/placeholder.png'} />
                  </CustomSmallStyledBoxIcon>
                  {name}
                  {
                    !application.public ?
                      <StyledBadgePrivate ellipsis title={'Private'}>Private</StyledBadgePrivate> :
                      application.isPremiumApp ?
                        <StyledBadgePremium ellipsis><CrownFilled style={{ color: orange[4], padding: 0, margin: 0 }} /> Premium</StyledBadgePremium> :
                        application.isNativeApp ?
                          <StyledBadgeOtomatis>Otomatis</StyledBadgeOtomatis> :
                          null
                  }
                </Space>
              </Tooltip>
            </Select.Option>
          )
        })
      }
    </Select>
  )
}

export default ApplicationSelectField

const CustomSmallStyledBoxIcon = styled(StyledBoxIcon)`
  width: 20px;
  height: 20px;
  img {
    width: 17px;
    height: 17px;
  }
`
export const StyledBadgeOtomatis = styled(StyledText)`
  color: #3E73E3;
  background: #F0FBFF;
  /* Blue/4 */

  border: 1px solid #3E73E3;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
  vertical-align: middle !important;
  line-height: 12px;
`
export const StyledBadgePremium = styled(StyledBadgeOtomatis)`
  color: #82612C;
  background: #F9EBCB;
  /* Orange/3 */

  border: 1px solid #EEC56F;
`

export const StyledBadgePrivate = styled(StyledBadgeOtomatis)`
  color: #8C8C8C;
 /* Neutral/3 */
  background: #F5F5F5;
  /* Neutral/7 */
  border: 1px solid #8C8C8C;
`