import { AuthenticationSchema } from 'db'
import { Error } from '../../types'
import useFetch from '../useFetch'

type Result = {
  authenticationSchemas?: AuthenticationSchema[],
  fetchingAllAuthenticationSchemas: boolean,
  errorFetchAllAuthenticationSchema?: Error
}

export default function useAllAuthenticationSchema(): Result {
  const url = '/authenticationSchemas'
  const { data, error, isValidating } = useFetch<{ authenticationSchemas: AuthenticationSchema[] }>(url, {}, { revalidateOnFocus: false })

  return {
    authenticationSchemas: data?.authenticationSchemas,
    fetchingAllAuthenticationSchemas: isValidating,
    errorFetchAllAuthenticationSchema: error
  }
}