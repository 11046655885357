import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'

type ContextValueType = {
  [stateId: string]: boolean
}

const StateFocusContext = createContext<ContextValueType | null>(null)
const StateFocusSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)
const StateVisitedFocusContext = createContext<ContextValueType | null>(null)
const StateVisitedFocusSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode,
  initValue?: ContextValueType | null
}

const StateFocusContextProvider: FC<Props> = ({ children, initValue }) => {
  const [stateFocus, setStateFocus] = useState<ContextValueType | null>(null)
  const [visitedStates, setVisitedStates] = useState<ContextValueType | null>(null)

  useEffect(() => {
    if (!stateFocus && initValue) {
      setStateFocus(initValue)
    }
  }, [initValue])

  return (
    <StateFocusContext.Provider value={stateFocus}>
      <StateFocusSetterContext.Provider value={setStateFocus}>
        <StateVisitedFocusContext.Provider value={visitedStates}>
          <StateVisitedFocusSetterContext.Provider value={setVisitedStates}>
            {children}
          </StateVisitedFocusSetterContext.Provider>
        </StateVisitedFocusContext.Provider>
      </StateFocusSetterContext.Provider>
    </StateFocusContext.Provider>
  )
}


export function useStateFocus(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>, ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(StateFocusContext)
  const setterContext = useContext(StateFocusSetterContext)
  const visitedStatesContext = useContext(StateVisitedFocusContext)
  const setterVisitedStatesContext = useContext(StateVisitedFocusSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useStateFocus must be used on child component of StateFocusContextProvider component'
  }

  return [valueContext, setterContext, visitedStatesContext, setterVisitedStatesContext]
}

export default StateFocusContextProvider