import { Member } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  updatedMember?: Member,
  updatingMember: boolean,
  errorUpdateMember?: Error,
  updateMember: (applicationId: number, memberId: number, payload: Partial<Member>) => void
}

export default function useUpdateMember(): Result {
  const { data, error, isValidating, call } = useApi<{ member: Member }>()

  return {
    updatedMember: data?.member,
    updatingMember: isValidating,
    errorUpdateMember: error,
    updateMember: (applicationId, memberId, payload) => {
      call({
        url: `/application/${applicationId}/member/${memberId}`,
        method: 'patch',
        data: {
          member: payload
        }
      })
    }
  }
}