import { message, Row, Spin } from 'antd'
import qs from 'query-string'
import { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import useAuthenticationCallback from '../../../hooks/application/authentication/useAuthenticationCallback'
import { IMPLIKASI_URL } from '../../../util/Constant'
import { getRedirectOAuthUrl } from '../../../util/formatter'
import Notification from '../../../util/Notification'

const OauthReturn: FC = () => {
  const { app, authUuid } = useParams<{ app: string, authUuid: string }>()
  const applicationId = app.replace(/[^\d]/g, '')
  const { location } = useHistory()
  const params = qs.parse(location.search)
  const { result, fetchingAuthenticationCallback, errorFetchAuthenticationCallback } = useAuthenticationCallback(applicationId, authUuid, { ...params, state: params.state || 'test', redirectUrl: getRedirectOAuthUrl(applicationId, authUuid) })

  useEffect(() => {
    if (result?.bundle && !result.bundle.error) {
      const windowAsAny = window as any
      windowAsAny.bundles = {
        ...windowAsAny.authData,
        ...result
      }
      windowAsAny.opener?.postMessage({ source: 'otomatis', payload: result }, IMPLIKASI_URL)

      windowAsAny.authData = undefined
      message.success('Succes authenticated')
      window.close()
    } else if (result && result.bundle?.error) {
      Notification.error({
        message: 'Login Failed',
        description: JSON.stringify(result.error)
      })
      setTimeout(() => {
        window.close()
      }, 2000)
    }
  }, [result])

  useEffect(() => {
    if (errorFetchAuthenticationCallback) {
      Notification.error({
        message: 'Something\'s Wrong',
        description: 'Failed to get callback'
      })
    }
  }, [errorFetchAuthenticationCallback])

  return (
    <Spin spinning={fetchingAuthenticationCallback}>
      <Row style={{ height: '100vh' }}></Row>
    </Spin>
  )
}

export default OauthReturn