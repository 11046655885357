import { Scenario, State } from 'db'
import { useEffect, useState } from 'react'
import { Error, TableState } from '../../types'
import { useApiScenario } from '../useAScenario'
import { useRevalidateFetch } from '../useFetch'
import useCreateScenario from './useCreateScenario'

type Result = {
  isDuplicatingScenario: boolean,
  duplicateAScenario: (scenarioId: number) => Promise<boolean>,
  errorDuplicateAScenario?: Error
}

export default function useDuplicateAScenario(tableState?: TableState<Scenario>): Result {
  const { createScenario, errorCreateScenario } = useCreateScenario()
  const [isDuplicatingScenario, setIsDuplicatingScenario] = useState(false)
  const { getScenario, errorFetchScenario } = useApiScenario()
  const { revalidateAScenario, revalidateScenarios } = useRevalidateFetch()


  const duplicateAScenario  = (scenarioId: number) => {
    setIsDuplicatingScenario(true)
    const duplicateScenario = getScenario(scenarioId, true)
      .then(async ({ data }) => {
        const { states, name, id, folderId } = data.scenario
        return await createScenario({ name: `Copy of ${name} - from ID:${id}`, folderId, states: states?.map(state => {
          const { realTestData, testData, triggerId, actionId, order, name, bundleId, params, conditionGroupOrder, conditions, stateConditionId, id, parentId, childrenIds } = state
          return {
            name: name || state.trigger?.name || state.action?.name, realTestData,
            testData, triggerId, actionId, order, bundleId, params, conditionGroupOrder, conditions, stateConditionId, id, childrenIds, parentId
          }
        }) as State[] })
          .then(() => {
            setIsDuplicatingScenario(false)
            // revalidate any component that used Scenario model
            revalidateScenarios(tableState)
            revalidateAScenario(scenarioId)
            return true
          })
      }).catch(() => {
        setIsDuplicatingScenario(false)
        return false
      })
    return duplicateScenario
  }

  useEffect(() => {
    if (errorCreateScenario || errorFetchScenario) {
      setIsDuplicatingScenario(false)
    }
  }, [errorCreateScenario, errorFetchScenario])

  return {
    errorDuplicateAScenario: errorCreateScenario || errorFetchScenario,
    isDuplicatingScenario,
    duplicateAScenario
  }
}