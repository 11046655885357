import { Bundle } from 'db'
import { Error } from '../../types'
import useFetch from '../useFetch'


type Result = {
  bundlesByApplicationId?: Bundle[],
  fetchingBundlesByApplicationId: boolean,
  errorFetchBundlesByApplicationId?: Error
}

export default function useBundlesByApplicationId(applicationId?: number | string): Result {
  const url = applicationId ? `/bundles?applicationId=${applicationId}` : null
  const { data, error, isValidating } = useFetch<{ bundles: Bundle[] }>(url, {}, { revalidateOnFocus: false })

  return {
    bundlesByApplicationId: data?.bundles,
    fetchingBundlesByApplicationId: isValidating,
    errorFetchBundlesByApplicationId: error
  }
}