
import { SorterResult } from 'antd/lib/table/interface'
import { Scenario } from 'db'
import { Implikasi } from '../service/Implikasi'
import { Error, TableState } from '../types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from '../util/Constant'
import { compareString } from '../util/functions'
import useFetch from './useFetch'


type Result = {
  scenariosLength?: number,
  scenarios?: Scenario[],
  fetchingScenarios: boolean,
  errorFetchScenarios?: Error
}

type UseScenarioConfig = {
  withDeleted?: boolean,
  expandState?: boolean,
  searchByBundleId?: number
}

export default function useScenarios({ expandState, withDeleted }: UseScenarioConfig = {}, tableState?: TableState<Scenario>): Result {
  const key = `/scenarios-${expandState ? 'state' : ''}-${withDeleted ? 'withDeleted' : ''}-${tableState ? JSON.stringify(tableState) : ''}`
  const url = '/scenarios'

  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const sorters: SorterResult<Scenario>[] | undefined = Array.isArray(tableState?.sorter) ? tableState?.sorter : undefined
  const sorterOrder = (tableState?.sorter as SorterResult<Scenario>)?.order
  const sorterField = (tableState?.sorter as SorterResult<Scenario>)?.field
  const filters = tableState?.filter || {}
  const search = (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
    if (field === 'search') {
      const ids = (filters[field] as string)?.match(/\d+/g) || []
      const searchByIds = ids.length > 0 ? `,scenario.id.in=(${ids.join(',')})` : ''
      return `${res},${searchByIds ? 'or=(' : ''}scenario.name.ilike=${filters[field]}${searchByIds ? `${searchByIds})` : ''}`
    }
    if (field === 'deletedAt'  && filters[field]) {
      return `${res},scenario.${field}.is=not null`
    }
    // if (field === 'applicationId' && filters[field]) {
    //   return `${res},(applicationTrigger.id in (${(filters[field] as string[])?.map(val => `'${val}'`).join(', ')}) or applicationAction.id in (${(filters[field] as string[])?.map(val => `'${val}'`).join(', ')}))`
    // }
    if (filters[field]) {
      return `${res},scenario.${field}.in=(${(filters[field] as string[])?.map(val => `'${val}'`).join(',')})`
    }
    return res
  }, 'true')

  const { data, error, isValidating } = useFetch<{ scenarios: Scenario[], length: number }>(
    key,
    //   [`scenario.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
    // } :  sorters?.sort((s1, s2) => s1.field?.toString().localeCompare(s2.field?.toString() || '') || 0)?.reduce((res, sorter) => {
    //   const sorterField = sorter.field
    //   const sorterOrder = sorter.order
    //   return {
    //     ...res,
    //     [`scenario.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
    //   }
    // }, {}),
    // search: search
    {},
    {
      fetcher: async () => {
        const res = await Implikasi(url, {
          method: 'get',
          params: {
            withDeleted: withDeleted || filters['deletedAt'] ? 'true' : undefined,
            expands: expandState ? 'state' : undefined,
            skip: (currentPage - 1) * pageSize,
            take: pageSize,
            orderBy: sorterField && sorterOrder ? `scenario.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}` :
              sorters?.sort((s1, s2) => compareString(s1.field?.toString(), s2.field?.toString()))?.reduce((res, sorter, index) => {
                const sorterField = sorter.field
                const sorterOrder = sorter.order
                return `${res}${index === 0 ? '' : ','}scenario.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}`
              }, ''),
            'and': `(${search})`,
          },
          data: {
            // orderBy: sorterField && sorterOrder ? {
            //   [`scenario.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
            // } :  sorters?.sort((s1, s2) => s1.field?.toString().localeCompare(s2.field?.toString() || '') || 0)?.reduce((res, sorter) => {
            //   const sorterField = sorter.field
            //   const sorterOrder = sorter.order
            //   return {
            //     ...res,
            //     [`scenario.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
            //   }
            // }, {}),
            // search: search
          }
        })
        return res.data
      },
      revalidateOnFocus: false }
  )

  return {
    scenariosLength: data?.length,
    scenarios: data?.scenarios,
    fetchingScenarios: isValidating,
    errorFetchScenarios: error
  }
}

type ParamsScenariosByTaskRunAt = {
  start: number,
  end: number
}

export function useScenariosByTaskRunAt(param?: ParamsScenariosByTaskRunAt): Result {
  const key = param ? `/scenarios-${param ? JSON.stringify(param) : ''}` : null
  const url = '/scenarios'

  const { data, error, isValidating } = useFetch<{ scenarios: Scenario[], length: number }>(
    key,
    {},
    {
      fetcher: async () => {
        const res = await Implikasi(url, {
          method: 'get',
          params: {
            withDeleted: true,
            expands: 'state',
            orderBy: 'scenario.lastExecuted:desc',
            and: `scenario.updatedAt.between=${new Date(param?.start || 0).toISOString()}_${new Date(param?.end || 0).toISOString()}`
          },
        })
        return res.data
      },
      revalidateOnFocus: false }
  )

  return {
    scenariosLength: data?.length,
    scenarios: data?.scenarios,
    fetchingScenarios: isValidating,
    errorFetchScenarios: error
  }

}

export function useScenariosWithLimitedData(): Result {
  const key = '/scenarios-useScenariosWithLimitedData'
  const url = '/scenarios'

  const { data, error, isValidating } = useFetch<{ scenarios: Scenario[], length: number }>(
    key,
    {},
    {
      fetcher: async () => {
        const res = await Implikasi(url, {
          method: 'get',
          params: {
            withDeleted: true,
            orderBy: 'scenario.lastExecuted:desc',
          },
        })
        return res.data
      },
      revalidateOnFocus: false }
  )

  return {
    scenariosLength: data?.length,
    scenarios: data?.scenarios,
    fetchingScenarios: isValidating,
    errorFetchScenarios: error
  }
}