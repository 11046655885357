import { Application } from 'db'
import { Error } from '../../../types'
import useFetch from '../../useFetch'


type Result = {
  revalidateApplication: () => void,
  application?: Application,
  fetchingApplication: boolean,
  errorFetchApplications?: Error
}

export default function useApplication(applicationId?: number | string): Result {
  // note: expands version is used in manage version, list version in leftpane
  const url = applicationId ? `/application/${applicationId}?expands=version` : null
  const { data, error, isValidating, revalidateFetch } = useFetch<{ application: Application }>(url, {}, { revalidateOnFocus: false })

  return {
    revalidateApplication: () => revalidateFetch(url),
    application: data?.application,
    fetchingApplication: isValidating,
    errorFetchApplications: error
  }
}