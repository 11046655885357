import { Button, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useUpdateAScenarioState from 'src/hooks/scenario/state/useUpdateAScenarioState'
import useAScenarioState from 'src/hooks/useAScenarioState'
import { useRevalidateFetch } from 'src/hooks/useFetch'
import CustomForm from 'src/pages/developer/components/form/CustomForm'
import FormItem from 'src/pages/developer/components/form/FormItem'
import { detailStateAtomFamily, selectedStateAtomFamily } from '../../recoil'
import EventField from './EventField'

const EventForm: FC = () => {
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const stateRecoil = useRecoilValue(selectedStateAtomFamily({ scenarioId }))
  const stateId = stateRecoil?.id
  const { state } = useAScenarioState(Number(scenarioId), stateRecoil?.id, true)
  const [selectedApplicationId, setSelectedApplicationId] = useState<number>()
  const [form] = useForm()
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const setDetailState = useSetRecoilState(detailStateAtomFamily({ stateId: stateId }))

  useEffect(() => {
    if (state) {
      setDetailState(state)
      const { name, trigger, action, triggerId, actionId } = state
      form.setFieldsValue({
        name, triggerId, actionId,
        applicationId: (trigger || action)?.applicationId,
      })
      setSelectedApplicationId((trigger || action)?.applicationId)
    }
  }, [state])

  const onValuesChange = (value: any) => {
    const keys = Object.keys(value)
    if (keys.includes('applicationId')) {
      setSelectedApplicationId(value.applicationId)
      form.setFieldsValue({
        [`${state?.trigger ? 'trigger' : 'action'}Id`]: null
      })
    }
  }

  const onFinish = (values) => {
    updateAScenarioState(Number(scenarioId), Number(stateId), {
      name: values.name,
      bundleId: values.triggerId !== state?.triggerId && values.actionId !== state?.actionId ? null : undefined,
      params: values.triggerId !== state?.triggerId && values.actionId !== state?.actionId ? null : undefined,
      testData: values.triggerId !== state?.triggerId && values.actionId !== state?.actionId ? null : undefined,
      realTestData: values.triggerId !== state?.triggerId && values.actionId !== state?.actionId ? null : undefined,
      triggerId: values.triggerId,
      actionId: values.actionId
    }).then(() => {
      revalidateAScenarioState(Number(scenarioId), Number(stateId))
      revalidateAScenario(Number(scenarioId))
    })
  }

  return (
    <>
      <CustomForm form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
        <FormItem small label="Name" name={'name'}>
          <Input size="small" />
        </FormItem>
        <EventField selectedApplicationId={selectedApplicationId} form={form} type={state?.trigger ? 'trigger' : 'action'} />
        <br />
        <FormItem shouldUpdate>
          {({ getFieldValue }) => {
            const disabled = getFieldValue('name') === state?.name &&
            getFieldValue('triggerId') === state?.triggerId && getFieldValue('actionId') === state?.actionId
            return <Button style={{ float: 'right' }} disabled={disabled} loading={updatingAScenarioState} type="primary" htmlType="submit">Save</Button>
          }}
        </FormItem>
      </CustomForm>
    </>
  )
}

export default EventForm