import { createRef, MutableRefObject, RefObject, useEffect, useRef, useState } from 'react'

type ResizeOutline = {
  separatorXPosition: MutableRefObject<number | null>,
  splitPaneRef: RefObject<HTMLDivElement>,
  outlineRef: RefObject<HTMLDivElement>,
  outlineWidth: number | null,
  isResizing: boolean,
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void,
  onMouseMove: (e: React.MouseEvent<HTMLDivElement>) => void,
  onMouseUp: () => void
}
export function useResizeOutline(showOutline: boolean): ResizeOutline {
  const separatorXPosition = useRef<number | null>(null)
  const splitPaneRef = createRef<HTMLDivElement>()
  const outlineRef = createRef<HTMLDivElement>()

  const [outlineWidth, setOutlineWidth]= useState<number | null>(null)
  const [isResizing, setIsResizing] = useState(false)

  useEffect(() => {
    localStorage.setItem('widthStateForms', `${70 * document.body.clientWidth / 100}`)
  }, [])

  useEffect(() => {
    if (outlineRef.current && splitPaneRef.current && showOutline) {
      if (outlineWidth === null) {
        // initial position, half way of the container split pane
        outlineRef.current.style.flex = 'none'
        // Pixel (px) = (Viewport width unit (vw) * Viewport width) / 100
        const pxOutlineFromDefaultvw = 32 * document.body.clientWidth / 100
        const pxOutline = pxOutlineFromDefaultvw > 400 ? 400 : pxOutlineFromDefaultvw
        outlineRef.current.style.width = `${pxOutline}px`
        setOutlineWidth(pxOutline)
        return
      }
    }
  }, [showOutline])

  useEffect(() => {
    // publishChangeWidthStateForms(document.body.clientWidth - (outlineWidth || 0) - 100)
    localStorage.setItem('widthStateForms', `${document.body.clientWidth - (outlineWidth || 0) - 100}`)
  }, [outlineWidth])

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    separatorXPosition.current = e.clientX
    setIsResizing(true)
  }

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    // e.stopPropagation()
    if (!separatorXPosition.current || !splitPaneRef.current || outlineWidth === null || !outlineRef.current) {
      return
    }

    const newOutlineWidth = outlineWidth - e.clientX + separatorXPosition.current

    if (Number(separatorXPosition.current)) {
      separatorXPosition.current = e.clientX
    }

    const splitPaneWidth = splitPaneRef.current.clientWidth

    if (newOutlineWidth <= splitPaneWidth * 1/12) {
      return setOutlineWidth(splitPaneWidth * 1/12)
    }

    if (newOutlineWidth >= splitPaneWidth * 1/2) {
      return setOutlineWidth(splitPaneWidth * 1/2)
    }

    outlineRef.current.style.flex = 'none'
    outlineRef.current.style.width = `${newOutlineWidth}px`

    setOutlineWidth(newOutlineWidth)
  }

  const onMouseUp = () => {
    separatorXPosition.current = null
    setIsResizing(false)
  }

  return {
    separatorXPosition,
    splitPaneRef,
    outlineRef,
    outlineWidth,
    isResizing,
    onMouseDown,
    onMouseMove,
    onMouseUp
  }
}