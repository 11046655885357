import { Input, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect } from 'react'
import useUpdateUserSettings from '../../../../hooks/user/useUpdateUserSettings'
import useUserSettings from '../../../../hooks/user/useUserSettings'
import { StyledButton } from '../../../../pages/components/StyledComponents'
import CustomForm from '../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../pages/developer/components/form/FormItem'
import Notification from '../../../../util/Notification'

const JobErrorEmailNotifSettings: FC = () => {
  const { settings } = useUserSettings()
  const [form] = useForm()
  const { updateUserSettings, updatingUserSettings } = useUpdateUserSettings()

  useEffect(() => {
    if (settings) {
      const { isEnableErrorNotification, emails } = settings
      form.setFieldsValue({
        isEnableErrorNotification,
        stringEmails: emails.join(',')
      })
    }
  }, [settings])

  const onSubmit = (values: any) => {
    if (settings) {
      updateUserSettings(settings?.id, {
        isEnableErrorNotification: values.isEnableErrorNotification,
        emails: values.stringEmails?.trim().split(',')
      }).then(() => {
        Notification.success({
          message: 'Settings updated'
        })
      })
    }
  }
  return (
    <>
      <h4>Job Error Email Notification</h4>
      <CustomForm form={form} onFinish={onSubmit}>
        <FormItem valuePropName="checked" name="isEnableErrorNotification" label="Receive error email notification">
          <Switch />
        </FormItem>
        <FormItem name="stringEmails" label="Email recipients" description="Separate multiple email with comma" >
          <Input />
        </FormItem>
        <StyledButton
          type="primary"
          style={{ float: 'right' }}
          htmlType="submit"
          disabled={!settings || updatingUserSettings}
          loading={updatingUserSettings}
        >
          Save
        </StyledButton>
      </CustomForm>
    </>
  )
}

export default JobErrorEmailNotifSettings