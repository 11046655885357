import { SorterResult } from 'antd/lib/table/interface'
import { LogJob } from 'db'
import { useEffect } from 'react'
import { useSWRConfig } from 'swr'
import qs from 'query-string'
import { Implikasi } from '../../service/Implikasi'
import { TableState } from '../../types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from '../../util/Constant'
import useFetch from '../useFetch'
import { useHistory } from 'react-router'

type Result = {
  logJobsLength?: number,
  logJobs?: LogJob[],
  fetchingLogJobs: boolean,
  errorFetchLogJobs?: any,
  revalidateLogJobs?: () => void
}


const useLogJobs: (tableState?: TableState<LogJob>, timeSpan?: ParamsJobsByCreatedAt) => Result = (tableState, timeSpan) => {
  const key = `/logJobs${tableState ? JSON.stringify(tableState) : ''}${timeSpan ? JSON.stringify(timeSpan) : ''}`
  const url = '/logJobs'

  const { push } = useHistory()

  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const sorterOrder = (tableState?.sorter as SorterResult<LogJob>)?.order
  const sorterField = (tableState?.sorter as SorterResult<LogJob>)?.field
  const filters = tableState?.filter || {}
  const filterDeleted = filters['withDeleted']
  const dateStart: Date = filters['createdAt']?.[0] as any
  const dateEnd: Date = filters['createdAt']?.[1] as any
  // let defaultSearch = timeSpan ? `logJob.createdAt.between=${new Date(timeSpan?.start || 0).toISOString()}_${new Date(timeSpan?.end || 0).toISOString()}` : 'true'
  // defaultSearch = `${defaultSearch},${filterDeleted ? 'logJob.deletedAt.is=not null' : 'logJob.deletedAt.is=null'}`
  // const search = (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
  //   if (field === 'createdAt' && filters[field]) {
  //     const dateStart: Date = filters[field]?.[0] as any
  //     const dateEnd: Date = filters[field]?.[1] as any
  //     return `${res},logJob.createdAt.between=${dateStart.toISOString()}_${dateEnd.toISOString()}`
  //   }
  //   if (field === 'withDeleted' && filterDeleted) {
  //     return `${res},logJob.deletedAt.is=not null`
  //   }
  //   if (field === 'search') {
  //     const ids = (filters[field] as string)?.match(/\d+/g) || []
  //     const searchByIds = ids.length > 0 ? `logJob.scenarioId.in=(${ids.map(id => `'${id}'`).join(',')})` : ''
  //     // const searchByIds = ids.length > 0 ? `,logJob.scenarioId.in=(${ids.map(id => `'${id}'`).join(',')}),logJob.id.in=(${ids.map(id => `'${id}'`).join(',')})` : ''
  //     // const searchByName =`scenario.name.ilike=${filters[field]}`
  //     // return `${res},${searchByIds ? 'or=(' : ''}${searchByName}${searchByIds ? `${searchByIds})` : ''}`
  //     return `${res},${searchByIds ? `${searchByIds}` : ''}`
  //   }
  //   return `${res},logJob.${field}.in=(${(filters[field] as string[])?.map(val => `'${val}'`).join(',')})`
  // }, defaultSearch)

  const { data, error, isValidating } = useFetch<{ logJobs: LogJob[], totalItem: number }>(key, {}, {
    revalidateOnFocus: false,
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          status: filters.status ? String(filters.status) : undefined,
          orderBy: sorterField && sorterOrder ?
            `${sorterOrder === 'ascend' ? 'asc' : 'desc'}`
            : undefined,
          // and: `(${search})`,
          // expands: 'scenario',
          withDeleted: filterDeleted ? 'only' : undefined,
          jobSearch: filters['search'],
          startDate: dateStart ? dateStart.toISOString() : undefined,
          endDate: dateEnd ? dateEnd.toISOString() : undefined,
          skip: (currentPage - 1) * pageSize,
          take: pageSize,
        },
      })
      return res.data
    }
  })

  const { mutate } = useSWRConfig()

  useEffect(() => {
    if (!timeSpan) {
      const params = Object.keys(filters).reduce((res, key) => {
        if (filters[key]) {
          // if (key === 'search') {
          //   const ids = (filters[key] as string)?.match(/\d+/g) || []
          //   if (ids.length > 0) {
          //     return {
          //       ...res,
          //       scenario: ids[0]
          //     }
          //   }
          //   return res
          // }
          if (key === 'createdAt') {
            const dateStart: Date = filters[key]?.[0] as any
            const dateEnd: Date = filters[key]?.[1] as any
            return {
              ...res,
              dateStart: dateStart.toISOString(),
              dateEnd: dateEnd.toISOString()
            }
          }
          return {
            ...res,
            [key]: filters[key]
          }
        }
        return res
      }, {
        page: tableState?.pagination?.current || 1
      })
      push({
        pathname: '/app/scenario/all/history/job',
        search: qs.stringify(params)
      })
    }
  }, [tableState])

  function revalidateLogJobs(): void {
    mutate(key)
  }

  return {
    logJobsLength: data?.totalItem,
    logJobs: data?.logJobs,
    fetchingLogJobs: isValidating,
    errorFetchLogJobs: error,
    revalidateLogJobs
  }
}

export default useLogJobs


export function useRevalidateLogJobs(tableState?: TableState<LogJob>): { revalidateLogJobs: () => void } {
  const { mutate } = useSWRConfig()
  return {
    revalidateLogJobs: mutate(`/logJobs${tableState ? JSON.stringify(tableState) : ''}`)
  }
}
type ParamsJobsByCreatedAt = {
  start: number,
  end: number
}

export function useLogJobsByCreatedAt(param?: ParamsJobsByCreatedAt): Result {
  const key = param ? `/logJobs${param ? JSON.stringify(param) : ''}` : null
  const url = '/logJobs'

  const { data, error, isValidating } = useFetch<{ logJobs: LogJob[], totalItem: number }>(key, {}, {
    revalidateOnFocus: false,
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          orderBy: 'desc',
          // search: `logJob.createdAt.between=${new Date(param?.start || 0).toISOString()}_${new Date(param?.end || 0).toISOString()}`,
          startDate: param?.start ? new Date(param.start).toISOString() : undefined,
          endDate: param?.end ? new Date(param.end).toISOString() : undefined,
          // expands: 'scenario',
          withDeleted: 'include',
        },
        // data: {
        //   orderBy: {
        //     ['logJob.createdAt'] : 'desc'
        //   },
        //   search: `logJob.createdAt between '${new Date(param?.start || 0).toISOString()}' and '${new Date(param?.end || 0).toISOString()}'`
        // }
      })
      return res.data
    }
  })

  return {
    logJobsLength: data?.totalItem,
    logJobs: data?.logJobs,
    fetchingLogJobs: isValidating,
    errorFetchLogJobs: error
  }
}