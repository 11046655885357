import axios, { AxiosResponse } from 'axios'
import { useState } from 'react'

interface HitRequest {
  response?: any,
  hitRequest: (url: string, data: any) => Promise<AxiosResponse<any>>,
  fetchingRequest: boolean
}

export default function useHitRequest(): HitRequest {
  const [fetchingRequest, setFetchingRequest] = useState(false)
  const [response, setResponse] = useState<any>()

  const hitRequest = (url: string, data: any) => {
    setFetchingRequest(true)
    const posting = axios.post(url, data, {
      withCredentials: false
    })
    posting.then((response) => {
      setResponse(response)
    })
    posting.finally(() => {
      setFetchingRequest(false)
    })
    return posting
  }

  return {
    response,
    hitRequest,
    fetchingRequest
  }
}

interface HitRequests {
  rowsExecuted: number,
  rowsSucceed: number,
  rowsError: number,
  responses?: AxiosResponse<any>[],
  hitRequests: (url: string, data: any[]) => Promise<AxiosResponse<any>[]>,
  fetchingRequests: boolean
}

export function useHitRequests(): HitRequests {
  const [fetchingRequests, setFetchingRequests] = useState(false)
  const [responses, setResponses] = useState<AxiosResponse<any>[]>([])
  const rowsSucceed = responses.filter((response) => response.status >= 200 && response.status < 300).length
  const rowsError = responses.length - rowsSucceed

  const hitRequests = (url: string, datas: any[]) => {
    setFetchingRequests(true)
    setResponses([])
    const posting = Promise.all(datas.map(async (data) => {
      return await axios.post(url, data).then((response) => {
        setResponses(prevState => [...prevState, response])
        return response
      }).catch((reason) => {
        setResponses(prevState =>[...prevState, reason.response])
        return reason
      })
    }))
      .then((data) => data)
      .finally(() => setFetchingRequests(false))
    return posting
  }

  return {
    rowsExecuted: responses.length,
    rowsSucceed,
    rowsError,
    responses,
    hitRequests,
    fetchingRequests
  }
}