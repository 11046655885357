import React, { FC } from 'react'
import { AppstoreAddOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Application } from 'db'
import styled from 'styled-components'
import DefaultPNG from '../../../../../../assets/DefaultPNG'
import { StyledText } from '../../../../../../pages/components/StyledComponents'
import { blue } from '../../../../../../util/colors'

interface Props {
  application?: Application,
  idx: number,
  hasLeftConnector?: boolean,
  hasRightConnector?: boolean,
  isConnected?: boolean,
  onClickCloseButton?: (index: number) => void
}

const ApplicationItem: FC<Props> = ({ application, onClickCloseButton, idx, hasLeftConnector, hasRightConnector, isConnected }) => {
  return (
    <StyledApplicationItem isConnected={isConnected}>
      <StyledIconAndText>
        <StyledIcon>
          {
            application ?
              <ApplicationLogo background={application?.logoUrl || DefaultPNG} /> :
              <AppstoreAddOutlined />
          }
        </StyledIcon>
        <StyledText ellipsis title={application?.name}>{application?.name || `Select App ${idx + 1}`}</StyledText>
      </StyledIconAndText>
      {
        hasLeftConnector ?
          <StyledConnectionLeft></StyledConnectionLeft>: null
      }
      {
        hasRightConnector ?
          <StyledConnectionRight></StyledConnectionRight>: null
      }

      {
        application ?
          // only show remove button when application !== undefined, not an empty node
          <RemoveAppButton
            onClick={() => onClickCloseButton ? onClickCloseButton(idx) : null}
          /> : null
      }
    </StyledApplicationItem>
  )
}

export default ApplicationItem


const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F0FBFF;
  border: 2px solid ${blue[5]};
  box-sizing: border-box;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  margin: auto;
  .ant-avatar {
    background: #FFF;
    color: #595959;
  }
`

const RemoveAppButton = styled(CloseCircleFilled)`
  display: none;
  position: absolute;
  top: 0;
  right: 22px;
  font-size: 14px;
  color: ${blue[5]};
  background: #FFF;
  border-radius: 50%;
`

const ApplicationLogo = styled.div<{ background?: string }>`
  border: 2px solid #F0FBFF;
  background-image: url('${props => props.background}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 79%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
`

const StyledIconAndText = styled.div`
  .ant-typography {
    width: 90px;
    color: rgba(0, 0, 0, 0.65);
  }
`
const StyledConnectionLeft = styled.div`
  width: 26%;
  height: 2px;
  position: absolute;
  top: 36%;
  transform: translateY(-50%);
  left: 0;
  background: #3E73E3;
  &::before {
    content: '';
    width: 5px;
    height: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: #3E73E3;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
const StyledConnectionRight = styled.div`
  width: 26%;
  height: 2px;
  position: absolute;
  top: 36%;
  transform: translateY(-50%);
  right: 0;
  background: #3E73E3;
  &::before {
    content: '';
    width: 5px;
    height: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background: #3E73E3;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &::after {
    content: '';
    width: 5px;
    height: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -3px;
    background: transparent;
    border-top: 2px solid #3E73E3;
    border-bottom: 2px solid #3E73E3;
  }
`

const StyledApplicationItem = styled.div<{ isConnected?: boolean }>`
  position: relative;
  margin-right: ${props => !props.isConnected ? '4px' : 0};
  width: 90px;
  text-align: center;
  cursor: pointer;

  &:hover ${RemoveAppButton} {
    display: initial;
  }
`