import { Col, Row, Tag } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { Scenario } from 'db'
import { DateTime } from 'luxon'
import { FC, useState } from 'react'
import { useHistory } from 'react-router'
import AvatarApplicationGroups from '../../../../components/pages/app/AvatarApplicationGroups'
import { useScenariosByTaskRunAt } from '../../../../hooks/useScenarios'
import { StyledInput } from '../../../components/StyledComponents'

interface Props {
  start?: number,
  end?: number
}

const TaskTable: FC<Props> = ({ start, end }) => {
  const { scenarios, fetchingScenarios } = useScenariosByTaskRunAt(start && end ? { start: start, end: end } : undefined)
  const { push } = useHistory()
  const [searchValue, setSearchValue] = useState<string>()

  const columns: ColumnsType<Scenario> = [
    { title: 'Apps',
      render: (_, record) => {
        const applications = record.states?.sort((s1, s2) => s1.order - s2.order).map(state => (state.trigger || state.action)?.application)
        return <AvatarApplicationGroups applications={applications} />
      }
    },
    { title: 'Scenario Name', dataIndex: 'name',
      render: (name, record) => <>{name} <Tag color="default">ID: {record.id}</Tag> {record.deletedAt ? <Tag color="orange">archived</Tag> : null}</>
    },
    { title: 'Total Task Used', dataIndex: 'taskExecutedCount' },
    { title: 'Last Run', dataIndex: 'lastExecuted',
      defaultSortOrder: 'descend',
      sorter: (scenario1, scenario2) => scenario1.lastExecuted && scenario2.lastExecuted ? new Date(scenario1.lastExecuted).getTime() - new Date(scenario2.lastExecuted).getTime() : 0,
      render: (lastExecuted) => lastExecuted ? DateTime.fromJSDate(new Date(lastExecuted)).toFormat('DD t') : '' },
    { title: 'Action', dataIndex: 'id', render: (id, record) => record.deletedAt ? null : <a onClick={() => onClickEditScenario(id)} >Open in Editor</a> },
  ]

  const onClickEditScenario = (id: number) => {
    push(`/app/scenario/${id}/edit`)
  }

  const onSearch = (value: string) => {
    setSearchValue(value)
  }

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]} align="middle">
        <Col>
          <h5>Job Executed</h5>
        </Col>
        <Col>
          <StyledInput.Search placeholder="Search Scenario" allowClear onSearch={onSearch} style={{ width: 200 }} />
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={fetchingScenarios}
        rowKey="id"
        dataSource={scenarios?.filter(scenario => searchValue ? scenario?.name.toLowerCase().includes(searchValue.toLowerCase()) : true)}
        scroll={{ x: true }}
      />
    </>
  )
}

export default TaskTable