import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Result } from 'antd'
import Axios from 'axios'
import Cookies from 'js-cookie'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import LoggedInUserContextProvider from './contexts/LoggedInUserContextProvider'
import './index.css'
import { StyledButton } from './pages/components/StyledComponents'
import reportWebVitals from './reportWebVitals'
import { APP_MODE_ENVIRONMENT, IMPLIKASI_URL, SENTRY_DSN, WEB_VERSION } from './util/Constant'

if (process.env.NODE_ENV === 'development' || APP_MODE_ENVIRONMENT !== 'production') {
  console.log('Development mode')

  Axios.interceptors.request.use(function (config) {
    const token = Cookies.get('authorization')
    config.headers.Authorization = token ? decodeURIComponent(token) : ''

    return config
  })
}

if (APP_MODE_ENVIRONMENT && !APP_MODE_ENVIRONMENT.includes('local') && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `otomatis-web@${WEB_VERSION}`,
    environment: APP_MODE_ENVIRONMENT,
    enabled: true,
    normalizeDepth: 10,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.2,
  })
}

window.onunhandledrejection = function (e: any) {
  console.log('on un handled rejection', e?.reason, e)
}

ReactDOM.render(
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag('web_version', WEB_VERSION)
      scope.setTag('otomatis_user', localStorage.getItem('otomatis_user'))
    }}
    onError={() => {
      console.log('Error in browser. Send report to Sentry...')
    }}
    fallback={({ resetError }) => {
      return <Result
        status={500}
        title="Something Wrong"
        subTitle="Sorry, something went wrong"
        extra={<StyledButton
          type="primary"
          onClick={() => {
            resetError()
            window.location.replace(IMPLIKASI_URL)
          }}
        >
          Back Home
        </StyledButton>}
      />
    }}
  >
    <BrowserRouter>
      <LoggedInUserContextProvider>
        <App />
      </LoggedInUserContextProvider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
