import { State } from 'db'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import useCreateAScenarioState from '../../../../../../../../hooks/scenario/state/useCreateAScenarioState'
import useUpdateAScenarioState from '../../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../../../hooks/useFetch'
import { StyledButton } from '../../../../../../../../pages/components/StyledComponents'
import { allStatesSelector } from '../../../recoil/scenario'
import { nextStatesAllAtom } from '../../../recoil/state'
import StatePath from './StatePath'

interface Props {
  pathStates: State[]
}
const StateContentPaths: FC<Props> = ({ pathStates }) => {
  const { revalidateAScenario } = useRevalidateFetch()
  const stateChilds = useRecoilValue(nextStatesAllAtom)
  const states = useRecoilValue(allStatesSelector)
  const scenarioId = states?.[0]?.scenarioId
  const { createAScenarioState, creatingAScenarioState } = useCreateAScenarioState()
  const history = useHistory()
  const { updateAScenarioState } = useUpdateAScenarioState()

  const onAddPath = async (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    // A = 65 in ascii
    const baseCharAsNumber = 64
    const state = pathStates[0]
    const nPathState = states?.filter(state => state.conditionGroupOrder).length || 0
    const parentId = state?.parentId
    // create  path state
    const newStateId = await createAScenarioState(state.scenarioId, {
      parentId: parentId,
      actionId: state.actionId,
      order: state.order,
      stateConditionId: state.stateConditionId,
      conditionGroupOrder: state.order,
      name: `Path ${String.fromCharCode(baseCharAsNumber + nPathState + 1)}`
    }).then(({ data }) => data.state.id)

    if (newStateId && parentId) {
      updateAScenarioState(state.scenarioId, parentId, {
        childrenIds: [...pathStates.map(s => s.id), newStateId]
      }).then(() => {
        revalidateAScenario(state.scenarioId)
      })
    }
  }

  const onEdit = (stateId: number) => {
    history.replace(`/app/template-scenario/editor/${scenarioId}/${stateId}`)
  }

  return (
    <>
      {
        pathStates.sort((s1, s2) => s1.id - s2.id).map(pathState => {
          const { id, name } = pathState
          const nSteps = stateChilds?.[id] ? stateChilds[id].length + 1 : 1
          const applicationChilds = [...stateChilds?.[id] || []]?.sort((s1, s2) => s1.order - s2.order).map(stateChild => stateChild.application)
          return (
            <StatePath
              name={name}
              nSteps={nSteps}
              id={id}
              key={id}
              applicationChilds={applicationChilds}
              onEdit={() => onEdit(id)}
            />
          )
        })
      }
      <StyledButton onClick={onAddPath} loading={creatingAScenarioState}>Add Path</StyledButton>
    </>
  )
}

export default StateContentPaths