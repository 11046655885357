import moment, { Moment } from 'moment'
import { version } from '../../package.json'

export const HELP_URL = process.env.REACT_APP_REACT_APP_HELP_OTOMATIS || 'https://help.otomatis.com'
export const BLOG_URL = process.env.REACT_APP_REACT_APP_HELP_OTOMATIS || 'https://blog.otomatis.com'
export const IMPLIKASI_API_URL = process.env.REACT_APP_IMPLIKASI_API_URL || 'https://dev-v2.implikasi.app'
export const IMPLIKASI_URL = process.env.REACT_APP_IMPLIKASI_URL || 'http://localhost:3000'
export const OTORISASI_URL = process.env.REACT_APP_OTORISASI_URL || 'https://otorisasi.dev'
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '50fa03a6-5e61-4615-8191-1cf897c1069a'
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const APP_MODE_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const DISABLE_DEV_MODE = Number(process.env.REACT_APP_DISABLE_DEV_MODE)
export const WEB_VERSION = version
// check environment to use intercom appId
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || ''
// Pricing plan
export const DISPLAY_LIMITER_NAME = process.env.REACT_APP_PRICING_LIMITER_NAME || 'Task/Decaminute'
export const DISPLAY_LIMITER_UNIT = process.env.REACT_APP_PRICING_LIMITER_UNIT || 'minutes'
export const DISPLAY_LIMITER_DIVIDER = process.env.REACT_APP_PRICING_LIMITER_DIVIDER || '60000'


// table2 properties
export const DEFAULT_PAGE_SIZE = 15
export const PAGE_SIZE_OPTIONS = ['5', '10', '15', '30', '50']
export const DEFAULT_PAGE_CURRENT = 1

export const DATE_TIME_FORMAT= 'D MMM YYYY HH:mm:ss'

export const JOBROLES = [
  { value: 'Accountancy', label: 'Accountancy' },
  { label: 'Banking', value: 'Banking' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Management', value: 'Management' },
  { lebel:'Marketing', value: 'Marketing' },
  { label: 'Advertising', value: 'Advertising' },
  { label: 'Public Relation', value: 'Public Relation' },
  { label: 'Recruitment & HR', value: 'Recruitment & HR' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Transport and Logistics', value: 'Transport and Logistics' },
  { label: 'Customer Support', value: 'Customer Support' },
]

export const ENABLE_IS_ERROR_STATE = process.env.REACT_APP_ENABLE_IS_ERROR_STATE || ''


// JOB FILTER
export const DEFAULT_DATE_RANGE: [Moment, Moment] = [moment().subtract(7, 'd'), moment()]