import { Layout, Menu } from 'antd'
import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AnchorWrapper from '../../../../components/AnchorWrapper'
import { baseApplicationRoute } from '../../application/routes'
import ApplicationDetailSection from './ApplicationDetailSection'
import routes from './routes'

const Sider: FC = () => {
  const { push } = useHistory()
  const { page } = useParams<{ page?: string }>()
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()
  const [collapsed, setCollapsed] = useState(false)

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed)
  }

  const onClickMenu = (to: string) => {
    push(`${baseApplicationRoute}/${applicationId}/${versionName}/main${to}`)
  }

  return (
    <StyledSider theme="light" collapsedWidth={collapsed ? 0 : undefined} onCollapse={onCollapse} collapsible breakpoint="sm">
      <ApplicationDetailSection />
      <Menu
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
        mode="inline" defaultSelectedKeys={['step']} selectedKeys={[page || 'step']} defaultOpenKeys={['build', 'manage']}>
        {
          routes.map(route => {
            const { children, icon, key, title } = route
            const Icon = icon
            if (children) {
              return (
                <Menu.SubMenu key={key} icon={<Icon />} title={title}>
                  {
                    children.map(menu => {
                      const { title, key, to } = menu
                      return (
                        <Menu.Item key={key} title={title} onClick={() => onClickMenu(to)} >
                          <AnchorWrapper href={`${baseApplicationRoute}/${applicationId}/${versionName}/main${to}`}>
                            {title}
                          </AnchorWrapper>
                        </Menu.Item>
                      )
                    })
                  }
                </Menu.SubMenu>
              )
            }
          })
        }
      </Menu>
    </StyledSider>
  )
}

export default Sider

const StyledSider = styled(Layout.Sider)`
  .ant-layout-sider-children {
    display: grid;
    overflow: hidden;
    grid-template-rows: max-content auto;
  }
  .ant-menu-item {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #FFF;
  }
  &.ant-layout-sider .ant-layout-sider-zero-width-trigger {
    top: 0;
    background: none;
    visibility: hidden;
  }
  &.ant-layout-sider-zero-width .ant-layout-sider-zero-width-trigger-left {
    background: none;
    visibility: visible;
  }
  &.ant-layout-sider-below span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
    visibility: visible;
  }
`