import { LogJob } from 'db'
import { useState } from 'react'
import { TableState } from '../../types'
import useDeleteAJob from './useDeleteAJob'
import useLogJobs from './useLogJobs'

type DeleteLogJobs = {
  deletingLogJobs: boolean,
  deleteLogJobs: (logJobs: LogJob[]) => Promise<{ logJob: LogJob }[]>
}

export default function useDeleteLogJobs(tableState: TableState<LogJob>): DeleteLogJobs {
  const { deleteAJob } = useDeleteAJob()
  const [deletingLogJobs, setDeletingLogJobs] = useState(false)
  const { revalidateLogJobs } = useLogJobs(tableState)

  const deleteLogJobs = (logJobs: LogJob[]) => {
    setDeletingLogJobs(true)
    const deletes = Promise.all(logJobs.map(async (logJob) => {
      const { id } = logJob
      return deleteAJob(id)
        .then(({ data }) => data)
    }))

    deletes.then(() => {
      revalidateLogJobs?.()
      setDeletingLogJobs(false)
    })

    return deletes
  }

  return {
    deletingLogJobs,
    deleteLogJobs
  }
}