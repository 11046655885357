import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Scenario, State } from 'db'
import React, { FC } from 'react'
import styled from 'styled-components'
import ChooseStateBundle from './ChooseStateBundle'
import StateContextMenu from './StateContextMenu'
import { useStateFocus } from './StateFocusContext'
import { ChildStateType, useStateChildsData, useStateTestData } from './StateTestDataContext'

type StateHeaderActionProps = {
  pathStates?: State[],
  stateId: number,
  scenarioId: number,
  applicationId?: number,
  isAccountSelected: boolean,
  isStateTested: boolean,
  isStateTestSucceed: boolean,
  state: State | undefined,
  scenario: Scenario | undefined,
  stateType: 'trigger' | 'action',
  selectedAccountId: number | undefined,
  openStateFormBody: () => void,
  onEditOrAddTrigger: (isAddTrigger?: boolean) => void
}

const StateHeaderAction: FC<StateHeaderActionProps> = ({ pathStates, isStateTestSucceed, stateId, scenarioId, applicationId, openStateFormBody, onEditOrAddTrigger, isAccountSelected, isStateTested, state, scenario, stateType, selectedAccountId }) => {
  const [focusStates, , visitedStates] = useStateFocus()
  const [testDataStates] = useStateTestData()
  const isThisStateFocus = focusStates ? focusStates[stateId] : undefined
  const isThisStateHasVisited = visitedStates ? visitedStates[stateId] : undefined
  const hasAuthenticationId = (state?.trigger || state?.action)?.authenticationId
  const [stateChilds] = useStateChildsData()
  // const hasFieldSchema = (state?.trigger || state?.action)?.paramSchema

  const isAnyNextStateHasTested = scenario?.states?.reduce((res, state, idx, states) => {
    const thisStateIndex = states.findIndex((s) => s.id === stateId)
    const isHasTestData = testDataStates && testDataStates[state.id]?.testData ? true : false
    if (thisStateIndex < idx && (state.testData || isHasTestData)) {
      return res || true
    }
    return res
  }, false)

  const nexts: ChildStateType[] | undefined = stateChilds ?
    pathStates ?
      pathStates.reduce((res: ChildStateType[], s) => stateChilds[s.id] ?
        [...res, ...stateChilds[s.id]] : res, pathStates.map(state => ({ id: state.id, order: state.order, conditionGroupOrder: state.conditionGroupOrder }))) :
      stateChilds[stateId] : undefined

  return (
    state && scenario ?
      <StateHeaderActionContainer>
        <ChooseStateBundle
          stateId={stateId}
          scenarioId={scenarioId}
          selectedAccountId={selectedAccountId}
          openStateForm={openStateFormBody}
          state={state}
          applicationId={applicationId}
        />
        {
          !pathStates ?
            (isAccountSelected || !hasAuthenticationId) && isStateTested && isStateTestSucceed ?
              <CheckCircleFilled style={{ color: '#52C41A', fontSize: 24 }} /> :
              <Tooltip
                placement="topRight"
                title={
                  !isAccountSelected && hasAuthenticationId ?
                    'Sign in or choose one the account to continue the setup' :
                    !isStateTestSucceed ?
                      `Test your ${stateType === 'trigger' ? 'trigger' : 'action'} to make sure it is well set up` :
                      `Complete all the ${stateType === 'trigger' ? 'trigger' : 'action'} setup`
                }
              >
                {
                  !isStateTestSucceed && (isStateTested || !isThisStateFocus && (isThisStateHasVisited || isAnyNextStateHasTested)) ?
                    <ExclamationCircleFilled style={{ color: '#ff4d4f', fontSize: 24 }} /> :
                    <CheckCircleFilled style={{ color: '#E3E3E3', fontSize: 24 }} />
                }
              </Tooltip>
            : null
        }
        {
          state && scenario ?
            <StateContextMenu
              parentStateId={state.parentId}
              childrenStateIds={state.childrenIds}
              pathStates={pathStates}
              order={state.order}
              nextStateIds={nexts}
              type={pathStates ? 'path' : stateType}
              scenarioId={scenario.id}
              stateId={state.id}
              disabled={!state}
              onClickEdit={onEditOrAddTrigger}
            /> : null
        }
      </StateHeaderActionContainer> :
      null

  )
}

export default StateHeaderAction

const StateHeaderActionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`