import Axios from 'axios'
import Cookies from 'js-cookie'
import { APP_MODE_ENVIRONMENT, IMPLIKASI_API_URL } from '../util/Constant'
import { notifySentry } from '../util/functions'

export const BASE_API_URL_IMPLIKASI = `${IMPLIKASI_API_URL}/api/v2`

const Implikasi = Axios.create({
  baseURL: `${IMPLIKASI_API_URL}/api/v2`,
  withCredentials: true
})

if (process.env.NODE_ENV === 'development' || APP_MODE_ENVIRONMENT !== 'production') {
  Implikasi.interceptors.request.use(function (config) {
    const token = Cookies.get('authorization')
    config.headers.Authorization = token ? decodeURIComponent(token) : ''

    return config
  })
}

Implikasi.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status !== 401) {
    // console.log(error, error.data, error.response?.data, error?.request)
    notifySentry(error, error?.response)
  }
  return Promise.reject(error)
})

export { Implikasi }