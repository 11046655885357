import { AxiosPromise } from 'axios'
import { Version } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  updatedVersion?: Version,
  updatingVersion: boolean,
  errorUpdateVersion?: Error,
  updateVersion: (applicationId: number, versionId: number, payload: Partial<Version>) => AxiosPromise<{ version: Version }>
}

export default function useUpdateVersion(): Result {
  const { data, error, isValidating, call } = useApi<{ version: Version }>()

  return {
    updatedVersion: data?.version,
    updatingVersion: isValidating,
    errorUpdateVersion: error,
    updateVersion: async (applicationId, versionId, payload) => {
      return await call({
        url: `/application/${applicationId}/version/${versionId}`,
        method: 'patch',
        data: {
          version: payload
        }
      })
    }
  }
}