import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Space } from 'antd'
import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { StyledAlert, StyledButton, StyledCheckbox, StyledLabel, StyledTagLabel } from '../../../../../../../pages/components/StyledComponents'
import { lime } from '../../../../../../../util/colors'
import { getSuggestionsFromValueArray, isValidValueArray } from '../../../../../../../util/functions'
import MarkdownRenderer from '../../../../../../MarkdownRenderer'
import RichTextInput from '../../RichTextInput'
import { useStateTestData } from '../../StateTestDataContext'
import StateFormItemMapping, { StateFormItemMappingProps, StyledFormItem } from '../StateFormItemMapping'


const ArrayMapStateStyledFormItem: FC<StateFormItemMappingProps> = ({ form, param, ...otherProps }) => {
  const { mapSchema, key, label, required, helpText } = param
  const [stateTestData] = useStateTestData()
  const dataSuggestion = stateTestData

  useEffect(() => {
    if (!form?.getFieldValue([key, 'data']) && required) {
      form?.setFieldsValue({
        [key]: {
          data: [{}]
        }
      })
    }
  }, [])
  return (
    <StyledFormItem>
      <Space>
        <StyledLabel className={required ? 'required' : ''}>{label}</StyledLabel>
        <StyledTagLabel style={{ color: lime[6] }} color="success">
          Multi Item
        </StyledTagLabel>
      </Space>
      {helpText ? <MarkdownRenderer>{helpText}</MarkdownRenderer> : null}
      <br />
      <StyledFormItem valuePropName="checked" name={[key, '_useArrayFromPrevAction']}>
        <StyledCheckbox>Use array value from previous Action</StyledCheckbox>
      </StyledFormItem>
      <StyledFormItem shouldUpdate={(prev, next) => prev[key]?._useArrayFromPrevAction !== next[key]?._useArrayFromPrevAction}>
        {({ getFieldValue }) => {
          const useArrayFromPrevAction = getFieldValue([key, '_useArrayFromPrevAction'])
          if (!useArrayFromPrevAction) {
            return null
          }
          return (
            <>
              <StyledFormItem name={[key, '_value']} label="Array Value" rules={[{ required: required || useArrayFromPrevAction, message: 'Array Value is required' },
                () => ({
                  async validator(_: any, value: any) {
                    if (!isValidValueArray(value, dataSuggestion)) {
                      return Promise.reject(new Error('Data selected must be an Array'))
                    }
                    return Promise.resolve()
                  },
                })
              ]} >
                {
                  // eslint-disable-next-line
                  // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                  <RichTextInput id={`valueArray-${key}`} />
                }
              </StyledFormItem>
              <StyledAlert
                className="-mt-3.5 mb-2"
                type="info"
                showIcon
                message={'Otomatis will map every item from ‘Array Value’ form to a new Array of Items. Every item will be mapped based on ‘Item Data Mapping’ form below'}
              />
            </>
          )
        }}
      </StyledFormItem>
      <StyledFormItem shouldUpdate={(prev, next) => prev[key]?._useArrayFromPrevAction !== next[key]?._useArrayFromPrevAction || prev[key]?._value !== next[key]?._value }>
        {({ getFieldValue,  }) => {
          const useArrayFromPrevAction = getFieldValue([key, '_useArrayFromPrevAction'])
          const arrayValue = getFieldValue([key, '_value'])
          const dataSuggestionForItem = getSuggestionsFromValueArray(arrayValue, dataSuggestion)
          if (useArrayFromPrevAction) {
            return (
              <WrapperItem>
                <div className="title" >Item Data Mapping</div>
                {
                  mapSchema?.map((field) => {
                    return (
                      <StateFormItemMapping
                        form={form}
                        parentKey={key}
                        param={field}
                        {...otherProps}
                        dataSuggestion={dataSuggestionForItem || undefined}
                        key={field.key}
                      />
                    )
                  })
                }
              </WrapperItem>
            )
          } else {
            return (
              <Form.List name={[key, 'data']}>
                {(fields, { add, remove }) =>
                  <>
                    {fields.map((fieldFormList, idx) =>
                      <WrapperItem key={fieldFormList.key}>
                        <div className="title" >
                          Item {idx + 1}
                          {
                            idx !==0 || !required ?
                              <DeleteOutlined className="ml-2" disabled={required && fields.length === 1} onClick={() => (fields.length !== 1 || !required) && remove(idx)} /> :
                              null
                          }
                        </div>
                        {
                          mapSchema?.map((field) => {
                            return (
                              <StateFormItemMapping
                                parentKey={[fieldFormList.name]}
                                fieldKey={[fieldFormList.name, field.key]}
                                param={field}
                                form={form}
                                {...otherProps}
                                key={field.key}
                              />
                            )
                          })
                        }
                      </WrapperItem>
                    )}
                    <Form.Item>
                      <StyledButton type="primary" ghost onClick={() => add()} block icon={<PlusOutlined />}>
                        Add More Items
                      </StyledButton>
                    </Form.Item>
                  </>
                }
              </Form.List>
            )
          }
        }}
      </StyledFormItem>
    </StyledFormItem>
  )
}

export default ArrayMapStateStyledFormItem

const WrapperItem = styled.div`
  border: 1px dashed #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 16px;
  position: relative;
  margin-bottom: 16px;

  .title {
    background: #FFF;
    position: absolute;
    top: -12px;

    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
`