import { Card, Col, Row } from 'antd'
import { Scenario, State as StateType } from 'db'
import PubSub from 'pubsub-js'
import React, { createRef, FC, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import useWebhookUrl from '../../../../../hooks/scenario/useWebhookUrl'
import useAScenarioState from '../../../../../hooks/useAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import { getFlatDataTest } from '../../../../../util/formatter'
import AddNewAction from './AddNewAction'
import EditStateEvent from './EditStateEvent'
import StateContentCondition from './StateContentCondition'
import StateContentForm from './StateContentForm'
import StateContentPaths from './StateContentPaths'
import StateHeaderAction from './StateHeaderAction'
import StateHeaderInfo from './StateHeaderInfo'
import StateSkeleton from './StateSkeleton'
import { useStateTestData } from './StateTestDataContext'
import AlertArrayInfo from './TestDataArray/AlertArrayInfo'
import { executionTypeAtomFamily } from './TestDataArray/testDataArrayRecoil'
import { useThisStateStaticValue } from './ThisStateStaticValueContext'

type StateProps = {
  isModalMounted?: boolean,
  pathStates?: StateType[],
  loading: boolean,
  scenario: Scenario,
  scenarioId: number,
  stateId: number,
  stateType: 'trigger' | 'action'
}

const State: FC<StateProps> = ({ isModalMounted, pathStates, loading, scenarioId, stateId, stateType, scenario }) => {
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const refCard = createRef<any>()
  const { params } = useRouteMatch<{ scenarioId: string, stateId: string, isPath?: string }>()
  const history = useHistory()
  const { state, fetchingState } = useAScenarioState(scenarioId, stateId, true) // refetch for more complete data of State
  const thisStateOrder = state?.order
  const thisStateParams = state?.params
  const thisStateInitialParamSchema = state?.[stateType]?.paramSchema
  const thisStateTestDataFromDB = state?.testData
  const thisStateRealTestDataFromDB = state?.realTestData
  const bundleId = state?.bundleId
  const triggerType = state?.trigger?.type
  const application = state?.[stateType]?.application
  const applicationId = state?.[stateType]?.applicationId ?? application?.id
  const triggerActionName = state?.[stateType]?.name
  const stateName = state?.name
  const [_, setThisStateStaticValue] = useThisStateStaticValue()

  const [isStateActive, setIsStateActive] = useState<boolean>(false)
  const [stateTestData, setStateTestData] = useStateTestData()
  const { url } = useWebhookUrl(state?.trigger?.type === 'hook' ? scenarioId : undefined)
  const isUseAuthenticationId = (state?.trigger || state?.action)?.authenticationId

  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const [isOnEdit, setIsOnEdit] = useState(false)
  const [isAddTrigger, setIsAddTrigger] = useState<boolean | undefined>(false)
  const [setupActionStatus, setSetupActionStatus] = useState({ chooseAccount: false, testTriggerAction: false, testTriggerActionSuccess: false })

  const executionType = state?.executionType
  const setExecutionType = useSetRecoilState(executionTypeAtomFamily(stateId))

  useEffect(() => {
    setExecutionType(executionType)
  }, [executionType])


  useEffect(() => {
    if (params.stateId) {
      setIsStateActive(isUseAuthenticationId && bundleId !== null && bundleId !== undefined || !isUseAuthenticationId)
      if (params.stateId === `${stateId}` || pathStates?.find(s => `${s.id}` === params.stateId)) {
        refCard.current?.scrollIntoView({ block: 'start' })
      }
    }
  }, [params.stateId, isUseAuthenticationId, bundleId, pathStates])

  useEffect(() => {
    if (isModalMounted) {
      if (params.stateId) {
        setIsStateActive(isUseAuthenticationId && bundleId !== null && bundleId !== undefined || !isUseAuthenticationId)
        if (params.stateId === `${stateId}`) {
          refCard.current?.scrollIntoView({ block: 'start' })
        }
      }
    }
  }, [isModalMounted, params.stateId, isUseAuthenticationId, bundleId])

  const toggleStateFormBody = () => {
    setIsStateActive(prevState => !prevState && (isUseAuthenticationId && bundleId !== null && bundleId !== undefined || !isUseAuthenticationId))

    history.replace(`/app/scenario/${scenarioId}/edit/${stateId}${pathStates?.find(s => s.id === stateId) ? '/path': ''}`)
    // forcing codemirror to refresh because it's a fcking lazy library
    PubSub.publish(`cm-${stateId}-force-refresh`)
    // the shit i do for you codemirror 🤬
  }

  const onCancel = () => {
    setIsOnEdit(false)
  }

  const onChangeEventId = (id?: number, name?: string) => {
    if (id) {
      updateAScenarioState(scenarioId, stateId, {
        name: name,
        bundleId: null,
        params: null,
        testData: null,
        triggerId: stateType === 'trigger' ? id : undefined,
        actionId: stateType === 'action' ? id : undefined
      }).then(() => {
        revalidateAScenarioState(scenarioId, stateId)
        revalidateAScenario(scenarioId)
      })
    }
  }

  useEffect(() => {
    if (bundleId !== null && bundleId !== undefined) {
      setSetupActionStatus(prevState => ({ ...prevState, chooseAccount: true }))
    }
  }, [bundleId])

  useEffect(() => {
    if (!fetchingState && isOnEdit) {
      onCancel()
    }
  }, [fetchingState])

  // initiate test data with examplePayload
  const thisStateTestData = stateTestData?.[stateId]
  useEffect(() => {
    // make sure this only running when real test data is not exist
    if (!thisStateTestData && stateId && thisStateOrder !== undefined && triggerActionName && application) {
      setStateTestData(otherStateTestData => ({
        ...otherStateTestData,
        [stateId]: {
          id: stateId,
          type: (state?.action || state?.trigger)?.type,
          order: thisStateOrder,
          triggerActionName: triggerActionName,
          stateName,
          application,
          testData: null,
          flatTestData: null
        }
      }))
    }
  }, [thisStateTestData, stateId, thisStateOrder, triggerActionName, application, setStateTestData])

  useEffect(() => {
    // only run when test data exists and application && trigger/action change
    if (application && triggerActionName && thisStateTestData) {
      setStateTestData(prevState => ({
        ...prevState,
        [stateId]: {
          ...prevState?.[stateId],
          application,
          triggerActionName,
          stateName,
          testData: null,
          flatTestData: null
        }
      }))
    }
  }, [application, triggerActionName])

  useEffect(() => {
    if (thisStateTestDataFromDB && stateId && thisStateOrder !== undefined && triggerActionName && application) {
      // const flattenTestData: Record<string, any> = flatten(thisStateTestDataFromDB)
      // const flattenTestDataSafe: Record<string, any> = flatten(thisStateTestDataFromDB, { safe: true })

      // const flatTestDataSafe = Object.keys(flattenTestDataSafe).reduce((acc, dataKey) => ({
      //   ...acc,
      //   [dataKey]: {
      //     sample: truncateLongData(flattenTestDataSafe[dataKey]),
      //     value: `${stateId}.${dataKey}`,
      //     label: `${thisStateOrder + 1}. ${dataKey} ${Array.isArray(flattenTestDataSafe[dataKey]) ? '(array)': ''}`,
      //     appLogoUrl: application.logoUrl
      //   }
      // }), {})

      // const flatTestData = Object.keys(flattenTestData).reduce((acc, dataKey) => ({
      //   ...acc,
      //   [dataKey]: {
      //     sample: truncateLongData(flattenTestData[dataKey]),
      //     value: `${stateId}.${dataKey}`,
      //     label: `${thisStateOrder + 1}. ${dataKey}`,
      //     appLogoUrl: application.logoUrl
      //   }
      // }), flatTestDataSafe)

      setStateTestData(otherStateTestData => ({
        ...otherStateTestData,
        [stateId]: {
          ...thisStateTestData,
          id: stateId,
          type: (state?.action || state?.trigger)?.type,
          order: thisStateOrder,
          triggerActionName: triggerActionName,
          stateName,
          application,
          realTestData: thisStateRealTestDataFromDB,
          testData: thisStateTestDataFromDB,
          flatTestData: getFlatDataTest({ testData: thisStateTestDataFromDB, stateId, stateOrder: thisStateOrder, application: application })
        }
      }))

      // set this to true because we already got test data from DB
      // which means the user already test this trigger/action before
      setSetupActionStatus(prevState => ({ ...prevState, testTriggerAction: true, testTriggerActionSuccess: thisStateRealTestDataFromDB ? thisStateRealTestDataFromDB?.success : !thisStateTestDataFromDB.error }))
    }
  }, [thisStateTestDataFromDB, thisStateRealTestDataFromDB])

  useEffect(() => {
    // run this when state order is change
    // for example when new action inserted before this state, this state order should change
    if (thisStateTestData && thisStateOrder !== undefined) {
      setStateTestData(otherStateTestData => ({
        ...otherStateTestData,
        [stateId]: {
          ...thisStateTestData,
          order: thisStateOrder
        }
      }))
    }
  }, [thisStateOrder])

  // set initial value of this State and if static value updated
  useEffect(() => {
    if (stateId !== undefined && thisStateOrder !== undefined && thisStateInitialParamSchema !== undefined && thisStateParams !== undefined && triggerActionName !== undefined && application !== undefined && scenarioId !== undefined) {
      setThisStateStaticValue({
        executionType: state?.executionType,
        id: stateId,
        order: thisStateOrder,
        type: stateType,
        triggerActionName,
        application,
        scenarioId,
        params: thisStateParams,
        paramSchema: thisStateInitialParamSchema
      })
    }
  }, [stateId, bundleId, scenarioId, thisStateOrder, triggerActionName, application, thisStateParams, thisStateInitialParamSchema])

  const nextStateId = scenario ? scenario.states?.reduce((res: number | undefined , state, idx, states) => {
    if (state.id === stateId && states.length > idx + 1) {
      return states[idx + 1].id
    }
    return res
  }, undefined) : undefined

  return (
    state && (state.trigger || state.action) && !loading ?
      <StateContainer ref={refCard}>
        {
          isOnEdit ?
            <EditStateEvent
              addTrigger={isAddTrigger}
              submitting={updatingAScenarioState || fetchingState}
              triggerId={state?.triggerId}
              actionId={state?.actionId}
              onCancel={onCancel}
              applicationId={applicationId}
              onChangeEventId={onChangeEventId}
            /> :
            state.conditionGroupOrder && !pathStates ?
              <CustomCardState
                title={<Row style={{ padding: '16px 24px' }} gutter={[16, 16]} justify="space-between" align="middle" onClick={toggleStateFormBody}>
                  <Col>
                    <StateHeaderInfo notAllowedEditName={!!pathStates} name={pathStates ? 'Path' : undefined} state={state} />
                  </Col>
                </Row>}
                hasConditions={true}
                isStateFocus={true}
                isStateActive={true}
              >
                <StateContentCondition
                  stateOrder={state.order}
                  actionId={state.actionId}
                  application={application}
                  scenarioId={scenarioId}
                  stateId={stateId}
                  type={state.action?.type}
                  conditions={state.conditions} />
              </CustomCardState>
              :
              <CustomCardState
                hasPaths={!!pathStates}
                isStateFocus={params.stateId === `${stateId}` || params.isPath && pathStates}
                isStateActive={isStateActive}
                title={
                  <Row style={{ padding: '16px 24px' }} gutter={[16, 16]} justify="space-between" align="middle" onClick={toggleStateFormBody}>
                    <Col>
                      <StateHeaderInfo notAllowedEditName={!!pathStates} name={pathStates ? 'Path' : undefined} state={state} />
                    </Col>
                    <Col>
                      <StateHeaderAction
                        pathStates={pathStates}
                        stateId={stateId}
                        scenarioId={scenarioId}
                        applicationId={applicationId}
                        state={state}
                        scenario={scenario}
                        isAccountSelected={setupActionStatus.chooseAccount}
                        isStateTested={setupActionStatus.testTriggerAction}
                        isStateTestSucceed={setupActionStatus.testTriggerActionSuccess}
                        stateType={stateType}
                        selectedAccountId={bundleId || undefined}
                        onEditOrAddTrigger={(isAddTrigger?: boolean) => {
                          setIsAddTrigger(isAddTrigger)
                          setIsOnEdit(true)
                        }}
                        // setSelectedAccountId={setSelectedAccountId}
                        openStateFormBody={() => setIsStateActive(true)}
                      />
                    </Col>
                  </Row>
                }
              >
                {
                  pathStates ?
                    <StateContentPaths
                      scenarioId={scenarioId}
                      pathStates={pathStates}
                      states={scenario?.states}
                    />
                    :
                    state.action?.type === 'filter' ?
                      <StateContentCondition
                        setSetupActionStatus={setSetupActionStatus}
                        stateOrder={state.order}
                        actionId={state.actionId}
                        application={application}
                        scenarioId={scenarioId}
                        stateId={stateId}
                        type={state.action.type}
                        conditions={state.conditions} /> :
                      params.stateId === `${stateId}` && (isUseAuthenticationId && bundleId || !isUseAuthenticationId) ?
                        <StateContentForm
                          isLastState={scenario.states?.reduce((res: boolean, state, idx, states) => {
                            if (state.id === stateId && idx === states.length - 1) {
                              return true
                            }
                            return res
                          }, false)}
                          toggleStateFormBody={toggleStateFormBody}
                          testGuideline={state.trigger?.testGuideline}
                          isEnableAuthentication={!!isUseAuthenticationId}
                          scenarioId={scenarioId}
                          hasDummyHttpRequestId={!!state?.trigger?.dummyTriggerHttpRequestId}
                          hasSubscribeHttpRequestId={!!state?.trigger?.subscribeHttpRequestId}
                          // setValuesForm={setValuesForm}
                          triggerId={state.trigger?.id}
                          actionId={state.action?.id}
                          stateId={stateId}
                          stateOrder={state.order}
                          triggerActionName={state[stateType]?.name}
                          application={state[stateType]?.application}
                          webhookUrl={url}
                          type={stateType}
                          params={state.params}
                          paramSchema={state[stateType]?.paramSchema}
                          examplePayload={state[stateType]?.examplePayload}
                          applicationId={state[stateType]?.application?.id}
                          bundleId={bundleId || undefined}
                          setupActionStatus={setupActionStatus}
                          setSetupActionStatus={setSetupActionStatus}
                          nextStateId={nextStateId}
                        /> :
                        null
                }
              </CustomCardState>

        }
        <AlertArrayInfo stateId={stateId} triggerType={triggerType} thisStateRealTestDataFromDB={thisStateRealTestDataFromDB} />
        {
          !pathStates ?
            <AddNewAction
              state={state}
              scenario={scenario}
            /> :
            null
        }
      </StateContainer> :
      <StateSkeleton />
  )
}

export default State

const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// Why? Here: https://github.com/styled-components/styled-components/issues/305
// eslint-disable-next-line
const CustomCardState = styled(({ hasConditions, hasPaths, isStateActive, isStateFocus, children, ...nativeProps }) => <Card {...nativeProps}>{children}</Card>)<{  hasConditions?: boolean, hasPaths: boolean, isStateActive: boolean, isStateFocus?: boolean }>`
  width: 100%;
  margin-top: 12px;

  & > .ant-card-head {
    padding: 0;
    cursor: pointer;
  }

  & .ant-card-head-title {
    padding: 0;
  }

  & > .ant-card-body {
    padding: ${props => props.hasPaths ? '24px' : '24px 0'};
    display: ${props => props.hasConditions || props.hasPaths || props.isStateActive && props.isStateFocus ? 'block' : 'none'};
    background: ${props => props.hasPaths ? '#F0FBFF' : 'unset'};
  }
  opacity: ${props => props.isStateFocus ? 1 : 0.6};
  border: ${props => props.isStateFocus ? '2px solid #BEE06E' : '1px solid #F0F0F0'};
  border-radius: 4px;
  &:hover {
    opacity: 1;
  }
`