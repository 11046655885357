import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Col, Input, Layout, PageHeader, Row, Space, Table, Tag } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import { ColumnType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { Trigger } from 'db'
import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import AnchorWrapper from '../../../../../../components/AnchorWrapper'
import ButtonActionMore from '../../../../../../components/ButtonActionMore'
import ModalImportTriggerAction from '../../../../../../components/pages/developer/main/ModalImportTriggerAction'
import useExportApplication from '../../../../../../hooks/application/main/useExportApplication'
import { useTriggers } from '../../../../../../hooks/application/trigger/useAllTrigger'
import useArchiveTrigger from '../../../../../../hooks/application/trigger/useArchiveTrigger'
import useDuplicateTrigger from '../../../../../../hooks/application/trigger/useDuplicateTrigger'
import { TableState } from '../../../../../../types'
import { blue } from '../../../../../../util/colors'
import { PAGE_SIZE_OPTIONS } from '../../../../../../util/Constant'
import { compareString } from '../../../../../../util/functions'
import Notification from '../../../../../../util/Notification'
import { StyledButton, StyledCard } from '../../../../../components/StyledComponents'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { useSharedValueTrigger } from '../components/SharedValueTriggerContextProvider'


const TriggerView: FC = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { archiveTrigger, archivingTrigger, archivedTrigger } = useArchiveTrigger()
  const [version] = useApplicationVersionContext()
  const [tableState, setTableState] = useState<TableState<Trigger>>({
    sorter: { field: 'name', order: 'ascend' },
    pagination: {
      pageSize: 30
    }
  })
  const { triggers, fetchingAllTriggers, revalidateTriggers, triggersLength } = useTriggers(applicationId, version?.id, tableState)
  const [, setValues] = useSharedValueTrigger()
  const [selectedTriggerIds, setSelectedTriggerIds] = useState<number[]>()
  const { exportTriggers } = useExportApplication()
  const [visible, setVisible] = useState(false)
  const { duplicateTrigger, duplicatingTrigger, activeDuplicateTriggerId } = useDuplicateTrigger()

  const onClickImport = () => setVisible(true)

  const onClickExport = () => {
    if (selectedTriggerIds && selectedTriggerIds?.length > 0) {
      exportTriggers(parseInt(applicationId), selectedTriggerIds)
    }
  }

  const columns: ColumnType<Trigger>[] = [
    { title: 'ID', dataIndex: 'id', render: (id) => <AnchorWrapper href={`${url}/${id}`} style={{ color: blue[4] }} >{id}</AnchorWrapper> },
    { title: 'Name',
      sorter: {
        compare: (a, b) => compareString(a.name, b.name)
      }, defaultSortOrder: 'ascend', dataIndex: 'name', render: (name, record) => <>{name} {record.isDraft || record.visibility === 'hidden' ? <Tag color="orange">hidden</Tag> : undefined} </> },
    { title: 'Action', dataIndex: 'id', render: (id) => <Space>
      <ButtonActionMore
        overlay={<></>}
        buttonProps={{
          loading: duplicatingTrigger && activeDuplicateTriggerId === id
        }}
        listMenu={[
          {
            key: 'delete',
            onClick: (info) => onDelete(info.domEvent, id),
            children: 'Delete'
          },
          {
            key: 'duplicate',
            onClick: (info) => onDuplicate(info.domEvent, id),
            children: 'Duplicate'
          }
        ]}
      />
    </Space> },
  ]

  const goToCreate = () => {
    setValues(null)
    history.push(`${url}/create`)
  }

  const onEdit = (e: MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`${url}/${id}`)
  }

  const onDelete = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> | undefined, id: number) => {
    e?.preventDefault()
    e?.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this trigger?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action can not be undone',
      onOk() {
        archiveTrigger(Number(applicationId), id, version?.id)
      }
    })
  }

  const onDuplicate = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> | undefined, id: number) => {
    e?.preventDefault()
    e?.stopPropagation()
    duplicateTrigger({ applicationId: Number(applicationId), versionId: version?.id, triggerId: id })
      .then(() => revalidateTriggers())
  }

  useEffect(() => {
    if (archivedTrigger) {
      Notification.success({
        message: `Trigger "${archivedTrigger.name}" deleted succcesfully`
      })
      revalidateTriggers()
    }
  }, [archivedTrigger])

  useEffect(() => {
    setSelectedTriggerIds?.([])
  }, [tableState.filter])

  const handleChange = (pagination: TablePaginationConfig, filter: Record<string, FilterValue | null>, sorter: SorterResult<Trigger> | SorterResult<Trigger>[], extra: TableCurrentDataSource<Trigger>) => {
    setTableState(prevState => ({ ...prevState, pagination, filter: { ...prevState.filter, ...filter }, sorter, extra }))
  }

  const onSearch = (value: string) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }


  return (
    <StyledTriggerView>
      <PageHeader
        title="Trigger"
      />
      <StyledCard>
        <Row align="middle" justify="space-between">
          <Col flex={0}>
            <Space direction="vertical">
              <h3>Build your{triggers?.length === 0 ? ' First' : ''} Trigger</h3>
              <p>Create trigger for your application.</p>
            </Space>
          </Col>
          <Col flex={0}>
            <AnchorWrapper href={`${url}/create`}>
              <StyledButton type="primary" onClick={goToCreate}>
                Add Trigger
              </StyledButton>
            </AnchorWrapper>
          </Col>
        </Row>
      </StyledCard>
      <StyledCard className="cardTable">
        <Row gutter={[16, 16]} align="middle">
          {(selectedTriggerIds || []).length > 0 ? <Col>{selectedTriggerIds?.length} Selected</Col> : null}
          <Col>
            <StyledButton disabled={!selectedTriggerIds || selectedTriggerIds?.length === 0} onClick={onClickExport}>Export Triggers</StyledButton>
          </Col>
          <Col>
            <StyledButton onClick={onClickImport}>Import Triggers</StyledButton>
          </Col>
          <Col>
            <Input.Search placeholder="Search trigger" onSearch={onSearch} />
          </Col>
        </Row>
        <br/><br />
        <Table
          onChange={handleChange}
          pagination={{
            current: tableState.pagination?.current,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            total: triggersLength,
            pageSize: tableState.pagination?.pageSize
          }}
          rowSelection={{
            onChange: (keys) => {
              setSelectedTriggerIds(keys as number[])
            },
            selectedRowKeys: selectedTriggerIds
          }}
          onRow={(record) => ({
            style: { cursor: 'pointer' },
            onClick: (e) => onEdit(e, record.id)
          })}
          loading={fetchingAllTriggers || archivingTrigger}
          rowKey="id"
          scroll={{ x: true }}
          dataSource={triggers}
          columns={columns}
        />
      </StyledCard>
      <ModalImportTriggerAction type="trigger" visible={visible} setVisible={setVisible} applicationId={parseInt(applicationId)} versionId={version?.id}/>
    </StyledTriggerView>
  )
}

export default TriggerView

const StyledTriggerView = styled(Layout.Content)`
  ${StyledCard} {
    margin: 0 24px 16px;
    padding: 0;
  }
`