import { Alert, Col, ConfigProvider, Empty, Row, Space, Table } from 'antd'
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { Scenario } from 'db'
import { DateTime } from 'luxon'
import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AvatarApplicationGroups from '../../../../components/pages/app/AvatarApplicationGroups'
import ActionsSelectedScenarios from '../../../../components/pages/app/scenario/all/ActionsSelectedScenarios'
import DragHandleScenario from '../../../../components/pages/app/scenario/all/DragHandleScenario'
import LayoutMyScenarioPage from '../../../../components/pages/app/scenario/all/LayoutMyScenarioPage'
import ModalImportScenario from '../../../../components/pages/app/scenario/all/ModalImportScenario'
import ScenarioFilterButtons from '../../../../components/pages/app/scenario/all/ScenarioFilterButtons'
import ScenarioTableActions from '../../../../components/pages/app/scenario/all/ScenarioTableActions'
import ScenarioTitle from '../../../../components/pages/app/scenario/all/ScenarioTitle'
import useScenarios from '../../../../hooks/useScenarios'
import { TableState } from '../../../../types'
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../../util/Constant'
import useOpenCloseModal from '../../../../util/useOpenCloseModal'
import { StyledButton, StyledDescription } from '../../../components/StyledComponents'

const ScenarioAllIndexPage: FC = () => {
  const [tableState, setTableState] = useState<TableState<Scenario>>({
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE
    },
    sorter: [{ field: 'active', order: 'descend' }, { field: 'createdAt', order: 'descend' }]
  })

  const { scenarios, fetchingScenarios, errorFetchScenarios, scenariosLength } = useScenarios({ expandState: true }, tableState)
  const [value, setValue] = useState<string>()
  // const { disableScenario } = useDisableAScenario(tableState)
  // const [onDisablingScenario, setOnDisablingScenario] = useState<number>()
  const history = useHistory()
  const { openModal, closeModal, visible } = useOpenCloseModal()
  const [selectedRows, setSelectedRows] = useState<Scenario[]>()
  const { folderId } = useParams<{ folderId?: string }>()

  useEffect(() => {
    setSelectedRows([])
    if (folderId) {
      setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, folderId: [folderId] } }))
    } else {
      setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, folderId: null } }))
    }
  }, [folderId])

  const handleChange = (pagination: TablePaginationConfig, filter: Record<string, FilterValue | null>, sorterParam: SorterResult<Scenario> | SorterResult<Scenario>[], extra: TableCurrentDataSource<Scenario>) => {
    const sorter: SorterResult<Scenario>[] = Array.isArray(sorterParam)  ? sorterParam as SorterResult<Scenario>[] : [sorterParam]
    setTableState(prevState => ({
      ...prevState,
      pagination,
      filter: { ...prevState.filter, ...filter },
      sorter: [...sorter,
        // ...(prevState.sorter as SorterResult<Scenario>[])
        //   .filter(s => s.field === 'lastExecuted')
      ],
      extra }))
  }

  useEffect(() => {
    if (scenarios?.length === 0 && (tableState.pagination?.current || 0) > 1) {
      setTableState((prevState) => ({ ...prevState, pagination: {
        ...prevState.pagination,
        current: (prevState.pagination?.current || 2) - 1
      } }))
    }
  }, [tableState, scenarios])

  const cols: ColumnsType<Scenario> = [
    {
      title: 'Apps',
      key: 'apps',
      dataIndex: 'apps',
      width: 124,
      render: (_, record) => {
        const applications = record.states?.sort((s1, s2) => s1.order - s2.order).map(state => (state.trigger || state.action)?.application)
        return <AvatarApplicationGroups applications={applications} />
      }
    },
    {
      title: 'Title',
      key: 'name',
      dataIndex: 'name',
      sorter: {
        multiple: 1,
      },
      render: (_, record) =>
        <ScenarioTitle scenario={record} tableState={tableState} />
    },
    {
      title: 'Jobs done',
      key: 'jobCount',
      className: 'text-right',
      dataIndex: 'jobCount',
      sorter: {
        multiple: 2,
      },
      render: (_, record) => parseInt(`${record.jobCount || 0}`).toLocaleString()
    },
    {
      title: 'Task Used',
      dataIndex: 'taskExecutedCount',
      sorter: {
        multiple: 3
      },
      className: 'text-right',
      render: (_, record) => parseInt(`${record.taskExecutedCount || 0}`).toLocaleString()
    },
    {
      title: 'Last Run',
      dataIndex: 'lastExecuted',
      align: 'right',
      width: 112,
      sorter: {
        multiple: 4,
        compare: (scenario1, scenario2) => scenario1.lastExecuted && scenario2.lastExecuted ? new Date(scenario1.lastExecuted).getTime() - new Date(scenario2.lastExecuted).getTime() : 0
      },
      render: (lastExecuted) =>
        lastExecuted ?
          <>
            <span>{DateTime.fromJSDate(new Date(lastExecuted)).toFormat('DD')}</span><br/>
            <span>{DateTime.fromJSDate(new Date(lastExecuted)).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}</span>
          </>:
          ''
    },
    {
      title: 'Created',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'right',
      width: 112,
      defaultSortOrder: 'descend',
      sorter: {
        compare: (s1, s2) => new Date(s1.createdAt).getTime() - new Date(s2.createdAt).getTime(),
        multiple: 5,
      },
      render: (_, record) =>
        <>
          <span>{DateTime.fromJSDate(new Date(record.createdAt)).toFormat('DD')}</span><br/>
          <span>{DateTime.fromJSDate(new Date(record.createdAt)).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}</span>
        </>
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'active',
      defaultSortOrder: 'descend',
      align: 'right',
      width: 112,
      sorter: {
        compare: (s1, s2) => Number(s1.active) - Number(s2.active),
        multiple: 6
      },
      render: (_, record) => record.deletedAt ? null : <ScenarioTableActions scenario={record} tableState={tableState} />
    }
  ]

  const onSearch = (value: string) => {
    setValue(value)
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const onClickCreateScenario = () => history.push('/app/create-scenario')

  return (
    <LayoutMyScenarioPage propsPageHeader={{
      extra: <Row gutter={[16, 16]} align="middle" justify="end">
        <ActionsSelectedScenarios setSelectedRows={setSelectedRows} selectedRows={selectedRows} tableState={tableState} />
        <Col><StyledButton type="primary" ghost onClick={openModal} >Import Scenario</StyledButton></Col>
        <Col><StyledButton type="primary" onClick={onClickCreateScenario} >Create Scenario</StyledButton></Col>
      </Row>
    }}>
      <ScenarioListContainer>
        {/* <Row style={{ marginBottom: '12px' }}>
          <Col span={24}>
            <Search placeholder="search scenario..." onSearch={onSearch} allowClear  />
          </Col>
        </Row> */}
        <ScenarioFilterButtons onSearch={onSearch} setTableState={setTableState} />
        {
          errorFetchScenarios ?
            <Alert message="Error fetching scenarios. Please refresh this page." type="error" /> :
            <ConfigProvider
              renderEmpty={() => <Empty
                imageStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 200
                }}
                image={fetchingScenarios ? null : value ? '/illustrations/notfound.svg' : '/illustrations/start.svg'}
                description={fetchingScenarios ? null : value ?
                  <>
                    <h5>Sorry, no result found</h5>
                    <StyledDescription>What you searched was unfortunately not found or doesn't exist</StyledDescription>
                  </> :
                  <>
                    <h5>There isn't any scenario here yet</h5>
                    <StyledDescription>Start creating your own scenario with easy steps in just a few minutes</StyledDescription>
                    <StyledButton type="primary" onClick={onClickCreateScenario} >Create Scenario</StyledButton>
                  </>}
              />}
            >
              <StyledTable
                size="small"
                rowSelection={{
                  selectedRowKeys: selectedRows?.map(scenario => scenario.id),
                  onChange: (_, selected) => {
                    setSelectedRows(selected)
                  },
                  renderCell: (_value, record, _index, originNode) => {
                    return <Space size={16}>
                      <DragHandleScenario selectedScenarios={selectedRows} scenario={record} />
                      {originNode}
                    </Space>
                  }
                }}
                onChange={handleChange}
                pagination={{
                  current: tableState.pagination?.current,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: scenariosLength,
                  pageSize: tableState.pagination?.pageSize
                }}
                columns={cols}
                dataSource={scenarios}
                rowKey={record => record.id}
                loading={fetchingScenarios}
                scroll={{ x: true }}
              />
            </ConfigProvider>
        }
        <ModalImportScenario visible={visible} closeModal={closeModal} tableState={tableState} />
      </ScenarioListContainer>
    </LayoutMyScenarioPage>
  )
}

export default ScenarioAllIndexPage

const ScenarioListContainer = styled.div`
  padding: 16px;
`

const StyledTable = styled((props) => <Table {...props} />)<TableProps<Scenario>>`
  .ant-table-cell.ant-table-selection-column {
    text-align: right;
  }
`