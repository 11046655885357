import { SearchOutlined } from '@ant-design/icons'
import React, { FC } from 'react'
import { DataSuggestion } from '../../../../developer/formItems/DataPickerPopper'
import { useThisStateStaticValue } from '../ThisStateStaticValueContext'
import useSearchInput from '../useSearchInput'
import CustomDataAccordion from './CustomDataAccordion'
import { CustomDualModeInputSearchData } from './DualModeDataPickerPopper'

type StateTestDataPickerProps = {
  statesWithTestData: Record<string, DataSuggestion> | null, // trigger and trigger.application should be expanded
  onTagClick: (tagValue: string) => void
}

const StateTestDataPicker: FC<StateTestDataPickerProps> = ({ statesWithTestData, onTagClick }) => {
  const { StyledSearchInput, searchQuery } = useSearchInput()
  const [thisStateStaticValue] = useThisStateStaticValue()
  const thisStateOrder = thisStateStaticValue?.order

  return (
    <>
      <div style={{ padding: '16px 16px 4px 16px' }}>
        <CustomDualModeInputSearchData>
          <StyledSearchInput
            style={{ marginBottom: 0 }}
            suffix={<SearchOutlined />}
            placeholder="Search data..."
            disabled={statesWithTestData ? Object.keys(statesWithTestData).filter(stateId => thisStateOrder ? (statesWithTestData[stateId].order || 0) < thisStateOrder : false).length === 0 : true}
          />
        </CustomDualModeInputSearchData>
      </div>
      <CustomDataAccordion
        statesWithTestData={statesWithTestData}
        onTagClick={onTagClick}
        searchTerm={searchQuery}
      />
    </>
  )
}

export default StateTestDataPicker