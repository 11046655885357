import React, { FC } from 'react'
import { Skeleton, Row, Space, Col } from 'antd'
import styled from 'styled-components'

const StateSkeleton: FC = () => {
  return (
    <WrapperSkeleton>
      <StyledCardSkeleton>
        <Row style={{ padding: '16px 24px' }} gutter={[16, 16]} justify="space-between" align="middle">
          <Col>
            <Space>
              <Skeleton.Avatar active size="large" style={{ height: 54, width: 54 }} shape="square" />
              <Space direction="vertical">
                <CustomSkeletonTitle title={{ width: 54 }} paragraph={false} active />
                <Skeleton.Input style={{ width: 200, height: 30 }} active />
              </Space>
            </Space>
          </Col>
          <Col>
            <Skeleton.Input style={{ width: 124, height: 30 }} active />
          </Col>
        </Row>
      </StyledCardSkeleton>
      <Skeleton.Button active style={{ width: '200px' }} />
    </WrapperSkeleton>
  )
}

export default StateSkeleton

const WrapperSkeleton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const CustomSkeletonTitle = styled(Skeleton)`
& .ant-skeleton-title {
  margin-top: 0;
  margin-bottom: 0;
}
`

const StyledCardSkeleton = styled.div`
  background: #FFF;
  width: 100%;
  margin: 12px 0;
`