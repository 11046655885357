import { ApplicationReview } from 'db'
import { useEffect, useState } from 'react'
import { PublicConfiguration } from 'swr/dist/types'
import { Error } from '../../../types'
import useFetch from '../../useFetch'

type Result = {
  revalidateApplicationReviews: () => void,
  applicationReviews?: ApplicationReview[],
  lastApplicationReview?: ApplicationReview,
  fetchingAllApplicationReviews: boolean,
  errorFetchAllApplicationReview?: Error
}

export default function useLastApplicationReview(applicationId?: number | string, config?: Partial<PublicConfiguration<any>>): Result {
  const url = applicationId ? `/application/${applicationId}/applicationReviews?take=1&orderBy=applicationReview.createdAt:desc` : null
  const { data, error, isValidating, revalidateFetch } = useFetch<{ applicationReviews: ApplicationReview[] }>(url, {}, { revalidateOnFocus: true, ...config })
  const [last, setLast] = useState<ApplicationReview>()

  useEffect(() => {
    if (data?.applicationReviews) {
      setLast(data.applicationReviews[0])
    } else {
      setLast(undefined)
    }
  }, [data])
  return {
    lastApplicationReview: last,
    revalidateApplicationReviews: () => revalidateFetch(url),
    applicationReviews: data?.applicationReviews,
    fetchingAllApplicationReviews: isValidating,
    errorFetchAllApplicationReview: error
  }
}