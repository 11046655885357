import { LogJob } from 'db'
import { Error } from '../../types'
import useFetch from '../useFetch'


type Result = {
  logJob?: LogJob,
  fetchingLogJob: boolean,
  errorFetchLogJobs?: Error
}

export default function useLogJob(logJobId?: number | string): Result {
  const url = logJobId ? `/logJob/${logJobId}?withDeleted=true&expands=scenario` : null
  const { data, error, isValidating } = useFetch<{ logJob: LogJob }>(url, {}, { revalidateOnFocus: false })

  return {
    logJob: data?.logJob,
    fetchingLogJob: isValidating,
    errorFetchLogJobs: error
  }
}