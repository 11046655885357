import { ClockCircleOutlined } from '@ant-design/icons'
import { Col, Layout, Row, Space, Table, Tag } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { ColumnType } from 'antd/lib/table'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import useDeleteInvitation from '../../../../../hooks/application/invitation/useDeleteInvitation'
import useAllMemberAndIvitation, { MemberAndInvitation } from '../../../../../hooks/application/member/useAllMemberAndInvitation'
import useDeleteMember from '../../../../../hooks/application/member/useDeleteMember'
import { orange } from '../../../../../util/colors'
import { getInitialName } from '../../../../../util/formatter'
import Notification from '../../../../../util/Notification'
import PageHeader from '../../../../components/PageHeader'
import { StyledCard } from '../../../../components/StyledComponents'
import MemberFormModal from './component/MemberFormModal'

const MemberPage: FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>()
  const { membersAndInvitations, fetchingAllMembersAndInvitation, revalidateMembersAndInvitation } = useAllMemberAndIvitation(applicationId)
  const [activeEdit, setActiveEdit] = useState<MemberAndInvitation>()
  const { deleteInvitation, deletingInvitation, deletedInvitation } = useDeleteInvitation()
  const { deleteMember, deletingMember, deletedMember } = useDeleteMember()
  const [members, setMembers] = useState<MemberAndInvitation[]>()

  const columns: ColumnType<MemberAndInvitation>[] = [
    { title: '', dataIndex: 'id', render: (_, record) => <Avatar style={{ background: orange[5] }} icon={getInitialName(record.user?.name || record.email || '')} /> },
    { title: 'Name', dataIndex: 'id', render: (_, record) => record.user?.name || record.user?.email || record.email },
    { title: 'Status', className: 'status', dataIndex: 'email', render: (_, record) => record.userId ? 'Member' : <Space><p>Pending</p><ClockCircleOutlined color="rgba(0, 0, 0, 0.25);" /></Space> },
    { title: 'Role', dataIndex: 'role', render: (role) => <Tag color={role === 'admin' ? 'geekblue' : 'green'} style={{ textTransform: 'capitalize' }}>{role}</Tag> },
    { title: 'Action', dataIndex: 'id', render: (_, record) => <Space>
      <a onClick={(e) => onEdit(e, record)} >Edit</a>
      <a onClick={(e) => onDelete(e, record)} >Delete</a>
    </Space> },
  ]

  const onEdit = (e: MouseEvent<HTMLElement>, data: MemberAndInvitation) => {
    e.preventDefault()
    e.stopPropagation()
    setActiveEdit(data)
  }

  const onDelete = (e: MouseEvent<HTMLElement> | undefined, data: MemberAndInvitation) => {
    e?.preventDefault()
    e?.stopPropagation()
    if (data.userId) {
      deleteMember(Number(applicationId), Number(data.id))
    } else {
      deleteInvitation(Number(applicationId), Number(data.id))
    }
  }

  const openNotif = (type: 'success' | 'error', title: string, description: string) => {
    Notification[type]({
      message: title,
      description: description
    })
  }

  useEffect(() => {
    if (deletedInvitation) {
      setMembers(prevState => prevState?.filter(member => member.email !== deletedInvitation.email))
      openNotif('success', 'Success', `Invitation to ${deletedInvitation.email} deleted`)
    }
  }, [deletedInvitation])

  useEffect(() => {
    if (deletedMember) {
      const member = members?.find(member => member.userId === deletedMember.userId)
      setMembers(prevState => prevState?.filter(member => member.userId !== deletedMember.userId))
      openNotif('success', 'Success', `Member ${member?.user?.name} deleted`)
    }
  }, [deletedMember])

  useEffect(() => {
    if (membersAndInvitations) {
      setMembers(membersAndInvitations)
    }
  }, [membersAndInvitations])

  const afterSubmit = (_: MemberAndInvitation) => {
    // const existedMember = members?.find(member => member.email === newMember.email)
    // if (existedMember) {
    //   setMembers(prevState => prevState?.map(member => existedMember.email === member.email ? newMember : member))
    // } else {
    //   setMembers(prevState => prevState ? [...prevState, newMember] : [newMember])
    // }
    revalidateMembersAndInvitation()
  }

  return (
    <StyledMember>
      <PageHeader title="Manage Team" description="Invite and manage team members on this application." />
      <StyledCard>
        <Row justify="space-between" align="middle">
          <Col>
            <h4>Member</h4>
          </Col>
          <Col>
            <MemberFormModal type="create" afterSubmit={afterSubmit} />
          </Col>
        </Row>
        <br />
        <Table
          dataSource={members}
          loading={fetchingAllMembersAndInvitation || deletingInvitation || deletingMember}
          rowKey="email"
          scroll={{ x: true }}
          columns={columns}
        />
      </StyledCard>
      <MemberFormModal type="update" onCancel={() => setActiveEdit(undefined)} onEdit={activeEdit} afterSubmit={afterSubmit} />
    </StyledMember>
  )
}

export default MemberPage

const StyledMember = styled(Layout.Content)`
  h4 {
    color: rgba(0, 0, 0, 0.65);
  }
  ${StyledCard} {
    margin: 16px 24px;
  }
`