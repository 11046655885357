import flatten from 'flat'
import moment from 'moment'
import { Action, Application, FieldSchema, Trigger } from '../../../packages/db/dist'
import { ChildStateType, ParentStateType } from '../components/pages/app/scenario/edit/StateTestDataContext'
import { IMPLIKASI_URL } from './Constant'
import truncateLongData from './truncateLongData'

export const getInitialName: (name?: string) => string = (name) => {
  if (name) {
    const splitedName = name.split(' ')
    return splitedName.length === 1 ? `${splitedName[0].charAt(0).toUpperCase()}` : `${splitedName[0].charAt(0).toUpperCase()}${splitedName[1].charAt(0).toUpperCase()}`
  }
  return ''
}

export const getRedirectOAuthUrl: (applicationId: number | string, authUuid: number | string) => string = (applicationId, authUuid) => {
  return `${IMPLIKASI_URL}/oauth/return/App${applicationId}CLIAPI/${authUuid}`
}

type ParamsStateNeeded = {
  conditionGroupOrder?: number,
  order: number,
  id: number,
  trigger?: Trigger,
  action?: Action,
  stateConditionId?: number | null
}
export function getChildsAllStates(states?: ParamsStateNeeded[]): { [key: number]: ChildStateType[] } {
  const memoize: { [key: number]: ChildStateType[] } = {}
  if (!states) {
    return memoize
  }
  states?.slice(0).sort((s1, s2) => s1.order > s2.order ? -1 : 1).map((state, _, states) => {
    const directChilds = states.filter(s => state.order + 1 === s.order &&
      (!s.stateConditionId || (state.stateConditionId === s.stateConditionId || state.id === s.stateConditionId)))
    if (directChilds.length === 0) {
      memoize[state.id] = []
    }
    directChilds.map(s => {
      if (memoize[state.id]) {
        memoize[state.id] = [...memoize[state.id], { id: s.id, order: s.order, conditionGroupOrder: s.conditionGroupOrder, application: (s.trigger || s.action)?.application }, ...memoize[s.id]]
      } else {
        memoize[state.id] = [{ id: s.id, order: s.order, conditionGroupOrder: s.conditionGroupOrder, application: (s.trigger || s.action)?.application }, ...memoize[s.id]]
      }
    })
  })
  return memoize
}

export function getParentsAllStates(states: ParamsStateNeeded[]): { [key: number]: ParentStateType[] } {
  const memoize: { [key: number]: ParentStateType[] } = {}
  if (!states) {
    return memoize
  }
  states?.slice(0).sort((s1, s2) => s1.order < s2.order ? -1 : 1).map((state, _, states) => {
    const parent = states.find(s => s.order === state.order - 1 &&
      (!state.stateConditionId || (state.stateConditionId === s.stateConditionId || state.stateConditionId === s.id))
    )
    if (parent) {
      if (memoize[parent.id]) {
        memoize[state.id] = [...memoize[parent.id], parent.id]
      }
    } else {
      memoize[state.id] = []
    }
  })
  return memoize
}

export function currencyFormatterIDR(num?: number): string {
  if (num) {
    return `IDR ${`${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
  }
  return 'IDR 0'
}

export function numberFormatting(num?: number): string {
  if (num) {
    return `${`${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
  }
  return '0'
}

export function convertListOfPairedKeyValueToStringRecordJSON(list?: ({ key?: string, value: string } | undefined)[]): string | undefined {
  if (list) {
    const mapping = list.reduce((resValue, curr) => {
      if (curr?.key) {
        return {
          ...resValue,
          [curr.key]: curr.value || ''
        }
      }
      return { ...resValue }
    }, {})
    return Object.keys(mapping).length === 0 ? undefined : JSON.stringify(mapping)
  }
  return ''
}

export function convertStringRecordJSONToListOfPairedKeyValue(data?: string): { key?: string, value: string }[] {
  const defaultValue = [{ key: '', value: '' }]
  if (data) {
    try {
      const jsonDataInput = JSON.parse(data)
      const value: { key?: string, value: string }[] = Object.keys(jsonDataInput).map(key => ({ key: key, value: jsonDataInput[key] }))
      if (value.length === 0) {
        return defaultValue
      }
      return value
    } catch (_: any) {
      return defaultValue
    }

  }
  return defaultValue
}
// useMoment param untuk formatter di developer mode, date picker antd pake moment
export function convertParamsToFormValue(params?: Record<string, string>, paramSchema?: FieldSchema[], useMoment?: boolean): any {
  if (params) {
    if (paramSchema) {
      return paramSchema.reduce((res, currField) => {
        const key = currField?.key
        if (paramSchema?.find(field => field.key === key && field.type === 'key-value')) {
          return {
            ...res,
            [key]: convertStringRecordJSONToListOfPairedKeyValue(params[key])
          }
        }
        if (useMoment && paramSchema?.find(field => field.key === key && (field.type === 'date' || field.type === 'date-time'))) {
          return {
            ...res,
            [key]: params[key] !== undefined && params[key] !== null ? moment(params[key]) : currField?.default ? moment(currField?.default) : null
          }
        }
        return {
          ...res,
          [key]: params[key] !== undefined && params[key] !== null ? params[key] : currField?.default
        }
      }, {})
    }
    return Object.keys(params).reduce((res, key) => {
      return {
        ...res,
        [key]: params[key]
      }
    }, {})
  } else if (paramSchema) {
    return paramSchema.filter(field => field.default !== undefined || field.type === 'key-value').reduce((res, field) => {
      if (field.type === 'key-value') {
        return {
          ...res,
          [field.key]: convertStringRecordJSONToListOfPairedKeyValue(field.default)
        }
      }
      return {
        ...res,
        [field.key]: field.default
      }
    }, {})
  }
  return {}
}

type Values = any
export function convertFormValueToParams(formValue: Values): Record<string, string> {
  if (formValue) {
    return Object.keys(formValue).reduce((res, key) => {
      const val = formValue[key]
      if (Array.isArray(val)) {
        return {
          ...res,
          [key]: convertListOfPairedKeyValueToStringRecordJSON(val)
        }
      }
      // buat test action (date nya moment)
      if (moment.isMoment(val)) {
        return {
          ...res,
          [key]: moment(val).toISOString()
        }
      }
      // buat di edit scenario
      if (val && !Number(val) && Date.parse(val)) {
        return {
          ...res,
          [key]: new Date(val).toISOString()
        }
      }

      if (val && typeof val === 'object') {
        return {
          ...res,
          [key]: {
            ...val,
            _value: val['_value'] || null
          }
        }
      }
      return {
        ...res,
        [key]: formValue[key]
      }
    }, {})
  }
  return {}
}

export function formatterValueBracket(text?: string): string {
  if (text) {
    return text.replace(/\{{2,3}.*?\}{2,3}/gi, value => {
      const vals = value.replace(/[\{\}]/gi, '').trim().split('.')
      return '{{{'
        + vals.map(attr => `[${attr}]`).join('.')
        + '}}}'
    })
  }
  return ''
}

export function truncateData(data: unknown): any {
  if (Array.isArray(data)) {
    return data.map(datum => truncateData(datum))
  }
  if (typeof data === 'object' && data) {
    return Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: truncateData(data[key]) }), {})
  }
  if (typeof data === 'string') {
    return truncateLongData(data, 100)
  }
  return data
}

type ParamGetFlatDataTest = { testData: any, stateId: number, stateOrder: number, application?: Application }
type ResultGetFlatDataTest = Record<string, {
  sample: any,
  value: string,
  label: string,
  appLogoUrl?: string
}>
export const getFlatDataTest = ({ testData, stateId, stateOrder, application }: ParamGetFlatDataTest): ResultGetFlatDataTest => {
  if (!testData) {
    return {}
  }
  const defaultFlat = Array.isArray(testData) ? {
    [stateId]: {
      sample: testData,
      value: `${stateId}`,
      label: `${stateOrder + 1}. Data (array)`,
      appLogoUrl: application?.logoUrl
    }
  } : {}
  const flattenTestData: Record<string, any> = flatten(testData)

  const manualFlat = Object.keys(testData).reduce((acc, key) => {
    if (typeof testData[key] === 'object') {
      return {
        ...acc,
        ...helperManualFlatTestData({ prevKey: key, testData: testData[key], stateId, stateOrder, application }),
        [key]: {
          sample: testData[key],
          value: `${stateId}.${key}`,
          label: `${stateOrder + 1}. ${key} ${Array.isArray(testData[key]) ? '(array)' : testData[key] ? '(object)' : ''}`,
          appLogoUrl: application?.logoUrl
        }
      }
    }
    return {
      ...acc
    }
  }, defaultFlat)

  const flatTestData = Object.keys(flattenTestData)
    .filter(dataKey => typeof flattenTestData[dataKey] !== 'object')
    .reduce((acc, dataKey) => ({
      ...acc,
      [dataKey]: {
        sample: flattenTestData[dataKey],
        value: `${stateId}.${dataKey}`,
        label: `${stateOrder + 1}. ${dataKey}`,
        appLogoUrl: application?.logoUrl
      }
    }), manualFlat)
  return flatTestData
}

const helperManualFlatTestData = ({ prevKey, testData, stateOrder, stateId, application }: ParamGetFlatDataTest & { prevKey: string }) => {
  if (!testData) {
    return
  }
  return Object.keys(testData).reduce((acc, key) => {
    if (typeof testData[key] === 'object') {
      return {
        ...acc,
        ...helperManualFlatTestData({ prevKey: `${prevKey}.${key}`, testData: testData[key], application, stateId, stateOrder }),
        [`${prevKey}.${key}`]: {
          sample: testData[key],
          value: `${stateId}.${prevKey}.${key}`,
          label: `${stateOrder + 1}. ${prevKey ? `${prevKey}.` : ''}${key}  ${Array.isArray(testData[key]) ? '(array)' : testData[key] ? '(object)' : ''}`,
          appLogoUrl: application?.logoUrl
        }
      }
    }
    return {
      ...acc
    }
  }, {})
}