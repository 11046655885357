import { FC } from 'react'
import { Col, Layout, Row } from 'antd'
import { Application } from 'db'
import styled from 'styled-components'
import { StyledCard, StyledText } from '../../../../../../pages/components/StyledComponents'
import SearchApplication from './SearchApplication'
import SelectedApplicationItem from './SelectedApplicationItem'

const StepOneSelectApplication: FC<{
  selectedApplications: Application[],
  addApplication: (app: Application) => void,
  removeApplicationByIndex: (index: number) => void
}> = ({ selectedApplications, addApplication, removeApplicationByIndex }) => {
  return (
    <StyledApplicationsForm>
      <Row className="apps-plus">
        {
          selectedApplications.length === 0 ?
            // placeholder if selected apps is empty
            [...new Array(2)].map((_, idx) =>
              <SelectedApplicationItem
                idx={idx}
                key={idx}
                hasLeftConnector={idx !== 0}
                hasRightConnector={idx !== 1} // lol
              />
            ) :
            // selected apps not empty
            selectedApplications
              .map((application, idx, selectedApplications) =>
                <SelectedApplicationItem
                  key={idx}
                  idx={idx}
                  application={application}
                  hasLeftConnector={idx !== 0} // all apps node always has left connector, except the 1st node
                  hasRightConnector={true} // all apps node always has right connector
                  isConnected={!!selectedApplications[idx + 1]} // if there is next item, then it is connected
                  onClickCloseButton={removeApplicationByIndex}
                />
              )
              .concat(
                // empty node on the tail, only appears when there is at least one selected app
                <SelectedApplicationItem
                  key={selectedApplications.length}
                  idx={selectedApplications.length}
                  hasLeftConnector={true}
                  hasRightConnector={false}
                />
              )
        }
      </Row>
      <br />
      <Row>
        <Col flex="auto">
          <SearchApplication onClickApp={addApplication} selectedAppIds={selectedApplications.reduce((res, curr) => {
            return {
              ...res,
              [curr.id]: true
            }
          }, {})} />
        </Col>
      </Row>
    </StyledApplicationsForm>
  )
}

export default StepOneSelectApplication

const StyledApplicationsForm = styled(Layout.Content)`
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.65);

  ${StyledCard} {
    padding: 24px;
  }
  ${StyledText} {
    color: rgba(0, 0, 0, 0.65);
  }
  .apps-plus {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .app-item {
    cursor: pointer;
  }

  .show {
    max-height: 100px;
    overflow: hidden;
  }
`