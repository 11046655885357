import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RedocStandalone } from 'redoc'
import { parse } from 'yamljs'
import { OTORISASI_API_URL } from '../../helpers/Constant'


const Docs: React.FC = () => {
  const [spec, setSpec] = useState<any>()

  useEffect(() => {
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/docs`, { withCredentials: true })
      .then(({ data }) => setSpec(parse(data)))
  }, [])

  return (
    <div>
      { spec ? <RedocStandalone spec={spec} /> : '' }
    </div>
  )
}

export default Docs