import { CloseOutlined, MenuOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Dropdown, Input, Layout, Menu, Row, Space, Typography } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { ReactComponent as OtomatisWhiteLogotype } from '../../assets/otomatis-logo (white).svg'
import AnchorWrapper from '../../components/AnchorWrapper'
import useUser from '../../hooks/user/useUser'
import { IMPLIKASI_URL } from '../../util/Constant'
import { getInitialName } from '../../util/formatter'
import { baseApplicationRoute } from '../developer/application/routes'

const Header: React.FC = () => {
  const [user, setUser] = useUser()
  const history = useHistory()
  const { path } = useRouteMatch()
  const link = {
    logout: '/auth/logout',
    app: '/app/create-scenario',
    devmode: '/developer',
    profile: '/app/settings/profile',
    privacyPolicy: '/app/privacy-policy',
    tos: '/app/term-of-services',
    docs: `${IMPLIKASI_URL}/private/docs/docs/build-apps/initiate-app`
  }

  const logout = async () => {
    setUser(undefined)
    history.push('/auth/logout')
  }

  const toUserProfile = () => {
    history.push('/app/settings/profile')
  }

  const createScenario = () => {
    const a = document.createElement('a')
    a.target = '_blank'
    // console.log(url)
    a.href = '/app/create-scenario'
    a.rel = 'noopener noreferrer'
    a.click()
  }

  const menu = <Menu>
    <Menu.Item key="toProfile" onClick={toUserProfile}><AnchorWrapper href={link['profile']}>Profile</AnchorWrapper></Menu.Item>
    <Menu.Item key="logout" onClick={logout}><AnchorWrapper href={link['logout']}>Logout</AnchorWrapper></Menu.Item>
    <Menu.Item key="create" onClick={createScenario}><AnchorWrapper href={link['app']} >Create Scenario</AnchorWrapper></Menu.Item>
  </Menu>

  const goToHomeDeveloper = () => {
    history.push('/developer')
  }

  const goToDocs = () => {
    window.location.href = `${IMPLIKASI_URL}/private/docs/docs/build-apps/initiate-app`
  }

  return (
    <StyledHeader>
      <Wrapper gutter={16}>
        <Col flex={0}>
          <AnchorWrapper href={link['devmode']}>
            <Space onClick={goToHomeDeveloper} style={{ cursor: 'pointer' }}>
              <OtomatisLogo>
                <OtomatisWhiteLogotype style={{ width: 'auto', height: '90%' }}/>
              </OtomatisLogo>
              <Typography.Title level={4}>Dev Mode</Typography.Title>
            </Space>
          </AnchorWrapper>
        </Col>
        <Col flex="auto" >
          <CheckboxResponsive type="checkbox" id="menu-header" />
          <Label htmlFor="menu-header"><StyledMenu /><StyledClose /></Label>

          {
            user ?
              <UserUtility gutter={16} justify="end">
                <Col flex={0} onClick={() => history.push(`${baseApplicationRoute}/me`)} className={path.includes('application/me') ? 'active': undefined}>
                  <AnchorWrapper href={`${baseApplicationRoute}/me`} >My App</AnchorWrapper>
                </Col>
                {
                  user.isAdmin ?
                    <Col flex={0} onClick={() => history.push(`${baseApplicationRoute}/review-app`)} className={path.includes('application/review') ? 'active': undefined}>
                      <AnchorWrapper href={`${baseApplicationRoute}/review-app`}>Review App</AnchorWrapper>
                    </Col> :
                    null
                }
                <Col flex={0}>
                  <SearchOutlined />
                </Col>
                <Col flex={0}>
                  <AnchorWrapper href={link['docs']}><QuestionCircleOutlined onClick={goToDocs} /></AnchorWrapper>
                </Col>
                <Col flex={0}>
                  <StyledDropdown overlay={menu}>
                    <Space>
                      <Avatar size="small" icon={user.profilePicture ? <img src={user.profilePicture} /> : getInitialName(user.name)} />
                      <span className="name" >{user.name}</span>
                    </Space>
                  </StyledDropdown>

                </Col>
              </UserUtility> :
              null
          }
        </Col>

      </Wrapper>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled(Layout.Header)`
  background: #3490FF;
  height: 58px;
  line-height: 58px;
  padding: 0 36px;
  .active {
    font-weight: bold;
  }
  @media (max-width: 320px) {
    h4 {
      visibility: hidden;
    }
  }
  @media (max-width: 500px) {
    height: max-content;
    padding: 0 10px;
  }
  h4.ant-typography, .ant-typography h4 {
    font-family: Metropolis;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
    margin-top: 7px;
  }
`
const StyledInput = styled(Input)`
  background: #6FB0FF;
  border-radius: 10px;
  border: none;
  color: #fff;
  max-width: 40vw;
  input {
    background: #6FB0FF;
    color: #fff;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #fff;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #fff;
    }
  }
`
const StyledDropdown = styled(Dropdown)`
  float: right;
  color: #fff;
  .ant-avatar {
    background: ${'#F3AD3D'};
    text-transform: uppercase;
  }
  .name {
    text-transform: capitalize;
  }
`

const Wrapper = styled(Row)`
  .utils {
    align-items: flex-end;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const UserUtility = styled(Row)`
  color: #FFF;
  .ant-col {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    display: none;
    > div {
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
`
const StyledMenu = styled(MenuOutlined)`
  color: white;
  width: 40px;
`
const StyledClose = styled(CloseOutlined)`
  color: white;
  width: 40px;
`

const Label = styled.label`
  @media (min-width: 500px) {
    display: none;
  }
  position: absolute;
  z-index: 3;
  right: 12px;
  top: -45px;
  ${StyledMenu} {
    /* visibility: visible; */
    display: block;
  }
  ${StyledClose} {
    /* visibility: hidden; */
    display: none;
  }
`
const CheckboxResponsive = styled.input`
  display: none;
  &:checked + ${Label} {
    ${StyledClose} {
      /* visibility: visible; */
      display: block;
    }
    ${StyledMenu} {
      /* visibility: hidden; */
      display: none;
    }
  }
  &:checked ~ ${UserUtility} {
    @media (max-width: 500px) {
      display: flex;
      line-height: 1;
      flex-direction: column;
      align-items: flex-end;
      .search-field {
        width: 100%;
        ${StyledInput} {
          max-width: unset;
          width: 100%;
        }
      }
    }
  }
`
const OtomatisLogo = styled.div`
  height: 32px;
  margin: 14px 0 7px;
`