import { Checkbox, DatePicker, Input, Modal, Radio, Select, Space, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FieldSchema } from 'db'
import { FC, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useParams } from 'react-router'
import { isValidRegex } from 'src/util/functions'
import styled from 'styled-components'
import MarkdownRenderer from '../../../../../../../../components/MarkdownRenderer'
import RichKeyValueInput from '../../../../../../../../components/pages/app/scenario/edit/RichKeyValueInput'
import FormListFieldSchema from '../../../../../../../../components/pages/developer/formItems/FormListFieldSchema'
import { useLoggedInUser } from '../../../../../../../../contexts/LoggedInUserContextProvider'
import useCreateOrUpdateTrigger from '../../../../../../../../hooks/application/trigger/useCreateOrUpdateTrigger'
import useHiddenTriggers from '../../../../../../../../hooks/application/trigger/useHiddenTriggers'
import { useRevalidateFetch } from '../../../../../../../../hooks/useFetch'
import { convertFormValueToParams, convertStringRecordJSONToListOfPairedKeyValue } from '../../../../../../../../util/formatter'
import { StyledButton, StyledCheckbox, StyledFooterForm, StyledLabel, StyledTag } from '../../../../../../../components/StyledComponents'
import CustomForm from '../../../../../../components/form/CustomForm'
import FormItem from '../../../../../../components/form/FormItem'
import MonacoEditor from '../../../../../../components/form/MonacoEditor'
import FormParamSchema from '../../../../../components/FormParamSchema'
import { useApplicationVersionContext } from '../../../../../contexts/ApplicationVersionContextProvider'
import { useSharedValueTrigger } from '../../SharedValueTriggerContextProvider'

interface Props {
  activeEditFieldForm?: FieldSchema & { idx?: number },
  onCancel: () => void
}
const FieldForm: FC<Props> = ({ onCancel, activeEditFieldForm }) => {
  const { revalidateTrigger } = useRevalidateFetch()
  const [user] = useLoggedInUser()
  const [values] = useSharedValueTrigger()
  const [form] = useForm<FieldSchema | any>()
  const { applicationId } = useParams<{ applicationId: string }>()
  const [version] = useApplicationVersionContext()
  const { hiddenTriggers, fetchingHiddenTriggers } = useHiddenTriggers(applicationId, version?.id)
  const [modalOpen, setModalOpen] = useState(false)
  const [formPreview] = useForm()
  const { createOrUpdateTrigger, creatingTrigger } = useCreateOrUpdateTrigger()

  const convertValuesToFieldSchemaObject = (values: any) => {
    const { stringOptions, mapSchema, ...allValues } = values
    const convertedValues = convertFormValueToParams(allValues)
    const newMapSchema = mapSchema?.map(field => convertValuesToFieldSchemaObject(field))
    const field: any = { ...convertedValues, mapSchema: newMapSchema, options: stringOptions ? JSON.parse(stringOptions) : undefined }
    return field

  }
  const onSubmit = async (allValues: any) => {
    const field: any = convertValuesToFieldSchemaObject(allValues)
    const isKeyDuplicate = values?.paramSchema?.find(curr => curr.key === field.key)

    const existed = values?.paramSchema || []

    const newFields = isKeyDuplicate || activeEditFieldForm ? existed?.map((currField, idx) => {
      if (currField.key === allValues.key) {
        return field
      }
      if (idx === activeEditFieldForm?.idx) {
        return field
      }
      return currField
    }): [...existed, field]

    const newTrigger = await createOrUpdateTrigger(applicationId,
      { ...values, paramSchema: newFields, isDraft: values?.isDraft === undefined ? true : values.isDraft,
        versionId: values?.versionId || version?.id })

    if (newTrigger) {
      revalidateTrigger(applicationId, values?.id, values?.versionId || version?.id)
      onCancelForm()
    }
    // setValues(prevState => ({ ...prevState, onAddFieldForm: false, activeEditFieldForm: undefined, paramSchema: newFields }))
  }

  const onCancelForm = () => {
    onCancel()
  }

  useEffect(() => {
    if (activeEditFieldForm) {
      const formValues = {
        ...activeEditFieldForm,
        isDropdown: !!activeEditFieldForm.options || !!activeEditFieldForm.triggerId || false,
        dropdownType: activeEditFieldForm.triggerId ? 'dynamic' : 'static',
        stringOptions: JSON.stringify(activeEditFieldForm.options, null, 2),
        mapSchema: activeEditFieldForm.mapSchema?.map((field) => {
          return {
            ...field,
            isDropdown: !!field.options || !!field.triggerId || false,
            dropdownType: field.triggerId ? 'dynamic' : 'static',
            stringOptions: JSON.stringify(field.options, null, 2)
          }
        })
      }
      if (activeEditFieldForm.type === 'key-value') {
        form.setFieldsValue({
          ...formValues,
          default: convertStringRecordJSONToListOfPairedKeyValue(activeEditFieldForm.default)
        })
      } else {
        form.setFieldsValue({
          ...formValues
        })
      }

    } else {
      form.resetFields()
      form.setFieldsValue({
        type: 'string'
      })
    }
  }, [activeEditFieldForm])

  const onShowPreview = () => {
    setModalOpen(true)
  }

  const onChangeValueForm = (value: any) => {
    if (value.type === 'key-value' && !activeEditFieldForm) {
      form.setFieldsValue({
        default: [{ key: '', value: '' }]
      })
    }
  }

  return (
    <CustomForm onFinish={onSubmit} form={form} onValuesChange={onChangeValueForm}>
      <FormItem label="Key" name="key" description="Enter the word or phrase your API uses to reference this field or parameter. Not seen by users." rules={[{ required: true }]} extra={<Space>
        <p>Example:</p>
        <StyledTag>first_name</StyledTag>
        <StyledTag>nama_depan</StyledTag>
      </Space>} >
        <Input />
      </FormItem>
      <FormItem label="Label" name="label" description="Enter the field's friendly name for users." rules={[{ required: true }]} extra={<Space>
        <p>Example:</p>
        <StyledTag>First Name</StyledTag>
        <StyledTag>Nama Depan</StyledTag>
      </Space>}>
        <Input />
      </FormItem>
      <FormItem label="Help Text" name="helpText" description="Explain to users what to include in this field, especially for API keys and other hard to find info. Include directions to find the data and links to your app settings or help docs." >
        <MonacoEditor defaultLanguage="markdown" height="150px" />
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev.helpText !== next.helpText}>
        {({ getFieldValue }) => {
          const helpText = getFieldValue('helpText')
          if (helpText) {
            return (
              <>
                Preview Help Text:
                <StyledMarkdown>{helpText}</StyledMarkdown>
              </>
            )
          }
          return null
        }}
      </FormItem>
      <FormItem label="Type" name="type" description="Select the field type. Use String (default) for most text input, or Password to obscure text for secret values." >
        <Select>
          <Select.Option value="string" >String</Select.Option>
          <Select.Option value="password" >Password</Select.Option>
          <Select.Option value="json" >JSON</Select.Option>
          <Select.Option value="key-value" >{'<Key, Value>'} Pair</Select.Option>
          <Select.Option value="array-map" >Multi Item</Select.Option>
          {
            user?.isAdmin ?
              <>
                <Select.Option value="code" >Code</Select.Option>
              </>
              : null
          }
          <Select.Option value="image" >Image URL</Select.Option>
          <Select.Option value="date-time" >Date Time</Select.Option>
          <Select.Option value="date" >Date</Select.Option>
        </Select>
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => {
          if (getFieldValue('type') === 'string') {
            return (
              <>
                <FormItem rules={[() => ({
                  async validator(_: any, value: any) {
                    if (!isValidRegex(value)) {
                      return Promise.reject(new Error('Regex is not valid'))
                    }
                    return Promise.resolve()
                  },
                })]} name="regexRule" label="Regex Validation" description={<>Validate Makers input using RegExp, ex: <StyledTag>/[0-9]/gi</StyledTag> </>}
                >
                  <Input />
                </FormItem>
                <FormItem name="regexErrorMessage" label="Regex Validation Error Message" description="Error message that will be displayed to Makers if the RegExp validation failed.">
                  <Input />
                </FormItem>
              </>
            )
          }
          return null
        }}
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => {
          const type = getFieldValue('type')
          if (type === 'array-map') {
            return null
          }
          if (type === 'key-value') {
            return (
              <RichKeyValueInput name="default" label="Default Value" description="Include a default value for this field as a fallback. For optional fields, the default value is set on initial creation and used instead of missing or null values every time the Connectioon runs. For required fields, this value is used during Connection creation, but not when the Connection runs (Otomatis raises an error for missing/null values instead)." />
            )
          }
          return (
            <FormItem label="Default Value" name="default" description="Include a default value for this field as a fallback. For optional fields, the default value is set on initial creation and used instead of missing or null values every time the Connectioon runs. For required fields, this value is used during Connection creation, but not when the Connection runs (Otomatis raises an error for missing/null values instead)." >
              {
                type === 'code' ?
                  <MonacoEditor defaultLanguage="javascript" /> :
                  type === 'date-time' || type === 'date' ?
                    <DatePicker showTime={type === 'date-time'} /> :
                    <Input />
              }
            </FormItem>
          )
        }}
      </FormItem>
      <FormItem name="getValueFunc" label="Computed Value" description="Get value from others input" >
        <MonacoEditor theme="vs-dark" defaultLanguage='javascript' />
      </FormItem>
      <FormItem name='allowEditByUser' valuePropName="checked" label="Show Computed Value" description="Enable to make computed value result editable">
        <Switch />
      </FormItem>
      <FormItem label="Constant Value" name="constantValue" description="Include a constant value for this field as a fallback. User will cannot change the value of this field." >
        <Input />
      </FormItem>
      <StyledLabel>Options</StyledLabel>
      <FormItem name="required" valuePropName="checked" style={{ marginBottom: 0 }} >
        <Checkbox>Required</Checkbox>
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev.type !== next.type}>
        {
          ({ getFieldValue }) => {
            if (getFieldValue('type') === 'array-map') {
              return null
            }
            return <FormItem name={'isDropdown'} valuePropName="checked" style={{ marginBottom: 0 }} >
              <StyledCheckbox>Dropdown</StyledCheckbox>
            </FormItem>
          }
        }
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => {
        return prev.isDropdown !== next.isDropdown
      }}>
        {
          ({ getFieldValue }) => {
            const useDropdown = getFieldValue('isDropdown')
            if (useDropdown) {
              return <FormItem label="Dropdown Type" name={'dropdownType'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={'static'}>Static</Radio>
                  <Radio value={'dynamic'}>Dynamic</Radio>
                </Radio.Group>
              </FormItem>
            }
          }
        }
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev.isDropdown !== next.isDropdown || prev.dropdownType !== next.dropdownType}>
        {
          ({ getFieldValue }) => {
            const useDropdown = getFieldValue('isDropdown')
            const dropdownType = getFieldValue('dropdownType')
            if (useDropdown && dropdownType === 'static') {
              return <FormItem label="Static Dropdown" rules={[{ required: true }]}
                description="List the dropdown choices."
                extra={<>Examples: ["username", "password"] or {'[{ "key": "userId", "label": "User ID" }]'}</>}
                name={'stringOptions'}
                fieldKey={'stringOptions'}
              >
                <MonacoEditor height="100px" theme="vs-dark" />
              </FormItem>
            } else if (useDropdown && dropdownType === 'dynamic') {
              return (
                <FormItem name={'triggerId'} label="Dropdown Source" rules={[{ required: true, message: 'Trigger is required' }]}>
                  <Select
                    loading={fetchingHiddenTriggers}
                    filterOption={(input, option) =>
                      option?.title?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {hiddenTriggers?.map(trigger => <Select.Option key={trigger.id} title={trigger.name} value={trigger.id}>{trigger.name}</Select.Option>)}
                  </Select>
                </FormItem>
              )
            }
            return null
          }
        }
      </FormItem>

      <FormItem shouldUpdate={(prev, next) => prev.type !== next.type}>
        {
          ({ getFieldValue }) => {
            if (getFieldValue('type') === 'array-map') {
              return (
                <DndProvider backend={HTML5Backend}>
                  <FormListFieldSchema name="mapSchema" hiddenTriggers={hiddenTriggers} />
                </DndProvider>
              )
            }
          }
        }
      </FormItem>
      <FormItem shouldUpdate>
        {
          ({ getFieldsError }) => {
            const isDisabledTest = getFieldsError().filter(field => field.errors.length > 0).length > 0
            return (
              <>
                <StyledButton htmlType="button" onClick={onCancelForm} danger ghost>Discard</StyledButton>
                <StyledFooterForm>
                  <StyledButton disabled={isDisabledTest} htmlType="button" onClick={onShowPreview}>Form Preview</StyledButton>
                  <StyledButton disabled={isDisabledTest} type="primary" loading={creatingTrigger} htmlType="submit" >{activeEditFieldForm ? 'Save' : 'Add'}</StyledButton>
                </StyledFooterForm>
              </>
            )
          }
        }
      </FormItem>
      <Modal visible={modalOpen} title="Form Preview" onOk={() => setModalOpen(false)} onCancel={() => setModalOpen(false)}>
        <FormParamSchema form={formPreview} initialParamSchema={values?.paramSchema ?
          activeEditFieldForm ?
            values.paramSchema?.map(field => field.key === form.getFieldsValue()?.key ? form.getFieldsValue() : field) :
            [...values.paramSchema, form.getFieldsValue()] :
          [form.getFieldsValue()]} applicationId={Number(applicationId)}>
        </FormParamSchema>
      </Modal>
    </CustomForm>
  )
}

export default FieldForm

const StyledMarkdown = styled(MarkdownRenderer)`
  height: 200px;
  overflow-y: auto;
`