import { Divider } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

const CustomSiderDivider: FC = () => {
  return (
    <CustomSiderDividerContainer>
      <Divider style={{ backgroundColor: 'rgba(255,255,255, .24)' }} />
    </CustomSiderDividerContainer>
  )
}

const CustomSiderDividerContainer = styled.div`
  margin: 12px;

  & .ant-divider {
    margin: 16px 0;
  }
`

export default CustomSiderDivider