import { CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined } from '@ant-design/icons'
import { Collapse, Divider, Form, Space, Tabs } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import DataResponse from '../../../../../../../../components/pages/app/history/job/DataResponse'
import { useUpdateDataSuggestions } from '../../../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import SelectOrAddBundle from '../../../../../../../../components/pages/developer/main/SelectOrAddBundle'
import useAllAuthentication from '../../../../../../../../hooks/application/authentication/useAllAuthentication'
import useCreateOrUpdateTrigger from '../../../../../../../../hooks/application/trigger/useCreateOrUpdateTrigger'
import useTriggerTest from '../../../../../../../../hooks/application/trigger/useTriggerTest'
import useWebhookUrlTrigger from '../../../../../../../../hooks/application/trigger/useWebhookUrlTrigger'
import { IMPLIKASI_URL } from '../../../../../../../../util/Constant'
import { convertFormValueToParams, convertParamsToFormValue } from '../../../../../../../../util/formatter'
import { StyledButton, StyledCard, StyledDescription, StyledFooterForm, StyledLabel, StyledTag, StyledText } from '../../../../../../../components/StyledComponents'
import MonacoEditor from '../../../../../../components/form/MonacoEditor'
import FormParamSchema from '../../../../../components/FormParamSchema'
import { useApplicationVersionContext } from '../../../../../contexts/ApplicationVersionContextProvider'
import { basePathTrigger, PropsContentStep } from '../../data'
import { useSharedValueTrigger } from '../../SharedValueTriggerContextProvider'

const Test: FC<PropsContentStep> = ({ onPrevious, isActive }) => {
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()
  // const { application } = useApplication(applicationId)
  const [version] = useApplicationVersionContext()
  const { authentications } = useAllAuthentication(applicationId, version?.id)
  const authenticationId = authentications ? authentications[0]?.id : undefined
  const [values, setValues] = useSharedValueTrigger()
  const [form] = useForm()
  const { createOrUpdateTrigger, creatingTrigger } = useCreateOrUpdateTrigger()
  const { testTrigger, testingTrigger, result } = useTriggerTest(applicationId, values?.id)
  const [response, setResponse] = useState<any>()
  const [bundles, setBundles] = useState<any>()
  const [counterRefetch, setCounterRefetch] = useState(0)
  const { push } = useHistory()
  const authentication = authentications?.[0]
  const [formAccount] = useForm()
  const { url } = useWebhookUrlTrigger(applicationId, values?.isAggregator ? values.id : undefined)

  useUpdateDataSuggestions(bundles, authentication, formAccount, undefined, result)

  const getAllOptions = () => {
    setCounterRefetch(counterRefetch + 1)
  }

  const onTest = async () => {
    // create or update trigger first
    if (values) {
      // const newTrigger = await createOrUpdateTrigger(applicationId, { ...values, isDraft: false,  testGuideline, versionId: values.versionId || version?.id })
      // if (newTrigger) {
      //   setValues({ ...values, ...newTrigger })
      // test
      const test = await testTrigger({
        params: convertFormValueToParams(form.getFieldsValue()),
        ...bundles
      })
      if (test) {
        setResponse(test)
      }
      // }
    }
  }

  const _onConnectAccount = () => {
    const windowAuth = window.open(`${IMPLIKASI_URL}/${applicationId}/run/auth/${authenticationId}?versionId=${version?.id}`, '_blank', 'height=600,width=800,centerscreen,chrome=yes')
    if (windowAuth) {
      windowAuth.onbeforeunload = () => {
        const windowAuthAsAny = windowAuth as any
        if (windowAuthAsAny.bundles) {
          // Old code. It assumes that nameAccountOtomatis always has a value, it isn't.
          // const { nameAccountOtomatis: _name, ...payload } = windowAuthAsAny.bundles

          const { nameAccountOtomatis, ...payload } = windowAuthAsAny.bundles
          const _name = nameAccountOtomatis // possibly undefined because it is optional field

          setBundles({ ...payload })
          windowAuthAsAny.bundles = undefined
        }
      }
    }
  }

  useEffect(() => {
    const checkEvent = (event: any) => {
      if (event.data.source === 'otomatis') {
        if (event.data.payload.authData) {
          const { nameAccountOtomatis: _name, ...payload } = event.data.payload
          setBundles({ ...payload })
        }
        if (event.data.payload.bundle) {
          const { nameAccountOtomatis: _name, ...payload } = event.data.payload
          setBundles({
            ...bundles,
            ...payload
          })
        }
      }
    }
    window.addEventListener('message', checkEvent)
    return () => {
      window.removeEventListener('message', checkEvent)
    }
  }, [])

  const getStatusRequest = () => {
    const result = response
    if (result) {
      if (result?.success) {
        return (
          <Space>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <p>Request Succeed</p>
          </Space>
        )
      }
      return (
        <Space>
          <CloseCircleTwoTone twoToneColor={'#9C3930'} />
          <p>Request Failed</p>
        </Space>
      )
    }
  }

  // const onRemoveAccount = () => {
  //   setBundles(undefined)
  // }

  useEffect(() => {
    if (values?.paramSchema) {
      form.setFieldsValue(convertParamsToFormValue(undefined, values?.paramSchema))
    }
  }, [values?.paramSchema])


  const onClearResponse = () => {
    setResponse(undefined)
  }

  const onCreateConnection = () => {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = '/app/create-scenario'
    a.rel = 'noopener noreferrer'
    a.click()
  }

  const onSave = async () => {
    // update or create trigger
    const newTrigger = await createOrUpdateTrigger(applicationId, { ...values, isDraft: false, versionId: values?.versionId || version?.id })

    if (newTrigger) {
      setValues(null)
      push(basePathTrigger.replace(':applicationId', applicationId).replace(':versionName', versionName))
    }
  }

  const onOpenLog = () => {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = `/developer/application/${applicationId}/${versionName}/main/trigger/${values?.id}/v/${version?.id}/logs`
    a.rel = 'noopener noreferrer'
    a.click()
  }

  return (
    <StyledTest className={isActive ? 'show' : undefined}>
      {
        values?.isAggregator && url ?
          <>
            <StyledLabel className="no-status">Webhook URL</StyledLabel>
            <div className="p-2 bg-green-100 rounded">
              Send request to this webhook URL:
              <br />
              <StyledText strong copyable>{url}</StyledText>
            </div>
            <br />
          </> :
          null
      }

      <br />
      {values?.type === 'hook' ?
        <>
          <StyledLabel className="no-status">Test your Trigger</StyledLabel>
          <StyledDescription>Try to create a scenario using the trigger. Don't forget to publish your application first</StyledDescription>
          <br />
          <StyledButton style={{ background: '#F3AD3D', color: '#FFF' }} onClick={onCreateConnection}>Create Scenario</StyledButton>
        </> :
        <Tabs defaultActiveKey="1" tabBarExtraContent={getStatusRequest()}>
          <Tabs.TabPane tab="Test Setup" key="1">
            <StyledLabel className="no-status">Test your Trigger</StyledLabel>
            <StyledDescription>Test your trigger request.</StyledDescription>
            {
              bundles ?
                <>
                  {/* <Space align="center">
                    <h5 style={{ margin: 0 }}>Sample Account Connected</h5>
                    <Button icon={<DeleteOutlined />} onClick={onRemoveAccount}>Remove Sample Account</Button>
                  </Space> */}
                  {
                    (authentication?.fieldSchema?.length || 0) > 0 ?
                      <Collapse ghost>
                        <Collapse.Panel key={'1'} header={`Detail ${bundles.user?.name || ''} Account`}>
                          <FormParamSchema
                            isDisabledAll
                            form={formAccount}
                            initialParamSchema={authentication?.fieldSchema}
                          ></FormParamSchema>
                        </Collapse.Panel>
                      </Collapse> :
                      null
                  }
                </>
                :
                null
            }
            {
              values?.authenticationId && authenticationId ?
                <SelectOrAddBundle
                  applicationId={applicationId}
                  authenticationId={authenticationId}
                  versionId={version?.id}
                  setBundles={setBundles}
                />
                : null
            }
            <Divider />
            <FormParamSchema
              isEnableAuthentication={!!values?.authenticationId}
              form={form}
              triggerId={values?.id}
              bundles={bundles}
              applicationId={parseInt(applicationId)}
              counterRefetch={counterRefetch}
              initialParamSchema={values?.paramSchema}
            >
              <StyledDescription>Run your test request{authenticationId ? ' using the account you connected' : null} to make sure that everything is working</StyledDescription>
              <Form.Item shouldUpdate>
                {
                  ({ getFieldsError }) => {
                    const isDisabledTest = getFieldsError().filter(field => field.errors.length > 0).length > 0
                    return (
                      <Space>
                        <StyledButton htmlType="button" onClick={getAllOptions} icon={<ReloadOutlined />} type="dashed">Refresh Data</StyledButton>
                        <StyledButton onClick={onClearResponse}>Clear Response</StyledButton>
                        <StyledButton type="primary" disabled={isDisabledTest} ghost onClick={onTest} loading={testingTrigger || creatingTrigger} >Test Your Trigger Request</StyledButton>
                        <StyledButton type="primary" ghost onClick={onOpenLog} disabled={isDisabledTest} loading={testingTrigger || creatingTrigger} >Open Log</StyledButton>
                      </Space>
                    )
                  }
                }
              </Form.Item>
            </FormParamSchema>
            {response ? <StyledCardResponse style={{ marginTop: 8 }}><DataResponse data={response?.data || response} /></StyledCardResponse> : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Bundle" key="3">
            {
              bundles ?
                <MonacoEditor options={{ readOnly: true }}  value={bundles ?JSON.stringify(bundles, null, 2) : undefined} />
                :
                authenticationId ? 'Connect to your account first' : 'No bundle here'
            }
          </Tabs.TabPane>
        </Tabs>
      }
      <br />
      <br/>
      <StyledFooterForm>
        <StyledButton type="primary" ghost onClick={onPrevious}>Previous</StyledButton>
        <StyledButton type="primary" onClick={onSave} loading={creatingTrigger}>{'Save & Finish'}</StyledButton>
      </StyledFooterForm>
      <br/>
    </StyledTest>
  )
}

export default Test

const StyledTest = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;

  ${StyledTag} {
    color: rgba(0, 0, 0, 0.65);
  }
`
const StyledCardResponse = styled(StyledCard)`
  .ant-card-body {
    padding: 0;
  }
`