import { LockOutlined } from '@ant-design/icons'
import { Button, Card, Col, Collapse, Form, Input, message, Row, Skeleton, Steps, Typography } from 'antd'
import Axios from 'axios'
import qrcode from 'qrcode'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { OTORISASI_API_URL } from '../../../../../helpers/Constant'
import useAuthUser from '../../../../../hooks/user/useAuthUser'
import Notification from '../../../../../util/Notification'
import PageHeader from '../../../../components/PageHeader'
import { StyledAlert } from '../../../../components/StyledComponents'

const TwoFactorAuthSetupPage: React.FC = () => {
  const history = useHistory()
  const [tfa, setTfa] = useState<any>()
  const [code, setCode] = useState<string>()
  const { authUser } = useAuthUser()
  const [verifying, setVerifying] = useState(false)

  useEffect(() => {
    Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/setup2fa`, {}, { withCredentials: true })
      .then(({ data }) => {
        qrcode.toDataURL(data.url, (err, imageUrl) => {
          if (err) {
            return message.error('Something error')
          }
          setTfa({ ...data, url: imageUrl })
        })
      })
      .catch(error => message.error(error?.response?.data?.error || 'Something error, please reload this page'))
  }, [])

  const activate = async () => {
    try {
      setVerifying(true)
      const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/activate2fa`, { code }, { withCredentials: true })
      if (!data.isValid) {
        setVerifying(false)
        return Notification.error({ message: 'Wrong code' })
      }
      Notification.success({ message: 'Success! Redirecting you to security settings page...' })
      setVerifying(false)
      return setTimeout(() => history.push('/app/settings/security'), 1500)
    } catch (error) {
      setVerifying(false)
      return Notification.error({ message: 'Wrong code' })
    }
  }

  return (
    <>
      <PageHeader
        title="2 Factor Authentication"
        extra={
          <StyledAlert message={
            <p style={{ color: '#8C8C8C' }}>Last login: {authUser?.lastLogin}</p>
          }
          showIcon type="info" />
        }
        routes={[
          {
            breadcrumbName: 'Security',
            path: 'app/settings/security'
          },
          {
            breadcrumbName: '2 Factor Authentication',
            path: ''
          }
        ]}
      />
      <StyledCard>
        <StyledSteps direction="vertical">
          <StyledStep
            status="process"
            title={<>
              <small>Step 1</small>
              <Typography.Title level={5}>Get the App</Typography.Title>
            </>}
            description={<>
              <Typography.Paragraph>
                Download and install the <a href="https://m.google.com/authenticator" target="_blank" rel="noopener noreferrer">Google Authenticator</a>, <a href="http://guide.duosecurity.com/third-party-accounts" target="_blank" rel="noopener noreferrer">Duo Mobile</a>, <a href="https://www.authy.com/" target="_blank" rel="noopener noreferrer">Authy</a>, or <a href=" https://www.windowsphone.com/en-us/store/app/authenticator/e7994dbc-2336-4950-91ba-ca22d653759b">Windows Phone Authenticator</a> app for your phone or tablet.
              </Typography.Paragraph>
            </>}
          />
          <StyledStep
            status="process"
            title={<>
              <small>Step 2</small>
              <Typography.Title level={5}>Scan this Barcode</Typography.Title>
            </>}
            description={<>
              <Typography.Paragraph>Open the authentication app and:</Typography.Paragraph>
              <ul>
                <li>Tap the "+" icon in the top-right of the app</li>
                <li>Scan the image below, using your phone's camera</li>
              </ul>
              <Typography.Paragraph style={{ textAlign: 'center' }}>
                { tfa?.url ? <img src={tfa?.url} alt="qrcode" style={{}} /> : <Skeleton.Image /> }
              </Typography.Paragraph>
              <Collapse ghost>
                <Collapse.Panel key="1" header="Can't scan this barcode?">
                  <Typography.Paragraph>
                    Instead of scanning, use your authentication app's "Manual entry" or equivalent option and provide the following time-based key. (Lower-case letters will work, too.)
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ textAlign: 'center' }}><code>{tfa?.key}</code></Typography.Paragraph>
                  <Typography.Paragraph>
                    Your app will then generate a 6-digit verification code, which you use below.
                  </Typography.Paragraph>
                </Collapse.Panel>
              </Collapse>
            </>}
          />
          <StyledStep
            status="process"
            title={<>
              <small>Step 3</small>
              <Typography.Title level={5}>Enter Verification Code</Typography.Title>
            </>}
            description={<>
              <Typography.Paragraph>
                Once the barcode above is scanned, enter the 6-digit verification code generated by the app.
              </Typography.Paragraph>
              <br/>
              <Form onFinish={activate}>
                <Row>
                  <Col flex="auto">
                    <Form.Item
                      name="code"
                      rules={[{ required: true, message: 'Code cannot be blank' }]}>
                      <Input placeholder="Code" prefix={<LockOutlined />} value={code} onChange={e => setCode(e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button loading={verifying} type="primary" htmlType="submit">Verify and Activate</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>}
          />
        </StyledSteps>
      </StyledCard>
    </>
  )
}

export default TwoFactorAuthSetupPage

const StyledCard = styled(Card)`
  margin: 0 24px;
`

const StyledSteps = styled(Steps)``

const StyledStep = styled(Steps.Step)`
  &.ant-steps-item-process > .ant-steps-item-container {
    .ant-steps-item-icon {
      background: #96C443;
      border-color: #96C443;
    }
    .ant-steps-item-content > .ant-steps-item-title {
      line-height: 1;
      small {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .ant-steps-item-tail {
      width: 2px;
      &:after {
        width: 2px;
        background: #D9D9D9;
      }
    }
  } 
`