import { Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Scenario } from 'db'
import { debounce } from 'debounce'
import { isEqual } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useAScenario from 'src/hooks/useAScenario'
import useUpdateAScenario from 'src/hooks/useUpdateAScenario'
import { StyledCard } from 'src/pages/components/StyledComponents'
import CustomForm from 'src/pages/developer/components/form/CustomForm'
import FormItem from 'src/pages/developer/components/form/FormItem'
import Notification from 'src/util/Notification'
import styled from 'styled-components'
import { updatingScenarioOrState } from '../../../scenario/edit/recoil'
import { currentStepAtom } from '../recoil/scenario'

const ScenarioInfoEditor: FC = () => {
  const currentStep = useRecoilValue(currentStepAtom)
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const { scenario } = useAScenario(scenarioId ? parseInt(scenarioId) : undefined, true)
  const { updateScenario, updatingScenario } = useUpdateAScenario()
  const [isMouseLeavingForm, setIsMouseLeavingForm] = useState<boolean>()
  const [form] = useForm()
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)

  const onMouseEnter = () => setIsMouseLeavingForm(false)
  const onMouseLeave = () => setIsMouseLeavingForm(true)

  useEffect(() => {
    if (scenario?.templateMetadata) {
      form.setFieldsValue(scenario.templateMetadata)
    }
  }, [scenario?.templateMetadata])

  const updateScenarioMetadata = useCallback((dataForm: Scenario['templateMetadata']) => {
    if (!isEqual(scenario?.templateMetadata, dataForm)) {
      updateScenario(parseInt(scenarioId), { templateMetadata: dataForm })
    }
  }, [scenarioId, scenario?.templateMetadata])

  useEffect(() => {
    if (!updatingScenario) {
      setIsUpdatingScenarioOrState(prevState => ({ ...prevState, isUpdating: false }))
    }

  }, [updatingScenarioOrState])

  useEffect(() => {
    let debounceUpdateMetadata: any

    if (isMouseLeavingForm) {
      const formValue = form.getFieldsValue()
      const errorValues = form.getFieldsError().filter(error => error.errors.length > 0)
      const errorKeys = errorValues.map(err => err.name[0])
      const validFormValues: Record<string, any> = Object.keys(formValue).reduce((res, key) => {
        if (key && !errorKeys.includes(key)) {
          return { ...res, [key]: formValue[key] }
        } return res
      }, {})
      if (errorKeys.length > 0) {
        Notification.error({
          message: 'Invalid value wont be save'
        })
      }
      if (Object.keys(validFormValues).length > 0 && !isEqual(formValue, scenario?.templateMetadata)) {
        debounceUpdateMetadata = debounce(() => updateScenarioMetadata(form.getFieldsValue()), 1000)

        debounceUpdateMetadata()
      }

      return () => {
        if (debounceUpdateMetadata) {
          debounceUpdateMetadata.clear()
        }
      }
    }
  }, [isMouseLeavingForm, scenario?.templateMetadata])

  return (
    <CustomStyledCard style={{ marginTop: '16px', display: currentStep === 1 ? 'flex' : 'none' }}>
      <CustomForm form={form} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        <FormItem
          name="description"
          label="Template Description"
          description="Explain to users how this scenario will benefits them. Include directions to build and activate the scenario."
        >
          <Input.TextArea />
        </FormItem>
        <FormItem
          name="videoUrl"
          label="Video URL"
          rules={[
            {
              type: 'url',
              message: 'This field must be a valid url.'
            }
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="blogUrl"
          label="Blog URL"
          description="Please create some explanatory tempalate in komunitas.otomatis.com"
          rules={[
            {
              type: 'url',
              message: 'This field must be a valid url.'
            }
          ]}
        >
          <Input />
        </FormItem>
      </CustomForm>
    </CustomStyledCard>
  )
}

export default ScenarioInfoEditor

const CustomStyledCard = styled(StyledCard)`
  .ant-card-body {
    width: 100%;  
  }
`