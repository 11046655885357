import { AxiosPromise } from 'axios'
import { GlobalVariable } from 'db'
import { Error } from '../../../types'
import useFetch, { useApi } from '../../useFetch'


type Result = {
  globalVariable?: GlobalVariable,
  fetchingGlobalVariable: boolean,
  errorFetchGlobalVariable?: Error
}

export default function useGlobalVariable(applicationId?: number | string, globalVariableId?: number | string, versionId?: number): Result {
  const url = globalVariableId && applicationId && versionId ? `/application/${applicationId}/globalVariable/${globalVariableId}?versionId=${versionId}` : null
  const { data, error, isValidating } = useFetch<{ globalVariable: GlobalVariable }>(url, {}, { revalidateOnFocus: false })

  return {
    globalVariable: data?.globalVariable,
    fetchingGlobalVariable: isValidating,
    errorFetchGlobalVariable: error
  }
}

interface GetGlobalVariable extends Result {
  getGlobalVariable: (params: { applicationId: number, versionId?: number, globalVariableId: number }) => AxiosPromise<{ globalVariable: GlobalVariable }>
}
export function useGetGlobalVariable(): GetGlobalVariable {
  const { call, data, isValidating, error } = useApi<{ globalVariable: GlobalVariable }>()

  const getGlobalVariable = ({ applicationId, globalVariableId, versionId }: { applicationId: number, versionId?: number, globalVariableId: number }) => {
    return call({
      method: 'get',
      url: `/application/${applicationId}/globalVariable/${globalVariableId}${versionId ? `?versionId=${versionId}` : ''}`
    })
  }
  return {
    getGlobalVariable,
    globalVariable: data?.globalVariable,
    fetchingGlobalVariable: isValidating,
    errorFetchGlobalVariable: error
  }
}