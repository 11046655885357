import { Menu, MenuItemProps } from 'antd'
import { FC } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import AnchorWrapper from '../../../AnchorWrapper'

const CustomSiderMenuItemLink: FC<{ label: string, to: string } & MenuItemProps> = ({ label, to, icon, ...menuItemProps }) => {
  const history = useHistory()

  const navigate = (to: string) => {
    if (to.startsWith('https')) {
      const a = document.createElement('a')
      a.target = '_blank'
      a.href = to
      a.rel = 'noopener noreferrer'
      a.click()
    } else {
      history.push(to)
    }
  }

  return (
    <CustomSiderMenuItemContainer {...menuItemProps} icon={icon ? <AnchorWrapper href={to}>{icon}</AnchorWrapper> : null} onClick={() => navigate(to)}>
      <AnchorWrapper style={{ color: '#FFF' }} href={to}>{label}</AnchorWrapper>
    </CustomSiderMenuItemContainer>
  )
}

export default CustomSiderMenuItemLink

const CustomSiderMenuItemContainer = styled(Menu.Item)`
  width: 100%;
  padding: 0 12px !important;
  display: flex;
  align-items: center;

  /* .ant-menu-inline-collapsed > & {
    display: none;
  } */

  .ant-menu-inline > & {
    width: 100%;
  }

  &::after {
    border-right-color: #F3AD3D !important;
  }

  &.ant-menu-item:hover, &.ant-menu-item:active {
    color: #FFF;
  }

  &.ant-menu-item-selected {
    color: #FFF;
    font-weight: 500;
    background-color: transparent !important;
    ::after {
      opacity: 1;
      transform: scaleY(1);
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  & > .ant-menu-item-icon {
    background-color: transparent;
    color: #FFF;
    padding: 4px;
    border-radius: 2px;
    .anticon {
      line-height: 0 !important;
    }
  }

  &.ant-menu-item-selected > .ant-menu-item-icon {
    background-color: #f3ad3d;
    color: #FFF;
    padding: 4px;
    border-radius: 2px;
  }

  a:hover {
    color: #FFF;
  }
`