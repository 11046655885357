import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import useApplicationByActionType from '../../../../../../../hooks/application/main/useApplicationByActionType'
import useCreateAScenarioState from '../../../../../../../hooks/scenario/state/useCreateAScenarioState'
import useUpdateAScenarioState from '../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../../hooks/useFetch'
import StateSkeleton from '../../../../scenario/edit/StateSkeleton'
import { allStatesSelector } from '../../recoil/scenario'
import { useStateValue } from '../StateValueContextProvider'

interface Props {
  stateConditionId?: number,
  onSuccessCreateNewState: () => void
}
const ChooseConditionEvent: FC<Props> = ({ stateConditionId, onSuccessCreateNewState }) => {
  const { revalidateAScenario } = useRevalidateFetch()
  const { createAScenarioState } = useCreateAScenarioState()
  const { getApplicationByActionType, actionId } = useApplicationByActionType()
  const history = useHistory()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const [stateValue] = useStateValue()
  const state = stateValue
  const { scenarioId } = state
  const states = useRecoilValue(allStatesSelector)
  const [newStateId, setNewStateId] = useState<number>()

  useEffect(() => {
    const newState = states?.find((state) => state.id === newStateId)
    if (newState) {
      onSuccessCreateNewState()
      history.replace(`/app/template-scenario/editor/${scenarioId}/${newStateId}/path`)
    }
  }, [newStateId, states])



  useEffect(() => {
    const createStates = async () => {
      // A = 65 in ascii
      const baseCharAsNumber = 64
      const nPathState = states?.filter(state => state.conditionGroupOrder).length || 0
      // create 2 path state
      const state1 = await createAScenarioState(scenarioId, {
        parentId: state.id,
        stateConditionId: stateConditionId,
        actionId: actionId,
        order: state.order + 1,
        conditionGroupOrder: state.order + 1,
        name: `Path ${String.fromCharCode(baseCharAsNumber + nPathState + 1)}`
      })
      const state2 = await createAScenarioState(scenarioId, {
        parentId: state.id,
        stateConditionId: stateConditionId,
        actionId: actionId,
        order: state.order + 1,
        conditionGroupOrder: state.order + 1,
        name: `Path ${String.fromCharCode(baseCharAsNumber + nPathState + 2)}`
      })

      if (state1 && state2) {
        // update childrenIds of parent
        updateAScenarioState(scenarioId, state.id, {
          childrenIds: [state1.data.state.id, state2.data.state.id]
        }).then(() => {
          revalidateAScenario(scenarioId)
          setNewStateId(state1.data.state.id)
        }).finally(() => {
        })
      }
      onSuccessCreateNewState()

    }
    if (actionId) {
      createStates()
    } else {
      // console.log('tes')
      getApplicationByActionType('path')
    }
  }, [actionId])

  return (
    <StateSkeleton />
  )
}

export default ChooseConditionEvent