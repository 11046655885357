import { EllipsisOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Modal, Space, Tooltip } from 'antd'
import { Scenario } from 'db'
import React, { FC, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { useLoggedInUser } from '../../../../../contexts/LoggedInUserContextProvider'
import useStatusPayment from '../../../../../hooks/payment/useStatusPayment'
import useDuplicateAScenario from '../../../../../hooks/scenario/useDuplicateAScenario'
import useArchiveAScenario from '../../../../../hooks/useArchiveAScenario'
import { danger } from '../../../../../util/colors'
import { tableStateScenarioTemplates } from '../editor/recoil/scenario'

const TemplateScenarioActions: FC<{ scenario: Scenario, onSuccessArchivedScenario?: () => void }> = ({ scenario, onSuccessArchivedScenario }) => {
  const tableState = useRecoilValue(tableStateScenarioTemplates)
  const { archiveScenario, archivingScenario, isSuccessArchivingScenario } = useArchiveAScenario(tableState)
  const { isDuplicatingScenario } = useDuplicateAScenario(tableState)
  const { maxScenario, maxTask, totalUsage, scenariosCount, status } = useStatusPayment()
  const [_errorPayment, setErrorPayment] = useState<string>()
  const [loggedInUser] = useLoggedInUser()

  const isActionsDisabled = archivingScenario || isDuplicatingScenario

  useEffect(() => {
    if (isSuccessArchivingScenario && onSuccessArchivedScenario) {
      onSuccessArchivedScenario()
    }
  }, [isSuccessArchivingScenario])

  useEffect(() => {
    if (maxScenario && scenariosCount && maxScenario !== 0 && scenariosCount >= maxScenario) {
      setErrorPayment('You already hit your scenario limit')
    } else if (maxTask && totalUsage && totalUsage >= maxTask) {
      setErrorPayment(undefined)
    // } else if (status === 'past_due') {
    //   setErrorPayment('your scenarios are stopped until you complete your payment')
    // } else if (status === 'unpaid') {
    //   setErrorPayment('your scenarios are stopped until you complete your payment')
    } else {
      setErrorPayment(undefined)
    }
  }, [maxScenario, maxTask, totalUsage, scenariosCount, status])

  const onClickArchive = () => {
    Modal.confirm({
      title: `Are you sure you want to archive "${scenario.name}" scenario?`,
      content: 'You can\'t access your scenario again. This action cannot be undone.',
      onOk() {
        archiveScenario(scenario.id)
      }
    })
  }

  const disabled = scenario.active || scenario.userId !== loggedInUser?.id
  const title = scenario.active ? 'You can\'t archive this scenario, please stop the scenario first to archive this scenario' :
    scenario.userId !== loggedInUser?.id ? 'You can\'t archive this scenario, because its not yours' : ''
  return (
    <Space>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key={0} onClick={onClickArchive} disabled={disabled} style={{ color: danger }}>
              <Tooltip title={title}>
                Archive
              </Tooltip>
            </Menu.Item>
            {/* <Menu.Item key={2} onClick={() => duplicateAScenario(scenario.id)}>Duplicate</Menu.Item> */}
          </Menu>
        }
        trigger={['click']}
        placement='bottomRight'
        disabled={isActionsDisabled}
      >
        <ScenarioMoreActionsButton loading={isActionsDisabled} disabled={isActionsDisabled} type='text' icon={
          <EllipsisOutlined style={{ color: 'rgba(0, 0, 0, .45)', verticalAlign: 'middle', textAlign: 'center' }} />
        } />
      </Dropdown>
    </Space>
  )

}

export default TemplateScenarioActions

const ScenarioMoreActionsButton = styled(Button)`
  border: 0;

  &:hover {
    background-color: rgba(0, 0, 0, .06);
  }
`