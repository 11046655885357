import { Card, Col, Row, Table, Typography } from 'antd'
import { FC } from 'react'
import { StyledDescription } from 'src/pages/components/StyledComponents'

interface Props {
  parsedData: { [key: string]: any }[] | undefined,
  dataHeader: string[] | undefined
}
const Step2Check: FC<Props> = ({ parsedData, dataHeader }) => {

  if (!parsedData && !dataHeader) return null
  return (
    <>
      <Card>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5}>Total Rows</Typography.Title>
            <Typography.Text>{(parsedData?.length || 0)}</Typography.Text>
          </Col>
          <Col>
            <Typography.Title level={5}>Header Detected</Typography.Title>
            <Typography.Text>{dataHeader?.join(', ')}</Typography.Text>
          </Col>
        </Row>
      </Card>
      <br />
      <Card>
        <Table
          columns={dataHeader?.map((title) => {
            return {
              title,
              dataIndex: title
            }
          })}
          pagination={false}
          dataSource={parsedData?.filter((_, idx) => idx < 10)}
          scroll={{ x: true }}
        />
        <StyledDescription className="text-center mt-2">Sample of first ten data.</StyledDescription>
      </Card>
    </>
  )
}

export default Step2Check