import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import useVersionName from 'src/hooks/application/version/useVersionName'
import DataSuggestionContextProvider from '../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useAction from '../../../../../../hooks/application/action/useAction'
import ActionSteps from '../components/ActionStep'
import { useSharedValueAction } from '../components/SharedValueActionContextProvider'

const UpdateAction: FC = () => {
  const { versionName, actionId, applicationId } = useParams<{ versionName: string, applicationId: string, actionId: string }>()
  const { versionId } = useVersionName(applicationId, versionName)
  const { action } = useAction(applicationId, actionId, versionId)
  const [_, setValues] = useSharedValueAction()

  useEffect(() => {
    if (action) {
      setValues(action)
    }
  }, [action])

  return (
    <DataSuggestionContextProvider>
      <ActionSteps type="update" />
    </DataSuggestionContextProvider>
  )
}

export default UpdateAction