import { Col, Row, Space, Tooltip } from 'antd'
import { Application, Scenario } from 'db'
import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { useLoggedInUser } from '../../../../../contexts/LoggedInUserContextProvider'
import useStatusPayment from '../../../../../hooks/payment/useStatusPayment'
import useCreateScenarioFromTemplate from '../../../../../hooks/scenario/template/useCreateScenarioFromTemplate'
import useUpdateAScenario from '../../../../../hooks/useUpdateAScenario'
import { StyledButton, StyledCard as BaseCard, StyledP2Description, StyledPMedium, StyledSwitch } from '../../../../../pages/components/StyledComponents'
import { openNewTabOnClick } from '../../../../../util/functions'
import AvatarApplicationGroups from '../../AvatarApplicationGroups'
import { tableStateScenarioTemplates } from '../editor/recoil/scenario'
import TemplateScenarioActions from './TemplateScenarioActions'

interface Props {
  scenario: Scenario
}

const TemplateScenarioItem: FC<Props> = ({ scenario }) => {
  const [loggedInUser] = useLoggedInUser()
  const { plan } = useStatusPayment()
  const { id, states } = scenario
  const applications: (Application | undefined)[] | undefined = states?.map(state => state.trigger?.application || state.action?.application)
  const { creatingScenarioFromTemplate } = useCreateScenarioFromTemplate()
  const tableState = useRecoilValue(tableStateScenarioTemplates)
  const { updateScenario, updatingScenario } = useUpdateAScenario(tableState)

  const onClickEditScenarioTemplate = () => {
    openNewTabOnClick(`/app/template-scenario/editor/${id}`)
  }

  const onClickCreateScenarioFromTemplate = () => {
    // createScenarioFromTemplate(id).then((scenario) => {
    //   if (scenario) {
    //     openNewTabOnClick(`/app/scenario/${scenario.id}/edit`)
    //   }

    // })
    openNewTabOnClick(`/app/template-scenario/${id}`)
  }

  const toggleScenario = () => {
    updateScenario(scenario.id, {
      published: !scenario.published
    })
  }

  const disabled =  !plan?.isUsingPremiumApps && applications?.filter(app => app?.isPremiumApp).length !== 0
  const title = disabled ? `Upgrade to Professional to use ${applications?.filter(app => app?.isPremiumApp).map(app => app?.name).join(', ')} premium app on this template` : ''
  const titleEdit = loggedInUser?.id !== scenario.userId ? 'This template is not yours' : title
  return (
    <StyledCard style={{ padding: 0, marginBottom: 16, width: '100%' }}>
      <Row gutter={16} justify="space-between" align="middle" >
        <Col span={3}>
          <div className="wrapper-icons">
            <AvatarApplicationGroups applications={applications} />
          </div>
        </Col>
        <Col flex="auto" >
          <StyledPMedium>
            {scenario.name}
          </StyledPMedium>
          <StyledP2Description>
            {applications?.map(application => application?.name).join(' + ')}
          </StyledP2Description>
        </Col>
        <Col flex="0">
          <Space>
            {
              loggedInUser?.isAdmin && scenario.userId === loggedInUser.id?
                <>
                  <Tooltip title={titleEdit}>
                    <StyledButton ghost type="primary" disabled={disabled} onClick={onClickEditScenarioTemplate} >Edit</StyledButton>
                  </Tooltip>
                </> :
                null
            }
            <Tooltip title={title}>
              <StyledButton loading={creatingScenarioFromTemplate} disabled={disabled} onClick={onClickCreateScenarioFromTemplate}>Try This</StyledButton>
            </Tooltip>
          </Space>
        </Col>
        {
          loggedInUser?.isAdmin ?
            <>
              <Col>
                <StyledSwitch
                  checkedChildren={<div style={{ width: '24px' }}>ON</div>}
                  unCheckedChildren={<div style={{ width: '24px' }}>OFF</div>}
                  checked={scenario.published}
                  onChange={toggleScenario}
                  loading={updatingScenario}
                  disabled={updatingScenario}
                />
              </Col>
              <Col>
                <TemplateScenarioActions scenario={scenario} />
              </Col>
            </>:
            null
        }

      </Row>
    </StyledCard>
  )
}

const StyledCard = styled(BaseCard)`
  .ant-card-body {
    padding: 12px;
  }
`

export default TemplateScenarioItem