import { Card, Col, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { useThisStateStaticValue } from '../../../../scenario/edit/ThisStateStaticValueContext'
import { stateEventAtom, stateNameAtom } from '../../recoil/state'
import { useStateValue } from '../StateValueContextProvider'
import FormStateEvent, { EventState } from './FormStateEvent'

export interface EditStateEventProps {
  className?: string,
  isAdd?: boolean,
  submitting?: boolean,
  onCancel?: () => void,
  onChangeEventId?: (eventId?: number, name?: string) => Promise<void> | undefined
}

const EditStateEvent: FC<EditStateEventProps> = ({ className, isAdd, onCancel, onChangeEventId, submitting }) => {
  const [stateValue] = useStateValue()
  const { id, type, actionId, triggerId, application } = stateValue || {}
  const applicationId = application?.id
  const [form] = useForm()
  const [event, setEvent] = useRecoilState(stateEventAtom(id))
  const setName = useSetRecoilState(stateNameAtom(id))
  const [, setStateStatic] = useThisStateStaticValue()

  useEffect(() => {
    form.setFieldsValue({
      applicationId:  event?.applicationId || applicationId,
      eventId: isAdd ? null : event?.id || triggerId || actionId
    })
  }, [triggerId, applicationId, actionId, isAdd, event])

  const onFinish = (values: any, event?: EventState) => {
    if (values.eventId && onChangeEventId && event) {
      // console.log((event.trigger || event.action)?.application?.logoUrl)
      setName((event.trigger || event.action)?.name)
      onChangeEventId(values.eventId, values.name)?.then(() => {
        setEvent(event.trigger || event.action)
      })

      setStateStatic(prevState => prevState ?{
        ...prevState,
        triggerActionName: (event.trigger || event.action)?.name || '',
        application: (event.trigger || event.action)?.application,
        paramSchema: (event.trigger || event.action)?.paramSchema || [],
      } : null)
    }
  }

  return (
    <StyledCardEditStateEvent className={className}>
      <Row>
        <Col flex="auto">
          <FormStateEvent
            form={form}
            buttonCancelProps={{
              onClick: onCancel,
              disabled: isAdd && !triggerId && !actionId
            }}
            buttonSaveProps={{
              loading: submitting
            }}
            onFinish={onFinish}
            title={`${isAdd ? 'Add' : 'Edit'} ${type === 'action' ? 'Action' : 'Trigger'}`}
            type={type || 'action'}
            actionId={actionId}
            applicationId={event?.applicationId || applicationId}
            triggerId={triggerId}
          />
        </Col>
      </Row>
    </StyledCardEditStateEvent>
  )
}

export default EditStateEvent

const StyledCardEditStateEvent = styled(Card)`
  width: 100%;
  margin-top: 12px;
  .ant-skeleton-element {
    width: 100%;
  }
`