import { Card, Divider, Input, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import qs from 'query-string'
import { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import useAuthentication from '../../../../../hooks/application/authentication/useAuthentication'
import useAuthenticationAuthUrl from '../../../../../hooks/application/authentication/useAuthenticationAuthUrl'
import useAuthenticationTest from '../../../../../hooks/application/authentication/useAuthenticationTest'
import useApplication from '../../../../../hooks/application/main/useApplication'
import useAuthenticationSchema from '../../../../../hooks/authenticationSchema/useAuthenticationSchema'
import { IMPLIKASI_URL } from '../../../../../util/Constant'
import { getRedirectOAuthUrl } from '../../../../../util/formatter'
import Notification from '../../../../../util/Notification'
import { StyledAlert, StyledButton, StyledFooterForm } from '../../../../components/StyledComponents'
import CustomForm from '../../../components/form/CustomForm'
import FormItem from '../../../components/form/FormItem'

const TestAuth: FC = () => {
  const [form] = useForm()
  const { applicationId, authenticationId } = useParams<{ applicationId: string, authenticationId: string }>()
  const { location } = useHistory()
  const params = qs.parse(location.search)
  const { authentication } = useAuthentication(applicationId, authenticationId, Number(params?.versionId))
  const { authenticationSchema } = useAuthenticationSchema(authentication?.authenticationSchemaId)
  const { application } = useApplication(applicationId)
  const { authUrlAUthentication, result } = useAuthenticationAuthUrl(applicationId, authenticationId)
  const { testAuthentication, testingAuthentication, errorTestAuthentication } = useAuthenticationTest(applicationId, authenticationId, params?.versionId as string)
  const isUseAuthUrl = authenticationSchema?.fields.find(field => field.name === 'authHttpRequestId')?.required
  const isUseAuthData = authentication?.fieldSchema && authentication.fieldSchema.length > 0
  const info = authentication?.helpText

  useEffect(() => {
    if (params?.editableName) {
      form.setFieldsValue({
        nameAccountOtomatis: params.editableName
      })
    }
  }, [params?.editableName])

  useEffect(() => {
    if (isUseAuthUrl && authentication && !isUseAuthData) {
      const stateUuid = (window as any).stateUuid
      authUrlAUthentication({
        uuid: stateUuid || 'test',
        bundle: {
          redirectUrl: getRedirectOAuthUrl(applicationId, authentication?.uuid)
        }
      })
    }
  }, [authenticationSchema, authentication])

  const onClose = () => {
    window.close()
  }

  const onFinish = async (valuesForm: any) => {
    const { nameAccountOtomatis, ...values } = valuesForm
    if (isUseAuthUrl && result && !isUseAuthData) {
      // window.open(result?.url, '_parent')
      window.location.href = result.url
    } else if (isUseAuthData && isUseAuthUrl && authentication) {
      const stateUuid = (window as any).stateUuid
      const authUrl = await authUrlAUthentication({
        uuid: stateUuid || 'test',
        bundle: {
          redirectUrl: getRedirectOAuthUrl(applicationId, authentication?.uuid)
        },
        authData: values
      })
      if (authUrl) {
        window.opener?.postMessage({ source: 'otomatis', payload: { authData: values, nameAccountOtomatis } }, IMPLIKASI_URL)
        window.location.href = authUrl.url
      }
    } else {
      const test = await testAuthentication({
        authData: values
      })
      if (test.success) {
        const windowAsAny = window as any
        windowAsAny.bundles = {
          nameAccountOtomatis,
          authData: values
        }
        windowAsAny.close()
      } else {
        Notification.error({
          message: 'Failed on test authentication',
          description: JSON.stringify(test.error?.message || test.error)
        })
      }
    }
    (window as any).stateUuid = undefined
  }

  useEffect(() => {
    const fieldSchemaWithDefaultOrConstantValues = authentication?.fieldSchema?.filter(field => field.default) || []
    if (fieldSchemaWithDefaultOrConstantValues.length > 0) {
      const objectFieldSchemaWithDefaultOrConstantValues = fieldSchemaWithDefaultOrConstantValues.reduce((res, field) => {
        return {
          ...res,
          [field.key]: field.default
        }
      }, {})
      form.setFieldsValue(objectFieldSchemaWithDefaultOrConstantValues)
    }
  }, [authentication?.fieldSchema])

  return (
    <StyledTestAuth>
      <Card>
        <h5>Allow Otomatis to access your {application?.name} Account?</h5>
        {info ? <><StyledAlert message={<div dangerouslySetInnerHTML={{ __html: info }} />} type="info" /><br/></> : null}
        <CustomForm onFinish={onFinish} form={form}>
          {
            authentication?.fieldSchema?.map(field => {
              const { allowEditByUser, key, label, required, type, helpText, options, constantValue, getValueFunc } = field

              if (getValueFunc && !allowEditByUser) {
                return null
              }

              if (constantValue) {
                return null
              }

              return (
                <FormItem hidden={!!constantValue} key={key} name={key} label={label} rules={[{ required: required }]} description={helpText}>
                  {
                    options ?
                      <Select options={options?.map((opt: any) => ({ value: opt.key || opt, label: opt.label || opt }))} disabled={!!constantValue} /> :
                      type === 'string' ?
                        <Input disabled={!!constantValue} /> :
                        type === 'password' ?
                          <Input.Password disabled={!!constantValue} />: null
                  }
                </FormItem>
              )
            })
          }
          <Divider />
          <FormItem name="nameAccountOtomatis" label="Account Name" description="You can rename the account name later on 'Connected Account' page">
            <Input />
          </FormItem>
          <StyledFooterForm>
            <StyledButton htmlType="submit" type="primary" disabled={!authentication && isUseAuthUrl && !result} loading={testingAuthentication}>Yes, continue</StyledButton>
            <StyledButton htmlType="button" onClick={onClose}>Cancel</StyledButton>
          </StyledFooterForm>
        </CustomForm>
        <br /> <br />
        {errorTestAuthentication ?
          <div><StyledAlert type="error" message={`${errorTestAuthentication}, please ensure your inputs are true`} /> </div> : null
        }
      </Card>
    </StyledTestAuth>
  )
}

export default TestAuth

const StyledTestAuth = styled.div`
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  display: block;
  margin: auto;
  max-width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
`