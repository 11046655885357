import { CodeOutlined } from '@ant-design/icons'
import { Card, Col, Empty, Row, Skeleton } from 'antd'
import { flatten } from 'flat'
import { FC, ReactNode, useEffect, useState } from 'react'
import { withResizeDetector } from 'react-resize-detector'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'
import { StyledBoxIcon, StyledButton, StyledInput, StyledTag as BaseTag } from '../../../../../pages/components/StyledComponents'
import MonacoEditor from '../../../../../pages/developer/components/form/MonacoEditor'
import { orange, primary65 } from '../../../../../util/colors'
import truncateLongData from '../../../../../util/truncateLongData'
import InfiniteLoader from 'react-window-infinite-loader'
import { compareString } from '../../../../../util/functions'

interface Props {
  actions?: ReactNode,
  width?: any,
  data?: any,
  logoUrl?: string,
  number?: number
}

const DataResponse: FC<Props> = ({ actions, data, logoUrl, number, width }) => {
  const [arrayData, setArrayData] = useState<{ key: string, value: string }[]>()
  const [filteredData, setFilteredData] = useState<{ key: string, value: string }[]>()
  const [isCodeMode, setIsCodeMode] = useState(false)
  const LOADING = 1
  const LOADED = 2
  const [itemStatusMap, setItemStatusMap] = useState<Record<number, number>>({})

  const isItemLoaded = index => !!itemStatusMap[index]
  const loadMoreItems = (startIndex, stopIndex) => {
    for (let index = startIndex; index <= stopIndex; index++) {
      setItemStatusMap(prevState => ({ ...prevState, [index]: LOADING }))
    }
    return new Promise(resolve =>
      setTimeout(() => {
        for (let index = startIndex; index <= stopIndex; index++) {
          setItemStatusMap(prevState => ({ ...prevState, [index]: LOADED }))
        }
        resolve(true)
      }, 500)
    )
  }

  const toggleCodeMode = () => {
    setIsCodeMode(!isCodeMode)
  }

  useEffect(() => {
    if (data) {
      const flatUnsafe: any = flatten(data)
      // // const flatSafe: any = flatten(data, { safe: true })

      const helper = (data: any, prevKey: string) => {
        if (!data) return
        return Object.keys(data).reduce((res, key) => {
          if (typeof data[key] === 'object') {
            return {
              ...res,
              ...helper(data[key], `${prevKey}.${key}`),
              [`${prevKey}.${key}`]: data[key]
            }
          }
          return res
        }, {})
      }
      const flatManual = Object.keys(data).reduce((res, key) => {
        if (typeof data[key] === 'object') {
          return {
            ...res,
            ...helper(data[key], key),
            [key]: data[key]
          }
        }
        return res
      }, {})

      const flat = { ...flatManual, ...flatUnsafe }
      setArrayData(Object.keys(flat).map(key => typeof flat[key] === 'object' ? Array.isArray(flat[key]) ? { key: `${key} (array)`, value: truncateLongData(flat[key]) } :  { key: `${key} (object)`, value: truncateLongData(flat[key]) } : { key: key, value: truncateLongData(flat[key], 60) }))
    }
  }, [data])

  useEffect(() => {
    onSearchData()
  }, [arrayData])

  const onSearchData = (event?: any) => {
    const value = event?.target.value
    if (value) {
      setFilteredData(arrayData?.filter(data => data.key.toLowerCase().includes(value.toLowerCase()) || data.value.toLowerCase().includes(value.toLowerCase())).sort((td1, td2) => {
        return compareString(td1.key, td2.key)
      }))
    } else {
      setFilteredData(arrayData?.sort((td1, td2) => {
        return compareString(td1.key, td2.key)
      }))
    }
  }

  const RowData = ({ style, index }) => {
    const data = filteredData?.[index]
    if (!data) {
      return null
    }
    let label
    if (itemStatusMap[index] === LOADED) {
      label = String(data.value).replace(/\n/g, ' ')
    } else {
      label = 'Loading...'
    }
    return(
      <StyledTag key={data.key} style={{ ...style, height: 30, width: 'fit-content', overflow: 'auto' }}>
        <CustomStyledBoxIcon style={{ width: 21, height: 21 }}>
          {logoUrl ? <img src={logoUrl} alt="" width="21px" /> : <Skeleton.Image style={{ width: '100%' }} />}
        </CustomStyledBoxIcon>
        <strong>{number ? `${number}.` : ''}<span dangerouslySetInnerHTML={{ __html: data.key.replace(/\(array\)|\(object\b\)/g, (v) => `<span style="color: ${orange[5]}" >${v}</span>`)
        }}></span>:</strong>
        {label}
      </StyledTag>
    )
  }


  return (
    <>
      <Row gutter={[16, 16]} justify="end">
        {
          isCodeMode ?
            null :
            <Col flex="auto">
              <StyledInput.Search placeholder="Search Data" allowClear onChange={onSearchData} />
            </Col>
        }
        {actions}
        <Col flex="0" >
          <StyledButton className="float-right" onClick={toggleCodeMode} icon={<CodeOutlined />}>{isCodeMode ? 'Switch to JSON viewer' : 'Switch to raw JSON'}</StyledButton>
        </Col>
      </Row>
      {
        isCodeMode ?
          <>
            <br />
            <MonacoEditor options={{ readOnly: true }}  value={data ? JSON.stringify(data, null, 2) : ''} />
          </>
          :
          filteredData?.length !== 0 ?
            <>
              <br />
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={filteredData?.length || 0}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) =>
                  <List
                    height={200}
                    width={width ? width - 20 : 300}
                    itemSize={35}
                    itemCount={filteredData?.length || 0}
                    itemData={filteredData}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                  >
                    {RowData}
                  </List>
                }
              </InfiniteLoader>
            </> :
            <Empty description="No Data" />
      }
    </>
  )
}

export default withResizeDetector(DataResponse)

const StyledTag = styled(BaseTag)`
  display: grid;
  width: max-content;
  grid-template-columns: repeat(3, max-content);
  column-gap: 5px;
  padding: 2px 4px;
  align-items: center;
  color: ${primary65};
  font-family: 'DM Sans', sans-serif;
  ${StyledBoxIcon} {
    img {
      width: 21px;
      height: 21px;
    }
  }
`
const CustomStyledBoxIcon = styled(StyledBoxIcon)`
  .ant-skeleton-element {
    width: 100%;
    height: 100%;
    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
`

export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 12px;
  }
`