import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import { FC, useEffect, useRef } from 'react'

export type TypeformProps = {
  formId: string,
  onSubmit?: (payload: {responseId: string }) => void
}

const Typeform: FC<TypeformProps> = ({ formId, onSubmit }) => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (container.current) {
      createWidget(formId, {
        container: container.current,
        hideFooter: true,
        hideHeaders: true,
        onSubmit
      })
    }
  }, [container])

  return <div style={{ height: '100%' }} ref={container} />
}

export default Typeform