import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Row, Space, Spin, Tooltip } from 'antd'
import { Action, Application, State, Trigger } from 'db'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import DefaultPNG from '../../../../../../assets/DefaultPNG'
import useApplicationsWithTriggerAndActionByIds from '../../../../../../hooks/application/main/useApplicationsWIthTriggerAndActionByIds'
import { StyledInput, StyledParagraph, StyledTagLabel } from '../../../../../../pages/components/StyledComponents'
import { red } from '../../../../../../util/colors'
import { compareString } from '../../../../../../util/functions'
import { useSharedValueScenario } from '../contexts/SharedValueScenarioContextProvider'

interface Props {
  type: 'do' | 'when',
  selectedApplications: Application[],
  selectedTrigger: Trigger | undefined,
  selectedActions: Action[],
  onClickTrigger?: (selectedTrigger: Trigger) => void,
  onClickAction?: (selectedAction: Action) => void
}

type OptionForm = (Partial<Trigger | Action>)

const SearchWhenOrDoForm: FC<Props> = ({ type, selectedApplications }) => {
  const [values, setValues] = useSharedValueScenario()
  const { applications, fetchingApplications } = useApplicationsWithTriggerAndActionByIds(selectedApplications.map(application => Number(application?.id)))
  const [searchValue, setSearchValue] = useState<string>()
  const [filteredOptions, setFilteredOptions] = useState<OptionForm[]>()

  const onClickItem = (opt: OptionForm) => {
    let currStates: (Partial<State> | null)[] = values?.states || [null]
    if (type === 'when') {
      currStates = currStates.length > 0 ? currStates.map((state, idx) => {
        if (idx === 0) {
          return { ...state, trigger: opt as Trigger, triggerId: opt.id }
        }
        return state
      }) : [{ trigger: opt as Trigger, triggerId: opt.id }]
    } else {
      currStates = [...currStates || null, { action: opt as Action, actionId: opt.id }]
    }
    setValues(prevState => ({ ...prevState, states: currStates as State[] }))
  }

  // useEffect(() => {
  //   const selected = selectedApplications.filter(application => application?.id).map(application => Number(application?.id))
  //   setSelectedApplicationIds(selected)
  // }, [selectedApplications])

  const getOptions: (type: 'do' | 'when') => OptionForm[] = (type: 'do' | 'when') => {
    if (type === 'when') {
      type Options = OptionForm[]
      const triggers: Options = applications?.reduce((result: Options, application) => {
        const versionId = application.versions ? application.versions[0]?.id : undefined
        const currTriggers = application.triggers
        if (currTriggers) {
          const triggersWithLogo: Options = currTriggers.filter(trigger => trigger.versionId === versionId && trigger.visibility === 'visible').map(trigger => ({ ...trigger, application: application }))
          return [...result, ...triggersWithLogo]
        }
        return result
      }, []) || []

      return triggers
    }

    type Options = OptionForm[]
    const actions: Options = applications?.reduce((result: Options, application) => {
      const versionId = application.versions ? application.versions[0]?.id : undefined
      const currActions = application.actions
      if (currActions) {
        const actionsWithLogo: Options = currActions.filter(action => action.versionId === versionId && !action.isDraft).map(action => ({ ...action, application: application }))
        return [...result, ...actionsWithLogo]
      }
      return result
    }, []) || []

    return actions
  }

  const onSearch = (e: any) => {
    setSearchValue(e.target.value)
  }

  const onRemove = (e: any, idx: number) => {
    e.stopPropagation()
    e.preventDefault()
    const newStates = values?.states?.filter((_, currIdx) => currIdx !== idx)
    setValues(prevState => ({ ...prevState, states: newStates }))
  }

  useEffect(() => {
    if (applications) {
      const tempOpts: OptionForm[] = getOptions(type).sort((opt1, opt2) => {
        if (opt1.applicationId === opt2.applicationId && opt1?.name && opt2?.name) {
          return compareString(opt1.name, opt2.name)
        }
        return 0
      })
      if (searchValue) {
        setFilteredOptions(tempOpts.filter(opt => opt.name?.toLowerCase().includes(searchValue.toLowerCase())))
      } else {
        setFilteredOptions(tempOpts)
      }
    }
  }, [searchValue, applications])


  const data = {
    do: {
      title: 'Do',
    },
    when: {
      title: 'When'
    }
  }

  return (
    <StyledWhenOrDoForm type={type}>
      <div className="searchbox">
        <h5>{data[type].title}</h5>
        <StyledInput suffix={<SearchOutlined />} placeholder={type === 'do' ? 'This action' : 'This happens'} onChange={onSearch} />
      </div>
      <Spin spinning={fetchingApplications}>
        <div className="optionsbox">
          {
            filteredOptions?.map((option: OptionForm) => {
              const { name, id, application, description } = option
              const activeIdxs = type === 'do' ? values?.states?.map((state, idx) => state?.actionId === id ? idx : null) : undefined
              const activeIdx = type === 'do' ? values?.states?.findIndex(state => state?.actionId === id) : undefined
              const isActive = type === 'when' ? !!values?.states?.find(state => state?.triggerId === id) : !!values?.states?.find(state => state?.actionId === id)

              return (
                <Tooltip overlayClassName="custom-tooltip" key={id} placement="bottom" title={description} overlayInnerStyle={{ color: '#000' }} color="white">
                  <Row style={{ marginBottom: 0 }} onClick={() => onClickItem(option)} key={id} title={`${application?.name} - ${name}`} gutter={[16, 16]} className={`option-item ${isActive ? 'active' : ''}`} align="middle">
                    <Col flex={0}>
                      <TriggerActionAppIcon background={application?.logoUrl || DefaultPNG} />
                    </Col>
                    <Col flex="auto">
                      <StyledParagraph>{name}</StyledParagraph>
                    </Col>
                    {
                      option.type === 'hook' ?
                        <Col flex={0}>
                          <StyledTagLabel color="success">real time</StyledTagLabel>
                        </Col> :
                        null
                    }
                    {
                      activeIdx && activeIdx > 0 ?
                        <Col flex={0}>
                          <Space style={{ maxWidth: 90, overflowX: 'auto', overflowY: 'hidden' }}>
                            {
                              activeIdxs?.map(number => number ?
                                <StyledParagraph onClick={(e) => onRemove(e, number)} key={number} className="active-number">
                                  {number}
                                  <DeleteOutlined className="delete-icon" />
                                </StyledParagraph> : null)
                            }
                          </Space>
                        </Col>: null
                    }
                  </Row>
                </Tooltip>
              )
            })
          }
        </div>
      </Spin>
    </StyledWhenOrDoForm>
  )
}

export default SearchWhenOrDoForm

export const TriggerActionAppIcon = styled.div<{ background?: string }>`
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;

  background-image: url('${props => props.background}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
`

export const StyledWhenOrDoForm = styled.div<{ type: 'when' | 'do' }>`
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 315px;
  overflow-y: hidden;
  
  .searchbox {
    padding: 16px;
    background: ${props => props.type === 'when' ? '#F3AD3D' : '#96C443'};
    h5 {
      color: #FFF;
    }
    ${StyledInput} {
      background: ${props => props.type === 'when' ? 'rgba(186, 139, 66, 0.4)' : 'rgba(116, 141, 61, 0.4)'};
      * {
        color: #FFF;
      }
      border: none;
      outline: none;
      &:focus {
        box-shadow: none;
      }
      input {
        background: ${props => props.type === 'when' ? 'rgba(186, 139, 66, 0)' : 'rgba(116, 141, 61, 0)'};
        color: #FFF;
        ::-webkit-input-placeholder { /* Edge */
          color: #FFF;
          opacity: 0.8;
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #FFF;
          opacity: 0.8;
        }

        ::placeholder {
          color: #FFF;
          opacity: 0.8;
        }
      }
    }
  }

  .optionsbox {
    padding: 16px;
    .option-item {
      cursor: pointer;
      padding-top: 3px;
      padding-bottom: 3px;
      margin-bottom: 10px;
      border-radius: 2px;
      ${StyledParagraph} {
        color: rgba(0, 0, 0, 0.65);
      }

      .active-number {
        font-size: 12px;
        color: ${'#F3AD3D'};
        background: #FFFFFF;
        border: 1px solid #96C443;
        box-sizing: border-box;
        border-radius: 100%;
        width: 19px;
        height: 19px;
        display: block;
        text-align: center;
        line-height: 19px;
        position: relative;
        &:hover {
          border-color: ${red[4]};
          .delete-icon {
            display: block;
          }
        }
        .delete-icon {
          display: none;
          color: ${red[4]};
          background: #FFF;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        background: ${props => props.type === 'when' ? '#F9EBCB' : '#ECF6D2'};

        ${TriggerActionAppIcon} {
          border: 1px solid ${props => props.type === 'when' ? '#F3AD3D' : '#96C443'};
        }
      }

      &.active {
        background: ${props => props.type === 'when' ? '#F9EBCB' : '#ECF6D2'};
        
        ${TriggerActionAppIcon} {
          border: 1px solid ${props => props.type === 'when' ? '#F3AD3D' : '#96C443'};
        }
      }
    }
    overflow-y: scroll;
    box-sizing: border-box;
    height: 205px;
  }
`