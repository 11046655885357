import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, ModalFuncProps } from 'antd'
import { red } from './colors'


class CustomModalMethod {
  private constructor() {}

  public static dangerConfirm(config: ModalFuncProps): void {
    const { okButtonProps, ...otherConfigs } = config
    Modal.confirm({
      className: 'base-custom',
      okButtonProps: {
        danger: true,
        ...okButtonProps
      },
      icon: <InfoCircleOutlined style={{ color: red[4] }} />,
      ...otherConfigs
    })
  }

  public static confirm(config: ModalFuncProps): void {
    const { ...otherConfigs } = config
    Modal.confirm({
      className: 'base-custom',
      ...otherConfigs
    })
  }
}

export default CustomModalMethod