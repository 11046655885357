import { Scenario } from 'db'
import { useState } from 'react'
import { Implikasi } from '../service/Implikasi'
import { Error, TableState } from '../types'
import Notification from '../util/Notification'
import { useRevalidateFetch } from './useFetch'

type Result = {
  disableScenario: (scenarioId: number) => Promise<void>,
  responseDisablingScenario?: Scenario,
  isSuccessDisablingScenario: boolean,
  disablingScenario: boolean,
  errorDisableScenario?: Error
}

export default function useDisableAScenario(tableState?: TableState<Scenario>): Result {
  const { revalidateAScenario, revalidateScenarios } = useRevalidateFetch()
  const [responseDisablingScenario, setResponseDisablingScenario] = useState()
  const [disablingScenario, setDisablingScenario] = useState<boolean>(false)
  const [errorDisableScenario, setErrorDisableScenario] = useState<Error>()
  const [isSuccessDisablingScenario, setIsSuccessDisablingScenario] = useState<boolean>(false)

  const disableScenario = async (scenarioId: number) => {
    if (scenarioId) {
      try {
        setDisablingScenario(true)
        const disableScenario = await Implikasi.post(`/scenario/${scenarioId}/stop`, {})

        if (disableScenario.status === 202) {
          setIsSuccessDisablingScenario(true)
        } else {
          setIsSuccessDisablingScenario(false)
        }

        // revalidate any component that used Scenario model
        revalidateScenarios(tableState)
        revalidateAScenario(scenarioId)

        setResponseDisablingScenario(disableScenario.data)
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error
        })

        setErrorDisableScenario(response?.data?.error)
      } finally {
        setDisablingScenario(false)
      }
    }
  }

  return {
    disableScenario: disableScenario,
    isSuccessDisablingScenario,
    responseDisablingScenario,
    disablingScenario,
    errorDisableScenario
  }
}