import { Scenario } from 'db'
import { useState } from 'react'
import { TableState } from '../../types'
import { useApi, useRevalidateFetch } from '../useFetch'

type ArchiveScenarios = {
  archivingScenarios: boolean,
  archiveScenarios: (scenarios: Scenario[]) => Promise<{ scenario: Scenario }[]>
}

export default function useArchiveScenarios(tableState: TableState<Scenario>): ArchiveScenarios {
  const { call } = useApi<{ scenario: Scenario }>()
  const [archivingScenarios, setArchivingScenarios] = useState(false)
  const { revalidateScenarios } = useRevalidateFetch()

  const archiveScenarios = (scenarios: Scenario[]) => {
    setArchivingScenarios(true)
    const archives = Promise.all(scenarios.map(async (scenario) => {
      const { id } = scenario
      return call({
        url: `/scenario/${id}/archive`,
        method: 'delete'
      })
        .then(({ data }) => data)
    }))

    archives.then(() => {
      revalidateScenarios(tableState)
      setArchivingScenarios(false)
    })

    return archives
  }

  return {
    archivingScenarios,
    archiveScenarios
  }
}