import { CloseCircleFilled } from '@ant-design/icons'
import { main } from '@popperjs/core'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/display/placeholder'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import { FC, useEffect, useRef, useState } from 'react'
import { Controlled as Codemirror } from 'react-codemirror2'
import ReactDOMServer from 'react-dom/server'
import OutsideClickHandler from 'react-outside-click-handler'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { orange } from '../../../../../util/colors'
import { formatterValueBracket } from '../../../../../util/formatter'
import truncateLongData from '../../../../../util/truncateLongData'
import { DataSuggestion } from '../../../developer/formItems/DataPickerPopper'
import DataPickerPopper from './DataPickerPopper'
import { useValueArray } from './StateForm/ArrayMapStateFormItem/ValueArrayContext'
import { useStateTestData } from './StateTestDataContext'
import { useThisStateStaticValue } from './ThisStateStaticValueContext'



const observeReferenceModifier = {
  // size change observer for the codemirror textarea
  // so that the popper can update data picker position
  name: 'observeReferenceModifier',
  enabled: true,
  phase: main,
  effect: (effectArg: any) => {
    const { state, instance } = effectArg
    const RO_PROP = '__popperjsRO__'
    const { reference } = state.elements

    reference[RO_PROP] = new ResizeObserver(() => {
      instance.update()
    })

    reference[RO_PROP].observe(reference)
    return () => {
      reference[RO_PROP].disconnect()
      delete reference[RO_PROP]
    }
  }
}

type CodeMirrorPosition = { line: number, start: number, stop: number, tag: string }

export type RichFormItemProps = {
  placeholder?: string,
  small?: boolean,
  id: string,
  value: string, // Ant Form need this for custom input
  onChange: (value: string) => void // Ant Form need this for custom input
}

const RichTextInput: FC<RichFormItemProps> = ({ id, value, onChange, placeholder }) => {
  const cm = useRef<any>(null)
  const [thisStateStaticValue] = useThisStateStaticValue()
  const [stateTestDataValue] = useStateTestData()
  const [arrayMap] = useValueArray()

  const stateTestData = arrayMap?.dataSuggestion || stateTestDataValue

  const stateOrder = thisStateStaticValue?.order
  const cmContainerElement = useRef<HTMLDivElement>(null)
  const dataPickerElement = useRef<HTMLDivElement>(null)
  const [showDataPicker, setShowDataPicker] = useState<boolean>(false)
  const [listOfMarkedText, setListOfMarkedText] = useState<any[]>([])

  const { styles, attributes } = usePopper(cmContainerElement.current, dataPickerElement.current, {
    strategy: 'absolute',
    placement: 'bottom-start',
    modifiers: [
      observeReferenceModifier,
      { name: 'flip', enabled: false } // disabled flip modifiers so the popper will stay on the bottom
    ]
  })

  const getTagPositions = (text: string) => {
    const lines = text.split('\n')
    const re = /\{\{\{.+?\}\}\}/g
    const positions: { line: number, start: any, stop: any, tag: string }[] = []
    let m

    for (let i = 0; i < lines.length; i++) {
      while ((m = re.exec(lines[i])) !== null) {
        const tag = m[0].substring(3, m[0].length - 3).replace(/\[.+?\]/gi, (v) => v.substr(1, v.length - 2))
        positions.push({
          line: i,
          start: m.index,
          stop: m.index + m[0].length,
          tag,
        })
      }
    }
    return positions
  }

  const transformToVisualTag = (cm: any, positions: CodeMirrorPosition[], stateTestData: Record<string, DataSuggestion>) => {
    positions.forEach(position => {
      const markedText = cm.markText(
        { line: position.line, ch: position.start },
        { line: position.line, ch: position.stop },
        {
          replacedWith: createTagVisual(position, stateTestData)
        }
      )

      setListOfMarkedText(prevValue => [...prevValue, markedText])
    })
  }

  const insertTag = (keyData: string, editor: any) => {
    editor.replaceRange(formatterValueBracket(`{{{${keyData}}}}`), editor.getCursor())
    editor.focus()
  }

  /**
   * @param {String} HTML representing a single element
   * @return {Element}
   *
   * credit to mark-amery https://stackoverflow.com/a/35385518
   */
  const htmlToElement = (html: string) => {
    const template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
  }

  const createTagVisual = (position: { line: number, start: number, stop: number, tag: string }, stateWithTestData: Record<string, DataSuggestion>) => {
    const tagStateId = position.tag.split('.')[0]
    const tagDataKey = position.tag.split('.').slice(1).join('.')
    const selectedState = stateWithTestData[tagStateId]

    if (selectedState && stateWithTestData[tagStateId].flatTestData?.[tagDataKey]) {
      const selectedTagTestData = selectedState.flatTestData[tagDataKey]
      const sample = selectedTagTestData.sample
      const truncatedSample = truncateLongData(sample, 40) // template literal is necessary to forces boolean parsed into a string
      const label = `${selectedTagTestData.label.replace(/\(array\)|\(object\b\)/g, (v) => `<span style="color: ${orange[5]}" >${v}</span>`)}:`
      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          {
            selectedTagTestData.appLogoUrl ?
              <img src={selectedTagTestData.appLogoUrl || '/placeholder.png'} alt=" " /> :
              null
          }
          <b dangerouslySetInnerHTML={{ __html: label }}></b> {truncatedSample}
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    } else if (selectedState?.flatTestData?.[position.tag]) {
      const selectedTagTestData = selectedState.flatTestData[position.tag]
      const truncatedSample = truncateLongData(selectedTagTestData.sample, 40) // template literal is necessary to forces boolean parsed into a string
      const label = `${selectedTagTestData.label.replace(/\(array\)|\(object\b\)/g, (v) => `<span style="color: ${orange[5]}" >${v}</span>`)}:`
      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          {
            selectedTagTestData.appLogoUrl ?
              <img src={selectedTagTestData.appLogoUrl || '/placeholder.png'} alt=" " /> :
              null
          }
          <b dangerouslySetInnerHTML={{ __html: label }}></b> {truncatedSample}
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    } else {
      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          <b>{position.tag}: </b> <i>no data</i>
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    }
  }

  const onBeforeChange = (_editor: any, _data: any, value: string) => {
    onChange?.(value)
  }

  const onEditorDidMount = (editor: any) => {
    cm.current = editor

    if (stateTestData) {
      transformToVisualTag(editor, getTagPositions(editor.getValue()), stateTestData)
    }
  }

  const onCodeMirrorValueChange = (editor: any) => {
    if (stateTestData) {
      transformToVisualTag(editor, getTagPositions(editor.getValue()), stateTestData)
    }
  }

  const onFocus = () => {
    // styled the wrapper when textarea is focused
    const wrapper = cmContainerElement.current

    if (wrapper) {
      wrapper.style.boxShadow = '0 0 0 2px rgba(243, 173, 61, 20%)'
      wrapper.style.borderColor = '#F3AD3D'

      // show data picker
      // setIsComponentVisible(true)
      setShowDataPicker(true)
    }
  }

  const onBlur = () => {
    // unstyled the wrapper when mouse leave focus
    const wrapper = cmContainerElement.current
    if (wrapper) {
      wrapper.style.boxShadow = 'none'
      wrapper.style.borderColor = '#d9d9d9'
    }
  }

  useEffect(() => {
    if (stateTestData && cm.current) {
      // reset all previous marked text
      listOfMarkedText.forEach(markedText => markedText.clear())
      // transform tag to visual if there is any change on test data
      transformToVisualTag(cm.current, getTagPositions(cm.current.getValue()), stateTestData)
    }
  }, [stateTestData])

  const onTagClick = (tagValue: string) => insertTag(tagValue, cm.current)

  const onClear = () => cm.current?.setValue('')

  const showClearButton = (cm.current?.getValue()?.length || 0) > 0

  return (
    <RichFormItemContainer id={id} className="codeMirrorContainer">
      <OutsideClickHandler onOutsideClick={() => setShowDataPicker(false)}>
        <CodeMirrorContainer ref={cmContainerElement}>
          <Codemirror
            value={value}
            onBeforeChange={onBeforeChange}
            onChange={onCodeMirrorValueChange}
            editorDidMount={onEditorDidMount}
            onFocus={onFocus}
            onBlur={onBlur}
            options={{
              placeholder: placeholder,
              mode: null,
              lineNumbers: false,
              lineWrapping: true,
              scrollbarStyle: null,
              autoRefresh: true
            }}
          />
          {showClearButton ? <StyledCloseCircleRichInput onClick={onClear} /> : null}
        </CodeMirrorContainer>
        <PopperComponent
          className="dataPickerPopperContainer"
          isVisible={showDataPicker}
          ref={dataPickerElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {
            stateTestData && stateOrder !== 0 && showDataPicker ?
              <DataPickerPopper statesWithTestData={stateTestData} onTagClick={onTagClick} />
              : null
          }
        </PopperComponent>
      </OutsideClickHandler>
    </RichFormItemContainer>
  )
}

export default RichTextInput


export const StyledCloseCircleRichInput = styled(CloseCircleFilled)`
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  color: #D5D5D5;
  font-size: 20px;
  z-index: 2;
  &:hover {
    color: #979797;
  }
`
const RichFormItemContainer = styled.div`
  position: relative;
  cursor: text;
`

const PopperComponent = styled.div<{ isVisible: boolean }>`
  width: 100%;
  display: block;
  z-index: 99;
  position: relative;
  top: 0;
  transition: all .24s;
  visibility: hidden;
  opacity: 0;

  ${props => props.isVisible ?
    `
      top: 4px !important;
      visibility: visible !important;
      opacity: 1 !important;
    ` : ''}
`

const CodeMirrorContainer = styled.div`
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition-property: top, visibility, opacity;
  transition-duration: .3s;
  margin-bottom: 16px;

  &:hover {
    border-color: #F3AD3D !important;
  }

  & .CodeMirror {
    font-family: 'DM Sans', sans-serif;
    height: fit-content;
    min-height: 36px;
    padding: 4px 11px;
    color: rgba(0,0,0,.85);
    background-color: #fff;
    background-image: none;
    transition: all .3s;
  }

  .CodeMirror pre.CodeMirror-placeholder {
    color: rgba(0, 0, 0, 0.65);
    opacity: 0.4;
    top: 4px;
  }

  & .CodeMirror-scroll {
    overflow: auto !important;
  }

  & .CodeMirror-scroll .CodeMirror-sizer {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  & .CodeMirror-scroll .CodeMirror-sizer .CodeMirror-lines {
    padding: 0;
  }

  & .CodeMirror-scroll .CodeMirror-sizer .CodeMirror-lines .CodeMirror-code {
    line-height: 1.8rem;
  }
`

const CodeMirrorDataTagContainer = styled.span`
  border: 1px solid #d9d9d9;
  background-color: #F5F5F5;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  display: inline-flex;
  align-items: center;
  line-height: 1rem !important;
  vertical-align: middle;
  font-size: 12px;
  margin-top: -2px;

  & b {
    font-size: 12px;
  }

  & img {
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`