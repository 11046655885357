import { Input, Modal, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Version } from 'db'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useAllVersion from '../../../../../../hooks/application/version/useAllVersion'
import useCloneVersion from '../../../../../../hooks/application/version/useCloneVersion'
import Notification from '../../../../../../util/Notification'
import CustomForm from '../../../../components/form/CustomForm'
import FormItem from '../../../../components/form/FormItem'

type Props = {
  afterSubmit?: (values: Version) => void,
  onCancel?: () => void,
  onClone?: Partial<Version>
}

const CloneVersionModal: FC<Props> = ({ onClone, afterSubmit, onCancel }) => {
  const { applicationId } = useParams<{ applicationId: string }>()
  const [visible, setVisible] = useState(false)
  const [form] = useForm()
  const { cloneVersion, cloningVersion, clonedVersion } = useCloneVersion()
  const { versions } = useAllVersion(applicationId)

  useEffect(() => {
    if (onClone) {
      open()
      form.setFieldsValue({
        from: onClone.id,
      })
    }
  }, [onClone])

  const open = () => {
    setVisible(true)
  }

  const close = () => {
    setVisible(false)
    if (onCancel) {
      onCancel()
    }
  }

  const openNotif = (type: 'success' | 'error', title: string, description: string) => {
    Notification[type]({
      message: title,
      description: description
    })
  }

  const onSubmit = (values: any) => {
    cloneVersion(Number(applicationId), values.from, { name: values.to })
  }

  useEffect(() => {
    if (clonedVersion) {
      if (afterSubmit) {
        afterSubmit(clonedVersion)
      }
      openNotif('success', 'Success', `Version ${clonedVersion.name} cloned successfully`)
      form.resetFields()
      close()
    }
  }, [clonedVersion])

  return (
    <>
      <Modal
        visible={visible}
        title="Clone Version"
        okText="Clone"
        cancelText="Cancel"
        confirmLoading={cloningVersion}
        onCancel={close}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values)
            })
            .catch(() => {
              // console.log('Validate Failed:', info)
            })
        }}
      >
        <CustomForm form={form} initialValues={{ from: onClone?.id }}>
          <FormItem
            name="from"
            label="Clone from Version"
            rules={[{ required: true, message: 'Please choose the version!' }]}
          >
            <Select options={versions?.map(version => ({ value: version.id, label: version.name }))} />
          </FormItem>
          <FormItem
            name="to"
            label="Clone to Version"
            rules={[{ required: true, message: 'Destination version is required' }]}
          >
            <Input />
          </FormItem>
        </CustomForm>
      </Modal>
    </>
  )
}

export default CloneVersionModal