import { DatePicker, FormInstance, FormItemProps, Input, Select, Tag } from 'antd'
import { FieldSchema } from 'db'
import { NamePath } from 'rc-field-form/lib/interface'
import { FC } from 'react'
import RichCodeInput from 'src/components/pages/app/scenario/edit/RichCodeInput'
import RichKeyValueInput from '../../../../components/pages/app/scenario/edit/RichKeyValueInput'
import { DataSuggestion } from '../../../../components/pages/developer/formItems/DataPickerPopper'
import { convertStringToRegex, isValidInputJSON } from '../../../../util/functions'
import FormItem from '../../components/form/FormItem'
import ArrayMapFormItem from './ArrayMapFormItem'
import CodeMirrorInput from './ArrayMapFormItem/CodeMirrorInput'
import SelectWithDynamicOption from './SelectWithDynamicOption'

export interface FormItemMappingProps extends Pick<FormItemProps, 'fieldKey'> {
  form?: FormInstance<any>,
  dataSuggestion?: Record<string, DataSuggestion>,
  param: FieldSchema,
  parentKey?: NamePath,
  enableRichInput: boolean | undefined,
  valuesForm: any | undefined,
  applicationId: number | undefined,
  bundles: any | undefined,
  isDisabledAll: boolean | undefined

}

const FormItemMapping: FC<FormItemMappingProps> = ({ form, fieldKey, dataSuggestion, param, parentKey, enableRichInput, valuesForm, applicationId, bundles, isDisabledAll }) => {
  const { regexErrorMessage, regexRule, allowEditByUser, paramSchemaFunc, key, label, options, required, type, helpText, constantValue, getValueFunc, triggerId: dynamicOptionTriggerId } = param

  const rulePattern = regexRule ? [{
    pattern: convertStringToRegex(regexRule),
    message: regexErrorMessage || 'Validation regex failed'
  }] : []

  if (getValueFunc && !allowEditByUser || paramSchemaFunc) {
    return null
  }

  if (type === 'array-map') {
    return <ArrayMapFormItem
      form={form}
      applicationId={applicationId}
      bundles={bundles}
      enableRichInput={enableRichInput}
      isDisabledAll={isDisabledAll}
      param={param}
      valuesForm={valuesForm}
      dataSuggestion={dataSuggestion}
    />
  }

  if (type === 'key-value') {
    return <RichKeyValueInput description={helpText} name={key} key={key} label={label} isRequired={required} />
  }

  return (
    <FormItem
      key={key}
      fieldKey={fieldKey}
      name={parentKey ? Array.isArray(parentKey) ? [...parentKey, key] : [parentKey, key] : key}
      label={<>{type === 'json' ? <Tag className="text-black bg-yellow-100">JSON</Tag> : null}{label}</>}
      rules={[{ required: required, message: label ? `${label} is required` : undefined }, ...rulePattern, () => ({
        async validator(_: any, value: any) {
          if (type === 'json' && !isValidInputJSON(value)) {
            return Promise.reject(new Error('JSON expected'))
          }
          return Promise.resolve()
        },
      }),]}
      description={helpText}
      tooltip={type === 'json' ? 'Expect json value' : undefined}
      hidden={!!constantValue}
      inputType={options || dynamicOptionTriggerId ? 'select' : type}
      // richInputId={`${stateId}-${triggerId || actionId}-${key}`}
      enableRichInput={enableRichInput && !options && !dynamicOptionTriggerId} // enable if only it is explicitly enabled and input is not a Select
      // onValueChange={(value: any) => form?.setFieldsValue({ [key]: value })}
      // initialRichInputValue={form.getFieldValue(key)} // TODO: if valuesForm deleted, y'all need to modify this
    >
      {
        options ?
          <Select showSearch filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={options.map((opt: any) => ({ value: opt.key || opt, label: opt.label ||opt }))} disabled={!!constantValue || isDisabledAll} /> :
          dynamicOptionTriggerId ?
            <SelectWithDynamicOption
              paramsDynamicOption={{
                formValue: valuesForm,
                applicationId: applicationId,
                triggerId: dynamicOptionTriggerId,
                bundles: bundles
              }}
              disabled={isDisabledAll}
            /> :
            type === 'code' ?
              // eslint-disable-next-line
              // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
              <RichCodeInput id={`${key}-tes`} disabled={isDisabledAll} /> :
              type === 'string' || type === 'image' || type === 'json' ?
                dataSuggestion ? <CodeMirrorInput disabled={!!constantValue || isDisabledAll} dataSuggestion={dataSuggestion} /> :
                  <Input disabled={!!constantValue || isDisabledAll} /> :
                type === 'password' ?
                  <Input.Password disabled={!!constantValue || isDisabledAll} /> :
                  type === 'date-time' || type === 'date' ?
                    <DatePicker showTime={type === 'date-time'} /> :
                    null
      }
    </FormItem>
  )
}

export default FormItemMapping