import { FormInstance, FormItemProps, Input, Spin, Tag } from 'antd'
import { FieldSchema } from 'db'
import { NamePath } from 'rc-field-form/lib/interface'
import { FC, Fragment } from 'react'
import RichCodeInput from 'src/components/pages/app/scenario/edit/RichCodeInput'
import RichDateInput from 'src/components/pages/app/scenario/edit/RichDateInput'
import RichDateTimeInput from 'src/components/pages/app/scenario/edit/RichDateTimeInput'
import RichKeyValueInput from 'src/components/pages/app/scenario/edit/RichKeyValueInput'
import RichSelectInput from 'src/components/pages/app/scenario/edit/RichSelectInput'
import RichTextInput from 'src/components/pages/app/scenario/edit/RichTextInput'
import { useStateTestData } from 'src/components/pages/app/scenario/edit/StateTestDataContext'
import { DataSuggestion } from 'src/components/pages/developer/formItems/DataPickerPopper'
import FormItem from 'src/pages/developer/components/form/FormItem'
import { convertStringToRegex, isValidInputDate, isValidInputJSON, isValidInputWithRegexValidation } from 'src/util/functions'
import styled from 'styled-components'
import ValueArrayContextProvider from '../../../../edit/StateForm/ArrayMapStateFormItem/ValueArrayContext'
import ArrayMapStateFormItem from './ArrayMapStateFormItem'

export interface StateFormItemMappingProps extends Pick<FormItemProps, 'fieldKey'> {
  parentKey?: NamePath,
  param: FieldSchema,
  dataSuggestion?: Record<string, DataSuggestion>,
  onFocusOut: (key: string) => void,
  idx: number,
  stateId: number | undefined,
  triggerId: number | undefined,
  actionId: number | undefined,
  form: FormInstance<any> | undefined,
  bundleId: number | undefined,
  initialParamSchema?: FieldSchema[],
  fetchingParamSchema: boolean
}

const StateFormItemMapping: FC<StateFormItemMappingProps> = ({ dataSuggestion, fieldKey, parentKey, stateId, triggerId, actionId, form, bundleId, param, onFocusOut, fetchingParamSchema, idx, initialParamSchema }) => {
  const { regexRule, regexErrorMessage, allowEditByUser, paramSchemaFunc, key, label, options, required, type, helpText, constantValue, getValueFunc, triggerId: dynamicOptionTriggerId } = param
  const [stateTestData] = useStateTestData()

  const rulePattern = regexRule ? convertStringToRegex(regexRule) : undefined

  // const dynamicOptions: any[] = dynamicOptionTriggerId && optionsAllHitRequest && optionsAllHitRequest[dynamicOptionTriggerId] ? optionsAllHitRequest[dynamicOptionTriggerId].map((opt: any) => ({ value: opt.value, label: opt.label })) : []
  if (type === 'array-map') {
    return <ArrayMapStateFormItem
      actionId={actionId}
      bundleId={bundleId}
      fetchingParamSchema={fetchingParamSchema}
      form={form}
      idx={idx}
      onFocusOut={onFocusOut}
      param={param}
      stateId={stateId}
      triggerId={triggerId}
      initialParamSchema={initialParamSchema}
      key={param.key}
    />
  }
  if (paramSchemaFunc || getValueFunc && !allowEditByUser) {
    return null
  }

  if (type === 'key-value') {
    return (
      <Fragment key={key}>
        <RichKeyValueInput small onBlur={() => onFocusOut(key)} description={helpText} isRequired={required} name={key} key={key} label={label} />
        {
          initialParamSchema?.[idx + 1]?.paramSchemaFunc ?
            <Spin spinning={fetchingParamSchema}>{fetchingParamSchema ? 'loading field..' : ''}</Spin> : null
        }
      </Fragment>
    )
  }

  return (
    <ValueArrayContextProvider dataValue={dataSuggestion ? { dataSuggestion: dataSuggestion } : undefined}>
      <StyledFormItem
        small
        fieldKey={fieldKey}
        onBlur={() => {
          // console.log('onfocus out', key)
          onFocusOut(key)
        }}
        // className={`${extra ? 'with-extra' : ''} ${notUseInfoRequired ? 'no-info' : ''}`} // in case we need this later
        key={key}
        hidden={!!constantValue}
        name={parentKey ? Array.isArray(parentKey) ? [...parentKey, key] : [parentKey, key] : key}
        label={<>{type === 'json' ? <Tag className="text-black bg-yellow-100">JSON</Tag> : null}{label}</>}
        rules={[{ required: required, message: label ? `${label} is required` : undefined }, () => ({
          async validator(_: any, value: any) {
            if (rulePattern && !isValidInputWithRegexValidation(value, rulePattern, stateTestData)) {
              return Promise.reject(new Error(regexErrorMessage || 'Validation regex failed'))
            }
            if (type === 'json' && !isValidInputJSON(value, stateTestData)) {
              return Promise.reject(new Error('JSON expected'))
            }
            if ((type === 'date' || type === 'date-time') && !isValidInputDate(value, stateTestData)) {
              return Promise.reject(new Error('Invalid date. Must be a valid date string format.'))
            }
            return Promise.resolve()
          },
        }),]}
        description={helpText}
        tooltip={type === 'json' ? 'Expect json value' : undefined}
      >
        {
          options ?
          // eslint-disable-next-line
          // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
            <RichSelectInput
              onFocusOut={() => {
                // console.log('onfocus out', key)
                onFocusOut(key)
              }}
              id={`${stateId}-${triggerId || actionId}-${key}`}
              options={options.map((opt: any) => ({ value: opt.key || opt, label: opt.label || opt }))}
            /> :
            dynamicOptionTriggerId ?
            // eslint-disable-next-line
            // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
              <RichSelectInput
                onFocusOut={() => {
                  // console.log('onfocus out', key)
                  onFocusOut(key)
                }}
                id={`${stateId}-${triggerId || actionId}-${key}`}
                options={[]}
                form={form}
                bundleId={bundleId}
                hiddenTriggerId={dynamicOptionTriggerId}
              /> :
              type === 'code' ?
              // eslint-disable-next-line
              // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                <RichCodeInput id={`${stateId}-${triggerId || actionId}-${key}`} /> :
                type === 'string' || type === 'image' || type === 'json' ?
                // eslint-disable-next-line
                      // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                  <RichTextInput id={`${stateId}-${triggerId || actionId}-${key}`} />

                  :
                  type === 'password' ?
                    <Input.Password disabled={!!constantValue} /> :
                    type === 'date-time' ?
                      // eslint-disable-next-line
                      // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                      <RichDateTimeInput />
                      :
                      type === 'date' ?
                      // eslint-disable-next-line
                      // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                        <RichDateInput />
                        :
                        null
        }
        {/* {extra ? <div style={{ marginBottom: 24 }}>{extra}</div> : null} in case we need this later */}
      </StyledFormItem>
      {
        initialParamSchema?.[idx + 1]?.paramSchemaFunc ?
          <Spin spinning={fetchingParamSchema} tip={'Reloading field..'}>
            <div />
          </Spin> : null
      }
    </ValueArrayContextProvider>
  )
}

export default StateFormItemMapping

// eslint-disable-next-line
export const StyledFormItem = styled(({ description, children, ...nativeProps }) => <FormItem description={description} {...nativeProps}>{children}</FormItem>)<{ description: string, small?: boolean }>`
  font-family: 'DM Sans', sans-serif;
  margin-bottom: ${({ small }) => small ? '8px' : '16px'};

  .ant-checkbox-wrapper, .ant-radio-wrapper, .ant-form-item-extra {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
  &.with-extra {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: ${props => props.description ? 0 : '5px'};
  }
  .ant-form-item-label > label {
    font-style: normal;
    font-weight: bold;
    font-size: ${({ small }) => small ? '12px' : '16px'};
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-form-item-optional, label.ant-form-item-required::before {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    text-transform: capitalize;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: '(Required)';
    color: ${'#9C3930'};
    margin-left: 4px;
  }

  &.no-info .ant-form-item-label > label.ant-form-item-required::before {
    content: '';
  }

  .ant-form-item-label > label.ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .ant-form-item-extra {
    padding-bottom: 5px;
  }

  .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-success {
    position: absolute;
    bottom: -8px;
  }

  label {
    padding-bottom: 0
  }

`