import { ExclamationCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons'
import { Badge, Select } from 'antd'
import { State } from 'db'
import React, { FC, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import useAllAuthentication from '../../../../../hooks/application/authentication/useAllAuthentication'
import useAuthenticationTest from '../../../../../hooks/application/authentication/useAuthenticationTest'
import useBundlesByApplicationId from '../../../../../hooks/bundle/useBundlesByApplicationId'
import useCreateABundle from '../../../../../hooks/bundle/useCreateABundle'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import { IMPLIKASI_URL } from '../../../../../util/Constant'
import Notification from '../../../../../util/Notification'
import { updatingScenarioOrState } from './recoil'

interface ChooseStateBundleProps {
  selectedAccountId: number | undefined, // bundle ID, undefined if not yet logged in
  openStateForm: () => void,
  scenarioId: number,
  applicationId?: number,
  stateId: number,
  state: State
}
const ChooseStateBundle: FC<ChooseStateBundleProps> = ({ state, scenarioId, stateId, applicationId, openStateForm, selectedAccountId }) => {
  const { revalidateWebhookUrl, revalidateAScenarioState, revalidateBundlesByApplicationId } = useRevalidateFetch()
  const { bundlesByApplicationId, fetchingBundlesByApplicationId } = useBundlesByApplicationId(applicationId)
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const { authentications } = useAllAuthentication(applicationId, undefined)
  const [authenticationId, setAuthenticationId] = useState<number>()
  const [versionId, setVersionId] = useState<number>()
  const { createBundle, creatingBundle } = useCreateABundle()
  const { testAuthentication, testingAuthentication } = useAuthenticationTest(applicationId, authenticationId)
  const [isTryingToLogin, setIsTryingToLogin] = useState(false)
  // const [isAddAccount, setIsAddAccount] = useState(false)
  const [authData, setAuthData] = useState<any>()
  const loading = creatingBundle || testingAuthentication || fetchingBundlesByApplicationId || isTryingToLogin || updatingAScenarioState
  const [activeAddAccount, setActiveAddAccount] = useState<number>()
  const hasAuthenticationId = (state?.trigger || state?.action)?.authenticationId
  const [counterChooseBundle, setCounterChooseBundle] = useState(0)
  const setUpdatingAScenarioState = useSetRecoilState(updatingScenarioOrState)

  useEffect(() => {
    if (authentications && !versionId && !authenticationId) {
      setAuthenticationId(authentications[0]?.id)
      setVersionId(authentications[0]?.versionId)
    }
  }, [authentications, versionId, authenticationId])

  const onMouseEnter = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onAddAccount = (e?: any) => {
    // open new window, login
    if (e) {
      onMouseEnter(e)
    }
    setIsTryingToLogin(true)
    const tempActive = state?.id
    setActiveAddAccount(tempActive)
    const windowAuth = window.open(`${IMPLIKASI_URL}/${applicationId}/run/auth/${authenticationId}`, '_blank', 'height=600,width=800,centerscreen,chrome=yes')
    const windowAuthAsAny = windowAuth as any
    windowAuthAsAny.stateUuid = state?.uuid
    const timer = setInterval(function () {
      if(windowAuth?.closed) {
        clearInterval(timer)
        setIsTryingToLogin(false)
      }
    }, 1000)
    if (windowAuth) {
      windowAuth.onbeforeunload = () => {
        const windowAuthAsAny = windowAuth as any
        if (windowAuthAsAny.bundles) {
          // Old code. It assumes that nameAccountOtomatis always has a value, it isn't.
          // const { nameAccountOtomatis: editableName, ...dataPayload } = windowAuthAsAny.bundles

          const { nameAccountOtomatis, ...dataPayload } = windowAuthAsAny.bundles
          const editableName = nameAccountOtomatis // possibly undefined because it is optional field

          const payload = { editableName, ...dataPayload }
          if (tempActive === state?.id && payload) {
            postBundle(payload, applicationId, versionId, tempActive === state?.id)
          }
          windowAuthAsAny.bundles = undefined
        }
      }
    }
  }

  const postBundle = (payload?: any, applicationId?: number, versionId?: number, isAddAccount?: boolean) => {
    if (payload && isAddAccount && versionId && applicationId) {
      Notification.info({
        message: 'Creating..'
      })
      setCounterChooseBundle(prev => prev + 1)
      setActiveAddAccount(undefined)
      createBundle({
        ...payload,
        versionId: versionId,
        applicationId: applicationId,
      }).then(async ({ data }) => {
        if (data.bundle.id) {
          const test = await testAuthentication(undefined, data.bundle.id)
          if (test) {
            revalidateBundlesByApplicationId(Number(applicationId))
            onChangeAccount(data.bundle.id)
            // setSelectedAccountId(data.bundle.id)
            // openStateForm()
          }
          if (test?.data?.error) {
            Notification.error({
              message: 'Failed on test account',
              description: JSON.stringify(test.data)
            })
          }
          setIsTryingToLogin(false)
        }
      }).catch((e: any) => {
        Notification.error({
          message: 'Failed on test account',
          description: JSON.stringify(e)
        })
        setIsTryingToLogin(false)
      })
    } else {
      Notification.error({
        message: 'Something\'s Wrong',
        description: `Data missing ${JSON.stringify({ applicationId, versionId, isAddAccount, activeAddAccount: activeAddAccount, payload: !!payload })}`
      })
    }
  }

  const onChangeAccount = (value: number, option?: any) => {
    // update bundleId to scenario
    if (option) {
      testAuthentication(undefined, value)?.catch(() => Notification.error({
        message: 'Failed to test your account',
        description: 'Please reconnect your account in page myscenario/accounts'
      }))
    }
    updateAScenarioState(scenarioId, stateId, { bundleId: value })
      .then(() => {
        revalidateAScenarioState(scenarioId, stateId)
        setUpdatingAScenarioState({ counter: 2, isUpdating: false })
        // if this state is trigger
        if (state.triggerId) {
          revalidateWebhookUrl(scenarioId)
        }
      })
      .finally(() => openStateForm())
  }

  useEffect(() => {
    const checkEvent = (event: any) => {
      if (event.data.source === 'otomatis') {
        if (event.data.payload.authData) {
          if (activeAddAccount === state?.id) {
            const { nameAccountOtomatis: editableName, ...payload } = event.data.payload
            setAuthData({ editableName, ...payload })
          }
        }
        if (event.data.payload.bundle) {
          if (activeAddAccount === state?.id) {
            postBundle({
              ...authData,
              ...event.data.payload
            }, applicationId, versionId, activeAddAccount === state?.id)
          }
        }
      }
    }
    window.addEventListener('message', checkEvent)
    return () => {
      window.removeEventListener('message', checkEvent)
    }
  }, [versionId, activeAddAccount, applicationId, authData])

  useEffect(() => {
    if (state?.bundleId && !selectedAccountId) {
      // setSelectedAccountId(state.bundleId)
    }
  }, [state?.bundleId])

  const renderSelectAccountDropdown = () =>
    <Select
      dropdownRender={menu =>
        <div>
          {menu}
          <StyledButton icon={<PlusCircleTwoTone />} onClick={onAddAccount} type="link">Add More Account</StyledButton>
        </div>
      }
      onClick={onMouseEnter}
      style={{ width: 200 }}
      loading={loading}
      disabled={loading}
      value={selectedAccountId}
      placeholder="Select Account"
      onChange={onChangeAccount}
    >
      {
        bundlesByApplicationId?.map(bundle => <Select.Option key={bundle.id} value={bundle.id}>{bundle.editableName || bundle.name}</Select.Option>)
      }
    </Select>

  return (
    <>
      {
        hasAuthenticationId ? bundlesByApplicationId?.length === 0 ?
          <Badge count={counterChooseBundle > 0 && !isTryingToLogin ? <ExclamationCircleTwoTone twoToneColor="#f5222d" /> : null}>
            <StyledButton loading={loading} onClick={onAddAccount} type="primary">Sign In to {(state?.trigger || state?.action)?.application?.name}</StyledButton>
          </Badge> :

          !selectedAccountId ?
            <Badge count={counterChooseBundle > 0 && !isTryingToLogin ? <ExclamationCircleTwoTone twoToneColor="#f5222d" /> : null}>
              {renderSelectAccountDropdown()}
            </Badge> :
            renderSelectAccountDropdown() :
          null
      }
    </>
  )
}

export default ChooseStateBundle