import { FC } from 'react'
import TaskTimeoutSettings from 'src/components/pages/app/settings/TaskTimeoutSettings'
import { StyledCard } from 'src/pages/components/StyledComponents'
import { StyledApplication } from '../submitApp'

const Advance: FC = () => {
  return (
    <StyledApplication>
      <StyledCard>
        <TaskTimeoutSettings />
      </StyledCard>
    </StyledApplication>
  )
}

export default Advance