import { State } from 'db'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import useArchiveAScenarioState from '../../../../../../../hooks/scenario/state/useArchiveAScenarioState'
import useUpdateAScenarioState from '../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../../hooks/useFetch'
import CustomModalMethod from '../../../../../../../util/CustomModalMethod'
import { pathStatesOfStateSelector } from '../../recoil/scenario'
import { useStateValue } from '../StateValueContextProvider'

type Params = {
  id?: number,
  pathStates?: State[]
}

type ArchivePathState = {
  archivePathState: () => void,
  archivingPathState: boolean
}

export default function useArchivePathState(params: Params): ArchivePathState {
  const [stateValue] = useStateValue()
  const { parentId: parentStateId, scenarioId, childrenIds, id: thisStateId } = stateValue
  const { pathStates, id } = params
  const [updatingOrderStates, setUpdatingOrderStates] = useState(false)
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const { archiveAScenarioState, archivingAScenarioState } = useArchiveAScenarioState()
  const { revalidateAScenario } = useRevalidateFetch()
  const getWidth = () => {
    const prevWidth = localStorage.getItem('widthStateForms')
    if (prevWidth) {
      return parseInt(prevWidth)
    }
    return undefined
  }

  const stateId = id || thisStateId

  const thisPathStates = useRecoilValue(pathStatesOfStateSelector(stateId))
  const childrenStateIds = !pathStates ? thisPathStates?.filter(s => s.id !== stateId).map(s => s.id) : childrenIds
  const width = getWidth()
  const archivePathState = () => {
    CustomModalMethod.dangerConfirm({
      style: { marginLeft: `calc(${width}px / 3)` },
      title: `Are you sure delete ${pathStates ? 'these' : 'this'} path?`,
      // icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk: () => {
        setUpdatingOrderStates(true)
        // update childrenIds parent to null
        if (parentStateId) {
          if (pathStates) {
            updateAScenarioState(scenarioId, parentStateId, {
              childrenIds: null
            }).then(() => {
              Promise.all(pathStates.map(async (state) => {
                return archiveAScenarioState(scenarioId, state.id, true).then(({ data }) => data)
              })).then(() => {
                setUpdatingOrderStates(false)
                revalidateAScenario(scenarioId)
              })
            })

          } else {
            updateAScenarioState(scenarioId, parentStateId, {
              childrenIds: childrenStateIds?.length === 0 ? null : childrenStateIds
            }).then(() => {
              archiveAScenarioState(scenarioId, stateId, true)
                .then(() => {
                  setUpdatingOrderStates(false)
                  revalidateAScenario(scenarioId)
                })
            })
          }
        }
      }
    })
  }

  return {
    archivePathState,
    archivingPathState: updatingOrderStates || archivingAScenarioState || updatingAScenarioState
  }
}