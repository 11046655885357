import { PageHeader, Tabs } from 'antd'
import React, { FC } from 'react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import UserBillingAlert from '../../../components/pages/app/index/UserBillingAlert'
import TransactionHistory from './TransactionHistory'
import UsageHistory from './UsageHistory'

const BillingAndUsagePage: FC = () => {
  // current path location: /app/scenario/all
  const history = useHistory()
  const { path } = useRouteMatch()

  const navigateTab = (location: string) => {
    return history.push(location)
  }

  const activeKey = history.location.pathname.includes('usage-history') ? '/usage-history' :
    history.location.pathname.includes('transaction-history') ? '/transaction-history' : ''

  return (
    <BillingAndUsageContainer>
      {/* header */}
      <PageHeader
        title="Billing and Usage"
        style={{ backgroundColor: '#FAFAFA', paddingTop: '10px' }}
        footer={
          <CustomTabs onChange={(activeKey: string) => navigateTab(`${path}${activeKey}`)} activeKey={activeKey}>
            <Tabs.TabPane key="/transaction-history" tab="Transaction History" />
            <Tabs.TabPane key="/usage-history" tab="Biiling & Usage History" />
          </CustomTabs>
        }
      >
      </PageHeader>
      {/* Content */}

      <UserBillingAlert margin={'16px 24px 0'} />
      <Switch>
        <Route path={path} exact>
          <Redirect to={`${path}/transaction-history`} />
        </Route>
        <Route path={`${path}/usage-history/:invoiceId?/:id?`} component={UsageHistory} />
        <Route path={`${path}/transaction-history`} component={TransactionHistory}/>
      </Switch>
    </BillingAndUsageContainer>
  )
}

export default BillingAndUsagePage

const BillingAndUsageContainer = styled.div`
  .ant-page-header-footer {
    margin-top: 0;
  }
`

const CustomTabs = styled(Tabs)`
  & .ant-tabs-tab {
    font-size: 14px !important;
    font-weight: normal !important;
  }
`