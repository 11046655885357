import { Application } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  createdApplication?: Application,
  creatingApplication: boolean,
  errorCreateApplications?: Error,
  createApplication: (payload: Partial<Application>) => void
}

export default function useCreateApplication(): Result {
  const { data, error, isValidating, call } = useApi<{ application: Application }>()

  return {
    createdApplication: data?.application,
    creatingApplication: isValidating,
    errorCreateApplications: error,
    createApplication: (payload: Partial<Application>) => {
      call({
        url: '/application',
        method: 'post',
        data: {
          application: payload
        }
      })
    }
  }
}