import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Skeleton, Space, Typography } from 'antd'
import { State } from 'db'
import React, { FC } from 'react'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import { StyledBoxIcon, StyledP2Description } from '../../../../../pages/components/StyledComponents'
import { primary65 } from '../../../../../util/colors'
import { getInitialName } from '../../../../../util/formatter'
import { stateNameAtom } from '../../templateScenario/editor/recoil/state'
import { updatingScenarioOrState } from './recoil'
import { useThisStateStaticValue } from './ThisStateStaticValueContext'

type StateHeaderInfoProps = {
  state: State | undefined,
  notAllowedEditName?: boolean,
  name?: string
}

const StateHeaderInfo: FC<StateHeaderInfoProps> = ({ state, name, notAllowedEditName }) => {
  const { revalidateAScenarioState } = useRevalidateFetch()
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const [thisState] = useThisStateStaticValue()
  const triggerActionName = thisState?.triggerActionName
  const applicationName = thisState?.application?.name
  const setName = useSetRecoilState(stateNameAtom(state?.id || 0))
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)

  return (
    <StyledTitle>
      {
        state ?
          <CustomStyledBoxIcon>
            {
              (state?.trigger || state?.action)?.application?.logoUrl ?
                <img src={(state?.trigger || state?.action)?.application?.logoUrl} /> : getInitialName(name || (state?.trigger || state?.action)?.application?.name)
            }
          </CustomStyledBoxIcon> :
          <Skeleton.Avatar active size="large" style={{ height: 54, width: 54 }} shape="square" />
      }
      <Space direction="vertical" size="small">
        {
          state ?
            <>
              <StyledP2Description>{state?.trigger ? 'Trigger' : 'Action'} — {applicationName}: {triggerActionName}</StyledP2Description>
              <Space>
                <h5>{`${state ? state?.order + 1 : '-'}. `}</h5>
                <Typography.Title level={5} editable={notAllowedEditName ? false : {
                  tooltip: 'edit name',
                  icon: updatingAScenarioState ? <LoadingOutlined spin style={{ fontSize: 18 }} /> : <EditOutlined />,
                  onChange: async (input) => {
                    if (input !== state?.name) {
                      if (state) {
                        await updateAScenarioState(state.scenarioId, state.id, {
                          name: input
                        })
                        setName(input)
                        setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
                        revalidateAScenarioState(state.scenarioId, state.id)
                      }
                    }
                  }
                }}>
                  {name ? name : state.name ? state.name : state?.action ? state.action.name : state?.trigger?.name}
                </Typography.Title>
              </Space>
            </> :
            <>
              <CustomSkeletonTitle title={{ width: 54 }} paragraph={false} active />
              <Skeleton.Input style={{ width: 200, height: 30 }} active />
            </>
        }
      </Space>
    </StyledTitle>
  )
}

export default StateHeaderInfo

const StyledTitle = styled(Space)`
  h5 {
    color: ${primary65}
  }
`

const CustomStyledBoxIcon = styled(StyledBoxIcon)`
  width: 53px;
  height: 53px;
  img {
    width: 37px;
    height: 37px;
  }
`

const CustomSkeletonTitle = styled(Skeleton)`
  & .ant-skeleton-title {
    margin-top: 0;
    margin-bottom: 0;
  }
`