import { ReactNode } from 'react'
import Config from './ActionStep/ApiConfig/Config'
import Test from './ActionStep/ApiConfig/Test'
// import ExamplePayloadForm from './ActionStep/ExamplePayloadForm'
import FormEditor from './ActionStep/FormEditor'
import SettingsForm from './ActionStep/SettingsForm'

export const basePathAction = '/developer/application/:applicationId/:versionName/main/action'

export type ContentCreate = {
  title: string,
  description: string,
  steps: {
    title: string,
    key: string,
    info?: ReactNode,
    content?: any
  }[]
}

export interface PropsContentStep {
  isActive?: boolean,
  isRequired?: boolean,
  onContinue: () => void,
  onPrevious?: () => void,
  info: ReactNode
}

type Contents = {
  [key: string]: ContentCreate
}

const steps: ContentCreate['steps'] = [
  {
    title: 'Settings',
    key: 'settings',
    content: SettingsForm
  },
  {
    title: 'Input Designers',
    key: 'inputDesigners',
    content: FormEditor
  },
  // {
  //   title: 'Define your output',
  //   key: 'examplePayload',
  //   content: ExamplePayloadForm,
  // },
  {
    title: 'API Configuration',
    key: 'apiConfiguration',
    content: Config
  },
  {
    title: 'Test Action',
    key: 'test',
    content: Test
  }
]

const data: Contents = {
  'create': {
    title: 'Add Action',
    description: 'Actions GET or read data from your API to start workflows. Add actions for new and updated items in your app so users can use the data in other apps.',
    steps: steps
  },
  'update': {
    title: 'Update Action',
    description: 'Actions GET or read data from your API to start workflows. Add actions for new and updated items in your app so users can use the data in other apps.',
    steps: steps
  }
}

export default data