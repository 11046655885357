import { Bundle } from 'db'
import { useState } from 'react'
import { Implikasi } from '../service/Implikasi'
import { Error, TableState } from '../types'
import Notification from '../util/Notification'
import { useRevalidateFetch } from './useFetch'

type Result = {
  archiveBundle: (bundleId: number) => void,
  responseArchivingBundle?: Bundle,
  isSuccessArchivingBundle: boolean,
  archivingBundle: boolean,
  errorArchiveBundle?: Error
}

export default function useArchiveABundle(tableState?: TableState<Bundle>): Result {
  const [responseArchivingBundle, setResponseArchivingBundle] = useState()
  const [archivingBundle, setArchivingBundle] = useState<boolean>(false)
  const [errorArchiveBundle, setErrorArchiveBundle] = useState<Error>()
  const [isSuccessArchivingBundle, setIsSuccessArchivingBundle] = useState<boolean>(false)
  const { revalidateBundles, revalidateABundle } = useRevalidateFetch()

  const archiveBundle = async (bundleId: number) => {
    if (bundleId) {
      try {
        setArchivingBundle(true)
        const deleteBundle = await Implikasi.delete(`/bundle/${bundleId}/archive`, {})

        if (deleteBundle.status === 200) {
          setIsSuccessArchivingBundle(true)
        } else {
          setIsSuccessArchivingBundle(false)
        }

        // revalidate any component that used Bundle model
        revalidateBundles(tableState)
        revalidateABundle(bundleId)

        setResponseArchivingBundle(deleteBundle.data)
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error
        })

        setErrorArchiveBundle(response?.data?.error)
      } finally {
        setArchivingBundle(false)
      }
    }
  }

  return {
    archiveBundle,
    isSuccessArchivingBundle,
    responseArchivingBundle,
    archivingBundle,
    errorArchiveBundle
  }
}