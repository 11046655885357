import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { State } from 'db'
import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { stateAuthIdSF, stateStatus } from '../../recoil/state'
import { useStateValue } from '../StateValueContextProvider'

interface Props {
  pathStates?: State[]
}
const StateStatusInfo: FC<Props> = ({ pathStates }) => {
  const [stateValue] = useStateValue()
  const { id, authenticationId, type: stateType } = stateValue || {}
  const status = useRecoilValue(stateStatus(id))
  const eventAuthId = useRecoilValue(stateAuthIdSF(id))
  const hasAuthenticationId = eventAuthId !== undefined ? eventAuthId : authenticationId
  const { chooseAccount: isAccountSelected, testTriggerAction: isStateTested, testTriggerActionSuccess: isStateTestSucceed } = status

  return (
    <>
      {
        !pathStates ?
          (isAccountSelected || !hasAuthenticationId) && isStateTested && isStateTestSucceed ?
            <CheckCircleFilled style={{ color: '#52C41A', fontSize: 24 }} /> :
            <Tooltip
              placement="topRight"
              title={
                !isAccountSelected && hasAuthenticationId ?
                  'Sign in or choose one the account to continue the setup' :
                  !isStateTestSucceed ?
                    `Test your ${stateType === 'trigger' ? 'trigger' : 'action'} to make sure it is well set up` :
                    `Complete all the ${stateType === 'trigger' ? 'trigger' : 'action'} setup`
              }
            >
              {
                !isStateTestSucceed && isStateTested ?
                  <ExclamationCircleFilled style={{ color: '#ff4d4f', fontSize: 24 }} /> :
                  <CheckCircleFilled style={{ color: '#E3E3E3', fontSize: 24 }} />
              }
            </Tooltip>
          : null

      }
    </>
  )
}

export default StateStatusInfo