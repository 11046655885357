import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Space } from 'antd'
import { FC, useEffect } from 'react'
import styled from 'styled-components'
import MarkdownRenderer from '../../../../../components/MarkdownRenderer'
import { lime } from '../../../../../util/colors'
import { getSuggestionsFromValueArray, isValidValueArray } from '../../../../../util/functions'
import { StyledAlert, StyledButton, StyledCheckbox, StyledLabel, StyledTagLabel } from '../../../../components/StyledComponents'
import FormItem from '../../../components/form/FormItem'
import FormItemMapping, { FormItemMappingProps } from '../FormItemMapping'
import CodeMirrorInput from './CodeMirrorInput'


const ArrayMapFormItem: FC<FormItemMappingProps> = ({ form, param, dataSuggestion, enableRichInput, ...otherProps }) => {
  const { mapSchema, key, label, required, helpText } = param

  useEffect(() => {
    if (!form?.getFieldValue([key, 'data']) && required) {
      form?.setFieldsValue({
        [key]: {
          data: [{}]
        }
      })
    }
  }, [])
  return (
    <FormItem>
      <Space>
        <StyledLabel className={required ? 'required' : ''}>{label}</StyledLabel>
        <StyledTagLabel style={{ color: lime[6] }} color="success">
          Multi Item
        </StyledTagLabel>
      </Space>
      {helpText ? <MarkdownRenderer>{helpText}</MarkdownRenderer> : null}
      <br />
      <FormItem valuePropName="checked" name={[key, '_useArrayFromPrevAction']}>
        <StyledCheckbox>Use array value from previous Action</StyledCheckbox>
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev[key]?._useArrayFromPrevAction !== next[key]?._useArrayFromPrevAction}>
        {({ getFieldValue }) => {
          const useArrayFromPrevAction = getFieldValue([key, '_useArrayFromPrevAction'])
          if (!useArrayFromPrevAction) {
            return null
          }
          return (
            <>
              <FormItem name={[key, '_value']} label="Array Value" rules={[{ required: required || useArrayFromPrevAction, message: 'Array Value is required' },
                () => ({
                  async validator(_: any, value: any) {
                    if (!isValidValueArray(value, dataSuggestion)) {
                      return Promise.reject(new Error('Data selected must be an Array'))
                    }
                    return Promise.resolve()
                  },
                })
              ]} >
                <CodeMirrorInput dataSuggestion={dataSuggestion} />
              </FormItem>
              <StyledAlert
                className="-mt-3.5 mb-2"
                type="info"
                showIcon
                message={'Otomatis will map every item from ‘Array Value’ form to a new Array of Items. Every item will be mapped based on ‘Item Data Mapping’ form below'}
              />
            </>
          )
        }}
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev[key]?._useArrayFromPrevAction !== next[key]?._useArrayFromPrevAction || prev[key]?._value !== next[key]?._value }>
        {({ getFieldValue,  }) => {
          const useArrayFromPrevAction = getFieldValue([key, '_useArrayFromPrevAction'])
          const arrayValue = getFieldValue([key, '_value'])
          const dataSuggestionForItem = getSuggestionsFromValueArray(arrayValue, dataSuggestion)
          if (useArrayFromPrevAction) {
            return (
              <WrapperItem text="Item Data Mapping">
                {
                  mapSchema?.map((field) => {
                    return (
                      <FormItemMapping
                        parentKey={key}
                        param={field}
                        enableRichInput={enableRichInput}
                        {...otherProps}
                        dataSuggestion={dataSuggestionForItem || undefined}
                        key={field.key}
                      />
                    )
                  })
                }
              </WrapperItem>
            )
          } else {
            return (
              <Form.List name={[key, 'data']}>
                {(fields, { add, remove }) =>
                  <>
                    {fields.map((fieldFormList, idx) =>
                      <WrapperItem key={fieldFormList.key} text={`Item #${idx + 1}`}>
                        <DeleteOutlined disabled={required && fields.length === 1} onClick={() => (fields.length !== 1 || !required) && remove(idx)} />
                        {
                          mapSchema?.map((field) => {
                            return (
                              <FormItemMapping
                                parentKey={[fieldFormList.name]}
                                fieldKey={[fieldFormList.name, field.key]}
                                param={field}
                                dataSuggestion={dataSuggestion}
                                enableRichInput={enableRichInput}
                                {...otherProps}
                                key={field.key}
                              />
                            )
                          })
                        }
                      </WrapperItem>
                    )}
                    <Form.Item>
                      <StyledButton type="primary" ghost onClick={() => add()} block icon={<PlusOutlined />}>
                        Add More Items
                      </StyledButton>
                    </Form.Item>
                  </>
                }
              </Form.List>
            )
          }
        }}
      </FormItem>
    </FormItem>
  )
}

export default ArrayMapFormItem

const WrapperItem = styled.div<{ text: string }>`
  border: 1px dashed #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 16px;
  position: relative;
  margin-bottom: 16px;

  &:before {
    content: '${({ text }) => text}';
    background: #FFF;
    position: absolute;
    top: -12px;

    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
`