import { Folder } from 'db'
import { useState } from 'react'
import { useApi } from '../useFetch'
import useFolders from './useFolders'

type UpdateOrderingFolders = {
  updateOrderingFolders: (folders: Folder[]) => Promise<(Folder)[]>,
  isUpdatingOrder: boolean
}

export default function useUpdateOrderingFolders(): UpdateOrderingFolders {
  const { call } = useApi<{ folder: Folder }>()
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)
  const { revalidateFolders } = useFolders()

  const updateOrderingFolders = (folders: Folder[]) => {
    setIsUpdatingOrder(true)
    const update = Promise.all(folders.map(async (folder) => {
      const { id, displayOrder } = folder
      return call({
        url: `/folder/${id}`,
        method: 'patch',
        data: {
          folder: {
            displayOrder
          }
        }
      }).then(({ data }) => data.folder)
    }))

    update.finally(() => {
      setIsUpdatingOrder(false)
      revalidateFolders()
    })
    return update
  }

  return {
    updateOrderingFolders,
    isUpdatingOrder
  }

}