import { Action, Authentication, Trigger } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  archivedAuthentication?: Authentication,
  archivingAuthentication: boolean,
  errorArchiveAuthentications?: Error,
  archiveAuthentication: (applicationId: number, authenticationId: number, versionId?: number) => void
}

export default function useArchiveAuthentication(): Result {
  const { data, error, isValidating, call } = useApi<{ authentication: Authentication }>()
  const apiTriggers = useApi<{ triggers: Trigger[] }>()
  const apiActions = useApi<{ actions: Action[] }>()
  const apiUpdateTrigger = useApi<{ trigger: Trigger }>()
  const apiUpdateAction = useApi<{ action: Action }>()

  return {
    archivedAuthentication: data?.authentication,
    archivingAuthentication: isValidating,
    errorArchiveAuthentications: error,
    archiveAuthentication: async (applicationId, authenticationId, versionId) => {
      call({
        url: `/application/${applicationId}/authentication/${authenticationId}/archive?versionId=${versionId}`,
        method: 'delete',
      })
      const triggers = apiTriggers.call({ url: `/application/${applicationId}/triggers?versionId=${versionId}&authenticationId=${authenticationId}`, method: 'get' }).then(({ data }) => data.triggers)
      const actions =  apiActions.call({ url: `/application/${applicationId}/actions?versionId=${versionId}&authenticationId=${authenticationId}`, method: 'get' }).then(({ data }) => data.actions)

      Promise.all((await triggers).map((trigger) => apiUpdateTrigger.call({
        method: 'patch',
        url: `/application/${applicationId}/trigger/${trigger.id}?versionId=${versionId}`,
        data: { trigger: { authenticationId: null } } }).then(({ data }) => data.trigger)))

      Promise.all((await actions).map((action) => apiUpdateAction.call({
        method: 'patch',
        url: `/application/${applicationId}/action/${action.id}?versionId=${versionId}`,
        data: { action: { authenticationId: null } } }).then(({ data }) => data.action)))

    }
  }
}