import { UploadOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import { Scenario } from 'db'
import { FC, useState } from 'react'
import useImportScenario from '../../../../../hooks/scenario/useImportScenario'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../types'

interface Props {
  visible: boolean,
  closeModal: () => void,
  tableState: TableState<Scenario>
}

const ModalImportScenario: FC<Props> = ({ visible, closeModal, tableState }) => {
  const [fileList, setFileList] = useState<any[]>()
  const { importScenario, importing } = useImportScenario(tableState)

  const propsUpload = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: file => {
      setFileList([file])
      return false
    },
    fileList,
  }

  return (
    <Modal visible={visible}
      title="Import Scenariolication by JSON file"
      onOk={() => importScenario(fileList?.[0], closeModal)}
      onCancel={closeModal}
      okButtonProps={{ disabled: !fileList?.[0], loading: importing }}
    >
      <Upload {...propsUpload}>
        <StyledButton icon={<UploadOutlined />}>Select File</StyledButton>
      </Upload>
    </Modal>
  )
}

export default ModalImportScenario