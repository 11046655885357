import { FolderOutlined } from '@ant-design/icons'
import { Input, Modal, Radio, Space } from 'antd'
import { Folder } from 'db'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import useFolders from '../../../../../hooks/folder/useFolders'

interface Props {
  visible: boolean,
  closeModal: () => void,
  onSubmit: (folderId: number, folderName: string) => void,
  submitting: boolean
}

const ModalMoveToFolder: FC<Props> = ({ visible, closeModal, onSubmit, submitting }) => {
  const { folders } = useFolders()
  const [filteredFolders, setFilteredFolders] = useState<Folder[]>()
  const [folderId, setFolderId] = useState<number>()
  const onOk = () => {
    if (folderId) {
      onSubmit(folderId, folders?.find(folder => folder.id === folderId)?.name || '')
    }
  }

  useEffect(() => {
    onSearch('')
  }, [folders])

  const onSearch = (value: string) => {
    setFilteredFolders(folders?.filter(folder => folder.name.toLowerCase().includes(value.toLowerCase() || '')))
  }
  return (
    <Modal
      className="base-custom"
      title="Please select the folder."
      visible={visible}
      closable={false}
      onOk={onOk}
      okButtonProps={{
        loading: submitting,
        disabled: submitting || !folderId
      }}
      onCancel={closeModal}
    >
      <Input.Search allowClear onSearch={onSearch}></Input.Search>
      <StyledRadioGroup onChange={(e) => setFolderId(e.target.value)}>
        {
          filteredFolders?.map(folder => {
            const { id, name } = folder
            return (
              <StyledRadio value={id} key={id}>
                <Space>
                  <FolderOutlined />
                  {name}
                </Space>
              </StyledRadio>
            )
          })
        }
      </StyledRadioGroup>
    </Modal>
  )
}

export default ModalMoveToFolder

const StyledRadio = styled(Radio)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  /* Neutral/2 */
  background: #FAFAFA;
  /* Neutral/4 */
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  &:after {
    display: none;
  }
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */


  /* Character/Title .85 */

  color: rgba(0, 0, 0, 0.85);

`
const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  max-height: 400px;
  overflow-y: auto;
`