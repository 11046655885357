import React, { FC, useState } from 'react'
import { Button, Modal } from 'antd'
import styled from 'styled-components'
import Typeform from '../../../../../util/Typeform'
import { StyledAlert } from '../../../../../pages/components/StyledComponents'

const RequestApplicationBanner: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const onClickOpenFormModal = () => setIsModalOpen(true)
  const onCancelModal = () => setIsModalOpen(false)

  return (
    <>
      <StyledAlert
        type="info"
        message={
          <p>Cant find the aplication? Request for integration <CustomButtonLink type="link" onClick={onClickOpenFormModal}>here</CustomButtonLink></p>
        }
      />

      <CustomModal
        title="Request for Application"
        closable={false}
        onCancel={onCancelModal}
        visible={isModalOpen}
        footer={null}
        width="96vw"
      >
        <Typeform formId="ZbbY2nY1" onSubmit={
          () => {
            setTimeout(() => onCancelModal(), 5000)
          }
        } />
      </CustomModal>
    </>
  )
}

export default RequestApplicationBanner

const CustomButtonLink = styled(Button)`
  padding: 0;
`

const CustomModal = styled(Modal)`
  & .ant-modal-body {
    height: 64vh;
    padding: 12px;
  }
`