import { CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined } from '@ant-design/icons'
import { Collapse, Divider, Form, Space, Tabs } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import DataResponse from '../../../../../../../../components/pages/app/history/job/DataResponse'
import { useUpdateDataSuggestions } from '../../../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import SelectOrAddBundle from '../../../../../../../../components/pages/developer/main/SelectOrAddBundle'
import useActionTest from '../../../../../../../../hooks/application/action/useActionTest'
import useCreateOrUpdateAction from '../../../../../../../../hooks/application/action/useCreateOrUpdateAction'
import useAllAuthentication from '../../../../../../../../hooks/application/authentication/useAllAuthentication'
import { convertFormValueToParams, convertParamsToFormValue } from '../../../../../../../../util/formatter'
import { StyledButton, StyledCard, StyledDescription, StyledFooterForm, StyledLabel, StyledTag } from '../../../../../../../components/StyledComponents'
import MonacoEditor from '../../../../../../components/form/MonacoEditor'
import FormParamSchema from '../../../../../components/FormParamSchema'
import { useApplicationVersionContext } from '../../../../../contexts/ApplicationVersionContextProvider'
import { basePathAction, PropsContentStep } from '../../data'
import { useSharedValueAction } from '../../SharedValueActionContextProvider'


const Test: FC<PropsContentStep> = ({ isActive, onPrevious }) => {
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()
  // const { application } = useApplication(applicationId)
  const [version] = useApplicationVersionContext()
  const { authentications } = useAllAuthentication(applicationId, version?.id)
  const authenticationId = authentications ? authentications[0]?.id : undefined
  const [values, setValues] = useSharedValueAction()
  const { push } = useHistory()
  const [form] = useForm()
  const { creatingAction, createOrUpdateAction } = useCreateOrUpdateAction()
  const { testAction, testingAction, result } = useActionTest(applicationId, values?.id)
  const [response, setResponse] = useState<any>()
  const [bundles, setBundles] = useState<any>()
  const [counterRefetch, setCounterRefetch] = useState(0)
  const authentication = authentications?.[0]
  const [formAccount] = useForm()

  useUpdateDataSuggestions(bundles, authentication, formAccount, undefined, result)

  const getAllOptions = () => {
    setCounterRefetch(counterRefetch + 1)
  }

  const onContinue = async () => {
    const create = await createOrUpdateAction(applicationId, { ...values, isDraft: values?.isDraft === undefined ? false : values.isDraft, type: values?.type || 'http', versionId: values?.versionId || version?.id })
    if (create) {
      setValues(null)
      push(basePathAction.replace(':applicationId', applicationId).replace(':versionName', versionName))
    }
  }

  const onTest = async () => {
    // create or update action first
    if (values) {
      // console.log(convertFormValueToParams(form.getFieldsValue()), form.getFieldsValue())
      // const newAction = await createOrUpdateAction(applicationId, { ...values, isDraft: values?.isDraft === undefined ? false : values.isDraft, type: values.type || 'http', versionId: values.versionId || version?.id })
      // if (newAction) {
      //   setValues({ ...values, ...newAction })
      //   // test
      const test = await testAction({
        params: convertFormValueToParams(form.getFieldsValue()),
        ...bundles
      })
      if (test) {
        setResponse(test)
      }
      // }
    }
  }

  // const onConnectAccount = () => {
  //   const windowAuth = window.open(`${IMPLIKASI_URL}/${applicationId}/run/auth/${authenticationId}?versionId=${version?.id}`, '_blank', 'height=600,width=800,centerscreen,chrome=yes')
  //   if (windowAuth) {
  //     windowAuth.onbeforeunload = () => {
  //       const windowAuthAsAny = windowAuth as any
  //       if (windowAuthAsAny.bundles) {
  //         // Old code. It assumes that nameAccountOtomatis always has a value, it isn't.
  //         // const { nameAccountOtomatis: _name, ...payload } = windowAuthAsAny.bundles

  //         const { nameAccountOtomatis, ...payload } = windowAuthAsAny.bundles
  //         const _name = nameAccountOtomatis // possibly undefined because it is optional field

  //         setBundles({ ...payload })
  //         windowAuthAsAny.bundles = undefined
  //       }
  //     }
  //   }
  // }

  useEffect(() => {
    const checkEvent = (event: any) => {
      if (event.data.source === 'otomatis') {
        if (event.data.payload.authData) {
          const { nameAccountOtomatis: _name, ...payload } = event.data.payload
          setBundles({ ...payload })
        }
        if (event.data.payload.bundle) {
          const { nameAccountOtomatis: _name, ...payload } = event.data.payload
          setBundles((prevState: any) => ({
            ...prevState,
            ...payload
          }))
        }
      }
    }
    window.addEventListener('message', checkEvent)
    return () => {
      window.removeEventListener('message', checkEvent)
    }
  }, [])


  const getStatusRequest = () => {
    const result = response
    if (result) {
      if (result?.success) {
        return (
          <Space>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <p>Request Succeed</p>
          </Space>
        )
      }
      return (
        <Space>
          <CloseCircleTwoTone twoToneColor={'#9C3930'} />
          <p>Request Failed</p>
        </Space>
      )
    }
  }

  // const onRemoveAccount = () => {
  //   setBundles(undefined)
  // }

  useEffect(() => {
    form.setFieldsValue(convertParamsToFormValue(undefined, values?.paramSchema))
  }, [values?.paramSchema])

  const onClearResponse = () => {
    setResponse(undefined)
  }

  const onOpenLog = () => {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = `/developer/application/${applicationId}/${versionName}/main/action/${values?.id}/v/${version?.id}/logs`
    a.rel = 'noopener noreferrer'
    a.click()
  }

  return (
    <StyledTest className={isActive ? 'show' : undefined}>
      <Tabs defaultActiveKey="1" tabBarExtraContent={getStatusRequest()}>
        <Tabs.TabPane tab="Test Setup" key="1">
          <StyledLabel className="no-status">Test your Action</StyledLabel>
          <StyledDescription>Test your action request.</StyledDescription>
          {
            bundles ?
              <>
                {/* <Space align="center">
                  <h5 style={{ margin: 0 }}>Sample Account Connected</h5>
                  <Button icon={<DeleteOutlined />} onClick={onRemoveAccount}>Remove Sample Account</Button>
                </Space> */}
                {
                  (authentication?.fieldSchema?.length || 0) > 0 ?
                    <Collapse ghost>
                      <Collapse.Panel key={'1'} header={`Detail ${bundles.user?.name || ''} Account`}>
                        <FormParamSchema
                          isDisabledAll
                          form={formAccount}
                          initialParamSchema={authentication?.fieldSchema}
                        ></FormParamSchema>
                      </Collapse.Panel>
                    </Collapse> :
                    null
                }
              </>
              :
              null
          }
          {
            values?.authenticationId && authenticationId ?
              <SelectOrAddBundle
                applicationId={applicationId}
                authenticationId={authenticationId}
                versionId={version?.id}
                setBundles={setBundles}
              />
              : null
          }
          <Divider />
          <FormParamSchema
            isEnableAuthentication={!!values?.authenticationId}
            form={form}
            actionId={values?.id}
            bundles={bundles}
            applicationId={parseInt(applicationId)}
            counterRefetch={counterRefetch}
            initialParamSchema={values?.paramSchema}
          >
            <StyledDescription>Run your test request{authenticationId ? ' using the account you connected' : null} to make sure that everything is working</StyledDescription>

            <Form.Item shouldUpdate>
              {
                ({ getFieldsError }) => {
                  const isDisabledTest = getFieldsError().filter(field => field.errors.length > 0).length > 0
                  return (
                    <Space>
                      <StyledButton htmlType="button" onClick={getAllOptions} icon={<ReloadOutlined />} type="dashed">Refresh Data</StyledButton>
                      <StyledButton onClick={onClearResponse}>Clear Response</StyledButton>
                      <StyledButton type="primary" ghost onClick={onTest} disabled={isDisabledTest} loading={testingAction || creatingAction} >Test Your Action Request</StyledButton>
                      <StyledButton type="primary" ghost onClick={onOpenLog} disabled={isDisabledTest} loading={testingAction || creatingAction} >Open Logs</StyledButton>
                    </Space>
                  )
                }
              }
            </Form.Item>

          </FormParamSchema>
          {response ? <StyledCardResponse style={{ marginTop: 8 }}><DataResponse data={response?.data || response} /></StyledCardResponse> : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bundle" key="3">
          {
            bundles ?
              <MonacoEditor options={{ readOnly: true }}  value={bundles ?JSON.stringify(bundles, null, 2) : undefined} />
              :
              authenticationId ? 'Connect to your account first' : 'No bundle here'
          }
        </Tabs.TabPane>
      </Tabs>
      <br />
      <StyledFooterForm>
        <StyledButton type="primary" ghost onClick={onPrevious}>Previous</StyledButton>
        <StyledButton type="primary" onClick={onContinue} loading={creatingAction}>{'Save & Finish'}</StyledButton>
      </StyledFooterForm>
    </StyledTest>
  )
}

export default Test

const StyledCardResponse = styled(StyledCard)`
  .ant-card-body {
    padding: 0;
  }
`
const StyledTest = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;

  ${StyledTag} {
    color: rgba(0, 0, 0, 0.65);
  }
`