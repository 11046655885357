import { Layout, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import GlobalVariableForm from 'src/components/pages/developer/main/globalVariable/GlobalVariableForm'
import useGlobalVariable from 'src/hooks/application/globalVariable/useGlobalVariable'
import useUpdateGlobalVariable from 'src/hooks/application/globalVariable/useUpdateGlobalVariable'
import PageHeader from 'src/pages/components/PageHeader'
import { StyledCard } from 'src/pages/components/StyledComponents'
import styled from 'styled-components'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'

const EditGlobalVariable: FC = () => {
  const [form] = useForm()
  const [version] = useApplicationVersionContext()
  const { versionName, applicationId, globalVariableId } = useParams<{ versionName: string, applicationId: string, globalVariableId: string }>()
  const { globalVariable, fetchingGlobalVariable } = useGlobalVariable(applicationId, globalVariableId, version?.id)
  const { updateGlobalVariable, updatingGlobalVariable } = useUpdateGlobalVariable()
  const history = useHistory()

  const onSubmit = (data: any) => {
    updateGlobalVariable(parseInt(applicationId), parseInt(globalVariableId), data, version?.id).then(() => {
      history.push(`/developer/application/${applicationId}/${versionName}/main/globalVariable`)
    })
  }

  useEffect(() => {
    if (globalVariable) {
      form.setFieldsValue(globalVariable)
    }
  }, [globalVariable])
  return (
    <StyledContainer>
      <Spin spinning={fetchingGlobalVariable}>
        <PageHeader
          title="Edit Global Variable"
        />
        <StyledCard>
          <GlobalVariableForm
            form={form}
            onSubmit={onSubmit}
            submitting={updatingGlobalVariable}
          />
        </StyledCard>
      </Spin>
    </StyledContainer>
  )
}

export default EditGlobalVariable

const StyledContainer = styled(Layout.Content)`
  ${StyledCard} {
    margin: 0 24px 16px;
    padding: 0;
  }
`