import { Application } from 'db'
import { Implikasi } from '../../../service/Implikasi'
import { TableState } from '../../../types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from '../../../util/Constant'
import useFetch from '../../useFetch'

type Result = {
  applicationsLength?: number,
  revalidateApplications: () => void,
  applications?: Application[],
  fetchingApplications: boolean,
  errorFetchApplications?: any
}


const useNeedReviewApplications: (tableState?: TableState<Application>) => Result = (tableState) => {
  const key = `needReviewApplications${JSON.stringify(tableState)}`
  const url = '/applications'

  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const filters = tableState?.filter || {}
  const search = tableState?.filter ? (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
    if (field === 'status') {
      const value = filters[field]
      if (value === 'waiting') {
        return `${res},submittedAt.is=not null,publishedAt.is=null`
      }
      if (value === 'accepted') {
        return `${res},submittedAt.is=not null,publishedAt.is=not null`
      }
      if (value === 'rejected') {
        return `${res},submittedAt.is=not null,application.id.in=('0')`
      }
    }
    if (field === 'search') {
      const ids = (filters[field] as string)?.match(/\d+/g) || []
      const searchByIds = ids.length > 0 ? `,application.id.in=(${ids.join(',')})` : ''
      return `${res},${searchByIds ? 'or=(' : ''}application.name.ilike=${filters[field]}${searchByIds ? `${searchByIds})` : ''}`
    }
    return res
  }, 'true') : 'true'


  const { data, error, isValidating, revalidateFetch } = useFetch<{ applications: Application[], length: number }>(`${key}-${url}`, {},
    {
      fetcher: async () => {
        const res = await Implikasi(url, { method: 'get',
          params: {
            expands: 'version',
            skip: (currentPage - 1) * pageSize,
            take: pageSize,
            and: `(${search},submittedAt.is=not null)`
          },
          // data: {
          //   'search': `${search} and "submittedAt" is not null`
          // }
        })
        return res.data
      }
    }
  )

  return {
    revalidateApplications: () => revalidateFetch(`${key}-${url}`),
    applicationsLength: data?.length,
    applications: data?.applications,
    fetchingApplications: isValidating,
    errorFetchApplications: error
  }
}

export default useNeedReviewApplications