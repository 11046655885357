import { AxiosPromise } from 'axios'
import { Application } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  getApplicationByActionType: (type?: 'path') => AxiosPromise<{ applications: Application[] }>,
  application?: Application,
  actionId?: number,
  fetchingApplication: boolean,
  errorFetchApplication?: Error
}

export default function useApplicationByActionType(): Result {
  const { data, error, call, isValidating } = useApi<{ applications: Application[] }>()

  return {
    getApplicationByActionType: async (type) => {
      return await call({
        url: '/applications?expands=action',
        method: 'get',
        params: {
          and: `(application.isNativeApp.is=true,action.type.eq=${type})`
        },
        // data: {
        //   'search': `"isNativeApp" = true AND "action"."type" = '${type}'`
        // }
      })
    },
    application: data?.applications ? data.applications[0] : undefined,
    errorFetchApplication: error,
    fetchingApplication: isValidating,
    actionId: data?.applications ? data.applications[0]?.actions ? data.applications[0].actions[0]?.id : undefined : undefined,

  }
}