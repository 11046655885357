import { Empty } from 'antd'
import { FC } from 'react'

const YourTemplates: FC = () => {
  return (
    <>
      <Empty
        className="flex align-middle flex-col justify-center h-full"
        imageStyle={{
          display: 'flex',
          justifyContent: 'center',
          height: 200
        }}
        image={'/illustrations/development.svg'}
        description={<>
          <h3>The Feature is Currently Being Developed</h3>
          <p>We’ll let you know if the feature is ready.</p>
        </>}
      />
    </>
  )
}

export default YourTemplates