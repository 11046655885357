import { Scenario, State } from 'db'
import { useState } from 'react'
import { Error } from '../../types'
import { useApi } from '../useFetch'

type Result = {
  createScenario: (payload: Partial<Scenario>) => Promise<Scenario | undefined>,
  createdScenario?: Scenario,
  isSuccessCreatingScenario: boolean,
  creatingScenario: boolean,
  errorCreateScenario?: Error
}

export default function useCreateScenario(): Result {
  const [responseCreatingScenario, setResponseCreatingScenario] = useState<Scenario>()
  const [creatingScenario, setCreatingScenario] = useState<boolean>(false)
  const [isSuccessCreatingScenario, setIsSuccessCreatingScenario] = useState<boolean>(false)
  const APICreateScenario = useApi<{ scenario: Scenario }>()
  const APICreateState = useApi<{ state: State }>()
  const APIUpdateState = useApi<{ state: State }>()

  const create = async (payload: Partial<Scenario>) => {
    setCreatingScenario(true)
    const { states, ...payloadScenario } = payload
    const post = await APICreateScenario.call({
      url: '/scenario',
      method: 'post',
      data: {
        scenario: payloadScenario
      }
    }).then(({ data }) => data?.scenario)
    if (post?.id && states) {
      const mappingExistedToNewId = {}
      const isDuplicate = !!states[0].id
      const promisesStates = states.map(async (state, idx) => {
        return await APICreateState.call({
          url: `/scenario/${post.id}/state`,
          method: 'post',
          data: {
            state: {
              ...state,
              id: null,
              order: state.order !== undefined ? state.order : idx,
            }
          }
        }).then(({ data }) => {
          mappingExistedToNewId[state.id] = data.state.id
          return data.state
        })
      })
      const createdStates= await Promise.all(promisesStates)
      await Promise.all(createdStates.sort((s1, s2) => s1.order - s2.order).map(async (state, idx, states) => {
        const parentId = state.parentId ? mappingExistedToNewId[state.parentId] : states[idx - 1]?.id
        const childIds = state.childrenIds ?
          // handle for duplicate scenario
          state.childrenIds.map(childId => mappingExistedToNewId[childId]) :
          // handle for create new
          states[idx + 1]?.id && !isDuplicate ? [states[idx + 1]?.id] : null
        const newStateConditionId = state.stateConditionId ? mappingExistedToNewId[state.stateConditionId] : null
        const stateParams = state.params ? mappingParams(state.params, mappingExistedToNewId) : null
        const stateConditions = state.conditions ? mappingConditions(state.conditions, mappingExistedToNewId) : null
        return await APIUpdateState.call({
          url: `/scenario/${post.id}/state/${state.id}`,
          method: 'patch',
          data: {
            state: {
              conditions: stateConditions,
              params: stateParams,
              parentId: parentId,
              childrenIds: childIds,
              stateConditionId: newStateConditionId
            }
          }
        })
      }))
      setIsSuccessCreatingScenario(true)
      setResponseCreatingScenario(post)
      setCreatingScenario(false)
      return post
    }
    setResponseCreatingScenario(post)
    setCreatingScenario(false)
  }

  return {
    createScenario: async (payload: Partial<Scenario>) => {
      return create(payload)
    },
    creatingScenario: creatingScenario,
    createdScenario: responseCreatingScenario,
    isSuccessCreatingScenario: isSuccessCreatingScenario,
    errorCreateScenario: APICreateScenario.error || APICreateState.error
  }

}

export const mappingStringParamToNewIds = (text: string, mappingExistedToNewId: Record<number, number>): string => {
  return text.replace(/\{\{\{.+?\}\}\}/g, (val) => {
    return val.replace(/\[\d+?\]/, v => {
      const id = v.substring(1, v.length - 1)
      const newId = mappingExistedToNewId[parseInt(id)]
      return `[${newId}]`
    })
  })
}

export const mappingParams = (stateParams: Record<string, any>, mappingExistedToNewId: Record<number, number>): Record<string, any> => {
  return Object.keys(stateParams).reduce((res, key) => {
    const thisValue = stateParams[key]
    if (typeof thisValue === 'string') {
      const newValue = mappingStringParamToNewIds(thisValue, mappingExistedToNewId)
      return {
        ...res,
        [key]: newValue
      }
    }
    if (Array.isArray(thisValue)) {
      const newValue = mappingKeyValuePaired(thisValue, mappingExistedToNewId)
      return {
        ...res,
        [key]: newValue
      }
    }
    if (typeof thisValue === 'object') {
      const newValue = mappingMultiItem(thisValue, mappingExistedToNewId)
      return {
        ...res,
        [key]: newValue
      }
    }
    return res
  }, {})
}

const mappingKeyValuePaired = (arrayValue: { key: string, value: string }[], mappingExistedToNewId: Record<number, number>): { key: string, value: string }[] => {
  return arrayValue.map((item) => {
    return {
      key: mappingStringParamToNewIds(item.key, mappingExistedToNewId),
      value: mappingStringParamToNewIds(item.value, mappingExistedToNewId)
    }
  })
}
type MultiItem = Record<string, any> & {
  _value?: string,
  _useArrayFromPrevAction?: boolean,
  data?: Record<string, any>[]
}
const mappingMultiItem = (item: MultiItem, mappingExistedToNewId: Record<number, number>): MultiItem => {
  return Object.keys(item).reduce((res, key) => {
    const thisValue = item[key]
    if (key === 'data') {
      return {
        ...res,
        [key]: thisValue.map(itemData => {
          return mappingParams(itemData, mappingExistedToNewId)
        })
      }
    }
    if (typeof thisValue === 'string') {
      const newValue = mappingStringParamToNewIds(thisValue, mappingExistedToNewId)
      return {
        ...res,
        [key]: newValue
      }
    }
    if (Array.isArray(thisValue)) {
      const newValue = mappingKeyValuePaired(thisValue, mappingExistedToNewId)
      return {
        ...res,
        [key]: newValue
      }
    }
    return {
      ...res,
      [key]: thisValue
    }
  }, {})
}

export const mappingConditions = (conditions: (string | null)[][][], mappingExistedToNewId: Record<number, number>): (string | null)[][][] => {
  return conditions.map(orClouse => {
    return orClouse.map(andClouse => {
      return andClouse.map(value => {
        if (value) return mappingStringParamToNewIds(value, mappingExistedToNewId)
        return value
      })
    })
  })
}