import React, { FC, useCallback, useState } from 'react'
import { Layout, Row, Steps } from 'antd'
import { Application } from 'db'
import styled from 'styled-components'
import PageHeader from '../../components/PageHeader'
import SharedValueScenarioContextProvider from '../../../components/pages/app/scenario/create/contexts/SharedValueScenarioContextProvider'
import StepOneSelectApplication from '../../../components/pages/app/scenario/create/StepOne/StepOneSelectApplication'
import StepTwoChooseTriggerAction from '../../../components/pages/app/scenario/create/StepTwo/StepTwoChooseTriggerAction'
import RequestApplicationBanner from '../../../components/pages/app/scenario/create/RequestApplicationBanner'
import UserBillingAlert from '../../../components/pages/app/index/UserBillingAlert'

const CreateScenarioPage: FC = () => {
  const [selectedApps, setSelectedApps] = useState<Application[]>([])

  const addApplication = useCallback((app: Application) => {
    setSelectedApps(prevState => [...prevState, app])
  }, [])

  const removeApplicationByIndex = useCallback((indexToBeRemoved: number) => {
    setSelectedApps(prevState => {
      return [
        ...prevState.slice(0, indexToBeRemoved), // take item(s) before the deleted app
        ...prevState.slice(indexToBeRemoved + 1) // take item(s) after the deleted app
      ]
    })
  }, [])

  return (
    <SharedValueScenarioContextProvider>
      <StyledInitCreateConnection>
        <PageHeader
          style={{ background: '#FFF', padding: '4px 24px' }}
          title="Welcome to Otomatis!"
          extra={<RequestApplicationBanner />}
          subTitle={'Follow these instructions to start your scenario'}
        >
        </PageHeader>
        <UserBillingAlert margin={16} />
        <Row>
          <CustomSteps direction="vertical" current={0}>
            <Steps.Step
              status="process"
              title="Select Apps you want to connect"
              description={
                <StepOneSelectApplication
                  selectedApplications={selectedApps}
                  addApplication={addApplication}
                  removeApplicationByIndex={removeApplicationByIndex}
                />
              }
            />
            <Steps.Step
              status={'process'}
              title={selectedApps.length === 0 ? 'Or, you can select from these recommended scenario.' : 'Create your own Scenario'}
              description={
                <StepTwoChooseTriggerAction
                  selectedApplications={selectedApps}
                />
              }
            />
          </CustomSteps>
        </Row>
      </StyledInitCreateConnection>
    </SharedValueScenarioContextProvider>
  )
}

export default CreateScenarioPage

const StyledInitCreateConnection = styled(Layout.Content)`
  & .ant-steps-item-container {
    .ant-steps-item-title {
      font-family: 'IBM Plex Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 2px;
    }

    .ant-steps-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > .ant-steps-item-tail::after {
      background: #D9D9D9;
    }
  }
`

const CustomSteps = styled(Steps)`
  padding: 24px;

  & .ant-steps-item-process .ant-steps-item-icon {
    background: #96C443;
    border-color: #96C443;
  }
`