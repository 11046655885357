import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { FC, useEffect, useState } from 'react'
import { useApiAllAuthentications } from '../../../../../../hooks/application/authentication/useAllAuthentication'
import { useApiAuthenticationTest } from '../../../../../../hooks/application/authentication/useAuthenticationTest'
import usePublicApplications from '../../../../../../hooks/application/main/usePublicApplications'
import useCreateABundle from '../../../../../../hooks/bundle/useCreateABundle'
import CustomForm from '../../../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../../../pages/developer/components/form/FormItem'
import { IMPLIKASI_URL } from '../../../../../../util/Constant'
import Notification from '../../../../../../util/Notification'
import ApplicationSelectField from '../../../ApplicationsSelectField'

interface Props {
  visible: boolean,
  onCloseModal: () => void
}

const ModalAddAccount: FC<Props> = ({ visible, onCloseModal }) => {
  const { publicApplications, fetchingPublicApplications } = usePublicApplications({ expands: 'authentication' })
  const [form] = useForm()
  const [isTryingToLogin, setIsTryingToLogin] = useState(false)
  const { getAllAuthentications, fetchingAllAuthentications, authentications } = useApiAllAuthentications()
  const { createBundle, creatingBundle } = useCreateABundle()
  const { testAuthentication, testingAuthentication } = useApiAuthenticationTest()
  const [authData, setAuthData] = useState<any>()
  const auth = authentications?.[0]
  const authenticationId = auth?.id
  const versionId = auth?.versionId
  const applicationId = auth?.applicationId

  const postBundle = (payload?: any, applicationId?: number, authId?: number, versionId?: number) => {
    if (payload && versionId && authId && applicationId) {
      Notification.info({
        message: 'Creating..'
      })
      createBundle({
        ...payload,
        applicationId: applicationId,
        versionId
      }).then(async ({ data }) => {
        if (data.bundle.id) {
          const test = await testAuthentication(applicationId, authId, data.bundle.id)
          if (test) {
            onCloseModal()
          }
          if (test?.data?.error) {
            Notification.error({
              message: 'Failed on test account',
              description: JSON.stringify(test.data)
            })
          }
          setIsTryingToLogin(false)
        }
      }).catch((e: any) => {
        Notification.error({
          message: 'Failed on test account',
          description: JSON.stringify(e)
        })
        setIsTryingToLogin(false)
      })
    }
  }

  const onOk = () => {
    const applicationId = form.getFieldValue('applicationId')
    setIsTryingToLogin(true)
    getAllAuthentications(applicationId).then(({ data }) => {
      const authId = data.authentications[0].id
      const versionId = data.authentications[0].versionId
      const windowAuth = window.open(`${IMPLIKASI_URL}/${applicationId}/run/auth/${authId}`, `_${IMPLIKASI_URL}-${applicationId}-${authenticationId}`, 'height=600,width=800,centerscreen,chrome=yes')
      const timer = setInterval(function () {
        if(windowAuth?.closed) {
          clearInterval(timer)
          setIsTryingToLogin(false)
        }
      }, 1000)
      if (windowAuth) {
        windowAuth.onbeforeunload = () => {
          const windowAuthAsAny = windowAuth as any

          if (windowAuthAsAny.bundles) {
            // Old code. It assumes that nameAccountOtomatis always has a value, it isn't.
            // const { nameAccountOtomatis: editableName, ...dataPayload } = windowAuthAsAny.bundles

            const { nameAccountOtomatis, ...dataPayload } = windowAuthAsAny.bundles
            const editableName = nameAccountOtomatis // possibly undefined because it is optional field
            const payload = { editableName, ...dataPayload }

            postBundle(payload, applicationId, authId, versionId)
            windowAuthAsAny.bundles = undefined
          }
        }
      }
    })
  }

  useEffect(() => {
    const checkEvent = (event: any) => {
      if (event.data.source === 'otomatis') {
        if (event.data.payload.authData) {
          const { nameAccountOtomatis: editableName, ...payload } = event.data.payload
          setAuthData({ editableName, ...payload })
        }
        if (event.data.payload.bundle) {
          postBundle({
            ...authData,
            ...event.data.payload
          }, applicationId, authenticationId, versionId)
        }
      }
    }
    window.addEventListener('message', checkEvent)
    return () => {
      window.removeEventListener('message', checkEvent)
    }
  }, [versionId, authenticationId , applicationId, authData])

  return (
    <>
      <Modal
        className="base-custom"
        visible={visible}
        title="Add Account for application"
        onCancel={onCloseModal}
        okButtonProps={{
          loading: creatingBundle || testingAuthentication || isTryingToLogin || fetchingAllAuthentications
        }}
        onOk={onOk}>
        <CustomForm form={form}>
          <FormItem label="App" name="applicationId" notUseInfoRequired rules={[{ required: true, message: 'App is required' }]}>
            <ApplicationSelectField loading={fetchingPublicApplications} applications={publicApplications?.filter(app => !app.isNativeApp)} />
          </FormItem>
        </CustomForm>
      </Modal>
    </>
  )
}

export default ModalAddAccount