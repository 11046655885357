import { AuthUser } from 'db/dist/Model/Auth'
import { OTORISASI_API_URL } from '../../helpers/Constant'
import useFetch from '../useFetch'

type Result = {
  authUser?: AuthUser
}

export default function useAuthUser(): Result {
  const { data } = useFetch<{ user: AuthUser }>(`${OTORISASI_API_URL}/api/auth/v1/user/me`, { baseURL: '' }, { revalidateOnFocus: false })

  return {
    authUser: data?.user
  }
}