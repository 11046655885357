import { Invitation } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  deletedInvitation?: Invitation,
  deletingInvitation: boolean,
  errorDeleteInvitation?: Error,
  deleteInvitation: (applicationId: number, invitationId: number) => void
}

export default function useDeleteInvitation(): Result {
  const { data, error, isValidating, call } = useApi<{ invitation: Invitation }>()

  return {
    deletedInvitation: data?.invitation,
    deletingInvitation: isValidating,
    errorDeleteInvitation: error,
    deleteInvitation: (applicationId, invitationId) => {
      call({
        url: `/application/${applicationId}/invitation/${invitationId}`,
        method: 'delete',
      })
    }
  }
}