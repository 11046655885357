import { Col, Layout, Row, Skeleton, Tooltip } from 'antd'
import { Application, Scenario } from 'db'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useStatusPayment from '../../../../../../hooks/payment/useStatusPayment'
import useCreateScenarioFromTemplate from '../../../../../../hooks/scenario/template/useCreateScenarioFromTemplate'
import usePublicScenarios from '../../../../../../hooks/scenario/usePublicScenarios'
import { StyledButton, StyledCard, StyledP2Description, StyledPMedium } from '../../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../../types'
import AvatarApplicationGroups from '../../../AvatarApplicationGroups'

type TemplateScenariosProps = {
  selectedApplications: Application[]
}

const TemplateScenarios: FC<TemplateScenariosProps> = ({ selectedApplications }) => {
  const [applications, setApplications] = useState<(Application | null)[]>()
  const [applicationIds, setApplicationIds] = useState<(number | undefined)[]>()
  const [tableState, setTableState] = useState<TableState<Scenario>>({
    filter: {
      published: ['1'],
      // applicationIds: applicationIds?.map(appId => Number(appId)) || []
    }
  })
  const { scenarios, fetchingScenarios, scenariosLength } = usePublicScenarios(tableState)
  const { createScenarioFromTemplate, creatingScenarioFromTemplate, activeId } = useCreateScenarioFromTemplate()
  const { push } = useHistory()
  const [filteredScenarios, setFilteredScenarios] = useState<Scenario[]>()
  const { plan } = useStatusPayment()

  useEffect(() => {
    const validApplications = selectedApplications?.filter(application => application?.id)
    const applicationIds = validApplications?.map(application => application?.id)
    setApplications(validApplications)
    setApplicationIds(applicationIds)
  }, [selectedApplications])

  useEffect(() => {
    if (applicationIds?.length === 0) {
      setFilteredScenarios(scenarios)
    } else {
      setFilteredScenarios(scenarios?.filter(scenario => {
        const appIds = scenario.states?.map(state => state.trigger?.application?.id || state.action?.application?.id)
        if (!applicationIds || applicationIds.length === 0) {
          return true
        }
        const hasIntersection = appIds?.reduce((res, appId) => res || applicationIds?.includes(appId), false)
        if (hasIntersection) {
          return true
        }
      }))
    }
  }, [applications, applicationIds, scenarios])

  const onCreateScenario = async (values: Scenario) => {
    createScenarioFromTemplate(values.id).then((scenario) => {
      if (scenario) {
        push(`/app/scenario/${scenario.id}/edit`)
      }
    })
  }

  const onLoadMore = () => {
    setTableState(prevState => ({ ...prevState, pagination: { ...prevState.pagination, current: (prevState.pagination?.current || 1) + 1 }  }))
  }

  return (
    <StyledTemplateScenario>
      {selectedApplications.length > 0 ?<h5>
        Need inspiration? See how others connect {selectedApplications.reduce((res, app, idx, apps) => `${res}${app?.name}${apps[idx + 1] ? !apps[idx + 2] ? ' and ' : ', ' : ''}`, ' ')}:
      </h5> : null}
      {
        filteredScenarios?.map((scenario, idx) => {
          const applications: (Application | undefined)[] | undefined = scenario.states?.map(state => state.trigger?.application || state.action?.application)

          return (
            <StyledCard style={{ padding: 0, marginBottom: 16 }} key={idx}>
              <Row gutter={16} justify="space-between" align="middle">
                <Col span={3}>
                  <div className="wrapper-icons">
                    <AvatarApplicationGroups applications={applications} />
                  </div>
                </Col>
                <Col flex="auto">
                  <StyledPMedium>
                    {scenario.name}
                  </StyledPMedium>
                  <StyledP2Description>
                    {applications?.map(application => application?.name).join(' + ')}
                  </StyledP2Description>
                </Col>
                <Col flex="0">
                  <Tooltip title={!plan?.isUsingPremiumApps && applications?.filter(app => app?.isPremiumApp).length !== 0 ?
                    `Upgrade to Professional to use ${applications?.filter(app => app?.isPremiumApp).map(app => app?.name).join(', ')} premium app on this template` : ''}>
                    <StyledButton
                      disabled={!plan?.isUsingPremiumApps && applications?.filter(app => app?.isPremiumApp).length !== 0}
                      type="primary" loading={activeId === scenario.id && creatingScenarioFromTemplate} ghost onClick={() => onCreateScenario(scenario)}>Try This</StyledButton>
                  </Tooltip>
                </Col>
              </Row>
            </StyledCard>
          )
        })
      }
      {
        !filteredScenarios ?
          [...new Array(5)].map((_, idx) => <StyledCard style={{ padding: 0, marginBottom: 16 }} key={idx} >
            <Skeleton active={fetchingScenarios} avatar paragraph={{ rows: 1 }} />
          </StyledCard>) : null
      }
      {
        !fetchingScenarios && scenarios && scenarios?.length > 0 && scenarios?.length !== scenariosLength ?
          <StyledButton type="primary" ghost loading={fetchingScenarios} onClick={() => onLoadMore()}>Load More...</StyledButton> :
          null
      }
    </StyledTemplateScenario>
  )
}

export default TemplateScenarios

const StyledTemplateScenario = styled(Layout.Content)`
  ${StyledP2Description}, ${StyledPMedium} {
    margin: 0;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    margin: 0;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 10px;
  }
`