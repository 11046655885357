import { Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useDataSuggestion } from '../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useCreateOrUpdateAuthentication from '../../../../../../hooks/application/authentication/useCreateUpdateAuthentication'
import { StyledAlert, StyledButton, StyledCard, StyledFooterForm } from '../../../../../components/StyledComponents'
import CustomForm from '../../../../components/form/CustomForm'
import FormCodeItem from '../../../../components/form/FormCodeItem'
import FormItem from '../../../../components/form/FormItem'
import FormRequestItem from '../../../../components/form/FormRequestItem'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { PropsContentStep } from '../create/data'
import { useSharedValues } from './SharedValuesContextProvider'

const StepContentForm: FC<PropsContentStep> = ({ onPrevious, onContinue, isActive, info, fields, schemaFields, isSaveOnContinue }) => {
  const [form] = useForm()
  const [values, setValues] = useSharedValues()
  const { applicationId } = useParams<{ applicationId: string }>()
  const [requests, setRequests] = useState<any>()
  const [version] = useApplicationVersionContext()
  const { createOrUpdateAuthentication, creatingAuthentication } = useCreateOrUpdateAuthentication()
  const [valuesForm, setValuesForm] = useState<any>()
  const fieldSchema = values?.fieldSchema
  const [_, setDataSuggestion] = useDataSuggestion()

  useEffect(() => {
    const isFieldTouched = fields.reduce((acc, field) => {
      return acc || form.isFieldTouched(field)
    }, false)
    if (values && !isFieldTouched) {
      form.setFieldsValue(values)
    }
  }, [values])

  useEffect(() => {
    if (fieldSchema && fieldSchema.length > 0) {
      const flatTestData= fieldSchema.reduce((acc, field) => ({
        ...acc,
        [`authData.${field.key}`]: {
          sample: field.default,
          value: `authData.${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      const testData = fieldSchema.reduce((acc, field) => ({
        ...acc,
        [`${field.key}`]: {
          sample: field.default,
          value: `${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      setDataSuggestion(prevState => ({
        ...prevState,
        authData: {
          flatTestData,
          order: 0,
          testData: testData,
          name: 'Auth Data',
          application: undefined
        }
      }))
    }
  }, [fieldSchema])

  const onChangeRequest = (id: string, values: any) => {
    setRequests((prevState: any) => ({ ...prevState, [id]: values }))
  }

  const onFinish = async (allValues: any) => {
    const newValues = fields.reduce((res, field) => {
      if (allValues[field]) {
        return { ...res, [field]: allValues[field] }
      }
      return { ...res }
    }, {})
    setValues(prevState => ({ ...prevState, ...newValues, ...requests }))
    if (isSaveOnContinue) {
      const create = await createOrUpdateAuthentication(applicationId, { ...values, ...requests, ...newValues, isDraft: false, versionId: values?.versionId || version?.id })
      if (create) {
        setValues({ ...values, ...create })
      }
      onContinue()
    } else {
      const create = await createOrUpdateAuthentication(applicationId, { ...values, ...requests, ...newValues, isDraft: values?.isDraft === undefined ? true : values?.isDraft, versionId: values?.versionId || version?.id })
      if (create) {
        setValues({ ...values, ...create })
      }
      onContinue()
    }
  }

  useEffect(() => {
    if (!isActive) {
      const values = form.getFieldsValue()
      const newValues = fields.reduce((res, field) => {
        if (values[field]) {
          return { ...res, [field]: values[field] }
        }
        return { ...res }
      }, {})
      setValues(prevState => ({ ...prevState, ...newValues, ...requests }))
    }
  }, [isActive])

  const onValuesChange = (_: any, allValues: any) => {
    setValuesForm(allValues)
  }

  const isDisabled = fields.reduce((acc, field) => {
    const schemaField = schemaFields.find(sf => sf.name === field)
    const isRequired = schemaField?.required
    const typeField = schemaField?.type
    let isFilled = form.getFieldValue(field) || values?.[field]
    if (typeField === 'http') {
      isFilled = form.getFieldValue(`${field.replace('Id', '')}-url`)
    }

    if (isRequired && !isFilled) {
      return true
    }
    return acc
  }, false)

  return (
    <StyleStepContent className={isActive ? 'show' : undefined}>
      {info ? <><StyledAlert message={<div dangerouslySetInnerHTML={{ __html: info }} />} type="info" /><br/></> : null}
      <CustomForm form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        {
          fields.map(field => {
            const schemaField = schemaFields.find(sf => sf.name === field)

            if (schemaField?.type === 'http') {
              const val: any = values
              const id = field.replace('Id', '')
              const existed = values ? val[id] : undefined
              return <FormRequestItem
                onChange={onChangeRequest}
                valuesForm={valuesForm}
                key={field}
                existedValue={existed}
                isRequired={schemaField.required}
                form={form}
                id={id}
                label={schemaField.label}
                description={schemaField.helpTextTop}
              />
            }
            if (schemaField?.type === 'string') {
              return <FormItem key={field} rules={[{ required: schemaField.required }]} label={schemaField.label} name={field} description={schemaField.helpTextTop} extra={schemaField.helpTextBottom}>
                <Input />
              </FormItem>
            }
            if (schemaField?.type === 'textarea') {
              return <FormItem key={field} rules={[{ required: schemaField.required }]} label={schemaField.label} name={field} description={schemaField.helpTextTop} extra={schemaField.helpTextBottom}>
                <Input.TextArea />
              </FormItem>
            }
            if (schemaField?.type === 'code') {
              return <FormCodeItem key={field} name={field} label={schemaField.label} description={schemaField.helpTextTop} extra={schemaField.helpTextBottom}/>
            }
          })
        }
        <StyledFooterForm>
          {onPrevious ? <StyledButton htmlType="button" onClick={onPrevious}>Previous</StyledButton> : null}
          <StyledButton type="primary" htmlType="submit" disabled={isDisabled} loading={creatingAuthentication} >Continue</StyledButton>
        </StyledFooterForm>
      </CustomForm>
    </StyleStepContent>
  )
}

export default StepContentForm

const StyleStepContent = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;
  .connection-label {
    display: none;
    &.code-mode {
      display: block;
    }
  }
`