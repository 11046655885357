import { Moment } from 'moment'
import { Dispatch, SetStateAction, useState } from 'react'

type RangeValue = [Moment | null, Moment | null] | null


type ValidationDateFilter = {
  isValid: (val: RangeValue) => boolean,
  onOpenChange: (open: boolean) => void,
  disabledDate: (current: Moment) => boolean,
  hackValue: RangeValue,
  value: RangeValue,
  setValue: Dispatch<SetStateAction<RangeValue>>,
  setDates: Dispatch<SetStateAction<RangeValue>>
}

export default function useValidationDateFilter(): ValidationDateFilter {
  const [dates, setDates] = useState<RangeValue>(null)
  const [hackValue, setHackValue] = useState<RangeValue>(null)
  const [value, setValue] = useState<RangeValue>(null)

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7
    return !!tooEarly || !!tooLate
  }

  const isValid = (val: RangeValue) => {
    if (val && val[0] && val[1] && val[1].diff(val[0], 'days') > 7) {
      return false
    }
    return true
  }

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null])
      setDates([null, null])
    } else {
      setHackValue(null)
    }
  }

  return {
    onOpenChange,
    disabledDate,
    hackValue,
    isValid,
    value,
    setValue,
    setDates
  }
}