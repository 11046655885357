import { Scenario, State } from 'db'
import { useState } from 'react'

type ExportScenario = {
  exportScenario: (scenario: Scenario) => void,
  exporting: boolean
}
export default function useExportScenario(): ExportScenario {
  const [exporting, setExporting] = useState(false)

  const exportScenario = (scenario: Scenario) => {
    setExporting(true)
    // based on attributes used for useImportScenario
    const scenarioNeeded: Partial<Scenario> = {
      name: scenario.name,
      states: scenario.states?.map((state) => {
        const { triggerId, actionId, order, name, params, testData, realTestData, conditionGroupOrder, conditions, stateConditionId, id, parentId, childrenIds } = state
        return {
          name: name || state.trigger?.name || state.action?.name, testData, realTestData,
          triggerId, actionId, order, params, conditionGroupOrder, conditions, stateConditionId, id, childrenIds, parentId
        }
      }) as State[]
    }
    const fileName = `scenario_${scenario.name}_${scenario.id}`
    const json = JSON.stringify(scenarioNeeded, null, 2)
    const blob = new Blob([json],{ type:'application/json' })
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = fileName + '.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setExporting(false)
  }

  return {
    exportScenario,
    exporting
  }
}