import { AxiosPromise } from 'axios'
import { LogJob } from 'db'
import { useState } from 'react'
import { Error } from '../../types'
import { useApi } from '../useFetch'

type Result = {
  retryJob: (jobId: number, type?: 'fromBeginning' | 'errorState') => AxiosPromise<any>,
  activeRetryingJobId?: number,
  logJob?: LogJob,
  retryingLogJob: boolean,
  errorRetryLogJob?: Error
}

export default function useRetryLogJob(): Result {
  const { call, isValidating, data, error } = useApi()
  const [jobId, setJobId] = useState<number>()

  return {
    retryingLogJob: isValidating,
    activeRetryingJobId: jobId,
    retryJob: async (jobId, type) => {
      setJobId(jobId)
      return await call({
        url: `/logJob/${jobId}/retry`,
        params: { type },
        method: 'post'
      })
    },
    logJob: data,
    errorRetryLogJob: error
  }
}