import { AnchorHTMLAttributes, FC } from 'react'
import styled from 'styled-components'

interface Props extends AnchorHTMLAttributes<any> {

}

const AnchorWrapper: FC<Props> = ({ children, ...anchorProps }) => {
  return <StyledAnchor onClick={(e) => e.preventDefault()} rel="noopener noreferrer" {...anchorProps} >{children}</StyledAnchor>
}

export default AnchorWrapper

const StyledAnchor = styled.a`
  text-decoration: none;
  width: max-content;
  height: max-content;
  color: inherit;
  &:hover {
    color: inherit;
  }
`