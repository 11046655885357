import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Divider, Form, Input, message, Spin } from 'antd'
import Axios from 'axios'
import Cookies from 'js-cookie'
import React, { FC, useCallback, useEffect, useState } from 'react'
const Recaptcha = require('react-google-invisible-recaptcha').default // ts angy to this lib, import Recaptcha from 'react-google-invisible-recaptcha'
import { Link, useHistory } from 'react-router-dom'
import useSyncLoggedIn from 'src/util/useSyncLogin'
import styled from 'styled-components'
import SignLayout, { StyledText as BaseStyledText, StyledTitle } from '../../components/SignLayout'
import Button from '../../components/StyledButton'
import { CLIENT_ID_GOOGLE, CLIENT_ID_WEB, OTORISASI_API_URL, SITE_KEY_RECAPTCHA } from '../../helpers/Constant'
import { encrypt } from '../../helpers/Crypto'
import { routingFlow } from '../../helpers/RoutingFlow'

const Login: FC = () => {
  // console.log('Login page')
  const history = useHistory()
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [captcha, setCaptcha] = useState<any>()
  const [hideAdditionalLoginOption, setHideAdditionalLoginOption] = useState<boolean>(true)
  const isAuthorized = Cookies.get('loggedIn')
  useSyncLoggedIn()

  useEffect(() => {
    if (isAuthorized) {
      console.info('Already Logged in')
      history.replace('/app')
    }
  }, [isAuthorized])


  const _routingFlow = useCallback(async (user?: any) => {
    setLoading(false)
    await routingFlow(history, window.location.search ? new URLSearchParams(window.location.search) : null, user)
  }, [history])

  useEffect(() => {
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/clients`, { params: { clientId: CLIENT_ID_WEB } })
      .then(({ data }) => {
        Axios.get(`${OTORISASI_API_URL}/api/auth/v1/application/${data.clients[0].applicationId}`)
          .then(({ data }) => {
            setHideAdditionalLoginOption(!data.application.enabledSignup)
          })
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/user/me`, { withCredentials: true })
      .then(({ data }) => _routingFlow(data.user))
      .catch(() => setLoading(false))
  }, [history, _routingFlow])

  const login = async () => {
    if (new Date().getMonth() === 3 && new Date().getDate() === 1 && localStorage.getItem('yeay') !== new Date().getFullYear().toString()) {
      message.error('Error: 418. I\'m a teapot.')
      await new Promise(res => setTimeout(res, 4500))
      message.info('Just kidding. Happy april fool\'s day!')
      await new Promise(res => setTimeout(res, 3500))
      message.info('How was your day? Is the apocalypse already out there?', 4)
      await new Promise(res => setTimeout(res, 4500))
      message.info('Sorry to interupt you. I\'m lonely :(')
      await new Promise(res => setTimeout(res, 3500))
      message.info('OK! Go on. Bye~')
      await new Promise(res => setTimeout(res, 3500))
      localStorage.setItem('yeay', new Date().getFullYear().toString())
    }

    try {
      setLoading(true)

      const token = await captcha?.execute()
      const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/google/recaptcha`, { token }, { withCredentials: true })
      if (!data?.isValid && !window.location.toString().includes('localhost') && !window.location.toString().includes('githubpreview.dev')) throw new Error()
    } catch (error) {
      setLoading(false)
      return message.error('Robot detected!')
    }

    let token: any
    try {
      token = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/token`, {
        grantType: 'password',
        clientId: CLIENT_ID_WEB,
        username,
        password: encrypt(password as string)
      }, { withCredentials: true })

      if (!token.data.auth.need2fa) {
        Cookies.set('authorized', 'true')
      }

      if (process.env.NODE_ENV === 'development') {
        Cookies.set('authorization', `Bearer%20${token.data.auth.token}`)
      }
      Cookies.set('loggedIn', 'true')
      localStorage.setItem('login', Date.now().toString())
    } catch (error) {
      setLoading(false)
      if (new Date().getMonth() === 3 && new Date().getDate() === 1) {
        return message.error('Huh?')
      }
      return message.error((error as any)?.response?.data?.error || 'Something wrong')
    }

    const params = new URLSearchParams(window.location.search)
    if (!params.get('installationId') && !params.get('clientId') && params.get('redirectUrl')) {
      message.success(`Redirecting to ${params.get('ref')}...`)
    }

    _routingFlow({ isEnabled2fa: token.data.auth.need2fa })
  }

  const googleLogin = async () => {
    const { data } = await Axios.get(`${OTORISASI_API_URL}/api/auth/v1/google/authUrl`, {
      params: {
        clientId: CLIENT_ID_GOOGLE,
        ...window.location.search ? { state: decodeURIComponent(window.location.search) } : {}
      }
    })
    window.location.href = data?.auth?.google
  }

  return (
    <SignLayout>
      <Spin spinning={loading}>
        <Card bordered={false}>
          <Form onFinish={login} layout="vertical" requiredMark="optional">
            <StyledTitle>Selamat Datang Kembali</StyledTitle>
            <StyledText>Silakan masuk dan mulai membuat pekerjaan kamu lebih Otomatis!</StyledText>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Email cannot be blank' }]}>
              <Input placeholder="Email kamu" prefix={<UserOutlined />} value={username} onChange={e => setUsername(e.target.value)} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Password cannot be blank' }]}>
              <Input.Password placeholder="Password kamu" prefix={<LockOutlined />} value={password} onChange={e => setPassword(e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button style={{ float: 'right', background: '#1256DF' }} type="primary" htmlType="submit">Login</Button>
              <p style={{ paddingTop: '5px' }}><Link to="/auth/forgotPassword" style={{ color: '#1256DF' }}>Lupa Password?</Link></p>
            </Form.Item>

            <Recaptcha
              ref={(ref: any) => setCaptcha(ref)}
              sitekey={SITE_KEY_RECAPTCHA}
              badge="bottomleft" />
          </Form>
          <StyledDivider plain dashed style={{ color: '#BAC4CD' }}>Atau</StyledDivider>
          <p style={{ marginBottom: '10px' }}>
            <Button icon={<img src="/misc/google-icon.svg" alt="google" width="14px" />} block style={{ display: 'grid',gridTemplateColumns: 'max-content auto', alignItems: 'center' }} onClick={googleLogin}>Sign in with Google</Button>
          </p>
          {hideAdditionalLoginOption ? <br /> : <StyledText2>Belum memiliki akun? <Link to="/auth/signup" style={{ color: '#1256DF' }}>Daftar Disini</Link></StyledText2>}
          {/* <p style={{ marginBottom: '10px' }}>
            <Button icon={<GithubOutlined />} block style={{ display: 'grid',gridTemplateColumns: 'max-content auto', alignItems: 'center' }} onClick={githubLogin}>Sign in with GitHub</Button>
          </p> */}

        </Card>
      </Spin>
    </SignLayout>
  )
}

export default Login

const StyledText = styled(BaseStyledText)`
  color: #5E6C84;
`
const StyledText2 = styled(BaseStyledText)`
  color: #8993A4;
`
const StyledDivider = styled(Divider)`
  text-align: center;
  .ant-divider-inner-text {
      /* P1- Strong */
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: rgba(0, 0, 0, 0.65);
  }
`