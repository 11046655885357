import { AxiosPromise } from 'axios'
import { Authentication } from 'db'
import { PublicConfiguration } from 'swr/dist/types'
import { Error } from '../../../types'
import useFetch, { useApi } from '../../useFetch'


type Result = {
  revalidateAuthentications: () => void,
  authentications?: Authentication[],
  fetchingAllAuthentications: boolean,
  errorFetchAllAuthentication?: Error
}

export default function useAllAuthentication(applicationId?: number | string, versionId?: number | null, config?: Partial<PublicConfiguration<any>>): Result {
  const url = applicationId ? `/application/${applicationId}/authentications${versionId ? `?versionId=${versionId}&expands=testHttpRequest,authHttpRequest,reqTokenHttpRequest,refreshTokenHttpRequest` : ''}` : null
  const { data, error, isValidating, revalidateFetch } = useFetch<{ authentications: Authentication[] }>(url, {}, { revalidateOnFocus: true, ...config })

  return {
    revalidateAuthentications: () => revalidateFetch(url),
    authentications: data?.authentications,
    fetchingAllAuthentications: isValidating,
    errorFetchAllAuthentication: error
  }
}

type ApiAllAuthentications = {
  authentications?: Authentication[],
  fetchingAllAuthentications: boolean,
  getAllAuthentications: (applicationId: number, params?: Record<string, any>) => AxiosPromise<{ authentications: Authentication[] }>
}
export function useApiAllAuthentications(): ApiAllAuthentications {
  const { call, isValidating, data } = useApi<{ authentications: Authentication[] }>()

  const getAllAuthentications = (applicationId: number, params?: Record<string, any>) => {
    return call({
      url: `/application/${applicationId}/authentications`,
      params: params
    })
  }
  return {
    getAllAuthentications,
    authentications: data?.authentications,
    fetchingAllAuthentications: isValidating
  }
}