import { HttpRequest, Trigger } from 'db'
import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import Notification from '../../../util/Notification'

type Result = {
  creatingTrigger?: boolean,
  createOrUpdateTrigger: (applicationId: number | string, payload: Partial<Trigger>) => Promise<Partial<Trigger> | undefined>
}

export default function useCreateOrUpdateTrigger(): Result {
  const [isValidating, setIsValidating] = useState(false)

  const createOrUpdateHttpRequest = async (payload: HttpRequest, id?: number | null) => {
    const urlHttpRequest = '/httpRequest'
    let result: HttpRequest | undefined
    if (!payload.url) {
      return {} as HttpRequest
    }
    if (id) {
      result = await Implikasi.patch(`${urlHttpRequest}/${id}`, {
        httpRequest: payload
      }).then(({ data }) => {
        return data.httpRequest
      }).catch((e) => {
        const error = JSON.stringify(e?.response?.data?.error)
        Notification.error({
          message: 'Something\'s Wrong',
          description: error
        })

      })
      return result
    }
    result = await Implikasi.post(urlHttpRequest, {
      httpRequest: payload
    }).then(({ data }) => {
      return data.httpRequest
    }).catch((e) => {
      const error = JSON.stringify(e?.response?.data?.error)
      Notification.error({
        message: 'Something\'s Wrong',
        description: error
      })

    })
    return result
  }



  const createOrUpdate = async (applicationId: number | string, payload: Partial<Trigger>) => {
    setIsValidating(true)
    let newTrigger: Partial<Trigger> = payload
    let error: string | undefined

    const createAllHttpRequest = async () => {
      const { dummyTriggerHttpRequest, dummyTriggerHttpRequestId, httpRequest, httpRequestId, subscribeHttpRequest, subscribeHttpRequestId, unsubscribeHttpRequest, unsubscribeHttpRequestId } = payload
      if (httpRequest) {
        const created = await createOrUpdateHttpRequest(httpRequest, httpRequestId)
        if (created) {
          newTrigger = { ...newTrigger, httpRequestId: created?.id || null }
        } else {
          error = 'error on create or update http request'
        }
      }
      if (subscribeHttpRequest) {
        const created = await createOrUpdateHttpRequest(subscribeHttpRequest, subscribeHttpRequestId)
        if (created) {
          newTrigger = { ...newTrigger, subscribeHttpRequestId: created?.id || null }
        } else {
          error = 'error on create or update http request'
        }
      }
      if (unsubscribeHttpRequest) {
        const created = await createOrUpdateHttpRequest(unsubscribeHttpRequest, unsubscribeHttpRequestId)
        if (created) {
          newTrigger = { ...newTrigger, unsubscribeHttpRequestId: created?.id || null }
        } else {
          error = 'error on create or update http request'
        }
      }
      if (dummyTriggerHttpRequest) {
        const created = await createOrUpdateHttpRequest(dummyTriggerHttpRequest, dummyTriggerHttpRequestId)
        if (created) {
          newTrigger = { ...newTrigger, dummyTriggerHttpRequestId: created?.id || null }
        } else {
          error = 'error on create or update http request'
        }
      }
    }

    if (applicationId) {
      if (payload.id) {
        // update
        // update | create smua httpRequest
        await createAllHttpRequest()
        // update trigger
        if (!error) {
          const { idempotencyKeyMap, isGlobalAggregator, isUsingPagination, isDraft, isAggregator, similarityThreshold, testGuideline, webhookResponseFunc, dummyTriggerHttpRequestId, subscribeHttpRequestId, unsubscribeHttpRequestId, visibility, isAsc, versionId, examplePayload, name, paramSchema, stateKeyMap, type, authenticationId, httpRequestId, findLastStateFunc, description, condition } = newTrigger
          const trigger = await Implikasi.patch(`/application/${applicationId}/trigger/${newTrigger.id}?versionId=${versionId}`,
            {
              trigger: {
                idempotencyKeyMap, isGlobalAggregator, isUsingPagination, isDraft, isAggregator, similarityThreshold, testGuideline, webhookResponseFunc, dummyTriggerHttpRequestId, subscribeHttpRequestId, unsubscribeHttpRequestId, visibility, isAsc, versionId, examplePayload, name, paramSchema, stateKeyMap, type, authenticationId, httpRequestId, findLastStateFunc, description, condition
              }
            })
            .then(({ data }) => {
              return data.trigger
            })
            .catch((e) => {
              const error = JSON.stringify(e?.response?.data?.error)
              Notification.error({
                message: 'Something\'s Wrong',
                description: error
              })
            })
          if (trigger) {
            newTrigger = { ...newTrigger, id: trigger.id }
            setIsValidating(false)
            return newTrigger
          }
        }

      } else {
        // create
        // create | update httpRequest
        await createAllHttpRequest()
        // create trigger
        if (!error) {
          const { idempotencyKeyMap, isGlobalAggregator, isUsingPagination, isDraft, isAggregator, similarityThreshold, testGuideline, webhookResponseFunc, dummyTriggerHttpRequestId, subscribeHttpRequestId, unsubscribeHttpRequestId, visibility, isAsc, versionId, examplePayload, name, paramSchema, stateKeyMap, type, authenticationId, httpRequestId, findLastStateFunc, description, condition  } = newTrigger
          const trigger: Trigger = await Implikasi.post(`/application/${applicationId}/trigger?versionId=${versionId}`,
            {
              trigger: {
                idempotencyKeyMap, isGlobalAggregator, isUsingPagination, isDraft, isAggregator, similarityThreshold, testGuideline, webhookResponseFunc, dummyTriggerHttpRequestId, subscribeHttpRequestId, unsubscribeHttpRequestId, visibility, isAsc, versionId, examplePayload, name, paramSchema, stateKeyMap, type, authenticationId, httpRequestId, findLastStateFunc, description, condition
              }
            })
            .then(({ data }) => {
              return data.trigger
            })
            .catch((e) => {
              const error = JSON.stringify(e?.response?.data?.error)
              Notification.error({
                message: 'Something\'s Wrong',
                description: error
              })

            })
          if (trigger) {
            newTrigger = { ...newTrigger, id: trigger.id }
            setIsValidating(false)
            return newTrigger
          }
        }
      }
    } else {
      Notification.error({
        message: 'Something\'s wrong',
        description: 'Failed, no application spesified'
      })
    }
    setIsValidating(false)
  }

  return {
    creatingTrigger: isValidating,
    createOrUpdateTrigger: async (applicationId: number | string, payload: Partial<Trigger>) => {
      return createOrUpdate(applicationId ,payload)
    }
  }
}