import { FolderOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { FC } from 'react'
import { useDragLayer } from 'react-dnd'
import styled from 'styled-components'
import ButtonDragHandle from '../../../../ButtonDragHandle'
import { StyledContainerItem, StyledP } from './siderPane/FolderTab'

const ScenarioAndFolderDragLayer: FC = () => {
  const { currentOffset, isDragging, itemType, item } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  )

  if (!isDragging) {
    return null
  }

  return (
    <Layer>
      <div
        style={{
          display: currentOffset ? 'block' : 'none',
          transform: currentOffset ? `translate(${currentOffset.x + 10}px, ${currentOffset.y - 10}px)` : 'unset',
          WebkitTransform: currentOffset ? `translate(${currentOffset.x + 10}px, ${currentOffset.y - 10}px)` : 'unset'
        }}
      >
        <FieldContainerStyle isDragging={isDragging}>
          {/* <FieldStyle>{item?.scenarios?.length} move</FieldStyle> */}
          {
            itemType === 'scenario' ?
              <FieldStyle>Move {item.scenarios.length} scenario</FieldStyle>
              :
              null
          }
          {
            itemType === 'folder' ?
              <StyledContainerItem isDragging style={{ minWidth: 200 }}>
                <Space>
                  <ButtonDragHandle />
                  <FolderOutlined />
                  <StyledP>{item.folder?.name}</StyledP>
                </Space>
              </StyledContainerItem> :
              null
          }
        </FieldContainerStyle>
      </div>
    </Layer>
  )
}

export default ScenarioAndFolderDragLayer

const Layer = styled.div`
  position: fixed;
  pointer-events: none;
  z-Index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`

const FieldContainerStyle = styled.div<{ isDragging: boolean }>`
  width: max-content;
  opacity: ${({ isDragging }) => isDragging ? 0.8 : 1};
`

const FieldStyle = styled.div`
  border-width: 1;
  height: 30;
  margin: 5;
  padding: 0 4px;
  border-radius: 5px;
  background-color: black;
  color: white;
`