import { MailOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, message } from 'antd'
import Axios from 'axios'
import React, { useState } from 'react'
const Recaptcha = require('react-google-invisible-recaptcha').default // ts angy to this lib, import Recaptcha from 'react-google-invisible-recaptcha'
import { Link } from 'react-router-dom'
import SignLayout, { StyledText, StyledTitle } from '../../components/SignLayout'
import { CLIENT_ID_WEB, OTORISASI_API_URL, SITE_KEY_RECAPTCHA } from '../../helpers/Constant'

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>()
  const [captcha, setCaptcha] = useState<any>()

  const send = async () => {
    try {
      const token = await captcha?.execute()
      const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/google/recaptcha`, { token }, { withCredentials: true })
      if (!data?.isValid) throw new Error()
    } catch (error) {
      return message.error('Robot detected!')
    }

    try {
      await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/forgotPassword`, {
        email, clientId: CLIENT_ID_WEB
      }, { withCredentials: true })
      return message.info('Please check your email and follow the link')
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <SignLayout>
      <Card bordered={false}>
        <Form onFinish={send}>
          <StyledTitle>Forgot Password</StyledTitle>
          <StyledText>We’ll send you a confirmation link, kindly put your email address below</StyledText>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Email cannot be blank' }]}>
            <Input type="email" placeholder="Your Email" prefix={<MailOutlined />} value={email} onChange={e => setEmail(e.target.value)} />
          </Form.Item>

          <Recaptcha
            ref={(ref: any) => setCaptcha(ref)}
            sitekey={SITE_KEY_RECAPTCHA}
            badge="bottomleft" />

          <Form.Item style={{ textAlign: 'center' }}>
            <Button style={{ width: '100%', background: '#1256DF' }} type="primary" htmlType="submit">Send</Button>
          </Form.Item>
          <Link style={{ color: '#1256DF' }} to="/auth/login">Oh shit, I remember now!</Link>
        </Form>
      </Card>
    </SignLayout>
  )
}

export default ForgotPassword