import { Scenario } from 'db'
import { FC, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import ButtonDragHandle from '../../../../ButtonDragHandle'
interface Props {
  scenario: Scenario,
  selectedScenarios?: Scenario[]
}
const DragHandleScenario: FC<Props> = ({ scenario, selectedScenarios }) => {
  const [_, drag, preview] = useDrag(
    () => ({
      type: 'scenario',
      item: { scenarios: selectedScenarios && selectedScenarios.find((currScenario) => currScenario.id === scenario.id) ? selectedScenarios : [scenario] },
      collect: (monitor) => {
        return {
          opacity: monitor.isDragging() ? 1 : 0,
          isDragging: monitor.isDragging(),
          draggingItem: monitor.getItem(),
        }
      },
    }),
    [scenario, selectedScenarios],
  )

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    })
  }, [])

  return (
    <ButtonDragHandle ref={drag} />
  )
}

export default DragHandleScenario