import { Application } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  archivedapplication?: Application,
  archivingApplication: boolean,
  errorArchiveApplications?: Error,
  archiveApplication: (applicationId: number) => void
}

export default function useArchiveApplication(): Result {
  const { data, error, isValidating, call } = useApi<{ application: Application }>()

  return {
    archivedapplication: data?.application,
    archivingApplication: isValidating,
    errorArchiveApplications: error,
    archiveApplication: (applicationId: number) => {
      call({
        url: `/application/${applicationId}/archive`,
        method: 'delete',
      })
    }
  }
}