import { Spin, Tag } from 'antd'
import { FC } from 'react'
import useAScenario from 'src/hooks/useAScenario'
import AvatarApplicationGroups from '../../AvatarApplicationGroups'

interface Props {
  scenarioId: number,
  type: 'name' | 'applications' | 'listApplications'
}

const ScenarioDetailRow: FC<Props> = ({ scenarioId, type }) => {
  const { scenario, fetchingScenario } = useAScenario(scenarioId, true, true)
  const applications = scenario?.states?.sort((s1, s2) => s1.order - s2.order).map(state => (state.trigger || state.action)?.application)
  return (
    <>
      {
        fetchingScenario ? <Spin spinning /> :
          type === 'name' ?
            <>{scenario?.name} <Tag color="default">ID: {scenarioId}</Tag> {scenario?.deletedAt ? <Tag color="orange">Archived Scenario</Tag> : null}</>
            :
            type === 'listApplications' ?
              <ul>
                {applications?.map((application, idx) => <li key={idx}>{application?.name}</li>)}
              </ul> :
              <>
                <AvatarApplicationGroups applications={applications} />
              </>
      }
    </>
  )
}

export default ScenarioDetailRow