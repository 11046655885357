import { FormProps, Form as BaseForm } from 'antd'
import { FC } from 'react'

interface Props extends FormProps {
}

const CustomForm: FC<Props> = ({ children, ...res }) => {

  return(
    <BaseForm requiredMark="optional" layout="vertical" {...res}>
      {children}
    </BaseForm>
  )
}

export default CustomForm

