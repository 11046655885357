import { CheckOutlined, ApiOutlined, ExclamationCircleOutlined, LockOutlined, NotificationOutlined, ThunderboltOutlined, UserAddOutlined } from '@ant-design/icons'
import { Badge, Layout, List } from 'antd'
import { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import AnchorWrapper from '../../../../../components/AnchorWrapper'
import useAllAction from '../../../../../hooks/application/action/useAllAction'
import useAllAuthentication from '../../../../../hooks/application/authentication/useAllAuthentication'
import useApplication from '../../../../../hooks/application/main/useApplication'
import useSubmitApplicationToPublic from '../../../../../hooks/application/main/useSubmitApplicationToPublic'
import useUpdateApplication from '../../../../../hooks/application/main/useUpdateApplication'
import useAllTrigger from '../../../../../hooks/application/trigger/useAllTrigger'
import useAllAuthenticationSchema from '../../../../../hooks/authenticationSchema/useAllAuthenticationSchema'
import { success } from '../../../../../util/colors'
import Notification from '../../../../../util/Notification'
import PageHeader from '../../../../components/PageHeader'
import { StyledButton } from '../../../../components/StyledComponents'
import { useApplicationVersionContext } from '../../contexts/ApplicationVersionContextProvider'
import { baseApplicationRoute } from '../../routes'

const Step: FC = () => {
  const { push } = useHistory()
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()
  const { application, fetchingApplication } = useApplication(applicationId)
  const [version] = useApplicationVersionContext()
  const { triggers } = useAllTrigger(applicationId, version?.id)
  const { actions } = useAllAction(applicationId, version?.id)
  const { updateApplication, updatingApplication } = useUpdateApplication()
  const [isPublishing, setIsPublishing] = useState(false)
  const { submitApplicationToPublic } = useSubmitApplicationToPublic()
  const { authentications } = useAllAuthentication(applicationId, version?.id)
  const { authenticationSchemas } = useAllAuthenticationSchema()

  const onClickStep = (to: string) => {
    if (to === 'submit-app') {
      updateApplication(applicationId, {
        submittedAt: new Date()
      }).then(() => {
        Notification.success({
          message: 'Application has successfully submitted to get review'
        })
        push(`${baseApplicationRoute}/${applicationId}/${versionName}/main/submit-app`)
      })
    } else if (to === 'publish') {
      setIsPublishing(true)
      const preActiveVersion = application?.versions?.find(v => v.isActive)?.id
      if (version?.id) {
        submitApplicationToPublic(Number(applicationId), version?.id, preActiveVersion)
          .then(() => setIsPublishing(false))
      }
    } else {
      push(`${baseApplicationRoute}/${applicationId}/${versionName}/main/${to}`)
    }
  }

  const authenticationName = authenticationSchemas?.find(authSchema => authSchema.id === authentications?.[0]?.authenticationSchemaId)?.name
  const options = [
    {
      buttonStyle: authenticationName ? { background: success, borderColor: success } : undefined,
      title: 'Authentication',
      description: 'Tell us how to authenticate users with your API. OAuth 2 is most common, but Implikasi also supports a handful of options.',
      buttonText:  authenticationName ? <><CheckOutlined /> {authenticationName}</> : 'Set Up Authentication',
      to: 'authentication',
      icon: <UserAddOutlined />
    },
    {
      title: 'Triggers',
      description: 'Help users find new data as soon as it’s available in your app. Triggers connect to webhooks or polling endpoints.',
      buttonText: 'Create Trigger',
      to: 'trigger',
      icon: <ApiOutlined />
    },
    {
      title: 'Actions',
      description: 'Help users write data back to your application. Actions create or update records through your API.',
      buttonText: 'Create Action',
      to: 'action',
      icon: <ThunderboltOutlined />
    },
    {
      title: 'Validate Applications',
      description: 'Check your project for errors, and get a list of remaining items that are required before you can make your application available to everyone.',
      buttonText: 'Run Validation',
      to: 'submit-app',
      loading: updatingApplication,
      disabled: triggers?.length === 0 && actions?.length === 0,
      icon: <ExclamationCircleOutlined />
    },
    {
      title: 'Publish',
      description: 'When you’re ready, let our review team know you want to take your application public.',
      buttonText: 'Start Publish',
      to: 'publish',
      disabled: !application?.publishedAt,
      loading: isPublishing && updatingApplication && fetchingApplication,
      icon: <NotificationOutlined />
    },
  ]
  return (
    <StyledStepPage>
      <PageHeader
        title="Application Steps"
        description="Follow these steps to create your own Application"
      />
      <List
        dataSource={options}
        renderItem={(item) => {
          const { title, icon, buttonText, description, to, disabled, loading, buttonStyle } = item
          return (
            <List.Item
              key={title}
              actions={[
                disabled ? <LockOutlined key="disabled" /> : null,
                to !== 'publish' && to !== 'submit-app' ?
                  <AnchorWrapper href={`${baseApplicationRoute}/${applicationId}/${versionName}/main/${to}`}>
                    <StyledButton style={buttonStyle} loading={loading} key="to" onClick={() => onClickStep(to)} type="primary" disabled={disabled} >{buttonText}</StyledButton>
                  </AnchorWrapper> :
                  <StyledButton style={buttonStyle} loading={loading} key="to" onClick={() => onClickStep(to)} type="primary" disabled={disabled} >{buttonText}</StyledButton>
              ]}
            >
              <List.Item.Meta
                avatar={<StyledBadge count={icon}>
                  <div className="head" />
                </StyledBadge>}
                title={title}
                description={description}
              ></List.Item.Meta>
            </List.Item>
          )
        }}
      >
      </List>
    </StyledStepPage>
  )
}

export default Step

const StyledBadge = styled(Badge)`
  margin-top: 16px;
  .head {
    width: 25px;
    height: 25px;
    background: #DEF5FF;
    border-radius: 100%;
  }
  .ant-scroll-number-custom-component {
    top: 5px;
    right: 5px;
    svg {
      font-size: 20px;
    }
  }
`
const StyledStepPage = styled(Layout.Content)`
  .ant-list {
    padding: 16px 24px;
    .ant-list-item {
      background: #FFF;
      margin-bottom: 8px;
      padding: 16px;
      border: 1px solid #DCE6FA;
      box-sizing: border-box;
      border-radius: 4px;
      .ant-list-item-meta-title {
        /* H5 - 16 */
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
      }
      .ant-list-item-meta-description {
        /* P1 - 14 */
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`