import { FormInstance } from 'antd'
import { Authentication, FieldSchema } from 'db'
import flatten from 'flat'
import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import useAllGlobalVariable from 'src/hooks/application/globalVariable/useAllGlobalVariable'
import { DataSuggestion } from '../DataPickerPopper'


type ContextValueType = {
  [key: string]: DataSuggestion
}


const DataSuggestionContext = createContext<ContextValueType | null>(null)
const DataSuggestionSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode
}

const DataSuggestionContextProvider: FC<Props> = ({ children }) => {
  const [dataSuggestion, setDataSuggestion] = useState<ContextValueType | null>(null)

  return (
    <DataSuggestionContext.Provider value={dataSuggestion}>
      <DataSuggestionSetterContext.Provider value={setDataSuggestion}>
        {children}
      </DataSuggestionSetterContext.Provider>
    </DataSuggestionContext.Provider>
  )
}

export function useDataSuggestion(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(DataSuggestionContext)
  const setterContext = useContext(DataSuggestionSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useDataSuggestion must be used on child component of DataSuggestionContextProvider component'
  }

  return [valueContext, setterContext]
}

export function useUpdateDataSuggestions(bundles?: Record<string, any>, authentication?: Authentication, formAccount?: FormInstance, paramSchema?: FieldSchema[], resultTest?: Record<string, any>): void {
  const [dataSuggestion, setDataSuggestion] = useDataSuggestion()

  useEffect(() => {
    let suggestion: { [key: string]: DataSuggestion} | null = dataSuggestion
    if (paramSchema && paramSchema.length > 0) {
      //
      const flatTestData= paramSchema.reduce((acc, field) => {
        if (!field.key) {
          return acc
        }
        return { ...acc,
          [`params.${field.key}`]: {
            sample: field.default,
            value: `params.${field.key}`,
            label: `${field.key}`,
          } }
      }, {})
      const testData = paramSchema.reduce((acc, field) => {
        if (!field.key) {
          return acc
        }
        return { ...acc,
          [`${field.key}`]: {
            sample: field.default,
            value: `${field.key}`,
            label: `${field.key}`,
          } }
      }, {})
      suggestion ={
        params: {
          flatTestData,
          order: 0,
          testData: testData,
          name: 'Params',
          application: undefined
        }
      }
    }
    if (authentication?.fieldSchema && authentication?.fieldSchema.length > 0) {
      let flatAuthData: any = {}
      if (bundles?.authData) {
        flatAuthData = flatten(bundles.authData)
        formAccount?.setFieldsValue(bundles.authData)
      }
      const flat = authentication?.fieldSchema?.reduce((acc, field) => ({
        ...acc,
        [`authData.${field.key}`]: {
          sample: flatAuthData?.[field.key],
          value: `authData.${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      suggestion = {
        ...suggestion,
        authData: {
          name: 'Auth Data',
          flatTestData: flat,
          testData: bundles?.bundle
        }
      }
    }
    if (bundles?.bundle) {
      const flatBundle: any = flatten(bundles.bundle)
      const flat = Object.keys(flatBundle || {}).reduce((acc, key) => ({
        ...acc,
        [`bundle.${key}`]: {
          sample: flatBundle?.[key],
          value: `bundle.${key}`,
          label: `${key}`,
        }
      }), {})
      suggestion = {
        ...suggestion,
        bundle: {
          name: 'Bundle',
          flatTestData: flat,
          testData: bundles.bundle
        }
      }
    }
    const res = resultTest?.data || resultTest
    const dataTest =  Array.isArray(res) ? res?.[0] : res
    if (dataTest) {
      const flatData: any = flatten(dataTest)
      const flat = Object.keys(flatData).reduce((acc, key) => ({
        ...acc,
        [`data.${key}`]: {
          sample: flatData?.[key],
          value: `data.${key}`,
          label: `${key}`,
        }
      }), {})
      suggestion = {
        ...suggestion,
        data: {
          name: 'Data',
          flatTestData: flat,
          testData: dataTest
        }
      }
    }
    setDataSuggestion(prevState => ({ ...prevState, ...suggestion }))
  }, [bundles, authentication, paramSchema, resultTest])

}

export function useAddGlobalVariableToDataSuggestions(applicationId?: number | string, versionId?: number): void {
  const { globalVariables } = useAllGlobalVariable(applicationId, versionId)
  const [_dataSuggestion, setDataSuggestion] = useDataSuggestion()
  useEffect(() => {
    if (globalVariables && globalVariables.length > 0) {
      const constantsVariables = globalVariables.filter((globalVar) => globalVar.type !== 'function')
      const testData = constantsVariables.reduce((res, curr) => {
        return {
          ...res,
          [curr.name]: curr.globalVariableValue?.constant
        }
      }, {})
      const flatTest = constantsVariables.reduce((res, curr) => {
        return {
          ...res,
          [`globalVar.${curr.name}`]: {
            sample: curr.globalVariableValue?.constant,
            value: `globalVar.${curr.name}`,
            label: `${curr.name}`,
          }
        }
      }, {})
      setDataSuggestion(prevState => ({
        ...prevState,
        globalVar: {
          name: 'Global Variables',
          flatTestData: flatTest,
          testData: testData
        }
      }))
    }
  }, [globalVariables])
}

export default DataSuggestionContextProvider