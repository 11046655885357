import { CheckCircleFilled, CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { State } from 'db'
import { FC, memo, useEffect } from 'react'
import { Connection, Edge, Handle, Node, NodeProps, Position, useReactFlow } from 'react-flow-renderer'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { StyledBoxIcon } from 'src/pages/components/StyledComponents'
import { danger, lime, red, success } from 'src/util/colors'
import { getInitialName } from 'src/util/formatter'
import styled from 'styled-components'
import { detailStateAtomFamily, selectedStateAtomFamily, statusStateSelectorFamily } from './recoil'
import useSyncConnectionStates from './useSyncConnectionStates'



const StateNode: FC<NodeProps<State>> = ({ data, selected }) => {
  const { getEdges, getNodes, setNodes, setEdges } = useReactFlow()
  const setSelected = useSetRecoilState(selectedStateAtomFamily({ scenarioId: `${data.scenarioId}` }))
  const logoUrl = (data.action || data.trigger)?.application?.logoUrl
  const triggerActionName = (data.action || data.trigger)?.name
  const applicationName = (data.action || data.trigger)?.application?.name
  const desc = `${data?.trigger ? 'Trigger' : 'Action'} — ${applicationName}: ${triggerActionName}`
  const setDetailState = useSetRecoilState(detailStateAtomFamily({ stateId: data.id }))
  const { onDeleteEdges, onDeleteNodes } = useSyncConnectionStates()

  useEffect(() => {
    setDetailState(data)
  }, [data])

  useEffect(() => {
    if (selected) {
      setSelected(data)
    }
  }, [selected])

  const onClickDelete = () => {
    const edges = getEdges()
    const nodes = getNodes()
    onDeleteEdges(edges.filter(edge => edge.target == `${data.id}` || edge.source === `${data.id}`), edges, nodes)
    onDeleteNodes(nodes.filter(node => node.id === `${data.id}`), edges, nodes)
    setEdges(prevState => prevState.filter(edge => edge.target !== `${data.id}` && edge.source !== `${data.id}`))
    setNodes(prevState => prevState.filter(node => node.id !== `${data.id}`))
  }

  return (
    <>
      {
        data.trigger ? null :
          <Handle
            position={Position.Top}
            type="target"
            style={{ width: 12, height: 12, background: red[3] }}
            isValidConnection={() => {
              return false
            }}
          />
      }
      <ContainerNode selected={selected}>
        <StyledBoxIcon>
          {
            logoUrl ?
              <img src={logoUrl} /> : getInitialName(data.name || triggerActionName)
          }
        </StyledBoxIcon>
        <div>
          <Desc title={desc}>{desc}</Desc>
          <Name title={data.name} >{data.name}</Name>
        </div>
        <StatusState stateId={data.id} />
        {
          data.trigger ? null :
            <StyledButton onClick={onClickDelete}>
              <CloseOutlined style={{ fontSize: 12 }} />
              {/* <DeleteFilled style={{ fontSize: 12 }} /> */}
            </StyledButton>
        }
      </ContainerNode>
      <Handle
        position={Position.Bottom}
        type="source"
        style={{ width: 12, height: 12, background: lime[3] }}
        isValidConnection={(connection) => {
          return isValidConnection(connection, getNodes(), getEdges())
        }}
      />
    </>
  )
}

export default memo(StateNode)

export function isValidConnection(connection: Connection, nodes: Node<State>[], edges: Edge[], oldEdge?: Edge): boolean {
  const { target, source } = connection
  // if target === source
  if (target === source) return false
  // if target has already connected: false
  if (edges.find(edge => edge.target === target)) {
    return false
  }
  const existedConnections = edges.filter(edge => edge.source === source)
  const firstTarget = existedConnections[0]?.target

  if (oldEdge) {
    const anyConnectedToPath = existedConnections.reduce((res, edge) => {
      if (edge.target === oldEdge.target) return res
      const type = nodes.find(node => node.id === edge.target)?.data.action?.type
      return res || type === 'path'
    }, false)
    const targetType = nodes?.find(node => `${node.id}` === target)?.data.action?.type
    if (anyConnectedToPath) {
      return targetType === 'path'
    } else {
      return true
    }
  }

  if (firstTarget) {
    const firstTargetType = nodes.find(node => node.id === firstTarget)?.data.action?.type
    if (firstTargetType === 'path') {
      const targetType = nodes?.find(node => `${node.id}` === target)?.data.action?.type
      if (targetType === 'path') {
        return true
      }
      // if source has already connected with action.type === path && target.action.type !== path
      return false
    }
    // if source has already connected with action type !== path: false
    return false
  }

  // if connection make it cycling
  const isMakeItCycle = (currentNodeId: string | null) => {
    const edgesFromCurrentNode = edges.filter(edge => edge.source === currentNodeId)
    if (edgesFromCurrentNode.length === 0) return false
    if (edgesFromCurrentNode.find(edge => edge.target === source)) return true
    return edgesFromCurrentNode.reduce((res, edge) => {
      return res || isMakeItCycle(edge.target)
    }, false)
  }
  return !isMakeItCycle(target)
}


const StyledButton = styled.button`
  cursor: pointer;
  color: ${danger};
  border: 1px solid ${danger};
  border-radius: 100%;
  position: absolute;
  top: -10px;
  right: -20px;
  &:hover {
    visibility: visible;
    * {
      color: white;
    }
    background: ${danger};
  }
`
const ContainerNode = styled.div<{ selected?: boolean }>`
  background: ${({ selected }) => selected ? lime[2] : 'white'};
  align-items: center;
  padding: 4px 8px;
  border: 1px solid black;
  border-radius: 8px;
  cursor: pointer;
  max-width: 200px;
  min-width: 200px;
  display: grid;
  grid-template-columns: max-content auto max-content;
  gap: 8px;
  ${StyledButton} {
    visibility: hidden;
  }
  &:hover {
    ${StyledButton} {
      visibility: visible;
    }
  }
`
const Name = styled.span`
  /* max-width: 80%; */
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 10px;
  line-height: 20px;
`
const Desc = styled(Name)`
  height: 14px;
  line-height: 18px;
  font-size: 8px;
  color: rgba(0, 0, 0, 0.65);
`

const StatusState: FC<{ stateId: number }> = ({ stateId }) => {
  const status = useRecoilValue(statusStateSelectorFamily({ stateId }))
  const { title, isValid } = status
  // console.log(stateId, title, isValid)

  return (
    <>
      <Tooltip
        placement="topRight"
        title={title}
      >
        {
          !isValid ?
            <ExclamationCircleFilled style={{ color: '#ff4d4f', fontSize: 24 }} /> :
            <CheckCircleFilled style={{ color: title ? '#E3E3E3' : success, fontSize: 24 }} />
        }
      </Tooltip>

    </>
  )
}