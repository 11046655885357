import { CalendarProps } from 'antd'
import moment from 'moment'
import { FC, useState } from 'react'
import { ContainerCalendar, StyledCalendar, StyledSelectDateTimeBtn } from '../DualModeDataPickerPopperDateTime/DateTimeDataPicker'

interface Props {
  value?: string,
  onChangeDate: (value: string) => void
}

const DateDataPicker: FC<Props> = ({ value, onChangeDate }) => {
  const [calendar, setCalendar] = useState<moment.Moment | undefined>(value ? moment(value) : undefined)

  const onChangeCalendar: CalendarProps<moment.Moment>['onChange'] = (value) => {
    setCalendar(value)
  }

  const onClickOk = () => {
    if (calendar) {
      console.log(calendar.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString())
      onChangeDate(calendar.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString())
    }
  }

  return (
    <div style={{ maxWidth: '350px' }} className="p-4">
      <strong>Date</strong>
      <ContainerCalendar>
        <StyledCalendar fullscreen={false} value={value ? moment(calendar) : undefined} onChange={onChangeCalendar} />
      </ContainerCalendar>
      <StyledSelectDateTimeBtn htmlType="button" onClick={onClickOk} className="mt-2" disabled={!calendar}>
        {`Select ${calendar ? `'${new Intl.DateTimeFormat('en-ID', {
          hour12: false,
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(new Date(calendar.toISOString()))}'` : ''}`}
      </StyledSelectDateTimeBtn>
    </div>
  )
}

export default DateDataPicker