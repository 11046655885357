import { Folder, Scenario, State } from 'db'
import { useState } from 'react'
import { TableState } from '../../types'
import useApiScenarios from '../scenario/useApiScenarios'
import useCreateScenario from '../scenario/useCreateScenario'
import { useRevalidateFetch } from '../useFetch'
import useCreateFolder from './useCreateFolder'

type DuplicateFolder = {
  duplicateFolder: (folderId: number, name?: string) => Promise<{
    newScenarios: {
      scenario: Scenario | undefined
    }[],
    newFolder: Folder
  }>,
  duplicatingFolder: boolean
}
export default function useDuplicateFolder(tableState?: TableState<Scenario>): DuplicateFolder {
  const { createFolder } = useCreateFolder()
  const { getScenarios } = useApiScenarios()
  const { createScenario } = useCreateScenario()
  const [duplicatingFolder, setDuplicating] = useState(false)
  const { revalidateScenarios } = useRevalidateFetch()


  const duplicateFolder = (folderId: number, name?: string) => {
    setDuplicating(true)
    const duplicate = createFolder({ name: `Copy of ${name}` })
      .then(async ({ data }) => {
        const newFolder = data.folder
        const newFolderId = newFolder.id
        const newScenarios = await getScenarios({ expands: 'state', folderId: folderId })
          .then(({ data }) => {
            const scenarios = Promise.all(data.scenarios.map(async (scenario) => {
              const { states, name, id } = scenario
              return await createScenario({ name: `Copy of ${name} - from ID:${id}`, folderId: newFolderId, states: states?.map(state => {
                const { triggerId, actionId, order, name, bundleId, params, conditionGroupOrder, conditions, stateConditionId, id, parentId, childrenIds } = state
                return {
                  name: name || state.trigger?.name || state.action?.name,
                  triggerId, actionId, order, bundleId, params, conditionGroupOrder, conditions, stateConditionId, id, childrenIds, parentId
                }
              }) as State[] })
                .then((scenario) => {
                  revalidateScenarios(tableState)
                  return {
                    scenario: scenario
                  }
                })
            }))
            return scenarios
          })
          .finally(() => setDuplicating(false))
        return {
          newScenarios,
          newFolder
        }
      })
    return duplicate
  }

  return {
    duplicateFolder,
    duplicatingFolder
  }
}