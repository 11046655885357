import { AxiosPromise } from 'axios'
import { State } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  createdAScenarioState?: State,
  creatingAScenarioState: boolean,
  errorCreateAScenarioState?: Error,
  createAScenarioState: (scenarioId: number, payload: Partial<State>) => AxiosPromise<{ state: State }>
}

export default function useCreateAScenarioState(): Result {
  const { data, error, isValidating, call } = useApi<{ state: State }>()

  return {
    createdAScenarioState: data?.state,
    creatingAScenarioState: isValidating,
    errorCreateAScenarioState: error,
    createAScenarioState: async (scenarioId, payload) => {
      return await call({
        url: `/scenario/${scenarioId}/state`,
        method: 'post',
        data: {
          state: payload
        }
      })
    }
  }
}