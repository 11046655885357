import NotFound from 'src/pages/error/NotFound'
import { FC } from 'react'
import { Route, Switch } from 'react-router'
import ListGlobalVariable from './ListGlobalVariable'
import CreateGlobalVariable from './create'
import EditGlobalVariable from './edit'

const GlobalVariable: FC = () => {
  const path = '/developer/application/:applicationId/:versionName/main/globalVariable'
  return (
    <>
      <Switch>
        <Route exact path={path} component={ListGlobalVariable}></Route>
        <Route exact path={`${path}/create`} component={CreateGlobalVariable}></Route>
        <Route exact path={`${path}/:globalVariableId`} component={EditGlobalVariable}></Route>
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default GlobalVariable