import { Trigger } from 'db'
import { Error } from '../../../types'
import useFetch from '../../useFetch'

type Result = {
  hiddenTriggers?: Trigger[],
  fetchingHiddenTriggers: boolean,
  errorFetchHiddenTrigger?: Error
}

export default function useHiddenTriggers(applicationId?: number | string, versionId?: number | null): Result {
  const url = applicationId ? `/application/${applicationId}/triggers?visibility=hidden${versionId ? `&versionId=${versionId}` : ''}` : null
  const { data, error, isValidating } = useFetch<{ triggers: Trigger[] }>(url, {}, { revalidateOnFocus: true })

  return {
    hiddenTriggers: data?.triggers,
    fetchingHiddenTriggers: isValidating,
    errorFetchHiddenTrigger: error
  }
}