import { Application, Version } from 'db'
import { useState } from 'react'
import { Error } from '../../../types'
import Notification from '../../../util/Notification'
import { useRevalidateFetch } from '../../useFetch'
import useUpdateVersion from '../version/useUpdateVersion'
import useUpdateApplication from './useUpdateApplication'

type Result = {
  submittedApplication?: Application,
  submittedNewVersion?: Version,
  submittedPrevVersion?: Version,
  submitingApplication?: boolean,
  errorSubmitApplication?: Error,
  submitApplicationToPublic: (applicationId: number, newVersionId: number, prevVersionId?: number, applicationName?: string) => Promise<any>
}

export default function useSubmitApplicationToPublic(): Result {
  const { revalidateAllVersion, revalidateApplication } = useRevalidateFetch()
  const { updateApplication, updatingApplication, updatedApplication, errorUpdateApplication } = useUpdateApplication()
  const { updateVersion, updatingVersion, errorUpdateVersion } = useUpdateVersion()
  const [newVersion, setNewVersion] = useState<Version>()
  const [prevVersion, setPrevVersion] = useState<Version>()

  return {
    errorSubmitApplication: errorUpdateApplication || errorUpdateVersion,
    submitingApplication: updatingApplication || updatingVersion,
    submittedNewVersion: newVersion,
    submittedPrevVersion: prevVersion,
    submittedApplication: updatedApplication,
    submitApplicationToPublic: (applicationId, newVersionId, prevVersionId, applicationName) => {
      return new Promise((resolve, reject) => {
        updateVersion(applicationId, newVersionId, {
          isActive: true
        }).then(({ data }) => {
          setNewVersion(data.version)
          updateApplication(applicationId, {
            public: true,
            publishedAt: new Date()
          }).then(() => {
            revalidateAllVersion(applicationId)
            revalidateApplication(applicationId)
            Notification.success({
              message: `You have successfully published ${applicationName || 'application'}`,
              description: 'User will soon use your Application to create scenario'
            })
            resolve(null)
          })
            .catch(() => reject)
        })
        if (prevVersionId) {
          updateVersion(applicationId, prevVersionId, {
            isActive: false
          }).then(({ data }) => {
            setPrevVersion(data.version)
            revalidateAllVersion(applicationId)
          })
        }
      })
    }
  }

}