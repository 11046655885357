import { AxiosPromise } from 'axios'
import { Folder } from 'db'
import { Error } from '../../types'
import { useApi } from '../useFetch'
import useFolders from './useFolders'

type RenameFolder = {
  renamedFolder?: Folder['name'],
  renamingFolder: boolean,
  errorRenameFolders?: Error,
  renameFolder: (folderId: number, name: string) => AxiosPromise<{ folder: Folder }>
}

export default function useRenameFolder(): RenameFolder {
  const { data, error, isValidating, call } = useApi<{ folder: Folder }>()
  const { revalidateFolders } = useFolders()

  return {
    renamedFolder: data?.folder.name,
    renamingFolder: isValidating,
    errorRenameFolders: error,
    renameFolder: (folderId: number, name: string) => {
      const put = call({
        url: `/folder/${folderId}`,
        method: 'patch',
        data: {
          folder: {
            name
          }
        }
      })

      put.then(() => {
        revalidateFolders()
      })

      return put
    }
  }
}