import { Col, Row, Space } from 'antd'
import { State } from 'db'
import { FC } from 'react'
import StateContextMenu from '../StateContextMenu'
import { useStateValue } from '../StateValueContextProvider'
import StateHeaderInfo from './StateHeaderInfo'
import StateSelectAccount from './StateSelectAccount'
import StateStatusInfo from './StateStatusInfo'

interface Props {
  toggleStateFormBody: () => void,
  onClickEdit: (isAdd?: boolean) => void,
  pathStates?: State[]
}
const StateHeader: FC<Props> = ({ onClickEdit, pathStates, toggleStateFormBody }) => {
  const [stateValue] = useStateValue()
  const { type, conditionGroupOrder } = stateValue
  const hasConditions = Boolean(conditionGroupOrder && !pathStates)

  return (
    <Row style={{ padding: '16px' }} gutter={[16, 16]} justify="space-between" align="middle" onClick={toggleStateFormBody}>
      <Col>
        <StateHeaderInfo
          notAllowedEditName={!!pathStates}
        />
      </Col>
      {
        hasConditions ?
          null :
          <Col>
            <Space>
              <StateSelectAccount />
              <StateStatusInfo pathStates={pathStates} />
              <StateContextMenu
                onClickEdit={onClickEdit}
                type={pathStates ? 'path' : type || 'trigger'}
                pathStates={pathStates}
              />
            </Space>
          </Col>
      }
    </Row>
  )
}

export default StateHeader