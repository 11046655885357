import { useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import useArchiveAScenarioState from '../../../../../../../hooks/scenario/state/useArchiveAScenarioState'
import useUpdateAScenarioState from '../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../../hooks/useFetch'
import CustomModalMethod from '../../../../../../../util/CustomModalMethod'
import { nextStateOfThisStateSelector } from '../../recoil/state'
import { useStateValue } from '../StateValueContextProvider'


type ArchiveActionState = {
  archiveActionState: () => void,
  archivingActionState: boolean
}

export default function useArchiveActionState(): ArchiveActionState {
  const getWidth = () => {
    const prevWidth = localStorage.getItem('widthStateForms')
    if (prevWidth) {
      return parseInt(prevWidth)
    }
    return undefined
  }
  const width = getWidth()
  const [stateValue] = useStateValue()
  const { parentId: parentStateId, scenarioId, childrenIds: childrenStateIds, id: stateId, order } = stateValue
  const [updatingOrderStates, setUpdatingOrderStates] = useState(false)
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const { archiveAScenarioState, archivingAScenarioState } = useArchiveAScenarioState()
  const { revalidateAScenario } = useRevalidateFetch()
  const nextStateIds = useRecoilValue(nextStateOfThisStateSelector(stateId))
  const history = useHistory()

  const archiveActionState = () => {
    CustomModalMethod.dangerConfirm({
      style: { marginLeft: `calc(${width}px / 3)` },
      title: 'Are you sure delete this action?',
      // icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk: () => {
        archiveAScenarioState(scenarioId, stateId)
        setUpdatingOrderStates(true)
        if (nextStateIds && order) {
          Promise.all(nextStateIds.map((state) => {
            updateAScenarioState(scenarioId, state.id, {
              // update parentId of direct child to stateParentId
              parentId: childrenStateIds?.includes(state.id) ? parentStateId : undefined,
              conditionGroupOrder: state.conditionGroupOrder ? state.conditionGroupOrder - 1 : state.conditionGroupOrder,
              order: state.order - 1
            }).then(({ data }) => data)
          })).then(() => {
            if (parentStateId) {
              // update childrenIds parent to direct next stateId
              updateAScenarioState(scenarioId, parentStateId, {
                childrenIds: childrenStateIds
              }).then(() => {
                setUpdatingOrderStates(false)
                revalidateAScenario(scenarioId)
                history.replace(`/app/template-scenario/editor/${scenarioId}/${parentStateId}`)
              })
            } else {
              setUpdatingOrderStates(false)
              revalidateAScenario(scenarioId)
              history.replace(`/app/template-scenario/editor/${scenarioId}/${parentStateId}`)
            }
          })
        }
      }
    })
  }

  return {
    archiveActionState,
    archivingActionState: updatingOrderStates || archivingAScenarioState || updatingAScenarioState
  }
}