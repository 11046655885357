import { timeZonesNames } from '@vvo/tzdb'
import { Card, Input, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import EmailVerification from '../../../components/EmailVerification'
import JobErrorEmailNotifSettings from '../../../components/pages/app/settings/jobErrorEmailNotifSettings'
import { useLoggedInUser } from '../../../contexts/LoggedInUserContextProvider'
import { OTORISASI_API_URL } from '../../../helpers/Constant'
import useAuthUser from '../../../hooks/user/useAuthUser'
import Notification from '../../../util/Notification'
import PageHeader from '../../components/PageHeader'
import { StyledButton } from '../../components/StyledComponents'
import CustomForm from '../../developer/components/form/CustomForm'
import FormItem from '../../developer/components/form/FormItem'
import UploadImageField from '../../developer/components/form/UploadImageField'

const ProfilePage: FC = () => {
  const [loggedInUser, setLoggedInUser] = useLoggedInUser()
  const { authUser } = useAuthUser()
  const [formBasic] = useForm()
  const [formEmail] = useForm()
  const [needEmailVerification, setNeedEmailVerification] = useState<boolean>()
  const [startCounter, setStartCounter] = useState<boolean>()
  const [updatingEmail, setUpdatingEmail] = useState(false)
  const [updatingBasicProfile, setUpdatingBasicProfile] = useState(false)

  useEffect(() => {
    if (authUser) {
      formBasic.setFieldsValue({ ...loggedInUser, ...authUser })
      formEmail.setFieldsValue(authUser)
    }
  }, [authUser])

  const updateBasicProfile = async (user: any) => {
    try {
      setUpdatingBasicProfile(true)
      const updated = await axios.patch(`${OTORISASI_API_URL}/api/auth/v1/user/me`, { user }, { withCredentials: true })
      Notification.success({ message: 'Profile updated' })
      setUpdatingBasicProfile(false)
      setLoggedInUser(prevState => ({ ...prevState as any, name: updated.data.user.name, profilePicture: updated.data.user.profilePicture }))
    } catch (error) {
      setUpdatingBasicProfile(false)
      return Notification.error({ message: error?.response?.data?.error || 'Something error, please try again' })
    }
  }

  const updateEmail = async (values: any) => {
    const { email } = values
    try {
      setUpdatingEmail(true)
      await axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/changeEmail`, { email }, { withCredentials: true })
      Notification.success({ message: 'Email updated' })
      setNeedEmailVerification(true)
      setStartCounter(true)
      setUpdatingEmail(false)
      setLoggedInUser(prevState => ({ ...prevState as any, email: email }))
    } catch (error) {
      setUpdatingEmail(false)
      return Notification.error({ message: error?.response?.data?.error || 'Something error, please try again' })
    }
  }

  return (
    <>
      <PageHeader
        title="My Profile"
      />
      <StyledCard>
        <h4>Basic Profile</h4>
        <CustomForm form={formBasic} onFinish={updateBasicProfile}>
          <FormItem label="Username" name="username" rules={[{ required: true, message: 'Username is required' }]}>
            <Input />
          </FormItem>
          <FormItem label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input />
          </FormItem>
          <FormItem label="Phone" name="phone">
            <Input />
          </FormItem>
          <FormItem label="Timezone" name="timezone" notUseInfoRequired rules={[{ required: true, message: 'Timezone is required' }]}>
            <Select
              filterOption={(input, option) =>
                option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              options={timeZonesNames.map(tz => ({ label: tz, value: tz }))} />
          </FormItem>
          {
            loggedInUser ?
              <UploadImageField label="Profile Picture" name="profilePicture" form={formBasic} existed={loggedInUser?.profilePicture}  /> : null
          }
          <FormItem shouldUpdate>
            {
              ({ getFieldValue, getFieldsError }) => {
                const isHasError = getFieldsError().reduce((acc, field) => {
                  if (field.errors.length > 0) {
                    return true
                  }
                  return acc
                }, false)
                return (
                  <StyledButton loading={updatingBasicProfile} style={{ float: 'right' }} htmlType="submit" type="primary"
                    disabled={isHasError || getFieldValue('profilePicture') === loggedInUser?.profilePicture && getFieldValue('phone') === authUser?.phone && getFieldValue('name') === authUser?.name && getFieldValue('username') === authUser?.username && getFieldValue('timezone') === authUser?.timezone}
                  >
                    Save Update
                  </StyledButton>
                )
              }
            }
          </FormItem>
        </CustomForm>
      </StyledCard>
      <br />
      <StyledCard>
        <h4>{needEmailVerification ? 'Need Verification' : 'Primary Email'}</h4>
        { needEmailVerification ?
          <EmailVerification email={loggedInUser?.email as string} onError={err => Notification.error({ message: err })} startCounting={!!startCounter} onCounterFinished={() => setStartCounter(false)} /> :
          <CustomForm form={formEmail} onFinish={updateEmail}>
            <FormItem name="email" label="Email" rules={[{ required: true, message: 'Email is required' }]}>
              <Input type="email" />
            </FormItem>
            <FormItem shouldUpdate>
              {
                ({ getFieldValue, getFieldsError }) => {
                  const isHasError = getFieldsError().reduce((acc, field) => {
                    if (field.errors.length > 0) {
                      return true
                    }
                    return acc
                  }, false)
                  return (
                    <StyledButton loading={updatingEmail} style={{ float: 'right' }} htmlType="submit" type="primary"
                      disabled={isHasError || getFieldValue('email') === authUser?.email}
                    >
                    Save Update
                    </StyledButton>
                  )
                }
              }
            </FormItem>
          </CustomForm>
        }
      </StyledCard>
      <br />
      <StyledCard>
        <JobErrorEmailNotifSettings />
      </StyledCard>
    </>
  )
}

export default ProfilePage

// const ProfilePageContainer = styled.div``

const StyledCard = styled(Card)`
  margin: 0 24px;
`