import { Application } from 'db'
import useFetch from '../../useFetch'

type Result = {
  publicApplicationsLength?: number,
  revalidatePublicApplications: () => void,
  publicApplications?: Application[],
  fetchingPublicApplications: boolean,
  errorFetchPublicApplications?: any
}


const usePublicApplications: (params?: { [param: string]: string }) => Result = (params) => {
  const url = `/applications${params ? Object.keys(params || []).reduce((res, param, i, arrKey) => {
    if (params[param]) {
      return res +`${param}=${params[param]}${i < arrKey.length - 1 ? '&' : ''}`
    }
    return res
  }, '?') : ''}`
  const { data, error, isValidating, revalidateFetch } = useFetch<{ applications: Application[], length: number }>(url)

  return {
    revalidatePublicApplications: () => revalidateFetch(url),
    publicApplicationsLength: data?.length,
    publicApplications: data?.applications,
    fetchingPublicApplications: isValidating,
    errorFetchPublicApplications: error
  }
}

export default usePublicApplications