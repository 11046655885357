import * as Sentry from '@sentry/react'
import { Layout, Spin } from 'antd'
import 'antd/dist/antd.css'
import { User } from 'db'
import Cookie from 'js-cookie'
import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { RecoilRoot } from 'recoil'
import { SWRConfig } from 'swr'
import './App.css'
import PersonalDataForm from './components/pages/app/personalData/PersonalDataForm'
import { useLoggedInUser } from './contexts/LoggedInUserContextProvider'
import useUser from './hooks/user/useUser'
import EndUserApp from './pages/app'
import Auth from './pages/auth'
import { removeCookies } from './pages/auth/Logout'
import OauthReturn from './pages/auth/oauth/return'
import Verification from './pages/auth/Verification'
import Application from './pages/developer/application'
import TestAuth from './pages/developer/application/test/auth'
import NotFound from './pages/error/NotFound'
import Legal from './pages/legal'
import { Implikasi } from './service/Implikasi'
import { INTERCOM_APP_ID } from './util/Constant'
import ModalGlobalStyle from './util/ModalGlobalStyle'
import TooltipGlobalStyle from './util/TooltipGlobalStyle'

const App: FC = () => {
  const [user] = useUser({ notProtected: true })
  const history = useHistory()

  useEffect(() => {
    localStorage.setItem('otomatis_user', `${user?.name} - id: ${user?.id}`)
  }, [user])

  return (
    <RecoilRoot>
      <SWRConfig value={{
        onError: (error) => {
          if (error?.response?.status === 401) {
            removeCookies()
            history.push('/auth/login')
          }
        }
      }}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <Layout className="App">
            <Switch>
              {/* Non-protected route */}
              <Route path="/" exact>
                <Redirect to="/app" />
              </Route>
              <Route path="/legal" component={Legal} />

              {/* <Route path="/app" component={EndUserApp} /> */}
              <Route path="/auth" component={Auth} />
              <Route path={'/verification'} exact component={Verification} />
              <Route path="/developer" exact>
                <Redirect to="/developer/application" />
              </Route>
              <PrivateRoute path="/developer/application" component={Application} />
              <Route path={'/:applicationId/run/auth/:authenticationId'} component={TestAuth} />
              <Route path="/oauth/return/:app/:authUuid" component={OauthReturn} />
              {

              }
              <Route path="/tell-us" exact component={PersonalDataForm} />

              {/* Protected route */}
              <PrivateRoute path="/app" component={EndUserApp} />

              <Route component={NotFound} />
            </Switch>
          </Layout>
        </IntercomProvider>
        <ModalGlobalStyle />
        <TooltipGlobalStyle />
      </SWRConfig>
    </RecoilRoot>
  )
}

export default Sentry.withProfiler(App)

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType
  // any additional vars
}

const PrivateRoute: FC<PrivateRouteProps & { onlyAdmin?: boolean }> = ({ component: Component, onlyAdmin, ...rest }) => {
  // const { data, isValidating, error } = useFetch('/user/me')
  const [user, setUser] = useState<User | null | undefined>(null)
  const [error, setError] = useState(null)
  const [_, setLoggedInUser] = useLoggedInUser()
  const { url } = useRouteMatch()
  const { replace } = useHistory()

  const syncLogout = event => {
    if (event.key === 'logout') {
      console.log('An active tab just triggering sync logout... Logout from this tab too')
      replace('/auth/login')
    }
  }

  useEffect(() => {
    // event listener for sync logout
    // lostening for event with key 'logout' from storage
    window.addEventListener('storage', syncLogout)

    // clean up the listener
    return () => {
      window.removeEventListener('storage', syncLogout)
      window.localStorage.removeItem('logout')
    }
  }, [])


  useEffect(() => {
    let cancelEffect = false

    if (!Cookie.get('authorization') && url.includes('login')) {
      return
    }

    Implikasi.get('/user/me', { withCredentials: true })
      .then(({ data }) => {
        if (!cancelEffect) {
          // console.log(data)
          setUser(data.user)
          setLoggedInUser(data.user)
        }
      })
      .catch(e => {
        setError(e)

        // return Notification.error({
        //   message: 'Something\'s Wrong',
        //   description: 'Failed on get user detail'
        // })
      })
    return () => {
      cancelEffect = true
    }
  }, [])

  if (!user && !error) {
    return (
      <Spin spinning>
        <div style={{ maxWidth: '100vw', height: '100vh', margin: 'auto' }} />
      </Spin>
    )
  } else {
    if (!Component) return null

    return (
      <Route
        {...rest}
        render={({ location }) => {
          if (user && !error) {
            if (!user.favouriteApps) {
              return <Redirect
                to={{
                  pathname: '/tell-us',
                  state: { from: location }
                }}
              />
            }
            if (!onlyAdmin) {
              return <Component {...rest} />
            } if (onlyAdmin && user.isAdmin) {
              return <Component {...rest} />
            }

            // return NotFound
          } else {
            removeCookies()
            return <Redirect
              to={{
                pathname: '/auth/login',
                state: { from: location }
              }}
            />
          }
        }}
      />
    )
  }

}
