import { Settings } from 'db'
import useFetch from '../useFetch'

interface UserSettings {
  settings?: Settings,
  fetchSetting: boolean
}

export default function useUserSettings(): UserSettings {
  const { data, isValidating } = useFetch<{ settings: Settings[] }>('/settings', {}, { revalidateOnFocus: false })

  return {
    settings: data?.settings?.[0],
    fetchSetting: isValidating
  }
}