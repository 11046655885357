import { AuthenticationSchema } from 'db'
import { Error } from '../../types'
import useFetch from '../useFetch'

type Result = {
  authenticationSchema?: AuthenticationSchema,
  fetchingAuthenticationSchema: boolean,
  errorFetchAuthenticationSchema?: Error
}

export default function useAuthenticationSchema(authenticationSchemaId?: number | string): Result {
  const url = authenticationSchemaId ? `/authenticationSchema/${authenticationSchemaId}` : null
  const { data, error, isValidating } = useFetch<{ authenticationSchema: AuthenticationSchema }>(url, {}, { revalidateOnFocus: false })

  return {
    authenticationSchema: data?.authenticationSchema,
    fetchingAuthenticationSchema: isValidating,
    errorFetchAuthenticationSchema: error
  }
}