import { main } from '@popperjs/core'
import { FormInstance } from 'antd'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/selection/selection-pointer'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Controlled as Codemirror } from 'react-codemirror2'
import ReactDOMServer from 'react-dom/server'
import OutsideClickHandler from 'react-outside-click-handler'
import { usePopper } from 'react-popper'
import { orange } from '../../../../../util/colors'
import { formatterValueBracket } from '../../../../../util/formatter'
import truncateLongData from '../../../../../util/truncateLongData'
import { DataSuggestion } from '../../../developer/formItems/DataPickerPopper'
import { CodeMirrorContainer, CodeMirrorDataTagContainer, CodeMirrorSelectValueTagContainer, PopperComponent, RichFormItemContainer } from './RichSelectInput'
import { StyledCloseCircleRichInput } from './RichTextInput'
import { useValueArray } from './StateForm/ArrayMapStateFormItem/ValueArrayContext'
import { useStateTestData } from './StateTestDataContext'
import { useThisStateStaticValue } from './ThisStateStaticValueContext'
import DualModeDataPickerPopperDateTime from './DataPickerPopper/DualModeDataPickerPopperDateTime'
import moment from 'moment'


const observeReferenceModifier = {
  // size change observer for the codemirror textarea
  // so that the popper can update data picker position
  name: 'observeReferenceModifier',
  enabled: true,
  phase: main,
  effect: (effectArg: any) => {
    const { state, instance } = effectArg
    const RO_PROP = '__popperjsRO__'
    const { reference } = state.elements

    reference[RO_PROP] = new ResizeObserver(() => {
      instance.update()
    })

    reference[RO_PROP].observe(reference)
    return () => {
      reference[RO_PROP].disconnect()
      delete reference[RO_PROP]
    }
  }
}

type CodeMirrorPosition = { line: number, start: number, stop: number, tag: string }

export type RichFormItemProps = {
  id: string,
  value: string, // Ant Form need this for custom input
  onChange: (value: string) => void, // Ant Form need this for custom input
  form?: FormInstance,
  onFocusOut?: () => void
}

const RichDateTimeInput: FC<RichFormItemProps> = ({ id, onFocusOut, value, onChange }) => {
  const cm = useRef<any>(null)
  const [stateTestDataValue] = useStateTestData()
  const [arrayMap] = useValueArray()

  const stateTestData = arrayMap?.dataSuggestion || stateTestDataValue
  const cmContainerElement = useRef<HTMLDivElement>(null)
  const dataPickerElement = useRef<HTMLDivElement>(null)
  const [showDataPicker, setShowDataPicker] = useState<boolean>(false)
  const [listOfMarkedText, setListOfMarkedText] = useState<any[]>([])
  const [cmReadOnlyOption, setCmReadOnlyOption] = useState<boolean | 'nocursor'>()
  const [thisStateStaticValue] = useThisStateStaticValue()
  const [dataPickerMode, setDataPickerMode] = useState<'date-time' | 'custom'>()

  useEffect(() => {
    if (value !== undefined && dataPickerMode === undefined) {
      const valueHasCustomTag = getTagPositions(value).length > 0
      if (valueHasCustomTag) setDataPickerMode('custom')
      else setDataPickerMode('date-time')
    }
  }, [value, dataPickerMode])
  // console.log({ value, options, stateTestData, dataPickerMode })

  const { styles, attributes } = usePopper(cmContainerElement.current, dataPickerElement.current, {
    strategy: 'absolute',
    placement: 'bottom-start',
    modifiers: [
      observeReferenceModifier,
      { name: 'flip', enabled: false } // disabled flip modifiers so the popper will stay on the bottom
    ]
  })

  const getTagPositions = (text: string | null) => {
    const lines = (text || '').split('\n')
    const re = /\{\{\{.+?\}\}\}/g
    const positions: { line: number, start: any, stop: any, tag: string }[] = []
    let m

    for (let i = 0; i < lines.length; i++) {
      while ((m = re.exec(lines[i])) !== null) {
        const tag = m[0].substring(3, m[0].length - 3).replace(/\[.+?\]/gi, (v) => v.substr(1, v.length - 2))
        positions.push({
          line: i,
          start: m.index,
          stop: m.index + m[0].length,
          tag,
        })
      }
    }
    return positions
  }

  const transformToVisualTag = (cm: any, positions: CodeMirrorPosition[], stateTestData: Record<string, DataSuggestion>) => {
    positions.forEach(position => {
      const markedText = cm.markText(
        { line: position.line, ch: position.start },
        { line: position.line, ch: position.stop },
        {
          replacedWith: createCustomDataTagVisual(position, stateTestData)
        }
      )

      setListOfMarkedText(prevValue => [...prevValue, markedText])
    })
  }

  const transformDateTimeToVisualTag = (cm: any) => {
    const value = cm.getValue()

    if (value !== '' && value !== undefined) {
      const markedText = cm.markText(
        { line: 0, ch: 0 },
        { line: 0, ch: value.length },
        {
          replacedWith: createDateTimeDataTagVisual(value)
        }
      )

      setListOfMarkedText(prevValue => [...prevValue, markedText])
    }
  }

  const insertTag = (keyData: string, editor: any) => {
    if (editor) {
      editor.replaceRange(formatterValueBracket(`{{{${keyData}}}}`), editor.getCursor())
      editor.focus()
    }
  }

  /**
   * @param {String} HTML representing a single element
   * @return {Element}
   *
   * credit to mark-amery https://stackoverflow.com/a/35385518
   */
  const htmlToElement = (html: string) => {
    const template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
  }

  const createCustomDataTagVisual = (position: { line: number, start: number, stop: number, tag: string }, stateWithTestData: Record<string, DataSuggestion>) => {
    const tagStateId = position.tag.split('.')[0]
    const tagDataKey = position.tag.split('.').slice(1).join('.')
    const selectedState = stateWithTestData[tagStateId]

    if (selectedState && stateWithTestData[tagStateId].flatTestData?.[tagDataKey]) {
      const selectedTagTestData = selectedState.flatTestData[tagDataKey]
      const sample = selectedTagTestData.sample
      const truncatedSample = truncateLongData(sample, 40) // template literal is necessary to forces boolean parsed into a string

      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          {
            selectedTagTestData.appLogoUrl ?
              <img src={selectedTagTestData.appLogoUrl || '/placeholder.png'} alt=" " /> :
              null
          }
          <b>{selectedTagTestData.label}:</b> {truncatedSample}
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    }else if (selectedState?.flatTestData?.[position.tag]) {
      const selectedTagTestData = selectedState.flatTestData[position.tag]
      const truncatedSample = truncateLongData(selectedTagTestData.sample, 40) // template literal is necessary to forces boolean parsed into a string
      const label = `${selectedTagTestData.label.replace(/\(array\)|\(object\b\)/g, (v) => `<span style="color: ${orange[5]}" >${v}</span>`)}:`
      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          {
            selectedTagTestData.appLogoUrl ?
              <img src={selectedTagTestData.appLogoUrl || '/placeholder.png'} alt=" " /> :
              null
          }
          <b dangerouslySetInnerHTML={{ __html: label }}></b> {truncatedSample}
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    }  else {
      const CodeMirrorDataTag = () =>
        <CodeMirrorDataTagContainer>
          <b>{position.tag}: </b> <i>no data</i>
        </CodeMirrorDataTagContainer>

      return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDataTag />))
    }
  }

  const createDateTimeDataTagVisual = (value: any) => {
    const CodeMirrorDateTimeTag = () =>
      <CodeMirrorSelectValueTagContainer>
        {moment(value).format('D MMM YYYY HH:mm:ss')}
      </CodeMirrorSelectValueTagContainer>

    return htmlToElement(ReactDOMServer.renderToStaticMarkup(<CodeMirrorDateTimeTag />))
  }

  const onBeforeChange = (_editor: any, _data: any, value: string) => {
    onChange?.(value)
  }

  const onEditorDidMount = (editor: any) => {
    cm.current = editor

    if (dataPickerMode === 'custom' && stateTestData) {
      transformToVisualTag(editor, getTagPositions(editor.getValue()), stateTestData)
    } else if (dataPickerMode === 'date-time') {
      transformDateTimeToVisualTag(editor)
    }
  }

  const onCodeMirrorValueChange = (editor: any) => {
    if (dataPickerMode === 'custom') {
      if (stateTestData) {
        transformToVisualTag(editor, getTagPositions(editor.getValue()), stateTestData)
      }
    } else if (dataPickerMode === 'date-time') {
      transformDateTimeToVisualTag(editor)
    } else {
      // do nothing
    }
  }

  const onCmContainerClick = useCallback(() => {
    // console.log('onCmContainerClick')
    if (dataPickerMode === 'date-time') {
      // styled the wrapper when textarea is focused
      const wrapper = cmContainerElement.current

      if (wrapper) {
        wrapper.style.boxShadow = '0 0 0 2px rgba(243, 173, 61, 20%)'
        wrapper.style.borderColor = '#F3AD3D'

        // show data picker
        // setIsComponentVisible(true)
      }
    }
    setShowDataPicker(true)
    // else do nothing
  }, [dataPickerMode])

  useEffect(() => {
    if (!showDataPicker) {
      onFocusOut?.()
    }
  }, [showDataPicker])


  const onFocus = () => {
    // styled the wrapper when textarea is focused
    const wrapper = cmContainerElement.current

    if (wrapper) {
      wrapper.style.boxShadow = '0 0 0 2px rgba(243, 173, 61, 20%)'
      wrapper.style.borderColor = '#F3AD3D'

      // show data picker
      // setIsComponentVisible(true)
      // console.log('params')
      // fetchDynamicOption({
      //   ...paramsDynamicOptions.current,
      //   formValue: form?.getFieldsValue(),
      // })
      setShowDataPicker(true)
    }
  }

  const onBlur = () => {
    // unstyled the wrapper when mouse leave focus
    const wrapper = cmContainerElement.current
    if (wrapper) {
      wrapper.style.boxShadow = 'none'
      wrapper.style.borderColor = '#d9d9d9'
    }
  }

  useEffect(() => {
    if (dataPickerMode === 'date-time') {
      setCmReadOnlyOption('nocursor')
      cm.current.setOption('selectionPointer', 'pointer')
    } else if (dataPickerMode === 'custom') {
      cm.current.setOption('selectionPointer', 'text')

      if (showDataPicker) {
        cm.current.focus()
      }

      setCmReadOnlyOption(false)
    } else {
      // do nothing
    }
  }, [dataPickerMode])

  useEffect(() => {
    if (stateTestData && cm.current && dataPickerMode === 'custom') {
      // reset all previous marked text
      listOfMarkedText.forEach(markedText => markedText.clear())
      // transform tag to visual if there is any change on test data
      transformToVisualTag(cm.current, getTagPositions(cm.current.getValue()), stateTestData)
    }
  }, [stateTestData, dataPickerMode])

  useEffect(() => {
    if (cm.current && dataPickerMode === 'date-time') {
      // reset all previous marked text
      listOfMarkedText.forEach(markedText => markedText.clear())
      // transform tag to visual if there is any change on test data
      transformDateTimeToVisualTag(cm.current)
    }
  }, [dataPickerMode, thisStateStaticValue])

  const onDataPickerModeChange = (mode: 'date-time' | 'custom', cm: any) => {
    setDataPickerMode(mode)

    // every time user change data picker mode
    // clear the editor content, marked text, and history
    cm.setValue('')
    cm.clearHistory()
    listOfMarkedText.forEach(markedText => markedText.clear())
  }

  const onTagClick = (tagValue: string) => insertTag(tagValue, cm.current)

  const onChangeDateTime = (value) => {
    cm.current.setValue(value) // make sure value is string, codemirror angy when it's not

    // unstyled the wrapper when mouse leave focus
    const wrapper = cmContainerElement.current
    if (wrapper) {
      wrapper.style.boxShadow = 'none'
      wrapper.style.borderColor = '#d9d9d9'
    }

    // close data picker after user select an option
    setShowDataPicker(false)
  }

  const onOutsideContainerClick = () => {
    setShowDataPicker(false)

    const wrapper = cmContainerElement.current
    if (wrapper) {
      wrapper.style.boxShadow = 'none'
      wrapper.style.borderColor = '#d9d9d9'
    }
  }

  const onClear = () => cm.current?.setValue('')

  const showClearButton = (cm.current?.getValue()?.length || 0) > 0

  return (
    <RichFormItemContainer id={id}>
      <OutsideClickHandler onOutsideClick={onOutsideContainerClick}>
        <CodeMirrorContainer ref={cmContainerElement} dataPickerMode={dataPickerMode === 'date-time' ? 'select' : 'custom'} onClick={onCmContainerClick}>
          <Codemirror
            value={value || ''}
            onBeforeChange={onBeforeChange}
            onChange={onCodeMirrorValueChange}
            editorDidMount={onEditorDidMount}
            onFocus={onFocus}
            onBlur={onBlur}
            options={{
              mode: null,
              lineNumbers: false,
              lineWrapping: true,
              scrollbarStyle: null,
              autoRefresh: true,
              readOnly: cmReadOnlyOption, // because default data picker will be 'date-time' mode
              // selectionPointer: 'pointer' // default cursor: pointer, data picker will be 'date-time' by default
            }}
          />
          {showClearButton ? <StyledCloseCircleRichInput onClick={onClear} /> : null}
        </CodeMirrorContainer>
        <PopperComponent
          isVisible={showDataPicker}
          ref={dataPickerElement}
          style={{ ...styles.popper, width: dataPickerMode === 'date-time' ? 'fit-content' : '100%' }}
          {...attributes.popper}
        >
          {
            stateTestData && showDataPicker ?
              <DualModeDataPickerPopperDateTime
                loading={false}
                mode={dataPickerMode || 'date-time'}
                selectedValue={cm.current?.getValue() || ''}
                statesWithTestData={stateTestData}
                onTagClick={onTagClick}
                onChangeDate={onChangeDateTime}
                onModeChange={(mode) => onDataPickerModeChange(mode, cm.current)}
              />

              : null
          }
        </PopperComponent>
      </OutsideClickHandler>
    </RichFormItemContainer>
  )
}

export default RichDateTimeInput