import { Button } from 'antd'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  font-family: 'DM Sans', sans-serif;
  font-weight: bold !important;
  &:hover, &:focus, &:visited  {
    color: #3E73E3;
    border-color: #3E73E3;
  }
  &.ant-btn-link {
    color: #3E73E3;
    &:hover, &:focus {
      border-color: transparent;
    }
  }
  &.ant-btn-primary {
    background: #3E73E3;
    border-color: #3E73E3;
    &[disabled] {
      background: #f5f5f5;
      border-color: #d9d9d9;
    }
    &:hover, &:focus {
      background: #749BEB;
      color: #FFF;
      border-color: #749BEB;
    }
    &.ant-btn-background-ghost {
      color: #3E73E3;
      background: #FFF;
      &:hover, &:focus {
        background: #3E73E3 !important;
        color: #FFF;
        border-color: #3E73E3;
      }
      &[disabled] {
        background: #f5f5f5;
        border-color: #d9d9d9;
        color: #d9d9d9;
        &:hover, &:focus {
          background: #f5f5f5 !important;
          border-color: #d9d9d9;
          color: #d9d9d9;
        }
      }
    }
  }
`

export default StyledButton