import { Input } from 'antd'
import { Dispatch, FC, SetStateAction } from 'react'
import useHitRequest from 'src/hooks/dataLoader/useHitRequest'
import { StyledButton, StyledDescription } from 'src/pages/components/StyledComponents'
import CustomForm from 'src/pages/developer/components/form/CustomForm'
import FormItem from 'src/pages/developer/components/form/FormItem'
import MonacoEditor from 'src/pages/developer/components/form/MonacoEditor'

interface Props {
  url?: string,
  setUrl: Dispatch<SetStateAction<string | undefined>>,
  parsedData: { [key: string]: any }[] | undefined
}
const Step3ForEach: FC<Props> = ({ setUrl, url, parsedData }) => {
  const { response, hitRequest, fetchingRequest } = useHitRequest()

  return (
    <CustomForm>
      <FormItem label="Webhook URL" description="Input URL">
        <Input type="url" onChange={(e) => {
          setUrl(e.target.value)
        }} />
      </FormItem>
      {
        url ?
          <>
            <StyledButton loading={!url || fetchingRequest} onClick={() => hitRequest(url, parsedData?.[0])}>
              Test Request
            </StyledButton>
            {
              response ?
                <>
                  <br /><br />
                  <MonacoEditor value={JSON.stringify(response, null, 2)} defaultLanguage={'json'} />
                </> :
                null
            }
          </> :
          <>
            <StyledDescription>Please input a valid URL</StyledDescription>
          </>
      }
    </CustomForm>
  )
}

export default Step3ForEach