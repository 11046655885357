import { CrownFilled } from '@ant-design/icons'
import { ButtonProps, Col, FormInstance, Input, Row, Select, Skeleton, Space, Tooltip } from 'antd'
import { Action, State, Trigger } from 'db'
import { FC, useEffect, useState } from 'react'
import useAllAction from '../../../../../../../hooks/application/action/useAllAction'
import usePublicApplications from '../../../../../../../hooks/application/main/usePublicApplications'
import useAllTrigger from '../../../../../../../hooks/application/trigger/useAllTrigger'
import useStatusPayment from '../../../../../../../hooks/payment/useStatusPayment'
import { StyledBoxIcon, StyledButton } from '../../../../../../../pages/components/StyledComponents'
import CustomForm from '../../../../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../../../../pages/developer/components/form/FormItem'
import { orange, primary65 } from '../../../../../../../util/colors'
import { sortApplications, sortTriggerActions } from '../../../../../../../util/functions'
import { StyledBadgePremium, StyledBadgePrivate, StyledBadgeOtomatis } from '../../../../ApplicationsSelectField'

export interface EventState {
  trigger?: Trigger,
  action?: Action
}
interface Props extends Pick<State, 'triggerId' | 'actionId'> {
  applicationId?: number,
  form: FormInstance<any>,
  type: 'trigger' | 'action',
  buttonCancelProps: ButtonProps,
  buttonSaveProps: ButtonProps,
  onFinish: (data: any, eventState?: EventState) => void,
  title: string
}
const FormStateEvent: FC<Props> = ({ form, onFinish, title, buttonCancelProps, buttonSaveProps, type, triggerId, actionId, applicationId }) => {
  const params: { [key: string]: string } | undefined = actionId || type === 'action' ? { expands: 'action' } : triggerId || type === 'trigger' ? { expands: 'trigger' } : undefined
  const { publicApplications, fetchingPublicApplications } = usePublicApplications(params)
  const [selectedApplicationId, setSelectedApplicationId] = useState<number>()
  const { triggers, fetchingAllTriggers } = useAllTrigger(triggerId || type === 'trigger' ? selectedApplicationId : undefined)
  const { actions, fetchingAllActions } = useAllAction(actionId || type === 'action' ? selectedApplicationId : undefined)
  const [options, setOptions] = useState<(Trigger | Action)[]>()
  const { plan } = useStatusPayment()

  useEffect(() => {
    form.setFieldsValue({
      applicationId:  applicationId,
      eventId: triggerId || actionId
    })
  }, [triggerId, applicationId, actionId])

  useEffect(() => {
    setSelectedApplicationId(applicationId)
  }, [applicationId])

  const onValuesChange = (value: any) => {
    const keys = Object.keys(value)
    if (value.eventId) {
      form.setFieldsValue({
        name: type === 'trigger' ? triggers?.find(trigger => trigger.id === value.eventId)?.name : actions?.find(action => action.id === value.eventId)?.name
      })
    }
    if (keys.includes('applicationId')) {
      setSelectedApplicationId(value.applicationId)
      form.setFieldsValue({
        eventId: null
      })
    }
  }

  useEffect(() => {
    if (triggers) {
      setOptions(triggers.filter(trigger => trigger.visibility === 'visible'))
    }
    if (actions) {
      setOptions(actions)
    }
  }, [actions, triggers])

  const onSubmit = (values: any) => {
    onFinish(values, {
      trigger: type === 'trigger' ? {
        ...triggers?.find(trigger => trigger.id === values.eventId),
        application: publicApplications?.find(app => app.id === values.applicationId)
      } as Trigger : undefined,
      action: type === 'action' ? {
        ...actions?.find(action => action.id === values.eventId),
        application: publicApplications?.find(app => app.id === values.applicationId)
      } as Action : undefined,
    })
  }

  return (
    <CustomForm onValuesChange={onValuesChange} onFinish={onSubmit} form={form}>
      <Row justify="space-between" align="middle" gutter={[16, 16]} >
        <Col>
          <h4 style={{ color: primary65 }}>{title}</h4>
          <FormItem hidden name="name">
            <Input />
          </FormItem>
        </Col>
        <Col>
          <Space>
            <StyledButton htmlType="button" {...buttonCancelProps}>Cancel</StyledButton>
            <StyledButton type="primary" htmlType="submit" {...buttonSaveProps}>Save</StyledButton>
          </Space>
        </Col>
      </Row>
      <FormItem notUseInfoRequired label="App" name="applicationId" rules={[{ required: true, message: 'App is required' }]}>
        {
          publicApplications ?
            <Select
              filterOption={(input, option) =>
                option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              placeholder="Select App" loading={fetchingPublicApplications}>
              {
                publicApplications?.filter(app =>
                  type === 'trigger' ?
                    app.triggers && app.triggers.length > 0
                    :
                    app.actions && app.actions.length > 0 && app.actions.filter(action => action.type === 'path').length === 0
                ).sort(sortApplications).map(application =>{
                  const { id, logoUrl, name, isPremiumApp } = application
                  return (
                    <Select.Option key={id} value={id} title={name}  disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                      <Tooltip placement="topLeft" title={!plan?.isUsingPremiumApps && isPremiumApp ? `Upgrade to Professional plan to use ${name}` : ''}>
                        <Space style={{ width: '100%' }}>
                          <StyledBoxIcon size="small" disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                            <img src={logoUrl || '/placeholder.png'} />
                          </StyledBoxIcon>
                          {name}
                          {
                            !application.public ?
                              <StyledBadgePrivate ellipsis title={'Private'}>Private</StyledBadgePrivate> :
                              application.isPremiumApp ?
                                <StyledBadgePremium ellipsis><CrownFilled style={{ color: orange[4] }} /> Premium</StyledBadgePremium> :
                                application.isNativeApp ?
                                  <StyledBadgeOtomatis>Otomatis</StyledBadgeOtomatis> :
                                  null
                          }
                        </Space>
                      </Tooltip>
                    </Select.Option>
                  )
                }
                )
              }
            </Select>
            :
            <Skeleton.Input active />
        }
      </FormItem>
      <FormItem notUseInfoRequired label="Event" name="eventId" rules={[{ required: true, message: 'Event is required' }]}>
        {
          options ?
            <Select
              disabled={!selectedApplicationId}
              filterOption={(input, option) =>
                option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              placeholder="Select Event"
              loading={fetchingAllActions || fetchingAllTriggers}
            >
              {
                options?.sort(sortTriggerActions).filter((option: Trigger | Action) => option.id).map((option: Trigger | Action) => {
                  const { id, name } = option
                  return (
                    <Select.Option key={id} value={id} title={name}>
                      {name}
                    </Select.Option>
                  )
                })
              }
            </Select>
            :
            <Skeleton.Input active />
        }
      </FormItem>
    </CustomForm>
  )
}

export default FormStateEvent