import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Dropdown, Menu, Skeleton } from 'antd'
import { User } from 'db'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { StyledButton } from '../../../../pages/components/StyledComponents'
import { DISABLE_DEV_MODE, WEB_VERSION } from '../../../../util/Constant'
import AnchorWrapper from '../../../AnchorWrapper'

interface Props {
  user: User,
  collapsed: boolean,
  setCollapsed: Dispatch<SetStateAction<boolean>>
}

const ProfileMenuSider: FC<Props> = ({ user, collapsed, setCollapsed }) => {
  const history = useHistory()
  const link = {
    logout: '/auth/logout',
    devmode: '/developer',
    profile: '/app/settings/profile',
    privacyPolicy: '/app/privacy-policy',
    tos: '/app/term-of-services'
  }

  const logout = () => {
    return history.push('/auth/logout')
  }

  const toDevMode = async () => {
    history.push('/developer')
  }

  const toUserProfile = () => {
    history.push('/app/settings/profile')
  }

  const toPrivacyPolicy = () => {
    history.push('/app/privacy-policy')
  }

  const toTOS = () => {
    history.push('/app/term-of-services')
  }

  const UserMenu =
  <>
    <Menu.Item key={3} onClick={toUserProfile}><AnchorWrapper href={link['profile']}>Settings</AnchorWrapper></Menu.Item>
    {DISABLE_DEV_MODE ? null : <Menu.Item key={1} onClick={toDevMode}><AnchorWrapper href={link['devmode']}>Dev Mode</AnchorWrapper></Menu.Item>}
    <Menu.Item key={4} onClick={toPrivacyPolicy}><AnchorWrapper href={link['privacyPolicy']}>Privacy Policy</AnchorWrapper></Menu.Item>
    <Menu.Item key={5} onClick={toTOS}><AnchorWrapper href={link['tos']}>Term of Services</AnchorWrapper></Menu.Item>
    <Menu.Item key={0} onClick={logout}><AnchorWrapper href={link['logout']}>Logout</AnchorWrapper></Menu.Item>
    <Menu.Item key={2} disabled style={{ fontSize: 11 }}>v{WEB_VERSION}</Menu.Item>
  </>

  return (
    <ProfileMenuSiderContainer collapsed={collapsed}>
      <ProfileMenuSiderUserName>
        <div>
          <Avatar size={32} style={{ backgroundColor: '#FFD8BF', verticalAlign: 'middle', textAlign: 'center' }} icon={user.profilePicture ? <img src={user.profilePicture} /> : <UserOutlined style={{ color: '#FA541C' }} />} />
        </div>
        {
          user ?
            collapsed ? null : <span>{user.name}</span> :
            <Skeleton.Button size="small" active />
        }
      </ProfileMenuSiderUserName>

      <ProfileMenuSiderUserMenu collapsed={collapsed}>
        {
          collapsed ?
            <Menu style={{ height: 'max-content', background: 'transparent', border: 0 }}>
              <Menu.SubMenu key={'settings'} icon={<SettingOutlined style={{ color: '#FFF', verticalAlign: 'middle', textAlign: 'center' }} />}>
                {UserMenu}
              </Menu.SubMenu>
            </Menu> :
            <Dropdown overlay={<Menu>{UserMenu}</Menu>} trigger={['click']} placement='topLeft'>
              <ProfileMenuSiderUserMenuButton type='ghost' icon={
                <SettingOutlined style={{ color: '#FFF', verticalAlign: 'middle', textAlign: 'center' }} />
              } />
            </Dropdown>}
        <StyledButton onClick={() => setCollapsed(!collapsed)} type="primary" icon={collapsed ? <MenuUnfoldOutlined style={{ color: '#FFF' }} /> : <MenuFoldOutlined style={{ color: '#FFF' }} />} />
      </ProfileMenuSiderUserMenu>
    </ProfileMenuSiderContainer>
  )
}


const ProfileMenuSiderContainer = styled.div<{ collapsed: boolean }>`
  padding: 0 12px 12px 12px;
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: ${({ collapsed }) => collapsed ? 'row' : 'column'};
  align-items: center;
  justify-content: ${({ collapsed }) => collapsed ? 'center' : 'space-between'};
  color: #FFF;
`

const AvatarStyled = styled(() => <Avatar style={{ backgroundColor: '#FFD8BF' }} icon={<UserOutlined style={{ color: '#FA541C' }} />} />)``

const ProfileMenuSiderUserName = styled.div`
  display: grid;
  align-items: center;
  min-width: 0;
  grid-auto-flow: column;
  grid-gap: 12px;

  & > ${AvatarStyled} {
    flex-shrink: 0;
  }

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

const ProfileMenuSiderUserMenuButton = styled(Button)`
  border: 0;
  display: grid;
  align-items: center;

  &:hover {
    background-color: rgba(255,255,255, .24);
  }
`

const ProfileMenuSiderUserMenu = styled.div<{ collapsed: boolean }>`
  display: grid;
  grid-auto-flow: ${({ collapsed }) => collapsed ? 'row' : 'column'};
  grid-gap: 8px;

  .ant-menu-submenu-title {
    height: max-content !important;
  }
`

export default ProfileMenuSider