import { SorterResult } from 'antd/lib/table/interface'
import { Trigger } from 'db'
import { useSWRConfig } from 'swr'
import { Implikasi } from '../../../service/Implikasi'
import { Error, TableState } from '../../../types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from '../../../util/Constant'
import { compareString } from '../../../util/functions'
import useFetch from '../../useFetch'


type Result = {
  triggersLength?: number,
  revalidateTriggers: () => void,
  triggers?: Trigger[],
  fetchingAllTriggers: boolean,
  errorFetchAllTrigger?: Error
}

export default function useAllTrigger(applicationId?: number | string, versionId?: number | null): Result {
  const url = applicationId ? `/application/${applicationId}/triggers${versionId ? `?versionId=${versionId}` : ''}` : null
  const { data, error, isValidating, revalidateFetch } = useFetch<{ triggers: Trigger[] }>(url, {}, { revalidateOnFocus: true })

  return {
    revalidateTriggers: () => revalidateFetch(url),
    triggers: data?.triggers,
    fetchingAllTriggers: isValidating,
    errorFetchAllTrigger: error
  }
}

export const useTriggers: (applicationId?: number | string, versionId?: number | null, tableState?: TableState<Trigger>) => Result = (applicationId, versionId, tableState) => {
  const key = applicationId && versionId ? `/triggers${applicationId}${versionId}${tableState ? JSON.stringify(tableState) : ''}` : null
  const url = `/application/${applicationId}/triggers`

  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const sorters: SorterResult<Trigger>[] | undefined = Array.isArray(tableState?.sorter) ? tableState?.sorter : undefined
  const sorterOrder = (tableState?.sorter as SorterResult<Trigger>)?.order
  const sorterField = (tableState?.sorter as SorterResult<Trigger>)?.field
  const filters = tableState?.filter || {}
  const filterDeleted = filters['withDeleted']
  let defaultSearch = 'true'
  defaultSearch = `${defaultSearch},${filterDeleted ? 'trigger.deletedAt.is=not null' : 'trigger.deletedAt.is=null'}`
  const search = (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
    if (field === 'createdAt' && filters[field]) {
      const dateStart: Date = filters[field]?.[0] as any
      const dateEnd: Date = filters[field]?.[1] as any
      return `${res},trigger.createdAt.between=${dateStart.toISOString()}_${dateEnd.toISOString()}`
    }
    if (field === 'withDeleted' && filterDeleted) {
      return `${res},trigger.deletedAt.is=not null`
    }
    if (field === 'search') {
      const ids = (filters[field] as string)?.match(/\d+/g) || []
      const searchByIds = ids.length > 0 ? `,trigger.id.in=(${ids.map(id => `'${id}'`).join(',')})` : ''
      const searchByName =`trigger.name.ilike=${filters[field]}`
      return `${res},${searchByIds ? 'or=(' : ''}${searchByName}${searchByIds ? `${searchByIds})` : ''}`
    }
    return `${res},trigger.${field}.in=(${(filters[field] as string[])?.map(val => `'${val}'`).join(',')})`
  }, defaultSearch)

  const { data, error, isValidating } = useFetch<{ triggers: Trigger[], length: number }>(key, {}, {
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          orderBy: sorterField && sorterOrder ? `trigger.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}` :
            sorters?.sort((s1, s2) => compareString(s1.field?.toString(), s2.field?.toString()))?.reduce((res, sorter, index) => {
              const sorterField = sorter.field
              const sorterOrder = sorter.order
              return `${res}${index === 0 ? '' : ','}trigger.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}`
            }, ''),
          and: `(${search})`,
          versionId,
          skip: (currentPage - 1) * pageSize,
          take: pageSize,
        },
        // data: {
        //   orderBy: sorterField && sorterOrder ? {
        //     ['trigger.createdAt'] : 'desc',
        //     [`trigger.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
        //   } : undefined,
        //   search: search
        // }
      })
      return res.data
    }
  })

  const { mutate } = useSWRConfig()

  function revalidateTriggers(): void {
    mutate(key)
  }

  return {
    revalidateTriggers,
    triggers: data?.triggers,
    triggersLength: data?.length,
    fetchingAllTriggers: isValidating,
    errorFetchAllTrigger: error
  }
}
