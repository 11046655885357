import { Application } from 'db'
import { Implikasi } from '../../../service/Implikasi'
import useFetch from '../../useFetch'

type Result = {
  applicationsLength?: number,
  revalidateApplications: () => void,
  applications?: Application[],
  fetchingApplications: boolean,
  errorFetchApplications?: any
}


const useApplicationsWithTriggerAndActionByIds: (ids?: (string | number)[]) => Result = (ids) => {
  const key = 'applicationWithTriggerAndActionByIds'
  const url = '/applications?expands=trigger,action,version'

  const { data, error, isValidating, revalidateFetch } = useFetch<{ applications: Application[], length: number }>(
    !ids  || ids?.length === 0 ? null : `${key}-${ids.join(',')}`, {},
    {
      fetcher: async () => {
        const res = await Implikasi(url, { method: 'get',
          params: {
            and: `(application.id.in=(${ids?.join(',')}))`
          },
          // data: {
          //   'search': `"application"."id" in (${ids?.join(',')})`
          // }
        })
        return res.data
      }
    }
  )

  return {
    revalidateApplications: () => revalidateFetch(!ids  || ids?.length === 0 ? null : `${key}-${ids.join(',')}`),
    applicationsLength: data?.length,
    applications: data?.applications,
    fetchingApplications: isValidating,
    errorFetchApplications: error
  }
}

export default useApplicationsWithTriggerAndActionByIds