import { AxiosPromise } from 'axios'
import { Trigger } from 'db'
import { Error } from '../../../types'
import useFetch, { useApi } from '../../useFetch'


type Result = {
  trigger?: Trigger,
  fetchingTrigger: boolean,
  errorFetchTrigger?: Error
}

export default function useTrigger(applicationId?: number | string, triggerId?: number | string, versionId?: number): Result {
  const url = triggerId && applicationId && versionId ? `/application/${applicationId}/trigger/${triggerId}${versionId ? `?versionId=${versionId}` : ''}` : null
  const { data, error, isValidating } = useFetch<{ trigger: Trigger }>(url, {}, { revalidateOnFocus: false })

  return {
    trigger: data?.trigger,
    fetchingTrigger: isValidating,
    errorFetchTrigger: error
  }
}

interface GetTrigger extends Result {
  getTrigger: (params: { applicationId: number, versionId?: number, triggerId: number }) => AxiosPromise<{ trigger: Trigger }>
}
export function useGetTrigger(): GetTrigger {
  const { call, data, isValidating, error } = useApi<{ trigger: Trigger }>()

  const getTrigger = ({ applicationId, triggerId, versionId }: { applicationId: number, versionId?: number, triggerId: number }) => {
    return call({
      method: 'get',
      url: `/application/${applicationId}/trigger/${triggerId}${versionId ? `?versionId=${versionId}` : ''}`
    })
  }
  return {
    getTrigger,
    trigger: data?.trigger,
    fetchingTrigger: isValidating,
    errorFetchTrigger: error
  }
}