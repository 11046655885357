import { Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Application } from 'db'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useApplication from '../../../../hooks/application/main/useApplication'
import useUpdateApplication from '../../../../hooks/application/main/useUpdateApplication'
import useCreateVersion from '../../../../hooks/application/version/useCreateVersion'
import Notification from '../../../../util/Notification'
import ApplicationForm from '../components/ApplicationForm'
import { baseApplicationRoute } from '../routes'

const UpdateApplication: React.FC = () => {
  const history = useHistory()
  const [form] = useForm<Partial<Application> & { activeVersion?: string }>()
  const { updateApplication, updatingApplication, updatedApplication } = useUpdateApplication()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { application, fetchingApplication } = useApplication(applicationId)
  const { createVersion, createdVersion, creatingVersion } = useCreateVersion()

  const finish = async () => {
    updateApplication(applicationId, {
      requestUrl: form.getFieldValue('requestUrl'),
      errorReporterEmail: form.getFieldValue('errorReporterEmail'),
      homepageUrl: form.getFieldValue('homepageUrl'),
      isNativeApp: form.getFieldValue('isNativeApp'),
      isPremiumApp: form.getFieldValue('isPremiumApp'),
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      logoUrl: form.getFieldValue('logoUrl'),
      public: form.getFieldValue('public'),
      hasAction: form.getFieldValue('hasAction') !== undefined ? form.getFieldValue('hasAction') : true,
      hasTrigger: form.getFieldValue('hasTrigger') !== undefined ? form.getFieldValue('hasTrigger') : true,
    })
  }

  useEffect(() => {
    if (application) {
      form.setFieldsValue({
        errorReporterEmail: application.errorReporterEmail,
        requestUrl: application.requestUrl,
        homepageUrl: application.homepageUrl,
        isPremiumApp: application.isPremiumApp,
        isNativeApp: application.isNativeApp,
        public: application.public,
        name: application.name,
        description: application.description,
        logoUrl: application.logoUrl,
        hasAction: application.hasAction,
        activeVersion: application.versions ? application.versions[0].name : undefined,
        hasTrigger: application.hasTrigger
      })
    }
  }, [application])


  const onBack = () => {
    history.push(baseApplicationRoute)
  }

  useEffect(() => {
    if (updatedApplication) {
      if (application?.versions && !application?.versions[0]?.name) {
        createVersion(updatedApplication.id, { name: form.getFieldValue('activeVersion') })
      } else {
        Notification.success({
          message: `Application ${updatedApplication.name} updated successfully`
        })
        onBack()
      }
    }
  }, [updatedApplication])

  useEffect(() => {
    if (createdVersion) {
      Notification.success({
        message: `Application ${updatedApplication?.name} updated successfully`
      })

      onBack()
    }
  }, [createdVersion])

  return (
    <div style={{ overflowY: 'auto', width: '100%' }}>
      {
        application ?
          <ApplicationForm title={`Update Application of '${application?.name}'`} version={application?.versions ? application.versions[0]?.name : undefined} onCancel={onBack} form={form} onFinish={finish} submitting={updatingApplication || fetchingApplication || creatingVersion} application={application} />
          : <Spin spinning style={{ height: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }} />
      }
    </div>
  )
}

export default UpdateApplication