import { ConfigProvider, Empty, Row, Table } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { LogJob } from 'db'
import { DateTime } from 'luxon'
import moment from 'moment'
import qs from 'query-string'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import useLogJobs from '../../../../../hooks/job/useLogJobs'
import { StyledDescription } from '../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../types'
import { blue, red, success, warning } from '../../../../../util/colors'
import { DEFAULT_DATE_RANGE, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../../../util/Constant'
import AnchorWrapper from '../../../../AnchorWrapper'
import LayoutMyScenarioPage from '../../scenario/all/LayoutMyScenarioPage'
import ActionsSelectedLogJobs from './ActionsSelectedJobs'
import JobFilter from './JobFilter'
import ListJobAction from './ListJobAction'
import ScenarioDetailRow from './ScenarioDetailRow'


interface Props {
  selectedRows?: LogJob[],
  setSelectedRows?: Dispatch<SetStateAction<LogJob[] | undefined>>
}

const ListJobExecuted: FC<Props> = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { search } = useLocation()
  const [tableState, setTableState] = useState<TableState<LogJob>>(() =>{
    const params = qs.parse(search)
    const { status, dateStart, dateEnd, search: scenario, withDeleted, page } = params
    const convertedParams = {
      withDeleted,
      status: status ? [status as string] : null,
      createdAt: dateStart ? [moment(params.dateStart as string), dateEnd ? moment(dateEnd as string) : moment()] : DEFAULT_DATE_RANGE,
      search: scenario
    }
    return {
      pagination: {
        pageSize: DEFAULT_PAGE_SIZE,
        current: parseInt(page?.toString() || '1')
      },
      sorter: {
        field: 'createdAt',
        order: 'descend'
      },
      filter: {
        ...convertedParams
      }
    }
  })
  const [selectedRows, setSelectedRows] = useState<LogJob[]>()

  const { logJobs, fetchingLogJobs, logJobsLength, revalidateLogJobs } = useLogJobs(tableState)

  const handleChange = (pagination?: TablePaginationConfig, filter?: Record<string, FilterValue | null>, sorter?: SorterResult<LogJob> | SorterResult<LogJob>[], extra?: TableCurrentDataSource<LogJob>) => {
    setTableState(prevState => ({ ...prevState, pagination, filter: { ...prevState.filter, ...filter }, sorter, extra }))
  }

  const onClickJobId = async (record: LogJob) => {
    history.push({
      pathname: `${url}/${record.id}`,
      search: history.location.search
    })
  }

  const columns: ColumnsType<LogJob> = [
    {
      title: 'Job ID',
      dataIndex: 'id',
      render: (id, record) => <AnchorWrapper style={{ color: blue[4] }} href={`${url}/${record.id}${history.location.search}`} onClick={(e) => {
        e.preventDefault()
        onClickJobId(record)
      }}>{id}</AnchorWrapper>
    },
    { title: 'Apps',
      render: (_, record) => {
        return <ScenarioDetailRow scenarioId={record.scenarioId} type="applications" />
      }
    },
    { title: 'Scenario Name', render: (_, record) => {
      return <ScenarioDetailRow scenarioId={record.scenarioId} type="name" />
    } },
    { title: 'Executed at', dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      align: 'right',
      width: 130,
      // filteredValue: (tableState?.filter?.createdAt) as FilterValue || null,
      // filterDropdown: () => <DatePicker.RangePicker
      //   size="small"
      //   value={tableState.filter?.createdAt ? tableState.filter.createdAt as any : undefined}
      //   onChange={(values: any) => handleChange(tableState?.pagination, { ...tableState?.filter, createdAt: values }, tableState?.sorter)}
      //   allowClear
      //   showTime={{
      //     defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]
      //   }} showHour showMinute showSecond={false} minuteStep={30}
      // />
      // ,
      sorter: (logJob1, logJob2) => new Date(logJob1.createdAt).getTime() - new Date(logJob2.createdAt).getTime(),
      render: (lastExecuted) => lastExecuted ?
        <>
          <span>{DateTime.fromJSDate(new Date(lastExecuted)).toFormat('DD')}</span><br/>
          <span>{DateTime.fromJSDate(new Date(lastExecuted)).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}</span>
        </> : '' },
    { title: 'Task', dataIndex: 'runningTasks', align: 'right' },
    { title: 'Status', dataIndex: 'status', render: (_, record) => {
      if (record.error){
        return <strong style={{ color: red[4] }}>Error</strong>
      }
      if (record.status) {
        return <strong style={{ textTransform: 'capitalize', color: record.status === 'complete' ? success : record.status === 'error' || record.status === 'partialError' ? red[4] : warning }}>{record.status}</strong>
      }
    },
    // filteredValue: tableState.filter?.status as FilterValue || null,
    // filterMultiple: false,
    // filters: [{ text: 'Start', value: 'start' }, { text: 'Pending', value: 'pending' }, { text: 'Complete', value: 'complete' },{ text: 'Error', value:'error' }, { text: 'Partial Error', value:'partialError' }]
    },
    { title: 'Action', dataIndex: 'id', render: (id, record) => <ListJobAction id={id} record={record} afterAction={revalidateLogJobs} /> },
  ]

  return (
    <LayoutMyScenarioPage propsPageHeader={{
      title: 'Job History',
      extra: <Row gutter={[16, 16]} align="middle" justify="end">
        <ActionsSelectedLogJobs setSelectedRows={setSelectedRows} selectedRows={selectedRows} tableState={tableState} />
      </Row>
    }}>
      <StyledListJobExecuted>
        <JobFilter setTableState={setTableState} tableState={tableState} />
        <ConfigProvider
          renderEmpty={() => <Empty
            imageStyle={{
              height: 200,
              display: 'flex',
              justifyContent: 'center'
            }}
            image={fetchingLogJobs ? null : tableState.filter?.search ? '/illustrations/notfound.svg' : '/illustrations/start-history.svg'}
            description={fetchingLogJobs ? null : tableState.filter?.search ?
              <>
                <h5>Sorry, no result found</h5>
                <StyledDescription>What you searched was unfortunately not found or doesn't exist</StyledDescription>
              </> :
              !fetchingLogJobs ?
                <>
                  <h5>There isn't any history here yet</h5>
                  <StyledDescription>Start creating your own scenario with easy steps in just a few minutes</StyledDescription>
                </> :
                null}
          />}
        >
          <Table
            onChange={handleChange}
            rowSelection={{
              selectedRowKeys: selectedRows?.map(logJob => logJob.id),
              onChange: (_, selected) => {
                setSelectedRows?.(selected)
              }
            }}
            pagination={{
              current: tableState.pagination?.current,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              total: logJobsLength,
              pageSize: tableState.pagination?.pageSize
            }}
            onRow={(record) => ({
              style: { cursor: 'pointer' },
              onClick: (_e) => onClickJobId(record)
            })}
            columns={columns}
            rowKey="id"
            size="small"
            dataSource={logJobs}
            scroll={{ x: true }}
            loading={fetchingLogJobs}
          />
        </ConfigProvider>
      </StyledListJobExecuted>
    </LayoutMyScenarioPage>
  )
}

export default ListJobExecuted

const StyledListJobExecuted = styled.div`
  padding: 16px;
`