import { Member } from 'db'
import { Error } from '../../../types'
import useFetch from '../../useFetch'

type Result = {
  revalidateMembers: () => void,
  members?: Member[],
  fetchingAllMembers: boolean,
  errorFetchAllMember?: Error
}

export default function useAllMember(applicationId?: number | string): Result {
  const url = applicationId ? `/application/${applicationId}/members` : null
  const { data, error, isValidating, revalidateFetch } = useFetch<{ members: Member[] }>(url, {}, { revalidateOnFocus: true })

  return {
    revalidateMembers: () => revalidateFetch(url),
    members: data?.members,
    fetchingAllMembers: isValidating,
    errorFetchAllMember: error
  }
}