import { Col, Divider, Row } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { DateTime } from 'luxon'
import moment from 'moment'
import { FC } from 'react'
import styled from 'styled-components'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import { Invoice } from '../../../../hooks/payment/useInvoices'
import useStatusPayment from '../../../../hooks/payment/useStatusPayment'
import { currencyFormatterIDR, getInitialName } from '../../../../util/formatter'
import { StyledDescription } from '../../../components/StyledComponents'
import ReportPdfComponent from './ReportPdfComponent'

interface Props {
  active?: Invoice
}
const SummaryBillingCard: FC<Props> = ({ active }) => {
  const [loggedInUser] = useLoggedInUser()
  const { totalUsage, maxTask, plan, scenariosCount, result } = useStatusPayment()
  const dueDate = moment((result?.subscription?.latestInvoice?.period_end || 0) * 1000).add({ month: 1 }).valueOf()
  const now = new Date().getTime()

  const activeInvoice = active
  const taskUsage = active?.usage_records?.total_usage || totalUsage
  const scenariosUsage = active?.scenarios_count || scenariosCount
  const activePlan = activeInvoice?.lines.data?.[0]?.pricingPlan || plan


  return (
    <>
      <StyledSummaryBillingCard gutter={[16, 0]}>
        <Col span={24}>
          <FirstRow gutter={[16, 16]} justify="space-between" align="middle">
            <Col>
              <Row gutter={[16, 16]} align="middle">
                <Col>
                  <Avatar size="large" icon={loggedInUser?.profilePicture ? <img src={loggedInUser.profilePicture} /> : getInitialName(loggedInUser?.name)} />
                </Col>
                <Col>
                  <Row gutter={[4, 4]}>
                    <Col><h5 style={{ margin: 0 }}>{loggedInUser?.name}</h5></Col>
                    <Col><StyledDescription>({loggedInUser?.email})</StyledDescription></Col>
                  </Row>
                  <p>Invoice Number: <span style={{ fontWeight: 500 }} >{active?.number || 'Not yet published'}</span></p>
                </Col>
              </Row>
            </Col>
            <Col>
              <ReportPdfComponent active={active} />
            </Col>
          </FirstRow>
        </Col>
        <StyledDivider />
        <Col span={24}>
          <SecondRow gutter={[16, 16]} justify="space-between" align="middle">
            <Col>
              <p>Active plan in this billing cycle:</p>
              <h4>{activePlan?.name}</h4>
            </Col>
            <Col>
              <StatusRow gutter={[16, 16]} align="middle">
                <Col>
                  <p>Task Usage:</p>
                  <h5>{taskUsage || 0} / <span style={{ fontWeight: 400 }} >{activePlan?.monthlyTask}</span></h5>
                </Col>
                <Col>
                  <p>Scenario Usage:</p>
                  <h5>{scenariosUsage || 0} / <span style={{ fontWeight: 400 }} >{activePlan?.maxScenario === 0 ? 'Unlimited' : activePlan?.maxScenario}</span></h5>
                </Col>
                <Col>
                  <p>Billing:</p>
                  <h5>{currencyFormatterIDR(activePlan?.price)}</h5>
                </Col>
              </StatusRow>
            </Col>
          </SecondRow>
        </Col>
      </StyledSummaryBillingCard>
      {
        (totalUsage || 0) > (maxTask || 0) ?
          <StyledAlertLimit>
            Tasks usage exceeded the limit, billing price will charged {'Rp50'} per task
          </StyledAlertLimit> :
          now < dueDate ?
            <StyledInfoPayment gutter={[16, 16]} justify="center" >
              Next Payment in: &#8202;<strong>{DateTime.fromJSDate(new Date(dueDate)).toFormat('DD t')}</strong>
            </StyledInfoPayment> :
            null
      }
    </>
  )
}

export default SummaryBillingCard

const StyledSummaryBillingCard = styled(Row)`
  background: #FFF;
  margin-bottom: 8px;
`
const FirstRow = styled(Row)`
  padding: 16px;
`
const SecondRow = styled(Row)`
  padding: 16px;
`
const StatusRow = styled(Row)`
  background: #FAFAFA;
  /* Neutral/3 */

  border: 1px solid #F5F5F5;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px;

  h5 {
    margin-bottom: 0;
    span {
      font-weight: 400;
    }
  }
`
const StyledDivider = styled(Divider)`
  margin: 0;
`
const StyledInfoPayment = styled(Row)`
  padding: 4px;
  /* Calendula Gold/1 */

  background: #FFFBE6;
  /* Calendula Gold/3 */

  border: 1px solid #FFE58F;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: center;

  /* Character/Title .85 */

  color: rgba(0, 0, 0, 0.85);

`

const StyledAlertLimit = styled(Row)`
  /* Character/Primary(inverse) */

  background: #FFFFFF;
  /* Orange/4 */

  border: 1px dashed #F3AD3D;
  box-sizing: border-box;
  border-radius: 2px;

  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: center;

  /* Character/Title .85 */

  color: rgba(0, 0, 0, 0.85);

`