import { State } from 'db'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useAScenario from 'src/hooks/useAScenario'

type ActiveStates = {
  thisStatePath?: State,
  activeStates?: State[]
}

export default function useActiveStates(): ActiveStates {
  const { scenarioId, stateId, isPath } = useParams<{ scenarioId: string, stateId?: string, isPath?: string }>()
  const { scenario } = useAScenario(parseInt(scenarioId), true)
  const [activeStates, setActiveStates] = useState<State[]>()
  const [thisStatePath, setThisStatePath] = useState<State>()

  const sortFunction = (s1: State, s2: State) => s1.order - s2.order

  useEffect(() => {
    const states = scenario?.states
    if (states && stateId) {
      const activeState = states.find(state => state.id === Number(stateId))
      if (activeState?.stateConditionId || activeState?.conditionGroupOrder && !isPath) {
        const thisStatePath = activeState?.conditionGroupOrder && !isPath ?
          scenario?.states?.find(s => s.id === activeState.id) :
          scenario?.states?.find(s => s.id === activeState?.stateConditionId)
        setThisStatePath(thisStatePath)
        setActiveStates(states.filter(state =>
          state.order >= 0 &&
          (state.stateConditionId === thisStatePath?.id ||
          state.id === thisStatePath?.id)).sort(sortFunction))
      } else {
        setThisStatePath(undefined)
        setActiveStates(states.filter(state => state.order >= 0 && !state.stateConditionId)
          .sort(sortFunction))
      }
    }
  }, [scenario, stateId, isPath])

  return {
    activeStates,
    thisStatePath
  }

}