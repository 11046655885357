import { Version } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  archivedVersion?: Version,
  archivingVersion: boolean,
  errorArchiveVersion?: Error,
  archiveVersion: (applicationId: number, versionId: number) => void
}

export default function useArchiveVersion(): Result {
  const { data, error, isValidating, call } = useApi<{ version: Version }>()

  return {
    archivedVersion: data?.version,
    archivingVersion: isValidating,
    errorArchiveVersion: error,
    archiveVersion: (applicationId, versionId) => {
      call({
        url: `/application/${applicationId}/version/${versionId}/archive`,
        method: 'delete',
      })
    }
  }
}