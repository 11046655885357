import { FC } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import ViewerTemplateScenario from 'src/components/pages/app/templateScenario/viewer'
import StateTestDataContextProvider from '../../../components/pages/app/scenario/edit/StateTestDataContext'
import EditorTemplateScenario from '../../../components/pages/app/templateScenario/editor'
import ListTemplateScenario from './listTemplateScenario'

const TemplateScenarioPage: FC = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Redirect exact from={path} to={`${path}/list`} />
        <Route path={`${path}/list`} component={ListTemplateScenario} />
        <Route path={`${path}/editor/:scenarioId?/:stateId?/:isPath?`}
          render={() =>
            <StateTestDataContextProvider>
              <EditorTemplateScenario />
            </StateTestDataContextProvider>}
        />
        <Route path={`${path}/:scenarioId?/:stateId?/:isPath?`}
          render={() =>
            <StateTestDataContextProvider>
              <ViewerTemplateScenario />
            </StateTestDataContextProvider>}
        />
      </Switch>
    </>
  )
}

export default TemplateScenarioPage