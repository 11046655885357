import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { FC } from 'react'
import useWebhookUrl from 'src/hooks/scenario/useWebhookUrl'
import { StyledButton, StyledLabel, StyledText } from 'src/pages/components/StyledComponents'
import { success } from 'src/util/colors'
import styled from 'styled-components'
import useGetState from './useGetState'

const WebhookURLPart: FC = () => {
  const { state, scenarioId } = useGetState()
  const { url } = useWebhookUrl(state?.trigger?.type === 'hook' ? Number(scenarioId) : undefined)
  const testGuideline = state?.trigger?.testGuideline
  const hasSubscribeHttpRequestId = !!state?.trigger?.subscribeHttpRequestId
  return (
    <>
      {
        url ?
          hasSubscribeHttpRequestId && !testGuideline ?
            null :
            <>
              <StyledLabel className="no-status">Webhook URL for Otomatis Webhook</StyledLabel>
              <br />
              <StyledTextUrl
                copyable={{
                  text: url,
                  icon: [<StyledButton size="small" icon={<CopyOutlined />}>Copy</StyledButton>, <StyledButton icon={<CheckOutlined style={{ color: success }} />}>Copied to Clipboard</StyledButton>],
                }}
              >{url.substring(0, 50)}</StyledTextUrl>
              <br />
            </> :
          null
      }
    </>
  )

}

export default WebhookURLPart

const StyledTextUrl = styled(StyledText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  font-size: 10px;
  .ant-typography-copy {
    border-left: 1px solid #D9D9D9;
  }
  /* &::before {
    content: '';
    width: 10px;
    height: 2px;
    background: blue;
  } */
  background: #FFFFFF;
  /* Neutral/5 */
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
`