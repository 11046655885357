import { useHistory, useParams } from 'react-router'

const useFolderActiveKey: () => string = () => {
  const history = useHistory()
  const { folderId } = useParams<{ folderId?: string }>()

  const getFolderActiveKey = () => {
    if (history.location.pathname.includes('/accounts')) return '/accounts'
    else if (history.location.pathname.includes('history')) return '/history'
    else if (folderId) return `/${folderId}`
    else return '' // 'All' folder
  }

  return getFolderActiveKey()
}

export default useFolderActiveKey