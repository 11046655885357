import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import useVersionName from 'src/hooks/application/version/useVersionName'
import DataSuggestionContextProvider from '../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useTrigger from '../../../../../../hooks/application/trigger/useTrigger'
import { useSharedValueTrigger } from '../components/SharedValueTriggerContextProvider'
import TriggerSteps from '../components/TriggerStep'

const UpdateTrigger: FC = () => {
  const { versionName, triggerId, applicationId } = useParams<{ versionName: string, applicationId: string, triggerId: string }>()
  const { versionId } = useVersionName(applicationId, versionName)
  const { trigger } = useTrigger(applicationId, triggerId, versionId)
  const [_, setValues] = useSharedValueTrigger()

  useEffect(() => {
    if (trigger) {
      setValues(trigger)
    }
  }, [trigger])

  return (
    <DataSuggestionContextProvider>
      <TriggerSteps type="update" />
    </DataSuggestionContextProvider>
  )
}

export default UpdateTrigger