import { CheckCircleOutlined, CheckOutlined, CloseOutlined, LockOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Form, Input, Layout, message, Modal, Row, Skeleton, Space, Switch, Typography } from 'antd'
import Axios from 'axios'
import Cookie from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CLIENT_ID_WEB, OTORISASI_API_URL } from '../../helpers/Constant'
import { encrypt } from '../../helpers/Crypto'

const Account: React.FC = () => {
  const history = useHistory()
  const [user, setUser] = useState<any>()
  const [tfa, setTfa] = useState<boolean>()
  const [showModalDeactivateTfa, setShowModalDeactivateTfa] = useState<boolean>()
  const [password, setPassword] = useState<string>()
  const [application, setApplication] = useState<any>()

  useEffect(() => {
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/user/me`, { withCredentials: true })
      .then(({ data }) => {
        if (!Cookie.get('authorized') && Cookie.get('authorization') && data.user.isEnabled2fa) {
          return history.push('/2fa/verify')
        }
        setUser(data?.user)
        setTfa(data?.user?.isEnabled2fa)
      })
      .catch(() => history.replace('/auth/login'))
  }, [history])

  useEffect(() => {
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/clients`, { params: { clientId: CLIENT_ID_WEB }, withCredentials: true })
      .then(({ data }) => {
        Axios.get(`${OTORISASI_API_URL}/api/auth/v1/application/${data.clients[0].applicationId}`, { withCredentials: true })
          .then(({ data }) => {
            setApplication(data.application)
          })
      })
  }, [])

  const updateApplication = async (update: any) => {
    const { data } = await Axios.patch(`${OTORISASI_API_URL}/api/auth/v1/application/${application.id}`, { application: update }, { withCredentials: true })
    setApplication(data.application)
  }

  const deactivate2fa = async () => {
    if (password) {
      try {
        await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/deactivate2fa`, { password: encrypt(password as string) }, { withCredentials: true })
        message.success('2FA deactivated successfully')
        setTfa(false)
      } catch (error) {
        message.error(error?.response?.data?.error || 'Something error, please try again')
      }
    }
    return setShowModalDeactivateTfa(false)
  }

  return (
    <Layout.Content style={{ padding: 16 }}>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            {/* <img alt="logo" src={`/product-logo/Logotype/otorisasi - logo${currentTheme === 'dark' ? ' (inv)' : ''}.png`} style={{ width: '134px', maxWidth: '100%' }} /> */}
            <Space>
              <Link to="/auth/edit"><Button>Edit</Button></Link>
              <Link to="/auth/logout"><Button type="primary" danger>Logout</Button></Link>
            </Space>
          </div>
          <Card>
            { user ?
              <>
                <Descriptions title={`Welcome back, ${user?.name}!`} column={1} bordered>
                  <Descriptions.Item label="Username">{user?.username}</Descriptions.Item>
                  <Descriptions.Item label="Email">{user?.email} {user?.isVerified ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{user?.phone || '-'}</Descriptions.Item>
                  <Descriptions.Item label="Timezone">{user?.timezone}</Descriptions.Item>
                  <Descriptions.Item label="Last Login">{user?.lastLogin}</Descriptions.Item>
                  <Descriptions.Item label="2FA">{user?.isEnabled2fa?.toString()}</Descriptions.Item>
                </Descriptions>
                <br />
                {/* <Card type="inner" title="OAuth Apps">
                  <Descriptions column={1}>
                    <Descriptions.Item>
                      <Link to="/auth/connectedClients">Review all connected apps</Link>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Link to="/auth/apps">My created apps</Link>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
                <br /> */}
                <Card type="inner" title="2FA Authentication">
                  { tfa ?
                    <p>
                      Active <CheckCircleOutlined type="success" />
                      <Button style={{ float: 'right' }} onClick={() => setShowModalDeactivateTfa(true)} danger>Disable</Button>
                    </p>
                    :
                    <p>
                      Inactive <WarningOutlined />
                      <Link to="/auth/2fa/setup">
                        <Button style={{ float: 'right' }} type="primary">Setup</Button>
                      </Link>
                    </p>
                  }
                </Card>
                { user?.isAdmin ?
                  <>
                    <br />
                    <Card type="inner" title="Admin Management">
                      <Descriptions column={1}>
                        <Descriptions.Item>
                          <Link to="/auth/userManagement">Manage all users</Link>
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <Switch checked={application?.enabledSignup} onChange={() => updateApplication({ enabledSignup: !application.enabledSignup })} /> Enable signup
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  </>
                  : '' }
              </>
              : <Skeleton active /> }
          </Card>
        </Col>
      </Row>
      <Modal
        title="Deactivate 2FA"
        okText="Deactivate anyway"
        okType="danger"
        okButtonProps={{ htmlType: 'submit' }}
        onOk={deactivate2fa}
        visible={showModalDeactivateTfa}
        onCancel={() => setShowModalDeactivateTfa(false)}>
        <Typography.Paragraph><WarningOutlined /> This action strongly not recommended.</Typography.Paragraph>
        <Form onFinish={deactivate2fa}>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Password cannot be blank' }]}>
            <Input.Password placeholder="Password" prefix={<LockOutlined />} value={password} onChange={e => setPassword(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </Layout.Content>
  )
}

export default Account