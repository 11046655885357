import { State } from 'db'
import { FC, useEffect, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { blue, lime } from '../../../../../../util/colors'
import ButtonDragHandle from '../../../../../ButtonDragHandle'
import { nextStatesAllAtom, stateApplicationLogoUrl, stateNameAtom } from '../recoil/state'

interface StateItemWithChildsProps {
  isViewer?: boolean,
  isFirstPath?: boolean,
  states?: State[],
  state: State,
  level: number,
  onDrop: (droppedState: State, thisState: State) => void,
  onClickState: (stateId: number, isPath?: boolean) => void
}
const StateItemWithChilds: FC<StateItemWithChildsProps> = ({ isViewer, onDrop, isFirstPath, states, state, level, onClickState }) => {
  const { stateId, isPath } = useParams<{ stateId?: string, isPath?: string }>()
  const pathStates = states?.filter(s => s.conditionGroupOrder === state.conditionGroupOrder && state.stateConditionId === s.stateConditionId)
  const isFocusState = isPath ? isFirstPath && !!pathStates?.find(s => `${s.id}` === stateId) : stateId === String(state.id)
  const stateName = useRecoilValue(stateNameAtom(state.id))
  const appLogoUrl = useRecoilValue(stateApplicationLogoUrl(state.id))
  const [{ opacity, isDragging, draggingItem }, drag, preview] = useDrag(
    () => ({
      type: state?.conditionGroupOrder ? 'path' : state?.action ? 'action'  : '',
      item: state,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging(),
        draggingItem: monitor.getItem()
      }),
    }),
    [state],
  )
  const nextStatesAll = useRecoilValue(nextStatesAllAtom)
  const [isCanDropPath, setIsCanDropPath] = useState(false)
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [isCanDropPath ? 'path' : '', state?.action || state?.trigger ? 'action' : ''],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (droppedItem: State) => onDrop(droppedItem, state)
  })

  useEffect(() => {
    const childId = state?.childrenIds?.[0]
    const isChildIsPath = childId && states ? states.findIndex(currState => currState.id === childId && currState.conditionGroupOrder) > -1 : false
    const nextStateAllFromDraggedItem = draggingItem ? nextStatesAll?.[draggingItem.id] : null
    const isNotAChildOfDraggingState = nextStateAllFromDraggedItem?.findIndex(s => s.id === state.id) === -1

    const isCanDropPath = (isChildIsPath  || !state.childrenIds) && isNotAChildOfDraggingState
    setIsCanDropPath(isCanDropPath)
  }, [nextStatesAll, draggingItem, states])

  const isActive = isOver && canDrop && !isDragging
  let borderColor = 'transparent'
  let borderBottomWidth = '1px'
  if (isActive) {
    borderColor = '#FFF'
    borderBottomWidth = '38px'
  } else if (canDrop) {
    borderColor = blue[1]
  }

  return (
    <>
      <div></div>
      {
        isFirstPath && state ?
          <StyledSpace style={{ paddingLeft: `${(level-1) * 32}px` }} onClick={() => state.id && onClickState(state.id, true)}>
            <ContainerHighlight canDrag={false} isFocus={isPath ? isFocusState : undefined}>
              <StyledButtonDragger canDrag={false}  />
              <NumberIcon>{state.order + 1}</NumberIcon>
              <img src={appLogoUrl || (state.trigger || state.action)?.application?.logoUrl || '/placeholder.png'} width="24px" height="24px" />
              Path Condition
            </ContainerHighlight>
          </StyledSpace>:
          null
      }
      {
        state ?
          <div ref={drop}>
            <StyledSpace style={{ paddingLeft: `${level * 32}px`, opacity, borderBottom: '1px solid', borderBottomWidth, borderColor, transition: 'all .12s linear' }} onClick={() => state.id && onClickState(state.id)}>
              <ContainerHighlight ref={preview} canDrag={!state.trigger && !isViewer} isFocus={!isPath ? isFocusState : undefined}>
                <StyledButtonDragger title={!state.trigger ? 'Move this step' : undefined} canDrag={!state.trigger && !isViewer} ref={isViewer ? undefined : drag} />
                <NumberIcon>{state.conditionGroupOrder ? '' : state.order + 1}</NumberIcon>
                {state.conditionGroupOrder ? null : <img src={appLogoUrl || (state.trigger || state.action)?.application?.logoUrl || '/placeholder.png'} width="24px" height="24px" />}
                {stateName  || state.name || (state.trigger || state.action)?.name || (state.trigger || state.action)?.application?.name}
              </ContainerHighlight>
            </StyledSpace>
          </div> :
          null
      }
      {
        states?.filter(s => s.stateConditionId === state?.id).length !== 0 ?
          <>
            {
              states?.filter(s => s.stateConditionId === state?.id).sort((s1, s2) => s1.order - s2.order || s1.id - s2.id).map((s, idx, currStates) => {
                return <StateItemWithChilds
                  isViewer={isViewer}
                  isFirstPath={s.conditionGroupOrder && !currStates[idx-1]?.conditionGroupOrder ? true : false}
                  key={`${s.id}-${idx}`}
                  onClickState={onClickState}
                  state={s}
                  onDrop={onDrop}
                  level={level + (s?.conditionGroupOrder ? 2 : 1)}
                  states={states}  />
              })
            }
          </> :
          null
      }
    </>
  )
}

export default StateItemWithChilds

const StyledButtonDragger = styled(ButtonDragHandle)<{ canDrag: boolean }>`
  opacity: 0;
  &:hover {
    opacity: ${({ canDrag }) => canDrag ? '1' : '0'};
  }
  cursor: ${({ canDrag }) => canDrag ? 'move' : ''}; /* fallback if grab cursor is unsupported */
  /* cursor: ${({ canDrag }) => canDrag ? 'grab' : ''};
  cursor: ${({ canDrag }) => canDrag ? '-moz-grab' : ''};
  cursor: ${({ canDrag }) => canDrag ? '-webkit-grab' : ''};
  padding: 0;
  border: 0;
  width: max-content;
  height: max-content; */
`
const NumberIcon = styled.div`
  width: 24px;
  height: 24px;

  /* Neutral/1 */
  background: #FFFFFF;
  /* Character/Disabled&amp;Placeholder .25 */
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 32px;
  /* P2 - 12 description */
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  justify-content: center;

  /* Character/Disabled&amp;Placeholder .25 */

  color: rgba(0, 0, 0, 0.25);

  cursor: pointer;

`

const StyledSpace = styled.div`
  margin-bottom: 4px;
  /* padding-top: 8px; */
  cursor: pointer;
  white-space: nowrap;
  /* width: max-content; */
  

  /* display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, max-content);
  align-items: center; */
`
const ContainerHighlight = styled.div<{ isFocus?: boolean, canDrag?: boolean }>`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, max-content);
  align-items: center;
  padding: 4px;
  padding-right: 25px;
  min-width: fit-content;

  background: ${({ isFocus }) => isFocus ? lime[1] : 'transparent' };
  border: ${({ isFocus }) => isFocus ? `1px solid ${lime[4]}` : 'transparent' };
  border-radius: 4px;
  &:hover {
    background: ${({ isFocus }) => isFocus ? lime[1] : '#F5F5F5' };
    ${StyledButtonDragger} {
      opacity: ${({ canDrag }) => canDrag ? '1' : '0'};
    }
  }
`