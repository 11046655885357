import { Layout } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import GlobalVariableForm from 'src/components/pages/developer/main/globalVariable/GlobalVariableForm'
import useCreateGlobalVariable from 'src/hooks/application/globalVariable/useCreateGlobalVariable'
import PageHeader from 'src/pages/components/PageHeader'
import { StyledCard } from 'src/pages/components/StyledComponents'
import styled from 'styled-components'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'

const CreateGlobalVariable: FC = () => {
  const [form] = useForm()
  const [version] = useApplicationVersionContext()
  const { applicationId } = useParams<{ applicationId: string, versionId: string }>()
  const { createGlobalVariable, creatingGlobalVariable } = useCreateGlobalVariable()
  const history = useHistory()

  const onSubmit = (data: any) => {
    createGlobalVariable(parseInt(applicationId), data, version?.id).then(() => {
      history.push(`/developer/application/${applicationId}/${version?.name}/main/globalVariable`)
    })
  }
  return (
    <StyledContainer>
      <PageHeader
        title="Create Global Variable"
      />
      <StyledCard>
        <GlobalVariableForm
          form={form}
          onSubmit={onSubmit}
          submitting={creatingGlobalVariable}
        />
      </StyledCard>
    </StyledContainer>
  )
}

export default CreateGlobalVariable

const StyledContainer = styled(Layout.Content)`
  ${StyledCard} {
    margin: 0 24px 16px;
    padding: 0;
  }
`