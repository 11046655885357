import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined } from '@ant-design/icons'
import { Badge, Dropdown, Menu, Row } from 'antd'
import { DateTime } from 'luxon'
import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import useInvoices from '../../../../hooks/payment/useInvoices'
import useStatusPayment from '../../../../hooks/payment/useStatusPayment'
import { success } from '../../../../util/colors'
import { StyledButton } from '../../../components/StyledComponents'
import moment from 'moment'

const BarNavigation: FC = () => {
  const { result } = useStatusPayment()
  const { invoices } = useInvoices()
  const options = result && invoices ? [
    {
      value: undefined,
      label: `${DateTime.fromJSDate(new Date((result?.subscription?.latestInvoice?.period_end || 0) * 1000)).toFormat('DD t')} - ${DateTime.fromJSDate(moment((result?.subscription?.latestInvoice?.period_end || 0) * 1000).add({ month: 1 }).toDate()).toFormat('DD t')}`
    },
    ...(invoices || [])?.filter((_, idx, invoices) => invoices[idx + 1]).map(invoice => ({
      value: invoice.id,
      label: `${DateTime.fromJSDate(new Date(invoice.period_start * 1000)).toFormat('DD t')} - ${DateTime.fromJSDate(new Date(invoice.period_end * 1000)).toFormat('DD t')}`
    }))] : []
  const { invoiceId } = useParams<{ invoiceId?: string }>()
  const history = useHistory()
  const [active, setActive] = useState<string>()
  const [prev, setPrev] = useState<{ value?: string, label: string }>()
  const [next, setNext] = useState<{ value?: string, label: string }>()

  useEffect(() => {
    const active = options.find(opt => opt.value === invoiceId)?.value
    const activeIdx = options?.findIndex(opt => opt.value === invoiceId)
    const prev = options?.[activeIdx + 1]
    const next = options?.[activeIdx - 1]
    setActive(active)
    setPrev(prev)
    setNext(next)
  }, [invoiceId, result, invoices])

  const onClickNext = () => history.push(`/app/billing-usage/usage-history${next?.value ? `/${next.value}` : ''}`)
  const onClickPrev = () => history.push(`/app/billing-usage/usage-history${prev?.value ? `/${prev.value}` : ''}`)

  const onClickMenu = (e: any) => history.push(`/app/billing-usage/usage-history${options[e.key].value ? `/${options[e.key].value}` : ''}`)

  const menu = <Menu>
    {options?.map((opt, idx) =>
      <Menu.Item key={idx} onClick={onClickMenu} >
        <Badge color={opt.value === undefined ? success : undefined} status={opt.value !== undefined ? 'default' : undefined} />{opt.label}
      </Menu.Item>
    )}
  </Menu>

  return (
    <StyledBarNavigation gutter={[16, 16]} justify="space-between" align="middle">
      <StyledButton disabled={!prev} onClick={onClickPrev} type="link" icon={<ArrowLeftOutlined />}>See Previous Cycle Report</StyledButton>
      <StyledDropdown overlay={menu}>
        <strong onClick={e => e.preventDefault()}>
          <Badge color={active === undefined ? success : undefined} status={active !== undefined ? 'default' : undefined} /> {options?.find(opt => opt.value === active)?.label || 'Loading..'} <DownOutlined />
        </strong>
      </StyledDropdown>
      <StyledButton disabled={!next} onClick={onClickNext} type="link">See Next Cycle Report <ArrowRightOutlined /></StyledButton>
    </StyledBarNavigation>
  )
}

export default BarNavigation

const StyledBarNavigation = styled(Row)`
  background: #FFF;
  padding: 8px;
  margin-bottom: 8px;
`
const StyledDropdown = styled(Dropdown)`
`