import { FC } from 'react'
import { PropsContentStep } from '../create/data'
// import ExamplePayloadForm from './ExamplePayloadForm'
import FieldSchemaForm from './FieldSchemaForm'
import RedirectUrl from './RedirectUrl'
import StepContentForm from './StepContentForm'
import TestAuthentication from './TestAuthentication'


const StepContent: FC<PropsContentStep> = ({ fields, schemaFields, ...props })=> {
  if (fields.length === 1) {
    const field = fields[0]
    if (field === 'fieldSchema') {
      const schemaField = schemaFields.find(sf => sf.name === field)
      return <FieldSchemaForm {...props} isRequired={schemaField?.required} fields={fields} schemaFields={schemaFields}  />
    }
    if (field === '{{examplePayload}}') {
      // return <ExamplePayloadForm {...props} fields={fields} schemaFields={schemaFields}  />
      return null
    }
    if (field === '{{test}}') {
      return <TestAuthentication {...props} fields={fields} schemaFields={schemaFields}/>
    }
    if (field === '{{oauthRedirectUrl}}') {
      return <RedirectUrl {...props} fields={fields} schemaFields={schemaFields}/>
    }
  }
  return <StepContentForm fields={fields} schemaFields={schemaFields} {...props} />
}

export default StepContent