import React, { FC, useState } from 'react'
import { Action, Application, State, Trigger } from 'db'
import { Col, Row, Spin } from 'antd'
import { CaretRightFilled, ThunderboltFilled } from '@ant-design/icons'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useCreateScenario from '../../../../../../hooks/scenario/useCreateScenario'
import { StyledButton, StyledCard, StyledTag, StyledText } from '../../../../../../pages/components/StyledComponents'
import { useSharedValueScenario } from '../contexts/SharedValueScenarioContextProvider'
import SearchWhenOrDoForm from './SearchWhenOrDoForm'
import SearchDoForm from './SearchDoForm'
import TemplateScenarios from './TemplateScenarios'

const StepTwoChooseTriggerAction: FC<{ selectedApplications: Application[] }> = ({ selectedApplications }) => {
  const [values, setValues] = useSharedValueScenario()
  const [selectedTrigger, setSelectedTrigger] = useState<Trigger>()
  const [selectedActions, setSelectedActions] = useState<Action[]>([])
  const { createScenario, creatingScenario } = useCreateScenario()
  const history = useHistory()

  const onRemove = (type: 'trigger' | 'action', idx: number, e: any) => {
    if (type === 'trigger') {
      const newStates = values?.states?.map((state, idx) => {
        if (idx !== 0) {
          return state
        }
        const newState: State = { ...state, trigger: undefined, triggerId: undefined } as State
        return newState
      })
      setValues(prevState => ({ ...prevState, states: newStates }))
    } else {
      const newStates = values?.states?.filter((_, currIdx) => currIdx !== idx)
      setValues(prevState => ({ ...prevState, states: newStates }))
      e.preventDefault()
    }
  }

  const onCreateScenario = async () => {
    const name = values?.states ? `When ${values.states[0].trigger?.name}, ${values.states[1].action?.name}` : undefined
    const scenario = await createScenario({
      name: name,
      states: values?.states?.map(state => ({ name: state.trigger?.name || state.action?.name, triggerId: state.triggerId, actionId: state.actionId })) as State[]
    })
    if (scenario?.id) {
      return history.push(`/app/scenario/${scenario.id}/edit`)
    }
  }

  const onClickTrigger = (selectedTrigger: Trigger) => {
    setSelectedTrigger(selectedTrigger)
  }

  // TODO not yet done, implement to ditch useSharedValue usage
  const onClickAction = (selectedAction: Action) => {
    // TODO still wrong implementation just to make sure it pass the build
    setSelectedActions(prevState => [...prevState, selectedAction])
  }

  return (
    <StyledScenariosStep spinning={creatingScenario}>
      <br />
      {
        selectedApplications.filter(application => application?.id).length !== 0 ?
          <>
            <Row gutter={[16, 16]}>
              <Col md={12} sm={24}>
                <SearchWhenOrDoForm
                  type="when"
                  selectedApplications={selectedApplications}
                  selectedTrigger={selectedTrigger}
                  selectedActions={selectedActions}
                  onClickTrigger={onClickTrigger}
                />
              </Col>
              <Col md={12} sm={24}>
                <SearchDoForm
                  type="do"
                  selectedApplications={selectedApplications}
                  selectedTrigger={selectedTrigger}
                  selectedActions={selectedActions}
                  onClickAction={onClickAction}
                />
              </Col>
            </Row>
            <br />
            <SelectedTriggerActionConfirmation>
              <StyledContainerListTriggerAndAction gutter={8}>
                {
                  values?.states && values?.states.length > 0 ?
                    values?.states?.map((state, idx, states) => {
                      if (!state) {
                        return null
                      }
                      const { trigger, action } = state
                      if (trigger) {
                        const { name } = trigger
                        return <Col key={idx} flex={0}>
                          <div className="wrapper-tag">
                            <StyledTag style={{ background: '#F9EBCB', borderColor: '#F3AD3D', whiteSpace: 'nowrap' }}>{name}</StyledTag>
                            {states.length === idx + 1 ? null : <CaretRightFilled />}
                          </div>
                        </Col>
                      }

                      if (action) {
                        const { name } = action
                        return <Col key={idx} flex={0}>
                          <div className="wrapper-tag">
                            <StyledTag style={{ background: '#ECF6D2', borderColor: '#96C443' }} key={idx} closable onClose={(e) => onRemove('action', idx, e)}>{idx}. {name}</StyledTag>
                            {states.length === idx + 1 ? null : <CaretRightFilled />}
                          </div>
                        </Col>
                      }
                      return null
                    }) :
                    <Col><StyledText>Select Trigger {'&'} Action First ☝️</StyledText></Col>
                }
              </StyledContainerListTriggerAndAction>
              <br />
              <StyledButton
                type="primary"
                style={{ width: '100%' }}
                loading={creatingScenario}
                icon={<ThunderboltFilled />}
                onClick={onCreateScenario}
                disabled={!values?.states || values.states.length < 2 || values?.states && !values.states[0]?.triggerId }
              >
                Create Scenario
              </StyledButton>
            </SelectedTriggerActionConfirmation>
            <br />
          </> : null
      }
      <TemplateScenarios selectedApplications={selectedApplications} />
    </StyledScenariosStep>
  )
}

export default StepTwoChooseTriggerAction

const StyledContainerListTriggerAndAction = styled(Row)`
  flex-wrap: nowrap;
  overflow-x: auto;
  .wrapper-tag {
    display: flex;
    align-items: center;
    ${StyledTag} {
      white-space: nowrap;
      font-family: 'DM Sans', sans-serif;
      color: rgba(0, 0, 0, 0.65);
    }
  }
`

const SelectedTriggerActionConfirmation = styled(StyledCard)`
  padding: 0;

  & .ant-card-body {
    padding: 16px;
  }
`

const StyledScenariosStep = styled(Spin)`
  ${StyledCard} {
    ${StyledButton} {
      width: 100%;
    }
  }
`