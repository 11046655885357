import { Layout } from 'antd'
import { FC } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import DataSuggestionContextProvider from '../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useAllAuthentication from '../../../../../hooks/application/authentication/useAllAuthentication'
import SharedValuesContextProvider from './components/SharedValuesContextProvider'
import FrontCreate from './create/Front'
import CreateAuthenticationSteps from './create/Steps'
import FrontEdit from './edit/Front'

const Authentication: FC = () => {
  const { subPage, versionId  } = useParams<{ versionId: string, applicationId: string, subPage?: string }>()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { authentications } = useAllAuthentication(applicationId, Number(versionId))

  return (
    <StyledPage>
      <SharedValuesContextProvider>
        <DataSuggestionContextProvider>
          {
            subPage ?
              <>
                <CreateAuthenticationSteps />
              </> :
              <>
                {
                  authentications?.length === 0 ?
                    <FrontCreate /> :
                    <FrontEdit />
                }

              </>
          }
        </DataSuggestionContextProvider>
      </SharedValuesContextProvider>
    </StyledPage>
  )
}

export default Authentication

const StyledPage = styled(Layout.Content)``