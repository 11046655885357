import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, Space } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import { StyledBoxIcon } from '../../../../../../pages/components/StyledComponents'
import { orange } from '../../../../../../util/colors'
import { compareString } from '../../../../../../util/functions'
import truncateLongData from '../../../../../../util/truncateLongData'
import { DataSuggestion } from '../../../../developer/formItems/DataPickerPopper'
import { useValueArray } from '../StateForm/ArrayMapStateFormItem/ValueArrayContext'
import { useStateParentsData } from '../StateTestDataContext'
import { useThisStateStaticValue } from '../ThisStateStaticValueContext'

export type CustomDataAccordionProps = {
  statesWithTestData: Record<string, DataSuggestion> | null, // trigger and trigger.application should be expanded
  onTagClick: (tagValue: string) => void,
  searchTerm: string
}

const CustomDataAccordion: FC<CustomDataAccordionProps> = ({ statesWithTestData: suggestionsProps, searchTerm, onTagClick }) => {
  const [thisStateStaticValue] = useThisStateStaticValue()
  const thisStateId = thisStateStaticValue?.id
  const [parentsStates] = useStateParentsData()
  const [arrayMap] = useValueArray()

  const statesWithTestData = arrayMap?.dataSuggestion || suggestionsProps

  const filterStateTestData = (stateId: string) => {
    if (stateId === 'ctx') return true
    return statesWithTestData && parentsStates && thisStateId ?
      parentsStates[thisStateId]?.includes(Number(stateId)) &&
      statesWithTestData[stateId]?.type !== 'path' && (statesWithTestData[stateId].order || 0) > -1
      :
      false
  }
  return (
    <CustomDataAccordionContainer>
      {
        statesWithTestData && Object.keys(statesWithTestData).filter(filterStateTestData).length > 0 ?
          <Collapse
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            // accordion
            defaultActiveKey={
              Object.keys(statesWithTestData)
                .filter(filterStateTestData)
                .sort((s1, s2) => s1 === 'ctx' ? -1 : s2 === 'ctx' ? 1 : -1 * ((statesWithTestData[s1].order || 0) - (statesWithTestData[s2].order || 0)))
                .map(stateId => `${statesWithTestData[stateId].id}_${statesWithTestData[stateId].order}`)
                .slice(0, 1)[0]
            }
          >
            {
              Object.keys(statesWithTestData)
                .filter(filterStateTestData)
                .sort((s1, s2) => s1 === 'ctx' ? -1 : s2 === 'ctx' ? 1 : -1 * ((statesWithTestData[s1].order || 0) - (statesWithTestData[s2].order || 0)))
                .map(stateId => {
                  const s = statesWithTestData[stateId]

                  const flatTestData: DataSuggestion['flatTestData'] = s.flatTestData

                  const TestDataHeader =
                    <Space>
                      {
                        s.application?.logoUrl ?
                          <CustomStyledBoxIcon>
                            <img src={s?.application?.logoUrl || '/placeholder.png'} />
                          </CustomStyledBoxIcon> :
                          null
                      }
                      <strong>{!s.name ? `${(s.order || 0) + 1}. ${s?.stateName || s.triggerActionName}` : s.name}</strong>
                    </Space>

                  return (
                    <CustomCollapseItem header={TestDataHeader} key={`${s.id}_${s.order}`}>
                      {
                        flatTestData ?
                          Object.keys(flatTestData)
                            .filter(td => searchTerm ? `${flatTestData[td].label}${JSON.stringify(flatTestData[td].sample)}`.toLowerCase().includes(searchTerm.toLowerCase()) : true)
                            .sort((td1, td2) => {
                              return compareString(td1, td2)
                            })
                            .map(td => {
                              const label = flatTestData[td].label
                              const value = flatTestData[td].value
                              const sample = flatTestData[td].sample
                              const truncatedSample = truncateLongData(sample, 60) // template literal is necessary to forces boolean parsed into a string

                              return (
                                <TestData key={td} onClick={() => onTagClick(s.id && td !== `${s.id}` ? `${s.id}.${td}` : value)}>
                                  {
                                    s.application?.logoUrl ?
                                      <TestDataAppLogo>
                                        <img src={s?.application?.logoUrl || '/placeholder.png'} />
                                      </TestDataAppLogo> :
                                      null
                                  }
                                  <p style={{ wordBreak: 'break-word', margin: 0 }}>
                                    <strong>{`${label?.replace(/ \(object\)| \(array\)/g, '')}`}{label?.includes('(array)') ? <> <span style={{ color: orange[5] }} >(array)</span>: </> :
                                      label?.includes('(object)') ? <> <span style={{ color: orange[5] }} >(object)</span>: </> :
                                        ': '}</strong>
                                    {truncatedSample.replace(/\n/g, ' ')}
                                  </p>
                                </TestData>
                              )

                            }) : !s.name ? <div>No test data available yet. Set up and test "{(s.order || 0) + 1}. {s.triggerActionName}" first.</div> : 'No Suggestions'
                      }
                    </CustomCollapseItem>
                  )
                })
            }
          </Collapse> :
          <NoTestDataContainer>
            No test data available. You can still input a custom value into the text area.
          </NoTestDataContainer>
      }
    </CustomDataAccordionContainer>
  )
}

export default CustomDataAccordion

const TestData = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  width: fit-content;
  color: rgb(0, 0, 0, .65);
  padding: 0 4px;
  cursor: pointer;
  
  background: #f5f5f5;
  border: 1px solid #D9D9D9;

  box-sizing: border-box;
  border-radius: 2px;

  &:hover {
    background: #F9EBCB;
    border: 1px solid #F3AD3D;
  }
`

const NoTestDataContainer = styled.div`
  background: #FFF;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
`

const TestDataAppLogo = styled(StyledBoxIcon)`
  width: 16px;
  height: 16px;
  margin-top: 3px;
  border: none;

  img {
    width: 16px;
    height: 16px;
  }
`

const CustomCollapseItem = styled(Collapse.Panel)`
  border-radius: 0;
  border-bottom: none;
  border-color: #f0f0f0;

  & .ant-collapse-header {
    display: flex;
    align-items: center;
    .ant-collapse-arrow {
      top: 8px !important;
    }
  }
  
  & .ant-collapse-content {
    border-top: none;

    .ant-collapse-content-box {
      display: flex;
      padding-left: 40px;
      flex-direction: column;
      gap: 6px;
      padding-top: 4px;
    }
  }
`

const CustomStyledBoxIcon = styled(StyledBoxIcon)`
  width: 28px;
  height: 28px;
  img {
    width: 20px;
    height: 20px;
  }
`

const CustomDataAccordionContainer = styled.div`
  padding: 0;
  height: fit-content;
  max-height: 240px;
  overflow: auto;

  & .ant-collapse {
    background-color: #FFF;
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`