import { Scenario } from 'db'
import { useState } from 'react'
import { Implikasi } from '../../service/Implikasi'
import Notification from '../../util/Notification'

type Result = {
  resetScenario: (scenarioId: number) => void,
  responseResetingScenario?: Scenario,
  isSuccessResetingScenario: boolean,
  resetingScenario: boolean,
  errorResetScenario?: Error
}

export default function useResetAScenario(): Result {
  const [responseResetingScenario, setResponseResetingScenario] = useState()
  const [resetingScenario, setResetingScenario] = useState<boolean>(false)
  const [errorResetScenario, setErrorResetScenario] = useState<Error>()
  const [isSuccessResetingScenario, setIsSuccessResetingScenario] = useState<boolean>(false)

  const resetScenario = async (scenarioId: number) => {
    if (scenarioId) {
      try {
        setResetingScenario(true)
        const resetScenario = await Implikasi.post(`/scenario/${scenarioId}/reset`, {})

        if (resetScenario.status === 200 || resetScenario.status === 202) {
          setIsSuccessResetingScenario(true)
          Notification.success({
            message: 'Success',
            description: 'Success reset scenario'
          })
        } else {
          setIsSuccessResetingScenario(false)
        }
        setResponseResetingScenario(resetScenario.data)
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error
        })

        setErrorResetScenario(response?.data?.error)
      } finally {
        setResetingScenario(false)
      }
    }
  }

  return {
    resetScenario: resetScenario,
    isSuccessResetingScenario: isSuccessResetingScenario,
    responseResetingScenario: responseResetingScenario,
    resetingScenario: resetingScenario,
    errorResetScenario: errorResetScenario
  }
}