import { CaretRightOutlined } from '@ant-design/icons'
import { ButtonProps, Col, Collapse, Input, Modal, ModalProps, Radio, Row, Space } from 'antd'
import { SearchProps } from 'antd/lib/input'
import flatten from 'flat'
import { isEqual } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import useUpdateAScenarioState from '../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { StyledButton } from '../../../../../../pages/components/StyledComponents'
import { getFlatDataTest } from '../../../../../../util/formatter'
import { updatingScenarioOrState } from '../recoil'
import { useStateTestData } from '../StateTestDataContext'
import { useThisStateStaticValue } from '../ThisStateStaticValueContext'

interface Props {
  visible?: boolean,
  closeModal: ModalProps['onCancel']
}
const ModalChooseSample: FC<Props> = ({ visible, closeModal }) => {
  const [stateTestData, setStateTestData] = useStateTestData()
  const [thisStateStaticValue] = useThisStateStaticValue()

  const stateOrder = thisStateStaticValue?.order || 0
  const stateId = thisStateStaticValue?.id || 0
  const application = thisStateStaticValue?.application
  const scenarioId = thisStateStaticValue?.scenarioId || 0

  const realTestDataState: { data: any, index: number }[] | undefined = stateTestData?.[stateId]?.realTestData?.data
  const currentTestData = stateTestData?.[stateId]?.testData
  const currentSampleIndex = realTestDataState?.findIndex((data) => isEqual(data, currentTestData))
  const [samples, setSamples] = useState<{ data: any, index: number }[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setSearchValue('')
    setSelectedIndex(currentSampleIndex)
  }, [currentSampleIndex, visible])

  useEffect(() => {
    const realTestData = realTestDataState?.map((data, index) => ({
      data,
      index
    })) || []
    if (searchValue) {
      const filteredData = realTestData?.filter(({ data, index }) => {
        const stringData = JSON.stringify(data)
        const numbers = searchValue.match(/\d+/g)
        if (stringData.toLowerCase().includes(searchValue.toLowerCase()) || numbers?.includes(`${index + 1}`)) {
          return true
        }
        return false
      }).sort((data1, data2) => data1.index - data2.index)
      setSamples(filteredData)
    } else {
      setSamples(realTestData)
    }
  }, [searchValue, realTestDataState])

  const onChangeSample = (index: number) => {
    setSelectedIndex(index)
  }

  const onSubmit: ButtonProps['onClick'] = (e) => {
    if (selectedIndex) {
      const newTestData = realTestDataState?.[selectedIndex]
      updateAScenarioState(scenarioId, stateId, {
        testData: newTestData
      }).then(() => {
        setStateTestData(prevState => ({
          ...prevState,
          [stateId]: {
            ...prevState?.[stateId],
            testData: newTestData,
            flatTestData: getFlatDataTest({ testData: newTestData, stateOrder, stateId, application })
          }
        }))
        setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
        closeModal?.(e)
      })
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearchValue(value)
  }

  const getWidth = () => {
    const prevWidth = localStorage.getItem('widthStateForms')
    if (prevWidth) {
      return parseInt(prevWidth)
    }
    return undefined
  }
  const width = getWidth()
  return (
    <Modal
      destroyOnClose
      width={(width ? width > 800 ? 800 : width : 800) - 100}
      style={{
        top: '10vw',
        marginLeft: `calc(${width}px / 5)`
      }}
      className="base-custom"
      visible={visible}
      onCancel={closeModal}
      title={'Select sample item'}
      footer={<Row justify="space-between" gutter={[16, 16]}>
        <Col>
          <StyledButton bordered={false} danger ghost onClick={closeModal}>Cancel</StyledButton>
        </Col>
        <Col>
          <Space>
            {/* <StyledButton onClick={onRefresh} icon={<RedoOutlined />}>Refresh Items</StyledButton> */}
            <StyledButton loading={updatingAScenarioState} type="primary" onClick={onSubmit} >Select Item</StyledButton>
          </Space>
        </Col>
      </Row>}
    >
      <Input.Search allowClear defaultValue={searchValue} enterButton placeholder={'Search by number of item, value, or key'} onSearch={onSearch} />
      <br /> <br />
      <Collapse
        bordered={false}
        style={{
          border: '1px solid #F0F0F0',
          overflowY: 'auto',
          maxHeight: 300
        }}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        {
          samples.map((data) => {
            const ends = ['st', 'nd', 'rd', 'th']
            const itemNumber = data.index
            const end = (itemNumber + 1) % 10
            const isBelasan = itemNumber >= 10 && itemNumber < 20
            const endWord = isBelasan || end === 0 || end > 3 ? ends[3] : ends[end - 1]

            return (
              <Collapse.Panel style={{ borderColor: '#F0F0F0' }} key={itemNumber} header={`${itemNumber + 1}${endWord} Item`}
                extra={
                  <Radio onClick={(e) => {
                    e.stopPropagation()
                    onChangeSample(itemNumber)
                  }} checked={selectedIndex === itemNumber} />
                }
              >
                <DataViewer data={data.data} />
              </Collapse.Panel>
            )
          })
        }
      </Collapse>
    </Modal>
  )
}

const DataViewer: FC<{ data: any }> = ({ data }) => {
  const flatData: any = flatten(data)
  const [isExpanded, setIsExpanded] = useState(false)
  const keys = Object.keys(flatData || {})
  return (
    <>
      {keys?.filter((_, idx) => isExpanded ? true : idx < 2).map(key => <p key={key}>"{key}" : "{flatData[key]}"</p>)}
      {keys.length <= 2 ? null : <StyledButton style={{ marginLeft: 'auto', display: 'block' }} type="link" onClick={() => setIsExpanded(!isExpanded)} >{isExpanded ? 'Less' : 'More'}</StyledButton>}
    </>
  )
}

export default ModalChooseSample