import { Application } from 'db'
import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'
import { StateWithTestData } from './DataPickerPopper'

export type ContextValueStateWithTestDataType = {
  [stateId: string]: StateWithTestData
}

export type ChildStateType = { id: number, order: number, conditionGroupOrder?: number, application?: Application }
type ValueChildsState = { [stateId: number]: ChildStateType[] }
type ContextValueChildsType = ValueChildsState | null

export type ParentStateType = number
type ValueParentState = { [stateId: number]: ParentStateType[] }
type ContextValueParentsType = ValueParentState | null


const ChildsDataContext = createContext<ContextValueChildsType | null>(null)
const ChildsSetterContext = createContext<Dispatch<SetStateAction<ContextValueChildsType | null>>>(() => null)

const StateTestDataContext = createContext<ContextValueStateWithTestDataType | null>(null)
const StateTestDataSetterContext = createContext<Dispatch<SetStateAction<ContextValueStateWithTestDataType | null>>>(() => null)

const ParentsDataContext = createContext<ContextValueParentsType | null>(null)
const ParentsSetterContext = createContext<Dispatch<SetStateAction<ContextValueParentsType | null>>>(() => null)


type Props = {
  children: ReactNode
}

const StateTestDataContextProvider: FC<Props> = ({ children }) => {
  const [stateTestData, setStateTestData] = useState<ContextValueStateWithTestDataType | null>(null)
  const [childsData, setChildsData] = useState<ContextValueChildsType | null>(null)
  const [parentsData, setParentsData] = useState<ContextValueParentsType | null>(null)

  return (
    <StateTestDataContext.Provider value={stateTestData}>
      <StateTestDataSetterContext.Provider value={setStateTestData}>
        <ChildsDataContext.Provider value={childsData}>
          <ChildsSetterContext.Provider value={setChildsData}>
            <ParentsDataContext.Provider value={parentsData}>
              <ParentsSetterContext.Provider value={setParentsData}>
                {children}
              </ParentsSetterContext.Provider>
            </ParentsDataContext.Provider>
          </ChildsSetterContext.Provider>
        </ChildsDataContext.Provider>
      </StateTestDataSetterContext.Provider>
    </StateTestDataContext.Provider>
  )
}

export function useStateTestData(): [ContextValueStateWithTestDataType | null, Dispatch<SetStateAction<ContextValueStateWithTestDataType | null>>] {
  const valueContext = useContext(StateTestDataContext)
  const setterContext = useContext(StateTestDataSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useStateTestData must be used on child component of StateTestDataContextProvider component'
  }

  return [valueContext, setterContext]
}

export default StateTestDataContextProvider

export function useStateChildsData(): [ContextValueChildsType | null, Dispatch<SetStateAction<ContextValueChildsType | null>>] {
  const valueContext = useContext(ChildsDataContext)
  const setterContext = useContext(ChildsSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useStateChildsData must be used on child component of StateTestDataContextProvider component'
  }

  return [valueContext, setterContext]
}

export function useStateParentsData(): [ContextValueParentsType | null, Dispatch<SetStateAction<ContextValueParentsType | null>>] {
  const valueContext = useContext(ParentsDataContext)
  const setterContext = useContext(ParentsSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useStateParentsData must be used on child component of StateTestDataContextProvider component'
  }

  return [valueContext, setterContext]
}