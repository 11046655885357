import { Checkbox, Input, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Application, ApplicationReview } from 'db'
import { FC, useEffect, useState } from 'react'
import useCreateApplicationReview from '../../../../../hooks/application/applicationReview/useCreateApplicationReview'
import Notification from '../../../../../util/Notification'
import CustomForm from '../../../components/form/CustomForm'
import FormItem from '../../../components/form/FormItem'

type Props = {
  type: 'accept' | 'reject',
  afterSubmit?: (values: ApplicationReview) => void,
  onCancel?: () => void,
  application?: Application
}

const ApplicationReviewFormModal: FC<Props> = ({ type, application, afterSubmit, onCancel }) => {
  const [visible, setVisible] = useState(false)
  const [form] = useForm<Partial<ApplicationReview>>()
  const { createApplicationReview, creatingApplicationReview, createdApplicationReview } = useCreateApplicationReview()

  const contents = {
    accept: {
      title: 'Accept Application',
      okText: 'Accept',
      background: '#96C443'
    },
    reject: {
      title: 'Reject Application',
      okText: 'Reject',
      background: '#BC4A40'
    }
  }

  useEffect(() => {
    if (application) {
      open()
      form.setFieldsValue({
        isApproved: type === 'accept'
      })
    }
  }, [application])

  const open = () => {
    setVisible(true)
  }

  const close = () => {
    setVisible(false)
    if (onCancel) {
      onCancel()
    }
  }

  const openNotif = (type: 'success' | 'error' | 'info', title: string, description: string) => {
    Notification[type]({
      message: title,
      description: description
    })
  }

  const onSubmit = (values: any) => {
    createApplicationReview(Number(application?.id), values)
  }

  useEffect(() => {
    if (createdApplicationReview) {
      if (afterSubmit) {
        afterSubmit(createdApplicationReview)
      }
      if (type === 'accept') {
        openNotif('success', `You have successfully accepted ${application?.name} App Submission`, `Feedback sent to admin of ${application?.name} app`)
      } else if (type === 'reject') {
        openNotif('info', `You have successfully rejected ${application?.name} App Submission`, `Feedback sent to admin of ${application?.name} app`)
      }

      form.resetFields()
      close()
    }
  }, [createdApplicationReview])


  return (
    <>
      <Modal
        visible={visible}
        title={contents[type].title}
        okText={contents[type].okText}
        okButtonProps={{ style: { background: contents[type].background } }}
        cancelText="Cancel"
        confirmLoading={creatingApplicationReview}
        onCancel={close}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values)
            })
            .catch(() => {
              // console.log('Validate Failed:', info)
            })
        }}
      >
        <CustomForm form={form} initialValues={{ isApproved: type === 'accept' }}>
          <FormItem
            name="isApproved"
            valuePropName="checked"
            hidden
          >
            <Checkbox />
          </FormItem>
          <FormItem
            name="feedback"
            label="Feedback"
            rules={[{ required: true, message: 'Please give feedback!' }]}
          >
            <Input.TextArea />
          </FormItem>
        </CustomForm>
      </Modal>
    </>
  )
}

export default ApplicationReviewFormModal