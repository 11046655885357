import { UploadOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import useImportTriggerAction from '../../../../hooks/application/main/useImportTriggerAction'
import { StyledButton } from '../../../../pages/components/StyledComponents'
import Notification from '../../../../util/Notification'

interface Props {
  type: 'trigger' | 'action',
  applicationId: number,
  versionId?: number,
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>
}

const ModalImportTriggerAction: FC<Props> = ({ visible, setVisible, applicationId, versionId, type }) => {
  const [fileList, setFileList] = useState<any[]>()
  const { importTriggerAction, importingTriggerAction } = useImportTriggerAction()

  const propsUpload = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: file => {
      setFileList([file])
      return false
    },
    fileList,
  }

  const close = () => setVisible(false)

  const onOKUpload = () => {
    Modal.confirm({
      title: `Are you sure you want to import these ${type}s?`,
      content: `if you upload ${type === 'action' ? 'trigger' : 'action'} file it will also uploaded directly to your ${type === 'action' ? 'trigger' : 'action'} list`,
      onOk() {
        if (versionId) {
          importTriggerAction(applicationId, versionId, fileList?.[0], close)
        } else {
          Notification.error({
            message: 'Something wrong'
          })
        }
      },
      onCancel: close
    })
  }

  return (
    <Modal visible={visible}
      title={`Import ${type === 'trigger' ? 'Trigger' : 'Action'} by JSON file`}
      onOk={onOKUpload}
      onCancel={close}
      okButtonProps={{ disabled: !fileList?.[0], loading: importingTriggerAction }}
    >
      <Upload {...propsUpload}>
        <StyledButton icon={<UploadOutlined />}>Select File</StyledButton>
      </Upload>
    </Modal>
  )
}

export default ModalImportTriggerAction