import { RedoOutlined } from '@ant-design/icons'
import { Affix, Badge, ButtonProps, Divider, Pagination, Skeleton, Space, Steps, Tag, Tooltip } from 'antd'
import { LogTask, State } from 'db'
import { DateTime } from 'luxon'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import useAScenario from 'src/hooks/useAScenario'
import styled from 'styled-components'
import useLogTasks from '../../../../../hooks/job/logTask/useLogTasks'
import useLogJob from '../../../../../hooks/job/useLogJob'
import useRetryLogJob from '../../../../../hooks/job/useRetryLogJob'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import PageHeader from '../../../../../pages/components/PageHeader'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../types'
import { PAGE_SIZE_OPTIONS } from '../../../../../util/Constant'
import LayoutMyScenarioPage from '../../scenario/all/LayoutMyScenarioPage'
import TaskCard from './TaskCard'

const DetailJob: FC = () => {
  // const { url } = useRouteMatch()
  const { push, location: { search } } = useHistory()
  const { id } = useParams<{ id?: string }>()
  const { logJob } = useLogJob(id)
  const { scenario } = useAScenario(logJob?.scenarioId, true, true)
  const [tableState, setTableState] = useState<TableState<LogTask>>({
    pagination: {
      pageSize: 50,
      current: 1
    }
  })
  const { logTasks, logTasksLength } = useLogTasks(id, tableState)
  const { retryJob, retryingLogJob, logJob: retriedLogJob } = useRetryLogJob()
  const { revalidateLogJob } = useRevalidateFetch()

  const jobCompletionStatus = logJob?.status || 'running'
  const isJobRetriable = scenario?.deletedAt && logJob?.error || ['error', 'partialError'].includes(jobCompletionStatus)

  useEffect(() => {
    if (retriedLogJob && !retryingLogJob) {
      revalidateLogJob(id)
    }
  }, [retriedLogJob, retryingLogJob])

  const onClickEditScenario: ButtonProps['onClick'] = (e) => {
    e.preventDefault()
    push(`/app/scenario/${logJob?.scenarioId}/edit`)
  }

  const onClickRetryJob = () => {
    if (id) {
      retryJob(parseInt(id))
    }
  }

  return (
    <LayoutMyScenarioPage propsPageHeader={{
      title: 'Job History'
    }}>
      <StyledDetailJob>
        <StyledPageHeader style={{ paddingLeft: 0, paddingTop: 0 }} onBack={() => push({
          pathname: '/app/scenario/all/history/job',
          search: search
        })} title={<h5>Job Detail</h5>} />
        {
          logJob ?
            <>
              <h4>
                {scenario?.name} {scenario?.deletedAt ? <Tag color="orange">Archived Scenario</Tag> : null}
                {logJob?.deletedAt ? <Tag color="yellow">Archived Job</Tag> : null}
              </h4>
              <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                <Space>
                  <Badge
                    status={jobCompletionStatus === 'complete' ? 'success' : jobCompletionStatus === 'error' || jobCompletionStatus === 'partialError' ? 'error' : 'processing'}
                    text={<span style={{ textTransform: 'capitalize' }}>{logJob?.status || 'running'}</span>}
                  />
                  {
                    isJobRetriable  ?
                      <Tooltip title={scenario?.deletedAt ? 'You can\'t retry failed job from an archived scenario' : null }>
                        <StyledButton
                          type="primary"
                          disabled={!isJobRetriable}
                          onClick={onClickRetryJob}
                          loading={retryingLogJob}
                          icon={<RedoOutlined />}
                        >
                          Retry Job
                        </StyledButton>
                      </Tooltip> : null
                  }
                </Space>
                <Divider type="vertical" />
                {logJob?.createdAt ? DateTime.fromJSDate(new Date(logJob?.createdAt)).toFormat('DD HH:mm:ss.SSS') : null}
                {scenario?.deletedAt ? null : <StyledButton onClick={onClickEditScenario} href={`/app/scenario/${logJob?.scenarioId}/edit`} type="link" >Edit Scenario</StyledButton>}
              </span>
            </>
            :
            <Skeleton paragraph={{ rows: 1 }} />
        }
        {
          logJob && logTasks ?
            <Steps direction="vertical" className={logJob.status === 'complete' ? 'success' : logJob?.status === 'error' || logJob?.status === 'partialError' ? 'error' : 'processing'}>
              {
                tableState.pagination?.current === 1 ?
                  <Steps.Step
                    status="process"
                    description={
                      <TaskCard
                        // number={1}
                        executionTime={logJob.executionTime}
                        logTaskId={0}
                        type="Trigger"
                        triggerActionName={(logJob.statesSnapshots?.[0] as State)?.trigger?.name}
                        name={(logJob.statesSnapshots?.[0] as State)?.name}
                        logoUrlDataIn={(logJob.statesSnapshots?.[0] as State)?.trigger?.application?.logoUrl}
                        logoUrlDataOut={(logJob.statesSnapshots?.[0] as State)?.trigger?.application?.logoUrl}
                        dataIn={logJob?.dataIn}
                        dataOut={logJob?.dataOut}
                        error={logJob?.error}
                        createdAt={logJob?.createdAt ? String(logJob.createdAt) : undefined} />
                    }
                  />
                  :
                  null
              }
              {
                logTasks?.map((logTask, idx, logTasks) => {
                  const { executionTime, id, dataIn, dataOut, error, createdAt, stateSnapshot } = logTask
                  const prevStateSnapshot = logTasks[idx - 1]?.stateSnapshot
                  const pagination = tableState.pagination
                  return (
                    <Steps.Step
                      icon={
                        <div style={{ color: '#FFF', fontSize: 16 }}>
                          {idx + 2 + ((pagination?.current || 0) - 1) * (pagination?.pageSize || 0)}
                        </div>}
                      key={id}
                      status="process"
                      description={
                        <TaskCard
                        // number={idx + 2}
                          executionTime={executionTime}
                          logTaskId={id}
                          type='Action'
                          triggerActionName={(stateSnapshot as State)?.action?.name}
                          dataIn={dataIn}
                          dataOut={dataOut}
                          name={(stateSnapshot as State)?.name}
                          logoUrlDataIn={idx === 0 ? (logJob.statesSnapshots[0] as State)?.trigger?.application?.logoUrl : (prevStateSnapshot as State).action?.application?.logoUrl}
                          logoUrlDataOut={(stateSnapshot as State)?.action?.application?.logoUrl}
                          error={error}
                          createdAt={String(createdAt)} />
                      }
                    />
                  )
                })
              }
            </Steps>
            :
            <Steps direction="vertical" className={logJob?.status === 'complete' ? 'success' : logJob?.status === 'error' || logJob?.status === 'partialError' ? 'error' : 'processing'}>
              <Steps.Step
                status="process"
                description={
                  <div style={{ border: '1px solid #d9d9d9', padding: 16, margin: 24 }}>
                    <Skeleton avatar paragraph={{ rows: 4 }} />
                  </div>
                }
              />
            </Steps>
        }
      </StyledDetailJob>
      <Affix offsetBottom={10}>
        <div className="bg-white m-4 p-3" style={{ marginTop: 10, paddingBottom: 45 }}>
          <Pagination
            style={{ display: 'flex', width: 'max-content', marginLeft: 'auto' }}
            total={logTasksLength}
            current={tableState.pagination?.current}
            pageSize={tableState.pagination?.pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            onChange={(page, pageSize) => {
              setTableState({ pagination: {
                pageSize,
                current: page
              } })
            }} />
        </div>
      </Affix>
    </LayoutMyScenarioPage>
  )
}

export default DetailJob

const StyledPageHeader = styled(PageHeader)`
  .ant-page-header-heading-left {
    flex-direction: row;
    align-items: center;
  }
`

const StyledDetailJob = styled.div`
  margin: 16px;
  padding: 16px;
  background: #FFF;
  .ant-steps {
    padding: 16px 0;
    .last {
      display: none;
    }
    &.success {
      .ant-steps-item-icon {
        background: #96C443;
        border-color: #96C443;
      }
    }
    &.error {
      .ant-steps-item-icon {
        background: #BC4A40;
        border-color: #BC4A40;
      }
    }
    &.processing {
      .ant-steps-item-icon {
        background: #F3AD3D;
        border-color: #F3AD3D;
      }
    }
    .ant-steps-item-container {
      .ant-steps-item-title {
        font-family: 'IBM Plex Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.85);
        width: 100%;
      }
      .ant-steps-item-icon {
        margin-top: 20px;
      }
      & > .ant-steps-item-tail{
        margin-top: 20px;
        top: 5px;
        &::after {
          background: #D9D9D9;
          height: calc(100% - 10px);
        }
      }
    }
  }
  h5 {
    color: #595959;
    margin: 0;
  }
  h4, .ant-badge-status-text {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-divider-vertical {
    background: rgba(0, 0, 0, 0.65);
  }
`