import { State } from 'db'
import { useEffect } from 'react'
import { getChildsAllStates, getFlatDataTest, getParentsAllStates } from '../../../../../util/formatter'
import { ContextValueStateWithTestDataType, useStateChildsData, useStateParentsData, useStateTestData } from './StateTestDataContext'

export default function useSetStateTestData(states: State[], counterFetchScenario: number): void {
  const [, setStateTestData] = useStateTestData()
  const [, setNextStateIdsAll] = useStateChildsData()
  const [_, setParentsAllState] = useStateParentsData()

  useEffect(() => {
    // just set testData firstTime load
    if (counterFetchScenario === 1 && states) {
      setStateTestData(states?.reduce((res: ContextValueStateWithTestDataType, state) => {
        // let flatTestData: StateWithTestData['flatTestData'] | null = null
        const { id: stateId, order: thisStateOrder, name: stateName } = state
        const application = (state?.action || state?.trigger)?.application
        if (state.testData && application) {
          // const flattenTestData: Record<string, any> = flatten(state.testData)
          // const flattenTestDataSafe: Record<string, any> = flatten(state.testData, { safe: true })
          // const flatTestDataSafe = Object.keys(flattenTestDataSafe).reduce((acc, dataKey) => ({
          //   ...acc,
          //   [dataKey]: {
          //     sample: truncateLongData(flattenTestDataSafe[dataKey]),
          //     value: `${stateId}.${dataKey}`,
          //     label: `${thisStateOrder + 1}. ${dataKey} ${Array.isArray(flattenTestDataSafe[dataKey]) ? '(array)': ''}`,
          //     appLogoUrl: application.logoUrl
          //   }
          // }), {})

          // flatTestData = Object.keys(flattenTestData).reduce((acc, dataKey) => ({
          //   ...acc,
          //   [dataKey]: {
          //     sample: truncateLongData(flattenTestData[dataKey]),
          //     value: `${stateId}.${dataKey}`,
          //     label: `${thisStateOrder + 1}. ${dataKey}`,
          //     appLogoUrl: application.logoUrl
          //   }
          // }), flatTestDataSafe)

        }
        return {
          ...res,
          [state.id]: {
            id: stateId,
            type: (state?.action || state?.trigger)?.type,
            order: thisStateOrder,
            triggerActionName: (state?.action || state?.trigger)?.name,
            stateName,
            application: application,
            realTestData: state.realTestData,
            testData: state.testData ? state.testData : null,
            flatTestData: getFlatDataTest({ testData: state.testData, stateId, stateOrder: thisStateOrder, application })
          }
        }
      }, {}) || {})
    }

    if (states) {
      setNextStateIdsAll(getChildsAllStates(states))
      setParentsAllState(getParentsAllStates(states))
    }
  }, [states, counterFetchScenario])

}