import { Layout, Space, Steps } from 'antd'
import { FC, useState } from 'react'
import styled from 'styled-components'
import PageHeader from '../../../../../../components/PageHeader'
import data, { ContentCreate } from '../data'
import { useSharedValueAction } from '../SharedValueActionContextProvider'

interface Props {
  type: 'create' | 'update'
}
const ActionSteps: FC<Props> = ({ type }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [values] = useSharedValueAction()

  const contents: () => ContentCreate | undefined = () => data[type]

  const onChange = (current: number) => {
    if (values?.id) {
      setCurrentStep(current)
    }
  }

  const onContinue = (idx: number) => {
    setCurrentStep(idx + 1)
  }

  const { Step } = Steps

  return (
    <StyledStep>
      <PageHeader
        title={values?.name ? `${contents()?.title} "${values.name}"` : contents()?.title}
        description={contents()?.description}
      />
      <Space direction="vertical">
        <Steps size="small" current={currentStep} onChange={onChange}>
          {
            contents()?.steps.map(step => {
              const { title, key } = step
              return <Step title={title} key={key} />
            })
          }
        </Steps>
        {
          contents()?.steps.map((step, idx) => {
            const { content, key, info } = step
            const Content = content
            if (Content) {
              return <Content info={info} key={key} onPrevious={idx > 0 ? () => setCurrentStep(idx - 1) : undefined} onContinue={() => onContinue(idx)} isActive={idx === currentStep} />
            }
            return null
          })
        }
      </Space>
    </StyledStep>
  )
}

export default ActionSteps

const StyledStep = styled(Layout.Content)`
  .ant-steps-item-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-steps-icon {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-steps, .ant-card {
    background: #FFFFFF;
    /* Blue/1 */
    border: 1px solid #F0FBFF;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
  }

  .ant-space-vertical {
    width: 100%;
    padding: 16px 24px;
  }
`