import { HomeOutlined, BranchesOutlined } from '@ant-design/icons'
import { Breadcrumb, Spin } from 'antd'
import { State } from 'db'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { lime } from 'src/util/colors'
import styled from 'styled-components'
import { stateNameAtom } from '../../../templateScenario/editor/recoil/state'
import useGetBreadcrumb from './useGetBreadcrumb'

interface Props {
  isViewerTemplate?: boolean,
  isTemplateScenarioPage?: boolean
}

const BreadcrumbScenario: FC<Props> = ({ isViewerTemplate, isTemplateScenarioPage }) => {
  const { breadcrumb, fetchingScenario, scenarioId, firstState } = useGetBreadcrumb()
  const { push } = useHistory()

  const onClickItem = (state: State) => {
    if (isViewerTemplate) {
      push(`/app/template-scenario/${state.scenarioId}/${state.id}`)
    } else if (isTemplateScenarioPage) {
      push(`/app/template-scenario/editor/${state.scenarioId}/${state.id}`)
    } else {
      push(`/app/scenario/${state.scenarioId}/edit/${state.id}`)
    }
  }
  const onClickHome = () => {
    if (isViewerTemplate) {
      push(`/app/template-scenario/${scenarioId}/${firstState?.id}`)
    } else if (isTemplateScenarioPage) {
      push(`/app/template-scenario/editor/${scenarioId}/${firstState?.id}`)
    } else {
      push(`/app/scenario/${scenarioId}/edit/${firstState?.id}`)
    }
  }
  return (
    <>
      <StyledBreadcrumb>
        <StyledBreadcrumb.Item onClick={onClickHome}>
          <HomeOutlined />
        </StyledBreadcrumb.Item>
        {
          fetchingScenario ? <Spin spinning /> :
            breadcrumb?.map((state) => {
              return (
                <StyledBreadcrumb.Item key={state.id} onClick={() => onClickItem(state)}>
                  <BranchesOutlined />
                  <span><StateName stateId={state.id} name={state.name} /></span>
                </StyledBreadcrumb.Item>
              )
            })
        }
      </StyledBreadcrumb>
    </>
  )
}

export default BreadcrumbScenario

const StateName: FC<{stateId: number, name?: string}> = ({ stateId, name }) => {
  const stateName = useRecoilValue(stateNameAtom(stateId))

  return <>
    {stateName || name}
  </>

}

const StyledBreadcrumb = styled(Breadcrumb)`
  background: white;
  padding: 10px 24px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  z-index: 1;
  > span:last-child {
    background: ${lime[1]};
    padding: 2px 10px;
    border: 1px solid ${lime[4]};
    border-radius: 4px;
  }
  > span {
    cursor: pointer;
  }
`