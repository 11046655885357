import { Col, Row } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { FC, useEffect, useState } from 'react'
import { Invoice } from '../../../../hooks/payment/useInvoices'
import useStatusPayment from '../../../../hooks/payment/useStatusPayment'
import { currencyFormatterIDR } from '../../../../util/formatter'
import { StyledInput } from '../../../components/StyledComponents'

interface Props {
  active?: Invoice
}

interface SummaryData {
  description: string,
  usage?: number,
  limit?: number,
  price: number
}
const SummaryTable: FC<Props> = ({ active }) => {
  const [summary, setSummary] = useState<SummaryData[]>()
  const [searchValue, setSearchValue] = useState<string>()
  const { result, plan, totalUsage, scenariosCount, fetchingStatusPayment } = useStatusPayment()

  useEffect(() => {
    if (active || result) {
      const pricingPlan = active?.lines.data[0].pricingPlan || plan
      const taskUsage = active?.usage_records?.total_usage || totalUsage || 0
      const scenarioUsage = active?.scenarios_count || scenariosCount
      const surplusTask = taskUsage - (pricingPlan?.monthlyTask || 0)
      setSummary([
        {
          description: 'Task Usage',
          usage: taskUsage,
          limit: pricingPlan?.monthlyTask,
          price: surplusTask > 0 ? (pricingPlan?.pricePerTask || 0) * surplusTask : 0
        },
        {
          description: 'Scenario Usage',
          usage: scenarioUsage,
          limit: pricingPlan?.maxScenario,
          price: 0
        },
      ])
    }
  }, [active, result])

  const columns: ColumnsType<SummaryData> = [
    { title: 'Description', dataIndex: 'description' },
    { title: 'Usage', dataIndex: 'usage' },
    { title: 'Limit', dataIndex: 'limit',
      render: (limit) => limit === 0 ? 'Unlimited' : limit },
    { title: 'Charged Price', dataIndex: 'price', render: (price) => currencyFormatterIDR(price) },
  ]

  const onSearch = (value: string) => {
    setSearchValue(value)
  }


  return (
    <>
      <Row justify="space-between" gutter={[16, 16]} align="middle">
        <Col>
          <h5>Summary</h5>
        </Col>
        <Col>
          <StyledInput.Search placeholder="Search Summary" allowClear onSearch={onSearch} style={{ width: 200 }} />
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={fetchingStatusPayment}
        rowKey="description"
        dataSource={summary?.filter(data => searchValue ? data?.description.toLowerCase().includes(searchValue.toLowerCase()) : true)}
        scroll={{ x: true }}
      />
    </>
  )
}

export default SummaryTable