import { Collapse } from 'antd'
import { FC, useEffect, useState } from 'react'
import { Prompt } from 'react-router'
import Step1Upload from './Step1Upload'
import Step2Check from './Step2Check'
import Step3ForEach from './Step3ForEach'
import Step4Execute from './Step4Execute'

const DataLoader: FC = () => {
  const [parsedData, setParsedData] = useState<{ [key: string]: any }[]>()
  const [dataHeader, setdataHeader] = useState<string[]>()
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleTabClosing)
    }
  }, [])

  const handleTabClosing = (e: any) => {
    alertUser(e)
  }

  const alertUser = (event: any) => {
    event.preventDefault()
    event.returnValue = 'Are you sure? the progress will stop and lost'
    return ''
  }

  return (
    <>
      <Prompt
        message={'Are you sure? the progress will stop and lost'}
      />
      <Collapse
        expandIconPosition={'right'}
      >
        <Collapse.Panel header={'1. Upload your CSV or XLSX'} key={1}>
          <Step1Upload setDataHeader={setdataHeader} setParsedData={setParsedData} />
        </Collapse.Panel>
        <Collapse.Panel collapsible={!parsedData ? 'disabled' : undefined} header={'2. Check your data example'} key={2}>
          <Step2Check parsedData={parsedData} dataHeader={dataHeader} />
        </Collapse.Panel>
        <Collapse.Panel collapsible={!parsedData ? 'disabled' : undefined} header={'3. For each row, do...'} key={3}>
          <Step3ForEach parsedData={parsedData} url={url} setUrl={setUrl} />
        </Collapse.Panel>
        <Collapse.Panel collapsible={!parsedData || !url ? 'disabled' : undefined} header={'4. Execute'} key={4}>
          <Step4Execute parsedData={parsedData} url={url} />
        </Collapse.Panel>
      </Collapse>
    </>
  )
}

export default DataLoader