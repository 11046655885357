import { Version } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  createdVersion?: Version,
  creatingVersion: boolean,
  errorCreateVersion?: Error,
  createVersion: (applicationId: number, payload: Partial<Version>) => void
}

export default function useCreateVersion(): Result {
  const { data, error, isValidating, call } = useApi<{ version: Version }>()

  return {
    createdVersion: data?.version,
    creatingVersion: isValidating,
    errorCreateVersion: error,
    createVersion: (applicationId, payload) => {
      call({
        url: `/application/${applicationId}/version`,
        method: 'post',
        data: {
          version: {
            ...payload
          }
        }
      })
    }
  }
}