import { BranchesOutlined, SettingOutlined } from '@ant-design/icons'

type Route = {
  key: string,
  title: string,
  to: string
}

type Menu = {
  key: string,
  title: string,
  icon: any,
  children?: Route[]
}
type RoutesType = Menu[]


const routes: RoutesType = [
  {
    key: 'build',
    title: 'BUILD',
    icon: BranchesOutlined,
    children: [
      {
        key: 'step',
        title: 'Steps',
        to: ''
      },
      {
        key: 'authentication',
        title: 'Authentications',
        to: '/authentication'
      },
      {
        key: 'trigger',
        title: 'Triggers',
        to: '/trigger'
      },
      {
        key: 'action',
        title: 'Action',
        to: '/action'
      },
      {
        key: 'globalVariable',
        title: 'Global Variable',
        to: '/globalVariable'
      },
      {
        key: 'advance',
        title: 'Advanced',
        to: '/advance'
      }
    ]
  },
  {
    key: 'manage',
    title: 'MANAGE',
    icon: SettingOutlined,
    children: [
      {
        key: 'general',
        title: 'General',
        to: '/general'
      },
      {
        key: 'member',
        title: 'Member',
        to: '/member'
      },
      {
        key: 'version',
        title: 'Version',
        to: '/version'
      },
      {
        key: 'submitApp',
        title: 'Submit App',
        to: '/submit-app'
      }
    ]
  }
]

export default routes