import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'
import RichTextInput from '../../../../components/pages/developer/formItems/RichTextInput'
import { StyledButton } from '../../../components/StyledComponents'

interface Props {
  isRequired?: boolean,
  onBlur?: any,
  name: string
}

const FormListKeyValueItem: FC<Props> = ({ name, onBlur, isRequired, ...rest }) => {
  return (
    <Form.List name={name} {...rest}>
      {(fields, { add, remove }) =>
        <>
          {fields.map((field, idx) =>
            <StyledRow key={idx} gutter={[8, 8]} align="top">
              <Col span={23}>
                <Row gutter={[8, 8]}>
                  <Col md={12} sm={24} style={{ width: '100%' }}>
                    <StyledFormItem
                      onBlur={onBlur}
                      {...field}
                      name={[field.name, 'key']}
                      fieldKey={[field.fieldKey, 'key']}
                      rules={[{ required: isRequired, message: 'Required' },
                        ({ getFieldValue }: any) => ({
                          async validator(_: any, value: any) {
                            const allKey = getFieldValue(name)?.map((v: any) => v?.key) || []
                            const isUnique = allKey.filter((v: string) => v === value).length === 1
                            const thisValue = getFieldValue(name)?.[idx]?.value
                            const thisKey = getFieldValue(name)?.[idx]?.key
                            if (thisValue && !thisKey) {
                              return Promise.reject(new Error('Required'))
                            }
                            if (isUnique) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Key must unique'))
                          },
                        }),
                      ]}
                    >
                      <Input placeholder={`${name}.key${idx + 1}`} />
                    </StyledFormItem>
                  </Col>
                  <Col md={12} sm={24} style={{ width: '100%' }}>
                    <StyledFormItem
                      onBlur={onBlur}
                      {...field}
                      name={[field.name, 'value']}
                      fieldKey={[field.fieldKey, 'value']}
                    >
                      {
                        // eslint-disable-next-line
                      // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                        <RichTextInput id={`keyValue-${name}-${idx}`} />
                      }
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={1}>
                <StyledFormItem>
                  <DeleteOutlined disabled={isRequired && fields.length === 1} onClick={() => (fields.length !== 1 || !isRequired) && remove(idx)} />
                </StyledFormItem>
              </Col>
            </StyledRow>
          )}
          <Form.Item>
            <StyledButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Item Field
            </StyledButton>
          </Form.Item>
        </>
      }
    </Form.List>
  )
}

export default FormListKeyValueItem

const StyledRow = styled(Row)`
  width: 100%;
`

// eslint-disable-next-line
export const StyledFormItem = styled(({ description, children, ...nativeProps }) => <Form.Item {...nativeProps}>{children}</Form.Item>)<{ description: string }>`
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 16px;

  .ant-checkbox-wrapper, .ant-radio-wrapper, .ant-form-item-extra {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
  &.with-extra {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: ${props => props.description ? 0 : '5px'};
  }
  .ant-form-item-label > label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-form-item-optional, label.ant-form-item-required::before {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    text-transform: capitalize;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: '(Required)';
    color: ${'#9C3930'};
    margin-left: 4px;
  }

  &.no-info .ant-form-item-label > label.ant-form-item-required::before {
    content: '';
  }

  .ant-form-item-label > label.ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .ant-form-item-extra {
    padding-bottom: 5px;
  }

  .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-success {
    position: absolute;
    bottom: -8px;
  }

  label {
    padding-bottom: 0
  }
  position: unset;
  .codeMirrorContainer {
    position: unset;
  }

  .CodeMirror {
    min-height: 0;
    height: 30px;
    padding-top: 1px;
  }
  .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error {
    bottom: -22px;
  }
`