import { useHistory } from 'react-router'

const useNavigateTabLeftPane = (): { navigateTabLeftPane: (location: string) => void } => {
  const history = useHistory()

  const navigateTabLeftPane: (location: string) => void = (location: string) => history.push(location.replace('/:folderId', '').replace('/job/:id?', ''))

  return {
    navigateTabLeftPane
  }
}

export default useNavigateTabLeftPane