import { FC } from 'react'
import { Route, Switch } from 'react-router'
import NotFound from '../../../../error/NotFound'
import SharedValueActionContextProvider from './components/SharedValueActionContextProvider'
import CreateAction from './create'
import UpdateAction from './update'
import ActionView from './view'

const Action: FC = () => {
  const path = '/developer/application/:applicationId/:versionName/main/action'

  return (
    <SharedValueActionContextProvider>
      <Switch>
        <Route exact path={path} component={ActionView}></Route>
        <Route exact path={`${path}/create`} component={CreateAction}></Route>
        <Route exact path={`${path}/:actionId`} component={UpdateAction}></Route>
        <Route component={NotFound} />
      </Switch>
    </SharedValueActionContextProvider>
  )
}

export default Action