import { Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useLogsByLogId from '../../../../hooks/application/codeLogs/useLogsByLogId'

interface DetailLogsProps {
  isOpen: boolean,
  logId: number
}

const DetailLogs: FC<DetailLogsProps> = ({ logId, isOpen }) => {
  const [counterOpen, setCounterOpen] = useState(0)
  const { applicationId } = useParams<{ applicationId: string }>()
  const { fetchingLogs, logs } = useLogsByLogId({ applicationId, logId: counterOpen > 0 ? logId : undefined })

  useEffect(() => {
    if (isOpen) {
      setCounterOpen(counterOpen + 1)
    }
  }, [isOpen])
  return (
    <Spin spinning={fetchingLogs}>
      {
        logs?.map((log) => {
          const { timestamp: _, eventId, message } = log
          return (
            <pre key={eventId}>
              {/* <b>
                <span>{DateTime.fromJSDate(new Date(timestamp)).toFormat('DD')}</span>
                <span>{DateTime.fromJSDate(new Date(timestamp)).toFormat('T', { hourCycle: 'h23' })}</span>
              </b> */}
              {message}
            </pre>
          )
        })
      }
    </Spin>
  )
}

export default DetailLogs