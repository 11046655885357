import { useForm } from 'antd/lib/form/Form'
import { Application } from 'db'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useCreateApplication from '../../../../hooks/application/main/useCreateApplication'
import useCreateVersion from '../../../../hooks/application/version/useCreateVersion'
import Notification from '../../../../util/Notification'
import Header from '../../../components/Header'
import ApplicationForm from '../components/ApplicationForm'
import { baseApplicationRoute } from '../routes'

const CreateApplication: React.FC = () => {
  const history = useHistory()
  const [form] = useForm<Partial<Application> & { activeVersion?: string }>()
  const { createApplication, creatingApplication, createdApplication } = useCreateApplication()
  const { createVersion, createdVersion, creatingVersion } = useCreateVersion()

  useEffect(() => {
    form.setFieldsValue({
      activeVersion: '1.0.0'
    })
  }, [])

  const finish = async () => {
    createApplication({
      requestUrl: form.getFieldValue('requestUrl'),
      errorReporterEmail: form.getFieldValue('errorReporterEmail'),
      isNativeApp: form.getFieldValue('isNativeApp'),
      isPremiumApp: form.getFieldValue('isPremiumApp'),
      homepageUrl: form.getFieldValue('homepageUrl'),
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      logoUrl: form.getFieldValue('logoUrl'),
      hasAction: form.getFieldValue('hasAction') !== undefined ? form.getFieldValue('hasAction') : true,
      hasTrigger: form.getFieldValue('hasTrigger') !== undefined ? form.getFieldValue('hasTrigger') : true,
    })
  }

  const onBack = () => {
    history.push(baseApplicationRoute)
  }

  useEffect(() => {
    if (createdApplication) {
      createVersion(createdApplication.id, { name: form.getFieldValue('activeVersion'), isActive: true })
    }
  }, [createdApplication])

  useEffect(() => {
    if (createdVersion) {
      Notification.success({
        message: `Application ${createdApplication?.name} created successfully`
      })
      history.push(`${baseApplicationRoute}/${createdApplication?.id}/main`)
    }
  }, [createdVersion])
  return (
    <div style={{ overflowY: 'auto' }}>
      <Header />
      <ApplicationForm form={form} onFinish={finish} submitting={creatingApplication || creatingVersion} onCancel={onBack} />
    </div>
  )
}

export default CreateApplication
