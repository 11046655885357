import { Col, Layout, Popconfirm, Row, Space, Table, Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { Version } from 'db'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import useApplication from '../../../../../hooks/application/main/useApplication'
import useArchiveVersion from '../../../../../hooks/application/version/useArchiveVersion'
import useUpdateVersion from '../../../../../hooks/application/version/useUpdateVersion'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import Notification from '../../../../../util/Notification'
import PageHeader from '../../../../components/PageHeader'
import { StyledCard } from '../../../../components/StyledComponents'
import { useApplicationVersionContext } from '../../contexts/ApplicationVersionContextProvider'
import { baseApplicationRoute } from '../../routes'
import CloneVersionModal from './components/CloneVersionModal'

const VersionPage: FC = () => {
  const { revalidateApplication } = useRevalidateFetch()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { application, fetchingApplication } = useApplication(applicationId)
  const [activeClone, setActiveClone] = useState<Version>()
  const versions = application?.versions
  const { archiveVersion, archivingVersion, archivedVersion } = useArchiveVersion()
  const { updateVersion, updatingVersion } = useUpdateVersion()
  const [version, setVersion] = useApplicationVersionContext()
  const { push } = useHistory()

  const columns: ColumnType<Version>[] = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Status', dataIndex: 'isActive', render: (isActive) => isActive ? <Tag color="geekblue" style={{ textTransform: 'capitalize' }}>Active</Tag> : null },
    { title: 'Action', dataIndex: 'id', render: (_, record) => <Space>
      <a onClick={(e) => onClone(e, record)} >Clone</a>
      {!record.isActive  ? <a onClick={(e) => onSetActive(e, record)}>Set Active</a> : null}
      { versions && versions.length > 1 ? <Popconfirm title="Sure to delete?" onConfirm={(e) => onDelete(e, record)} >
        <a>Delete</a>
      </Popconfirm> : null}
    </Space> },
  ]

  useEffect(() => {
    if (versions && version) {
      const newVersion = versions.find(v => v.id === version.id) || null
      setVersion(newVersion)
    }
  }, [versions])

  const onClone = (e: MouseEvent<HTMLElement>, data: Version) => {
    e.preventDefault()
    e.stopPropagation()
    setActiveClone(data)
  }

  const onSetActive = (e: MouseEvent<HTMLElement>, data: Version) => {
    e.preventDefault()
    e.stopPropagation()
    const preActiveVersion = versions?.find(v => v.isActive)?.id
    updateVersion(Number(applicationId), data.id, {
      isActive: true
    }).then(() => {
      if (preActiveVersion) {
        updateVersion(Number(applicationId), preActiveVersion, {
          isActive: false
        }).then(() => {
          revalidateApplication(parseInt(applicationId))
        })
      }
    })
  }

  const onDelete = (e: MouseEvent<HTMLElement> | undefined, data: Version) => {
    e?.preventDefault()
    e?.stopPropagation()
    archiveVersion(Number(applicationId), data.id)
  }

  const openNotif = (type: 'success' | 'error', title: string, description: string) => {
    Notification[type]({
      message: title,
      description: description
    })
  }

  useEffect(() => {
    if (archivedVersion) {
      openNotif('success', 'Success', `Version ${archivedVersion.name} deleted`)
      if (archivedVersion.id === version?.id) {
        push(`${baseApplicationRoute}/me`)
      } else {
        revalidateApplication(parseInt(applicationId))
      }
    }
  }, [archivedVersion])

  const afterSubmit = (_: Version) => {
    revalidateApplication(parseInt(applicationId))
  }

  const onCancelModal = () => {
    setActiveClone(undefined)
  }

  return (
    <StyledVersion>
      <PageHeader title="Manage Version" description="" />
      <StyledCard>
        <Row justify="space-between" align="middle">
          <Col>
            <h4>Version</h4>
          </Col>
        </Row>
        <br />
        <Table
          dataSource={versions}
          loading={fetchingApplication || archivingVersion || updatingVersion}
          rowKey="id"
          scroll={{ x: true }}
          columns={columns}
        />
        <CloneVersionModal onClone={activeClone} onCancel={onCancelModal} afterSubmit={afterSubmit} />
      </StyledCard>
    </StyledVersion>
  )
}

export default VersionPage

const StyledVersion = styled(Layout.Content)`
  h4 {
    color: rgba(0, 0, 0, 0.65);
  }
  ${StyledCard} {
    margin: 16px 24px;
  }
`