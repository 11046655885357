import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { Application, FieldSchema, State, Trigger } from 'db'
import ThisStateStaticValueContextProvider, { ThisStateStaticData } from '../../../scenario/edit/ThisStateStaticValueContext'

export type StateStaticData = Pick<State, 'isErrorState' | 'id' | 'uuid' | 'order' | 'scenarioId' | 'bundleId' | 'bundle' | 'actionId' | 'triggerId' | 'parentId' | 'childrenIds' | 'stateConditionId' | 'conditionGroupOrder'> & {
  triggerActionName: string,
  triggerType: Trigger['type'] | undefined,
  subscribeHttpRequestId: Trigger['subscribeHttpRequestId'],
  testGuideline?: Trigger['testGuideline'],
  stateName?: string,
  application?: Application,
  type: 'trigger' | 'action',
  params: Record<string, any> | null,
  authenticationId: Trigger['authenticationId'],
  paramSchema: FieldSchema[]
}

type ContextValueType = StateStaticData

const StateValueContext = createContext<ContextValueType>({} as ContextValueType)
const StateValueSetterContext = createContext<Dispatch<SetStateAction<ContextValueType>>>(() => null)

type Props = {
  children: ReactNode,
  value: State
}

const StateValueContextProvider: FC<Props> = ({ children, value }) => {
  const [StateValue, setStateValue] = useState<ContextValueType>(getValue(value))

  useEffect(() => {
    setStateValue(getValue(value))
  }, [value])

  return (
    <StateValueContext.Provider value={StateValue}>
      <StateValueSetterContext.Provider value={setStateValue}>
        <ThisStateStaticValueContextProvider value={getValueForStaticValueContext(value)}>
          {children}
        </ThisStateStaticValueContextProvider>
      </StateValueSetterContext.Provider>
    </StateValueContext.Provider>
  )
}


export function useStateValue(): [ContextValueType, Dispatch<SetStateAction<ContextValueType>>] {
  const valueContext = useContext(StateValueContext)
  const setterContext = useContext(StateValueSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useStateValue must be used on child component of StateValueContextProvider component'
  }

  return [valueContext, setterContext]
}

const getValueForStaticValueContext = (value: State): ThisStateStaticData => {
  return {
    executionType: value.executionType,
    id: value.id,
    order: value.order,
    type: value.order === 0 || value.triggerId ? 'trigger' : 'action',
    triggerActionName: (value.trigger || value.action)?.name || '',
    application: (value.trigger || value.action)?.application,
    scenarioId: value.scenarioId,
    params: value.params || null,
    paramSchema: (value.trigger || value.action)?.paramSchema || []
  }
}

const getValue = (state: State): ContextValueType => {
  const { id, isErrorState, order, scenarioId, bundleId, bundle, triggerId, actionId, uuid,
    trigger, action, name, params, parentId, childrenIds, stateConditionId, conditionGroupOrder } = state || {}
  return {
    id, order, scenarioId, bundleId, bundle, triggerId, actionId, parentId, childrenIds, stateConditionId, conditionGroupOrder,
    triggerType: trigger?.type,
    subscribeHttpRequestId: trigger?.subscribeHttpRequestId,
    testGuideline: trigger?.testGuideline,
    isErrorState,
    triggerActionName: (trigger || action)?.name || '',
    stateName: name,
    uuid,
    authenticationId: (trigger || action)?.authenticationId,
    application: (trigger || action)?.application,
    type: order === 0 || triggerId ? 'trigger' : 'action',
    params: params || null,
    paramSchema: (trigger || action)?.paramSchema || []
  }

}

export function useSetStateValue(state?: State): void {
  const [_, setStateValue] = useStateValue()
  const { id, order, scenarioId, bundleId, bundle, triggerId, actionId, name, params } = state || {}
  useEffect(() => {
    if (state) {
      setStateValue(getValue(state))
    }
  }, [id, order, scenarioId, bundle, bundleId, triggerId, actionId, name, params, triggerId, actionId])
}

export default StateValueContextProvider