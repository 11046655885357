import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'

const notificationBaseStyle = {
  backgroundColor: '#F0F0F0',
  border: '1px solid #D9D9D9',
  boxShadow: 'none'
}

const defaultPlacement = 'topRight'

class Notification {
  private constructor() {}

  public static open(config: ArgsProps): void {
    notification.open({
      style: { ...notificationBaseStyle },
      placement: defaultPlacement,
      ...config
    })
  }

  public static error(config: ArgsProps): void {
    notification.open({
      style: {
        ...notificationBaseStyle,
        backgroundColor: '#F7EAE9',
        border: '1px solid #E1AFAD'
      },
      placement: defaultPlacement,
      ...config
    })
  }

  public static success(config: ArgsProps): void {
    notification.open({
      style: {
        ...notificationBaseStyle,
        backgroundColor: '#ECF6D2',
        border: '1px solid #CEE78D'
      },
      placement: defaultPlacement,
      ...config
    })
  }

  public static warning(config: ArgsProps): void {
    notification.open({
      style: {
        ...notificationBaseStyle,
        backgroundColor: '#F9EBCB',
        border: '1px solid #F4D899'
      },
      placement: defaultPlacement,
      ...config
    })
  }

  public static info(config: ArgsProps): void {
    notification.open({
      style: {
        ...notificationBaseStyle,
        backgroundColor: '#F0FBFF',
        border: '1px solid #CFEEFA'
      },
      placement: defaultPlacement,
      ...config
    })
  }
}

export default Notification