import { AxiosPromise } from 'axios'
import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import { Error } from '../../../types'
import Notification from '../../../util/Notification'
import { useApi } from '../../useFetch'

type Result = {
  testAuthentication: (payload?: any, bundleId?: number) => Promise<any>,
  result?: any,
  testingAuthentication: boolean,
  errorTestAuthentication?: Error
}

export default function useAuthenticationTest(applicationId?: number | string, authenticationId?: number | string, versionId?: number | string): Result {
  const url = authenticationId && applicationId ? `/application/${applicationId}/authentication/${authenticationId}/test` : null
  const [data, setData] = useState()
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState<Error>()

  const post = async (payload?: any, bundleId?: number) => {
    setIsValidating(true)
    if (url) {
      const test = await Implikasi.post(url, payload, {
        params: {
          bundleId,
          versionId: versionId ? versionId : undefined
        }
      })
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          Notification.error({
            message: 'Something\'s wrong',
            description: response?.data?.error
          })
          setError(response?.data?.error)
          return response
        })
      setIsValidating(false)
      setData(test)
      return test
    }
    setIsValidating(false)
  }

  return {
    result: data,
    testingAuthentication: isValidating,
    errorTestAuthentication: error,
    testAuthentication: post
  }
}

type ApiAuthenticationTest = {
  testAuthentication: (applicationId: number, authenticationId: number, bundleId?: number, payload?: any) => AxiosPromise<any>,
  testingAuthentication: boolean
}
export function useApiAuthenticationTest(): ApiAuthenticationTest {
  const { call, isValidating } = useApi()

  const testAuthentication = (applicationId: number, authenticationId: number, bundleId?: number, payload?: any) => {
    return call({
      url: `/application/${applicationId}/authentication/${authenticationId}/test`,
      method: 'post',
      data: payload,
      params: {
        bundleId: bundleId
      }
    })
  }

  return {
    testAuthentication,
    testingAuthentication: isValidating
  }
}