import { PageHeader as BasePageHeader } from 'antd'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import { PageHeaderProps } from 'antd/lib/page-header'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props extends PageHeaderProps {
  routes?: Route[],
  description?: string
}

const PageHeader: React.FC<Props> = ({ routes, breadcrumb, description, ...rest }) => {
  return (
    <StyledPageHeader
      breadcrumb={routes?.length ? {
        ...breadcrumb,
        routes: routes,
        itemRender: (route, _, routes) => {
          const last = routes.indexOf(route) === routes.length - 1
          return last ?
            <span>{route.breadcrumbName}</span> : <Link to={`/${route.path}`}>{route.breadcrumbName}</Link>
        },
      } : undefined}
      {...rest}>
      {description}
    </StyledPageHeader>
  )

}

export default PageHeader

const StyledPageHeader = styled(BasePageHeader)`
  padding: 24px;
  .ant-page-header-heading-title {
    /* H3 - 24 */
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
  .ant-page-header-content, .ant-page-header-heading-sub-title {
    padding-top: 0;

    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-page-header-heading-left {
    flex-direction: column;
    align-items: start;
    .ant-page-header-heading-sub-title {
      color: rgba(0, 0, 0, 0.85);
    }
  }
`