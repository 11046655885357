import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import styled from 'styled-components'

interface Props extends ReactMarkdownOptions {
  children: string
}
const MarkdownRenderer: FC<Props> = ({ children, ...props }) => {
  return (
    <ReactMarkdown
      children={children}
      components={{
        code({ node, inline, className, children, ref, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          node
          ref
          return !inline ?
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              language={match?.[1]}
              PreTag="div"
              {...props}
            />
            :
            <code className={className} style={inline ? { border: '1px solid #d9d9d9', padding: '0 2px', borderRadius: 4 } : props.style} {...props}>
              {children}
            </code>

        },
        a({ children, ...props }) {
          return <a {...props} target={'_blank'} rel="noopener noreferrer">{children}</a>
        },
        img({ children, ...props }) {
          return <img {...props} style={{ width: '100%' }}>{children}</img>
        },
        pre({ node: _node, children, ref: _ref, ...props }) {
          return <CustomPre {...props} className="custom-pre" style={{ width: '100%', whiteSpace: 'pre-wrap' }}>{children}</CustomPre>
        },
        ...props.components
      }}
      {...props}
    />
  )
}

export default MarkdownRenderer

const CustomPre = styled.pre`
  * {
    white-space: pre-wrap !important;
  }
`