import { useEffect } from 'react'

export default function useTitleHead(title: string): void {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  }, [title])
}