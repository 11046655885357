import { EditOutlined } from '@ant-design/icons'
import { Col, Divider, Select } from 'antd'
import { FormProps, useForm } from 'antd/lib/form/Form'
import { debounce } from 'debounce'
import { FC, useCallback, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import useUpdateAScenarioState from '../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import useAScenarioState from '../../../../../../hooks/useAScenarioState'
import { StyledButton, StyledTabs } from '../../../../../../pages/components/StyledComponents'
import CustomForm from '../../../../../../pages/developer/components/form/CustomForm'
import FormItem from '../../../../../../pages/developer/components/form/FormItem'
import { getFlatDataTest } from '../../../../../../util/formatter'
import useOpenCloseModal from '../../../../../../util/useOpenCloseModal'
import DataResponse from '../../../history/job/DataResponse'
import { updatingScenarioOrState } from '../recoil'
import { useStateTestData } from '../StateTestDataContext'
import { useThisStateStaticValue } from '../ThisStateStaticValueContext'
import ModalChooseSample from './ModalChooseSample'
import { executionTypeAtomFamily } from './testDataArrayRecoil'

const TestDataArray: FC = () => {
  const [stateTestData, setStateTestData] = useStateTestData()
  const [thisStateStaticValue] = useThisStateStaticValue()

  const stateOrder = thisStateStaticValue?.order || 0
  const stateId = thisStateStaticValue?.id || 0
  const application = thisStateStaticValue?.application
  const scenarioId = thisStateStaticValue?.scenarioId || 0

  const { state } = useAScenarioState(scenarioId, stateId, true)
  const [executionType, setExecutionType] = useRecoilState(executionTypeAtomFamily(stateId))
  const { visible, closeModal, openModal } = useOpenCloseModal()
  const [form] = useForm()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)
  const [isMouseLeavingForm, setIsMouseLeavingForm] = useState(false)
  const onMouseEnter = () => setIsMouseLeavingForm(false)
  const onMouseLeave = () => setIsMouseLeavingForm(true)

  useEffect(() => {
    const executionType = thisStateStaticValue?.executionType
    form.setFieldsValue({
      executionType: executionType?.includes('index') ? 'index' : executionType || 'default',
      payloadSelection: executionType?.includes('index') ? executionType : undefined,
    })
    setExecutionType(thisStateStaticValue?.executionType)
  }, [thisStateStaticValue?.executionType])

  const onUpdateState = useCallback(() => {
    const fields = form.getFieldsValue()
    const fieldExecutionType = fields['executionType']
    const fieldPayloadSelection = fields['payloadSelection']
    const isIndex = fieldExecutionType === 'index' && fieldPayloadSelection
    const newExecutionType = fieldPayloadSelection || fieldExecutionType
    // update executionType state to db
    if (isIndex || fieldExecutionType !== 'index') {
      const newTestData = newExecutionType === 'as object' ?
        stateTestData?.[stateId]?.realTestData?.data
        : {
          ...stateTestData?.[stateId]?.realTestData?.data?.[0]
        }
      updateAScenarioState(scenarioId, stateId, {
        executionType: newExecutionType,
        testData: newTestData
      }).then(() => {
        setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
        setStateTestData(prevState => ({
          ...prevState,
          [stateId]: {
            ...prevState?.[stateId],
            testData: newTestData,
            flatTestData: getFlatDataTest({ testData: newTestData, stateOrder, stateId, application })
          }
        }))
      })
    }

  }, [scenarioId, stateId, executionType, updateAScenarioState, setIsUpdatingScenarioOrState])

  useEffect(() => {
    let debounceUpdateState: any

    if (isMouseLeavingForm) {
      debounceUpdateState = debounce(() => onUpdateState(), 1000)
      debounceUpdateState()
      return () => {
        if (debounceUpdateState) {
          debounceUpdateState.clear()
        }
      }
    }
  }, [isMouseLeavingForm])

  const onValuesChange: FormProps['onValuesChange'] = (_, values) => {
    if (values.executionType) {
      if (values.executionType === 'index' && values.payloadSelection) {
        setExecutionType(values.payloadSelection)
      } else {
        setExecutionType(values.executionType)
      }
    }
  }

  const lastIndex = (stateTestData?.[stateId]?.realTestData?.data?.length || 1) - 1

  return (
    <>
      <StyledTabs>
        <StyledTabs.TabPane tab="Sample of Item" key="sample">
          <DataResponse number={stateOrder !== undefined ? stateOrder + 1 : undefined} data={stateTestData?.[stateId]?.testData} logoUrl={application?.logoUrl}
            actions={<Col flex={0}>
              <StyledButton icon={<EditOutlined />} disabled={executionType === 'as object'} onClick={openModal}>Change Sample Item</StyledButton>
            </Col>}
          />
        </StyledTabs.TabPane>
        <StyledTabs.TabPane tab="List of Items" key="list">
          <DataResponse number={stateOrder !== undefined ? stateOrder + 1 : undefined} data={stateTestData?.[stateId]?.realTestData?.data} logoUrl={application?.logoUrl} />
        </StyledTabs.TabPane>
      </StyledTabs>
      <Divider />
      <CustomForm form={form} onValuesChange={onValuesChange} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        <FormItem name={'executionType'} label="Array Execution Type" description={`Array detected as return value for ${state?.action ? 'action' : 'trigger'} "${state?.name}". How do you want the next actions to be executed?`} >
          <Select
            showSearch
            onChange={() => {}}
            allowClear
            options={[
              {
                title: 'Multi-item Execution (Separated execution for each of item)',
                label: <><b>Multi-item Execution</b> (Separated execution for each of item)</>,
                value: 'default'
              },
              // {
              //   title: 'Single Item Execution (Choose one item from array)',
              //   label: <><b>Single Item Execution</b> (Choose one item from array)</>,
              //   value: 'index'
              // },
              {
                title: 'Normal Execution (Return value treated as object)',
                label: <><b>Normal Execution</b> (Array value ignored and returned as object)</>,
                value: 'as object'
              }
            ]}
            filterOption={(input, option) =>
              (option?.title as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </FormItem>
        <FormItem shouldUpdate={(prev, next) => prev.executionType !== next.executionType}>
          {
            ({ getFieldValue }) => {
              const executionType = getFieldValue('executionType')
              if (executionType !== 'index') {
                return null
              }
              return (
                <FormItem
                  label="Payload Selection"
                  description="Which Payload you want to select to the next action?"
                  name="payloadSelection"
                >
                  <Select
                    showSearch
                    onChange={() => {}}
                    allowClear
                    options={[
                      { label: 'First Item', value: 'index:0' },
                      { label: 'Last Item', value: `index:${lastIndex}` }]}
                    filterOption={(input, option) =>
                      (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </FormItem>
              )
            }
          }
        </FormItem>
      </CustomForm>
      <ModalChooseSample visible={visible} closeModal={closeModal} />
    </>
  )
}

export default TestDataArray