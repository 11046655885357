import { ReactNode } from 'react'
import Config from './TriggerStep/ApiConfig/Config'
import Test from './TriggerStep/ApiConfig/Test'
// import ExamplePayloadForm from './TriggerStep/ExamplePayloadForm'
import FormEditor from './TriggerStep/FormEditor'
import SettingsForm from './TriggerStep/SettingsForm'

export const basePathTrigger = '/developer/application/:applicationId/:versionName/main/trigger'

export type ContentCreate = {
  title: string,
  description: string,
  steps: {
    title: string,
    key: string,
    info?: ReactNode,
    content?: any
  }[]
}

export interface PropsContentStep {
  isActive?: boolean,
  isRequired?: boolean,
  onContinue: () => void,
  onPrevious?: () => void,
  info: ReactNode
}

type Contents = {
  [key: string]: ContentCreate
}

const steps: ContentCreate['steps'] = [
  {
    title: 'Settings',
    key: 'settings',
    content: SettingsForm
  },
  {
    title: 'Input Designers',
    key: 'inputDesigners',
    content: FormEditor
  },
  // {
  //   title: 'Define your output',
  //   key: 'examplePayload',
  //   content: ExamplePayloadForm,
  // },
  {
    title: 'API Configuration',
    key: 'apiConfiguration',
    content: Config
  },
  {
    title: 'Test Trigger',
    key: 'test',
    content: Test
  }
]

const data: Contents = {
  'create': {
    title: 'Add Trigger',
    description: 'Triggers GET or read data from your API to start workflows. Add triggers for new and updated items in your app so users can use the data in other apps.',
    steps: steps
  },
  'update': {
    title: 'Update Trigger',
    description: 'Triggers GET or read data from your API to start workflows. Add triggers for new and updated items in your app so users can use the data in other apps.',
    steps: steps
  }
}

export default data