import { FC } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import JobExecutedHistory from '../../../../components/pages/app/history/job'

const ScenarioAllHistoryPage: FC = () => {
  // const history = useHistory()
  const { path } = useRouteMatch()

  // const navigateTab = (location: string) => {
  //   return history.push(location)
  // }

  return (
    <>
      {/* // <LayoutMyScenarioPage propsPageHeader={{ title: 'Job History' }}>
    //   <StyledScenarioAllHistoryPage> */}
      {/* <StyledTabs type="card" onChange={(activeKey: string) => navigateTab(`${path}${activeKey}`)} activeKey={history.location.pathname.includes('task') ? '/task' : '/job'}>
          <StyledTabs.TabPane key="/task" tab="Task Used">
          </StyledTabs.TabPane>
          <StyledTabs.TabPane key="/job" tab="Job Executed">
          </StyledTabs.TabPane>
        </StyledTabs> */}
      <Switch>
        <Redirect exact from={path} to={`${path}/job`} />
        {/* <Route path={`${path}/task`} component={TaskUsedHistory} /> */}
        <Route path={`${path}/job/:id?`} component={JobExecutedHistory} />
      </Switch>
      {/* //   </StyledScenarioAllHistoryPage>
    // </LayoutMyScenarioPage> */}
    </>
  )
}

export default ScenarioAllHistoryPage

// const StyledScenarioAllHistoryPage = styled.div`
//   /* margin: 16px; */
//   /* background: #FFF; */
//   border-radius: 4px;
// `