import { Folder } from 'db'
import { FC } from 'react'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { blue } from '../../../../../../util/colors'
import AnchorWrapper from '../../../../../AnchorWrapper'
import FolderContent from './FolderContent'
import useDragFolder from './useDragFolder'
import useDropFolder from './useDropFolder'
import useFolderActiveKey from './useFolderActiveKey'
import useNavigateTabLeftPane from './useNavigateTabLeftPane'
interface Props extends Pick<Folder, 'name' | 'id' | 'displayOrder'> {
  index: number
}

const FolderTab: FC<Props> = ({ name, id, index }) => {
  const { path } = useRouteMatch()
  const { navigateTabLeftPane: navigateTab } = useNavigateTabLeftPane()
  const activeKey = useFolderActiveKey()
  const isAllFolder = id === 0 && index === 0
  // console.log({ name, id, activeKey, index })

  const onClickMenu = (id: number) => () => {
    // this is to support Nurul's previous code behavior
    // index === 0 && id === 0 is 'All' folder
    navigateTab(`${path.replace(activeKey, '')}${isAllFolder ? '' : `/${id}`}`)
  }

  const { drag, isDragging } = useDragFolder({ index, id, name })
  const { isFolderDragging, drop, canDrop, isOver, movingToFolder } = useDropFolder(id, onClickMenu(id), { index })

  const isActive = isOver && canDrop
  let background: string | undefined = undefined
  let color: string | undefined = undefined
  let borderBottom: string | undefined = undefined
  if (isFolderDragging) {
    if (isActive) {
      borderBottom = `48px solid ${blue[4]}`
    } else if (canDrop) {
      borderBottom = `2px solid ${blue[2]}`
    }
  } else if (isActive) {
    background = blue[4]
    color = '#FFF'
  } else if (canDrop) {
    background = blue[1]
  }

  return (
    <AnchorWrapper href={`/app/scenario/all/${id}`}>
      <StyledContainerItem isDragging={isDragging} ref={drop} style={{ background, color, borderBottom, transition: 'all .12s linear' }} title={name} className={isAllFolder && activeKey === '' || activeKey === `/${id}` ? 'ant-menu-item-selected' : undefined} key={`/${id}`} onClick={onClickMenu(id)}>
        {/* <Tooltip overlay={'Add new Folder'} placement="bottom">
          <StyledButtonAdd onClick={onClickCreateFolder}><PlusCircleFilled style={{ color: blue[4], background: '#fff' }} /></StyledButtonAdd>
        </Tooltip> */}
        <FolderContent
          id={id}
          index={index}
          name={name}
          dragRef={drag}
          isLoading={movingToFolder}
        />
      </StyledContainerItem>
    </AnchorWrapper>
  )
}

export default FolderTab

// export const StyledButtonAdd = styled.span`
//   position: absolute;
//   right: 50%;
//   top: 100%;
//   transform: translate(75%, -50%);
//   opacity: 0;
//   z-index: 1;
//   cursor: pointer;
// `

export const StyledContainerItem = styled.div<{ isDragging: boolean }>`
  max-width: 200px;
  position: relative;
  text-overflow: unset;
  overflow: unset;
  padding: 8px 16px;
  padding-left: 0;
  cursor: pointer;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  background: ${({ isDragging }) => isDragging ? '#F5F5F5' : undefined};
  color: ${({ isDragging }) => isDragging ? 'rgba(0, 0, 0, 0.85)' : undefined};
  &:hover {
    background: #F5F5F5;
    color: rgba(0, 0, 0, 0.85);
    & .folder-context-menu-btn, .folder-drag-handle-btn {
      opacity: 1
    }
    &:after {
      opacity: 0;
    }
  }
  &.ant-menu-item-selected {
    background: #F0FBFF;
    border-right: 2px solid ${blue[4]};
    :after {
      opacity: 1;
      transform: scaleY(1);
    }
  }
`

export const StyledP = styled.p`
  cursor: pointer;
  overflow-x: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
`