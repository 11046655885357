import { FC } from 'react'
import DataLoader from 'src/components/pages/app/dataLoader'
import PageHeader from 'src/pages/components/PageHeader'
import styled from 'styled-components'

const DataLoaderPage: FC = () => {
  return (
    <StyledContainer>
      <PageHeader
        style={{ background: '#FFF', padding: '8px 16px' }}
        title="Data Loader"
        // subTitle={'Manage and Explore Scenario Template to start your Automation!'}
      ></PageHeader>
      <StyledContentContainer>
        <DataLoader />
      </StyledContentContainer>
    </StyledContainer>
  )
}

export default DataLoaderPage

const StyledContainer = styled.div`
`
const StyledContentContainer = styled.div`
  padding: 16px;
`