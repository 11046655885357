import CustomModalMethod from '../../../../../../../util/CustomModalMethod'

type ArchiveTriggerState = {
  archiveTriggerState: (onOkArchive: () => void) => void
}

export default function useArchiveTriggerState(): ArchiveTriggerState {
  const getWidth = () => {
    const prevWidth = localStorage.getItem('widthStateForms')
    if (prevWidth) {
      return parseInt(prevWidth)
    }
    return undefined
  }
  const width = getWidth()

  const archiveTriggerState = (onOkArchive: () => void) => {
    CustomModalMethod.dangerConfirm({
      // getContainer: getContainer('state-forms-container'),
      style: { marginLeft: `calc(${width}px / 3)` },
      title: 'Are you sure delete this trigger?',
      content: 'Your scenario must have a trigger. Please set up another trigger.',
      // icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk: onOkArchive
    })
  }

  return {
    archiveTriggerState,
  }
}