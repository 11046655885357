import { FieldSchema } from 'db'
import { useEffect } from 'react'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  getParamSchema: (paramsForm: any, idxFieldChange?: number) => void | boolean,
  paramSchema?: FieldSchema[],
  fetchingParamSchema: boolean,
  errorFetchParamSchemas?: Error
}

type Params = {
  applicationId?: number,
  triggerId?: number,
  actionId?: number,
  bundleId?: number,
  initialParamSchema?: FieldSchema[],
  bundles?: any
}

export default function useGetParamSchema(params: Params, isHasDynamic?: boolean): Result {
  const { applicationId, triggerId, actionId, bundleId, bundles, initialParamSchema } = params
  const isValid = isHasDynamic && applicationId && (triggerId || actionId)
  const url = applicationId && triggerId ? `/application/${applicationId}/trigger/${triggerId}/paramSchema` :
    applicationId && actionId ? `/application/${applicationId}/action/${actionId}/paramSchema` :
      ''
  const { data, error, isValidating, call } = useApi<{ paramSchema: FieldSchema[] }>()

  useEffect(() => {
    getParamSchema(undefined)
  }, [])

  const getParamSchema = (paramsForm: any, _?: number) => {
    // fetch only all required fields has filled
    // const isRequiredFieldsHasFilled = paramsForm ? initialParamSchema?.filter(field => field.required)
    //   .reduce((res, field) => {
    //     return res && paramsForm[field.key]
    //   }, true) : true

    // do not fetch dynamic fields if next field has dynamic option
    // example case: change sheet that worksheet has filled from another sheet
    // const isNextFieldsHasDynamicOption = initialParamSchema?.reduce((res, currField , idx) => {
    //   if (currField.triggerId && (idxFieldChanged !== undefined && idx > idxFieldChanged)) {
    //     return res || true
    //   }
    //   return res
    // }, false)
    // console.log({ paramsForm, tes: initialParamSchema?.find(param => param.getValueFunc && param.allowEditByUser), isValid })
    if ((paramsForm !== undefined || initialParamSchema?.find(param => param.getValueFunc && param.allowEditByUser)) && isValid) {
      call(
        {
          method: 'post',
          url: url,
          params: {
            bundleId: bundleId
          },
          data: {
            ...bundles,
            params: paramsForm || {}
          }
        }
      )
      return true
    } else {
      return false
    }
  }

  return {
    getParamSchema: getParamSchema,
    paramSchema: data?.paramSchema,
    fetchingParamSchema: isValidating,
    errorFetchParamSchemas: error
  }
}