import { UnorderedListOutlined } from '@ant-design/icons'
import { Col, Layout, PageHeader, Row, Skeleton, Tooltip } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { useHistory, useRouteMatch } from 'react-router'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import BreadcrumbScenario from 'src/components/pages/app/scenario/edit/BreadcrumbScenario'
import useActiveStates from 'src/components/pages/app/scenario/edit/BreadcrumbScenario/useActiveStates'
import styled from 'styled-components'
import UserBillingAlert from '../../../components/pages/app/index/UserBillingAlert'
import ButtonLiveFooter from '../../../components/pages/app/scenario/edit/ButtonLiveFooter'
import EditScenarioNamePageHeader from '../../../components/pages/app/scenario/edit/EditScenarioNamePageHeader'
import { updatingScenarioOrState } from '../../../components/pages/app/scenario/edit/recoil'
import ScenarioOutline from '../../../components/pages/app/scenario/edit/ScenarioOutline'
import { useResizeOutline } from '../../../components/pages/app/scenario/edit/ScenarioOutline/useResizeOutline'
import State from '../../../components/pages/app/scenario/edit/State'
import ThisStateStaticValueContextProvider from '../../../components/pages/app/scenario/edit/ThisStateStaticValueContext'
import useSetStateTestData from '../../../components/pages/app/scenario/edit/useSetStateTestData'
import EditScenarioHeaderActions from '../../../components/pages/app/scenario/EditScenarioHeaderActions'
import useAScenario from '../../../hooks/useAScenario'
import useTitleHead from '../../../util/useTitleHead'

const EditScenarioPage: FC = () => {
  const { params, url } = useRouteMatch<{ scenarioId: string, stateId: string, isPath?: string }>()
  const { scenario, fetchingScenario } = useAScenario(parseInt(params.scenarioId), true)
  const { push, replace } = useHistory()
  const [counterFetchScenario, setCounterFetchScenario] = useState(0)
  const [showOutline, setShowOutline] = useState(true)
  // const [isModalPathVisible, setIsModalPathVisible] = useState(false)
  const resetIsUpdatingScenarioOrState = useResetRecoilState(updatingScenarioOrState)
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)
  const { activeStates, thisStatePath } = useActiveStates()

  const { splitPaneRef, onMouseDown, onMouseMove, onMouseUp, outlineRef, isResizing } = useResizeOutline(showOutline)

  useSetStateTestData(scenario?.states || [], counterFetchScenario)
  useTitleHead(scenario ? `${scenario?.name} | Otomatis - Workflow Automation Studio` : 'Otomatis - Workflow Automation Studio')

  const { width, ref } = useResizeDetector()
  useEffect(() => {
    localStorage.setItem('widthStateForms', `${width}`)
  }, [width])

  // move to useSetStateTestData
  // useEffect(() => {
  //   if (scenario?.states) {
  //     setNextStateIdsAll(getChildsAllStates(scenario.states))
  //     setParentsAllState(getParentsAllStates(scenario.states))
  //   }
  // }, [scenario])

  useEffect(() => {
    if (scenario && !fetchingScenario) {
      // setIsModalPathVisible(!!scenario?.states?.find(state => `${state.id}` === params.stateId)?.stateConditionId || !params.isPath && !!scenario?.states?.find(state => `${state.id}` === params.stateId)?.conditionGroupOrder)
    }
  }, [scenario, fetchingScenario, fetchingScenario, params.stateId, params.isPath])

  useEffect(() => {
    if (params && !params.stateId && scenario?.states) {
      const initActiveStateId = scenario.states[0].testData && !scenario.states[0].testData.error ?
        // if first state valid
        scenario.states.reduce((res, state) => {
          if (!res && !state.testData || state.testData?.error) {
            return state.id
          }
          return res
        }, 0)
        :
      // if first state not valid
        scenario.states.find(state => state.order === 0)?.id
      replace(`${url}/${initActiveStateId || scenario.states[0].id}`)
    }
    // if (params.stateId && !showOutline) {
    //   setShowOutline(!!scenario?.states?.find(state => `${state.id}` === params.stateId)?.stateConditionId || !params.isPath && !!scenario?.states?.find(state => `${state.id}` === params.stateId)?.conditionGroupOrder)
    // }
  }, [params, scenario])

  useEffect(() => {
    if (!fetchingScenario) {
      setCounterFetchScenario(prev => prev + 1)
    }
  }, [fetchingScenario])

  useEffect(() => {
    if (counterFetchScenario < 2) {
      resetIsUpdatingScenarioOrState()
    } else {
      setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
    }
  }, [counterFetchScenario])

  const afterArchive = () => {
    push('/app/scenario/all')
  }

  useEffect(() => {
    return () => {
      localStorage.removeItem('widthStateForms')
    }
  }, [])

  return (
    <StyledEditPage
      ref={splitPaneRef}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      isResizing={isResizing}
    >
      <StyledLayoutContent >
        <CustomHeaderPage
        >
          <EditScenarioNamePageHeader scenario={scenario} />

          <CustomHeaderActions>
            {
              !scenario ?
                <Skeleton.Input style={{ width: 100, height: 24, display: 'block' }} active /> :
                <EditScenarioHeaderActions onSuccessArchivedScenario={afterArchive} scenario={scenario} />
            }
          </CustomHeaderActions>

        </CustomHeaderPage>
        <BreadcrumbScenario />
        <Row ref={ref} style={{ overflowY: 'scroll', height: '100%' }}  id="state-forms-container">
          <Col flex={'auto'} sm={24} style={{ padding: '24px' }}>
            <UserBillingAlert />
            {
              scenario ? activeStates?.map((state, idx, states) =>
                !thisStatePath  ?
                  !state.conditionGroupOrder || !states[idx + 1] ?
                    <ThisStateStaticValueContextProvider key={state.id}>
                      <State
                        pathStates={!states[idx + 1] && state.conditionGroupOrder ? states.filter(s => s.order === state.order) : undefined}
                        loading={counterFetchScenario === 0}
                        scenario={scenario}
                        scenarioId={scenario.id}
                        stateId={state.id}
                        stateType={state.trigger ? 'trigger' : 'action'}
                      />
                    </ThisStateStaticValueContextProvider> :
                    null :
                  state.stateConditionId === thisStatePath?.id && !state.conditionGroupOrder ||
                  state.id === thisStatePath?.id || !states[idx + 1] ?
                    <ThisStateStaticValueContextProvider key={state.id}>
                      <State
                        pathStates={!states[idx + 1] && state.conditionGroupOrder && thisStatePath?.conditionGroupOrder && state.conditionGroupOrder > thisStatePath.conditionGroupOrder ? states.filter(s => s.order === state.order) : undefined}
                        loading={counterFetchScenario === 0}
                        scenario={scenario}
                        scenarioId={scenario.id}
                        stateId={state.id}
                        stateType={state.trigger ? 'trigger' : 'action'}
                      />
                    </ThisStateStaticValueContextProvider> :
                    null
              ) :
                null
            }
          </Col>
        </Row>
        <ButtonLiveFooter scenario={scenario} />
        {/* <StatePathModal
          counterFetchScenario={counterFetchScenario}
          scenario={scenario}
          isVisible={isModalPathVisible} /> */}
      </StyledLayoutContent>
      <StyledRightMenuContainer>
        <Tooltip title="Outline" placement="left">
          <StyledUnorderedListOutlined className={showOutline ? 'active' : ''} onClick={() => setShowOutline(!showOutline)} style={{ cursor: 'pointer' }} />
        </Tooltip>
      </StyledRightMenuContainer>
      {/* <div className="room-queue-separator" onMouseDown={onMouseDown} style={{ background: '#D0D6DD', width: '5px', cursor: 'col-resize', zIndex: 1001  }} /> */}
      <FormAndOutlineSeparator onMouseDown={onMouseDown} />
      {
        showOutline ?
          <ScenarioOutline refResize={outlineRef} />: null
      }
    </StyledEditPage>
  )
}

export default EditScenarioPage

const FormAndOutlineSeparator = styled.div`
  background: #D0D6DD;
  width: 5px;
  cursor: col-resize;
  z-index: 1002;

  &:hover, &:focus {
    background: #1890ffaa !important;
    box-shadow: 0px 0px 4px 0px #1890ffaa;
  }
`

const StyledUnorderedListOutlined = styled(UnorderedListOutlined)`
  &:hover, &.active:hover {
    background: #F9EBCB;
    color: #F3AD3D;
  }
  &.active {
    background: #F3AD3D;
    color: #FFF;
  }
  height: fit-content;
  padding: 12px;
  color: #595959;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
`
const StyledRightMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.05);
  background: #FFF;
  z-index: 1001;
`
const StyledEditPage = styled.div<{ isResizing: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: auto max-content max-content max-content;
  cursor:${props => props.isResizing ? 'col-resize' : 'default'};
`
const StyledLayoutContent = styled(Layout.Content)`
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
`

const CustomHeaderPage = styled(PageHeader)`
  & > .ant-page-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15); */
  background: #FFF;
  /* z-index: 2; */
`

const CustomHeaderActions = styled.div`
  display: flex;
  align-items: center;
`