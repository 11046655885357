import { Button, Modal, Space } from 'antd'
import { Bundle } from 'db'
import React, { FC, useEffect, useState } from 'react'
import useAllAuthentication from '../../../../../../hooks/application/authentication/useAllAuthentication'
import useAuthenticationTest from '../../../../../../hooks/application/authentication/useAuthenticationTest'
import useBundleTest from '../../../../../../hooks/bundle/useBundleTest'
import useRestoreABundle from '../../../../../../hooks/bundle/useRestoreABundle'
import useUpdateABundle from '../../../../../../hooks/bundle/useUpdateABundle'
import useArchiveABundle from '../../../../../../hooks/useArchiveABundle'
import { TableState } from '../../../../../../types'
import { red } from '../../../../../../util/colors'
import { IMPLIKASI_URL } from '../../../../../../util/Constant'
import Notification from '../../../../../../util/Notification'

const AccountTableActions: FC<{ account: Bundle, tableState?: TableState<Bundle>  }> = ({ account, tableState }) => {
  const { archiveBundle, archivingBundle } = useArchiveABundle(tableState)
  const { testBundle, testingBundle } = useBundleTest()
  const { restoreBundle, restoringBundle, responseRestoringBundle } = useRestoreABundle(tableState)
  const [applicationId, setApplicationId] = useState<number>()
  const { authentications } = useAllAuthentication(applicationId, account.versionId)
  const { updateBundle, errorUpdateBundle } = useUpdateABundle(tableState)
  const [activeReconnectId, setActiveReconnectId] = useState<number>()
  const [authData, setAuthData] = useState<any>()
  const authId = authentications?.[0]?.id
  const { testAuthentication } = useAuthenticationTest(applicationId, authId)

  // TODO implement testAccount functions
  const testAccount = (accountId: number) =>  testBundle(account.applicationId, accountId, account.versionId)
  // TODO implement testAccount functions
  const reconnectAccount = (accountId: number) => {
    restoreBundle(accountId)
    setActiveReconnectId(accountId)
    setApplicationId(account.applicationId)
  }

  useEffect(() => {
    if (authentications && responseRestoringBundle) {
      const authenticationId = authentications[0]?.id
      const windowAuth = window.open(`${IMPLIKASI_URL}/${applicationId}/run/auth/${authenticationId}?${account.editableName || account.name ? `editableName=${account.editableName || account.name}` : ''}${account.versionId ? `&versionId=${account.versionId}` : ''}`, `_${IMPLIKASI_URL}-${applicationId}-${authenticationId}`, 'height=600,width=800,centerscreen,chrome=yes')
      if (windowAuth) {
        windowAuth.onbeforeunload = () => {
          const windowAuthAsAny = windowAuth as any

          if (windowAuthAsAny.bundles) {
            // Old code. It assumes that nameAccountOtomatis always has a value, it isn't.
            // const { nameAccountOtomatis: editableName, ...payload } = windowAuthAsAny.bundles

            const { nameAccountOtomatis, ...payload } = windowAuthAsAny.bundles
            const editableName = nameAccountOtomatis // possibly undefined because it is optional field

            if (activeReconnectId === account.id && payload) {
              testAuthentication(payload)
                .then(() => {
                  updateBundle(account.id, { ...payload, editableName, bundle: null })
                    ?.then(() => {
                      testBundle(Number(applicationId), account.id, account.versionId)
                        ?.then(() => {
                          Notification.success({
                            message: 'Success, Account Reconnected'
                          })
                        })
                    })
                  setActiveReconnectId(undefined)
                })
            }
            windowAuthAsAny.bundles = undefined
          }
        }
      }
    }
  }, [authentications, responseRestoringBundle])

  useEffect(() => {
    const checkEvent = (event: any) => {
      if (event.data.source === 'otomatis') {
        if (event.data.payload.authData && activeReconnectId === account.id) {
          const { nameAccountOtomatis: editableName, ...payload } = event.data.payload
          setAuthData({ editableName, ...payload })
        }
        if (event.data.payload.bundle) {
          if (activeReconnectId === account.id) {
            const { ...payloadAuthData } = authData
            testAuthentication({
              ...payloadAuthData,
              ...event.data.payload
            }).then(() => {
              updateBundle(account.id, {
                ...authData,
                ...event.data.payload
              })?.then(() => {
                testBundle(Number(applicationId), account.id, account.versionId)
                  ?.then(() => {
                    Notification.success({
                      message: 'Success, Account Reconnected'
                    })
                  })
              })
            })
            setAuthData(undefined)
            setActiveReconnectId(undefined)
          }
        }
      }
    }
    window.addEventListener('message', checkEvent)
    return () => {
      window.removeEventListener('message', checkEvent)
    }
  }, [account, authData, activeReconnectId])

  // useEffect(() => {
  //   if (responseUpdatingBundle) {
  //     Notification.success({
  //       message: 'Success, Account Reconnected'
  //     })
  //   }
  // }, [responseUpdatingBundle])

  useEffect(() => {
    if (errorUpdateBundle) {
      Notification.error({
        message: 'Error, Account failed to reconnect',
      })
    }
  }, [errorUpdateBundle])


  const disconnectAccount = (accountId: number) => {
    if (account.states && account.states.length > 0) {
      Notification.error({
        message: 'Can not Disconnect',
        description: 'This connected Account is still being used in Scenario'
      })
    } else {
      Modal.confirm({
        title: `Are you sure to disconnect and delete "${account.editableName || account.name}" account?`,
        onOk() {
          archiveBundle(accountId)
        }
      })
    }
  }

  // TODO add more checking for testing and reconnect account
  const isActionButtonDisabled = archivingBundle || testingBundle || restoringBundle

  return (
    <Space>
      <Button type="link" size="small" disabled={isActionButtonDisabled} loading={testingBundle} onClick={() => testAccount(account.id)}>Test</Button>
      <Button type="link" size="small" disabled={isActionButtonDisabled} loading={restoringBundle} onClick={() => reconnectAccount(account.id)}>Reconnect</Button>
      {
        account.deletedAt ?
          null
          :
          <Button type="link" size="small" style={{ color: red[4] }} disabled={isActionButtonDisabled} loading={archivingBundle} onClick={() => disconnectAccount(account.id)}>Delete</Button>}
    </Space>
  )
}

export default AccountTableActions