import { Trigger } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  archivedTrigger?: Trigger,
  archivingTrigger: boolean,
  errorArchiveTriggers?: Error,
  archiveTrigger: (applicationId: number, triggerId: number, versionId?: number) => void
}

export default function useArchiveTrigger(): Result {
  const { data, error, isValidating, call } = useApi<{ trigger: Trigger }>()

  return {
    archivedTrigger: data?.trigger,
    archivingTrigger: isValidating,
    errorArchiveTriggers: error,
    archiveTrigger: (applicationId, triggerId, versionId) => {
      call({
        url: `/application/${applicationId}/trigger/${triggerId}/archive?versionId=${versionId}`,
        method: 'delete',
      })
    }
  }
}