import { LockOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, message } from 'antd'
import Axios from 'axios'
import Cookie from 'js-cookie'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import SignLayout from '../../../components/SignLayout'
import { OTORISASI_API_URL } from '../../../helpers/Constant'
import { routingFlow } from '../../../helpers/RoutingFlow'

const TwoFactorAuthVerify: React.FC = () => {
  const history = useHistory()
  const [code, setCode] = useState<string>()

  const verify = async () => {
    try {
      const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/verify2fa`, { code }, { withCredentials: true })
      if (data.isValid) {
        Cookie.set('authorized', 'true')
        await new Promise(res => setTimeout(res, 500))
        return await routingFlow(history, window.location.search ? new URLSearchParams(window.location.search) : null)
      }
      return message.error('Wrong code')
    } catch (error) {
      console.error(error)
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  return (
    <SignLayout>
      <Card title="2FA Verification" bordered={false}>
        <Form onFinish={verify}>
          <p>Use your app to get an authentication code and enter it below to log in.</p>
          <br/>
          <Form.Item
            name="code"
            rules={[{ required: true, message: 'Code cannot be blank' }]}>
            <Input autoComplete="off" placeholder="Code" prefix={<LockOutlined />} value={code} onChange={e => setCode(e.target.value)} />
          </Form.Item>

          <Form.Item>
            <Button style={{ float: 'right' }} type="primary" htmlType="submit">Login</Button>
          </Form.Item>
        </Form>
      </Card>
    </SignLayout>
  )
}

export default TwoFactorAuthVerify