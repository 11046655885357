import { FC } from 'react'
import FlowDiagramEditor from 'src/components/pages/app/scenario/flow/FlowDiagramEditor'
import AddAction from 'src/components/pages/app/scenario/flow/FlowDiagramEditor/AddAction'
import StateDetailAndForm from 'src/components/pages/app/scenario/flow/FlowDiagramEditor/StateDetailAndForm'

const FlowScenarioPage: FC = () => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <FlowDiagramEditor />
      <StateDetailAndForm />
      <AddAction />
    </div>
  )
}

export default FlowScenarioPage