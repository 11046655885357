import { Alert, Button, Card, Input, Space, Switch, Tabs, Tag, Typography } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import styled from 'styled-components'
import { blue, danger, lime, red } from '../../util/colors'

export const StyledButton = styled(Button)<{ bordered?: boolean }>`
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  &.ant-btn {
    ${({ bordered }) => bordered === false && 'border: none'};
    ${props => !props.type && 'color: rgba(0, 0, 0, 0.65)'};
  }
  &:hover, &:focus, &:visited  {
    color: ${blue[4]};
    border-color: ${blue[4]};
  }
  &.ant-btn-link {
    color: ${blue[4]};
    &.ant-btn-dangerous {
      color: ${danger};
    }
    &:hover, &:focus {
      border-color: transparent !important;
    }
    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &.ant-btn-background-ghost {
    &.ant-btn-dangerous {
      color: ${red[4]};
      border-color: ${red[4]};
      &:hover, &:focus {
        background: ${red[3]} !important;
        color: #FFF;
        border-color: ${red[3]};
      }
    }
  }
  &.ant-btn-primary {
    background: ${blue[4]};
    border-color: ${blue[4]};
    &[disabled] {
      background: #f5f5f5;
      border-color: #d9d9d9;
    }
    &:hover, &:focus {
      background: ${blue[3]};
      color: #FFF;
      border-color: ${blue[3]};
    }
    &.ant-btn-background-ghost {
      color: ${blue[4]};
      background: #FFF;
      &:hover, &:focus {
        background: ${blue[4]} !important;
        color: #FFF;
        border-color: ${blue[4]};
      }
      &[disabled] {
        background: #f5f5f5;
        border-color: #d9d9d9;
        color: #d9d9d9;
        &:hover, &:focus {
          background: #f5f5f5 !important;
          border-color: #d9d9d9;
          color: #d9d9d9;
        }
      }
    }
    &.ant-btn-dangerous {
      background: ${danger};
      border-color: ${danger};
      &:hover, &:focus {
        background: ${red[3]};
        color: #FFF;
        border-color: ${red[3]};
      }
    }
  }
  &.ant-btn-dashed {
    &.primary {
      color: ${blue[4]};
      border-color: ${blue[4]};
      &:hover, &:focus, &:visited  {
        color: ${blue[5]};
        border-color: ${blue[5]};
      }
      &[disabled] {
        background: #f5f5f5;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        &:hover, &:focus {
          background: #f5f5f5;
          border-color: #d9d9d9;
          color: #d9d9d9;
        }
      }
    }
  }
`

export const StyledSecondaryButton = styled(StyledButton)`
  &.ant-btn {
    color: ${blue[4]};
  }
  background: #FFF;
  border-color: ${blue[4]};
  &:hover, &:focus {
    background: #FFF;
    color: ${blue[6]};
    border-color: ${blue[6]};
  }
  &[disabled] {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: #d9d9d9;
    &:hover, &:focus {
      background: #f5f5f5;
      border-color: #d9d9d9;
      color: #d9d9d9;
    }
  }
`

export const StyledText = styled(Typography.Text)`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-left: 4px;
`
export const StyledFooterForm = styled(Space)`
  float: right;
`
export const StyledCard = styled(Card)`
  background: #FFFFFF;
  /* Blue/1 */

  border: 1px solid #F0FBFF;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 24px;
`

export const StyledLabel = styled.label<{ small?: boolean }>`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ small }) => small ? '12px !important' : '16px' };
  line-height: 24px;
  &.required::after {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
    content: '(Required)';
    color: ${'#9C3930'};
    margin-left: 4px;
  }
  &.no-status::after {
    content: '';
  }
  &::after {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    text-transform: capitalize;
    content: '(Optional)';
    margin-left: 4px;
  }
`
export const StyledDescription = styled.p`
  padding-bottom: 5px;
  color: rgba(0, 0, 0, 0.65);
`
export const StyledAlert = styled(Alert)`
  /* P2 - 12 description */
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  p, b {
    font-size: inherit;
  }
`
export const StyledTag = styled(Tag)`
  font-family: Menlo-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  white-space: pre-wrap;
`
export const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
`
export const StyledTabs = styled(Tabs)`
  background: #FFFFFF;

  /* border: 1px solid #F0FBFF; */
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  .ant-tabs-nav, .ant-tabs-content-holder {
    padding: 0 0px;
  }
  &.ant-tabs-card {
    .ant-tabs-nav, .ant-tabs-content-holder {
      padding: 0;
      .ant-tabs-tab {
        box-shadow: inset -1px 0 0 #f0f0f0, inset 0 1px 0 #f0f0f0, inset 1px 0 0 #f0f0f0;
      }
    }
    border-color: #FFF;
  }
`
export const StyledInput = styled(Input)`
`

export const StyledParagraph = styled(Typography.Paragraph)`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
`
export const StyledBoxIcon = styled.div<{ size?: 'small' | 'large', disabled?: boolean }>`
  width: ${props => props.size === 'small' ? '20px' : props.size === 'large' ? '44px' : '32px'};
  height: ${props => props.size === 'small' ? '20px' : props.size === 'large' ? '44px' : '32px'};
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: ${props => props.size === 'small' ? '17px' : '24px'};
    height: ${props => props.size === 'small' ? '17px' : '24px'};
    filter: ${props => props.disabled ? 'opacity(0.3) grayscale(1)' : 'unset'};
  }
`
export const StyledPMedium = styled(Typography.Paragraph)`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
`
export const StyledP2Description = styled(StyledPMedium)`
  font-size: 12px;
  font-weight: normal;
`
export const StyledTagLabel = styled(Tag)`
  font-size: 12px;
  font-weight: normal;
  ${props => props.color === 'success' ?
    `color: ${lime[6]};
    background: ${lime[1]};
    border-color: ${lime[3]};`
    : null}
  
`
export const StyledP1Strong = styled(StyledPMedium)`
  font-weight: bold;
  color: #000;
`

export const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #96C443;
  }
`
export const StyledInfo = styled(StyledAlert)`
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  margin-bottom: 16px;
`