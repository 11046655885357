import { Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useLogTask from '../../../../../../hooks/job/logTask/useLogTask'
import DataResponse from '../DataResponse'

interface Props {
  type: 'dataIn' | 'dataOut',
  number?: number,
  logoUrl?: string,
  isActive?: boolean,
  logTaskId: number
}
const DataContent: FC<Props> = ({ isActive, logTaskId, logoUrl, number, type }) => {
  const [counter, setCounter] = useState(0)
  const { id: logJobId } = useParams<{ id?: string }>()
  const { logTask, fetchingLogTask } = useLogTask({ logJobId, logTaskId: counter > 0 ? logTaskId : undefined, expands: type })

  useEffect(() => {
    if (isActive) {
      setCounter(counter + 1)
    }
  }, [isActive])

  return (
    <Spin spinning={fetchingLogTask}>
      <DataResponse number={number} data={logTask?.[type]} logoUrl={logoUrl} />
    </Spin>
  )
}

export default DataContent