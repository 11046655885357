
import { SorterResult } from 'antd/lib/table/interface'
import { Bundle } from 'db'
import { Implikasi } from 'src/service/Implikasi'
import { Error, TableState } from 'src/types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from 'src/util/Constant'
import useFetch from '../useFetch'


type Result = {
  revalidateBundles: () => void,
  bundlesLength?: number,
  bundles?: Bundle[],
  fetchingBundles: boolean,
  errorFetchBundles?: Error
}

export default function useConnectedAccounts(tableState?: TableState<Bundle>): Result {
  const key = `/bundle/connectedAccounts${tableState ? JSON.stringify(tableState) : ''}`
  const url = '/bundle/connectedAccounts'

  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const sorterOrder = (tableState?.sorter as SorterResult<Bundle>)?.order
  const sorterField = (tableState?.sorter as SorterResult<Bundle>)?.field
  const filters = tableState?.filter || {}
  const search = tableState?.filter?.search ? (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
    if (field === 'search') {
      return `${res},bundle.name.ilike=${filters[field]}`
    }
    return res
  }, 'true') : 'true'


  const { data, error, isValidating, revalidateFetch } = useFetch<{ bundles: Bundle[], length?: number }>(key, {}, {
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          orderBy: sorterField && sorterOrder ?
            `bundle.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}`
            : undefined,
          and: `(${search})`,
          skip: (currentPage - 1) * pageSize,
          take: pageSize,
          expands: 'application,states'
        },
        data: {
          // orderBy: sorterField && sorterOrder ? {
          //   [`bundle.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
          // } : undefined,
          // search: search
        }
      })
      return res.data
    },
    revalidateOnFocus: false })

  return {
    revalidateBundles: () => revalidateFetch(key),
    bundlesLength: data?.length,
    bundles: data?.bundles,
    fetchingBundles: isValidating,
    errorFetchBundles: error
  }
}