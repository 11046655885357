import { SorterResult } from 'antd/lib/table/interface'
import { LogTask } from 'db'
import { Implikasi } from '../../../service/Implikasi'
import { Error, TableState } from '../../../types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from '../../../util/Constant'
import useFetch from '../../useFetch'

type Result = {
  logTasksLength?: number,
  logTasks?: LogTask[],
  fetchingLogTasks: boolean,
  errorFetchLogTasks?: Error
}

export default function useLogTasks(logJobId?: number | string, tableState?: TableState<LogTask>): Result {
  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const _sorters: SorterResult<LogTask>[] | undefined = Array.isArray(tableState?.sorter) ? tableState?.sorter : undefined
  const _sorterOrder = (tableState?.sorter as SorterResult<LogTask>)?.order
  const _sorterField = (tableState?.sorter as SorterResult<LogTask>)?.field
  const filters = tableState?.filter || {}
  const search = (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
    if (field === 'search') {
      const ids = (filters[field] as string)?.match(/\d+/g) || []
      const searchByIds = ids.length > 0 ? `,logTask.id.in=(${ids.join(',')})` : ''
      return `${res},${searchByIds ? 'or=(' : ''}${searchByIds ? `${searchByIds})` : ''}`
    }
    if (field === 'deletedAt'  && filters[field]) {
      return `${res},logTask.${field}.is=not null`
    }
    // if (field === 'applicationId' && filters[field]) {
    //   return `${res},(applicationTrigger.id in (${(filters[field] as string[])?.map(val => `'${val}'`).join(', ')}) or applicationAction.id in (${(filters[field] as string[])?.map(val => `'${val}'`).join(', ')}))`
    // }
    if (filters[field]) {
      return `${res},logTask.${field}.in=(${(filters[field] as string[])?.map(val => `'${val}'`).join(',')})`
    }
    return res
  }, 'true')

  const key = logJobId ? `/logJob/${logJobId}/logTasks?orderBy="logTask"."createdAt":asc${JSON.stringify(tableState)}` : null
  const url = `/logJob/${logJobId}/logTasks`
  const { data, error, isValidating } = useFetch<{ logTasks: LogTask[], length: number }>(key, {}, {
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          skip: (currentPage - 1) * pageSize,
          take: pageSize,
          orderBy: 'logTask.createdAt:asc',
          'and': `(${search})`,
        },
      })
      return res.data
    },
    revalidateOnFocus: false
  })

  return {
    logTasksLength: data?.length,
    logTasks: data?.logTasks,
    fetchingLogTasks: isValidating,
    errorFetchLogTasks: error
  }
}