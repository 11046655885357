import { Menu, MenuItemProps } from 'antd'
import { FC } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router'
import { styled } from 'redoc'
import PageHeader from '../../../components/PageHeader'
import PrebuiltTemplates from './prebuiltTemplates'
import YourTemplates from './YourTemplates'

const ListTemplateScenario: FC = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const { push } = useHistory()

  const onClickMenu: MenuItemProps['onClick'] = (e) => {
    push(`${path}/${e.key}`)
  }

  return (
    <Container className="overflow-hidden">
      <PageHeader
        style={{ background: '#FFF', padding: '8px 16px' }}
        title="Scenario Templates"
        subTitle={'Manage and Explore Scenario Template to start your Automation!'}
      ></PageHeader>
      <Wrapper>
        <div className="bg-white w-full max-w-">
          <Menu selectedKeys={[pathname.replace(`${path}/`, '')]}>
            <StyledMenuItem key="prebuilt" onClick={onClickMenu} >Prebuilt Template</StyledMenuItem>
            <StyledMenuItem key="yours" onClick={onClickMenu} >Your Template</StyledMenuItem>
          </Menu>
        </div>
        <Switch>
          <Redirect exact from={path} to={`${path}/prebuilt`} />
          <Route path={`${path}/prebuilt`} component={PrebuiltTemplates} />
          <Route path={`${path}/yours`} component={YourTemplates} />
        </Switch>
      </Wrapper>
    </Container>
  )
}

export default ListTemplateScenario

const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item-selected {
    box-shadow: inset -3px 0px 0px #1890FF;
  }
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 180px auto;
  overflow-y: hidden;
`
const Container = styled.div`
  display: grid;
  overflow-y: hidden;
  grid-template-rows: max-content auto;
  height: 100vh;
`