import { EditOutlined } from '@ant-design/icons'
import { Skeleton, Tag, Typography } from 'antd'
import { Scenario, State } from 'db'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import useUpdateAScenario from '../../../../../hooks/useUpdateAScenario'
import { updatingScenarioOrState } from '../../scenario/edit/recoil'

const EditScenarioNamePageHeader: FC<{ isViewer?: boolean, scenario?: Scenario, state?: State }> = ({ isViewer, state, scenario }) => {
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const [name, setName] = useState((scenario || state)?.name || 'Name your template')
  const { updateScenarioName: patchName } = useUpdateAScenario()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const setUpdatingAScenarioState = useSetRecoilState(updatingScenarioOrState)


  useEffect(() => {
    if (scenario?.name) {
      setName(scenario.name)
    }
    if (state?.name) {
      setName(state.name)
    }
  }, [scenario?.name, state?.name])

  const updateScenarioName = (name: string) => {
    setName(name)
    patchName(parseInt(scenarioId), name)
  }
  const updateStateName = (name: string) => {
    setName(name)
    if (state?.id) {
      updateAScenarioState(parseInt(scenarioId), state.id, { name })
        .then(() => {
          setUpdatingAScenarioState({ counter: 2, isUpdating: false })
        })
    }
  }
  return (
    <div>
      {
        scenario && !state ?
          <Tag color="blue" style={{ height: 'max-content' }}>Template Scenario</Tag> :
          null
      }
      {
        scenarioId && !scenario && !state ?
          <Skeleton.Input style={{ width: 200, height: '38px', display: 'block' }} active /> :
          <StyledTitle
            level={4}
            editable={isViewer ? undefined :{
              tooltip: 'edit scenario name',
              icon: <EditOutlined />,
              onChange: async (input) => {
                if (!state) {
                  updateScenarioName(input)
                } else {
                  updateStateName(input)
                }
              }
            }}
          >
            {name}
          </StyledTitle>
      }
      {/*
      <StyledP>
        Follow these instructions to {scenario ? 'create your scenario' : 'add a scenario for this path'}.
      </StyledP> */}
    </div>
  )
}

export default EditScenarioNamePageHeader

const StyledTitle = styled(Typography.Title)`
  /* H3 - 24 */
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* Character/Primary .65 */

  color: rgba(0, 0, 0, 0.65);
  margin: 0 !important;
`
const _StyledP = styled(Typography.Paragraph)`
    /* P1 - 14 */
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* Character/Title .85 */
  color: rgba(0, 0, 0, 0.85);
`