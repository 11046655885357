import React, { FC, ReactText, ImgHTMLAttributes } from 'react'

type Props = ImgHTMLAttributes<any> & {
  src: string,
  width?: ReactText,
  height?: ReactText
}

const SVGIcon: FC<Props> = ({ src, width, height, ...props }) => {
  return (
    <img src={src} width={width} height={height} {...props} />
  )
}

export default SVGIcon