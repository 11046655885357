import { BranchesOutlined, CreditCardFilled, MenuFoldOutlined, MenuUnfoldOutlined, ThunderboltFilled } from '@ant-design/icons'
import { Layout } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import ReactMarkdownHeading from 'react-markdown-heading'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import styled from 'styled-components'
import { ReactComponent as OtomatisWhiteLogotype } from '../../assets/otomatis-logo (white).svg'
import CustomSiderDivider from '../../components/pages/app/index/CustomSiderDivider'
import UserBillingPlanSider from '../../components/pages/app/index/UserBillingPlanSider'
import { useLoggedInUser } from '../../contexts/LoggedInUserContextProvider'
import PrivacyPolicy from './privacyPolicy'
import TermOfService from './termOfService'

const { Sider, Content } = Layout

const Legal: FC = () => {
  const [user] = useLoggedInUser()
  const { path } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)
  const { boot } = useIntercom()
  const [text, setText] = useState<string>('')

  useEffect(() => {
    const path = location.pathname.includes('privacy-policy') ? require('./privacyPolicy/otomatis_privacy_policy.md') :
      location.pathname.includes('term-of-service') ? require('./termOfService/otomatis_tos.md') : null
    if (path) {
      const readmePath = path
      fetch(readmePath.default)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setText(text)
        })
    } else {
      setText('')
    }
  }, [location])

  // add/edit/create sider main menu here

  const mainSiderMenu: { label: string, path: string, icon?: JSX.Element, isAdminOnly?: boolean }[] = [
    {
      label: 'Create Scenario',
      path: `${path}/create-scenario`,
      icon: <ThunderboltFilled />
    },
    {
      label: 'My Scenario',
      path: `${path}/scenario`,
      icon: <BranchesOutlined />
    },
    {
      label: 'Billing and Usage',
      path: `${path}/billing-usage`,
      icon: <CreditCardFilled />
    }
  ]

  const settingsSiderMenu: { label: string, path: string, icon?: JSX.Element, isAdminOnly?: boolean }[] = [
    {
      label: 'My Profile',
      path: `${path}/settings/profile`,
      icon: undefined
    },
    {
      label: 'Security',
      path: `${path}/settings/security`,
      icon: undefined
    },
    {
      label: 'Admin Dashboard',
      path: `${path}/settings/admin`,
      isAdminOnly: true,
      icon: undefined
    }
  ]

  const getListMenu = (pathname: string) => {
    return pathname.includes('settings') ? settingsSiderMenu :
      pathname.includes('privacy-policy') ? [] :
        pathname.includes('term-of-service') ? [] :
          mainSiderMenu
  }

  useEffect(() => {
    if (user && !user.favouriteApps) {
      history.push('/tell-us')
    } else if (user) {
      boot({ customAttributes: { name: user.name } })
    }
  }, [user])

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }
  }, [location])

  const toHomeEndUserApp = () => {
    history.push('/app/create-scenario')
  }

  return (
    <Layout>
      <CustomSider
        trigger={collapsed ? <MenuUnfoldOutlined style={{ color: '#FFF' }} /> : <MenuFoldOutlined style={{ color: '#FFF' }} />}
        collapsible
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        collapsed={collapsed}
        breakpoint={location.pathname.includes('edit') ? 'xxl' : 'md'}
        collapsedWidth="0"
        iseditpage={`${location.pathname.includes('edit')}`}
        zeroWidthTriggerStyle={{ backgroundColor: '#F3AD3D', color: '#000' }}
      >
        <TopNavSider>
          <OtomatisLogo onClick={toHomeEndUserApp}>
            <OtomatisWhiteLogotype style={{ width: '100%', height: '90%' }} />
          </OtomatisLogo>

          <CustomSiderDivider />
          {
            text ?
              <ReactMarkdownHeading ulClassName="rmh-ul ant-menu ant-menu-root ant-menu-inline ant-menu-light" liClassName="rmh-li ant-menu-item" markdown={text} hyperlink={true} /> : null
          }
        </TopNavSider>
        {
          location.pathname.includes('settings') || getListMenu(location.pathname).length === 0 ?
            null:
            <BottomNavSider>
              <UserBillingPlanSider />

              <CustomSiderDivider />

            </BottomNavSider>
        }
      </CustomSider>
      <Layout>
        <StyledContent>
          {/* /app here */}
          <Switch>
            <Route path={`${path}/privacy-policy`} component={PrivacyPolicy} />
            <Route path={`${path}/term-of-services`} component={TermOfService} />
          </Switch>
        </StyledContent>
      </Layout>
    </Layout>
  )
}

export default Legal

const StyledContent = styled(Content)`
  max-height: 100vh;
  overflow-y: auto;
`

const CustomSider = styled(Sider)<{ iseditpage?: string }>`
  background-color: #3E73E3;

  .ant-layout-sider-zero-width-trigger {
    top: 0;
    right: -24px;
    line-height: 25px;
    height: 27px;
    width: 24px;
    z-index: ${props => props.iseditpage === 'true' ? '3' : '0'};
    display: ${props => props.iseditpage === 'true' ? 'block' : 'none'};
    @media (max-width: 768px) {
      z-index: 3;
      display: block;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .rmh-ul {
    background-color: transparent;
    color: #FFF;
    background-color: inherit;
    border: 0;
    max-height: 88vh;
    overflow-y: auto;

    & > .ant-menu-item-active {
      color: #FFF;
      background-color: #749BEB;
    }
    .rmh-li {
      height: fit-content;
      min-height: 40px;
      width: 100%;
      padding: 0 12px !important;
      display: flex;
      align-items: center;
      white-space: pre-wrap;

      &:hover {
        background-color: #749BEB;
      }

      a {
        color: white;
        max-width: 178px;
        &:active, &:hover {
          font-weight: bold;
        }
      }

      .ant-menu-inline-collapsed > & {
        display: none;
      }

      .ant-menu-inline > & {
        width: 100%;
      }

      &::after {
        border-right-color: transparent !important;
      }

      &.ant-menu-item:hover, &.ant-menu-item:active {
        color: #FFF;
      }
    }
  }
`

const OtomatisLogo = styled.div`
  height: 32px;
  margin: 12px;
  cursor: pointer;
`


const TopNavSider = styled.div``

const BottomNavSider = styled.div``