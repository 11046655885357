import { LogLambdaInvocation } from 'db'
import { Error } from '../../../types'
import useFetch from '../../useFetch'

interface ParamsLogsByLogId {
  applicationId?: string | number,
  logId?: string | number
}

interface ResponseEndpoint {
  success: boolean,
  logs: LogLambdaInvocation
}

interface LogsByLogId {
  logs?: LogLambdaInvocation['logs'],
  logLambdaInvocation?: LogLambdaInvocation,
  fetchingLogs: boolean,
  errorFetchLogs?: Error
}

export default function useLogsByLogId(params: ParamsLogsByLogId): LogsByLogId {
  const { applicationId, logId } = params
  // const key = applicationId && logId ? `logsByLogId${JSON.stringify(params)}` : null
  const url = applicationId && logId ? `/application/${applicationId}/logs/${logId}` : null

  const { data, isValidating, error } = useFetch<ResponseEndpoint>(url, {}, { revalidateOnFocus: false })

  return {
    errorFetchLogs: error,
    logLambdaInvocation: data?.logs,
    logs: data?.logs.logs,
    fetchingLogs: isValidating
  }
}