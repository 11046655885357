import { Checkbox, Input, Select, Space, Switch, Tag } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FieldSchema } from 'db'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import MarkdownRenderer from '../../../../../../components/MarkdownRenderer'
import useCreateOrUpdateAuthentication from '../../../../../../hooks/application/authentication/useCreateUpdateAuthentication'
import { useRevalidateFetch } from '../../../../../../hooks/useFetch'
import { StyledButton, StyledCard as BaseCard, StyledDescription, StyledFooterForm, StyledLabel } from '../../../../../components/StyledComponents'
import CustomForm from '../../../../components/form/CustomForm'
import FormItem from '../../../../components/form/FormItem'
import MonacoEditor from '../../../../components/form/MonacoEditor'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { useSharedValues } from './SharedValuesContextProvider'

interface Props {
  activeEditFieldForm?: FieldSchema & { idx?: number },
  onCancel: () => void
}

const FieldForm: FC<Props> = ({ activeEditFieldForm, onCancel }) => {
  const { revalidateAllAuthentication } = useRevalidateFetch()
  const [values] = useSharedValues()
  const [stringOptions, setStringOptions] = useState<string>()
  const [form] = useForm<FieldSchema>()
  const [getValueFunc, setGetValueFunc] = useState<string>()
  const { applicationId } = useParams<{ applicationId: string }>()
  const [version] = useApplicationVersionContext()
  const { createOrUpdateAuthentication, creatingAuthentication } = useCreateOrUpdateAuthentication()


  const onSubmit = async (allValues: any) => {
    const field = { ...allValues, options: stringOptions ? JSON.parse(stringOptions) : undefined, getValueFunc: getValueFunc }
    const isKeyDuplicate = values?.fieldSchema?.find(curr => curr.key === field.key)

    const existed = values?.fieldSchema || []

    const newFields = isKeyDuplicate || activeEditFieldForm ? existed?.map((currField, idx) => {
      if (currField.key === allValues.key) {
        return field
      }
      if (idx === activeEditFieldForm?.idx) {
        return field
      }
      return currField
    }): [...existed, field]

    const newAuth = await createOrUpdateAuthentication(applicationId, { ...values, fieldSchema: newFields, isDraft: values?.isDraft === undefined ? true : values?.isDraft, versionId: values?.versionId || version?.id })
    if (newAuth) {
      revalidateAllAuthentication(applicationId, values?.versionId || version?.id)
      onCancelForm()
      // setValues({ ...values, onAddFieldForm: false, activeEditFieldForm: undefined, ...newAuth })
    }
    // setValues(prevState => ({ ...prevState, onAddFieldForm: false, activeEditFieldForm: undefined, fieldSchema: newFields }))
    // setStringOptions(undefined)
    // setGetValueFunc(undefined)
  }

  const onCancelForm = () => {
    // setValues(prevState => ({ ...prevState, onAddFieldForm: false, activeEditFieldForm: undefined }))
    setStringOptions(undefined)
    setGetValueFunc(undefined)
    onCancel()
  }

  useEffect(() => {
    if (activeEditFieldForm) {
      form.setFieldsValue({
        ...activeEditFieldForm
      })
      if (activeEditFieldForm.options) {
        setStringOptions(JSON.stringify(activeEditFieldForm.options, null, 2))
      }
      if (activeEditFieldForm.getValueFunc) {
        setGetValueFunc(activeEditFieldForm.getValueFunc)
      }
    } else {
      form.resetFields()
      form.setFieldsValue({
        type: 'string'
      })
    }
  }, [activeEditFieldForm])

  return (
    <>
      <h4>{activeEditFieldForm ? 'Edit Field' : 'Add Field'}</h4>
      <StyledCard style={{ margin: 0, padding: 0 }}>
        <CustomForm onFinish={onSubmit} form={form}>
          <FormItem label="Label" name="label" description="Enter the field's friendly name for users." >
            <Input />
          </FormItem>
          <FormItem label="Key" name="key" description="Add the field key" rules={[{ required: true }]} extra={<Space>
            <p>Example:</p>
            <Tag>api_key</Tag>
          </Space>} >
            <Input />
          </FormItem>
          <FormItem name="required" valuePropName="checked" >
            <Checkbox>Is this field required? Check if yes.</Checkbox>
          </FormItem>
          <FormItem label="Type" name="type" description="Select the field type. Use String (default) for most text input, or Password to obscure text for secret values." >
            <Select>
              <Select.Option value="string" >String</Select.Option>
              <Select.Option value="password" >Password</Select.Option>
            </Select>
          </FormItem>
          <FormItem label="Help Text" name="helpText" description="Explain to users what to include in this field, especially for API keys and other hard to find info. Include directions to find the data and links to your app settings or help docs." >
            <MonacoEditor defaultLanguage="markdown" height="150px" />
          </FormItem>
          <FormItem shouldUpdate={(prev, next) => prev.helpText !== next.helpText}>
            {({ getFieldValue }) => {
              const helpText = getFieldValue('helpText')
              if (helpText) {
                return (
                  <>
                    Preview Help Text:
                    <StyledMarkdown>{helpText}</StyledMarkdown>
                  </>
                )
              }
              return null
            }}
          </FormItem>
          <FormItem shouldUpdate={(prev, next) => prev.type !== next.type}>
            {({ getFieldValue }) => {
              return (
                <FormItem label="Default Value" name="default" description="Include a default value for this field as a fallback. For optional fields, the default value is set on initial creation and used instead of missing or null values every time the Connectioon runs. For required fields, this value is used during Connection creation, but not when the Connection runs (Otomatis raises an error for missing/null values instead)." >
                  {
                    getFieldValue('type') === 'code' ?
                      <MonacoEditor defaultLanguage="javascript" /> :
                      <Input />
                  }
                </FormItem>
              )
            }}
          </FormItem>
          <FormItem label="Computed Value" description="Get value from others input" >
            <MonacoEditor theme="vs-dark" value={getValueFunc} setValue={setGetValueFunc} defaultLanguage='javascript' />
          </FormItem>
          <FormItem name='allowEditByUser' valuePropName="checked" label="Show Computed Value" description="Enable to make computed value result editable">
            <Switch />
          </FormItem>
          <FormItem label="Constant Value" name="constantValue" description="Include a constant value for this field as a fallback. User will cannot change the value of this field." >
            <Input />
          </FormItem>
          <FormItem>
            <StyledLabel>Static Dropdown</StyledLabel>
            <StyledDescription>List the dropdown choices.</StyledDescription>
            <MonacoEditor value={stringOptions} setValue={setStringOptions} theme="vs-dark" />
            <StyledDescription>Examples: ["username", "password"] or {'[{ "key": "userId", "label": "User ID" }]'}</StyledDescription>
          </FormItem>
          <StyledFooterForm>
            <StyledButton htmlType="button" onClick={onCancelForm}>Cancel</StyledButton>
            <StyledButton type="primary" loading={creatingAuthentication} htmlType="submit" >{activeEditFieldForm ? 'Save' : 'Add'}</StyledButton>
          </StyledFooterForm>
        </CustomForm>
      </StyledCard>
    </>
  )
}

export default FieldForm


const StyledCard = styled(BaseCard)`
  .ant-card-body {
    padding: 0
  }
`
const StyledMarkdown = styled(MarkdownRenderer)`
  height: 200px;
  overflow-y: auto;
`