import { AxiosPromise } from 'axios'
import { Scenario } from 'db'
import { Error } from '../types'
import useFetch, { useApi } from './useFetch'


type Result = {
  scenario?: Scenario,
  fetchingScenario: boolean,
  errorFetchScenario?: Error
}

export default function useAScenario(scenarioId?: number, expandState: boolean = false, withDeleted?: boolean): Result {
  const url = scenarioId ? `/scenario/${scenarioId}` : null
  const { data, error, isValidating } = useFetch<{ scenario: Scenario }>(
    url,
    expandState ? { params: { expands: 'state', withDeleted } } : {},
    { revalidateOnFocus: false }
  )

  return {
    scenario: data?.scenario,
    fetchingScenario: isValidating,
    errorFetchScenario: error
  }
}


export function useApiScenario(): Result & { getScenario: (scenarioId?: number, expandState?: boolean) => AxiosPromise<{ scenario: Scenario }> } {
  const { data, error, isValidating, call } = useApi<{ scenario: Scenario }>()

  return {
    scenario: data?.scenario,
    fetchingScenario: isValidating,
    errorFetchScenario: error,
    getScenario: (scenarioId, expandState) => {
      const url = `/scenario/${scenarioId}`
      return call({
        method: 'get',
        url: url,
        params: expandState ? { expands: 'state' } : undefined
      })
    }
  }
}