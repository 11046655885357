import { PricingPlan } from 'db'
import { Error } from '../../types'
import useFetch from '../useFetch'


type Response = {
  plan?: PricingPlan,
  scenariosCount: number,
  subscription?: Subscription,
  activeUsageRecord?: ActiveUsageRecord
}
type Result = {
  plan?: PricingPlan,
  planName?: string,
  status?: string,
  maxScenario?: number,
  maxTask?: number,
  totalUsage?: number,
  scenariosCount?: number,
  result?: Response,
  fetchingStatusPayment: boolean,
  errorFetchStatusPayment?: Error
}

export default function useStatusPayment(): Result {
  const url = '/payment/status'
  const { data, error, isValidating } = useFetch<Response>(url)

  return {
    plan: data?.plan,
    planName: data?.plan?.name,
    status: data?.subscription?.status,
    fetchingStatusPayment: isValidating,
    errorFetchStatusPayment: error,
    result: data,
    maxScenario: data?.plan?.maxScenario,
    maxTask: data?.plan?.monthlyTask,
    totalUsage: data?.activeUsageRecord?.total_usage,
    scenariosCount: data?.scenariosCount
  }
}

// quick type of subscription
export interface Subscription {
  status:        string,
  latestInvoice: LatestInvoice
}

export interface ActiveUsageRecord {
  id:                string,
  object:            string,
  invoice:           null,
  livemode:          boolean,
  period:            Period,
  subscription_item: string,
  total_usage:       number
}

export interface Period {
  end:   number | null,
  start: number | null
}

export interface AutomaticTax {
  enabled: boolean,
  status:  null
}

export interface Lines {
  object:      string,
  data:        Datum[],
  has_more:    boolean,
  total_count: number,
  url:         string
}

export interface Datum {
  id:                string,
  object:            string,
  amount:            number,
  currency:          string,
  description:       string,
  discount_amounts:  any[],
  discountable:      boolean,
  discounts:         any[],
  livemode:          boolean,
  metadata:          Metadata,
  period:            Period,
  plan:              DatumPlan,
  price:             Price,
  proration:         boolean,
  quantity:          number,
  subscription:      string,
  subscription_item: string,
  tax_amounts:       TaxAmount[],
  tax_rates:         TaxRate[],
  type:              string
}

export interface Metadata {
}

export interface DatumPlan {
  id:                string,
  object:            string,
  active:            boolean,
  aggregate_usage:   string,
  amount:            null,
  amount_decimal:    null,
  billing_scheme:    string,
  created:           number,
  currency:          string,
  interval:          string,
  interval_count:    number,
  livemode:          boolean,
  metadata:          Metadata,
  nickname:          null,
  product:           string,
  tiers_mode:        string,
  transform_usage:   null,
  trial_period_days: null,
  usage_type:        string
}

export interface Price {
  id:                  string,
  object:              string,
  active:              boolean,
  billing_scheme:      string,
  created:             number,
  currency:            string,
  livemode:            boolean,
  lookup_key:          null,
  metadata:            Metadata,
  nickname:            null,
  product:             string,
  recurring:           Recurring,
  tiers_mode:          string,
  transform_quantity:  null,
  type:                string,
  unit_amount:         null,
  unit_amount_decimal: null
}

export interface Recurring {
  aggregate_usage:   string,
  interval:          string,
  interval_count:    number,
  trial_period_days: null,
  usage_type:        string
}

export interface TaxAmount {
  amount:    number,
  inclusive: boolean,
  tax_rate:  string
}

export interface TaxRate {
  id:           string,
  object:       string,
  active:       boolean,
  country:      string,
  created:      number,
  description:  string,
  display_name: string,
  inclusive:    boolean,
  jurisdiction: null,
  livemode:     boolean,
  metadata:     Metadata,
  percentage:   number,
  state:        null
}

export interface PaymentSettings {
  payment_method_options: null,
  payment_method_types:   null
}

export interface StatusTransitions {
  finalized_at:            number,
  marked_uncollectible_at: null,
  paid_at:                 number,
  voided_at:               null
}

interface LatestInvoice {
  id:                               string,
  object:                           string,
  account_country:                  string,
  account_name:                     string,
  account_tax_ids:                  null,
  amount_due:                       number,
  amount_paid:                      number,
  amount_remaining:                 number,
  application_fee_amount:           null,
  attempt_count:                    number,
  attempted:                        boolean,
  auto_advance:                     boolean,
  automatic_tax:                    AutomaticTax,
  billing_reason:                   string,
  charge:                           null,
  collection_method:                string,
  created:                          number,
  currency:                         string,
  custom_fields:                    null,
  customer:                         string,
  customer_address:                 null,
  customer_email:                   string,
  customer_name:                    string,
  customer_phone:                   null,
  customer_shipping:                null,
  customer_tax_exempt:              string,
  customer_tax_ids:                 any[],
  default_payment_method:           null,
  default_source:                   null,
  default_tax_rates:                any[],
  description:                      null,
  discount:                         null,
  discounts:                        any[],
  due_date:                         number,
  ending_balance:                   number,
  footer:                           null,
  hosted_invoice_url:               string,
  invoice_pdf:                      string,
  last_finalization_error:          null,
  lines:                            Lines,
  livemode:                         boolean,
  metadata:                         Metadata,
  next_payment_attempt:             null,
  number:                           string,
  on_behalf_of:                     null,
  paid:                             boolean,
  payment_intent:                   null,
  payment_settings:                 PaymentSettings,
  period_end:                       number,
  period_start:                     number,
  post_payment_credit_notes_amount: number,
  pre_payment_credit_notes_amount:  number,
  receipt_number:                   null,
  starting_balance:                 number,
  statement_descriptor:             null,
  status:                           string,
  status_transitions:               StatusTransitions,
  subscription:                     string,
  subtotal:                         number,
  tax:                              number,
  total:                            number,
  total_discount_amounts:           any[],
  total_tax_amounts:                TaxAmount[],
  transfer_data:                    null,
  webhooks_delivered_at:            number
}