import { Scenario } from 'db'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { updatingScenarioOrState } from '../components/pages/app/scenario/edit/recoil'
import { Implikasi } from '../service/Implikasi'
import { Error, TableState } from '../types'
import Notification from '../util/Notification'
import { useRevalidateFetch } from './useFetch'

type Result = {
  updateScenario: (scenarioId: number, payload: Partial<Scenario>) => void,
  updateScenarioName: (scenarioId: number, newName: string) => void,
  responseUpdatingScenario?: Scenario,
  isSuccessUpdatingScenario: boolean,
  updatingScenario: boolean,
  errorUpdateScenario?: Error
}

export default function useUpdateAScenario(tableState?: TableState<Scenario>): Result {
  const { revalidateAScenario, revalidateScenarios } = useRevalidateFetch()
  const [responseUpdatingScenario, setResponseUpdatingScenario] = useState()
  const [updatingScenario, setUpdatingScenario] = useState<boolean>(false)
  const [errorUpdateScenario, setErrorUpdateScenario] = useState<Error>()
  const [isSuccessUpdatingScenario, setIsSuccessUpdatingScenario] = useState<boolean>(false)
  const setUpdatingAScenarioState = useSetRecoilState(updatingScenarioOrState)

  const updateScenarioName = async (scenarioId: number, newName: string) => updateScenario(scenarioId, { name: newName })

  const updateScenario = async (scenarioId: number, payload: Partial<Scenario>) => {
    if (scenarioId) {
      setUpdatingAScenarioState(prevState => ({ counter: prevState.counter + 1, isUpdating: true }))
      try {
        setUpdatingScenario(true)
        const updateScenario = await Implikasi.patch(`/scenario/${scenarioId}`, { scenario: { ...payload } })

        if (updateScenario.status === 200) {
          setIsSuccessUpdatingScenario(true)
        } else {
          setIsSuccessUpdatingScenario(false)
        }

        // revalidate any component that used Scenario model
        revalidateScenarios(tableState)
        revalidateAScenario(scenarioId)

        setResponseUpdatingScenario(updateScenario.data)
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong when updating scenario',
          description: response?.data?.error
        })

        setErrorUpdateScenario(response?.data?.error)
      } finally {
        setUpdatingScenario(false)
      }
    }
  }

  return {
    updateScenario,
    updateScenarioName,
    isSuccessUpdatingScenario,
    responseUpdatingScenario,
    updatingScenario,
    errorUpdateScenario
  }
}