import { Modal, Typography } from 'antd'
import { BASE_API_URL_IMPLIKASI } from '../../../service/Implikasi'
import { useApi } from '../../useFetch'

type Result = {
  exportAppToMd: (applicationId: number, versionId: number) => void,
  exportTriggers: (applicationId: number, triggerIds: number[]) => void,
  exportActions: (applicationId: number, actionIds: number[]) => void,
  exportApplication: (applicationId: number) => void
}

export default function useExportApplication(): Result {
  const { call } = useApi()
  return {
    exportActions: (applicationId, actionIds) => window.open(`${BASE_API_URL_IMPLIKASI}/application/${applicationId}/export?actionIds=${actionIds.join(',')}`, '_blank'),
    exportTriggers: (applicationId, triggerIds) => window.open(`${BASE_API_URL_IMPLIKASI}/application/${applicationId}/export?triggerIds=${triggerIds.join(',')}`, '_blank'),
    exportApplication: (applicationId) => window.open(`${BASE_API_URL_IMPLIKASI}/application/${applicationId}/export`, '_blank'),
    exportAppToMd: (applicationId, versionId) => call({
      url: `/utils/exportApp?id=${applicationId}&versionId=${versionId}&format=md`
    }).then(({ data }) => {
      Modal.confirm({
        icon: false,
        centered: true,
        closable: true,
        onCancel: () => true,
        title: 'Hasil Export',
        cancelText: <Typography.Text copyable={{ text: data.results, icon: 'Copy All' }} />,
        width: 'max-content',
        content: <pre>
          {data.results}
          <br /><br />
        </pre>
      })
    }),
  }
}