import { CloseOutlined } from '@ant-design/icons'
import { Col, Form, Row, Select, Space } from 'antd'
import { Action, Application, LogicalOperatos, Trigger } from 'db'
import { debounce } from 'debounce'
import isEqual from 'lodash.isequal'
import { Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useStateTest, { ResponseTest } from '../../../../../hooks/scenario/state/useStateTest'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import CustomForm from '../../../../../pages/developer/components/form/CustomForm'
import { getFlatDataTest } from '../../../../../util/formatter'
import { updatingScenarioOrState } from './recoil'
import RichTextInput from './RichTextInput'
import { StyledFormItem as BaseFormItem } from './StateForm'
import { useStateTestData } from './StateTestDataContext'
import StateTestSection from './StateTestSection'
import { useThisStateStaticValue } from './ThisStateStaticValueContext'


interface Props {
  setSetupActionStatus?: Dispatch<SetStateAction<{ chooseAccount: boolean, testTriggerAction: boolean, testTriggerActionSuccess: boolean }>>,
  conditions?: string[][][],
  stateId: number,
  scenarioId?: number,
  application?: Application,
  actionName?: string,
  stateOrder: number,
  type?: Trigger['type'] | Action['type'],
  actionId?: number
}

const StateContentCondition: FC<Props> = ({ setSetupActionStatus, type, scenarioId, conditions, stateId, application, stateOrder, actionName }) => {
  const [form] = Form.useForm<any>()
  const [counterChange, setCounterChange] = useState(0)
  const [_stateTestData, setStateTestData] = useStateTestData()
  const { testState, testingState } = useStateTest()
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const [thisStaticValue] = useThisStateStaticValue()
  const stateName = thisStaticValue?.stateName
  const [isMouseLeavingForm, setIsMouseLeavingForm] = useState<boolean>()
  const { revalidateAScenarioState } = useRevalidateFetch()
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)

  const onMouseEnter = () => setIsMouseLeavingForm(false)
  const onMouseLeave = () => setIsMouseLeavingForm(true)

  const updateStateParams = useCallback((newParamsValue: Record<string, any>) => {
    if (scenarioId) {
      const newConditions = newParamsValue?.or
        ?.map((or: any) => or.and
          ?.map((andClause: any) => [andClause.field, andClause.operator, andClause.value]))
      if (!isEqual(conditions, newConditions)) {
        updateAScenarioState(scenarioId, stateId, { conditions: newConditions })
          .then(() => {
            setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
            revalidateAScenarioState(scenarioId, stateId)
          })
      }
    }
  }, [scenarioId, stateId, updateAScenarioState])


  useEffect(() => {
    let debounceUpdateState: any

    if (isMouseLeavingForm) {
      const formValue = form.getFieldsValue()
      const newConditions = formValue?.or
        ?.map((or: any) => or.and
          ?.map((andClause: any) => [andClause.field, andClause.operator, andClause.value]))

      if (newConditions?.length > 0 && !isEqual(newConditions, conditions)) {
        debounceUpdateState = debounce(() => updateStateParams(form.getFieldsValue()), 1000)

        debounceUpdateState()
      }

      return () => {
        if (debounceUpdateState) {
          debounceUpdateState.clear()
        }
      }
    }
  }, [isMouseLeavingForm])

  const onValuesChange = (_: any, __: any) => {
    // console.log(allValue)
  }
  const saveTestData = (testData: ResponseTest) => {
    if (testData && scenarioId && stateId) {
      const rawTestData: Record<string, any> | (Record<string, any>)[] =  typeof testData.error === 'string' ? testData.data || testData : testData.error || testData.data || testData
      let triggerActionTestResult: Record<string, any>

      if (Array.isArray(rawTestData)) {
        triggerActionTestResult = { ...rawTestData[0] }
      } else {
        triggerActionTestResult = { ...rawTestData }
      }

      setStateTestData(prevState => {
        const prevStateWithTestData = prevState && prevState[`${stateId}`] ?
          prevState[`${stateId}`] :
          {
            id: stateId,
            order: stateOrder,
            triggerActionName: actionName,
            stateName,
            application: application
          }

        // const stateFlattenTestData: Record<string, any> = flatten(triggerActionTestResult)

        // const flattenTestDataSafe: Record<string, any> = flatten(triggerActionTestResult, { safe: true })

        // const flatTestDataSafe = Object.keys(flattenTestDataSafe).reduce((acc, dataKey) => ({
        //   ...acc,
        //   [dataKey]: {
        //     sample: truncateLongData(flattenTestDataSafe[dataKey]),
        //     value: `${stateId}.${dataKey}`,
        //     label: `${stateOrder + 1}. ${dataKey} ${Array.isArray(flattenTestDataSafe[dataKey]) ? '(array)': ''}`,
        //     appLogoUrl: application?.logoUrl
        //   }
        // }), {})

        // const flattenCodeMirrorTagData = Object.keys(stateFlattenTestData)
        //   .filter(dataKey => typeof stateFlattenTestData[dataKey] !== 'object')
        //   .reduce((acc, dataKey) => ({
        //     ...acc,
        //     [dataKey]: {
        //       sample: truncateLongData(stateFlattenTestData[dataKey]),
        //       value: `${stateId}.${dataKey}`,
        //       label: `${stateOrder + 1}. ${dataKey}`,
        //       appLogoUrl: application?.logoUrl
        //     }
        //   }), flatTestDataSafe)

        return {
          ...prevState,
          [`${stateId}`]: {
            ...prevStateWithTestData,
            type: type,
            realTestData: {
              ...testData,
              success: !testData.error
            },
            testData: triggerActionTestResult,
            flatTestData: getFlatDataTest({ testData: triggerActionTestResult, stateOrder, stateId, application })
          }
        }
      })
    }
  }

  const onTest = async () => {
    if (scenarioId && stateId) {
      const conditions = form.getFieldsValue()?.or
        ?.map((or: any) => or.and
          ?.map((andClause: any) => [andClause.field, andClause.operator, andClause.value]))

      // patch state
      await updateAScenarioState(scenarioId, stateId, {
        conditions: conditions
      })

      // test state
      const test = await testState(scenarioId, stateId).then(({ data }) => data)
      if (test) {
        saveTestData(test)
        if (test.success === false) {
          setSetupActionStatus?.(prevState => ({ ...prevState, testTriggerAction: true, testTriggerActionSuccess: false }))
        } else {
          setSetupActionStatus?.(prevState => ({ ...prevState, testTriggerAction: true, testTriggerActionSuccess: true }))
        }
      }
    }
  }

  useEffect(() => {
    if (!conditions) {
      form.setFieldsValue({
        or: [{ and: [{}] }]
      })
    } else {
      setCounterChange(counterChange + 1)
      const value = conditions.map(statement => ({
        and: statement.map(clause => ({ field: clause[0], operator: clause[1], value: clause[2] }))
      }))
      form.setFieldsValue({
        or: value
      })
    }
  }, [conditions])

  return (
    <StyledContainer>
      <h4>Data Filter</h4>
      <CustomForm form={form} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onValuesChange={onValuesChange}>
        <Form.List name="or">
          {(fieldsOr, { add, remove }) =>
            <>
              {fieldsOr.map((fieldOr, idxOr) =>
                <Fragment key={idxOr}>
                  <h5>{
                    idxOr === 0 ? 'Only continue if..' : 'Or continue if..'
                  }</h5>
                  <Form.List name={[fieldOr.name, 'and']}>
                    {(fieldsAnd, andOperation) =>
                      <>
                        {fieldsAnd.map((fieldAnd, idxAnd) =>
                          <Row gutter={8} key={`${idxOr}-${idxAnd}`}>
                            <Col lg={23} xl={23} md={23} sm={20} xs={20} >
                              <div style={{ position: 'relative' }}>
                                <Row gutter={8} align="top">
                                  <Col lg={10} xl={10} md={10} sm={24} xs={24} >
                                    <StyledFormItem
                                      name={[fieldAnd.name, 'field']}
                                      fieldKey={[fieldAnd.fieldKey, 'field']}
                                    >
                                      {
                                        // eslint-disable-next-line
                                        // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                                        <RichTextInput onChange={() => setCounterChange(counterChange + 1)} placeholder="Choose Field" id={`text-input-${idxOr}-${idxAnd}`} />
                                      }
                                    </StyledFormItem>
                                  </Col>
                                  <Col lg={4} xl={4} md={4} sm={24} xs={24}>
                                    <StyledFormItem
                                      name={[fieldAnd.name, 'operator']}
                                      fieldKey={[fieldAnd.fieldKey, 'operator']}
                                    >
                                      <Select
                                        onChange={() => setCounterChange(counterChange + 1)}
                                        placeholder="Choose Condition"
                                        showSearch
                                        filterOption={(input, option) =>
                                          (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={Object.keys(LogicalOperatos).map(o => ({ value: o, label: (LogicalOperatos as any)[o]?.label }))} />
                                    </StyledFormItem>
                                  </Col>
                                  <Col lg={10} xl={10} md={10} sm={24} xs={24}>
                                    <StyledFormItem shouldUpdate={(prev, next) => prev?.or?.[idxOr]?.and?.[idxAnd]?.operator !== next?.or?.[idxOr]?.and?.[idxAnd]?.operator}>
                                      {
                                        ({ getFieldsValue }) => {
                                          const thisOperator = getFieldsValue()?.or?.[idxOr]?.and?.[idxAnd]?.operator
                                          return <StyledFormItem
                                            hidden={!LogicalOperatos?.[thisOperator]?.isNeedComparisonValue}
                                            name={[fieldAnd.name, 'value']}
                                            fieldKey={[fieldAnd.fieldKey, 'value']}
                                            style={{ position: 'unset' }}
                                          >
                                            {
                                              // eslint-disable-next-line
// @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                                              <RichTextInput onChange={() => setCounterChange(counterChange + 1)} placeholder="Enter or Select Value" style={{ position: 'unset' }} id={`text-input-${idxOr}-${idxAnd}`} />
                                            }
                                          </StyledFormItem>
                                        }
                                      }
                                    </StyledFormItem>
                                  </Col>
                                </Row>
                              </div>

                            </Col>
                            <Col span={1} style={{ paddingTop: 4, textAlign: 'center' }}>
                              <CloseOutlined onClick={() => {
                                andOperation.remove(idxAnd)
                                if (idxAnd === 0) {
                                  remove(idxOr)
                                }
                              }}/>
                            </Col>
                          </Row>
                        )}
                        <Space style={{ marginBottom: '20px' }}>
                          <StyledButton onClick={() => andOperation.add({})}>And</StyledButton>
                          {fieldsOr[idxOr + 1] ? null :
                            <StyledButton onClick={() => add({ and: [{}] })}>Or</StyledButton>
                          }
                        </Space>
                      </>
                    }
                  </Form.List>
                </Fragment>
              )}
            </>
          }
        </Form.List>
      </CustomForm>
      {
        counterChange > 0 ?
          <StateTestSection
            onClickTest={onTest}
            loading={updatingAScenarioState || testingState}
            logoApplicationUrl={application?.logoUrl}
            stateId={stateId}
            stateOrder={stateOrder}
          /> :
          null
      }
    </StyledContainer>
  )
}

export default StateContentCondition

const StyledContainer = styled.div`
  padding: 0 16px;
  .ant-row {
    position: unset;
  }
  .ant-form-item-control-input, .ant-form-item, .ant-col, .ant-form-item-control {
    position: unset;
  }
  .dataPickerPopperContainer {
    transform: translate(0, 38px) !important;
  }
`

const StyledFormItem = styled(BaseFormItem)`
  position: unset;
  .codeMirrorContainer {
    position: unset;
  }

  .CodeMirror {
    min-height: 0;
    height: 30px;
    padding-top: 1px;
  }
`