import { Spin } from 'antd'
import { FC } from 'react'
import { useActions } from 'src/hooks/application/action/useAllAction'
import { useTriggers } from 'src/hooks/application/trigger/useAllTrigger'

interface Props {
  versionId?: number,
  type: 'trigger' | 'action',
  applicationId: number
}

const TotalEvent: FC<Props> = ({ versionId, type, applicationId }) => {
  const { fetchingAllTriggers, triggersLength } = useTriggers(type === 'trigger' ? applicationId: undefined, versionId)
  const { fetchingAllActions, actionsLength } = useActions(type === 'action' ? applicationId : undefined, versionId)
  return (
    <>
      {
        fetchingAllActions || fetchingAllTriggers ?
          <Spin spinning /> :
          type === 'action' ? actionsLength || 0 : triggersLength || 0}
    </>
  )
}

export default TotalEvent