import { Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useLogHttpData from '../../../../../../hooks/job/logTask/logHttpData/useLogHttpData'
import MonacoEditor from '../../../../../../pages/developer/components/form/MonacoEditor'

interface Props {
  isActive?: boolean,
  logTaskId: number
}
const HttpDataContent: FC<Props> = ({ isActive, logTaskId }) => {
  const [counter, setCounter] = useState(0)
  const { id: logJobId } = useParams<{ id?: string }>()
  const { logHttpData, fetchingLogHttpDatas } = useLogHttpData(logJobId, counter > 0 ? logTaskId : undefined)

  useEffect(() => {
    if (isActive) {
      setCounter(counter + 1)
    }
  }, [isActive])

  return (
    <Spin spinning={fetchingLogHttpDatas}>
      <MonacoEditor options={{ readOnly: true }} value={JSON.stringify(logHttpData, null, 2) || ''} />
    </Spin>
  )
}

export default HttpDataContent