import { Input, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FieldSchema } from 'db'
import { FC, useEffect } from 'react'
import { StyledButton, StyledFooterForm, StyledTag } from '../../../components/StyledComponents'
import CustomForm from '../../components/form/CustomForm'
import FormItem from '../../components/form/FormItem'
import MonacoEditor from '../../components/form/MonacoEditor'

const sample = `/**
* You can create an async function or not, here's a working
* example for Form Editor
*
* @param data It contains params, authData, and bundle object
* @param module Use this to get the module-level functions/consts
* @returns FieldSchema[]
*/
async (data, module) => {
 // expands the data object
 // params: all of the previous fields value
 // authData: the value from others field in authentication
 // bundle: the response data from auth endpoints will be stored here
 const { params, authData, bundle } = data

 // you can load the built-in packages like this
 const axios = module.require('axios')
 const resp = await axios.get('https://example.com/getOtherFields', {
   headers: { authorization: \`Bearer \${bundle.accessToken}\` }
 })

 // should return like this type
 // type FieldSchema = {
 //   label: string,
 //   key: string,
 //   type: string,
 //   required: boolean,
 //   helpText?: string,
 //   options?: string[],
 //   default?: string,
 //   constantValue?: string
 // }[]
 return resp.data.map(field => ({
   key: field.key,
   label: field.label,
   required: true,
   type: 'string',
   helpText?: string,      // optional
   options?: string[],     // optional
   default?: string,       // optional
   constantValue?: string, // optional
 }))
}`

export type DynamicFields = Pick<FieldSchema, 'key' | 'paramSchemaFunc'>
interface Props {
  initialValue?: DynamicFields,
  saving?: boolean,
  onCancel: () => void,
  onSave: (value?: Pick<FieldSchema, 'key' | 'paramSchemaFunc'>) => void
}
const DynamicFieldForm: FC<Props> = ({ saving, onCancel, onSave, initialValue }) => {
  const [form] = useForm()

  useEffect(() => {
    form.setFieldsValue(initialValue)
  }, [initialValue])

  const save = () => {
    onSave(form.getFieldsValue())
  }

  return (
    <CustomForm form={form} onFinish={save}>
      <FormItem label="Key" name="key" description="Enter the word or phrase your API uses to reference this field or parameter. Not seen by users." rules={[{ required: true }]} extra={<Space>
        <p>Example:</p>
        <StyledTag>first_name</StyledTag>
        <StyledTag>nama_depan</StyledTag>
      </Space>} >
        <Input />
      </FormItem>
      <FormItem name="paramSchemaFunc" label="Function" description="Use Dynamic Fields to fetch user defined fields with a custom API calls to your app. Otomatis expects the response data to be an array containing field keys and names">
        <MonacoEditor defaultValue={sample} defaultLanguage="javascript" />
      </FormItem>
      <br />
      <StyledFooterForm>
        <StyledButton onClick={onCancel} htmlType="button">Cancel</StyledButton>
        <StyledButton type="primary" loading={saving} htmlType="submit">Save</StyledButton>
      </StyledFooterForm>
    </CustomForm>
  )
}

export default DynamicFieldForm