import { Col, Divider, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { DateTime } from 'luxon'
import moment from 'moment'
import { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import ScenarioDetailRow from 'src/components/pages/app/history/job/ScenarioDetailRow'
import styled from 'styled-components'
import { ReactComponent as OtomatisLogo } from '../../../../assets/otomatis_logo.svg'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import { useLogJobsByCreatedAt } from '../../../../hooks/job/useLogJobs'
import { Invoice } from '../../../../hooks/payment/useInvoices'
import useStatusPayment from '../../../../hooks/payment/useStatusPayment'
import { IMPLIKASI_URL } from '../../../../util/Constant'
import { currencyFormatterIDR, numberFormatting } from '../../../../util/formatter'
import { StyledButton } from '../../../components/StyledComponents'

interface Props {
  active?: Invoice
}

const ReportPdfComponent: FC<Props> = ({ active }) => {
  const [loggedInUser] = useLoggedInUser()
  const { totalUsage, plan, scenariosCount, result } = useStatusPayment()
  const { invoiceId } = useParams<{ invoiceId?: string }>()

  const start = invoiceId ? (active?.lines.data?.[0].period.start || 0) * 1000 : (result?.subscription?.latestInvoice.lines.data[0].period.end || 0) * 1000
  const end = invoiceId ? (active?.lines.data?.[0].period.end || 0) * 1000 : moment((result?.subscription?.latestInvoice?.period_end || 0) * 1000).add({ month: 1 }).valueOf()
  const cycleDate = `${DateTime.fromJSDate(new Date(start)).toFormat('DD t')} - ${DateTime.fromJSDate(new Date(end)).toFormat('DD t')}`

  const taskUsage = active?.usage_records?.total_usage || totalUsage || 0
  const scenariosUsage = active?.scenarios_count || scenariosCount
  const activePlan = active?.lines.data?.[0]?.pricingPlan || plan
  const surplusTask = taskUsage - (activePlan?.monthlyTask || 0)

  const [onExport, setOnExport] = useState(false)
  const [param, setParam] = useState<any>()

  const { logJobs, fetchingLogJobs } = useLogJobsByCreatedAt(onExport && param ? param : undefined)

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      render: (_,__, idx) => idx + 1
    },
    {
      title: 'Job ID',
      dataIndex: 'id',
      render: (id) => <a href={`${IMPLIKASI_URL}/app/scenario/all/history/job/${id}`}>{id}</a>
    },
    { title: 'Apps',
      render: (_, record) => {
        return <ScenarioDetailRow scenarioId={record.scenarioId} type="listApplications" />
      }
    },
    { title: 'Scenario Name', render: (_, record) => {
      return <ScenarioDetailRow scenarioId={record.scenarioId} type="name" />
    } },
    { title: 'Executed at', dataIndex: 'createdAt',
      render: (lastExecuted) => lastExecuted ? DateTime.fromJSDate(new Date(lastExecuted)).toFormat('DD t') : '' },
    { title: 'Task', dataIndex: 'runningTasks' },
    { title: 'Status', render: (_, record) => {
      if (record.error){
        return <p>Failed</p>
      }
      if (record.status) {
        return <p>{record.status}</p>
      }
    }
    },
  ]

  useEffect(() => {
    setOnExport(false)
    setParam({
      start, end
    })
  }, [start, end])

  const ref = useRef(null)

  const onClickExport = () => {
    if (onExport && logJobs) {
      handlePrint?.()
    }
    setOnExport(true)
  }

  const handlePrint = useReactToPrint({
    content: () => ref.current
  })

  useEffect(() => {
    let cancelEffect = false
    if (onExport && logJobs && !cancelEffect) {
      handlePrint?.()
    }

    return () => {
      cancelEffect = true
    }
  }, [logJobs])

  return (
    <>
      <StyledButton onClick={onClickExport} loading={fetchingLogJobs} type="primary">Export Report</StyledButton>
      <div style={{ display: 'none' }}>
        <div ref={ref} style={{ padding: 24 }}>
          <Row justify="space-between" align="middle">
            <OtomatisLogo width="148px" height="32px" />
            <Col>
              If you find something is not right contact <a>developer@otomatis.com</a>
            </Col>
          </Row>
          <Divider />
          <div style={{ textAlign: 'center' }}>
            <h4>Otomatis Usage Report</h4>
          </div>
          <StyledSubtitle>User Information</StyledSubtitle>
          <Divider style={{ margin: '10px 0'  }} />
          <Row gutter={16}>
            <Col span={6}>Name</Col>
            <Col>:</Col>
            <Col>{loggedInUser?.name}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>Email</Col>
            <Col>:</Col>
            <Col>{loggedInUser?.email}</Col>
          </Row>
          <br />
          <StyledSubtitle>Subscription Plan</StyledSubtitle>
          <Divider style={{ margin: '10px 0'  }} />
          <Row gutter={16}>
            <Col span={6}>Price Plan</Col>
            <Col>:</Col>
            <Col>{activePlan?.name}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>Task Limit</Col>
            <Col>:</Col>
            <Col>{activePlan?.monthlyTask}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>Scenario Limit</Col>
            <Col>:</Col>
            <Col>{activePlan?.maxScenario === 0 ? 'Unlimited' : activePlan?.maxScenario}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>Cycle Date</Col>
            <Col>:</Col>
            <Col>{cycleDate}</Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>Usage report for invoice</Col>
            <Col>:</Col>
            <Col>{active?.number}</Col>
          </Row>
          <br />
          <StyledSubtitle>Summary</StyledSubtitle>
          <Divider style={{ margin: '10px 0'  }} />
          <br />
          <StyledTable
            rowKey="no"
            bordered
            summary={() =>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  {`Total Billing on ${cycleDate}`}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} align="right">
                  <StyledColumnNumber>
                    {currencyFormatterIDR((activePlan?.price || 0) + (activePlan?.pricePerTask || 0) * ((taskUsage || 0) - (activePlan?.monthlyTask || 0)))}
                  </StyledColumnNumber>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            }
            pagination={false}
            columns={[
              { title: 'No', dataIndex: 'no', render: (no) => <StyledColumnNumber>{no}</StyledColumnNumber> },
              { title: 'Description', dataIndex: 'description' },
              { title: 'Usage', dataIndex: 'usage', render: (usage) => <StyledColumnNumber>{numberFormatting(usage)}</StyledColumnNumber> },
              { title: 'Limit', dataIndex: 'limit', render: (limit) => <StyledColumnNumber style={{ textAlign: limit === 0 ? 'left' : 'right' }}>{limit === 0 ? 'Unlimited' : numberFormatting(limit)}</StyledColumnNumber> },
              { title: 'Charged Price', dataIndex: 'price', render: (price) => <StyledColumnNumber>{currencyFormatterIDR(price)}</StyledColumnNumber> },
            ]}
            dataSource={[
              {
                no: 1,
                description: `${activePlan?.name} plan`,
                usage: '-',
                limit: '-',
                price: activePlan?.price
              },
              {
                no: 2,
                description: 'Task Usage',
                usage: taskUsage,
                limit: activePlan?.monthlyTask,
                price: surplusTask > 0 ? (activePlan?.pricePerTask || 0) * surplusTask : 0
              },
              {
                no: 3,
                description: 'Scenario Usage',
                usage: scenariosUsage,
                limit: activePlan?.maxScenario,
                price: 0
              }
            ]}
          />
          <br />
          <StyledSubtitle>Job Executed</StyledSubtitle>
          <Divider style={{ margin: '10px 0'  }} />
          <br />
          <StyledTable
            bordered
            rowKey="id"
            pagination={false}
            dataSource={logJobs}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}

export default ReportPdfComponent

const StyledSubtitle = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`
const StyledTable = styled(Table)`
  .ant-table-summary {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    background: #F0F0F0;
  }
  .ant-table-thead th {
    background: #F0F0F0;
  }
`

const StyledColumnNumber = styled.p`
  font-family: monospace;
  text-align: right;
`