import { Input, InputProps } from 'antd'
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'

const StyledSearchInput: (setSearchQuery: Dispatch<SetStateAction<string>>) => FC<InputProps> = (setSearchQuery) => ({ ...props }) => <Input {...props} onChange={e => setSearchQuery(e.target.value)} />

type UseSearchInput = {
  StyledSearchInput: React.FC<InputProps>,
  searchQuery: string
}

const useSearchInput: () => UseSearchInput = () => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  return {
    StyledSearchInput: useMemo(() => StyledSearchInput(setSearchQuery), [setSearchQuery]),
    searchQuery
  }
}

export default useSearchInput