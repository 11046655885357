import { FormInstance, FormProps } from 'antd'
import { FieldSchema } from 'db'
import { debounce } from 'debounce'
import { FC, useEffect, useState } from 'react'
import useGetParamSchema from '../../../../hooks/application/main/useGetParamSchema'
// import useHitRequestAll from '../../../../hooks/application/main/useHitRequestAll'
import { convertFormValueToParams, convertParamsToFormValue } from '../../../../util/formatter'
import { StyledAlert } from '../../../components/StyledComponents'
import CustomForm from '../../components/form/CustomForm'
import FormItemMapping from './FormItemMapping'

interface Props {
  isDisabledAll?: boolean,
  isEnableAuthentication?: boolean,
  formAntProps?: FormProps,
  setOnRefetchOptions?: (isRefetch?: boolean) => void,
  counterRefetch?: number,
  form?: FormInstance<any>,
  initialParamSchema?: FieldSchema[],
  bundleId?: number,
  bundles?: any,
  triggerId?: number,
  actionId?: number,
  stateId?: number,
  applicationId?: number,
  enableRichInput?: boolean
}

const FormParamSchema: FC<Props> = ({ isDisabledAll, isEnableAuthentication, enableRichInput, form, formAntProps, counterRefetch, initialParamSchema, children, ...props }) => {
  const isNoDynamic = initialParamSchema?.filter(field => field.paramSchemaFunc || field.getValueFunc && field.allowEditByUser).length === 0
  const { paramSchema, getParamSchema } = useGetParamSchema({ ...props, initialParamSchema }, !isNoDynamic && (isEnableAuthentication && (props.bundleId || props.bundles) || !isEnableAuthentication))
  const [valuesForm, setValuesForm] = useState<any>()
  const { bundleId, bundles, applicationId, actionId, triggerId } = props
  const [savedParamSchema, setSavedParamSchema] = useState<FieldSchema[]>()
  // const { optionsAllHitRequest, getAllOptionsHitRequest, fetchingOptionsAllHitRequest } = useHitRequestAll({ initialParamSchema, applicationId, bundleId, bundles })


  useEffect(() => {
    if (paramSchema) {
      setSavedParamSchema(paramSchema)
    }
  }, [paramSchema])

  // useEffect(() => {
  //   if (setOnRefetchOptions) {
  //     setOnRefetchOptions(fetchingOptionsAllHitRequest)
  //   }
  // }, [fetchingOptionsAllHitRequest])

  // useEffect(() => {
  //   if (!isEnableAuthentication || isEnableAuthentication && (bundleId || bundles)){
  //     getAllOptionsHitRequest(form?.getFieldsValue())
  //   }
  // }, [valuesForm, bundles, bundleId, initialParamSchema, paramSchema])

  // useEffect(() => {
  //   if (counterRefetch !== 0) {
  //     if (!isEnableAuthentication || isEnableAuthentication && (bundleId || bundles)){
  //       getAllOptionsHitRequest(form?.getFieldsValue(), undefined, true)
  //     }
  //   }
  // }, [counterRefetch])

  useEffect(() => {
    if (valuesForm && (!isEnableAuthentication || isEnableAuthentication && (bundleId || bundles))){
      getParamSchema(form?.getFieldsValue())
    }
  }, [bundles, bundleId, counterRefetch, valuesForm])

  const onChangeValues = (_: any, allValues: any) => {
    debounceValueForm(allValues)
  }

  const debounceValueForm = debounce((allValues: any) => {
    setValuesForm(allValues)
  }, 1000)

  useEffect(() => {
    if (savedParamSchema && initialParamSchema && form) {
      form.setFieldsValue(convertParamsToFormValue(convertFormValueToParams(form.getFieldsValue()), paramSchema, true))
    }
  }, [savedParamSchema])

  return (
    form ?
      <CustomForm form={form} onValuesChange={onChangeValues} {...formAntProps}>
        {
          !isNoDynamic && !actionId && !triggerId ?
            <>
              <StyledAlert type="info" message="Have dynamic fields, can't see dynamic field preview"></StyledAlert>
            </>
            : null
        }
        {
          (savedParamSchema || initialParamSchema)?.map(param => {

            return (
              <FormItemMapping
                form={form}
                param={param}
                applicationId={applicationId}
                bundles={bundles}
                enableRichInput={enableRichInput}
                isDisabledAll={isDisabledAll}
                valuesForm={valuesForm}
                key={param.key}
              />
            )
          })}
        {children}
      </CustomForm> : null
  )
}

export default FormParamSchema