import React, { FC } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import ScenarioAllIndexPage from './all'
import ScenarioAllAccountsPage from './all/accounts'
import ScenarioAllHistoryPage from './all/history'

const ListScenarioPage: FC = () => {
  const { path } = useRouteMatch()

  return (
    <ListScenarioContainer>
      <Switch>
        <Route exact path={`${path}`} component={ScenarioAllIndexPage} />
        <Route path={`${path}/accounts`} component={ScenarioAllAccountsPage} />
        <Route path={`${path}/history`} component={ScenarioAllHistoryPage} />
        <Route path={`${path}/:folderId`} component={ScenarioAllIndexPage} />
      </Switch>
    </ListScenarioContainer>
  )
}

export default ListScenarioPage

const ListScenarioContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: max-content auto;
  overflow-y: hidden;

  @media (max-width: 768px) {
    height: unset;
    overflow-y: auto;
  }
`

