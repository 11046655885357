import React, { FC, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Account from './Account'
import Apps from './Apps'
import CreateApp from './Apps/Create'
import ViewApp from './Apps/View'
import Authorize from './Authorize'
import ConnectedClients from './ConnectedClients'
import Docs from './Docs'
import EditProfile from './EditProfile'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import Logout from './Logout'
import ResetPassword from './ResetPassword'
import Signup from './Signup'
import GithubAuth from './ThirdPartyLogin/GithubAuth'
import GoogleAuth from './ThirdPartyLogin/GoogleAuth'
import TwoFactorAuthSetup from './TwoFactorAuth/Setup'
import TwoFactorAuthVerify from './TwoFactorAuth/Verify'
import UserManagement from './UserManagement'
import Verification from './Verification'

const Auth: FC = () => {
  const { path } = useRouteMatch()
  // console.log(path)

  useEffect(() => {
    // console.log('\r\n\r\n\r\n  ___     _                  _   _               _        _   ___ \r\n |_ _|_ _| |_ ___ _ _ ___ __| |_(_)_ _  __ _    | |_ _  _| |_|__ \\\r\n  | || \' \\  _/ -_) \'_/ -_|_-<  _| | \' \\/ _` |_  | \' \\ || | \' \\ /_/\r\n |___|_||_\\__\\___|_| \\___/__/\\__|_|_||_\\__, ( ) |_||_\\_,_|_||_(_) \r\n                                       |___/|/                    \r\n  _____           _          __ _                              _   \r\n |_   _| _ _  _  | |_ ___   / _(_)__ _ _  _ _ _ ___   ___ _  _| |_ \r\n   | || \'_| || | |  _/ _ \\ |  _| / _` | || | \'_/ -_) / _ \\ || |  _|\r\n   |_||_|  \\_, |  \\__\\___/ |_| |_\\__, |\\_,_|_| \\___| \\___/\\_,_|\\__|\r\n           |__/     _   _    _   |___/  ___                        \r\n  ___ ___ _ __  ___| |_| |_ (_)_ _  __ |__ \\                       \r\n (_-</ _ \\ \'  \\/ -_)  _| \' \\| | \' \\/ _` |/_/                       \r\n /__/\\___/_|_|_\\___|\\__|_||_|_|_||_\\__, (_)                        \r\n                                   |___/                           \r\n     _         _                    _ _        _             \r\n  _ | |_  _ __| |_   ___ _ __  __ _(_) |  __ _| |_           \r\n | || | || (_-<  _| / -_) \'  \\/ _` | | | / _` |  _|          \r\n  \\__/ \\_,_/__/\\__| \\___|_|_|_\\__,_|_|_| \\__,_|\\__|          \r\n     _          ____  _          _                         _ \r\n  __| |_____ __/ __ \\| |__  __ _| |_  __ _ ___ __ _   __ _(_)\r\n / _` / -_) V / / _` | \'_ \\/ _` | \' \\/ _` (_-</ _` |_/ _` | |\r\n \\__,_\\___|\\_/\\ \\__,_|_.__/\\__,_|_||_\\__,_/__/\\__,_(_)__,_|_|\r\n               \\____/                                        \r\n\r\n\r\n')
  }, [])

  return (
    <>
      <Switch>
        {/* if accessing /auth check if user already logged in */}
        {/* <Route
          path="/"
          exact
          render={() => {
            if (Cookies.get('authorization')) {
              history.replace('/app')
              return null
            } else {
              history.replace('/auth/login')
              return null
            }
          }}
        /> */}
        <Route
          path={`${path}/login`}
          exact
          component={Login}
          // render={() => {

          // }}
        />
        <Route path={`${path}/account`} exact component={Account} />
        <Route path={`${path}/authorize`} exact component={Authorize} />
        <Route path={`${path}/forgotPassword`} exact component={ForgotPassword} />
        <Route path={`${path}/resetPassword`} exact component={ResetPassword} />
        <Route path={`${path}/signup`} exact component={Signup} />
        <Route path={`${path}/verification`} exact component={Verification} />
        <Route path={`${path}/edit`} exact component={EditProfile} />
        <Route path={`${path}/logout`} exact component={Logout} />
        <Route path={`${path}/2fa/setup`} exact component={TwoFactorAuthSetup} />
        <Route path={`${path}/2fa/verify`} exact component={TwoFactorAuthVerify} />
        <Route path={`${path}/google/callback`} exact component={GoogleAuth} />
        <Route path={`${path}/github/callback`} exact component={GithubAuth} />

        <Route path={`${path}/connectedClients`} exact component={ConnectedClients} />
        <Route path={`${path}/apps`} exact component={Apps} />
        <Route path={`${path}/docs`} exact component={Docs} />
        <Route path={`${path}/userManagement`} exact component={UserManagement} />
        <Route path={`${path}/apps/new`} exact component={CreateApp} />
        <Route path={`${path}/apps/:id`} exact component={(props: any) => ViewApp(props)} />
      </Switch>
    </>
  )
}

export default Auth
