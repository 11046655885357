import { FormInstance, Input, Select } from 'antd'
import { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import { StyledButton, StyledFooterForm } from 'src/pages/components/StyledComponents'
import CustomForm from 'src/pages/developer/components/form/CustomForm'
import FormItem from 'src/pages/developer/components/form/FormItem'
import MonacoEditor from 'src/pages/developer/components/form/MonacoEditor'

interface Props {
  onSubmit: (data: any) => void,
  submitting: boolean,
  form: FormInstance<any> | undefined
}

const GlobalVariableForm: FC<Props> = ({ form, onSubmit }) => {
  const history = useHistory()
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()

  return (
    <CustomForm form={form} onFinish={onSubmit}>
      <FormItem name="name" label="Variable name" rules={[{ required: true, message: 'Variable name is required' }]}>
        <Input />
      </FormItem>
      <FormItem name="type" label="Type" rules={[{ required: true, message: 'Type is required' }]}>
        <Select
          showSearch
          placeholder="Type"
          allowClear
          options={[{ value: 'string', label: 'String' }, { value: 'number', label: 'Number' }, { value: 'function', label: 'Function' }]}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
        </Select>
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => {
          const type = getFieldValue('type')
          return (
            <FormItem name={['globalVariableValue', type === 'function' ? 'function' : 'constant']} label="Value" rules={[{ required: true, message: 'Value is required' }]}>
              {
                type === 'function' ?
                  <MonacoEditor defaultLanguage="javascript" /> :
                  <Input type={type !== 'string' ? type : 'text'} />
              }
            </FormItem>
          )
        }}
      </FormItem>
      <FormItem>
        <StyledFooterForm>
          <StyledButton onClick={() => history.push(`/developer/application/${applicationId}/${versionName}/main/globalVariable`)} htmlType="button" ghost type="primary">Cancel</StyledButton>
          <StyledButton htmlType="submit" type="primary">Save</StyledButton>
        </StyledFooterForm>
      </FormItem>
    </CustomForm>
  )
}

export default GlobalVariableForm