import { CheckCircleFilled, CloseCircleFilled, SafetyCertificateOutlined, StopOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { Card, Form, Input, Row, Switch, Table, Tag, Typography } from 'antd'
import Axios from 'axios'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CLIENT_ID_WEB, OTORISASI_API_URL } from '../../../helpers/Constant'
import { compareString } from '../../../util/functions'
import Notification from '../../../util/Notification'
import PageHeader from '../../components/PageHeader'
import { StyledButton } from '../../components/StyledComponents'

const AdminDashboardPage: React.FC = () => {
  const PAGE_SIZE = 15
  const history = useHistory()
  const [search, setSearch] = useState<string>()
  const [users, setUsers] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>()
  const [total, setTotal] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<[number[], any[]]>()
  const [tableState, setTableState] = useState<[any, any, any]>()
  const [application, setApplication] = useState<any>()

  useEffect(() => {
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/clients`, { params: { clientId: CLIENT_ID_WEB }, withCredentials: true })
      .then(({ data }) => {
        Axios.get(`${OTORISASI_API_URL}/api/auth/v1/application/${data.clients[0].applicationId}`, { withCredentials: true })
          .then(({ data }) => {
            setApplication(data.application)
          })
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    Axios.get(`${OTORISASI_API_URL}/api/auth/v1/user/me`, { withCredentials: true })
      .then(({ data }) => {
        if (!data.user.isAdmin) {
          return history.replace('/auth/account')
        }
        Axios.get(`${OTORISASI_API_URL}/api/auth/v1/users`, { withCredentials: true, params: {
          skip: 0,
          take: PAGE_SIZE,
          withDeleted: true
        } })
          .then(({ data }) => {
            setUsers(data.users)
            setTotal(data.length)
            setLoading(false)
          })
          .catch(() => Notification.error({ message: 'Something error, please reload this page' }))
      })
      .catch(() => history.replace('/auth/login'))
  }, [history])

  const changeHandler = async (pagination: any, filters: any, sorter: any, _?: any, query?: string) => {
    setUsers([])
    setLoading(true)
    const additionalFilter = filters ? (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
      if (field === 'deletedAt') {
        if (filters[field].includes('true') && filters[field].includes('false')) {
          return res
        }
        if (field === 'deletedAt') {
          return `${res},authUser.${field}.is=${!filters[field].includes('true') ? 'not' : ''} null`
        }
      }
      return `${res},authUser.${field}.in=(${(filters[field] as string[])?.map(val => `'${val}'`).join(',')})`
    }, 'true') : 'true'

    query = query === undefined ? search : query || ''
    try {
      // const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/users`, {
      //   and: query ? `(${additionalFilter},or=(authUser.name.ilike=${query},authUser.email.like=${query},authUser.username.ilike=${query}))` : `(${additionalFilter})`
      // }, { withCredentials: true, params: {
      //   skip: ((pagination?.current || 1) - 1) * PAGE_SIZE,
      //   take: PAGE_SIZE,
      //   withDeleted: true,
      //   ...sorter?.field ?  { orderBy: `"${sorter.field}":${sorter.order === 'ascend' ? 'asc' : 'desc'}` } : {}
      // } })
      const { data } = await Axios({
        url: `${OTORISASI_API_URL}/api/auth/v1/users`,
        withCredentials: true,
        method: 'get',
        params: {
          and: query ? `(${additionalFilter},or=(authUser.name.ilike=${query},authUser.email.like=${query},authUser.username.ilike=${query}))` : `(${additionalFilter})`,
          skip: ((pagination?.current || 1) - 1) * PAGE_SIZE,
          take: PAGE_SIZE,
          withDeleted: true,
          ...sorter?.field ?  { orderBy: `"${sorter.field}":${sorter.order === 'ascend' ? 'asc' : 'desc'}` } : {}
        }
      })
      setTableState([pagination, filters, sorter])
      setUsers(data.users)
      setTotal(data.length)
    } catch (error) {
      Notification.error({ message: error?.response?.data?.error || 'Something error, please reload your browser' })
    }
    setLoading(false)
  }

  const searchUsers = async (val: string) => {
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2], null, val || '')
    setSearch(val)
  }

  const disable = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const i of selectedIds[0]) {
      try {
        await Axios.delete(`${OTORISASI_API_URL}/api/auth/v1/user/${i}/archive`, { withCredentials: true })
      } catch (error) {
        console.error(error)
        Notification.error({ message: `Error on update user ID ${i}, please see the console` })
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return Notification.success({ message: 'Users disabled (maybe) successfully' })
  }

  const enable = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const i of selectedIds[0]) {
      try {
        await Axios.patch(`${OTORISASI_API_URL}/api/auth/v1/user/${i}/restore`, {}, { withCredentials: true })
      } catch (error) {
        setLoading(false)
        return Notification.success({ message: 'Users disabled (maybe) successfully' })
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return Notification.success({ message: 'Users enabled (maybe) successfully' })
  }

  const verify = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const i of selectedIds[0]) {
      try {
        await Axios.patch(`${OTORISASI_API_URL}/api/auth/v1/user/${i}`, {
          user: {
            isVerified: true,
            emailVerificationToken: null
          }
        }, { withCredentials: true })
      } catch (error) {
        setLoading(false)
        return Notification.success({ message: 'Users disabled (maybe) successfully' })
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return Notification.success({ message: 'Users verified (maybe) successfully' })
  }

  const switchUser = async () => {
    if (!selectedIds?.[0]?.length) {
      return
    }
    setLoading(true)
    for (const user of selectedIds[1]) {
      try {
        await Axios.patch(`${OTORISASI_API_URL}/api/auth/v1/user/${user.id}`, {
          user: {
            isAdmin: !user.isAdmin
          }
        }, { withCredentials: true })
      } catch (error) {
        setLoading(false)
        return Notification.success({ message: 'Users disabled (maybe) successfully' })
      }
    }
    setSelectedIds([[], []])
    await changeHandler(tableState?.[0], tableState?.[1], tableState?.[2])
    setLoading(false)
    return Notification.success({ message:'Users switched (maybe) successfully' })
  }

  const updateApplication = async (update: any) => {
    const { data } = await Axios.patch(`${OTORISASI_API_URL}/api/auth/v1/application/${application.id}`, { application: update }, { withCredentials: true })
    if (data) {
      Notification.success({
        message: `Success, Sign up ${data.application.enabledSignup ? 'enabled' : 'disabled'}`
      })
      setApplication(data.application)
    }
  }

  return (
    <>
      <PageHeader
        title="Admin"
      />
      <StyledCard>
        <h4>Settings</h4>
        <h5 style={{ color: 'rgba(0, 0, 0, 0.65)' }}>User Signup</h5>
        <Switch checked={application?.enabledSignup} onChange={() => updateApplication({ enabledSignup: !application?.enabledSignup })} /> <span style={{ color: '#8C8C8C' }}>{application?.enabledSignup ? 'Enabled' : 'Disabled'}</span>
      </StyledCard>
      <br />
      <StyledCard>
        <Form>
          <Form.Item name="search" wrapperCol={{ md: { span: 8, offset: 16 } }}>
            <Input.Search
              placeholder="Search by name, email, or username..." onSearch={searchUsers} allowClear />
          </Form.Item>
        </Form>
        <Row justify="space-between" align="middle">
          <h4 style={{ marginBottom: 8 }}>
            Users
            <TotalUserContainer>{' '}({total} users)</TotalUserContainer>
          </h4>
          <div>
            <StyledButton className="primary" style={{ marginLeft: 8, marginBottom: 8 }} size="small" icon={<StopOutlined />} type="dashed" disabled={!selectedIds?.[0]?.length || !!selectedIds?.[1]?.find(user => user.deletedAt)} onClick={disable}>Disable</StyledButton>
            <StyledButton className="primary" style={{ marginLeft: 8, marginBottom: 8 }} size="small" icon={<CheckCircleFilled />} type="dashed" disabled={!selectedIds?.[0]?.length || !!selectedIds?.[1]?.find(user => !user.deletedAt)} onClick={enable}>Enable</StyledButton>
            <StyledButton className="primary" style={{ marginLeft: 8, marginBottom: 8 }} size="small" icon={<SafetyCertificateOutlined />} type="dashed" disabled={!selectedIds?.[0]?.length || !!selectedIds?.[1]?.find(user => user.isVerified)} onClick={verify}>Verify</StyledButton>
            <StyledButton className="primary" style={{ marginLeft: 8, marginBottom: 8 }} size="small" icon={<UserSwitchOutlined />} type="dashed" disabled={!selectedIds?.[0]?.length} onClick={switchUser}>Switch Role</StyledButton>
          </div>
        </Row>
        <br />
        <Table
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              fixed: 'left',
              width: 70,
              sorter: (a, b) => a.id - b.id
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              width: 240,
              sorter: (a, b) => compareString(a.name, b.name)
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
              width: 240,
              sorter: (a, b) => compareString(a.email, b.email)
            },
            {
              title: 'Username',
              dataIndex: 'username',
              key: 'username',
              width: 240,
              sorter: (a, b) => compareString(a.username, b.username)
            },
            {
              title: 'Role',
              dataIndex: 'isAdmin',
              key: 'isAdmin',
              width: 130,
              render: isAdmin => <>{isAdmin ? <Tag color="blue">Admin</Tag> : <Tag color="lime">User</Tag>}</>,
              filterMultiple: false,
              filters: [
                { text: 'Admin', value: true },
                { text: 'Not Admin', value: false },
              ]
            },
            {
              title: 'Verified',
              dataIndex: 'isVerified',
              key: 'isVerified',
              width: 100,
              render: isVerified => <>{isVerified ? <Typography.Text type="success"><CheckCircleFilled /></Typography.Text> : <Typography.Text type="danger"><CloseCircleFilled /></Typography.Text>}</>,
              filterMultiple: false,
              filters: [
                { text: 'Verified', value: true },
                { text: 'Not verified', value: false },
              ]
            },
            {
              title: 'Last Login',
              dataIndex: 'lastLogin',
              key: 'lastLogin',
              width: 240,
              sorter: (a, b) => (a.lastLogin ? new Date(a.lastLogin).getTime() : Infinity) - (b.lastLogin ? new Date(b.lastLogin).getTime() : Infinity),
              render: (_, record) => DateTime.fromJSDate(new Date(record.lastLogin)).toFormat('DD T')
            },
            {
              title: 'Enable 2FA',
              dataIndex: 'isEnabled2fa',
              key: 'isEnabled2fa',
              width: 140,
              render: isEnabled2fa => <>{isEnabled2fa ? <Typography.Text type="success"><CheckCircleFilled /></Typography.Text> : <Typography.Text type="danger"><CloseCircleFilled /></Typography.Text>}</>,
              filterMultiple: false,
              filters: [
                { text: 'Enable', value: true },
                { text: 'Disable', value: false },
              ],
            },
            {
              title: 'Registered At',
              dataIndex: 'createdAt',
              key: 'createdAt',
              width: 240,
              sorter: (a, b) => (a.createdAt ? new Date(a.createdAt).getTime() : Infinity) - (b.createdAt ? new Date(b.createdAt).getTime() : Infinity),
              render: (_, record) => DateTime.fromJSDate(new Date(record.createdAt)).toFormat('DD T')
            },
            {
              title: 'Is Active',
              dataIndex: 'deletedAt',
              key: 'deletedAt',
              width: 120,
              render: deletedAt => <>{!deletedAt ? <Typography.Text type="success"><CheckCircleFilled /></Typography.Text> : <Typography.Text type="danger"><CloseCircleFilled /></Typography.Text>}</>,
              filterMultiple: false,
              filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
              ]
            },
          ]}
          dataSource={users?.map(user => ({ ...user, key: user.id }))}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedIds?.[0] || [],
            onChange: (selectedRowKeys, data) => setSelectedIds([selectedRowKeys as number[], data])
          }}
          onChange={changeHandler}
          sticky
          scroll={{ x: 1300 }}
          pagination={{
            pageSize: 15,
            total: total
          }}
          loading={loading}
        />
      </StyledCard>
    </>
  )
}

export default AdminDashboardPage

const StyledCard = styled(Card)`
  margin: 0 24px;
`
const TotalUserContainer = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8C8C8C;
`