import { DeleteOutlined, EditOutlined, EllipsisOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Dropdown, Menu, MenuItemProps, Space, Tooltip } from 'antd'
import { LogJob } from 'db'
import { FC } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useDeleteAJob from '../../../../../hooks/job/useDeleteAJob'
import useRetryLogJob from '../../../../../hooks/job/useRetryLogJob'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import { blue, danger } from '../../../../../util/colors'
import CustomModalMethod from '../../../../../util/CustomModalMethod'
import AnchorWrapper from '../../../../AnchorWrapper'
interface Props {
  afterAction?: () => void,
  record: LogJob,
  id: number
}
const ListJobAction: FC<Props> = ({ record, id, afterAction }) => {
  const { retryJob, retryingLogJob } = useRetryLogJob()
  // const { url } = useRouteMatch()
  const { push } = useHistory()
  const { deleteAJob, deletingAJob } = useDeleteAJob()

  const onClickArchive: MenuItemProps['onClick'] = (e) => {
    e.domEvent.stopPropagation()
    CustomModalMethod.dangerConfirm({
      title: 'Are you sure you want to archive this Log Job?',
      content: 'You will not be able to access this Log Job again. This Action can not be undone.',
      onOk: async () => {
        return new Promise((resolve, reject) => {
          deleteAJob(id).then(() => {
            return resolve(true)
          }).catch(() => reject('error')).finally(() => afterAction?.())
        }).catch(() => console.log('Oops errors!'))
      },
    })
  }

  const onClickEditScenario: MenuItemProps['onClick'] = (e) => {
    e.domEvent.stopPropagation()
    push(`/app/scenario/${record.scenarioId}/edit`)
  }

  const onClickRetryJobFromBeginning: MenuItemProps['onClick'] = (e) => {
    e.domEvent.stopPropagation()
    CustomModalMethod.dangerConfirm({
      title: 'Are you sure to restart job from the beginning?',
      content: 'All result data from the successful task will be lost. Otomatis will restart the Job from trigger step.',
      onOk: async () => {
        return new Promise((resolve, reject) => {
          retryJob(id, 'fromBeginning').then(() => {
            return resolve(true)
          }).catch(() => reject('error')).finally(() => afterAction?.())
        }).catch(() => console.log('Oops errors!'))
      },
    })
  }

  const onClickRetry: ButtonProps['onClick'] = (e) => {
    e.stopPropagation()
    retryJob(id, 'errorState').finally(() => afterAction?.())
  }

  return (
    <Space>
      {/* <a onClick={() => push(`${url}/${id}`)} >See Detail</a> */}
      <Tooltip title={record.scenario?.deletedAt ? 'You can\'t retry failed job from an archived scenario' :
        record.status !== 'error' && record.status !== 'partialError' && record.status !== 'pending' ? 'Only error and pending job that can be retry' : null
      }>
        <StyledButton
          type="primary" size="small"
          disabled={!!record.scenario?.deletedAt || record.status !== 'error' && record.status !== 'partialError' && record.status !== 'pending'}
          icon={<RedoOutlined />} onClick={onClickRetry}>
          Retry Job
        </StyledButton>
      </Tooltip>
      <Dropdown
        overlay={
          <Menu>
            {/* <Menu.Item key={1} disabled={!!record.scenario?.deletedAt || record.status !== 'error' && record.status !== 'partialError'} icon={<RedoOutlined />} onClick={onClickRetry}>
              <Tooltip title={record.scenario?.deletedAt ? 'You can\'t retry failed job from an archived scenario' :
                record.status !== 'error' && record.status !== 'partialError' && record.status !== 'pending' ? 'Only error and pending job that can be retry' : null
              }>
                Retry Job
              </Tooltip>
            </Menu.Item> */}
            <Menu.Item key={0} icon={<EditOutlined style={{ color: blue[4] }} />} onClick={onClickEditScenario} disabled={!!record.scenario?.deletedAt}>
              <AnchorWrapper
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ color: blue[4] }}
                href={`/app/scenario/${record.scenarioId}/edit`}
              >
                Edit Scenario
              </AnchorWrapper>
            </Menu.Item>
            <Menu.Item key={2} icon={<RedoOutlined />} onClick={onClickRetryJobFromBeginning}
              disabled={!!record.scenario?.deletedAt || record.status !== 'error' && record.status !== 'partialError' && record.status !== 'pending'}
            >
              Restart job from the beginning
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key={1} icon={<DeleteOutlined />} onClick={onClickArchive} disabled={!!record.deletedAt} style={{ color: danger }}>
              Archive
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
        placement='bottomRight'
      >
        <ScenarioMoreActionsButton onClick={(e) => e.stopPropagation()} loading={deletingAJob || retryingLogJob} type='text' icon={
          <EllipsisOutlined style={{ color: 'rgba(0, 0, 0, .45)', verticalAlign: 'middle', textAlign: 'center' }} />
        } />
      </Dropdown>
    </Space>
  )
}

export default ListJobAction

const ScenarioMoreActionsButton = styled(Button)`
  border: 0;

  &:hover {
    background-color: rgba(0, 0, 0, .06);
  }
`