import { Input, Select, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import CustomForm from '../../../../../components/form/CustomForm'
import FormItem from '../../../../../components/form/FormItem'
import { StyledButton, StyledCard, StyledFooterForm, StyledTag } from '../../../../../../components/StyledComponents'
import { PropsContentStep } from '../data'
import { useSharedValueAction } from '../SharedValueActionContextProvider'
import { useApplicationVersionContext } from '../../../../contexts/ApplicationVersionContextProvider'
import useCreateOrUpdateAction from '../../../../../../../hooks/application/action/useCreateOrUpdateAction'

const SettingsForm: FC<PropsContentStep> = ({ isActive, onContinue }) => {
  const history = useHistory()
  const [values, setValues] = useSharedValueAction()
  const [form] = useForm()
  const [version] = useApplicationVersionContext()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { createOrUpdateAction, creatingAction } = useCreateOrUpdateAction()


  useEffect(() => {
    let cancelEffect = false
    if (!cancelEffect && values) {
      form.setFieldsValue({
        ...values,
        isDraft: values.isDraft ? 'hidden' : 'visible'
      })
    } else if (!values) {
      form.resetFields()
      form.setFieldsValue({
        isDraft: 'visible'
      })
    }
    return () => {
      cancelEffect = true
    }
  }, [values?.name, values?.description])

  const onCancel = () => {
    history.goBack()
  }

  const onFinish = async (allValues: any) => {
    const payload = { ...values, ...allValues }
    const newAction = await createOrUpdateAction(applicationId,
      { ...payload, isDraft: allValues.isDraft !== 'visible',
        type: values?.type || 'http',
        versionId: values?.versionId || version?.id })

    if (newAction) {
      setValues(prevState => ({ ...prevState, ...payload, ...newAction }))
    }
    onContinue()
  }

  useEffect(() => {
    if (!isActive) {
      setValues(prevState => ({ ...prevState, ...form.getFieldsValue(), isDraft: form.getFieldValue('isDraft') !== 'visible' }))
    }
  }, [isActive])
  return (
    <StyledSettingsForm className={isActive ? 'show' : undefined}>
      <CustomForm onFinish={onFinish} form={form}>
        <FormItem name='name' rules={[{ required: true }]} label="Name" description="Enter a user friendly name for this action that describes what makes it run. Shown to users inside Implikasi."
          extra={<Space><p>Example:</p><StyledTag>New Ticket</StyledTag><StyledTag>Tiket Baru</StyledTag></Space>}
        >
          <Input />
        </FormItem>
        <FormItem name='description' rules={[{ required: true }]} label="Description" description="Describe clearly the purpose of this action in a complete sentence."
          extra={<Space><p>Example:</p><StyledTag>Actions when a new support ticket is created.</StyledTag><StyledTag>Dipicu ketika ada tiket suport baru.</StyledTag></Space>}
        >
          <Input />
        </FormItem>
        <FormItem rules={[{ required: true }]} name='category' label="Action Category" description="Choose category of this action. Make it easier for Makers to understand what this action do in general ">
          <Select options={[{ label: 'Search - This action get, search, find, or lookup for data', value: 'search' }, { label: 'Write - This action creating, updating, or deleting data', value: 'write' }]}  />
        </FormItem>
        <FormItem name='isDraft' label="Visibility Options" description="Show this action to user">
          <Select options={[{ label: 'Visible for User', value: 'visible' }, { label: 'Hidden from User', value: 'hidden' }]}  />
        </FormItem>
        {/* <FormItem name='direction' label="Direction" description="Add additional detail to set up this action, if needed, especially if users need to customize anything in your app.">
          <Input.TextArea />
        </FormItem>*/}
        <StyledFooterForm>
          <StyledButton htmlType="button" onClick={onCancel}>Cancel</StyledButton>
          <StyledButton htmlType="submit" loading={creatingAction} type="primary">{'Save & Continue'}</StyledButton>
        </StyledFooterForm>
      </CustomForm>
    </StyledSettingsForm>
  )
}

export default SettingsForm

const StyledSettingsForm = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;

  ${StyledTag} {
    color: rgba(0, 0, 0, 0.65);
  }
`