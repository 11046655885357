import React, { useState, useContext, createContext, FC, ReactNode, Dispatch, SetStateAction, useEffect } from 'react'
import { DataSuggestion } from '../../../../../developer/formItems/DataPickerPopper'

type ContextValueType = {
  value?: string,
  dataSuggestion?: Record<string, DataSuggestion>
}

const ValueArrayContext = createContext<ContextValueType | null>(null)
const ValueArraySetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode,
  dataValue?: ContextValueType
}

const ValueArrayContextProvider: FC<Props> = ({ children, dataValue }) => {
  const [valueArray, setValueArray] = useState<ContextValueType | null>(dataValue || null)

  useEffect(() => {
    setValueArray(dataValue || null)
  }, [dataValue])

  return (
    <ValueArrayContext.Provider value={valueArray}>
      <ValueArraySetterContext.Provider value={setValueArray}>
        {children}
      </ValueArraySetterContext.Provider>
    </ValueArrayContext.Provider>
  )
}


export function useValueArray(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(ValueArrayContext)
  const setterContext = useContext(ValueArraySetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useValueArray must be used on child component of ValueArrayContextProvider component'
  }

  return [valueContext, setterContext]
}

export default ValueArrayContextProvider