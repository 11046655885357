import { useState } from 'react'
import { Implikasi } from '../../service/Implikasi'
import { Error } from '../../types'
import Notification from '../../util/Notification'

type Result = {
  testBundle: (applicationId: number, bundleId: number, versionId?: number) => Promise<any> | undefined,
  result?: any,
  testingBundle: boolean,
  errorTestBundle?: Error
}

export default function useBundleTest(): Result {
  const [data, setData] = useState()
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState<Error>()

  const post = async (applicationId: number, bundleId: number, versionId?: number) => {
    setIsValidating(true)
    if (applicationId && bundleId && versionId) {
      const authenticationId = await Implikasi.get(`/application/${applicationId}/authentications${versionId ? `?versionId=${versionId}` : ''}`)
        .then(({ data }) => data.authentications[0]?.id)
        .catch(({ response }) => {
          Notification.error({
            message: 'Something\'s Wrong',
            description: response?.data?.error
          })
          setError(response?.data?.error)
        })
      if (authenticationId) {
        const test = await Implikasi.post(`/application/${applicationId}/authentication/${authenticationId}/test?bundleId=${bundleId}`)
          .then(({ data }) => {
            if (data.success) {
              Notification.success({
                message: 'Success',
                description: JSON.stringify(data.data, null, 2)
              })
            } else {
              Notification.error({
                message: 'Failed',
                description: JSON.stringify(data.error, null, 2)
              })
            }
            return data
          })
          .catch(({ response }) => {
            Notification.error({
              message: 'Something\'s Wrong',
              description: response?.data?.error
            })
            setError(response?.data?.error)
          })

        setIsValidating(false)
        setData(test)
        return test
      } else {
        Notification.error({
          message: 'Something\'s Wrong',
          description: 'Authentication detail not found'
        })
      }
      setIsValidating(false)
    }
    setIsValidating(false)
  }

  return {
    result: data,
    testingBundle: isValidating,
    errorTestBundle: error,
    testBundle: post
  }
}