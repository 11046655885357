import { ApplicationReview } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  createdApplicationReview?: ApplicationReview,
  creatingApplicationReview: boolean,
  errorCreateApplicationReview?: Error,
  createApplicationReview: (applicationId: number, payload: Partial<ApplicationReview>) => void
}

export default function useCreateApplicationReview(): Result {
  const { data, error, isValidating, call } = useApi<{ applicationReview: ApplicationReview }>()

  return {
    createdApplicationReview: data?.applicationReview,
    creatingApplicationReview: isValidating,
    errorCreateApplicationReview: error,
    createApplicationReview: (applicationId, payload) => {
      call({
        url: `/application/${applicationId}/applicationReview`,
        method: 'post',
        data: {
          applicationReview: payload
        }
      })
    }
  }
}