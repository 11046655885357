import { UploadOutlined } from '@ant-design/icons'
import { Upload, UploadProps } from 'antd'
import { Dispatch, FC, memo, SetStateAction, useState } from 'react'
import { StyledButton } from 'src/pages/components/StyledComponents'
import * as XLSX from 'xlsx'

interface Props {
  setDataHeader: Dispatch<SetStateAction<string[] | undefined>>,
  setParsedData: Dispatch<SetStateAction<{ [key: string]: any }[] | undefined>>
}

const Step1Upload: FC<Props> = ({ setDataHeader, setParsedData }) => {
  const [fileList, setFileList] = useState<any[]>()

  const propsUpload: UploadProps = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: file => {
      onChangeFile(file)
      setFileList([file])
      return false
    },
    fileList,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  }
  const onChangeFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      try {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetNameList = workbook.SheetNames
        const ws: { [key: string]: string }[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]])
        setParsedData(ws)
        setDataHeader(Object.keys(ws[0]))
      } catch (e) {
        console.log('error', e)
      }
    }
    reader.readAsArrayBuffer(file)
  }
  return (
    <>
      <Upload {...propsUpload}>
        <StyledButton icon={<UploadOutlined />}>Select File</StyledButton>
      </Upload>
    </>
  )
}

export default memo(Step1Upload)