import { Card, Col, Input, Row, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { Application } from 'db'
import React from 'react'
import CustomForm from '../../components/form/CustomForm'
import FormItem from '../../components/form/FormItem'
import UploadImageField from '../../components/form/UploadImageField'
import { StyledButton, StyledFooterForm } from '../../../components/StyledComponents'
import { useRouteMatch } from 'react-router'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'

interface Props {
  title?: string,
  onCancel: () => void,
  version?: string,
  form: FormInstance<Partial<Application>>,
  application?: Application,
  submitting?: boolean,
  onFinish: () => void
}

const ApplicationForm: React.FC<Props> = ({ form, onFinish, submitting, application, onCancel, title, version }) => {
  const { url } = useRouteMatch()
  const [user] = useLoggedInUser()

  return (
    <>
      <Row justify="center">
        <Col span={20} md={16} lg={16}>
          <h3 style={{ margin: '20px 0' }}>{title || 'Create Application'}</h3>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20} md={16} lg={16}>
          <Card>
            <CustomForm layout="vertical" form={form} onFinish={onFinish} style={{ width: '100%' }}>
              <FormItem description="The name of your app as it should display in Otomatis." label="Name" name="name" rules={[{ required: true, message: 'Name cannot be blank' }]}>
                <Input />
              </FormItem>
              <FormItem label="Description" name="description" description='A sentence describing your app in 140 characters or less, e.g. "Trello is a team collaboration tool to organize tasks and keep projects on track.'>
                <Input.TextArea />
              </FormItem>
              <FormItem label="Homepage URL" name="homepageUrl" description="The homepage URL of your App">
                <Input />
              </FormItem>
              <FormItem label="Request URL" name="requestUrl" description="The Request URL of your App">
                <Input />
              </FormItem>
              <FormItem label="Error Reporter Email" name="errorReporterEmail" description="The Error Reporter Email of your App">
                <Input type="email" />
              </FormItem>
              <FormItem name="hasTrigger" label="Has Trigger?" valuePropName="checked" hidden>
                <Switch />
              </FormItem>
              <FormItem name="hasAction" label="Has Action?" valuePropName="checked" hidden>
                <Switch />
              </FormItem>
              <UploadImageField form={form} name="logoUrl" label="Logo" existed={application?.logoUrl} description="Upload a square, 256x256px or larger transparent PNG image that does not include the app name." />
              <FormItem name="activeVersion" label="Active Version" rules={[{ required: true, message: 'Version cannot be blank' }]}>
                <Input disabled={url.includes('edit') && !!version} />
              </FormItem>
              <FormItem name="public" label="Is Public ?" valuePropName="checked" hidden={!application?.publishedAt}>
                <Switch />
              </FormItem>
              {
                user?.isAdmin ?
                  <>
                    <FormItem name="isNativeApp" label="Is Native App ?" valuePropName="checked">
                      <Switch />
                    </FormItem>
                    <FormItem name="isPremiumApp" label="Is Premium App ?" valuePropName="checked">
                      <Switch />
                    </FormItem>
                  </>
                  :
                  null
              }
              <br />
              <StyledFooterForm>
                <StyledButton htmlType="button" disabled={submitting} onClick={onCancel}>
                  Cancel
                </StyledButton>
                <StyledButton type="primary" htmlType="submit" loading={submitting}>
                  Save
                </StyledButton>
              </StyledFooterForm>
            </CustomForm>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ApplicationForm