import { LogRequestExecutor } from 'db'
import { Error } from '../../../../types'
import useFetch from '../../../useFetch'

type LogHttpData = LogRequestExecutor
type Result = {
  logHttpData?: LogHttpData,
  fetchingLogHttpDatas: boolean,
  errorFetchLogHttpDatas?: Error
}

export default function useLogHttpData(logJobId?: number | string, logTaskId?: number | string): Result {
  const url = logJobId && logTaskId !== undefined ?
    logTaskId ? `/logJob/${logJobId}/logTask/${logTaskId}/logHttpData` :
      `/logJob/${logJobId}/logHttpData`
    : null
  const { data, error, isValidating } = useFetch<{ logHttpData: LogHttpData }>(url, {}, { revalidateOnFocus: false })

  return {
    logHttpData: data?.logHttpData,
    fetchingLogHttpDatas: isValidating,
    errorFetchLogHttpDatas: error
  }
}