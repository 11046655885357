import { Error } from '../../../types'
import useFetch from '../../useFetch'


type Result = {
  url?: string,
  fetchingWebhookUrl: boolean,
  errorFetchWebhookUrl?: Error
}

export default function useWebhookUrlTrigger(applicationId?: number | string, triggerId?: number): Result {
  const url = applicationId && triggerId ? `/application/${applicationId}/trigger/${triggerId}/webhookUrl` : null
  const { data, error, isValidating } = useFetch<{ url: string }>(
    url,
    {},
    { revalidateOnFocus: false }
  )

  return {
    url: data?.url,
    fetchingWebhookUrl: isValidating,
    errorFetchWebhookUrl: error
  }
}