import { Layout, Popconfirm, Space, Table } from 'antd'
import { Authentication } from 'db'
import { FC, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { useAddGlobalVariableToDataSuggestions, useDataSuggestion } from '../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useAllAuthentication from '../../../../../../hooks/application/authentication/useAllAuthentication'
import useArchiveAuthentication from '../../../../../../hooks/application/authentication/useArchiveAuthentication'
import useAllAuthenticationSchema from '../../../../../../hooks/authenticationSchema/useAllAuthenticationSchema'
import Notification from '../../../../../../util/Notification'
import PageHeader from '../../../../../components/PageHeader'
import { StyledCard } from '../../../../../components/StyledComponents'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { useSharedValues } from '../components/SharedValuesContextProvider'

const Front: FC = () => {
  const { applicationId  } = useParams<{ applicationId: string, subPage?: string }>()
  const [version] = useApplicationVersionContext()
  const { authentications, revalidateAuthentications, fetchingAllAuthentications } = useAllAuthentication(applicationId, version?.id)
  const { authenticationSchemas } = useAllAuthenticationSchema()
  const [_, setValues] = useSharedValues()
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { archiveAuthentication, archivingAuthentication, archivedAuthentication } = useArchiveAuthentication()
  const [, setDataSuggestion] = useDataSuggestion()
  useAddGlobalVariableToDataSuggestions(applicationId, version?.id)

  const edit = (record: Authentication) => {
    const authenticationSchema = authenticationSchemas?.find(as => as.id === record.authenticationSchemaId)
    setValues(prevState => ({
      ...prevState, ...record, authenticationSchema: authenticationSchema }))
    const fieldSchema = record.fieldSchema
    if (fieldSchema && fieldSchema.length > 0) {
      const flatTestData= fieldSchema.reduce((acc, field) => ({
        ...acc,
        [`authData.${field.key}`]: {
          sample: field.default,
          value: `authData.${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      const testData = fieldSchema.reduce((acc, field) => ({
        ...acc,
        [`${field.key}`]: {
          sample: field.default,
          value: `${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      setDataSuggestion(prevState => ({
        ...prevState,
        authData: {
          flatTestData,
          order: 0,
          testData: testData,
          name: 'Auth Data',
          application: undefined
        }
      }))
    }
    // push(`${url}/${encodeURIComponent(authenticationSchema?.name || '')}`)
    push(`${url}/${record.id}`)
  }

  const onDelete = (record: Authentication) => {
    archiveAuthentication(record.applicationId, record.id, version?.id)
  }

  useEffect(() => {
    if (archivedAuthentication) {
      Notification.success({
        message: 'authentication scheme deleted succcesfully'
      })
      revalidateAuthentications()
    }
  }, [archivedAuthentication])

  return (
    <StyledFront>
      <PageHeader
        title="Authentication"
      />
      <StyledCard>
        <h5>SCHEME</h5>
        <Table
          onRow={(record) => ({
            style: { cursor: 'pointer' },
            onClick: () => edit(record)
          })}
          rowKey="id"
          loading={fetchingAllAuthentications || archivingAuthentication}
          dataSource={authentications}
          columns={[
            {
              title: 'Auth Scheme', dataIndex: 'authenticationSchemaId',
              render: (authenticationSchemaId) => authenticationSchemas?.find(authenticationSchema => authenticationSchema.id === authenticationSchemaId)?.name
            },
            {
              title: 'Action', dataIndex: 'id', render: (_: any, record: Authentication) => {
                return (
                  <Space>
                    <a onClick={() => edit(record)}>
                    Edit
                    </a>
                    <Popconfirm title="Sure to cancel?" onVisibleChange={(_, e) => e?.stopPropagation()} onConfirm={(e) => {
                      e?.preventDefault()
                      e?.stopPropagation()
                      onDelete(record)
                    }}>
                      <a onClick={(e) => e.stopPropagation()} >Delete</a>
                    </Popconfirm>
                  </Space>
                )
              }
            }
          ]}
        />
      </StyledCard>
    </StyledFront>
  )
}

export default Front

const StyledFront = styled(Layout.Content)`
  ${StyledCard} {
    margin: 0 24px 16px;
  }

`