import { SettingOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Col, Descriptions, Empty, Layout, List, message, Popconfirm, Row, Tabs } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import Axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OTORISASI_API_URL } from '../../helpers/Constant'

const ConnectedClients: React.FC = () => {
  const history = useHistory()
  const [integrations, setIntegrations] = useState<any[]>()
  const [clients, setClients] = useState<any[]>()
  const [selectedClient, setSelectedClient] = useState<any>()
  const [activeKey, setActiveKey] = useState<string>()

  const _findClients = useCallback(async (integrations: any[]) => {
    if (!integrations?.length) return
    try {
      const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/userClients`, {
        search: `(client.integration is null or client.integration in (${integrations?.map(ig => ig.id).join(',')}))`
      }, { withCredentials: true, params: { orderBy: '"client"."name":asc' } })
      return setClients(data.userClients)
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error')
    }
  }, [])

  useEffect(() => {
    if (integrations === undefined) {
      Axios.get(`${OTORISASI_API_URL}/api/auth/v1/integrations`, { withCredentials: true, params: { skip: 1, orderBy: 'id:asc' } })
        .then(({ data }) => {
          if (data?.integrations?.length) {
            setIntegrations([...data.integrations, { id: -1, name: 'Others' }])
            return _findClients(data.integrations)
          }
        })
        .catch(error => message.error(error?.response?.data?.error || 'Something error'))
    }
  }, [history, integrations, _findClients])

  const revoke = async ({ user, client }: { user: any, client: any }) => {
    setSelectedClient(undefined)
    try {
      await Axios.delete(`${OTORISASI_API_URL}/api/auth/v1/userClient/u${user.id}/c${client.id}`, { withCredentials: true })
      const candidateKey = client.integration || -1
      setActiveKey(clients?.filter(uc => uc.client.id !== client.id)?.find(
        uc => uc.client.integration === candidateKey || candidateKey === -1 && !uc.client.integration
      ) ? candidateKey.toString() : undefined)
      _findClients(integrations as any[])
      return message.success(`${client.name} revoked`)
    } catch (error) {
      return message.error(error?.response?.body?.error || 'Something error')
    }
  }

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }}>
          <Card title={`Connected Apps (${clients?.length || 0})`}>
            { clients?.length ?
              <Tabs activeKey={activeKey || undefined} onChange={e => setActiveKey(e)}>
                { integrations?.filter(integration => clients?.find(
                  uc => uc.client.integration === integration.id || integration.id === -1 && !uc.client.integration
                ))?.map(integration =>
                  <Tabs.TabPane tab={integration.name} key={integration.id}>
                    <List
                      size="large"
                      dataSource={clients?.filter(
                        uc => uc.client.integration === integration.id || integration.id === -1 && !uc.client.integration
                      )}
                      renderItem={item =>
                        <List.Item key={item.client.id}>
                          <List.Item.Meta
                            title={<Button style={{ padding: 0, height: 'auto' }} type="link" onClick={() => setSelectedClient(item)}>{item.client.name}</Button>}
                            description={item.client.clientId}
                            avatar={item.client.iconUrl ? <Avatar size="large" shape="square" src={item.client.iconUrl} /> : <Avatar size="large" shape="square" icon={<SettingOutlined />} />}
                          />
                        </List.Item>
                      }
                    />
                  </Tabs.TabPane>
                ) }
              </Tabs>
              : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
          </Card>
        </Col>
      </Row>
      <Modal
        title={selectedClient?.client?.name}
        onOk={() => setSelectedClient(undefined)}
        onCancel={() => setSelectedClient(undefined)}
        destroyOnClose={true}
        visible={!!selectedClient}
        footer={[
          <Button key="back" onClick={() => setSelectedClient(undefined)}>
            Cancel
          </Button>,
          <Popconfirm title={`Are you sure want to revoke ${selectedClient?.client.name}?`} onConfirm={() => revoke(selectedClient)}>
            <Button danger type="primary">Revoke</Button>
          </Popconfirm>
        ]}
      >
        <Descriptions column={1}>
          <Descriptions.Item label="Client ID">{selectedClient?.client.clientId}</Descriptions.Item>
          <Descriptions.Item label="Desciption">{selectedClient?.client.description || '-'}</Descriptions.Item>
          <Descriptions.Item label="Authorized at">{selectedClient?.createdAt}</Descriptions.Item>
        </Descriptions>
      </Modal>
    </Layout.Content>
  )
}

export default ConnectedClients