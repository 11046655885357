import { Card, Col, Input, Layout, Row, Select, Space, Table, Tag } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { ColumnType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { Application, ApplicationReview } from 'db'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import AnchorWrapper from '../../../../components/AnchorWrapper'
import useNeedReviewApplications from '../../../../hooks/application/main/useNeedReviewApplication'
import { TableState } from '../../../../types'
import { blue } from '../../../../util/colors'
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../../util/Constant'
import { getInitialName } from '../../../../util/formatter'
import Header from '../../../components/Header'
import { baseApplicationRoute } from '../routes'
import ApplicationReviewFormModal from './components/ApplicationReviewFormModal'
import AuthSchemaField from './components/AuthSchemaField'
import TotalEvent from './components/TotalEvent'

const ReviewApp: React.FC = () => {
  const history = useHistory()
  const [tableState, setTableState] = useState<TableState<Application>>({
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE
    },
  })
  const { applications, fetchingApplications, revalidateApplications, applicationsLength } = useNeedReviewApplications(tableState)
  const [activeApplication, setActiveApplication] = useState<Application>()
  const [status, setStatus] = useState<'accept' | 'reject'>()
  const [filteredApplications, setFilteredApplications] = useState<Application[]>()

  const handleChange = (pagination: TablePaginationConfig, filter: Record<string, FilterValue | null>, sorter: SorterResult<Application> | SorterResult<Application>[], extra: TableCurrentDataSource<Application>) => {
    setTableState(prevState => ({ ...prevState, pagination, filter: { ...prevState.filter, ...filter }, sorter, extra }))
  }

  useEffect(() => {
    setFilteredApplications(applications)
  }, [applications])

  const goToDashboard = async (applicationId: number, versionName: string | number) => {
    return history.push(`${baseApplicationRoute}/${applicationId}/${versionName}/main`)
  }

  const columns: ColumnType<Application>[] = [
    { title: 'ID', dataIndex: 'id', render: (id) => <AnchorWrapper style={{ color: blue[4] }} href={`${baseApplicationRoute}/${id}/main`}>{id}</AnchorWrapper> },
    { title: '', dataIndex: 'logoUrl', render: (logoUrl, record) => logoUrl ? <img src={logoUrl} width="30px" /> : <Avatar icon={getInitialName(record.name)} /> },
    { title: 'App', dataIndex: 'name' },
    { title: 'Auth', dataIndex: 'id', render: (_, record) => <AuthSchemaField applicationId={record.id} versionId={record.versions?.[0]?.id} /> },
    { title: 'Trigger', dataIndex: 'id', render: (_, record) => <TotalEvent type ='trigger' applicationId={record.id} versionId={record.versions?.[0]?.id} /> },
    { title: 'Action', dataIndex: 'id', render: (_, record) => <TotalEvent type ='action' applicationId={record.id} versionId={record.versions?.[0]?.id} /> },
    { title: 'Status', dataIndex: 'id', render: (_, record) => record.publishedAt ?
      <Tag color="green">Accepted</Tag> :
      record.submittedAt ? 'Waiting' : <Tag color="red">Rejected</Tag> },
    { title: 'Action', dataIndex: 'id', render: (id, record) => <Space>
      <AnchorWrapper href={`${baseApplicationRoute}/${id}/main`} onClick={(e) => onView(e, id)} style={{ color: blue[4] }} >View Detail</AnchorWrapper>
      {
        record.publishedAt ? null :
          <>
            <a onClick={(e) => onAccept(e, record)} style={{ color: '#96C443' }} >Accept</a>
            <a onClick={(e) => onReject(e, record)} style={{ color: '#BC4A40' }} >Reject</a>
          </>}
    </Space> },
  ]

  const onView = (e: MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`${baseApplicationRoute}/${id}/main`)
  }

  const onAccept = (e: MouseEvent<HTMLElement>, data: Application) => {
    e.preventDefault()
    e.stopPropagation()
    setActiveApplication(data)
    setStatus('accept')
  }

  const onReject = (e: MouseEvent<HTMLElement> | undefined, data: Application) => {
    e?.preventDefault()
    e?.stopPropagation()
    setActiveApplication(data)
    setStatus('reject')
  }

  const afterSubmit = (_: ApplicationReview) => {
    revalidateApplications()
  }

  const onCancelModal = () => {
    setActiveApplication(undefined)
  }

  const onChangeStatusFilter = (value: any) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, status: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const { Search } = Input

  const onSearch = (value: string) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  return (
    <StyledReviewApp>
      <Header />
      <Row style={{ padding: 16 }}>
        <Col span={24} lg={{ span: 16, offset: 4 }} style={{ marginTop: '20px' }}>
          <Row align="middle" justify="space-between">
            <Col flex={0}>
              <Space direction="vertical">
                <h3>Review App</h3>
                <p>Publish or Reject App Submission.</p>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={{ span: 16, offset: 4 }} style={{ marginTop: '20px' }}>
          <StyledCard>
            <Row justify="space-between" align="middle">
              <Col>
                <h4>App Submission</h4>
              </Col>
              <Col>
                <Space>
                  <Search placeholder="search application..." onSearch={onSearch} allowClear  />
                  Filter by Status:
                  <Select style={{ minWidth: 130 }} onChange={onChangeStatusFilter} allowClear placeholder="All" options={[{ value: 'waiting', label: 'Waiting' }, { value: 'accepted', label: 'Accepted' }, { label: 'Rejected', value: 'rejected' }]} />
                </Space>
              </Col>
            </Row>
            <br />
            <Table
              onChange={handleChange}
              pagination={{
                current: tableState.pagination?.current,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                showSizeChanger: true,
                total: applicationsLength,
                pageSize: tableState.pagination?.pageSize
              }}
              onRow={(record) => ({
                onClick: () => goToDashboard(record.id, record.versions?.[0]?.name || '')
              })}
              loading={fetchingApplications}
              rowKey="id"
              scroll={{ x: true }}
              dataSource={filteredApplications}
              columns={columns}
            />
          </StyledCard>
        </Col>
      </Row>
      <ApplicationReviewFormModal type={status || 'accept'} application={activeApplication} onCancel={onCancelModal} afterSubmit={afterSubmit} />
    </StyledReviewApp>
  )
}

export default ReviewApp

const StyledReviewApp = styled(Layout.Content)`
  .ant-list {
    background: #FFF;
  }
`
const StyledCard = styled(Card)`
  .ant-table-row {
    cursor: pointer;
  }
  h3 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
  background: #FFFFFF;
  /* Blue/1 */
  border: 1px solid #DCE6FA;
  box-sizing: border-box;
  border-radius: 4px;
`