import { Authentication } from 'db'
import { Error } from '../../../types'
import useFetch from '../../useFetch'

type Result = {
  authentication?: Authentication,
  fetchingAuthentication: boolean,
  errorFetchAuthentications?: Error
}

export default function useAuthentication(applicationId?: number | string, authenticationId?: number | string, versionId?: number | null): Result {
  const url = authenticationId && applicationId ? `/application/${applicationId}/authentication/${authenticationId}${versionId ? `?versionId=${versionId}` : ''}` : null
  const { data, error, isValidating } = useFetch<{ authentication: Authentication }>(url, {}, { revalidateOnFocus: false })

  return {
    authentication: data?.authentication,
    fetchingAuthentication: isValidating,
    errorFetchAuthentications: error
  }
}