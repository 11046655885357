import { MenuOutlined } from '@ant-design/icons'
import { Popconfirm, Space, Spin, Table, Tag, Typography } from 'antd'
import arrayMove from 'array-move'
import { FieldSchema } from 'db'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { SortableContainer as sortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import NestedTableMapSchema from '../../../../../../../../components/pages/developer/formItems/FormListFieldSchema/NestedTableMapSchema'
import useCreateOrUpdateAction from '../../../../../../../../hooks/application/action/useCreateOrUpdateAction'
import { useRevalidateFetch } from '../../../../../../../../hooks/useFetch'
import { StyledButton } from '../../../../../../../components/StyledComponents'
import { useSharedValueAction } from '../../SharedValueActionContextProvider'

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableContainer = sortableContainer((props: any)=> <tbody {...props} />)

interface Props {
  setActiveEditFieldForm: Dispatch<SetStateAction<(FieldSchema & {idx?: number}) | undefined>>
}


const TableFormItems: FC<Props> = ({ setActiveEditFieldForm }) => {
  const { revalidateAction } = useRevalidateFetch()
  const [values] = useSharedValueAction()
  const { createOrUpdateAction, creatingAction } = useCreateOrUpdateAction()
  const [activeDelete, setActiveDelete] = useState<number>()
  const dataSource = values?.paramSchema?.map((field, idx) => ({ ...field, idx: idx })) || []

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => creatingAction ? null: <DragHandle />,
    },
    { title: 'Label', dataIndex: 'label', className: 'drag-visible' },
    { title: 'Key', dataIndex: 'key' },
    { title: 'Type', dataIndex: 'type' },
    { title: 'Tag', dataIndex: 'required', render: (required: boolean) => required ? <Tag color="red">Required</Tag> : '' },
    { title: 'Action', dataIndex: 'key', render: (_: any, record: FieldSchema & { idx?: number }) => {
      return (
        <Space>
          <Typography.Link onClick={() => edit(record)}>
              Edit
          </Typography.Link>
          <Popconfirm title="Sure to cancel?" onConfirm={() => onDelete(record)}>
            <StyledButton type="link" loading={activeDelete === record.idx && creatingAction} >Delete</StyledButton>
          </Popconfirm>
        </Space>
      )
    }, },
  ]

  const edit = (record: FieldSchema & { key: React.Key, idx?: number }) => {
    setActiveEditFieldForm(record)
  }

  const onDelete = async (record: Partial<FieldSchema> & { key: React.Key, idx?: number }) => {
    const temp = values?.paramSchema?.filter((_, idx) => record.idx !== idx)
    setActiveDelete(record.idx)
    const newAction = await createOrUpdateAction(values?.applicationId || 0,
      { ...values, paramSchema: temp, isDraft: values?.isDraft === undefined ? true : values.isDraft,
        versionId: values?.versionId })

    if (newAction) {
      revalidateAction(values?.applicationId, values?.id, values?.versionId)
    }
    setActiveDelete(undefined)
  }

  const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const temp: any[] = []
      const newData = arrayMove(temp.concat(dataSource), oldIndex, newIndex).filter(el => !!el).map(field => ({ ...field, idx: undefined }))
      const newAction = await createOrUpdateAction(values?.applicationId || 0,
        { ...values, paramSchema: newData, isDraft: values?.isDraft === undefined ? true : values.isDraft,
          versionId: values?.versionId })

      if (newAction) {
        revalidateAction(values?.applicationId, values?.id, values?.versionId)
      }
    }
  }

  const onChangeMapSchemaOrder = async (newField: FieldSchema) => {
    const newFields: any[] = values?.paramSchema?.map((field) => {
      if (field.key === newField.key) {
        return {
          ...newField,
          idx: undefined
        }
      }
      return field
    }) || []
    const newAction = await createOrUpdateAction(values?.applicationId || 0,
      { ...values, paramSchema: newFields, isDraft: values?.isDraft === undefined ? true : values.isDraft,
        versionId: values?.versionId })

    if (newAction) {
      revalidateAction(values?.applicationId, values?.id, values?.versionId)
    }
  }

  const DraggableContainer = (props: any) =>
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />


  const DraggableBodyRow = (props: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.idx === props['data-row-key'])
    return <SortableItem index={index} {...props} />
  }

  return (
    <Spin spinning={creatingAction && !activeDelete}>
      <Table
        scroll={{ x: true }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="idx"
        expandable={{
          rowExpandable: (record) => !!record.mapSchema,
          expandedRowRender: (record) => {
            const mapSchema = record.mapSchema
            if (mapSchema) {
              return <NestedTableMapSchema loading={creatingAction || false} mapSchema={mapSchema}
                onChangeMapSchema={(newMapSchema) => onChangeMapSchemaOrder({
                  ...record,
                  mapSchema: newMapSchema
                })} />
            }
            return null
          }
        }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </Spin>
  )

}

export default TableFormItems