import { State } from 'db'
import { atomFamily, selectorFamily } from 'recoil'

export const selectedStateAtomFamily = atomFamily<State | undefined, { scenarioId: string }>({
  default: undefined,
  key: 'selectedStateAtomFamily'
})

type DetailState = Pick<State, 'id' | 'realTestData' | 'testData' | 'action' | 'trigger' |
'actionId' | 'triggerId' | 'params' | 'bundleId' | 'order' | 'conditionGroupOrder' | 'childrenIds' |
'parentId' | 'conditions' | 'name' | 'stateConditionId'
>

export const detailStateAtomFamily = atomFamily<DetailState | undefined, { stateId?: number }>({
  default: undefined,
  key: 'detailStateAtomFamily'
})

export const statusStateSelectorFamily = selectorFamily<{ isValid: boolean, title: string }, { stateId?: number }>({
  key: 'statusStateSelectorFamily',
  get: ({ stateId }) => ({ get }) => {
    let isValid = false
    let title = ''
    const state = get(detailStateAtomFamily({ stateId }))
    const isTrigger = !!state?.trigger
    const needAuthentication = !!(state?.trigger || state?.action)?.authenticationId
    const isTested = !!state?.realTestData
    const testSucceed = state?.realTestData?.success
    // console.log(state?.realTestData, stateId)
    if (testSucceed) {
      isValid = true
      title = ''
    } else {
      isValid = false
      if (isTested) {
        title = `Complete all the ${isTrigger ? 'trigger' : 'action'} setup`
      } else {
        title = `Test your ${isTrigger ? 'trigger' : 'action'} to make sure it is well set up`
        if (needAuthentication && !state?.bundleId) {
          title = 'Sign in or choose one the account to continue the setup'
        }
      }
    }
    return {
      isValid,
      title
    }
  }
})