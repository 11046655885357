import { LockOutlined, WarningOutlined } from '@ant-design/icons'
import { Card, Input, Switch, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { OTORISASI_API_URL } from '../../../../helpers/Constant'
import { encrypt } from '../../../../helpers/Crypto'
import useAuthUser from '../../../../hooks/user/useAuthUser'
import Notification from '../../../../util/Notification'
import PageHeader from '../../../components/PageHeader'
import { StyledAlert, StyledButton, StyledLabel } from '../../../components/StyledComponents'
import CustomForm from '../../../developer/components/form/CustomForm'
import FormItem from '../../../developer/components/form/FormItem'

const SecurityPage: FC = () => {
  const { authUser } = useAuthUser()
  const { path } = useRouteMatch()
  const [formPassword] = useForm()
  const history = useHistory()
  const [tfa, setTfa] = useState<boolean>()
  const [updatingPassword, setUpdatingPassword] = useState(false)
  const [showModalDeactivateTfa, setShowModalDeactivateTfa] = useState<boolean>()

  const setUp2FA = () => {
    history.push(`${path}/2fa/setup`)
  }

  useEffect(() => {
    setTfa(authUser?.isEnabled2fa)
  }, [authUser])

  const updatePassword = async (values: any) => {
    const { oldPassword, newPassword } = values
    try {
      setUpdatingPassword(true)
      await axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/changePassword`, {
        oldPassword: encrypt(oldPassword as string),
        newPassword: encrypt(newPassword as string)
      }, { withCredentials: true })
      formPassword.setFieldsValue({
        oldPassword: '',
        newPassword: '',
      })
      setUpdatingPassword(false)
      Notification.success({
        message: 'Password changed'
      })
      setTimeout(() => history.push('/auth/logout'), 1500)
    } catch (error) {
      setUpdatingPassword(false)
      Notification.error({
        message: error?.response?.data?.error || 'Something error, please try again'
      })
    }
  }

  const deactivate2fa = async (values: any) => {
    const { password } = values
    if (password) {
      try {
        await axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/deactivate2fa`, { password: encrypt(password as string) }, { withCredentials: true })
        Notification.success({ message: '2FA deactivated successfully' })
        setTfa(false)
      } catch (error) {
        Notification.error({ message: error?.response?.data?.error || 'Something error, please try again' })
      }
    }
    return setShowModalDeactivateTfa(false)
  }
  return (
    <>
      <PageHeader
        title="Security"
        extra={
          <StyledAlert message={
            <p style={{ color: '#8C8C8C' }}>Last login: {authUser?.lastLogin}</p>
          }
          showIcon type="info" />
        }
      />
      <StyledCard>
        <h4>Password</h4>
        <CustomForm form={formPassword} onFinish={updatePassword}>
          <FormItem name="oldPassword" label="Old Password" rules={[{ required: true, message: 'Old password is required' }]}>
            <Input.Password />
          </FormItem>
          <FormItem name="newPassword" label="New Password" rules={[{ required: true, message: 'New password is required' }]}>
            <Input.Password />
          </FormItem>
          <FormItem shouldUpdate>
            {
              ({ getFieldValue }) => {
                return <StyledButton loading={updatingPassword} type="primary" htmlType="submit" style={{ float: 'right' }} disabled={!getFieldValue('oldPassword') || !getFieldValue('newPassword')}>Save Update</StyledButton>
              }
            }
          </FormItem>
        </CustomForm>
      </StyledCard>
      <br />
      <StyledCard>
        <h4>2 Factor Authentication</h4>
        <p style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Protect your Otomatis account by requiring a second authentication method using Authentication App in addition to your password. Text message or security key is not supported at the moment.</p>
        <br />
        <StyledLabel className="no-status" style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Status</StyledLabel>
        <div style={{ paddingTop: 10 }}>
          <StyledSwitch checked={tfa} onChange={(checked) => checked ? setUp2FA() : setShowModalDeactivateTfa(true)} /> {tfa ? 'Active' : 'Inactive'}
        </div>
      </StyledCard>
      <Modal
        title="Deactivate 2FA"
        okText="Deactivate anyway"
        okType="danger"
        okButtonProps={{ htmlType: 'submit' }}
        onOk={deactivate2fa}
        visible={showModalDeactivateTfa}
        onCancel={() => setShowModalDeactivateTfa(false)}>
        <Typography.Paragraph><WarningOutlined /> This action strongly not recommended.</Typography.Paragraph>
        <CustomForm onFinish={deactivate2fa}>
          <FormItem
            name="password"
            rules={[{ required: true, message: 'Password cannot be blank' }]}>
            <Input.Password placeholder="Password" prefix={<LockOutlined />} />
          </FormItem>
        </CustomForm>
      </Modal>
    </>
  )
}

export default SecurityPage

const StyledCard = styled(Card)`
  margin: 0 24px;
`
const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background: #96C443;
  }
`