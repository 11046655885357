import { InputNumber, Radio, Switch, Tag } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useAddGlobalVariableToDataSuggestions, useUpdateDataSuggestions } from '../../../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import RichTextInput from '../../../../../../../../components/pages/developer/formItems/RichTextInput'
import { useLoggedInUser } from '../../../../../../../../contexts/LoggedInUserContextProvider'
import useAllAuthentication from '../../../../../../../../hooks/application/authentication/useAllAuthentication'
import useCreateOrUpdateTrigger from '../../../../../../../../hooks/application/trigger/useCreateOrUpdateTrigger'
import useAuthenticationSchema from '../../../../../../../../hooks/authenticationSchema/useAuthenticationSchema'
import { IMPLIKASI_URL } from '../../../../../../../../util/Constant'
import { StyledButton, StyledCard, StyledDescription, StyledFooterForm, StyledLabel, StyledTag } from '../../../../../../../components/StyledComponents'
import CustomForm from '../../../../../../components/form/CustomForm'
import FormItem from '../../../../../../components/form/FormItem'
import FormRequestItem from '../../../../../../components/form/FormRequestItem'
import MonacoEditor from '../../../../../../components/form/MonacoEditor'
import { useApplicationVersionContext } from '../../../../../contexts/ApplicationVersionContextProvider'
import { PropsContentStep } from '../../data'
import { useSharedValueTrigger } from '../../SharedValueTriggerContextProvider'

const Config: FC<PropsContentStep> = ({ isActive, onPrevious, onContinue }) => {
  const [form] = useForm()
  const [condition, setCondition] = useState<string>()
  const [webhookResponseFunc, setWebhookResponseFunc] = useState<string>()
  const [values, setValues] = useSharedValueTrigger()
  const { applicationId } = useParams<{ applicationId: string }>()
  const [version] = useApplicationVersionContext()
  const { authentications } = useAllAuthentication(applicationId, version?.id)
  const authenticationId = authentications ? authentications[0]?.id : null
  const { authenticationSchema } = useAuthenticationSchema(authentications ? authentications[0]?.authenticationSchemaId : undefined)
  const { createOrUpdateTrigger, creatingTrigger } = useCreateOrUpdateTrigger()
  const [valuesForm, setValuesForm] = useState<any>()
  const [httpRequest, setHttpRequest] = useState<any>()
  const [user] = useLoggedInUser()
  const authentication = authentications?.[0]

  useUpdateDataSuggestions(undefined, authentication, undefined, values?.paramSchema)
  useAddGlobalVariableToDataSuggestions(applicationId, version?.id)


  const onChangeType  = (e: any) => {
    setValues(prevState => ({ ...prevState, type: e.target.value }))
  }

  useEffect(() => {
    form.setFieldsValue({
      stateKeyMap: values?.stateKeyMap
    })
  }, [values?.stateKeyMap])

  useEffect(() => {
    setValues(prevState => ({ ...prevState, condition: condition }))
  }, [condition])

  useEffect(() => {
    setValues(prevState => ({ ...prevState, webhookResponseFunc }))
  }, [webhookResponseFunc])

  useEffect(() => {
    if (values?.type) {
      form.setFieldsValue({
        type: values.type
      })
    }
  }, [values?.type])

  useEffect(() => {
    if (authenticationId && (!values || !values?.id)) {
      setValues(prevState => ({ ...prevState, authenticationId }))
    }
  }, [values?.id, authenticationId])

  useEffect(() => {
    if (!values?.id) {
      form.resetFields()
    }
  }, [values?.id])

  useEffect(() => {
    form.setFieldsValue({
      isUseAuthentication: values?.authenticationId || !values?.id ? true : false
    })
  }, [values?.authenticationId])

  useEffect(() => {
    form.setFieldsValue({
      isAsc: values?.isAsc,
    })
  }, [values?.isAsc])

  useEffect(() => {
    form.setFieldsValue({
      isAggregator: values?.isAggregator,
    })
  }, [values?.isAggregator])

  useEffect(() => {
    form.setFieldsValue({
      isGlobalAggregator: values?.isGlobalAggregator,
    })
  }, [values?.isGlobalAggregator])

  useEffect(() => form.setFieldsValue({ isUsingPagination: values?.isUsingPagination }) , [values?.isUsingPagination])

  useEffect(() => {
    form.setFieldsValue({
      similarityThreshold: values?.similarityThreshold
    })
  }, [values?.similarityThreshold])

  useEffect(() => {
    form.setFieldsValue({
      idempotencyKeyMap: values?.idempotencyKeyMap
    })
  }, [values?.idempotencyKeyMap])

  useEffect(() => {
    if (!isActive) {
      if (httpRequest) {
        setValues(prevState => ({ ...prevState, idempotencyKeyMap: form.getFieldValue('idempotencyKeyMap'),
          similarityThreshold: valuesForm?.similarityThreshold, stateKeyMap: form.getFieldValue('stateKeyMap'), ...httpRequest }))
      } else {
        setValues(prevState => ({ ...prevState, idempotencyKeyMap: form.getFieldValue('idempotencyKeyMap'),
          similarityThreshold: valuesForm?.similarityThreshold, stateKeyMap: form.getFieldValue('stateKeyMap'), httpRequestId: null, httpRequest: null }))
      }
    }
  }, [isActive])

  const onChangeUseAuthentication = (e: CheckboxChangeEvent) => {
    setValues(prevState => ({ ...prevState, authenticationId: e.target.checked ? authenticationId : null }))
  }

  const onChangeIsAsc = (e: boolean) => {
    setValues(prevState => ({ ...prevState, isAsc: e }))
  }

  const onChangeIsAggregator = (e: boolean) => {
    setValues(prevState => ({ ...prevState, isAggregator: e, isGlobalAggregator: !e ? false : prevState?.isGlobalAggregator }))
  }

  const onChangeIsGlobalAggregator = (e: boolean) => {
    setValues(prevState => ({ ...prevState, isGlobalAggregator: e }))
  }

  const onChangeUsingPagination = (e: boolean) => {
    setValues(prevState => ({ ...prevState, isUsingPagination: e }))
  }

  const onSaveAndContinue = async () => {
    let payload = { ...values, idempotencyKeyMap: valuesForm?.idempotencyKeyMap, similarityThreshold: valuesForm?.similarityThreshold, stateKeyMap: form.getFieldValue('stateKeyMap') }
    if (httpRequest) {
      payload = { ...payload, ...httpRequest }
    } else {
      payload = { ...payload, httpRequestId: null, httpRequest: null }
    }

    const newTrigger = await createOrUpdateTrigger(applicationId, { ...payload, isDraft: false, versionId: values?.versionId || version?.id })

    if (newTrigger) {
      setValues(prevState => ({ ...prevState, ...payload, ...newTrigger }))
    }
    onContinue()
  }

  const onValuesChange = (_: any, allValues: any) => {
    setValuesForm(allValues)
  }

  const onChangeRequest = (id: string, requestValues: any) => {
    setHttpRequest((prevState: any) => {
      if (values?.type === 'polling' && id === 'httpRequest') {
        return { ...prevState,
          [id]: requestValues,
          dummyTriggerHttpRequest: null,
          subscribeHttpRequest: null,
          unsubscribeHttpRequest: null }
      }
      if (values?.type === 'hook' && id !== 'httpRequest') {
        return { ...prevState, [id]: requestValues, httpRequest: null }
      }
    })
  }

  return (
    <StyledConfigForm className={isActive ? 'show' : undefined}>
      <CustomForm form={form} onValuesChange={onValuesChange}>
        <FormItem name="type" label="Type" rules={[{ required: true }]} >
          <Radio.Group onChange={onChangeType}>
            <Radio value={'hook'}>REST Hook</Radio>
            <Radio value={'polling'}>Polling</Radio>
            <Radio value={'script'}>Script</Radio>
            {user?.isAdmin ? <Radio value={'schedule'}>Schedule</Radio> : null}
          </Radio.Group>
        </FormItem>
        <StyledLabel className="no-status">Authentication</StyledLabel>
        <div style={{ padding: 8, border: '1px solid #d9d9d9', borderRadius: 2, marginTop: 12 }}>
          <h5 style={{ margin: 0 }}>{authenticationSchema?.name || '-'}</h5>
        </div>
        <FormItem name='isUseAuthentication' valuePropName="checked" >
          <Checkbox onChange={onChangeUseAuthentication} >Use Authentication</Checkbox>
        </FormItem>
        <br />
        {values?.type === 'polling' ?
          <FormRequestItem
            onChange={onChangeRequest}
            valuesForm={valuesForm}
            key="httpRequest"
            existedValue={values?.httpRequest}
            isRequired={values?.type === 'polling'}
            form={form}
            id="httpRequest"
            label="Endpoint"
          />
          :
          null
        }

        {
          values?.type === 'hook' ?
            <>
              <FormRequestItem
                onChange={onChangeRequest}
                valuesForm={valuesForm}
                key="subscribeHttpRequest"
                existedValue={values?.subscribeHttpRequest}
                isRequired={false}
                form={form}
                id="subscribeHttpRequest"
                label="Subscribe"
              />
              <FormRequestItem
                onChange={onChangeRequest}
                valuesForm={valuesForm}
                key="unsubscribeHttpRequest"
                existedValue={values?.unsubscribeHttpRequest}
                isRequired={false}
                form={form}
                id="unsubscribeHttpRequest"
                label="Unsubscribe"
              />
              <FormRequestItem
                onChange={onChangeRequest}
                valuesForm={valuesForm}
                key="dummyTriggerHttpRequest"
                existedValue={values?.dummyTriggerHttpRequest}
                isRequired={false}
                form={form}
                id="dummyTriggerHttpRequest"
                label="Perform List"
              />
              <StyledLabel>Webhook Response Function</StyledLabel>
              <StyledDescription>Run the function when get webhook, <a target="_blank" href={`${IMPLIKASI_URL}/private/docs/docs/build-apps/trigger#api-configuration`}>click here to more info</a></StyledDescription>
              <MonacoEditor height="50px" noResize options={{ readOnly: true, lineNumbers: 'off', overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true, scrollBeyondLastLine: false, lineHeight: 15,
                scrollbar: {
                  vertical: 'hidden'
                },
                overviewRulerBorder: false, }} defaultLanguage="javascript" value={'\n// Example: ({data}) => data.result.filter((a) => a.ok) \n'} />
              <MonacoEditor defaultLanguage="javascript" value={values?.webhookResponseFunc} setValue={setWebhookResponseFunc} />
              <br />
            </> : null
        }
        {
          values?.type !== 'schedule' ?
            <FormItem name='similarityThreshold' label="Similarity Threshold" description="0 to 1 float number, Default: 0.82">
              <InputNumber step={0.01} min={0} max={1} />
            </FormItem> :
            null
        }
        <FormItem name='idempotencyKeyMap' label="Idempotency Key Map" description="Idempotency Key">
          {
            // eslint-disable-next-line
              // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
            <RichTextInput />
          }
        </FormItem>
        {
          values?.visibility === 'hidden' ?
            <FormItem name='isUsingPagination' valuePropName="checked" label="Use Pagination"
              description={<>Enable to use pagination. You can use <Tag>{'{{{bundle.meta.page}}}'}</Tag> if your API page start from 0, or <Tag>{'{{{increment bundle.meta.page}}}'}</Tag> if your API page start from 1</>}>
              <Switch onChange={onChangeUsingPagination}/>
            </FormItem> :
            null
        }
        <FormItem name='isAsc' valuePropName="checked" label="Reverse Data (newest first)" description="Enable to get / sort data to newest first result">
          <Switch onChange={onChangeIsAsc} />
        </FormItem>
        <FormItem required={values?.type=== 'script'} className="no-padding-extra" name="stateKeyMap" label="Perform" description={
          <>
            <p>The perform function is called each time a new hook is received. Make any needed changes to the data before it's returned to the Connection. This request must return something. <a target="_blank" href={`${IMPLIKASI_URL}/private/docs/docs/build-apps/action#api-configuration`}>Click here to more info</a></p>
            <MonacoEditor height="50px" noResize options={{ readOnly: true, lineNumbers: 'off', overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true, scrollBeyondLastLine: false, lineHeight: 15,
              scrollbar: {
                vertical: 'hidden'
              },
              overviewRulerBorder: false, }} defaultLanguage="javascript" value={'\n// Example: ({data}) => data.result.sort((a, b) => a.message.date - b.message.date) \n'} />
          </>}
        >
          <MonacoEditor defaultLanguage="javascript"  />
        </FormItem>
        <br />
        {
          values?.type === 'hook' ?
            <>
              <FormItem name='isAggregator' valuePropName="checked" label="As Aggregator" description="Enable to make this trigger as aggregator">
                <Switch onChange={onChangeIsAggregator} />
              </FormItem>
              {
                values.isAggregator ?
                  <FormItem name='isGlobalAggregator' valuePropName="checked" label="As Global Aggregator" description="Enable to make this trigger as global aggregator">
                    <Switch onChange={onChangeIsGlobalAggregator} />
                  </FormItem> :
                  null
              }
            </>
            : null
        }
        <StyledLabel className={values?.isAggregator ? 'required' : ''}>Condition</StyledLabel>
        <StyledDescription>The condition function is called each time a new hook is received. Make any needed changes to the data before it's returned to the Connection. This request must return something.</StyledDescription>
        <MonacoEditor   height="50px" noResize options={{ readOnly: true, lineNumbers: 'off', overviewRulerLanes: 0,
          hideCursorInOverviewRuler: true, scrollBeyondLastLine: false, lineHeight: 15,
          scrollbar: {
            vertical: 'hidden'
          },
          overviewRulerBorder: false, }} defaultLanguage="javascript" value={'\n// Example: ({data}) => {return data.type === \'transaction failed\'}'} />
        <MonacoEditor defaultLanguage="javascript" value={values?.condition} setValue={setCondition} />

        <br/>
        <FormItem shouldUpdate>
          {({ getFieldValue }) => {
            const currType = getFieldValue('type')
            return (
              <StyledFooterForm>
                <StyledButton type="primary" ghost onClick={onPrevious}>Previous</StyledButton>
                <StyledButton type="primary"
                  disabled={
                    !currType ||
                  currType === 'polling' && !httpRequest?.httpRequest?.url ||
                  values?.isAggregator && !values.condition ||
                  currType === 'script' && !getFieldValue('stateKeyMap')}
                  onClick={onSaveAndContinue}
                  loading={creatingTrigger}
                >
                  {'Save & Continue'}
                </StyledButton>
              </StyledFooterForm>
            )
          }}
        </FormItem>
      </CustomForm>
    </StyledConfigForm>
  )
}

export default Config

const StyledConfigForm = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;

  ${StyledTag} {
    color: rgba(0, 0, 0, 0.65);
  }
`