import { SearchOutlined } from '@ant-design/icons'
import { Application } from 'db'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { StateWithTestData } from '../../app/scenario/edit/DataPickerPopper'
import useSearchInput from '../../app/scenario/edit/useSearchInput'
import CustomDataAccordion from './DataPickerPopper/CustomDataAccordion'

export type DataSuggestion = Partial<StateWithTestData> & {
  name?: string | undefined,
  order?: number,
  stateName?: string,
  triggerActionName?: string,
  application?: Application,
  testData: Record<string, any>,
  flatTestData: {
    [tag: string]: {
      sample: any,
      value: string,
      label: string,
      appLogoUrl?: string
    }
  }
}

export type DataPickerPopperProps = {
  dataSuggestion: { [stateId: string]: DataSuggestion } | null, // trigger and trigger.application should be expanded
  onTagClick: (tagValue: string) => void
}

const DataPickerPopper = forwardRef<HTMLDivElement, DataPickerPopperProps>(({ dataSuggestion, onTagClick }, ref) => {
  const { StyledSearchInput, searchQuery } = useSearchInput()

  return (
    <DataPickerPopperContainer ref={ref}>
      <SingleDataPickerPopperSearch>
        <h5>Insert data</h5>
        <CustomInputSearchData>
          <StyledSearchInput suffix={<SearchOutlined />} placeholder="Search data..." />
        </CustomInputSearchData>
      </SingleDataPickerPopperSearch>
      <CustomDataAccordion
        dataSuggestion={dataSuggestion}
        onTagClick={onTagClick}
        searchTerm={searchQuery}
      />
    </DataPickerPopperContainer>
  )
})

export default DataPickerPopper

const CustomInputSearchData = styled.div`
  & > .ant-input-affix-wrapper {
    background: rgba(186, 139, 66, 0.4);

    * {
      color: #FFF;
    }

    border: none;
    outline: none;

    &:focus {
      box-shadow: none;
    }

    input {
      background: rgba(186, 139, 66, 0);
      color: #FFF;
    }

    input::placeholder {
      color: #FFF;
      font-size: 12px;
    }
  }
`

const DataPickerPopperContainer = styled.div`
  display: flex;
  z-index: 99;
  flex-direction: column;
  border-radius: 4px;

  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  transition: all .24s;
`

const SingleDataPickerPopperSearch = styled.div`
  padding: 16px;
  background-color: #F3AD3D;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  h5 {
    color: #FFF;
  }
`