import { ReloadOutlined } from '@ant-design/icons'
import { Form, Spin } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { useForm } from 'antd/lib/form/Form'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useStateTest, { ResponseTest } from '../../../../../../../../hooks/scenario/state/useStateTest'
import useUpdateAScenarioState from '../../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { StyledAlert, StyledButton, StyledFooterForm, StyledInfo, StyledSecondaryButton } from '../../../../../../../../pages/components/StyledComponents'
import { ENABLE_IS_ERROR_STATE } from '../../../../../../../../util/Constant'
import { convertFormValueToParams, convertParamsToFormValue, getFlatDataTest } from '../../../../../../../../util/formatter'
import { isValidArrayMapItem } from '../../../../../../../../util/functions'
import DataResponse, { StyledCard } from '../../../../../history/job/DataResponse'
import { updatingScenarioOrState } from '../../../../../scenario/edit/recoil'
import StateForm from '../../../../../scenario/edit/StateForm'
import { useStateTestData } from '../../../../../scenario/edit/StateTestDataContext'
import TestDataArray from '../../../../../scenario/edit/TestDataArray'
import { stateBundleIdAtomFamily, stateEventAtom, stateNameAtom, stateStatus } from '../../../recoil/state'
import { useStateValue } from '../../StateValueContextProvider'
import ButtonContinueOrEnd from './ButtonContinueOrEnd'
import TestGuidelineContent from './TestGuidelineContent'

interface StateContentMainProps {
}
const StateContentMain: FC<StateContentMainProps> = () => {
  // initiate ant form
  const [form] = useForm()
  const [stateValue] = useStateValue()
  const { id: stateId, type, stateName: defaultStateName, triggerType: defaultTriggerType, isErrorState,
    authenticationId, application: defaultApplication, paramSchema: defaultParamSchema, params, triggerId: defaultTriggerId, actionId: defaultActionId, order: stateOrder, triggerActionName: defaultEventName, scenarioId } = stateValue || {}
  const event = useRecoilValue(stateEventAtom(stateId))
  const selectedBundleId = useRecoilValue(stateBundleIdAtomFamily(stateId))
  const bundleId = selectedBundleId
  const actionId = type === 'action' ? event ? event.id : defaultActionId : undefined
  const triggerId = type === 'trigger' ? event ? event.id : defaultTriggerId : undefined
  const triggerType = event ? event.type : defaultTriggerType
  const applicationId = event ? event.applicationId : defaultApplication?.id
  const application = event ? event.application : defaultApplication
  const triggerActionName = event ? event.name : defaultEventName
  const paramSchema = event ? event.paramSchema : defaultParamSchema
  const isUseAuthenticationId = event ? event.authenticationId : authenticationId
  const name = useRecoilValue(stateNameAtom(stateId))
  const stateName = name || defaultStateName
  // we need this ref for calling form method inside useEffect, so that form is not necessary as useeffect dependency
  // reference:
  // - https://github.com/ant-design/ant-design/issues/18983 (issue page)
  // - https://github.com/ant-design/ant-design/issues/18983#issuecomment-840816962 (solution)
  const mainForm = useRef(form)
  // set up ref mainform every time form changes (which is every fcking render the form is always re-created by Ant and this should be Ant bug)
  // so that mainForm can be use inside useEffect and the useEffect not running every render
  useEffect(function setMainFormRef() {
    mainForm.current = form
  }, [form])
  // the shit I do for you Ant *sigh... aight let's move on

  // update form value if paramSchema has default value, execute when params doesn't have value yet
  useEffect(() => {
    if (paramSchema && !params) {
      mainForm.current.setFieldsValue(convertParamsToFormValue(params || undefined, paramSchema))
    }
  }, [paramSchema, params, bundleId])

  // initializa form value
  useEffect(() => {
    if (params) {
      mainForm.current.setFieldsValue(convertParamsToFormValue(params, paramSchema))
    }
  }, [params, bundleId])

  const [stateTestData, setStateTestData] = useStateTestData()
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)
  const [_, setIsFormValid] = useState<boolean>(false)
  const [counterRefetch, setCounterRefetch] = useState(0)
  const [counterTest, setCounterTest] = useState(0)
  const [isOnRefresh, setIsOnRefresh] = useState(false)
  const { testState, testingState, errorTestState, resultDataTest } = useStateTest()
  const setStateStatus = useSetRecoilState(stateStatus(stateId))

  const getAllOptions = () => {
    setCounterRefetch(counterRefetch + 1)
    setIsOnRefresh(true)
  }

  const validateFormFields = () => setIsFormValid(!form.getFieldsError().some(item => item.errors.length > 0))

  const saveTestData = (testData: ResponseTest) => {
    if (testData) {
      const rawTestData: Record<string, any> | (Record<string, any>)[] = typeof testData.error === 'string' ? testData.data || testData : testData.error || testData.data || testData
      let triggerActionTestResult: Record<string, any>

      if (Array.isArray(rawTestData)) {
        triggerActionTestResult = { ...rawTestData[0] }
      } else {
        triggerActionTestResult = { ...rawTestData }
      }

      setStateTestData(prevState => {
        if (!triggerActionTestResult || !stateId || stateOrder === undefined || !triggerActionName || !application) { // meh, eslint go angry without this check
          return { ...prevState }
        }

        const prevStateWithTestData = prevState && prevState[`${stateId}`] ?
          prevState[`${stateId}`] :
          {
            id: stateId,
            order: stateOrder,
            triggerActionName: triggerActionName,
            stateName,
            application: application
          }

        // const stateFlattenTestData: Record<string, any> = flatten(triggerActionTestResult)
        // const flattenTestDataSafe: Record<string, any> = flatten(triggerActionTestResult, { safe: true })

        // const flatTestDataSafe = Object.keys(flattenTestDataSafe).reduce((acc, dataKey) => ({
        //   ...acc,
        //   [dataKey]: {
        //     sample: truncateLongData(flattenTestDataSafe[dataKey]),
        //     value: `${stateId}.${dataKey}`,
        //     label: `${stateOrder + 1}. ${dataKey} ${Array.isArray(flattenTestDataSafe[dataKey]) ? '(array)': ''}`,
        //     appLogoUrl: application?.logoUrl
        //   }
        // }), {})

        // const flattenCodeMirrorTagData = Object.keys(stateFlattenTestData)
        //   .filter(dataKey => typeof stateFlattenTestData[dataKey] !== 'object')
        //   .reduce((acc, dataKey) => ({
        //     ...acc,
        //     [dataKey]: {
        //       sample: truncateLongData(stateFlattenTestData[dataKey]),
        //       value: `${stateId}.${dataKey}`,
        //       label: `${stateOrder + 1}. ${dataKey}`,
        //       appLogoUrl: application?.logoUrl
        //     }
        //   }), flatTestDataSafe)

        // console.log('update test data: ', { [`${stateId}`]: { ...prevStateWithTestData, testData: { ...triggerActionTestResult }, flatTestData: { ...flattenCodeMirrorTagData } } })

        return {
          ...prevState,
          [`${stateId}`]: {
            ...prevStateWithTestData,
            realTestData: {
              ...testData,
              success: !testData.error
            },
            testData: triggerActionTestResult,
            flatTestData: getFlatDataTest({ testData: triggerActionTestResult, stateId, stateOrder, application })
          }
        }
      })
    }
  }


  const onTest = async () => {
    // update state first before doing testing
    updateAScenarioState(scenarioId, stateId, { params: convertFormValueToParams(form.getFieldsValue()) })
      .then(async () => {
        const test = await testState(scenarioId, stateId).then(({ data }) => data)
        setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
        setCounterTest(counted => counted + 1)
        if (test) {
          // console.log('test data on StateContentMain: ', test)
          saveTestData(test)
          if (test.success === false) {
            setStateStatus(prevState => ({ ...prevState, testTriggerAction: true, testTriggerActionSuccess: false }))
          } else {
            setStateStatus(prevState => ({ ...prevState, testTriggerAction: true, testTriggerActionSuccess: true }))
          }
        }
        // revalidateAScenarioState(scenarioId, stateId)
      })
  }

  const stopEventPropagation = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onRefetchOptions = (isRefetch?: boolean) => {
    if (!isRefetch && counterRefetch > 0 && isOnRefresh) {
      setIsOnRefresh(false)
    }
  }


  return (
    <>
      <TestGuidelineContent positionContent="top" />
      {
        form ?
          <Spin spinning={isOnRefresh}>
            <StateForm
              setOnRefetchOptions={onRefetchOptions}
              isEnableAuthentication={!!isUseAuthenticationId}
              form={form}
              triggerId={triggerId}
              actionId={actionId}
              applicationId={applicationId}
              bundleId={bundleId || undefined}
              counterRefetch={counterRefetch}
              initialParamSchema={paramSchema}
              scenarioId={scenarioId}
              stateId={stateId}
              formAntProps={{
                name: `${stateId}`, // form name will use state.id
                // onClick: stopEventPropagation,
                style: { padding: '0 24px' },
                onFieldsChange: validateFormFields,
                // onFinish: onTest
              }}
            >
              <TestGuidelineContent positionContent='test-no-matter' />
              {
                counterTest == 0 && triggerType === 'hook' ?
                  <StyledInfo
                    message={<TestGuidelineContent positionContent="after-fields-before-test" />}
                  />
                  : null
              }
              {
                // if success
                counterTest > 0 && resultDataTest?.success === true && triggerType === 'hook' ?
                  <StyledAlert
                    className="mb-4"
                    type="success"
                    message={
                      <>
                        <h5>We found a request!</h5>
                        <TestGuidelineContent positionContent="after-fields-after-test-success" />
                      </>
                    } />
                  :
                // if error
                  counterTest > 0 && triggerType === 'hook' && (resultDataTest?.error || resultDataTest?.success === false || errorTestState) ?
                    <StyledAlert
                      className="mb-4"
                      type="error"
                      message={
                        <>
                          <h5>We couldn’t find a request</h5>
                          <TestGuidelineContent positionContent="after-fields-after-test-error" />
                        </>
                      }
                    /> : null
              }
              <CustomStyledFooterForm>
                <Form.Item shouldUpdate>
                  {
                    paramSchema && paramSchema?.filter(field => field.paramSchemaFunc).length > 0 ?
                      <StyledSecondaryButton
                        htmlType="button"
                        onClick={getAllOptions}
                        icon={<ReloadOutlined />}
                        loading={isOnRefresh}
                      >
                        Refresh Data
                      </StyledSecondaryButton> :
                      <div />
                  }
                </Form.Item>
                <Form.Item shouldUpdate>
                  {({ getFieldsError }) => {
                    const errors = getFieldsError().reduce((res, curr) => {
                      const errorExceptRequired = curr.errors.filter(err => !err.includes('required'))
                      return {
                        ...res,
                        [curr.name[0]]: [...res[curr.name[0]] || [], ...errorExceptRequired]
                      }
                    }, {})
                    const isFormValid = Object.keys(errors).filter(key => errors[key].length > 0).length === 0

                    return (
                      stateTestData?.[stateId]?.testData ?
                        <StyledAlert
                          action={
                            <StyledButton size="small" className="test" onClick={onTest} disabled={(() => {
                              if (paramSchema) {
                                return !isFormValid || paramSchema
                                  .filter(field => !field.getValueFunc && !field.allowEditByUser)
                                  .reduce((acc: boolean, ps) => {
                                    const notValidArrayMap = !isValidArrayMapItem({ param: ps, value: form.getFieldValue(ps.key), stateTestData: stateTestData })
                                    // console.log('tes', notValidArrayMap)
                                    return ps.required && form.getFieldValue(ps.key) === '' || notValidArrayMap || acc
                                  }, false)
                              }
                              return false // by default false
                            })()}
                            loading={testingState || updatingAScenarioState} htmlType="button"
                            >
                              Retest
                            </StyledButton>
                          }
                          type={stateTestData?.[stateId]?.realTestData?.success === false ? 'error' : 'success'}
                          showIcon
                          message={`${type === 'action' ? 'Action' : 'Trigger'} test ${stateTestData?.[stateId]?.realTestData?.success === false ? 'error' : 'success'}`} /> :
                        <StyledButton
                          className="test"
                          htmlType="button"
                          type="primary"
                          onClick={onTest}
                          loading={testingState || updatingAScenarioState}
                          disabled={(() => {
                            if (paramSchema) {
                              return !isFormValid || paramSchema
                                .filter(field => !field.getValueFunc && !field.allowEditByUser)
                                .reduce((acc: boolean, ps) => ps.required && form.getFieldValue(ps.key) === '' ||
                                !isValidArrayMapItem({ param: ps, value: form.getFieldValue(ps.key), stateTestData: stateTestData }) || acc, false)
                            }
                            return false // by default false
                          })()}
                        >
                          Test {type === 'trigger' ? 'Trigger' : 'Action'}
                        </StyledButton>
                    )

                  }}
                </Form.Item>
              </CustomStyledFooterForm>
            </StateForm>

            <StyledContainerTestSection onClick={stopEventPropagation}>
              {
                Array.isArray(stateTestData?.[stateId]?.realTestData?.data) && !(triggerType === 'schedule') ?
                  <TestDataArray /> :
                  stateTestData?.[stateId]?.testData ?
                    <StyledCard>
                      <DataResponse number={stateOrder !== undefined ? stateOrder + 1 : undefined} data={stateTestData?.[stateId]?.testData} logoUrl={application?.logoUrl} />
                    </StyledCard> :
                    null
              }
              <br />

              <div className="float-right flx items-center">
                {
                  !ENABLE_IS_ERROR_STATE || type === 'trigger' ? null :
                    <>
                      Is Error State:
                      <Checkbox className="mx-3" defaultChecked={isErrorState} onChange={(e) => {
                        updateAScenarioState(scenarioId, stateId, { isErrorState: e.target.checked }).then(() => {
                          setIsUpdatingScenarioOrState(prevState => ({ counter: prevState.counter + 1, isUpdating: false }))
                        })
                      }} />
                    </>

                }
                <ButtonContinueOrEnd />
              </div>
            </StyledContainerTestSection>
          </Spin> : null
      }
    </>
  )
}

export default StateContentMain

const CustomStyledFooterForm = styled(StyledFooterForm)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

const StyledContainerTestSection = styled.div`
  padding: 0 24px;
  overflow: hidden;
`