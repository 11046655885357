import { AxiosPromise } from 'axios'
import { Folder } from 'db'
import { Error } from '../../types'
import { useApi } from '../useFetch'
import useFolders from './useFolders'

type CreateFolder = {
  createdFolder?: Folder,
  creatingFolder: boolean,
  errorCreateFolders?: Error,
  createFolder: (payload?: Partial<Folder>) => AxiosPromise<{ folder: Folder }>
}

export default function useCreateFolder(): CreateFolder {
  const { data, error, isValidating, call } = useApi<{ folder: Folder }>()
  const { revalidateFolders } = useFolders()

  return {
    createdFolder: data?.folder,
    creatingFolder: isValidating,
    errorCreateFolders: error,
    createFolder: (payload?: Partial<Folder>) => {
      const post = call({
        url: '/folder',
        method: 'post',
        data: {
          folder: {
            name: 'New Folder',
            ...payload
          }
        }
      })

      post.then(() => {
        revalidateFolders()
      })

      return post
    }
  }
}