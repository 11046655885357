import { EditOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'
import { FC } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useUpdateAScenarioState from '../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { StyledBoxIcon, StyledP2Description } from '../../../../../../../pages/components/StyledComponents'
import { primary65 } from '../../../../../../../util/colors'
import { getInitialName } from '../../../../../../../util/formatter'
import { updatingScenarioOrState } from '../../../../scenario/edit/recoil'
import { stateEventAtom, stateNameAtom } from '../../recoil/state'
import { useStateValue } from '../StateValueContextProvider'

interface Props {
  notAllowedEditName?: boolean
}
const StateHeaderInfo: FC<Props> = ({ notAllowedEditName }) => {
  const [stateValue] = useStateValue()
  const { id, type, application, stateName, order: thisOrder, triggerActionName, scenarioId } = stateValue || {}
  const name = notAllowedEditName ? 'Path' : stateName || triggerActionName
  const [thisName, setThisName] = useRecoilState(stateNameAtom(id))
  const eventState = useRecoilValue(stateEventAtom(id))
  const { updateAScenarioState } = useUpdateAScenarioState()
  const setUpdatingAScenarioState = useSetRecoilState(updatingScenarioOrState)

  // useEffect(() => {
  //   !notAllowedEditName && !thisName && setThisName(name)
  // }, [name, id])

  const currentApplication = eventState?.application || application
  const currentTriggerActionName = eventState?.name || triggerActionName

  return (
    <StyledTitle>
      <CustomStyledBoxIcon>
        {
          currentApplication?.logoUrl ?
            <img src={currentApplication?.logoUrl} /> : getInitialName(currentApplication?.name)
        }
      </CustomStyledBoxIcon>
      <Space direction="vertical" size="small">
        <StyledP2Description>{type === 'trigger' ? 'Trigger' : 'Action'} — {currentApplication?.name}: {currentTriggerActionName}</StyledP2Description>
        <Space>
          <h5>{`${(thisOrder || 0) + 1}. `}</h5>
          <Typography.Title level={5} editable={notAllowedEditName ? false : {
            tooltip: 'edit name',
            icon: <EditOutlined />,
            onChange: async (input) => {
              if (input) {
                setThisName(input)
                updateAScenarioState(scenarioId, id, { name: input })
                  .then(() => {
                    setUpdatingAScenarioState({ counter: 2, isUpdating: false })
                  })
              }
            }
          }}>
            {thisName || name}
          </Typography.Title>
        </Space>
      </Space>
    </StyledTitle>
  )
}

export default StateHeaderInfo

const StyledTitle = styled(Space)`
  h5 {
    color: ${primary65}
  }
`

const CustomStyledBoxIcon = styled(StyledBoxIcon)`
  width: 53px;
  height: 53px;
  img {
    width: 37px;
    height: 37px;
  }
`