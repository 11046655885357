import { useEffect } from 'react'
import { ConnectDragPreview, ConnectDragSource, useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

interface DragFolderHooks {
  drag: ConnectDragSource,
  preview: ConnectDragPreview,
  isDragging: boolean,
  opacity: number
}

export default function useDragFolder({ index, id, name }: { index: number, id: number, name: string }): DragFolderHooks {
  const [{ isDragging }, drag, preview] = useDrag({
    type: index ? 'folder' : '',
    item: {
      folder: {
        index, id, name
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    })
  }, [])

  const opacity = isDragging ? 0 : 1
  return {
    drag, preview,
    isDragging,
    opacity
  }

}