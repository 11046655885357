import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Image, Input, Layout, Row, Skeleton, Spin, Tooltip, Typography } from 'antd'
import { Application } from 'db'
import { default as React, FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import DefaultPNG from '../../../../assets/DefaultPNG'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import usePublicApplications from '../../../../hooks/application/main/usePublicApplications'
import useNewCheckoutPayment from '../../../../hooks/payment/useNewCheckoutPayment'
import useUpdateUser from '../../../../hooks/user/useUpdateUser'
import { StyledButton, StyledTag as BaseTag } from '../../../../pages/components/StyledComponents'
import CustomForm from '../../../../pages/developer/components/form/CustomForm'
import { blue, orange } from '../../../../util/colors'
import SignLayout from '../../../SignLayout'


const PersonalDataForm: FC = () => {
  const [step, setStep] = useState(0)
  const { publicApplications } = usePublicApplications({ public: 'true', isNativeApp: 'false' })
  const [favouriteApps, setFavoriteApps] = useState<string[]>([])
  const [selectedApps, setSelectedApps] = useState<Application[]>()
  const [searchValue, setSearchValue] = useState<string>('')
  const { updateUser, updatingUser } = useUpdateUser()
  const { checkout } = useNewCheckoutPayment()
  const [user] = useLoggedInUser()
  const history = useHistory()

  const DotSteps = <WrapperDots>
    <Dot active={step === 0}></Dot>
    <Dot active={step === 1}></Dot>
  </WrapperDots>

  const onStepOneFinish = (values: any) => {
    if (user) {
      updateUser(user.id, values)
      setStep(step + 1)
    }
  }

  const addApplication = (application: Application) => {
    if (!selectedApps?.map(app => app.id).includes(application.id)) {
      setSelectedApps(selectedApps ? [...selectedApps, application] : [application])
      setFavoriteApps(selectedApps ? [...selectedApps, application].map(app => app.name) : [application.name])
    }
  }

  const removeApp = (id: number) => {
    const newApps = selectedApps?.filter(app => app.id !== id)
    if (newApps) {
      setFavoriteApps(newApps.map(app => app.name))
    }
    setSelectedApps(newApps)
  }

  const onStepTwoFinish = async () => {
    if (user) {
      await checkout()
      await updateUser(user.id, {
        favouriteApps: favouriteApps
      })

      redirectToHome()
    }
  }

  const redirectToHome = () => {
    history.push('/app')
  }

  useEffect(() => {
    if (user?.favouriteApps) {
      redirectToHome()
    }
  }, [user])

  if (!user) {
    return <Spin spinning={true} className={'h-auto m-auto'} />
  }
  return (
    <StyledLayoutContent>
      {
        step === 0 ?
          <SignLayout>
            <Card bordered={false}>
              <CustomForm onFinish={onStepOneFinish} requiredMark="optional">
                <StyledTitle>Selamat datang!</StyledTitle>
                <StyledText>Bantu kami memberikan pengalaman terbaik yang sesuai dengan profil kamu, dengan mengisi informasi berikut.</StyledText>
                <Form.Item name="name" label="Nama" rules={[{ required: true, message: 'Nama wajib diisi' }]}>
                  <Input placeholder="Nama Kamu" />
                </Form.Item>
                <Form.Item name="job" label="Pekerjaan" rules={[{ required: true, message: 'Pekerjaan wajib diisi' }]}>
                  <Input placeholder="Pekerjaan Kamu" />
                </Form.Item>
                <Form.Item name="company" label="Perusahaan tempat kamu bekerja"  rules={[{ required: true, message: 'Perusahaan wajib diisi' }]}>
                  <Input placeholder="Perusahaan Kamu" />
                </Form.Item>
                <StyledButton htmlType="submit" type="primary" style={{ width: '100%' }}>Berikutnya</StyledButton>
              </CustomForm>
              <br />
              {DotSteps}
            </Card>
          </SignLayout> :
          <Row align="middle" justify="center" gutter={[16, 16]}>
            {
              step === 1 ?
                <StyledCol3  lg={{ span: 16 }} xs={{ span: 20 }} xl={{ span: 14 }} >
                  <Card bordered={false}>
                    <StyledTitle>Aplikasi Apa yang Sering Kamu Gunakan?</StyledTitle>
                    <StyledText>Otomatis akan membantu Kamu untuk memanfaatkan Aplikasi yang sering Kamu gunakan.</StyledText>
                    <br />
                    <BlueBox>
                      <Input onChange={(e) => setSearchValue(e.target.value)} placeholder='input search text' suffix={<SearchOutlined />} />
                      <br /><br />
                      <StyledRow gutter={[0, 16]}>
                        {
                          !publicApplications ?
                            [...new Array(12)]
                              .map((_, i) =>
                                <AppItem key={i} xs={8} sm={4} md={4} lg={3} xl={2} xxl={2}>
                                  <Skeleton.Button size="large" shape="circle" active />
                                  <Skeleton.Input style={{ width: '50px' }} size="small" active />
                                </AppItem>
                              ) :

                            publicApplications
                              .filter(application => searchValue !== '' ? application.name.toLowerCase().includes(searchValue.toLowerCase()) : true)
                              .map(application => {
                                const { name, logoUrl } = application

                                return (
                                  <Tooltip key={application.id} title={name}>
                                    <AppItem xs={8} sm={4} md={4} lg={3} xl={2} xxl={2} className="app-item" onClick={() => addApplication(application)}>
                                      <BoxImage>
                                        <Image src={logoUrl || DefaultPNG} width={40} height={40} fallback={DefaultPNG} preview={false} />
                                      </BoxImage>
                                      <Typography.Text ellipsis style={{ width: '100%' }} title={name}>{name}</Typography.Text>
                                    </AppItem>
                                  </Tooltip>
                                )
                              })
                        }
                      </StyledRow>
                    </BlueBox>
                    <BoxTags>
                      {
                        selectedApps?.map(app => {
                          const { logoUrl, id, name } = app
                          return (
                            <StyledTag
                              closeIcon={<CloseCircleOutlined />}
                              icon={<Image src={logoUrl || DefaultPNG} width={20} height={20} fallback={DefaultPNG}preview={false} />}
                              key={id}
                              closable
                              onClose={() => removeApp(id)}
                            >
                              {name}
                            </StyledTag>
                          )
                        })
                      }
                    </BoxTags>
                    <br />
                    <StyledButton onClick={onStepTwoFinish} loading={updatingUser} style={{ width: '100%' }} type="primary">Mulai Sekarang!</StyledButton>
                    <br /><br />
                    {DotSteps}
                  </Card>
                </StyledCol3>
                :
                null
            }
          </Row>

      }
    </StyledLayoutContent>
  )
}

export default PersonalDataForm

const StyledLayoutContent = styled(Layout.Content)`
  min-height: 100vh;
  overflow-y: auto;
  background: ${blue[4]};
  display: grid;
  align-items: center;
  overflow-x: hidden;

  .ant-form label {
    font-size: 14px;
  }
`
const StyledCol2 = styled(Col)`
  height: 554px;
  background: #FFF;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  @media (max-width: 768px) {
    height: unset;
  }
`
const StyledCol3 = styled(StyledCol2)`
  border-radius: 4px;
  height: unset;
`

const StyledText = styled.p`
  /* P1 - 14 */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 0;
`
const StyledTitle = styled.h1`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: #5E6C84;
  margin-bottom: 0;
  margin-left: 0;
`

const Dot = styled.div<{ active: boolean }>`
  width: 9px;
  height: 9px;
  background: ${props => props.active ? orange[4] : orange[1]};
  border-radius: 100%;
`
const WrapperDots = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 10px;
  justify-content: center;
`
const BlueBox = styled.div`
  /* Blue/1 */
  background: #F0FBFF;
  /* Blue/4 */
  border: 0.5px dashed #3E73E3;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 16px;
  height: 245px;
  overflow-y: scroll;
`
const BoxTags = styled.div`
  overflow-x: auto;
  margin-top: 16px;
  display: flex;
`
const AppItem = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`
const StyledRow = styled(Row)``

const StyledTag = styled(BaseTag)`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  width: max-content;
  white-space: nowrap;
  column-gap: 4px;
  padding: 2px 7px;

  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  /* Character/Primary .65 */
  color: rgba(0, 0, 0, 0.65);

  .ant-tag-close-icon {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
  }
`
const BoxImage = styled.div`
  /* Neutral/1 */
  background: #FFFFFF;
  /* Neutral/5 */
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px;
`