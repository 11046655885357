import { Action } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  archivedAction?: Action,
  archivingAction: boolean,
  errorArchiveAction?: Error,
  archiveAction: (applicationId: number, actionId: number, versionId?: number) => void
}

export default function useArchiveAction(): Result {
  const { data, error, isValidating, call } = useApi<{ action: Action }>()

  return {
    archivedAction: data?.action,
    archivingAction: isValidating,
    errorArchiveAction: error,
    archiveAction: (applicationId, actionId, versionId) => {
      call({
        url: `/application/${applicationId}/action/${actionId}/archive?versionId=${versionId}`,
        method: 'delete',
      })
    }
  }
}