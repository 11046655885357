import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Row, Space, Spin, Tooltip } from 'antd'
import { Action, Application, State, Trigger } from 'db'
import { FC, Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import DefaultPNG from '../../../../../../assets/DefaultPNG'
import useApplicationsWithTriggerAndActionByIds from '../../../../../../hooks/application/main/useApplicationsWIthTriggerAndActionByIds'
import { StyledInput, StyledP2Description, StyledParagraph, StyledTagLabel } from '../../../../../../pages/components/StyledComponents'
import { compareString } from '../../../../../../util/functions'
import { useSharedValueScenario } from '../contexts/SharedValueScenarioContextProvider'
import { StyledWhenOrDoForm, TriggerActionAppIcon } from './SearchWhenOrDoForm'

interface Props {
  type: 'do' | 'when',
  selectedApplications: Application[],
  selectedTrigger: Trigger | undefined,
  selectedActions: Action[],
  onClickTrigger?: (selectedTrigger: Trigger) => void,
  onClickAction?: (selectedAction: Action) => void
}

type OptionForm = Action | Trigger
type Options = Record<number, Record<string, (Action | Trigger)[]>>

const SearchDoForm: FC<Props> = ({ type, selectedApplications }) => {
  const [values, setValues] = useSharedValueScenario()
  const { applications, fetchingApplications } = useApplicationsWithTriggerAndActionByIds(selectedApplications.map(application => Number(application?.id)))
  const [searchValue, setSearchValue] = useState<string>()
  const [filteredOptions, setFilteredOptions] = useState<Options>()

  const onClickItem = (opt: OptionForm) => {
    let currStates: (Partial<State> | null)[] = values?.states || [null]
    if (type === 'when') {
      currStates = currStates.length > 0 ? currStates.map((state, idx) => {
        if (idx === 0) {
          return { ...state, trigger: opt as Trigger, triggerId: opt.id }
        }
        return state
      }) : [{ trigger: opt as Trigger, triggerId: opt.id }]
    } else {
      currStates = [...currStates || null, { action: opt as Action, actionId: opt.id }]
    }

    setValues(prevState => ({ ...prevState, states: currStates as State[] }))
  }

  // useEffect(() => {
  //   const selected = selectedApplications.filter(application => application?.id).map(application => Number(application?.id))
  //   setSelectedApplicationIds(selected)
  // }, [selectedApplications])

  const getOptions: (type: 'do' | 'when', searchValue?: string) => Options = (_, searchValue) => {
    if (type === 'when') {
      const triggers: Options = applications?.reduce((result: Options, application) => {
        const versionId = application.versions ? application.versions[0]?.id : undefined
        const currTriggers = application.triggers?.filter(trigger => trigger.name.toLowerCase().includes(searchValue?.toLocaleLowerCase() || ''))
        if (currTriggers) {
          const triggersWithLogo: Trigger[] = currTriggers.filter(trigger => trigger.versionId === versionId && trigger.visibility === 'visible').map(trigger => ({ ...trigger, application: application } as Trigger))
          const realtimeTriggers = triggersWithLogo.filter(trigger => trigger.type === 'hook')
          const poolingTrigger = triggersWithLogo.filter(trigger => trigger.type === 'polling')
          return { ...result, [application.id]: {
            realtimeTriggers: [...result[application.id]?.search || [], ...realtimeTriggers],
            poolingTrigger: [...result[application.id]?.write || [], ...poolingTrigger],
          } }
        }
        return result
      }, []) || []

      return triggers
    }

    const actions: Options = applications?.reduce((result: Options, application) => {
      const versionId = application.versions ? application.versions[0]?.id : undefined
      const currActions = application.actions?.filter(action => action.name.toLowerCase().includes(searchValue?.toLocaleLowerCase() || ''))
      if (currActions) {
        const actionsWithLogo: Action[] = currActions.filter(action => action.versionId === versionId && !action.isDraft).map(action => ({ ...action, application: application } as Action))
        const searchActions = actionsWithLogo.filter(action => action.category === 'search')
        const writeActions = actionsWithLogo.filter(action => action.category === 'write')
        const nonCategories = actionsWithLogo.filter(action => !action.category)
        return { ...result, [application.id]: {
          search: [...result[application.id]?.search || [], ...searchActions],
          write: [...result[application.id]?.write || [], ...writeActions],
          '': [...result[application.id]?.[''] || [], ...nonCategories]
        } }
      }
      return result
    }, {}) || []

    return actions
  }

  const onSearch = (e: any) => {
    setSearchValue(e.target.value)
  }

  const onRemove = (e: any, idx: number) => {
    e.stopPropagation()
    e.preventDefault()
    const newStates = values?.states?.filter((_, currIdx) => currIdx !== idx)
    setValues(prevState => ({ ...prevState, states: newStates }))
  }

  useEffect(() => {
    if (applications) {
      setFilteredOptions(getOptions(type, searchValue))
    }
  }, [searchValue, applications])


  const data = {
    do: {
      title: 'Do',
    },
    when: {
      title: 'When'
    }
  }

  return (
    <StyledWhenOrDoForm type={type}>
      <div className="searchbox">
        <h5>{data[type].title}</h5>
        <StyledInput suffix={<SearchOutlined />} placeholder={type === 'do' ? 'This action' : 'This happens'} onChange={onSearch} />
      </div>
      <Spin spinning={fetchingApplications}>
        <div className="optionsbox">
          {
            Object.keys(filteredOptions || {})?.map((applicationId) => {
              if (!filteredOptions) return null
              const optionsCategory = filteredOptions[parseInt(applicationId)]
              return Object.keys(optionsCategory).map((category, idx) => {
                const options = optionsCategory[category]
                if (options.length === 0) return null
                return <Fragment key={idx}>
                  <StyledCategoryOption>{category}</StyledCategoryOption>
                  {
                    options
                      .sort((o1, o2) => compareString(o1.name, o2.name))
                      .map((option) => {
                        const { name, id, application, description } = option
                        const activeIdxs = type === 'do' ? values?.states?.map((state, idx) => state?.actionId === id ? idx : null) : undefined
                        const activeIdx = type === 'do' ? values?.states?.findIndex(state => state?.actionId === id) : undefined
                        const isActive = type === 'when' ? !!values?.states?.find(state => state?.triggerId === id) : !!values?.states?.find(state => state?.actionId === id)

                        return (
                          <Tooltip overlayClassName="custom-tooltip" key={id} placement="bottom" title={description} color="white">
                            <Row style={{ marginBottom: 0 }} onClick={() => onClickItem(option)} key={id} gutter={[16, 16]} className={`option-item ${isActive ? 'active' : ''}`} align="middle">
                              <Col flex={0}>
                                <TriggerActionAppIcon background={application?.logoUrl || DefaultPNG} />
                              </Col>
                              <Col flex="auto">
                                <StyledParagraph>{name}</StyledParagraph>
                              </Col>
                              {
                                option.type === 'hook' ?
                                  <Col flex={0}>
                                    <StyledTagLabel color="success">real time</StyledTagLabel>
                                  </Col> :
                                  null
                              }
                              {
                                activeIdx && activeIdx > 0 ?
                                  <Col flex={0}>
                                    <Space style={{ maxWidth: 90, overflowX: 'auto', overflowY: 'hidden' }}>
                                      {
                                        activeIdxs?.map(number => number ?
                                          <StyledParagraph onClick={(e) => onRemove(e, number)} key={number} className="active-number">
                                            {number}
                                            <DeleteOutlined className="delete-icon" />
                                          </StyledParagraph> : null)
                                      }
                                    </Space>
                                  </Col>: null
                              }
                            </Row>
                          </Tooltip>
                        )
                      })
                  }
                </Fragment>
              })
            })
          }
        </div>
      </Spin>
    </StyledWhenOrDoForm>
  )
}

export default SearchDoForm

const StyledCategoryOption = styled(StyledP2Description)`
  color: #000;
  border-bottom: 1px solid #96C443;
  &.ant-typography {
    margin-bottom: 4px !important;
  }
  text-transform: capitalize;
  width: max-content;
  line-height:20px;
`