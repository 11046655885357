import { Form, FormItemProps } from 'antd'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import MarkdownRenderer from '../../../../components/MarkdownRenderer'

interface Props extends FormItemProps {
  notUseInfoRequired?: boolean,
  description?: ReactNode,
  onBlur?: any,

  // below props are required only for rich text input
  small?: boolean,
  inputType?: string,
  enableRichInput?: boolean
}

const FormItem: FC<Props> = ({ description, inputType, enableRichInput, children, label, name, rules, extra, notUseInfoRequired, ...res }) => {
  return (
    <>
      <StyledFormItem enableRichInput={enableRichInput} inputType={inputType} className={`${extra ? 'with-extra' : ''} ${notUseInfoRequired ? 'no-info' : ''}`} label={label} rules={rules} extra={
        typeof description === 'string' ? <MarkdownRenderer>{description}</MarkdownRenderer> : description
      } name={name} {...res}>
        {children}
      </StyledFormItem>
      {extra ? <div style={{ marginBottom: 24, marginTop: 24 }}>
        {extra}
      </div> : null}
    </>
  )
}

export default FormItem


// eslint-disable-next-line
const StyledFormItem = styled(({ description, inputType, children, enableRichInput, ...nativeProps }) => <Form.Item {...nativeProps}>{children}</Form.Item>)<{ inputType: string, description: string, enableRichInput?: boolean, small?: boolean }>`
  font-family: 'DM Sans', sans-serif;
  ${({ small, ...props }) => props.enableRichInput && props.inputType !== 'select' ? 'margin-bottom: 0;' : small ? 'margin-bottom: 4px;' : 'margin-bottom: 16px;'}
  .ant-checkbox-wrapper, .ant-radio-wrapper, .ant-form-item-extra {
    font-style: normal;
    font-weight: normal;
    font-size: ${({ small }) => small ? '10px' : '14px'};
    line-height: ${({ small }) => small ? '16px' : '22px'};
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-form-item-control-input {
    min-height: unset;
  }
  &.with-extra {
    margin-bottom: ${({ small }) => small ? '4px' : '8px'};
  }
  .ant-form-item-label {
    padding-bottom: ${props => props.description ? 0 : props.small ? '2px' : '5px'};
  }
  .ant-form-item-label > label {
    font-style: normal;
    font-weight: bold;
    font-size: ${({ small }) => small ? '12px' : '16px'};
    line-height: ${({ small }) => small ? '16px' : '24px'};
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-form-item-optional, label.ant-form-item-required::before {
    font-style: normal;
    font-weight: 500;
    font-size: ${({ small }) => small ? '10px' : '14px'};
    line-height: ${({ small }) => small ? '16px' : '22px'};
    color: #BFBFBF;
    text-transform: capitalize;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: '(Required)';
    color: ${'#9C3930'};
    margin-left: 4px;
  }

  &.no-info .ant-form-item-label > label.ant-form-item-required::before {
    content: '';
  }

  .ant-form-item-label > label.ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .ant-form-item-extra {
    padding-bottom: 5px;
    white-space: pre-line;
  }

  &.no-padding-extra {
    .ant-form-item-extra {
      padding-bottom: 0;
    } 
  }

  .ant-form-item-control {
    ${props => props.enableRichInput && props.inputType !== 'select' ? 'display: none;' : ''}
    flex-direction: column-reverse;
  }

  label {
    padding-bottom: 0
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    bottom: -20px;
  }

`