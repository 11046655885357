import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import { Error } from '../../../types'
import Notification from '../../../util/Notification'

type Result = {
  testTrigger: (payload?: any, bundleId?: number) => Promise<any> | undefined,
  result?: any,
  testingTrigger: boolean,
  errorTestTrigger?: Error
}

export default function useTriggerTest(applicationId?: number | string, triggerId?: number | string): Result {
  const url = triggerId && applicationId ? `/application/${applicationId}/trigger/${triggerId}/test` : null
  const [data, setData] = useState()
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState<Error>()

  const post = async (payload?: any, bundleId?: number) => {
    setIsValidating(true)
    if (url) {
      const test = await Implikasi.post(bundleId ? `${url}?bundleId=${bundleId}` : url, payload)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          Notification.error({
            message: 'Something\'s wrong',
            description: response?.data?.error
          })
          setError(response?.data?.error)
        })
      setIsValidating(false)
      setData(test)
      return test
    }
    setIsValidating(false)
  }

  return {
    result: data,
    testingTrigger: isValidating,
    errorTestTrigger: error,
    testTrigger: post
  }
}