import { ReloadOutlined } from '@ant-design/icons'
import { Divider, Form, FormInstance, Row, Space } from 'antd'
import { FC, useState } from 'react'
import DataResponse, { StyledCard } from 'src/components/pages/app/history/job/DataResponse'
import useStateTest from 'src/hooks/scenario/state/useStateTest'
import useUpdateAScenarioState from 'src/hooks/scenario/state/useUpdateAScenarioState'
import { StyledAlert, StyledButton, StyledSecondaryButton } from 'src/pages/components/StyledComponents'
import { convertFormValueToParams } from 'src/util/formatter'
import { isValidArrayMapItem } from 'src/util/functions'
import styled from 'styled-components'
import { useStateTestData } from '../../../../edit/StateTestDataContext'
import useGetState from '../useGetState'
import useSaveTestData from './useSaveTestData'

interface Props {
  type: 'params' | 'conditions',
  form: FormInstance
}

const TestSection: FC<Props> = ({ type, form }) => {
  const { state } = useGetState()
  const [stateTestData] = useStateTestData()
  const [showTest, setShowTest] = useState(true)
  const stateOrder = state?.order
  const stateId = state?.id || 0
  const isTrigger = !!state?.trigger
  const logoApplicationUrl = (state?.trigger || state?.action)?.application?.logoUrl
  const { updateAScenarioState, updatingAScenarioState } = useUpdateAScenarioState()
  const { testState, testingState } = useStateTest()
  const { saveTestData } = useSaveTestData()
  const paramSchema = (state?.trigger || state?.action)?.paramSchema

  const loading = updatingAScenarioState || testingState

  const onClickTest = async () => {
    if (state) {
      if (type === 'conditions') {
        const conditions = form.getFieldsValue()?.or
          ?.map((or: any) => or.and
            ?.map((andClause: any) => [andClause.field, andClause.operator, andClause.value]))

        // patch state
        await updateAScenarioState(state.scenarioId, state.id, {
          conditions: conditions
        })
      } else {
        await updateAScenarioState(state.scenarioId, state.id, { params: convertFormValueToParams(form.getFieldsValue()) })
      }
      const test = await testState(state.scenarioId, state.id).then(({ data }) => data)
      if (test) {
        saveTestData(test, {
          application: (state.trigger || state.action)?.application,
          scenarioId: state.scenarioId,
          stateId: state.id,
          stateName: state.name || '',
          stateOrder: state.order,
          type: (state.trigger || state.action)?.type,
          triggerActionName: (state.trigger || state.action)?.name
        })
      }
    }
  }
  return (
    <>
      {
        type === 'params' ?
          <>
            <CustomStyledFooterForm>
              <Form.Item shouldUpdate>
                {
                  paramSchema && paramSchema?.filter(field => field.paramSchemaFunc).length > 0 ?
                    <StyledSecondaryButton
                      htmlType="button"
                      // onClick={getAllOptions}
                      icon={<ReloadOutlined />}
                      // loading={isOnRefresh}
                    >
                        Refresh Data
                    </StyledSecondaryButton> :
                    <div />
                }
              </Form.Item>
              <Form.Item shouldUpdate>
                {({ getFieldsError }) => {
                  const errors = getFieldsError().reduce((res, curr) => {
                    const errorExceptRequired = curr.errors.filter(err => !err.includes('required'))
                    return {
                      ...res,
                      [curr.name[0]]: [...res[curr.name[0]] || [], ...errorExceptRequired]
                    }
                  }, {})
                  const isFormValid = Object.keys(errors).filter(key => errors[key].length > 0).length === 0

                  return (
                    stateTestData?.[stateId]?.testData ?
                      <StyledAlert
                        action={
                          <StyledButton size="small" onClick={onClickTest} disabled={(() => {
                            if (paramSchema) {
                              return !isFormValid || paramSchema
                                .filter(field => !field.getValueFunc && !field.allowEditByUser)
                                .reduce((acc: boolean, ps) => {
                                  const notValidArrayMap = !isValidArrayMapItem({ param: ps, value: form.getFieldValue(ps.key), stateTestData: stateTestData })
                                  // console.log('tes', notValidArrayMap)
                                  return ps.required && form.getFieldValue(ps.key) === '' || notValidArrayMap || acc
                                }, false)
                            }
                            return false // by default false
                          })()}
                          loading={testingState || updatingAScenarioState} htmlType="button"
                          >
                              Retest
                          </StyledButton>
                        }
                        type={stateTestData?.[stateId]?.realTestData?.success === false ? 'error' : 'success'}
                        showIcon
                        message={`${!isTrigger ? 'Action' : 'Trigger'} test ${stateTestData?.[stateId]?.realTestData?.success === false ? 'error' : 'success'}`} /> :
                      <StyledButton
                        htmlType="button"
                        type="primary"
                        onClick={onClickTest}
                        loading={testingState || updatingAScenarioState}
                        disabled={(() => {
                          if (paramSchema) {
                            return !isFormValid || paramSchema
                              .filter(field => !field.getValueFunc && !field.allowEditByUser)
                              .reduce((acc: boolean, ps) => ps.required && form.getFieldValue(ps.key) === '' ||
                                !isValidArrayMapItem({ param: ps, value: form.getFieldValue(ps.key), stateTestData: stateTestData }) || acc, false)
                          }
                          return false // by default false
                        })()}
                      >
                          Test {isTrigger ? 'Trigger' : 'Action'}
                      </StyledButton>
                  )

                }}
              </Form.Item>
            </CustomStyledFooterForm>

          </> :
          <>
            <Divider />
            <StyledContainerTestButton gutter={[16, 16]} justify={'space-between'} align="middle">
              {
                stateId && stateTestData?.[stateId]?.testData ?
                  <StyledSecondaryButton onClick={() => setShowTest(!showTest)}>{showTest ? 'Hide Sample' : 'Show Sample'}</StyledSecondaryButton> :
                  <div />
              }
              <Space>
                <p style={{ margin: 0 }}>Let’s test this code</p>
                {
                  stateId && stateTestData?.[stateId]?.testData ?
                    <StyledAlert
                      action={
                        <StyledButton size="small" onClick={onClickTest}
                          loading={loading} className="test"
                        >
                          Retest
                        </StyledButton>
                      }
                      style={{ float: 'right' }}
                      type={stateTestData?.[stateId]?.testData?.error ? 'error' : 'success'}
                      showIcon
                      message={stateTestData?.[stateId]?.testData?.error ? 'Test Code Failed' : 'Test Code Success'} /> :
                    <StyledButton
                      style={{ float: 'right' }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      onClick={onClickTest}
                      className="test"
                    >
                      Test
                    </StyledButton>
                }
              </Space>
            </StyledContainerTestButton>
          </>
      }
      {
        stateId && showTest ?
          <>
            <br />
            <StyledCard>
              <DataResponse number={stateOrder !== undefined ? stateOrder + 1 : undefined} data={stateTestData?.[stateId]?.testData} logoUrl={logoApplicationUrl} />
            </StyledCard>
          </>
          :
          null
      }
    </>
  )
}

export default TestSection

const StyledContainerTestButton = styled(Row)`
  padding-left: 8px;
  padding-right: 8px;
`
const CustomStyledFooterForm = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`