import { FieldSchema, Trigger } from 'db'
import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'



type ContextValueType = Partial<Trigger> & {
  activeEditFieldForm?: FieldSchema & { idx?: number },
  onAddFieldForm?: boolean
}

const SharedValueTriggerContext = createContext<ContextValueType | null>(null)
const SharedValueTriggerSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode
}

const SharedValueTriggerContextProvider: FC<Props> = ({ children }) => {
  const [sharedValueTrigger, setSharedValueTrigger] = useState<ContextValueType | null>(null)

  return (
    <SharedValueTriggerContext.Provider value={sharedValueTrigger}>
      <SharedValueTriggerSetterContext.Provider value={setSharedValueTrigger}>
        {children}
      </SharedValueTriggerSetterContext.Provider>
    </SharedValueTriggerContext.Provider>
  )
}


export function useSharedValueTrigger(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(SharedValueTriggerContext)
  const setterContext = useContext(SharedValueTriggerSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useSharedValueTrigger must be used on child component of SharedValueTriggerContextProvider component'
  }

  return [valueContext, setterContext]
}

export default SharedValueTriggerContextProvider