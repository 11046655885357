import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'
import { State } from 'db/dist/Model/State'
import { Action, Application, Trigger } from 'db'
import useSearchInput from './useSearchInput'
import CustomDataAccordion from './DataPickerPopper/CustomDataAccordion'
import { DataSuggestion } from '../../../developer/formItems/DataPickerPopper'
import { ResponseTest } from '../../../../../hooks/scenario/state/useStateTest'

export type StateWithTestData = Pick<State, 'id' | 'order'> & {
  stateName?: string,
  triggerActionName: string | undefined,
  application: Application | undefined,
  type?: Trigger['type'] | Action['type'],
  testData: Record<string, any>,
  realTestData?: ResponseTest,
  flatTestData: {
    [tag: string]: {
      sample: string,
      value: string,
      label: string,
      appLogoUrl?: string
    }
  }
}

export type DataPickerPopperProps = {
  statesWithTestData: Record<string, DataSuggestion> | null, // trigger and trigger.application should be expanded
  onTagClick: (tagValue: string) => void
}

const DataPickerPopper = forwardRef<HTMLDivElement, DataPickerPopperProps>(({ statesWithTestData, onTagClick }, ref) => {
  const { StyledSearchInput, searchQuery } = useSearchInput()

  return (
    <DataPickerPopperContainer ref={ref}>
      <SingleDataPickerPopperSearch>
        <h5>Insert data</h5>
        <CustomInputSearchData>
          <StyledSearchInput suffix={<SearchOutlined />} placeholder="Search data..." />
        </CustomInputSearchData>
      </SingleDataPickerPopperSearch>
      <CustomDataAccordion
        statesWithTestData={statesWithTestData}
        onTagClick={onTagClick}
        searchTerm={searchQuery}
      />
    </DataPickerPopperContainer>
  )
})

export default DataPickerPopper

const CustomInputSearchData = styled.div`
  & > .ant-input-affix-wrapper {
    background: rgba(186, 139, 66, 0.4);

    * {
      color: #FFF;
    }

    border: none;
    outline: none;

    &:focus {
      box-shadow: none;
    }

    input {
      background: rgba(186, 139, 66, 0);
      color: #FFF;
    }

    input::placeholder {
      color: #FFF;
      font-size: 12px;
    }
  }
`

const DataPickerPopperContainer = styled.div`
  display: flex;
  z-index: 99;
  flex-direction: column;
  border-radius: 4px;

  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  transition: all .24s;
`

const SingleDataPickerPopperSearch = styled.div`
  padding: 16px;
  background-color: #F3AD3D;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  h5 {
    color: #FFF;
  }
`