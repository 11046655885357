import { Bundle } from 'db'
import { useState } from 'react'
import { Implikasi } from '../../service/Implikasi'
import { Error, TableState } from '../../types'
import Notification from '../../util/Notification'
import { useRevalidateFetch } from '../useFetch'

type Result = {
  activeUpdateBundleId?: number,
  updateBundle: (bundleId: number, payload?: Partial<Bundle>) => Promise<any> | undefined,
  responseUpdatingBundle?: Bundle,
  isSuccessUpdatingBundle: boolean,
  updatingBundle: boolean,
  errorUpdateBundle?: Error
}

export default function useUpdateABundle(tableState?: TableState<Bundle>): Result {
  const { revalidateBundles } = useRevalidateFetch()
  const [responseUpdatingBundle, setResponseUpdatingBundle] = useState()
  const [updatingBundle, setUpdatingBundle] = useState<boolean>(false)
  const [errorUpdateBundle, setErrorUpdateBundle] = useState<Error>()
  const [isSuccessUpdatingBundle, setIsSuccessUpdatingBundle] = useState<boolean>(false)
  const [activeUpdateBundleId, setActiveUpdateBundleId] = useState<number>()

  const updateBundle = async (bundleId: number, payload?: Partial<Bundle>) => {
    if (bundleId) {
      setActiveUpdateBundleId(bundleId)
      try {
        setUpdatingBundle(true)
        const updateBundle = await Implikasi.patch(`/bundle/${bundleId}`, { bundle: payload })

        if (updateBundle.status === 200) {
          setIsSuccessUpdatingBundle(true)
        } else {
          setIsSuccessUpdatingBundle(false)
        }

        // revalidate any component that used useBundles
        revalidateBundles(tableState)

        setResponseUpdatingBundle(updateBundle.data)
        return updateBundle
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error
        })

        setErrorUpdateBundle(response?.data?.error)
      } finally {
        setUpdatingBundle(false)
        setActiveUpdateBundleId(undefined)
      }
    }
  }

  return {
    activeUpdateBundleId,
    updateBundle,
    isSuccessUpdatingBundle,
    responseUpdatingBundle,
    updatingBundle,
    errorUpdateBundle
  }
}