import { Card } from 'antd'
import { State, Trigger } from 'db'
import { createRef, FC, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { useStateTestData } from '../../../scenario/edit/StateTestDataContext'
import AlertArrayInfo from '../../../scenario/edit/TestDataArray/AlertArrayInfo'
import { isModalMounted } from '../recoil/scenario'
import { isStateActiveAtomFamily, stateBundleIdAtomFamily, stateEventAtom, stateStatus } from '../recoil/state'
import AddNewAction from './addNewAction/AddNewAction'
import EditStateEvent from './changeEvent/EditStateEvent'
import useChangeEvent from './changeEvent/useChangeEvent'
import StateContentCondition from '../../../scenario/edit/StateContentCondition'
import StateContentMain from './stateContent/StateContentMain'
import StateContentPaths from './stateContent/StateContentPaths'
import StateHeader from './StateHeader'

interface Props extends Partial<State> {
  pathStates?: State[],
  type: 'trigger' | 'action'
}

const StateForm: FC<Props> = ({ order, conditions, trigger, type, action, pathStates, conditionGroupOrder, id, scenarioId }) => {
  const { isOnEdit, isAdd, onCancel, onChangeEventId, onClickEdit, updatingEvent } = useChangeEvent()
  const refCard = createRef<any>()
  const { params } = useRouteMatch<{ scenarioId: string, stateId: string, isPath?: string }>()
  const isModalPathStateMounted = useRecoilValue(isModalMounted)
  const [isStateActive, setIsStateActive] = useRecoilState(isStateActiveAtomFamily(id))
  const event = useRecoilValue(stateEventAtom(id || 0))
  const bundleId = useRecoilValue(stateBundleIdAtomFamily(id || 0))
  const history = useHistory()
  const isUseAuthenticationId = (event || trigger || action)?.authenticationId
  const triggerType = type === 'trigger' ? event ? event.type : trigger?.type : undefined
  const actionId = type === 'action' ? (event || action)?.id : undefined
  const application = (event || action || trigger)?.application
  const actionName = (event || action)?.name
  const [stateTestData] = useStateTestData()
  const thisStateRealTestDataFromDB = stateTestData?.[id || 0]?.realTestData
  const setStatus = useSetRecoilState(stateStatus(id || 0))
  // console.log(triggerType)
  useEffect(() => {
    const status = {
      testTriggerAction: !!thisStateRealTestDataFromDB, testTriggerActionSuccess: thisStateRealTestDataFromDB?.success || false
    }
    setStatus(prevState => ({ ...prevState, ...status }))
  }, [thisStateRealTestDataFromDB])

  useEffect(() => {
    if (params.stateId) {
      if (params.stateId === `${id}` || pathStates?.find(s => `${s.id}` === params.stateId)) {
        setIsStateActive(isUseAuthenticationId && bundleId !== null && bundleId !== undefined || !isUseAuthenticationId)
        refCard.current?.scrollIntoView({ block: 'start' })
        if (isModalPathStateMounted) {
          setTimeout(() => {
            refCard.current?.scrollIntoView({ block: 'start' })
          }, 500)
        }
      }
    }
  }, [params.stateId, isModalPathStateMounted, bundleId, isUseAuthenticationId])

  const toggleStateFormBody = () => {
    if (params.stateId !== String(id)) {
      history.replace(`/app/template-scenario/editor/${scenarioId}/${id}${pathStates?.find(s => s.id === id) ? '/path': ''}`)
    }
    setIsStateActive(prevState => !prevState && (isUseAuthenticationId && bundleId !== null && bundleId !== undefined || !isUseAuthenticationId))
    // forcing codemirror to refresh because it's a fcking lazy library
    PubSub.publish(`cm-${id}-force-refresh`)
    // the shit i do for you codemirror 🤬
  }

  const hasConditions = Boolean(conditionGroupOrder && !pathStates)
  return (
    <StateContainer ref={refCard}>
      {
        isOnEdit ?
          <EditStateEvent
            className={isOnEdit ? '' : 'hidden'}
            isAdd={isAdd}
            submitting={updatingEvent}
            onCancel={onCancel}
            onChangeEventId={onChangeEventId}
          /> :
          null
      }
      <CustomCardState
        className={isOnEdit ? 'hidden' : ''}
        hasConditions={hasConditions}
        isStateFocus={params.stateId === `${id}` || params.isPath && pathStates}
        isStateActive={hasConditions || pathStates ? true : isStateActive}
        hasPaths={!!pathStates}
        title={<StateHeader toggleStateFormBody={toggleStateFormBody} onClickEdit={onClickEdit} pathStates={pathStates} />}
      >
        {
          pathStates ?
            <StateContentPaths pathStates={pathStates} /> :
            hasConditions ?
              <StateContentCondition
                actionId={actionId}
                actionName={actionName}
                stateId={id || 0}
                stateOrder={order || 0}
                application={application}
                scenarioId={scenarioId}
                conditions={conditions}
                setSetupActionStatus={setStatus}
                type="path"
              /> :
              <StateContentMain />
        }
      </CustomCardState>
      <AlertArrayInfo stateId={id} triggerType={triggerType as Trigger['type']} thisStateRealTestDataFromDB={thisStateRealTestDataFromDB} />
      {
        !pathStates ?
          <AddNewAction /> :
          null
      }
    </StateContainer>
  )
}

export default StateForm

const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// Why? Here: https://github.com/styled-components/styled-components/issues/305
// eslint-disable-next-line
const CustomCardState = styled(({ hasConditions, hasPaths, isStateActive, isStateFocus, children, ...nativeProps }) => <Card {...nativeProps}>{children}</Card>)<{  hasConditions?: boolean, hasPaths: boolean, isStateActive: boolean, isStateFocus?: boolean }>`
  width: 100%;
  margin-top: 12px;

  & > .ant-card-head {
    padding: 0;
    cursor: pointer;
  }

  & .ant-card-head-title {
    padding: 0;
  }

  & > .ant-card-body {
    padding: ${props => props.hasPaths ? '24px' : '24px 0'};
    display: ${props => props.hasConditions || props.hasPaths || props.isStateActive && props.isStateFocus ? 'block' : 'none'};
    background: ${props => props.hasPaths ? '#F0FBFF' : 'unset'};
  }
  opacity: ${props => props.isStateFocus ? 1 : 0.6};
  border: ${props => props.isStateFocus ? '2px solid #BEE06E' : '1px solid #F0F0F0'};
  border-radius: 4px;
  &:hover {
    opacity: 1;
  }
`