import { Checkbox, Layout } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import useAllAuthenticationSchema from '../../../../../../hooks/authenticationSchema/useAllAuthenticationSchema'
import PageHeader from '../../../../../components/PageHeader'
import { StyledButton, StyledFooterForm } from '../../../../../components/StyledComponents'
import { useSharedValues } from '../components/SharedValuesContextProvider'

const Front: FC = () => {
  const [checked, setChecked] = useState<string>()
  const { authenticationSchemas } = useAllAuthenticationSchema()
  const [_, setValues] = useSharedValues()
  const { push } = useHistory()
  const { url } = useRouteMatch()

  const onChange = (checkedValue: any[]) => {
    setChecked(checkedValue.pop())
  }

  const onSave = () => {
    const authenticationSchema = authenticationSchemas?.find(as => as.name === checked)
    setValues(prevState => ({ ...prevState,
      fieldSchema: authenticationSchema?.preBuildFields,
      authenticationSchema: authenticationSchema, authenticationSchemaId: authenticationSchema?.id }))
    push(`${url}/${encodeURIComponent(checked || '')}`)
  }

  useEffect(() => {
    setValues(null)
  }, [])

  return (
    <StyledFront>
      <PageHeader
        title="Add Authentication"
        description="Authentication lets users prove their identity to your app and authorize Implikasi to access their data, using your API authentication scheme."
      />
      <Checkbox.Group onChange={onChange} value={checked ? [checked] : undefined}>
        {
          authenticationSchemas?.map(option => {
            const { name, description } = option
            return (
              <Checkbox key={name} value={name} disabled={!authenticationSchemas?.find(authenticationSchema => authenticationSchema.name === name)} >
                <h5>{name}</h5>
                <p>{description}</p>
              </Checkbox>
            )
          })
        }
      </Checkbox.Group>
      <StyledFooterForm>
        <StyledButton type="primary" onClick={onSave}>Save</StyledButton>
      </StyledFooterForm>
    </StyledFront>
  )
}

export default Front

const StyledFront = styled(Layout.Content)`
  .ant-checkbox-group, ${StyledFooterForm} {
    padding: 0 24px 16px;
  }
  .ant-checkbox-wrapper {
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 30px;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-checkbox + span {
      padding-left: 16px;
    }
  }
  .ant-checkbox-wrapper-checked {
    border: 1px solid #2658C6;
  }
  .ant-checkbox-wrapper-disabled {
    background: #F5F5F5;
    /* Neutral/5 */
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 4px;
    h5 {
      color: #8C8C8C;
    }
    p {
      color: #BFBFBF;
    }
  }
`