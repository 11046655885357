import { Scenario, State } from 'db'
import { atom, selector, selectorFamily } from 'recoil'
import { TableState } from '../../../../../../types'

export const scenarioAtom = atom<Scenario | undefined>({
  key: 'scenario',
  default: undefined
})

export const allStatesSelector = selector<State[] | undefined>({
  key: 'allStates',
  get: ({ get }) => {
    const scenario = get(scenarioAtom)
    return scenario?.states
  },
})

export const statesInFirstLoadSelector = selector<State[] | undefined>({
  key: 'statesInFirstLoad',
  get: ({ get }) => {
    const scenario = get(scenarioAtom)
    return scenario?.states?.filter(state => !state.stateConditionId)
      .sort((s1, s2) => s1.order - s2.order) // make sure trigger action ordered by order properties
  }
})

// note: only helper for delete one path in path content states, not global
export const pathStatesOfStateSelector = selectorFamily<State[] | undefined, number>({
  key: 'pathStates',
  get: (stateId) => ({ get }) => {
    const scenario = get(scenarioAtom)
    const thisState = scenario?.states?.find((state) => state.id === stateId)
    return scenario?.states?.filter(state => thisState?.conditionGroupOrder === state.conditionGroupOrder && thisState?.parentId === state.parentId)
  }
})

export const thisPathSelector = selectorFamily<State | undefined, { stateId: number, isPath: boolean }>({
  key: 'thisPath',
  get: ({ stateId, isPath }) => ({ get }) => {
    const scenario = get(scenarioAtom)
    const states = scenario?.states
    const thisState = states?.find((state) => state.id === stateId)
    const thisPath = thisState?.conditionGroupOrder && !isPath ? states?.find(s => s.id === thisState.id) : states?.find(s => s.id === thisState?.stateConditionId)
    return thisPath
  }
})

export const statesOfThisPathSelector = selectorFamily<State[] | undefined, { stateId: number, isPath: boolean }>({
  key: 'statesOfThisPath',
  get: ({ stateId, isPath }) => ({ get }) => {
    const scenario = get(scenarioAtom)
    const states = scenario?.states
    const thisPath = get(thisPathSelector({ stateId, isPath }))
    return states?.filter(state => state.stateConditionId === thisPath?.id || state.id === thisPath?.id)
      .sort((s1, s2) => s1.order - s2.order) // make sure trigger action ordered by order properties
  }
})

export const isModalPathVisibleSelector = selectorFamily<boolean, { stateId?: string, isPath?: string }>({
  key: 'isModalPathVisible',
  get: (params) => ({ get }) => {
    const states = get(allStatesSelector)
    return !!states?.find(state => `${state.id}` === params.stateId)?.stateConditionId || !params.isPath && !!states?.find(state => `${state.id}` === params.stateId)?.conditionGroupOrder
  }
})

export const isModalMounted = atom<boolean>({
  key: 'isModalMounted',
  default: false
})

export const tableStateScenarioTemplates = atom<TableState<Scenario>>({
  key: 'tableStateScenarioTemplates',
  default: {
    sorter: [{ field: 'createdAt', order: 'descend' }, { field: 'createdAt', order: 'descend' }],
    pagination: {
      current: 1
    }
  }
})

export const aNextStateIdOfThisState = selectorFamily<number | undefined, number>({
  key: 'aNextStateIdOfThisState',
  get: (stateId) => ({ get }) => {
    const states = get(allStatesSelector)
    return states?.reduce((res: number | undefined , state, idx, states) => {
      if (state.id === stateId && states.length > idx + 1) {
        return states[idx + 1].id
      }
      return res
    }, undefined)
  }
})

export const isLastStateSelectorFamily = selectorFamily<boolean | undefined, number>({
  key: 'isLastStateSelectorFamily',
  get: (stateId) => ({ get }) => {
    const states = get(allStatesSelector)
    return states?.reduce((res: boolean, state, idx, states) => {
      if (state.id === stateId && idx === states.length - 1) {
        return true
      }
      return res
    }, false)
  }
})
export const currentStepAtom = atom<number>({
  key: 'currentStepAtom',
  default: 0
})