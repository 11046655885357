import { AxiosPromise } from 'axios'
import { Scenario } from 'db'
import { useApi } from '../useFetch'

type ApiScenarios = {
  getScenarios: (params: Partial<Scenario> & { expands?: string }) => AxiosPromise<{ scenarios: Scenario[] }>,
  fetchingScenarios: boolean
}

export default function useApiScenarios(): ApiScenarios {
  const { call, isValidating } = useApi<{ scenarios: Scenario[] }>()

  const getScenarios = (params: Partial<Scenario> & { expands?: string }) => {
    return call({
      url: '/scenarios',
      params: {
        ...params
      },
    })
  }

  return {
    getScenarios,
    fetchingScenarios: isValidating
  }
}