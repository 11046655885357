import { Bundle } from 'db'
import { useState } from 'react'
import { Implikasi } from '../../service/Implikasi'
import { Error, TableState } from '../../types'
import Notification from '../../util/Notification'
import { useRevalidateFetch } from '../useFetch'

type Result = {
  restoreBundle: (bundleId: number) => void,
  responseRestoringBundle?: Bundle,
  isSuccessRestoringBundle: boolean,
  restoringBundle: boolean,
  errorRestoreBundle?: Error
}

export default function useRestoreABundle(tableState?: TableState<Bundle>): Result {
  const { revalidateBundles } = useRevalidateFetch()
  const [responseRestoringBundle, setResponseRestoringBundle] = useState()
  const [restoringBundle, setRestoringBundle] = useState<boolean>(false)
  const [errorRestoreBundle, setErrorRestoreBundle] = useState<Error>()
  const [isSuccessRestoringBundle, setIsSuccessRestoringBundle] = useState<boolean>(false)

  const restoreBundle = async (bundleId: number) => {
    if (bundleId) {
      try {
        setRestoringBundle(true)
        const deleteBundle = await Implikasi.patch(`/bundle/${bundleId}/restore`, {})

        if (deleteBundle.status === 200) {
          setIsSuccessRestoringBundle(true)
        } else {
          setIsSuccessRestoringBundle(false)
        }

        // revalidate any component that used useBundles
        revalidateBundles(tableState)

        setResponseRestoringBundle(deleteBundle.data)
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error
        })

        setErrorRestoreBundle(response?.data?.error)
      } finally {
        setRestoringBundle(false)
      }
    }
  }

  return {
    restoreBundle,
    isSuccessRestoringBundle,
    responseRestoringBundle,
    restoringBundle,
    errorRestoreBundle
  }
}