import { PlusCircleTwoTone } from '@ant-design/icons'
import { Select } from 'antd'
import { FC, useState } from 'react'
import useAuthenticationTest from '../../../../hooks/application/authentication/useAuthenticationTest'
import useBundlesByApplicationId from '../../../../hooks/bundle/useBundlesByApplicationId'
import useCreateABundle from '../../../../hooks/bundle/useCreateABundle'
import { useRevalidateFetch } from '../../../../hooks/useFetch'
import { StyledButton } from '../../../../pages/components/StyledComponents'
import { IMPLIKASI_URL } from '../../../../util/Constant'
import Notification from '../../../../util/Notification'

interface Props {
  applicationId: string,
  authenticationId?: number,
  versionId?: number,
  setBundles: (bundle: any) => void
}


const SelectOrAddBundle: FC<Props> = ({ applicationId, authenticationId, versionId, setBundles }) => {
  const { revalidateBundlesByApplicationId } = useRevalidateFetch()
  const { bundlesByApplicationId, fetchingBundlesByApplicationId } = useBundlesByApplicationId(applicationId)
  const { createBundle, creatingBundle } = useCreateABundle()
  const [isTryingToLogin, setIsTryingToLogin] = useState(false)
  const { testAuthentication, testingAuthentication } = useAuthenticationTest(applicationId, authenticationId, versionId)
  const loading = fetchingBundlesByApplicationId || creatingBundle || isTryingToLogin || testingAuthentication
  const [selected, setSelected] = useState<number>()

  const onMouseEnter = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onChangeAccount = (value: number, option?: any) => {
    setSelected(value)
    const bundle = bundlesByApplicationId?.find(bundle => bundle.id === value)
    if (bundle) {
      setBundles({
        authData: bundle.authData,
        bundle: bundle.bundle
      })
    }
    if (option) {
      testAuthentication(undefined, value)?.catch(() => Notification.error({
        message: 'Failed to test your account',
        description: 'Please reconnect your account in page myscenario/accounts'
      }))
    }
  }

  const onAddAccount = (e?: any) => {
    // open new window, login
    if (e) {
      onMouseEnter(e)
    }
    setIsTryingToLogin(true)
    const windowAuth = window.open(`${IMPLIKASI_URL}/${applicationId}/run/auth/${authenticationId}?versionId=${versionId}`, '_blank', 'height=600,width=800,centerscreen,chrome=yes')

    const timer = setInterval(function () {
      if(windowAuth?.closed) {
        clearInterval(timer)
        setIsTryingToLogin(false)
      }
    }, 1000)
    if (windowAuth) {
      windowAuth.onbeforeunload = () => {
        const windowAuthAsAny = windowAuth as any
        if (windowAuthAsAny.bundles) {

          const { nameAccountOtomatis, ...dataPayload } = windowAuthAsAny.bundles
          const editableName = nameAccountOtomatis // possibly undefined because it is optional field

          const payload = { editableName, ...dataPayload }
          if (payload) {
            postBundle(payload, parseInt(applicationId), versionId)
          }
          windowAuthAsAny.bundles = undefined
        }
      }
    }
  }

  const postBundle = (payload?: any, applicationId?: number, versionId?: number) => {
    if (payload && versionId && applicationId) {
      Notification.info({
        message: 'Creating..'
      })
      createBundle({
        ...payload,
        versionId: versionId,
        applicationId: applicationId,
      }).then(async ({ data }) => {
        if (data.bundle.id) {
          const test = await testAuthentication(undefined, data.bundle.id)
          if (test) {
            revalidateBundlesByApplicationId(Number(applicationId))
            onChangeAccount(data.bundle.id)
            // setSelectedAccountId(data.bundle.id)
            // openStateForm()
            setBundles({
              authData: data.bundle.authData,
              bundle: data.bundle.bundle
            })
          }
          if (test?.data?.error) {
            Notification.error({
              message: 'Failed on test account',
              description: JSON.stringify(test.data)
            })
          }
          setIsTryingToLogin(false)
        }
      }).catch((e: any) => {
        Notification.error({
          message: 'Failed on test account',
          description: JSON.stringify(e)
        })
        setIsTryingToLogin(false)
      })
    } else {
      Notification.error({
        message: 'Something\'s Wrong',
        description: `Data missing ${JSON.stringify({ applicationId, versionId, payload: !!payload })}`
      })
    }
  }

  return (
    <Select
      dropdownRender={menu =>
        <div>
          {menu}
          <StyledButton icon={<PlusCircleTwoTone />} onClick={onAddAccount} type="link">Add More Account</StyledButton>
        </div>
      }
      onClick={onMouseEnter}
      style={{ width: 200 }}
      loading={loading}
      disabled={loading}
      placeholder="Select Account"
      onChange={onChangeAccount}
      value={selected}
    >
      {
        bundlesByApplicationId?.map(bundle => <Select.Option key={bundle.id} value={bundle.id}>{bundle.editableName || bundle.name}</Select.Option>)
      }
    </Select>

  )
}

export default SelectOrAddBundle