import { Col, Row, Space } from 'antd'
import { State } from 'db'
import { FC } from 'react'
import StateHeaderInfo from '../../../editor/State/StateHeader/StateHeaderInfo'
import StateStatusInfo from '../../../editor/State/StateHeader/StateStatusInfo'
import { useStateValue } from '../../../editor/State/StateValueContextProvider'

interface Props {
  toggleStateFormBody: () => void,
  onClickEdit: (isAdd?: boolean) => void,
  pathStates?: State[]
}
const StateHeaderViewer: FC<Props> = ({ pathStates, toggleStateFormBody }) => {
  const [stateValue] = useStateValue()
  const { conditionGroupOrder } = stateValue
  const hasConditions = Boolean(conditionGroupOrder && !pathStates)

  return (
    <Row style={{ padding: '16px' }} gutter={[16, 16]} justify="space-between" align="middle" onClick={toggleStateFormBody}>
      <Col>
        <StateHeaderInfo
          notAllowedEditName={true}
        />
      </Col>
      {
        hasConditions ?
          null :
          <Col>
            <Space>
              <StateStatusInfo pathStates={pathStates} />
            </Space>
          </Col>
      }
    </Row>
  )
}

export default StateHeaderViewer