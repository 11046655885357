import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Avatar, Badge, Col, Divider, Dropdown, Menu, Row, Space, Tag } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import AnchorWrapper from '../../../../components/AnchorWrapper'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import useAllAction from '../../../../hooks/application/action/useAllAction'
import useApplication from '../../../../hooks/application/main/useApplication'
import useExportApplication from '../../../../hooks/application/main/useExportApplication'
import useSubmitApplicationToPublic from '../../../../hooks/application/main/useSubmitApplicationToPublic'
import useUpdateApplication from '../../../../hooks/application/main/useUpdateApplication'
import useAllTrigger from '../../../../hooks/application/trigger/useAllTrigger'
import { getInitialName } from '../../../../util/formatter'
import { StyledButton, StyledText } from '../../../components/StyledComponents'
import SVGIcon from '../../../components/SVGIcon'
import { useApplicationVersionContext } from '../../application/contexts/ApplicationVersionContextProvider'
import { baseApplicationRoute } from '../../application/routes'

const ApplicationDetailSection: FC = () => {
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()
  const { application, revalidateApplication } = useApplication(applicationId)
  const [version, setVersion] = useApplicationVersionContext()
  const { triggers } = useAllTrigger(version?.id ? applicationId : undefined, version?.id)
  const { actions } = useAllAction(version?.id ? applicationId: undefined, version?.id)
  const { push, replace } = useHistory()
  const { updateApplication, updatingApplication, updatedApplication } = useUpdateApplication()
  const { submitApplicationToPublic, submitingApplication } = useSubmitApplicationToPublic()
  const { exportApplication, exportAppToMd } = useExportApplication()
  const [loggedInUser] = useLoggedInUser()

  const onClickExportToMd = () => {
    if (applicationId && version?.id) {
      exportAppToMd(parseInt(applicationId), version?.id)
    }
  }

  const onClickExport = () => {
    exportApplication(parseInt(applicationId))
  }

  function handleMenuVersionClick(e: any) {
    if (e.key === 'all') {
      push(`${baseApplicationRoute}/${applicationId}/${versionName}/main/version`)
    } else if (application?.versions) {
      setVersion(application.versions[e.key])
      push(`${baseApplicationRoute}/${applicationId}/${application.versions[e.key]?.name}/main`)
    }
  }

  useEffect(() => {
    const activeVersion = application?.versions ? application.versions.find(version => version.name === versionName) : undefined
    if (activeVersion && !version) {
      setVersion(activeVersion)
    }
    if (application && !activeVersion) {
      replace(`${baseApplicationRoute}/${applicationId}/${application?.versions?.[0]?.name}/main`)
      setVersion(application.versions?.[0] || null)
    }
  }, [application])

  const onSubmitToReview = () => {
    confirm({
      title: `Are you sure you want to Submit ${application?.name} for Review?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Your Application will be reviewed by Admin. You’ll get notified if there’s update to your application',
      okText: 'Yes, Submit',
      onOk() {
        return new Promise((resolve, reject) => {
          updateApplication(applicationId, {
            submittedAt: new Date()
          }).then(() => {
            resolve(null)
            push(`${baseApplicationRoute}/${applicationId}/${versionName}/main/submit-app`)
          }).catch(() => reject)
        })
          // .catch(() => console.log('Oops errors!'))
          .catch(() => {})
      },
      onCancel() {},
    })
  }

  const onSubmitPublish = () => {
    confirm({
      title: `Are you sure you want to publish ${application?.name}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Otomatis’ users will be able to use your Application',
      okText: 'Yes, Publish',
      onOk() {
        const prevActiveVersion = application?.versions?.find(v => v.isActive)?.id
        if (version?.id) {
          return submitApplicationToPublic(parseInt(applicationId), version?.id, prevActiveVersion, application?.name)
        }
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (updatedApplication) {
      revalidateApplication()
    }
  }, [updatedApplication])

  const menuVersion = <Menu onClick={handleMenuVersionClick}>
    {application?.versions?.map((version, idx) => <Menu.Item key={idx}>
      {version.name}
    </Menu.Item>)}
    <Menu.Item key="all" >
      <AnchorWrapper href={`${baseApplicationRoute}/${applicationId}/${versionName}/main/version`}>
        View All Version
      </AnchorWrapper>
    </Menu.Item>
  </Menu>

  return (
    <StyledApplicationDetailSectionContainer>
      <Row gutter={[16, 16]} align="middle">
        <Col span={8}>
          <Badge dot={true}>
            <a href="#" className="head-example">
              {
                application?.logoUrl ?
                  <SVGIcon src={application?.logoUrl || '/logo192.png'} width="40px" height="40px" /> :
                  <Avatar icon={getInitialName(application?.name)} size={40} />
              }
            </a>
          </Badge>
        </Col>
        <Col span={16}>
          <Space direction="vertical" style={{ display: 'block' }}>
            <StyledText ellipsis={true} title={application?.name} style={{ display: 'block', maxWidth: 120 }}>{application?.name}</StyledText>
            <Tag color={application?.public ? 'green' : 'geekblue'}>{application?.public ? 'LIVE' : 'private'}</Tag>
          </Space>
        </Col>
      </Row>
      {
        application?.publishedAt ?
          application?.public && version?.isActive ? null :
            <Row gutter={[16, 16]}>
              <StyledButton type="primary" ghost onClick={onSubmitPublish} loading={submitingApplication}>Publish</StyledButton>
            </Row>
          :
          application?.submittedAt ?
            <Row gutter={[16, 16]}>
              <Tag color="geekblue" style={{ width: '100%', textAlign: 'center' }}>On Review</Tag>
            </Row>
            :
            <Row gutter={[16, 16]}>
              <StyledButton type="primary" title="Your app must at least has 1 trigger or has 1 action" disabled={triggers?.length === 0 && actions?.length === 0} ghost loading={updatingApplication} onClick={onSubmitToReview}>Submit to Get Review</StyledButton>
            </Row>
      }
      <Row gutter={[16, 16]}>
        <StyledButton onClick={onClickExport} >Export</StyledButton>
        {
          loggedInUser?.isAdmin && application?.public ?
            <StyledButton onClick={onClickExportToMd} >Export to MD</StyledButton>
            : null
        }
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col>
          <StyledText>Version</StyledText>
        </Col>
        <Col>
          <Dropdown overlayStyle={{ maxHeight: 300, overflowY: 'auto' }} overlay={menuVersion}>
            <StyledButton>
              {version?.name} <DownOutlined />
            </StyledButton>
          </Dropdown>
        </Col>
      </Row>
      <Divider />
    </StyledApplicationDetailSectionContainer>
  )
}

export default ApplicationDetailSection

const StyledApplicationDetailSectionContainer = styled.div`
  padding: 0 16px;
  .head-example {
    width: 42px;
    height: 42px;
    border-radius: 2px;
    background: #eee;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
  ${StyledButton} {
    width: 100%;
  }

  .ant-divider-horizontal {
    margin: 16px 0;  
  }

  .ant-row {
    margin-top: 16px;
  }
`