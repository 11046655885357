import { PlusOutlined } from '@ant-design/icons'
import { ButtonProps } from 'antd'
import React, { FC, useState } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { useLoggedInUser } from '../../../../../../../contexts/LoggedInUserContextProvider'
import useAPricingPlan from '../../../../../../../hooks/pricingPlan/useAPricingPlan'
import { StyledSecondaryButton } from '../../../../../../../pages/components/StyledComponents'
import useOpenCloseModal from '../../../../../../../util/useOpenCloseModal'
import ChooseNewActionTypeModal from '../../../../scenario/edit/ChooseNewActionTypeModal'
import { scenarioAtom } from '../../recoil/scenario'
import { stateEventAtom } from '../../recoil/state'
import { useStateValue } from '../StateValueContextProvider'
import ChooseActionAppAndEvent from './ChooseActionAppAndEvent'
import ChooseConditionEvent from './ChooseConditionEvent'

interface AddNewActionButtonModalProps extends ButtonProps {
}

type ActionType = 'app' | 'condition' | 'transform' | 'delay' | number

const AddNewAction: FC<AddNewActionButtonModalProps> = ({ ...otherProps }) => {
  const [newActionType, setNewActionType] = useState<ActionType>() // default value will be undefined
  const [loggedInUser] = useLoggedInUser()
  const { pricingPlan } = useAPricingPlan(loggedInUser?.pricingPlanId)
  const { closeModal, openModal, visible } = useOpenCloseModal()
  const scenario = useRecoilValue(scenarioAtom)
  const [state] = useStateValue()
  const { triggerId, actionId } = state

  const event = useRecoilValue(stateEventAtom(state.id))

  const resetActionType = () => setNewActionType(undefined) // reset with undefined 👍

  const onSubmitNewActionType = async (values: any) => {
    setNewActionType(values.actionType)
    closeModal()
  }

  const isLastState = scenario?.states?.reduce((res, s, _, states) => {
    if (s.id === state?.id && !states.find(currState => currState.order > s.order && (!currState.stateConditionId || (currState.stateConditionId === s.stateConditionId || currState.stateConditionId === s.id)))) {
      return true
    }
    return res
  }, false) && pricingPlan?.isConditionalStep

  return (
    <>
      {
        state ?
          <AddActionButton
            onClick={openModal}
            icon={<PlusOutlined />}
            disabled={!event && !triggerId && !actionId}
            {...otherProps}
          >
            {isLastState ? 'Add action/condition' : 'Add Action'}
          </AddActionButton> :
          <AddActionButton
            icon={<PlusOutlined />}
            disabled
          >
            {isLastState ? 'Add action/condition' : 'Add Action'}
          </AddActionButton>
      }

      {
        newActionType === 'app' && state && scenario ?
          <>
            <ChooseActionAppAndEvent
              stateConditionId={state.conditionGroupOrder ? state.id : state.stateConditionId ? state.stateConditionId : undefined}
              onCancel={resetActionType}
              onSuccessCreateNewState={resetActionType}
            />

            {/* not-usable-ghost button just to make sure the visual aligned */}
            <AddActionButton
              icon={<PlusOutlined />}
              type="primary"
              ghost
              disabled
            >
              {isLastState ? 'Add action/condition' : 'Add Action'}
            </AddActionButton>
          </> : null
      }

      {
        newActionType === 'condition' && state && scenario ?
          <>
            <ChooseConditionEvent
              stateConditionId={state.conditionGroupOrder ? state.id : state.stateConditionId ? state.stateConditionId : undefined}
              onSuccessCreateNewState={resetActionType}
            />
          </> : null
      }

      {
        typeof newActionType === 'number' && state && scenario ?
          <>
            <ChooseActionAppAndEvent
              applicationId={newActionType}
              stateConditionId={state.conditionGroupOrder ? state.id : state.stateConditionId ? state.stateConditionId : undefined}
              onCancel={resetActionType}
              onSuccessCreateNewState={resetActionType}
            />

            {/* not-usable-ghost button just to make sure the visual aligned */}
            <AddActionButton
              icon={<PlusOutlined />}
              type="primary"
              ghost
              disabled
            >
              {isLastState ? 'Add action/condition' : 'Add Action'}
            </AddActionButton>
          </> : null
      }

      {/* Pop-up modal to ask customer which type of action/condition they want to add */}
      <ChooseNewActionTypeModal isLastState={isLastState} visible={visible} onClose={closeModal} onSubmit={onSubmitNewActionType} />
    </>
  )
}

export default AddNewAction

const AddActionButton = styled(StyledSecondaryButton)`
  margin-top: 12px;
  background: #FFF !important;
  &[disabled] {
    background: #f5f5f5 !important
  }
  &:hover {
    outline: 1.5px solid;
  }
`