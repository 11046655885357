import { Card, Col, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import useCreateAScenarioState from '../../../../../../../hooks/scenario/state/useCreateAScenarioState'
import useUpdateAScenarioState from '../../../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../../../hooks/useFetch'
import StateSkeleton from '../../../../scenario/edit/StateSkeleton'
import { allStatesSelector } from '../../recoil/scenario'
import { nextStateOfThisStateSelector } from '../../recoil/state'
import FormStateEvent from '../changeEvent/FormStateEvent'
import { useStateValue } from '../StateValueContextProvider'

interface ChooseActionAppAndEventProps {
  applicationId?: number, // untuk new applicationId
  stateConditionId?: number, // untuk new stateConditionId
  isLastState?: boolean,
  onCancel: () => void,
  onSuccessCreateNewState: () => void
}

const ChooseActionAppAndEvent: FC<ChooseActionAppAndEventProps> = ({ applicationId, stateConditionId, onSuccessCreateNewState, onCancel }) => {
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const [form] = useForm()
  const { createAScenarioState, creatingAScenarioState, createdAScenarioState } = useCreateAScenarioState()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const [updatingOrderStates, setUpdatingOrderStates] = useState(false)
  const [stateValue] = useStateValue()
  const { id, order, scenarioId, childrenIds: childrenStateIds } = stateValue
  const parentStateId = id
  const states = useRecoilValue(allStatesSelector)
  const history = useHistory()
  const [refetchingScenario, setRefetchingSchenario] = useState(false)

  useEffect(() => {
    const newState = states?.find((state) => state.id === createdAScenarioState?.id)
    if (newState) {
      setRefetchingSchenario(false)
      onSuccessCreateNewState()
      history.replace(`/app/template-scenario/editor/${scenarioId}/${createdAScenarioState?.id}`)
    }
  }, [createdAScenarioState, states])


  const nextStateIds = useRecoilValue(nextStateOfThisStateSelector(id))

  const onFinish = async (values: any) => {
    const newStateId = await createAScenarioState(scenarioId, {
      name: values.name,
      parentId: parentStateId,
      childrenIds: childrenStateIds,
      stateConditionId: stateConditionId,
      order: order + 1, // increment new state order
      actionId: values.eventId
    }).then(({ data }) => {
      setUpdatingOrderStates(true)
      return data.state.id
    })
    if (parentStateId && newStateId) {
      updateAScenarioState(scenarioId, parentStateId, {
        childrenIds: [newStateId]
      }).then(async () => {
        if (nextStateIds && newStateId) {
          await Promise.all(
            nextStateIds.map(async (state) =>
              await updateAScenarioState(scenarioId, state.id, {
                parentId: childrenStateIds?.includes(state.id) ? newStateId : undefined,
                order: state.order + 1,
                conditionGroupOrder: state.conditionGroupOrder ? state.conditionGroupOrder + 1 : state.conditionGroupOrder
              }).then(({ data }) => data)
            )
          ).then(() => {
            setUpdatingOrderStates(false)
            setRefetchingSchenario(true)
          })
        }
      })
    }
  }

  useEffect(() => {
    // new state created
    if (createdAScenarioState && !creatingAScenarioState && !updatingOrderStates) {
      // console.log('new state: ', createdAScenarioState)
      revalidateAScenario(scenarioId) // revalidate the scenario to fetch the new state
      if (nextStateIds) {
        // revalidate all next states
        for (const state of nextStateIds) {
          revalidateAScenarioState(scenarioId, state.id)
        }
      }
    }
  }, [updatingOrderStates])

  if (refetchingScenario) {
    return <StateSkeleton />
  }

  return (
    <StyledCardCreateStateActionForm>
      <Row>
        <Col flex="auto">
          <FormStateEvent
            form={form}
            buttonCancelProps={{
              onClick: onCancel,
            }}
            buttonSaveProps={{
              loading: creatingAScenarioState || updatingOrderStates
            }}
            onFinish={onFinish}
            title={'Add Action'}
            type={'action'}
            applicationId={applicationId}
          />
        </Col>
      </Row>
    </StyledCardCreateStateActionForm>
  )
}

const StyledCardCreateStateActionForm = styled(Card)`
  width: 100%;
  margin-top: 12px;
`

export default ChooseActionAppAndEvent