import { PlusOutlined } from '@ant-design/icons'
import { Col, Modal, Row, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FieldSchema } from 'db'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import MarkdownRenderer from '../../../../../../../../components/MarkdownRenderer'
import ImportFields from '../../../../../../../../components/pages/developer/main/ImportFields'
import useCreateOrUpdateTrigger from '../../../../../../../../hooks/application/trigger/useCreateOrUpdateTrigger'
import { useRevalidateFetch } from '../../../../../../../../hooks/useFetch'
import { StyledButton, StyledCard, StyledFooterForm, StyledLabel } from '../../../../../../../components/StyledComponents'
import MonacoEditor from '../../../../../../components/form/MonacoEditor'
import DynamicFieldForm, { DynamicFields } from '../../../../../components/DynamicFieldForm'
import FormParamSchema from '../../../../../components/FormParamSchema'
import { useApplicationVersionContext } from '../../../../../contexts/ApplicationVersionContextProvider'
import { PropsContentStep } from '../../data'
import { useSharedValueTrigger } from '../../SharedValueTriggerContextProvider'
import FieldForm from './FieldForm'
import TableFormItems from './TableFormItems'

const FormEditor: FC<PropsContentStep> = ({ isActive, onContinue, onPrevious }) => {
  const { revalidateTrigger } = useRevalidateFetch()
  const [values] = useSharedValueTrigger()
  const [onAddFieldForm, setOnAddFieldForm] = useState(false)
  const [activeEditFieldForm, setActiveEditFieldForm] = useState<FieldSchema & { idx?: number }>()
  const isFormActive  = onAddFieldForm || activeEditFieldForm
  const [isDynamicFormActive, setIsDynamicFormActive] = useState(false)
  const [version] = useApplicationVersionContext()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { createOrUpdateTrigger, creatingTrigger } = useCreateOrUpdateTrigger()
  const [modalOpen, setModalOpen] = useState(false)
  const [form] = useForm()
  const [testGuideline, setTestGuideline] = useState<string>()

  useEffect(() => {
    if (values?.testGuideline && testGuideline !== values.testGuideline) {
      setTestGuideline(values?.testGuideline)
    }
  }, [values?.testGuideline])

  const onAdd = () => {
    setOnAddFieldForm(true)
  }

  const onShowPreview = () => {
    setModalOpen(true)
  }

  const onSaveAndContinue = async () => {
    const newTrigger = await createOrUpdateTrigger(applicationId,
      { ...values, testGuideline, isDraft: values?.isDraft === undefined ? true : values.isDraft,
        versionId: values?.versionId || version?.id })

    if (newTrigger) {
      revalidateTrigger(applicationId, values?.id, values?.versionId || version?.id)
    }
    onContinue()
  }

  const onClickDynamicForm = () => {
    setIsDynamicFormActive(true)
    onAdd()
  }

  const onCancel = () => {
    setIsDynamicFormActive(false)
    setOnAddFieldForm(false)
    setActiveEditFieldForm(undefined)
  }

  const onSave = async (valueParamFunc?: DynamicFields) => {
    const newValues = {
      key: '',
      label: 'Dynamic Field',
      type: 'Dynamic Field',
      required: false,
      ...valueParamFunc
    }
    let newParamSchema: FieldSchema[]
    if (activeEditFieldForm && values?.paramSchema) {
      newParamSchema = values.paramSchema.map((field, idx) => idx === activeEditFieldForm?.idx ? newValues as FieldSchema : field)
      // setValues(prevState => ({ ...prevState, paramSchema: newParamSchema, onAddFieldForm: false, activeEditFieldForm: undefined }))
    } else {
      newParamSchema = values?.paramSchema ? [...values.paramSchema, newValues as FieldSchema] : [newValues as FieldSchema]
      // setValues(prevState => ({ ...prevState, paramSchema: newParamSchema, onAddFieldForm: false, activeEditFieldForm: undefined }))
    }
    const newTrigger = await createOrUpdateTrigger(applicationId,
      { ...values, testGuideline, paramSchema: newParamSchema, isDraft: values?.isDraft === undefined ? true : values.isDraft,
        versionId: values?.versionId || version?.id })

    if (newTrigger) {
      revalidateTrigger(applicationId, values?.id, values?.versionId || version?.id)
      onCancel()
    }
    // setIsDynamicFormActive(false)
  }

  const onImport = async (fields?: FieldSchema[]) => {
    const newParamSchema = [...values?.paramSchema || [], ...fields || []]
    const newTrigger = await createOrUpdateTrigger(applicationId,
      { ...values, testGuideline, paramSchema: newParamSchema, isDraft: values?.isDraft === undefined ? true : values.isDraft,
        versionId: values?.versionId || version?.id })

    if (newTrigger) {
      revalidateTrigger(applicationId, values?.id, values?.versionId || version?.id)
      onCancel()
    }
  }
  return (
    <StyledFormEditor className={isActive ? 'show' : undefined}>
      <Row justify="space-between" align="middle">
        <Col>
          <h4>Form Editor</h4>
        </Col>
        <Col>
          {
            isDynamicFormActive || isFormActive ? null :
              <Space>
                <ImportFields onImport={onImport} existedFields={values?.paramSchema} />
                <StyledButton onClick={onClickDynamicForm} type="link">Add Dynamic Field</StyledButton>
                <StyledButton onClick={onAdd} icon={<PlusOutlined />} ghost type="primary">Add User Input Field</StyledButton>
              </Space>
          }
        </Col>
      </Row>
      <br />
      {
        isFormActive && (!isDynamicFormActive && !activeEditFieldForm?.paramSchemaFunc) ?
          <FieldForm onCancel={onCancel} activeEditFieldForm={activeEditFieldForm} /> :
          isFormActive && (isDynamicFormActive || activeEditFieldForm?.paramSchemaFunc) ?
            <DynamicFieldForm onSave={onSave} saving={creatingTrigger} onCancel={onCancel} initialValue={{ key: activeEditFieldForm?.key || '', paramSchemaFunc: activeEditFieldForm?.paramSchemaFunc }} /> :
            <>
              <TableFormItems setActiveEditFieldForm={setActiveEditFieldForm} />
              <br />
              <StyledLabel>Test Guideline</StyledLabel>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <MonacoEditor value={testGuideline} defaultLanguage='markdown' onChange={(e) => setTestGuideline(e)} />
                </Col>
                <Col span={12}>
                  <StyledReactMarkDown>{testGuideline || ''}</StyledReactMarkDown>
                </Col>
              </Row>
              <br />
              <br />
              <StyledButton onClick={onShowPreview} type="primary" ghost>Form Preview</StyledButton>
              <StyledFooterForm>
                <StyledButton onClick={onPrevious}>Previous</StyledButton>
                <StyledButton type="primary" loading={creatingTrigger} onClick={onSaveAndContinue}>{'Save & Continue'}</StyledButton>
              </StyledFooterForm>
            </>
      }
      <Modal visible={modalOpen} title="Form Preview" onOk={() => setModalOpen(false)} onCancel={() => setModalOpen(false)}>
        <FormParamSchema form={form} initialParamSchema={values?.paramSchema} applicationId={Number(applicationId)}>
        </FormParamSchema>
      </Modal>
    </StyledFormEditor>
  )
}

export default FormEditor

const StyledFormEditor = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;
  .row-dragging {
    color: red;
  }
`
const StyledReactMarkDown = styled(MarkdownRenderer)`
  resize: vertical;
  height: 360px;
  min-height: 360px;
  overflow-y: auto;
  width: 100%;

  padding: 10px;
  background: #FAFAFA;
  font-family: 'DM Sans', sans-serif;
  
  color: rgba(0, 0, 0, 0.65);
`