import { AxiosPromise } from 'axios'
import { useApi } from '../useFetch'

type Result = {
  checkout: () => AxiosPromise<any>,
  checkoutingPayment: boolean,
  errorCheckoutPayment?: any
}

export default function useNewCheckoutPayment(): Result {
  const { call, isValidating, error } = useApi<any>()

  return {
    checkout: () => {
      return call({
        url: '/payment/customer',
        method: 'post',
      })
    },
    checkoutingPayment: isValidating,
    errorCheckoutPayment: error
  }
}