import { createGlobalStyle } from 'styled-components'

const TooltipGlobalStyle = createGlobalStyle`
  .ant-tooltip.custom-tooltip {
    width: 100%;
    * {
      box-shadow: none;
    }
    .ant-tooltip-content {
      width: 100%;
      position: absolute;
    }
    .ant-tooltip-arrow-content {
      border: 1px solid #F0F0F0;
      width: 10px;
      height: 10px;
    }
    .ant-tooltip-inner {
      color: #000;
      padding: 8px;
      border: 1px solid #F0F0F0;
      border-radius: 4px;
      background: white;
    }
    &.ant-tooltip-placement-top,
    &.ant-tooltip-placement-topLeft, 
    &.ant-tooltip-placement-topRight {
      .ant-tooltip-arrow {
        bottom: -12px;
      }
    }
    &.ant-tooltip-placement-bottom,
    &.ant-tooltip-placement-bottomLeft, 
    &.ant-tooltip-placement-bottomRight {
      .ant-tooltip-arrow {
        top: -12px;
      }
    }
  }
`
export default TooltipGlobalStyle