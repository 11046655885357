import { State, Trigger } from 'db'
import { atom, atomFamily, selectorFamily } from 'recoil'
import { ChildStateType } from '../../../scenario/edit/StateTestDataContext'

export const stateAtomFamily = atomFamily<State | undefined, number>({
  key: 'state',
  default: undefined
})

export const stateNameAtom = atomFamily<State['name'], number>({
  key: 'stateName',
  default: undefined
})

export const stateEventAtom = atomFamily<State['trigger'] | State['action'], number>({
  key: 'stateEvent',
  default: undefined
})

export const stateEventAsTrigger = selectorFamily<State['trigger'], number>({
  key: 'stateEventAsTrigger',
  get: (stateId) => ({ get }) => {
    const event = get(stateEventAtom(stateId))
    return event as State['trigger']
  }
})
export const stateAuthIdSF = selectorFamily<Trigger['authenticationId'], number>({
  key: 'stateAuthId',
  get: (stateId) => ({ get }) => {
    const event = get(stateEventAtom(stateId))
    return event?.authenticationId
  }
})

export const stateApplicationLogoUrl = selectorFamily<string | undefined, number>({
  key: 'appLogoUrl',
  get: (stateId) => ({ get }) => {
    const event = get(stateEventAtom(stateId))
    return event?.application?.logoUrl
  }
})

export const nextStatesAllAtom = atom<Record<number, ChildStateType[]> | null>({
  key: 'nextStatesAll',
  default: null
})

export const nextStateOfThisStateSelector = selectorFamily<ChildStateType[] | undefined, number>({
  key: 'getSpecificNextStates',
  get: (stateId) => ({ get }) => {
    const nextStateIdsAll = get(nextStatesAllAtom)
    return nextStateIdsAll &&  stateId ? nextStateIdsAll[stateId] : undefined
  }
})

export const stateBundleIdAtomFamily = atomFamily<State['bundleId'], number>({
  key: 'stateBundleId',
  default: undefined
})

export const isStateActiveAtomFamily = atomFamily<boolean, number | undefined>({
  key: 'stateActive',
  default: false
})

interface StateStatus {
  chooseAccount: boolean, testTriggerAction: boolean, testTriggerActionSuccess: boolean
}
export const stateStatus = atomFamily<StateStatus, number>({
  key: 'stateStatus',
  default: {
    chooseAccount: false, testTriggerAction: false, testTriggerActionSuccess: false
  }
})