import * as Sentry from '@sentry/react'
import { Action, Application, FieldSchema, State, Trigger } from 'db'
import { StateWithTestData } from '../components/pages/app/scenario/edit/DataPickerPopper'
import { DataSuggestion } from '../components/pages/developer/formItems/DataPickerPopper'
import { WEB_VERSION } from './Constant'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function notifySentry(err?: any, errInfo?: any): void {
  console.log('Error in browser. Send report to Sentry...', err, errInfo)
  Sentry.configureScope(scope => {
    scope.setTag('version_web', WEB_VERSION)
    scope.setTag('otomatis_user', localStorage.getItem('otomatis_user'))
    if (errInfo) {
      Object.keys(errInfo).forEach((key) => {
        scope.setExtra(key, errInfo[key])
      })
    }
    Sentry.captureException(err)
  })
}


export function isValidFormValues(formValues?: Record<string, any>, paramSchema?: FieldSchema[]): boolean {
  if (paramSchema) {
    const jsonInputFields = paramSchema.filter(field => field.type === 'json')
    if (jsonInputFields.length > 0) {
      const isValid = jsonInputFields.reduce((acc: boolean, field) => {
        const value: string | undefined = formValues?.[field.key]
        if (value) {
          try {
            JSON.parse(value)
          } catch (e) {
            const matchParamsValue = value.match(/\{{2,3}.*?\}{2,3}/gi)
            if (matchParamsValue && matchParamsValue.length == 1) {
              return acc && true
            }
            return acc && false // not valid if failed to parse as json xor use prev data more than one
          }
        }
        return acc && true
      }, true)
      return isValid
    }
    return true
  }
  return true
}


export function isValidInputJSON(value?: string, stateTestData?: null | Record<string, DataSuggestion | StateWithTestData>): boolean {
  if (value) {
    try {
      JSON.parse(value)
    } catch (e) {
      const matchParamsValue = value.match(/(^\{{2,3})[^\}\{\\R\n]+(\}{2,3})$/g)
      if (matchParamsValue && matchParamsValue.length == 1) {
        if (stateTestData) {
          try {
            const sampleValue = getSampleValue(matchParamsValue, stateTestData)
            return Boolean(sampleValue) && typeof sampleValue === 'object'
          } catch (e) {
            return false
          }
        }
        return true
      }
      return false // not valid if failed to parse as json xor use prev data more than one
    }
  }
  return true
}

function getTagsUsedOnParamsValue(text: string): string[] {
  const lines = text.split('\n')
  const re = /\{\{\{.+?\}\}\}/g
  const tags: any[] = []
  let m

  for (let i = 0; i < lines.length; i++) {
    while ((m = re.exec(lines[i])) !== null) {
      const tag = m[0].substring(3, m[0].length - 3).replace(/\[.+?\]/gi, (v) => v.substr(1, v.length - 2))
      tags.push(tag)
    }
  }
  return tags
}

export function getContainer(id?: string): (() => HTMLElement) | undefined {
  if (!id || typeof document === 'undefined') return undefined
  return () => {
    return document.getElementById(id) as HTMLElement
  }
}

export const sortApplications = (app1: Application, app2: Application): number => {
  const app1Premium = Number(app1.isPremiumApp)
  const app2Premium = Number(app2.isPremiumApp)

  const app1NativeApp = Number(app1.isNativeApp)
  const app2NativeApp = Number(app2.isNativeApp)

  const app1Public = Number(app1.public) && !app1NativeApp && !app1Premium
  const app2Public = Number(app2.public) && !app2NativeApp && !app2Premium

  if (app1Premium > app2Premium) return -1
  if (app1Premium < app2Premium) return 1
  if (app1Public > app2Public) return -1
  if (app1Public < app2Public) return 1
  if (app1NativeApp > app2NativeApp) return -1
  if (app1NativeApp < app2NativeApp) return 1
  return compareString(app1.name, app2.name)
}

export const sortTriggerActions = (opt1: Trigger | Action, opt2: Trigger | Action): number => {
  return compareString(opt1.name, opt2.name)
}

export const compareString = (string1?: string, string2?: string): number => {
  return (string1 || '')?.toLowerCase().localeCompare(string2?.toLowerCase() || '')
}

export function isValidValueArray(value?: string, stateTestData?: null | Record<string, DataSuggestion | StateWithTestData>): boolean {
  if (value) {
    try {
      const valueArray = JSON.parse(value)
      if (Array.isArray(valueArray)) {
        return true
      } return false
    } catch (e) {
      const matchParamsValue = value.match(/(^\{{2,3})[^\}\{\\R\n]+(\}{2,3})$/g)
      if (matchParamsValue && matchParamsValue.length == 1) {
        if (stateTestData) {
          try {
            const sampleValue = getSampleValue(matchParamsValue, stateTestData)
            return Boolean(sampleValue) && Array.isArray(sampleValue)
          } catch (e) {
            return false
          }
        }
        return false
      }
      return false // not valid if failed to parse as json xor use prev data more than one
    }
  }
  return true
}

export function getSuggestionsFromValueArray(value?: string, stateTestData?: null | Record<string, DataSuggestion | StateWithTestData>): Record<string, DataSuggestion> | null {
  if (value) {
    try {
      const valueArray = JSON.parse(value)
      if (Array.isArray(valueArray)) {
        return helperGetSuggestionFromValueArray(valueArray)
      }
      return null
    } catch (e) {
      const matchParamsValue = value.match(/(^\{{2,3})[^\}\{\\R\n]+(\}{2,3})$/g)
      if (matchParamsValue && matchParamsValue.length == 1) {
        if (stateTestData) {
          try {
            const sampleValue = getSampleValue(matchParamsValue, stateTestData)
            return {
              ...helperGetSuggestionFromValueArray(sampleValue),
              ...stateTestData
            }
          } catch (e) {
            return {}
          }
        }
        return {}
      }
      return {} // not valid if failed to parse as json xor use prev data more than one
    }
  }
  return {}
}

const helperGetSuggestionFromValueArray = (valueArray: any[], app?: Application): Record<string, DataSuggestion> | null => {
  const getItemsInObjectArray: Record<string, any> = valueArray.reduce((res, currentValue) => {
    if (typeof currentValue === 'object') {
      return {
        ...res,
        ...currentValue
      }
    }
    return {
      ...res,
    }

  }, {})

  return {
    'ctx': {
      name: 'Array Value: Item Attribute',
      application: app,
      flatTestData: Object.keys(getItemsInObjectArray).reduce((res, key) => {
        return {
          ...res,
          [`ctx.${key}`]: {
            sample: getItemsInObjectArray[key],
            label: key,
            value: `ctx.${key}`,
            appLogoUrl: app?.logoUrl
          }
        } as DataSuggestion['flatTestData']
      }, {
        ['ctx']: {
          sample: typeof valueArray?.[0] === 'object' ? getItemsInObjectArray : valueArray?.[0],
          label: typeof valueArray?.[0] === 'object' ? 'Item (object)' : 'Item',
          value: 'ctx',
          appLogoUrl: app?.logoUrl
        }
      } as DataSuggestion['flatTestData']),
      testData: getItemsInObjectArray
    }
  }
}

interface DataArrayMapItem extends Record<string, unknown> {
  _value?: string,
  data?: null | any[],
  _useArrayFromPrevAction?: null | boolean
}

interface ParamValidateArrayMapItem {
  value?: DataArrayMapItem,
  param: FieldSchema,
  stateTestData?: Record<string, DataSuggestion> | null
}

export const isValidArrayMapItem = (params: ParamValidateArrayMapItem): boolean => {
  const { value, param, stateTestData  } = params
  if (!value) {
    return true
  }
  const { _value, data, _useArrayFromPrevAction, ...otherValues } = value

  if(!param.mapSchema) {
    return true
  }

  const getIsAnyFieldsFilled = (data: any) => {
    return param.mapSchema?.reduce((res, curr) => {
      if (data?.[curr.key]) {
        return res || true
      }
      return res || false
    }, false) || false
  }
  const getIsAllRequiredFieldsFilled = (data: any) => {
    return param.mapSchema?.reduce((res, curr) => {
      if (curr.required && !data?.[curr.key]) {
        return res && false
      }
      return res && true
    }, true) || false
  }
  if (_useArrayFromPrevAction) {
    // use array from prev action
    if (_value) {
      if (isValidValueArray(_value, stateTestData)){
        const isAllRequiredFieldsFilled = getIsAllRequiredFieldsFilled(otherValues)
        return isAllRequiredFieldsFilled
      }
      return false
    }
    // if required return false
    return !param.required
  }
  // not using array from prev action
  if (data) {
    const isItemsValid = data.reduce((res, currData) => {
      const currentData = currData
      const isAnyFieldsFilled = getIsAnyFieldsFilled(currentData)
      const isAllRequiredFieldsFilled = getIsAllRequiredFieldsFilled(currentData)
      if (isAnyFieldsFilled && !isAllRequiredFieldsFilled) {
        return res && false
      }
      if (param.required && !isAnyFieldsFilled) {
        return res && false
      }
      return res && true
    }, true)
    return isItemsValid
  }
  return true
}

export const openNewTabOnClick = (url: string): void => {
  const a = document.createElement('a')
  a.target = '_blank'
  a.href = url
  a.rel = 'noopener noreferrer'
  a.click()
}

export function isValidInputDate(value?: string, stateTestData?: null | Record<string, DataSuggestion | StateWithTestData>): boolean {
  if (value) {
    try {
      if (!Date.parse(value)) {
        throw new Error('parse error')
      }
    } catch (e) {
      const matchParamsValue = value.match(/(^\{{2,3})[^\}\{\\R\n]+(\}{2,3})$/g)
      if (matchParamsValue && matchParamsValue.length == 1) {
        if (stateTestData) {
          try {
            const sampleValue = getSampleValue(matchParamsValue, stateTestData)
            return Boolean(sampleValue) && !!Date.parse(sampleValue)
          } catch (e) {
            return false
          }
        }
        return true
      }
      return false // not valid if failed to parse as json xor use prev data more than one
    }
  }
  return true
}

export function convertStringToRegex(inputString: string): RegExp {
  const flags = inputString.replace(/.*\/([gimy]*)$/, '$1')
  const pattern = inputString?.replace(new RegExp('^/(.*?)/'+flags+'$'), '$1')
  const regex = new RegExp(pattern, flags !== inputString ? flags : undefined) // if no flags, dont use flags
  return regex
  // return new RegExp(inputString)
}

export function isValidRegex(inputString?: string): boolean {
  let isValid = true
  if (inputString) {
    try {
      convertStringToRegex(inputString)
    } catch(e) {
      isValid = false
    }
  }
  return isValid
}

export function isValidInputWithRegexValidation(value?: string, pattern?: RegExp, stateTestData?: null | Record<string, DataSuggestion | StateWithTestData>): boolean {
  if (value && pattern) {
    try {
      if (!pattern.test(value)) {
        throw new Error('test regex failed')
      }
    } catch (e) {
      const matchParamsValue = value.match(/(^\{{2,3})[^\}\{\\R\n]+(\}{2,3})$/g)
      if (matchParamsValue && matchParamsValue.length == 1) {
        if (stateTestData) {
          try {
            const sampleValue = getSampleValue(matchParamsValue, stateTestData)
            return Boolean(sampleValue) && pattern.test(sampleValue)
          } catch (e) {
            return false
          }
        }
        return true
      }
      return false // not valid if failed to parse as json xor use prev data more than one
    }
  }
  return true
}

const getSampleValue = (matchParamsValue: RegExpMatchArray, stateTestData: Record<string, DataSuggestion | StateWithTestData>): any => {
  const tag = getTagsUsedOnParamsValue(matchParamsValue[0])[0]
  const stateIdFromTag = tag.split('.')[0]
  const selectedStateFlatTestData = stateTestData[stateIdFromTag].flatTestData
  const tagWithoutStateId = tag.split('.').slice(1).join('.')
  const sampleValue = selectedStateFlatTestData[tagWithoutStateId]?.sample
  return sampleValue
}

export const getDistinctApplications = (states?: State[]): Application[] => {
  if (states) {
    const uniquesObject = states.reduce((res, state) => {
      return {
        ...res,
        [(state.trigger || state.action)?.applicationId || '']: (state.trigger || state.action)?.application
      }
    }, {})
    return Object.keys(uniquesObject).map((appId) => {
      return uniquesObject[appId]
    })
  }
  return []
}