import { CloseOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { EdgeProps, getBezierPath, getEdgeCenter, useReactFlow } from 'react-flow-renderer'
import { danger } from 'src/util/colors'
import styled from 'styled-components'
import useSyncConnectionStates from './useSyncConnectionStates'

const foreignObjectSize = 30


const StateEdge: FC<EdgeProps> = ({ id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  selected,
  markerEnd }) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })
  const { setEdges, getEdges, getNodes } = useReactFlow()
  const { onDeleteEdges } = useSyncConnectionStates()

  const onDeleteEdgeClick = (evt, id) => {
    evt.stopPropagation()
    const allEdges = getEdges()
    onDeleteEdges(allEdges.filter(edge => edge.id === id), allEdges, getNodes())
    setEdges((edges) => edges.filter(edge => edge.id !== id))
  }
  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {/* <body> */}
      {
        selected ?
          <foreignObject
            width={foreignObjectSize}
            height={foreignObjectSize}
            x={edgeCenterX - foreignObjectSize / 2}
            y={edgeCenterY - foreignObjectSize / 2}
            className="edgebutton-foreignobject"
            requiredExtensions="http://www.w3.org/1999/xhtml"
          >
            <StyledButton style={{ cursor: 'pointer', color: danger, borderRadius: '100%', border: `1px solid ${danger}` }} onClick={(event) => onDeleteEdgeClick(event, id)}>
              <CloseOutlined style={{ fontSize: 12 }} />
              {/* <DeleteFilled style={{ fontSize: 12 }} /> */}
            </StyledButton>

          </foreignObject>
          : null}
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <StyledButton style={{ cursor: 'pointer', color: danger, borderRadius: '100%', border: `1px solid ${danger}` }} onClick={(event) => onDeleteEdgeClick(event, id)}>
          <CloseOutlined style={{ fontSize: 12 }} />
          {/* <DeleteFilled style={{ fontSize: 12 }} /> */}
        </StyledButton>

      </foreignObject>
    </>
  )
}

export default StateEdge

const StyledButton = styled.button`
  cursor: pointer;
  color: ${danger};
  border: 1px solid ${danger};
  border-radius: 100%;
  &:hover {
    * {
      color: white;
    }
    background: ${danger};
  }
`