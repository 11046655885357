import { compareString } from './functions'

export default function truncateLongData(dataSample: string | any[], limit = 60): string {
  if (Array.isArray(dataSample)) {
    return `[${dataSample.reduce((res, val, idx, arr) => {
      if (typeof val === 'object' && val !== null && idx === 0) {
        return `{ ${res}${Object.keys(val).sort((k1, k2) => compareString(k1, k2)).reduce((resVal, key, currIdx, arrKey) => {
          if (currIdx < 3) {
            return `${resVal}${key}${arrKey[currIdx + 1] ? ', ' : ''}`
          }
          if (!arrKey[currIdx + 1]) {
            return `${resVal}...`
          }
          return resVal
        }, '')} }${arr[idx + 1] ? ', ...' : ''}`
      } else if (typeof val !== 'object') {
        if (idx < 3) {
          return `${res}${val}${arr[idx + 1] ? ', ' : ''}`
        }
        if (idx === 4 && arr[idx + 1]) {
          return `${res}..., `
        }
        if (!arr[idx + 1]) {
          return `${res}${val}`
        }
      }
      return res
    }, '')}]`
  }
  let data = `${dataSample}`
  if (typeof dataSample === 'object') {
    data = JSON.stringify(dataSample)
  }
  if (typeof data === 'string') {
    return data.length > limit ?
      `${[...data.split('').slice(0, Math.floor(limit / 2))].join('')}...${[...data.split('').slice(Math.floor(limit / 2) * -1)].join('')}` :
      data
  }
  return ''
}