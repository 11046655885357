import { Scenario, State } from 'db'
import { useState } from 'react'
import { useApiScenario } from '../../useAScenario'
import { useApi } from '../../useFetch'
import { mappingConditions, mappingParams } from '../useCreateScenario'

type CreateScenarioFromTemplate = {
  activeId?: number,
  createScenarioFromTemplate: (templateId: number) => Promise<Scenario | undefined>,
  creatingScenarioFromTemplate: boolean
}

export default function useCreateScenarioFromTemplate(): CreateScenarioFromTemplate {
  const { call } = useApi<{ scenario: Scenario }>()
  const [activeId, setActiveId] = useState<number>()
  const { getScenario } = useApiScenario()
  const APICreateState = useApi<{ state: State }>()
  const APIUpdateState = useApi<{ state: State }>()
  const [creatingScenarioFromTemplate, setCreatingScenarioFromTemplate] = useState(false)


  const createScenarioFromTemplate = async (templateId: number) => {
    setActiveId(templateId)
    setCreatingScenarioFromTemplate(true)
    return getScenario(templateId, true).then(async ({ data }) => {
      const states = data.scenario.states
      return call({
        url: `/scenario/template/${templateId}/create`,
        method: 'post'
      }).then(async ({ data }) => {
        const newScenario = data.scenario
        const newScenarioId = newScenario.id
        if (newScenarioId && states) {
          const mappingExistedToNewId = {}
          const isDuplicate = !!states[0].id
          const promisesStates = states.map(async (state, idx) => {
            const { realTestData, testData, triggerId, actionId, name, params, conditionGroupOrder, conditions, stateConditionId, parentId, childrenIds } = state
            return await APICreateState.call({
              url: `/scenario/${newScenarioId}/state`,
              method: 'post',
              data: {
                state: {
                  testData, triggerId, actionId, name, params, conditionGroupOrder, conditions, stateConditionId, parentId, childrenIds,
                  id: null, realTestData,
                  order: state.order !== undefined ? state.order : idx,
                }
              }
            }).then(({ data }) => {
              mappingExistedToNewId[state.id] = data.state.id
              return data.state
            })
          })
          const createdStates= await Promise.all(promisesStates)
          await Promise.all(createdStates.sort((s1, s2) => s1.order - s2.order).map(async (state, idx, states) => {
            const parentId = state.parentId ? mappingExistedToNewId[state.parentId] : states[idx - 1]?.id
            const childIds = state.childrenIds ?
            // handle for duplicate scenario
              state.childrenIds.map(childId => mappingExistedToNewId[childId]) :
            // handle for create new
              states[idx + 1]?.id && !isDuplicate ? [states[idx + 1]?.id] : null
            const newStateConditionId = state.stateConditionId ? mappingExistedToNewId[state.stateConditionId] : null
            const stateParams = state.params ? mappingParams(state.params, mappingExistedToNewId) : null
            const stateConditions = state.conditions ? mappingConditions(state.conditions, mappingExistedToNewId) : null
            return await APIUpdateState.call({
              url: `/scenario/${newScenarioId}/state/${state.id}`,
              method: 'patch',
              data: {
                state: {
                  conditions: stateConditions,
                  params: stateParams,
                  parentId: parentId,
                  childrenIds: childIds,
                  stateConditionId: newStateConditionId
                }
              }
            })
          }))
          setCreatingScenarioFromTemplate(false)
          return newScenario
        }
      })
    })
  }

  return {
    activeId,
    createScenarioFromTemplate,
    creatingScenarioFromTemplate
  }

}