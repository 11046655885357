import Editor, { EditorProps } from '@monaco-editor/react'
import { Dispatch, FC, SetStateAction, useRef } from 'react'
import styled from 'styled-components'

interface Props extends EditorProps {
  maxHeight?: string,
  noResize?: boolean,
  value?: string,
  setValue?: Dispatch<SetStateAction<string | undefined>>
}

const MonacoEditor: FC<Props> = ({ height, setValue, maxHeight, value, options, noResize, ...rest }) => {
  const editorRef = useRef<any | null>(null)

  function handleEditorDidMount(editor: any, _: any) {
    editorRef.current = editor
  }

  function onChange(value: string | undefined, _: any) {
    setValue ? setValue(value) : null
  }

  return (
    <StyledContainer height={height} maxHeight={maxHeight} noResize={noResize}>
      <Editor
        defaultLanguage="json"
        value={value}
        onMount={handleEditorDidMount}
        onChange={onChange}
        theme='vs-dark'
        keepCurrentModel={true}
        height="100%"
        options={{
          tabSize: 2,
          quickSuggestions: false,
          minimap: {
            enabled: false,
          },
          ...options
        }}
        {...rest}
      />
    </StyledContainer>
  )
}

export default MonacoEditor

const StyledContainer = styled.div<{ maxHeight?: string, height?: string | number, noResize?: boolean }>`
  resize: ${props => props.noResize ? 'none' : 'vertical'};
  overflow-y: auto;
  background: #1e1e1e;
  height: ${props => props.height || '360px'};
  min-height: ${props => props.height || '360px'};
  max-height: ${props => props.maxHeight || 'unset'};
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 14px;
    height: 0;
  }
  ::-webkit-resizer {
    /* border-top: none;
    border-left: none; */
    /* border: ${props => props.noResize ? 'none' : '4px double grey'}; */
    /* border-bottom: ${props => props.noResize ? 'none' : '4px double grey'}; */
    /* background: #1e1e1e; */
    background: red;
  }
  scrollbar-width: none;
`