import { Avatar, Tooltip } from 'antd'
import { Application } from 'db'
import { FC } from 'react'

interface Props {
  applications?: (Application | undefined)[]
}

const AvatarApplicationGroups: FC<Props> = ({ applications }) => {
  const uniqueApplications: Application[] | undefined = applications?.reduce((res: Application[], app) => {
    if (app && !res.find(a => a?.id === app.id)) {
      return [...res, app]
    }
    return res
  }, [])


  return (
    <>
      {
        applications && uniqueApplications ?
          <Avatar.Group maxCount={3}
            style={{
              verticalAlign: 'middle'
            }}
            maxStyle={{
              color: '#FFFFFF',
              backgroundColor: '#3E73E3'
            }}>
            {
              uniqueApplications.map((app, idx) => {
                return (
                  <Tooltip title={app.name} key={idx}>
                    <Avatar
                      key={idx}
                      src={app.logoUrl || '/placeholder.png'}
                      style={{
                        padding: '4px',
                        backgroundColor: '#F0FBFF',
                        border: '1px solid #3E73E3'
                      }}
                      draggable={false}
                    />
                  </Tooltip>
                )
              })
            }
          </Avatar.Group>
          :
          null
      }
    </>

  )
}

export default AvatarApplicationGroups