import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { FC, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { StyledDescription } from '../../../../../pages/components/StyledComponents'
import { updatingScenarioOrState } from '../../scenario/edit/recoil'

const SavingSignifier: FC = () => {
  const { counter, isUpdating } = useRecoilValue(updatingScenarioOrState)
  const [text, setText] = useState('')

  useEffect(() => {
    if (counter !== 0) {
      if (isUpdating) {
        setText('Saving...')
      } else {
        setText('Saved')
        setTimeout(() => {
          setText('')
        }, 3000)
      }
    }
  }, [isUpdating, counter])
  return (
    <StyledDescription style={{ padding: 0, marginTop: 2 }}>{text} {isUpdating ? <LoadingOutlined /> : <CheckCircleOutlined />}</StyledDescription>
  )
}

export default SavingSignifier