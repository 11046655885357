import { Folder } from 'db'
import { Error } from '../../types'
import useFetch from '../useFetch'

type Folders = {
  folders?: Folder[],
  revalidateFolders: () => void,
  errorFetchFolders?: Error,
  fetchingFolders: boolean
}
export default function useFolders(): Folders {
  const url = '/folders'
  const { data, error, isValidating, revalidateFetch } = useFetch<{ folders: Folder[] }>(url, {}, { revalidateOnFocus: false })

  return {
    revalidateFolders: () => revalidateFetch(url),
    folders: data?.folders,
    errorFetchFolders: error,
    fetchingFolders: isValidating
  }
}