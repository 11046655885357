import { Scenario } from 'db'
import { useState } from 'react'
import { Implikasi } from '../service/Implikasi'
import { Error, TableState } from '../types'
import Notification from '../util/Notification'
import { useRevalidateFetch } from './useFetch'

type Result = {
  enableScenario: (scenarioId: number) => void,
  responseEnablingScenario?: Scenario,
  isSuccessEnablingScenario: boolean,
  enablingScenario: boolean,
  errorEnableScenario?: Error
}

export default function useEnableAScenario(tableState?: TableState<Scenario>): Result {
  const { revalidateAScenario, revalidateScenarios } = useRevalidateFetch()
  const [responseEnablingScenario, setResponseEnablingScenario] = useState()
  const [enablingScenario, setEnablingScenario] = useState<boolean>(false)
  const [errorEnableScenario, setErrorEnableScenario] = useState<Error>()
  const [isSuccessEnablingScenario, setIsSuccessEnablingScenario] = useState<boolean>(false)

  const enableScenario = async (scenarioId: number) => {
    if (scenarioId) {
      try {
        setEnablingScenario(true)
        const enableScenario = await Implikasi.post(`/scenario/${scenarioId}/run`, {})

        if (enableScenario.status === 202) {
          setIsSuccessEnablingScenario(true)
        } else {
          setIsSuccessEnablingScenario(false)
        }

        // revalidate any component that used Scenario model
        revalidateScenarios(tableState)
        revalidateAScenario(scenarioId)

        setResponseEnablingScenario(enableScenario.data)
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error
        })

        setErrorEnableScenario(response?.data?.error)
      } finally {
        setEnablingScenario(false)
      }
    }
  }

  return {
    enableScenario,
    isSuccessEnablingScenario,
    responseEnablingScenario,
    enablingScenario,
    errorEnableScenario
  }
}