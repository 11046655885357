import React, { FC, useState, useEffect, useCallback } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import styled, { keyframes } from 'styled-components'
import useSearchInput from '../useSearchInput'
// import { useThisStateStaticValue } from '../ThisStateStaticValueContext'
import { CustomDualModeInputSearchData } from './DualModeDataPickerPopper'
import { Skeleton } from 'antd'

type DynamicOptionsDataPickerProps = {
  loading?: boolean,
  selectedValue: string,
  options?: ({ label: string, value: string })[],
  hiddenTriggerId?: number,
  onChangeOption: (selected: { label: string, value: string }) => void
}

const DynamicOptionsDataPicker: FC<DynamicOptionsDataPickerProps> = ({ loading, options, selectedValue, onChangeOption }) => {
  const [mappedOptions, setMappedOptions] = useState<Record<string, { label: string, value: string }> | undefined>(
    options?.reduce((acc, o) => ({ ...acc, [o.value]: { ...o } }), {})
  )

  useEffect(() => {
    if (options) {
      setMappedOptions(options?.reduce((acc, o) => ({ ...acc, [o.value]: { ...o } }), {}))
    }
  }, [options])

  // const [thisStateStaticValue] = useThisStateStaticValue()
  // const thisStateBundleId
  const { StyledSearchInput, searchQuery } = useSearchInput()

  // const [selectedOption, setSelectedOption] = useState<string>(selectedValue) // option.value

  const onClickOption = useCallback((value: string) => {
    // setSelectedOption(value)
    mappedOptions && onChangeOption(mappedOptions[value])
  }, [mappedOptions])

  return (
    <>
      <div style={{ padding: '16px 16px 4px 16px' }}>
        <CustomDualModeInputSearchData>
          <StyledSearchInput style={{ marginBottom: 0 }} suffix={<SearchOutlined />} placeholder="Search data..." />
        </CustomDualModeInputSearchData>
      </div>
      <RadioGroupContainer>
        {mappedOptions && options && !loading ?
          options
            .filter(o => o.label?.toLowerCase().includes(searchQuery?.toLowerCase()) || String(o.value)?.toLowerCase().includes(searchQuery.toLowerCase()))
            .map(o =>
              <CustomPseudoRadioOption key={o.value} onClick={() => onClickOption(o.value)}>
                <RadioItemCircle checked={o.value.toString() === selectedValue}>
                  <RadioItemCircleInner></RadioItemCircleInner>
                </RadioItemCircle>
                <RadioItemContent>
                  <span>{o.label}</span>
                  <span>{o.value}</span>
                </RadioItemContent>
              </CustomPseudoRadioOption>
            ) :
          [1,2,3,4].map((_, idx) =>
            <CustomPseudoRadioOption key={idx}>
              <RadioItemCircle checked={false}>
                <RadioItemCircleInner></RadioItemCircleInner>
              </RadioItemCircle>
              <StyledSkeleton paragraph={{ rows: 1 }} active />
            </CustomPseudoRadioOption>
          )
        }
      </RadioGroupContainer>
    </>
  )
}

export default DynamicOptionsDataPicker

const StyledSkeleton = styled(Skeleton)`
  .ant-skeleton-paragraph, .ant-skeleton-title {
    margin: 4px 0 !important;
  }
`
const CustomPseudoRadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 3px;

  cursor: pointer;

  &:hover {
    background: rgb(243 173 61 / 8%);
  }
`

const radioAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
`

const RadioItemContent = styled.div`
  display: flex;
  flex-direction: column;

  & span:first-of-type {
    font-weight: 500;
  }

  & span:last-of-type {
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
  }
`

const RadioItemCircleInner = styled.span`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  transition: all .3s;

  &:after {
    position: absolute;
    top: 3px;
    left: 3px;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #F3AD3D;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    transform: scale(0);
    opacity: 0;
    transition: all .3s cubic-bezier(.78,.14,.15,.86);
    content: " ";
  }
`

const RadioItemCircle = styled.span<{ checked?: boolean }>`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: pointer;

  & ${RadioItemCircleInner} {
    ${({ checked }) => checked ? 'border-color: #F3AD3D;' : ''}
  }

  &:hover ${RadioItemCircleInner} {
    border-color: #F3AD3D;
  }

  & ${RadioItemCircleInner}:after {
    ${({ checked }) => checked ?
    `transform: scale(1);
    opacity: 1;
    transition: all .3s cubic-bezier(.78,.14,.15,.86);` : ''}
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #F3AD3D;
    border-radius: 50%;
    visibility: hidden;
    -webkit-animation: ${radioAnimation} .36s ease-in-out;
    animation: ${radioAnimation} .36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    content: "";
  }
`

const RadioGroupContainer = styled.div`
  height: fit-content;
  max-height: 240px;
  overflow: scroll;
  padding: 8px 12px 16px 16px;
`

// const CustomRadioOption = styled(Radio)`
//   & .ant-radio .ant-radio-input:focus + .ant-radio-inner {
//     box-shadow: 0 0 0 3px rgb(243 173 61 / 8%);
//   }

//   &:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
//     border-color: #F3AD3D;
//   }

//   & .ant-radio.ant-radio-checked::after {
//     border-color: #F3AD3D;
//   }

//   & .ant-radio.ant-radio-checked > .ant-radio-inner {
//     border-color: #F3AD3D;
//   }

//   & .ant-radio > .ant-radio-inner::after {
//     background: #F3AD3D;
//   }

//   & .ant-radio.ant-radio-checked > .ant-radio-inner::after {
//     background: #F3AD3D;
//   }
// `
