import { Scenario } from 'db'
import { useState } from 'react'
import { Implikasi } from '../service/Implikasi'
import { Error, TableState } from '../types'
import Notification from '../util/Notification'
import { useRevalidateFetch } from './useFetch'

type Result = {
  archiveScenario: (scenarioId: number) => void,
  responseArchivingScenario?: Scenario,
  isSuccessArchivingScenario: boolean,
  archivingScenario: boolean,
  errorArchiveScenario?: Error
}

export default function useArchiveAScenario(tableState?: TableState<Scenario>): Result {
  const { revalidateAScenario, revalidateScenarios } = useRevalidateFetch()
  const [responseArchivingScenario, setResponseArchivingScenario] = useState()
  const [archivingScenario, setArchivingScenario] = useState<boolean>(false)
  const [errorArchiveScenario, setErrorArchiveScenario] = useState<Error>()
  const [isSuccessArchivingScenario, setIsSuccessArchivingScenario] = useState<boolean>(false)

  const archiveScenario = async (scenarioId: number) => {
    if (scenarioId) {
      try {
        setArchivingScenario(true)
        const deleteScenario = await Implikasi.delete(`/scenario/${scenarioId}/archive`, {})

        if (deleteScenario.status === 200) {
          setIsSuccessArchivingScenario(true)
        } else {
          setIsSuccessArchivingScenario(false)
        }

        // revalidate any component that used Scenario model
        revalidateScenarios(tableState)
        revalidateAScenario(scenarioId)

        setResponseArchivingScenario(deleteScenario.data)
      } catch({ response }) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error
        })

        setErrorArchiveScenario(response?.data?.error)
      } finally {
        setArchivingScenario(false)
      }
    }
  }

  return {
    archiveScenario: archiveScenario,
    isSuccessArchivingScenario: isSuccessArchivingScenario,
    responseArchivingScenario: responseArchivingScenario,
    archivingScenario: archivingScenario,
    errorArchiveScenario: errorArchiveScenario
  }
}