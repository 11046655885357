import { Spin } from 'antd'
import { FC, RefObject } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useHistory, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import useAScenario from '../../../../../../hooks/useAScenario'
import { blue } from '../../../../../../util/colors'
import StateItemWithChilds from './StateItemWithChilds'
import useMoveState from './useMoveState'

interface Props {
  refResize: RefObject<HTMLDivElement>
}
const ScenarioOutline: FC<Props> = ({ refResize }) => {
  const { params } = useRouteMatch<{ scenarioId: string, stateId: string, isPath?: string }>()
  const { scenario, fetchingScenario } = useAScenario(parseInt(params.scenarioId), true)
  const history = useHistory()
  const { onDrop, updatingOrderStates } = useMoveState(scenario?.states)

  const onClickState = (stateId: number, isPath?: boolean) => {
    history.replace(`/app/scenario/${params.scenarioId}/edit/${stateId}${isPath ? '/path': ''}`)
  }

  return (
    <ScnearioOutlineContainer ref={refResize}>
      <ScenarioOutlineHeader>
        Outline
      </ScenarioOutlineHeader>
      <DndProvider backend={HTML5Backend}>
        <Spin spinning={updatingOrderStates || fetchingScenario}>
          <ScenarioOutlineContent>
            {
              scenario?.states?.filter(s => s.order >= 0 && !s.stateConditionId).sort((s1, s2) => s1.order - s2.order || s1.id - s2.id).map((state, idx, currStates) => {
                return (
                  <StateItemWithChilds
                    onDrop={onDrop}
                    key={state.id}
                    isFirstPath={state.conditionGroupOrder && !currStates[idx-1]?.conditionGroupOrder ? true : false}
                    state={state}
                    states={scenario.states}
                    level={state.conditionGroupOrder ? 1 : 0}
                    onClickState={onClickState} />
                )
              })
            }
          </ScenarioOutlineContent>
        </Spin>
      </DndProvider>
    </ScnearioOutlineContainer>
  )
}

export default ScenarioOutline

const ScnearioOutlineContainer = styled.div`
  display: grid;
  grid-template-rows: max-content auto;
  overflow-y: hidden;
  overflow-x: auto;
  /* width: 20vw; */
  /* max-width: 400px; */
  background: #FFF;
  height: 100vh;
  z-index: 1001;
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.05);

  .ant-spin-container, .ant-spin-nested-loading {
    height: calc(100vh - 38px);
    overflow-x: auto;
    /* width: 100%; */
    /* min-width: max-content; */
  }
`
const ScenarioOutlineHeader = styled.div`
  background: ${blue[4]};
  color: #FFF;
  padding: 8px;
`
const ScenarioOutlineContent = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-left: 8px;
  height: 100%;
  padding-bottom: 80px;
`