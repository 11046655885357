import { UnorderedListOutlined } from '@ant-design/icons'
import { Layout, PageHeader, Tooltip } from 'antd'
import { Scenario } from 'db'
import { FC, useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { useParams } from 'react-router'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useAScenario from '../../../../../hooks/useAScenario'
import { getChildsAllStates, getParentsAllStates } from '../../../../../util/formatter'
import BreadcrumbScenario from '../../scenario/edit/BreadcrumbScenario'
import useActiveStates from '../../scenario/edit/BreadcrumbScenario/useActiveStates'
import { updatingScenarioOrState } from '../../scenario/edit/recoil'
import { useResizeOutline } from '../../scenario/edit/ScenarioOutline/useResizeOutline'
import StateSkeleton from '../../scenario/edit/StateSkeleton'
import { useStateParentsData } from '../../scenario/edit/StateTestDataContext'
import useSetStateTestData from '../../scenario/edit/useSetStateTestData'
import EditorFooter from './EditorFooter'
import EditScenarioNamePageHeader from './EditScenarioNamePageHeader'
import { currentStepAtom, scenarioAtom } from './recoil/scenario'
import { nextStatesAllAtom } from './recoil/state'
import SavingSignifier from './SavingSignifier'
import ScenarioInfoEditor from './ScenarioInfoEditor'
import StateForm from './State'
import StateValueContextProvider from './State/StateValueContextProvider'
import StepsEditor from './StepsEditor'
import TemplateScenarioOutline from './TemplateScenarioOutline'
import useSetInitialActiveState from './useSetInitialActiveState'

const EditorTemplateScenario: FC = () => {
  // const { params } = useRouteMatch<{ scenarioId: string, stateId: string, isPath?: string }>()
  const { scenarioId } = useParams<{ scenarioId?: string }>()
  const { scenario } = useAScenario(scenarioId ? parseInt(scenarioId) : undefined, true)
  const [templateScenario, setTemplateScenario] = useRecoilState(scenarioAtom)
  // const isModalPathVisible = useRecoilValue(isModalPathVisibleSelector(params))
  // const statesInFirstLoad = useRecoilValue(statesInFirstLoadSelector)
  const [counterFetchScenario, setCounterFetchScenario] = useState(0)
  const resetIsUpdatingScenarioOrState = useResetRecoilState(updatingScenarioOrState)
  const setIsUpdatingScenarioOrState = useSetRecoilState(updatingScenarioOrState)
  const [_, setParentsAllState] = useStateParentsData()
  const currentStep = useRecoilValue(currentStepAtom)
  useSetStateTestData(scenario?.states || [], counterFetchScenario)
  useSetInitialActiveState(scenario)
  const { activeStates, thisStatePath } = useActiveStates()

  // for outline
  const [showOutline, setShowOutline] = useState(true)
  const { splitPaneRef, onMouseDown, onMouseMove, onMouseUp, outlineRef, isResizing } = useResizeOutline(showOutline)
  const setNextStates = useSetRecoilState(nextStatesAllAtom)

  // for width modal based on width form
  const { width, ref } = useResizeDetector()
  useEffect(() => {
    localStorage.setItem('widthStateForms', `${width}`)
  }, [width])

  useEffect(() => {
    if (scenario?.states) {
      setParentsAllState(getParentsAllStates(scenario.states))
    }
  }, [scenario?.states])

  useEffect(() => {
    if (!scenarioId) {
      // setTemplateScenario({
      //   states: [1,2].map((fakeId, idx) => {
      //     return {
      //       id: fakeId,
      //       trigger: idx === 0 ? {} : undefined,
      //       action: idx !== 0 ? {} : undefined,
      //       order: idx
      //     }
      //   }) as State[]
      // } as Scenario)
    } else {
      if (scenario) {
        setTemplateScenario((prevState) => ({ ...prevState, ...scenario } as Scenario))
        setNextStates(getChildsAllStates(scenario.states))
        setCounterFetchScenario(prevState => prevState + 1)
      }
    }
  }, [scenario, scenarioId])

  useEffect(() => {
    if (counterFetchScenario <= 1) {
      resetIsUpdatingScenarioOrState()
    } else {
      setIsUpdatingScenarioOrState({ counter: counterFetchScenario, isUpdating: false })
    }
  }, [counterFetchScenario])

  return (
    <StyledEditPage
      ref={splitPaneRef}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      isResizing={isResizing}
    >
      <StyledLayoutContent style={{ gridTemplateRows: currentStep !== 0 ? 'max-content max-content auto max-content' : undefined }} >
        <CustomHeaderPage>
          <EditScenarioNamePageHeader scenario={templateScenario} />
          <SavingSignifier />
        </CustomHeaderPage>
        <StepsEditor />
        {currentStep === 0 ? <BreadcrumbScenario isTemplateScenarioPage  /> : null}
        <div ref={ref} className="h-full overflow-y-auto px-4 pb-4">
          <div style={{ display: currentStep === 0 ? 'block' : 'none' }}>
            {
              templateScenario ?
                !thisStatePath ?
                  activeStates?.map((state, idx, states) =>
                    !state.conditionGroupOrder || !states[idx + 1] ?
                      <StateValueContextProvider value={state} key={idx}>
                        <StateForm
                          key={idx}
                          type={state.trigger || state.order === 0 ? 'trigger' : 'action'}
                          pathStates={!states[idx + 1] && state.conditionGroupOrder ? states.filter(s => s.order === state.order) : undefined}
                          {...state}
                        />
                      </StateValueContextProvider>:
                      null
                  ) :
                  activeStates?.map((state, idx, states) =>
                    state.stateConditionId === thisStatePath?.id && !state.conditionGroupOrder ||
                  state.id === thisStatePath?.id || !states[idx + 1] ?
                      <StateValueContextProvider value={state} key={state.id}>
                        <StateForm
                          {...state}
                          pathStates={!states[idx + 1] && state.conditionGroupOrder && thisStatePath?.conditionGroupOrder && state.conditionGroupOrder > thisStatePath.conditionGroupOrder ? states.filter(s => s.order === state.order) : undefined}
                          type={state.trigger ? 'trigger' : 'action'}
                        />
                      </StateValueContextProvider> :
                      null
                  ) :
                scenarioId ?
                  [1, 2, 3].map((key) => <StateSkeleton key={key} />) :
                  null
            }
          </div>
          <ScenarioInfoEditor />
        </div>
        <EditorFooter />
        {/* <StatePathModal isVisible={isModalPathVisible}  /> */}
      </StyledLayoutContent>
      <StyledRightMenuContainer>
        <Tooltip title="Outline" placement="left">
          <StyledUnorderedListOutlined className={showOutline ? 'active' : ''} onClick={() => setShowOutline(!showOutline)} style={{ cursor: 'pointer' }} />
        </Tooltip>
      </StyledRightMenuContainer>
      <div className="room-queue-separator" onMouseDown={onMouseDown} style={{ background: '#D0D6DD', width: '5px', cursor: 'col-resize', zIndex: 1001  }} />
      {
        showOutline ?
          <TemplateScenarioOutline refResize={outlineRef} />: null
      }
    </StyledEditPage>
  )
}

export default EditorTemplateScenario

const StyledUnorderedListOutlined = styled(UnorderedListOutlined)`
  &:hover, &.active:hover {
    background: #F9EBCB;
    color: #F3AD3D;
  }
  &.active {
    background: #F3AD3D;
    color: #FFF;
  }
  height: fit-content;
  padding: 12px;
  color: #595959;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
`
const StyledRightMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.05);
  background: #FFF;
  z-index: 1001;
`
const StyledEditPage = styled.div<{ isResizing: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: auto max-content max-content max-content;
  cursor:${props => props.isResizing ? 'col-resize' : 'default'};
`
const StyledLayoutContent = styled(Layout.Content)`
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: max-content max-content max-content auto max-content;
`
const CustomHeaderPage = styled(PageHeader)`
  & > .ant-page-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15); */
  background: #FFF;
  z-index: 2;
`