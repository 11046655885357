import { User } from 'db'
import React, { useState, useContext, createContext, FC, ReactNode, Dispatch, SetStateAction } from 'react'

type ContextValueType = User

const LoggedInUserContext = createContext<ContextValueType | null>(null)
const LoggedInUserSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode
}

const LoggedInUserContextProvider: FC<Props> = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState<ContextValueType | null>(null)

  return (
    <LoggedInUserContext.Provider value={loggedInUser}>
      <LoggedInUserSetterContext.Provider value={setLoggedInUser}>
        {children}
      </LoggedInUserSetterContext.Provider>
    </LoggedInUserContext.Provider>
  )
}


export function useLoggedInUser(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(LoggedInUserContext)
  const setterContext = useContext(LoggedInUserSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useLoggedInUser must be used on child component of LoggedInUserContextProvider component'
  }

  return [valueContext, setterContext]
}

export default LoggedInUserContextProvider