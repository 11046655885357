import { PageHeader, PageHeaderProps } from 'antd'
import { FC } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styled from 'styled-components'
import UserBillingAlert from '../../index/UserBillingAlert'
import Leftpane from './siderPane/Leftpane'

interface Props {
  propsPageHeader?: PageHeaderProps
}

const LayoutMyScenarioPage: FC<Props> = ({ children, propsPageHeader }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      {/* header */}
      <PageHeader
        title="My Scenario"
        style={{ padding: '10px 24px', backgroundColor: '#FFF', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)', zIndex: 1 }}
        {...propsPageHeader}
      >
      </PageHeader>
      {/* Content */}
      <ContainerContent>
        <Leftpane />
        <ContainerContentTab>
          <UserBillingAlert margin={'16px 24px 0'} />
          {children}
        </ContainerContentTab>
      </ContainerContent>
    </DndProvider>
  )
}

export default LayoutMyScenarioPage


const ContainerContent = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  height: 100%;
  overflow-y: hidden;
  @media (max-width: 768px) {
    grid-template-columns: auto;
    grid-template-rows: max-content auto;
  }
`
const ContainerContentTab = styled.div`
  overflow-y: auto;
`