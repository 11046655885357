import { ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Modal } from 'antd'
import { State } from 'db'
import React, { FC, useEffect, useState } from 'react'
import useArchiveAScenarioState from '../../../../../hooks/scenario/state/useArchiveAScenarioState'
import useDuplicateAScenarioState from '../../../../../hooks/scenario/state/useDuplicateAScenarioState'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import { getContainer } from '../../../../../util/functions'
import { ChildStateType, useStateTestData } from './StateTestDataContext'

interface Props {
  childrenStateIds?: number[] | null,
  parentStateId?: number | null,
  pathStates?: State[],
  order?: number,
  nextStateIds?: ChildStateType[],
  type: 'trigger' | 'action' | 'path',
  scenarioId: number,
  stateId: number,
  disabled: boolean,
  onClickEdit: (isAddTrigger?: boolean) => void
}
const StateContextMenu: FC<Props> = ({ childrenStateIds, parentStateId, pathStates, order, disabled, onClickEdit, scenarioId, stateId, type, nextStateIds }) => {
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const { archiveAScenarioState, archivingAScenarioState, archivedAScenarioState } = useArchiveAScenarioState()
  const { updateAScenarioState } = useUpdateAScenarioState()
  const [updatingOrderStates, setUpdatingOrderStates] = useState(false)
  const [, setTestData] = useStateTestData()
  const { duplicateAScenarioState, duplicatingAScenarioState } = useDuplicateAScenarioState(scenarioId, stateId, nextStateIds)

  const getWidth = () => {
    const prevWidth = localStorage.getItem('widthStateForms')
    if (prevWidth) {
      return parseInt(prevWidth)
    }
    return undefined
  }
  const width = getWidth()

  const onMouseEnter = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onDuplicate = (menuInfo: any) => {
    onMouseEnter(menuInfo.domEvent)
    duplicateAScenarioState()
  }

  const onEdit = (menuInfo: any) => {
    onMouseEnter(menuInfo.domEvent)
    onClickEdit()
  }

  const onDelete = (menuInfo: any) => {
    onMouseEnter(menuInfo.domEvent)
    if (type === 'path') {
      Modal.confirm({
        style: { marginLeft: `calc(${width}px / 3)` },
        title: `Are you sure delete ${pathStates ? 'these' : 'this'} path?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        onOk: () => {
          setUpdatingOrderStates(true)
          // update childrenIds parent to null
          if (parentStateId) {
            if (pathStates) {
              updateAScenarioState(scenarioId, parentStateId, {
                childrenIds: null
              }).then(() => {
                revalidateAScenarioState(scenarioId, parentStateId)
                Promise.all(pathStates.map(async (state) => {
                  return archiveAScenarioState(scenarioId, state.id, true).then(({ data }) => data)
                })).then(() => {
                  setUpdatingOrderStates(false)
                })
              })

            } else {
              updateAScenarioState(scenarioId, parentStateId, {
                childrenIds: childrenStateIds?.length === 0 ? null : childrenStateIds
              }).then(() => {
                revalidateAScenarioState(scenarioId, parentStateId)
                archiveAScenarioState(scenarioId, stateId, true)
                  .then(() => setUpdatingOrderStates(false))
              })
            }
          }
        }
      })
    } else if (type === 'action') {
      Modal.confirm({
        style: { marginLeft: `calc(${width}px / 4)` },
        title: 'Are you sure delete this action?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        onOk: () => {
          archiveAScenarioState(scenarioId, stateId)
          setUpdatingOrderStates(true)
          if (nextStateIds && order) {
            Promise.all(nextStateIds.map((state) => {
              updateAScenarioState(scenarioId, state.id, {
                // update parentId of direct child to stateParentId
                parentId: childrenStateIds?.includes(state.id) ? parentStateId : undefined,
                conditionGroupOrder: state.conditionGroupOrder ? state.conditionGroupOrder - 1 : state.conditionGroupOrder,
                order: state.order - 1
              }).then(({ data }) => data)
            })).then(() => {
              if (parentStateId) {
                updateAScenarioState(scenarioId, parentStateId, {
                  childrenIds: childrenStateIds
                }).then(() => {
                  revalidateAScenarioState(scenarioId, parentStateId)
                  setUpdatingOrderStates(false)
                })
              } else {
                setUpdatingOrderStates(false)
              }
            })
            // update childrenIds parent to direct next stateId
          }
        }
      })
    } else {
      Modal.confirm({
        getContainer: getContainer('state-forms-container'),
        style: { marginLeft: `calc(${width}px / 4)` },
        title: 'Are you sure delete this trigger?',
        content: 'Your scenario must have a trigger. Please set up another trigger.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        onOk: () => {
          onClickEdit(true)
        }
      })
    }
  }

  useEffect(() => {
    if (archivedAScenarioState && !updatingOrderStates) {
      revalidateAScenario(scenarioId)
      if (nextStateIds && type !== 'path') {
        // revalidate all next states
        for (const state of nextStateIds) {
          revalidateAScenarioState(scenarioId, state.id)
        }
      }
    }
    if (archivedAScenarioState) {
      setTestData(prevState => Object.keys(prevState || {}).reduce((res, stateId) => {
        if (parseInt(stateId) === archivedAScenarioState.id) {
          return res
        } else if (prevState && prevState[stateId]) {
          return {
            ...res,
            [stateId]: prevState[stateId]
          }
        }
        return res
      }, {}))
    }
  }, [archivedAScenarioState, updatingOrderStates])

  return (
    <Dropdown
      disabled={disabled}
      overlay={
        <Menu>
          {type !== 'path' ? <>
            <Menu.Item key="edit" onClick={onEdit} disabled={!!pathStates}>Edit...</Menu.Item>
            {
              type === 'action' ?
                <Menu.Item key="duplicate"  onClick={onDuplicate} disabled={!!pathStates}>Duplicate...</Menu.Item> :
                null
            }
          </> : null}
          <Menu.Item key="delete" onClick={onDelete} >Delete...</Menu.Item>
        </Menu>
      }
      trigger={['click']}
      placement='bottomRight'
    >
      <StyledButton loading={archivingAScenarioState || updatingOrderStates || duplicatingAScenarioState} onClick={onMouseEnter} type='text' icon={
        <MoreOutlined style={{ color: 'rgba(0, 0, 0, .45)', verticalAlign: 'middle', textAlign: 'center' }} />
      } />
    </Dropdown>
  )
}

export default StateContextMenu