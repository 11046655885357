import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Col, Input, Layout, PageHeader, Row, Space, Table } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import { ColumnType } from 'antd/lib/table'
import { GlobalVariable } from 'db'
import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import AnchorWrapper from 'src/components/AnchorWrapper'
import ButtonActionMore from 'src/components/ButtonActionMore'
import useAllGlobalVariable from 'src/hooks/application/globalVariable/useAllGlobalVariable'
import useArchiveGlobalVariable from 'src/hooks/application/globalVariable/useArchiveGlobalVariable'
import { StyledButton, StyledCard } from 'src/pages/components/StyledComponents'
import MonacoEditor from 'src/pages/developer/components/form/MonacoEditor'
import { blue } from 'src/util/colors'
import { compareString } from 'src/util/functions'
import styled from 'styled-components'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'

const ListGlobalVariable: FC = () => {
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const [version] = useApplicationVersionContext()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { globalVariables, revalidateGlobalVariables, fetchingAllGlobalVariables } = useAllGlobalVariable(applicationId, version?.id)
  const { archiveGlobalVariable, archivingGlobalVariable } = useArchiveGlobalVariable()
  const [filteredData, setFilteredData] = useState<GlobalVariable[]>([])
  const [searchValue, setSearchValue] = useState<string>()

  const columns: ColumnType<GlobalVariable>[] = [
    { title: 'ID', dataIndex: 'id', render: (id) => <AnchorWrapper href={`${url}/${id}`} style={{ color: blue[4] }} >{id}</AnchorWrapper> },
    { title: 'Name',
      sorter: { compare: (a, b) => compareString(a.name, b.name) },
      defaultSortOrder: 'ascend', dataIndex: 'name' },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Value',
      dataIndex: 'globalVariableValue',
      render: (_, record) => record.globalVariableValue?.constant
    },
    { title: 'Action', dataIndex: 'id', render: (id) => <Space>
      <ButtonActionMore
        overlay={<></>}
        buttonProps={{
          loading: archivingGlobalVariable
        }}
        listMenu={[
          {
            key: 'delete',
            onClick: (info) => onDelete(info.domEvent, id),
            children: 'Delete'
          },
        ]}
      />
    </Space> },
  ]

  const onDelete = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> | undefined, id: number) => {
    e?.preventDefault()
    e?.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this global variable?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action can not be undone',
      onOk() {
        archiveGlobalVariable(Number(applicationId), id, version?.id).then(() => revalidateGlobalVariables())
      }
    })
  }
  const onEdit = (e: MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault()
    e.stopPropagation()
    push(`${url}/${id}`)
  }

  useEffect(() => {
    setFilteredData((globalVariables || []).filter((globalVariable) => {
      return searchValue ?
        globalVariable.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          JSON.stringify(globalVariable.globalVariableValue).toLowerCase().includes(searchValue.toLowerCase()) :
        true

    }))
  }, [globalVariables, searchValue])

  const onSearch = (value: string) => {
    setSearchValue(value)
  }
  return (
    <StyledContainer>
      <PageHeader
        title="List Global Variable"
      />
      <StyledCard>
        <Row justify="space-between" className="mb-2">
          <Col>
            <Input.Search onSearch={onSearch} allowClear placeholder="Search by name and value" />
          </Col>
          <Col flex={0}>
            <AnchorWrapper href={`${url}/create`}>
              <StyledButton type="primary" onClick={() => push(`${url}/create`)}>
                Add Global Variable
              </StyledButton>
            </AnchorWrapper>
          </Col>
        </Row>
        <Table
          // onChange={handleChange}
          // pagination={{
          //   current: tableState.pagination?.current,
          //   pageSizeOptions: PAGE_SIZE_OPTIONS,
          //   showSizeChanger: true,
          //   total: triggersLength,
          //   pageSize: tableState.pagination?.pageSize
          // }}
          // rowSelection={{
          //   onChange: (keys) => {
          //     setSelectedTriggerIds(keys as number[])
          //   },
          //   selectedRowKeys: selectedTriggerIds
          // }}
          onRow={(record) => ({
            style: { cursor: 'pointer' },
            onClick: (e) => onEdit(e, record.id)
          })}
          loading={fetchingAllGlobalVariables}
          rowKey="id"
          scroll={{ x: true }}
          dataSource={filteredData}
          columns={columns}
          expandable={{
            rowExpandable: (record) => !!record.globalVariableValue?.function,
            expandedRowRender: (record) => {
              return (
                <MonacoEditor defaultLanguage="javascript" height="100px" maxHeight="360px" options={{ readOnly: true }} value={record.globalVariableValue?.function} />
              )
            }
          }}
        />
      </StyledCard>
    </StyledContainer>
  )
}

export default ListGlobalVariable

const StyledContainer = styled(Layout.Content)`
  ${StyledCard} {
    margin: 0 24px 16px;
    padding: 0;
  }
`