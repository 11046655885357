import { Input, Select, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import CustomForm from '../../../../../components/form/CustomForm'
import FormItem from '../../../../../components/form/FormItem'
import { StyledButton, StyledCard, StyledFooterForm, StyledTag } from '../../../../../../components/StyledComponents'
import { PropsContentStep } from '../data'
import { useSharedValueTrigger } from '../SharedValueTriggerContextProvider'
import useCreateOrUpdateTrigger from '../../../../../../../hooks/application/trigger/useCreateOrUpdateTrigger'
import { useApplicationVersionContext } from '../../../../contexts/ApplicationVersionContextProvider'

const SettingsForm: FC<PropsContentStep> = ({ isActive, onContinue }) => {
  const history = useHistory()
  const [values, setValues] = useSharedValueTrigger()
  const [form] = useForm()
  const [version] = useApplicationVersionContext()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { createOrUpdateTrigger, creatingTrigger } = useCreateOrUpdateTrigger()

  useEffect(() => {
    let cancelEffect = false
    if (!cancelEffect && values) {
      form.setFieldsValue({
        ...values,
        visibility: values.visibility || 'visible'
      })
    } else if (!values) {
      form.resetFields()
      form.setFieldsValue({
        visibility: 'visible'
      })
    }
    return () => {
      cancelEffect = true
    }
  }, [values?.name, values?.description])

  const onCancel = () => {
    history.goBack()
  }

  const onFinish = async (allValues: any) => {
    const payload = { ...values, ...allValues }
    const newTrigger = await createOrUpdateTrigger(applicationId,
      { ...payload, isDraft: values?.isDraft === undefined ? true : values.isDraft,
        type: values?.type || 'hook',
        versionId: values?.versionId || version?.id })

    if (newTrigger) {
      setValues(prevState => ({ ...prevState, ...payload, ...newTrigger }))
    }
    onContinue()
  }

  useEffect(() => {
    if (!isActive) {
      setValues(prevState => ({ ...prevState, ...form.getFieldsValue() }))
    }
  }, [isActive])

  return (
    <StyledSettingsForm className={isActive ? 'show' : undefined}>
      <CustomForm onFinish={onFinish} form={form}>
        <FormItem name='name' rules={[{ required: true }]} label="Name" description="Enter a user friendly name for this trigger that describes what makes it run. Shown to users inside Implikasi."
          extra={<Space><p>Example:</p><StyledTag>New Ticket</StyledTag><StyledTag>Tiket Baru</StyledTag></Space>}
        >
          <Input />
        </FormItem>
        <FormItem name='description' rules={[{ required: true }]} label="Description" description="Describe clearly the purpose of this trigger in a complete sentence."
          extra={<Space><p>Example:</p><StyledTag>Triggers when a new support ticket is created.</StyledTag><StyledTag>Dipicu ketika ada tiket suport baru.</StyledTag></Space>}
        >
          <Input />
        </FormItem>
        <FormItem name='visibility' label="Visibility Options" description="Show this trigger to user">
          <Select options={[{ label: 'Visible for User', value: 'visible' }, { label: 'Hidden from User', value: 'hidden' }]}  />
        </FormItem>
        {/* <FormItem name='direction' label="Direction" description="Add additional detail to set up this trigger, if needed, especially if users need to customize anything in your app.">
          <Input.TextArea />
        </FormItem> */}
        <StyledFooterForm>
          <StyledButton htmlType="button" onClick={onCancel}>Cancel</StyledButton>
          <StyledButton htmlType="submit" type="primary" loading={creatingTrigger}>{'Save & Continue'}</StyledButton>
        </StyledFooterForm>
      </CustomForm>
    </StyledSettingsForm>
  )
}

export default SettingsForm

const StyledSettingsForm = styled(StyledCard)`
  &.show {
    display: block;
  }
  display: none;

  ${StyledTag} {
    color: rgba(0, 0, 0, 0.65);
  }
`