import { State } from 'db'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useAScenarioState from 'src/hooks/useAScenarioState'
import { detailStateAtomFamily, selectedStateAtomFamily } from '../recoil'

export default function useGetState(): { state: State | undefined, scenarioId?: string, fetchingState: boolean } {
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const stateRecoil = useRecoilValue(selectedStateAtomFamily({ scenarioId }))
  const { state, fetchingState } = useAScenarioState(Number(scenarioId), stateRecoil?.id, true)
  const setDetailState = useSetRecoilState(detailStateAtomFamily({ stateId: state?.id }))

  useEffect(() => {
    if (state) {
      setDetailState(state)
    }
  }, [state])

  return {
    state,
    scenarioId,
    fetchingState
  }
}