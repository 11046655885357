import { Layout } from 'antd'
import React from 'react'
import { Route, Switch, useParams } from 'react-router'
import Header from '../../../components/Header'
import Sider from '../../components/Sider'
import ApplicationVersionContextProvider from '../contexts/ApplicationVersionContextProvider'
import UpdateApplication from '../Update'
import Action from './action'
import Advance from './advance'
import Authentication from './authentication'
import GlobalVariable from './globalVariable'
import Member from './member'
import Step from './step'
import SubmitApp from './submitApp'
import Trigger from './trigger'
import VersionPage from './version'



const Main: React.FC = () => {
  const { page } = useParams<{ page?: string }>()
  const path = '/developer/application/:applicationId/:versionName/main'
  return (
    <ApplicationVersionContextProvider>
      <Header />
      <Layout>
        <Sider />
        {!page ? <Step /> : null}
        {page === 'authentication' ? <Authentication/> : null }
        {page === 'trigger' ? <Trigger /> : null}
        {page === 'action' ? <Action /> : null}
        {page === 'general' ? <UpdateApplication /> : null}
        {page === 'member' ? <Member /> : null}
        {page === 'version' ? <VersionPage /> : null}
        {page === 'submit-app' ? <SubmitApp /> : null}
        {page === 'advance' ? <Advance /> : null}
        <Switch>
          <Route path={`${path}/globalVariable`} component={GlobalVariable} />
          {/* <Route component={NotFound} /> */}
        </Switch>
      </Layout>
    </ApplicationVersionContextProvider>
  )
}

export default Main