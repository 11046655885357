import { State } from 'db'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useAScenario from 'src/hooks/useAScenario'

type GetBreadcrumb = {
  breadcrumb?: State[],
  firstState?: State,
  scenarioId: string,
  fetchingScenario: boolean
}

export default function useGetBreadcrumb(): GetBreadcrumb {
  const { scenarioId, stateId, isPath } = useParams<{ scenarioId: string, stateId?: string, isPath?: string }>()
  const { scenario, fetchingScenario } = useAScenario(parseInt(scenarioId), true)
  const [breadcrumb, setBreadcrumb] = useState<State[]>()

  useEffect(() => {
    const states = scenario?.states
    if (states && stateId) {
      const activeState = states.find(state => state.id === Number(stateId))
      if (activeState) {
        const breadcrumb = [...reqursivelyGetStateCondition(activeState, states)]
        setBreadcrumb(breadcrumb.sort((state1, state2) => state1.order - state2.order))
      }
    }
  }, [scenario, stateId, isPath])

  const reqursivelyGetStateCondition = (currentState: State, states: State[]): State[] => {
    const stateCondition = states.find(state => state.id === currentState.stateConditionId)
    if (currentState.conditionGroupOrder && currentState.id === Number(stateId) && !isPath) {
      if (stateCondition) {
        return [currentState, stateCondition, ...reqursivelyGetStateCondition(stateCondition, states)]
      }
      return [currentState]
    }
    if (stateCondition) {
      return [stateCondition, ...reqursivelyGetStateCondition(stateCondition, states)]
    }
    return []
  }

  return {
    scenarioId,
    firstState: scenario?.states?.[0],
    breadcrumb,
    fetchingScenario
  }
}