import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Button, Col, Row, Skeleton, Tag } from 'antd'
import { DateTime } from 'luxon'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { StyledAlert, StyledBoxIcon, StyledTabs } from '../../../../../../pages/components/StyledComponents'
import MonacoEditor from '../../../../../../pages/developer/components/form/MonacoEditor'
import { danger, primary65, success } from '../../../../../../util/colors'
import DataContent from './DataContent'
import HttpDataContent from './HttpDataContent'

interface Props {
  logTaskId: number,
  type?: 'Trigger' | 'Action',
  triggerActionName?: string,
  executionTime?: number,
  number?: number,
  createdAt?: string,
  error?: any,
  dataIn?: any,
  dataOut?: any,
  name?: string,
  logoUrlDataOut?: string,
  logoUrlDataIn?: string
}

const TaskCard: FC<Props> = ({ executionTime, logTaskId, type, triggerActionName, createdAt, error, name, logoUrlDataIn, logoUrlDataOut, number }) => {
  const [activeTab, setActiveTab] = useState<string>('in')
  const [detailVisible, setDetailVisible] = useState(!!error)

  const onSeeDetail = () => setDetailVisible(true)

  return (
    <StyledTaskCard>
      <Row gutter={[16, 16]} align="middle">
        <Col flex="0">
          <StyledBoxIcon>
            {
              logoUrlDataOut ? <img src={logoUrlDataOut} alt="" width="32px" /> : <Skeleton.Image style={{ width: 32, height: 32 }} />
            }
          </StyledBoxIcon>
        </Col>
        <Col flex="auto">
          <p>{type} — {triggerActionName}</p>
          <h5>{name || triggerActionName}</h5>
          {/* <p>{createdAt ? DateTime.fromJSDate(new Date(createdAt)).toFormat('DD t') : null}</p> */}
        </Col>
        <Col flex="0">
          {
            error ?
              <CloseCircleFilled style={{ color: danger, fontSize: 30 }} /> :
              <CheckCircleFilled style={{ color: success, fontSize: 30 }} />
          }
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Tag>Start at: <strong>{createdAt ? DateTime.fromJSDate(new Date(createdAt)).toFormat('DD HH:mm:ss.SSS') : null}</strong></Tag>
          {executionTime !== null && executionTime !== undefined ? <Tag>Execution time: <strong>{(executionTime/1000)?.toFixed(3) || 0}s</strong></Tag> : null}
        </Col>
      </Row>

      <br />
      {
        !detailVisible ?
          <Row>
            <Col>
              <Button onClick={onSeeDetail}>See Task execution details</Button>
            </Col>
          </Row>
          :
          null
      }

      {
        detailVisible ?
          <StyledTabs onChange={setActiveTab} activeKey={activeTab}>
            <StyledTabs.TabPane tab="Data In" key="in">
              <DataContent type="dataIn" number={number} logoUrl={logoUrlDataIn} logTaskId={logTaskId} isActive={activeTab === 'in'}  />
            </StyledTabs.TabPane>
            <StyledTabs.TabPane tab="API Request" key="http">
              <HttpDataContent logTaskId={logTaskId} isActive={activeTab === 'http'} />
            </StyledTabs.TabPane>
            {
              error ?
                <StyledTabs.TabPane tab="Error Message" key="error">
                  <StyledAlert type="error" message="The operation couldn't be completed. Please contact Otomatis's Admin for help or try again later" />
                  <br />
                  <MonacoEditor options={{ readOnly: true }} value={JSON.stringify(error, null, 2)} />
                </StyledTabs.TabPane> :
                <StyledTabs.TabPane tab="Data Out" key="out">
                  <DataContent type="dataOut" number={number} logTaskId={logTaskId} isActive={activeTab === 'out'} logoUrl={logoUrlDataOut} />
                </StyledTabs.TabPane>
            }
          </StyledTabs>
          :
          null
      }
    </StyledTaskCard>
  )
}

export default TaskCard

const StyledTaskCard = styled.div`
  margin-bottom: 20px;
  padding: 16px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;

  & ${StyledTabs} {
    border: none;
  }
  h5, p {
    color: ${primary65};
  }
`