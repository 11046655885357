import { Col } from 'antd'
import { LogJob } from 'db'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import useDeleteLogJobs from '../../../../../hooks/job/useDeleteJobs'
import useRetryLogJobs from '../../../../../hooks/job/useRetryLogJobs'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../types'
import CustomModalMethod from '../../../../../util/CustomModalMethod'

interface Props {
  setSelectedRows?: Dispatch<SetStateAction<LogJob[] | undefined>>,
  selectedRows?: LogJob[],
  tableState: TableState<LogJob>
}
const ActionsSelectedLogJobs: FC<Props> = ({ selectedRows, tableState, setSelectedRows }) => {
  const { deleteLogJobs } = useDeleteLogJobs(tableState)
  const { retryLogJobs } = useRetryLogJobs(tableState)
  const retryJobsAccepted = selectedRows?.filter(job => (job.status === 'error' || job.status === 'partialError' || job.status === 'pending') && !job.scenario?.deletedAt)

  useEffect(() => {
    setSelectedRows?.([])
  }, [tableState.filter])

  const onClickArchive = () => {
    CustomModalMethod.dangerConfirm({
      title: `Are you sure you want to archive ${selectedRows?.length} Log Job?`,
      content: 'You will not be able to access this Log Job again. This Action can not be undone.',
      onOk: async () => {
        return new Promise((resolve, reject) => {
          deleteLogJobs(selectedRows || []).then(() => {
            setSelectedRows?.([])
            return resolve(true)
          }).catch(() => reject('error'))
        }).catch(() => console.log('Oops errors!'))
      },
    })
  }

  const onClickRetry = () => {
    CustomModalMethod.confirm({
      title: `Are you sure you want to retry ${retryJobsAccepted?.length} Log Job?`,
      content: 'Note: You can only retry "Partial Error" or "Failed" job. Selected job with other status will be ignored.',
      onOk: async () => {
        return new Promise((resolve, reject) => {
          retryLogJobs(retryJobsAccepted || []).then(() => {
            setSelectedRows?.([])
            return resolve(true)
          }).catch(() => reject('error'))
        }).catch(() => console.log('Oops errors!'))
      },
    })
  }

  return (
    <>
      {
        selectedRows && selectedRows.length !== 0 ?
          <>
            <Col>Selected: {selectedRows?.length}</Col>
            {
              !tableState.filter?.deletedAt ?
                <>
                  <Col><StyledButton danger ghost onClick={onClickArchive} >Archive</StyledButton></Col>
                  <Col><StyledButton disabled={retryJobsAccepted?.length === 0} type="primary" ghost onClick={onClickRetry} >Retry Job</StyledButton></Col>
                </> :
                null
            }
          </>
          :
          null
      }
    </>
  )
}

export default ActionsSelectedLogJobs