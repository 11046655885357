import { AxiosPromise } from 'axios'
import { Application } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  updatedApplication?: Application,
  updatingApplication: boolean,
  errorUpdateApplication?: Error,
  updateApplication: (applicationId: number | string, payload: Partial<Application>) => AxiosPromise<{ application: Application }>
}

export default function useUpdateApplication(): Result {
  const { data, error, isValidating, call } = useApi<{ application: Application }>()

  return {
    updatedApplication: data?.application,
    updatingApplication: isValidating,
    errorUpdateApplication: error,
    updateApplication: async (applicationId: number | string, payload: Partial<Application>) => {
      return await call({
        url: `/application/${applicationId}`,
        method: 'patch',
        data: {
          application: payload
        }
      })
    }
  }
}