import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Col, Input, Layout, PageHeader, Row, Space, Table, Tag } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import { ColumnType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { Action } from 'db'
import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import AnchorWrapper from '../../../../../../components/AnchorWrapper'
import ButtonActionMore from '../../../../../../components/ButtonActionMore'
import ModalImportTriggerAction from '../../../../../../components/pages/developer/main/ModalImportTriggerAction'
import { useActions } from '../../../../../../hooks/application/action/useAllAction'
import useArchiveAction from '../../../../../../hooks/application/action/useArchiveAction'
import useDuplicateAction from '../../../../../../hooks/application/action/useDuplicateAction'
import useExportApplication from '../../../../../../hooks/application/main/useExportApplication'
import { TableState } from '../../../../../../types'
import { blue } from '../../../../../../util/colors'
import { PAGE_SIZE_OPTIONS } from '../../../../../../util/Constant'
import { compareString } from '../../../../../../util/functions'
import Notification from '../../../../../../util/Notification'
import { StyledButton, StyledCard } from '../../../../../components/StyledComponents'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { useSharedValueAction } from '../components/SharedValueActionContextProvider'


const ActionView: FC = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { applicationId } = useParams<{ applicationId: string }>()
  const { archiveAction, archivingAction, archivedAction } = useArchiveAction()
  const [version] = useApplicationVersionContext()
  const [tableState, setTableState] = useState<TableState<Action>>({
    sorter: { field: 'name', order: 'ascend' },
    pagination: {
      pageSize: 30
    }
  })
  const { actions, fetchingAllActions, revalidateActions, actionsLength } = useActions(applicationId, version?.id, tableState)
  const [, setValues] = useSharedValueAction()
  const [selectedActionIds, setSelectedActionIds] = useState<number[]>()
  const { exportActions } = useExportApplication()
  const [visible, setVisible] = useState(false)
  const { duplicateAction, duplicatingAction, activeDuplicateActionId } = useDuplicateAction()


  const onClickImport = () => setVisible(true)

  const onClickExport = () => {
    if (selectedActionIds && selectedActionIds?.length > 0) {
      exportActions(parseInt(applicationId), selectedActionIds)
    }
  }

  const columns: ColumnType<Action>[] = [
    { title: 'ID', dataIndex: 'id', render: (id) => <AnchorWrapper href={`${url}/${id}`} style={{ color: blue[4] }} >{id}</AnchorWrapper> },
    { title: 'Name',
      sorter: {
        compare: (a, b) => compareString(a.name, b.name)
      }
      , defaultSortOrder: 'ascend', dataIndex: 'name', render: (name, record) => <>{name} {record.isDraft ? <Tag color="orange">hidden</Tag> : null}</> },
    { title: 'Action', dataIndex: 'id', render: (id) => <Space>
      <ButtonActionMore
        overlay={<></>}
        buttonProps={{
          loading: duplicatingAction && id === activeDuplicateActionId
        }}
        listMenu={[
          {
            key: 'delete',
            onClick: (info) => onDelete(info.domEvent, id),
            children: 'Delete'
          },
          {
            key: 'duplicate',
            onClick: (info) => onDuplicate(info.domEvent, id),
            children: 'Duplicate'
          }
        ]}
      />
    </Space> },
  ]

  useEffect(() => {
    setSelectedActionIds?.([])
  }, [tableState.filter])

  const goToCreate = () => {
    setValues(null)
    history.push(`${url}/create`)
  }

  const onEdit = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> , id: number) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`${url}/${id}`)
  }

  const onDuplicate = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> , id: number) => {
    e.preventDefault()
    e.stopPropagation()
    duplicateAction({ applicationId: Number(applicationId), versionId: version?.id, actionId: id })
      .then(() => revalidateActions())
  }

  const onDelete = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement> | undefined, id: number) => {
    e?.preventDefault()
    e?.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this action?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action can not be undone',
      onOk() {
        archiveAction(Number(applicationId), id, version?.id)
      }
    })
  }

  useEffect(() => {
    if (archivedAction) {
      Notification.success({
        message: `Action "${archivedAction.name}" deleted succcesfully`
      })
      revalidateActions()
    }
  }, [archivedAction])

  const handleChange = (pagination: TablePaginationConfig, filter: Record<string, FilterValue | null>, sorter: SorterResult<Action> | SorterResult<Action>[], extra: TableCurrentDataSource<Action>) => {
    setTableState(prevState => ({ ...prevState, pagination, filter: { ...prevState.filter, ...filter }, sorter, extra }))
  }

  const onSearch = (value: string) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  return (
    <StyledActionView>
      <PageHeader
        title="Action"
      />
      <StyledCard>
        <Row align="middle" justify="space-between">
          <Col flex={0}>
            <Space direction="vertical">
              <h3>Build your{actions?.length === 0 ? ' First' : ''} Action</h3>
              <p>Create action for your application.</p>
            </Space>
          </Col>
          <Col flex={0}>
            <AnchorWrapper href={`${url}/create`}>
              <StyledButton type="primary" onClick={goToCreate}>
                Add Action
              </StyledButton>
            </AnchorWrapper>
          </Col>
        </Row>
      </StyledCard>
      <StyledCard className="cardTable">
        <Row gutter={[16, 16]} align="middle">
          {(selectedActionIds || []).length > 0 ? <Col>{selectedActionIds?.length} Selected</Col> : null}
          <Col>
            <StyledButton disabled={!selectedActionIds || selectedActionIds?.length === 0} onClick={onClickExport}>Export Actions</StyledButton>
          </Col>
          <Col>
            <StyledButton onClick={onClickImport}>Import Actions</StyledButton>
          </Col>
          <Col>
            <Input.Search placeholder="Search action" onSearch={onSearch} />
          </Col>
        </Row>
        <br/><br />
        <Table
          onChange={handleChange}
          pagination={{
            current: tableState.pagination?.current,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            total: actionsLength,
            pageSize: tableState.pagination?.pageSize
          }}
          rowSelection={{
            onChange: (keys) => {
              setSelectedActionIds(keys as number[])
            },
            selectedRowKeys: selectedActionIds
          }}
          onRow={(record) => ({
            style: { cursor: 'pointer' },
            onClick: (e) => onEdit(e, record.id)
          })}
          loading={fetchingAllActions || archivingAction}
          rowKey="id"
          scroll={{ x: true }}
          dataSource={actions}
          columns={columns}
        />
      </StyledCard>
      <ModalImportTriggerAction type="action" visible={visible} setVisible={setVisible} applicationId={parseInt(applicationId)} versionId={version?.id}/>
    </StyledActionView>
  )
}

export default ActionView

const StyledActionView = styled(Layout.Content)`
  ${StyledCard} {
    margin: 0 24px 16px;
    padding: 0;
  }
`