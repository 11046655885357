import { Trigger } from 'db'
import { useState } from 'react'
import { useGetTrigger } from './useTrigger'
import useCreateOrUpdateTrigger from './useCreateOrUpdateTrigger'

interface DuplicateTrigger {
  activeDuplicateTriggerId?: number,
  duplicateTrigger: (params: { applicationId: number, versionId?: number, triggerId: number }) => Promise<Partial<Trigger> | undefined>,
  duplicatingTrigger: boolean
}

export default function useDuplicateTrigger(): DuplicateTrigger {
  const { createOrUpdateTrigger } = useCreateOrUpdateTrigger()
  const [duplicatingTrigger, setDuplicatingTrigger] = useState(false)
  const { getTrigger } = useGetTrigger()
  const [activeDuplicateTriggerId, setActiveDuplicateTriggerId] = useState<number>()

  const duplicateTrigger = (params: { applicationId: number, versionId?: number, triggerId: number }) => {
    const { applicationId, triggerId } = params
    setActiveDuplicateTriggerId(triggerId)
    setDuplicatingTrigger(true)
    const trigger = getTrigger(params)
      .then(async ({ data }) => {
        const { trigger } = data
        const {  condition, cron, dummyTriggerHttpRequest, findLastStateFunc, isAggregator, isAsc, isGlobalAggregator, isUsingPagination, webhookResponseFunc, similarityThreshold, subscribeHttpRequest, testGuideline, visibility, unsubscribeHttpRequest, description, type, authenticationId, examplePayload, httpRequest, versionId, idempotencyKeyMap, paramSchema, stateKeyMap } = trigger
        return createOrUpdateTrigger(applicationId, {
          condition, cron, dummyTriggerHttpRequest, findLastStateFunc, isAggregator, isAsc, isGlobalAggregator, isUsingPagination, webhookResponseFunc, similarityThreshold, subscribeHttpRequest, testGuideline, visibility, unsubscribeHttpRequest, description, type, authenticationId, examplePayload, httpRequest, versionId, idempotencyKeyMap, paramSchema, stateKeyMap,
          isDraft: true,
          name: `Copy of "${trigger.name}"`
        }).then((data) => {
          setDuplicatingTrigger(false)
          setActiveDuplicateTriggerId(undefined)
          return data
        })
      })
    return trigger
  }

  return {
    activeDuplicateTriggerId,
    duplicateTrigger,
    duplicatingTrigger
  }
}