import { Col } from 'antd'
import { Scenario } from 'db'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useParams } from 'react-router'
import useArchiveScenarios from '../../../../../hooks/scenario/useArchiveScenarios'
import useDuplicateScenarios from '../../../../../hooks/scenario/useDuplicateScenarios'
import useMoveScenarios from '../../../../../hooks/scenario/useMoveScenarios'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../types'
import CustomModalMethod from '../../../../../util/CustomModalMethod'
import Notification from '../../../../../util/Notification'
import useOpenCloseModal from '../../../../../util/useOpenCloseModal'
import ModalMoveToFolder from './ModalMoveToFolder'

interface Props {
  setSelectedRows?: Dispatch<SetStateAction<Scenario[] | undefined>>,
  selectedRows?: Scenario[],
  tableState: TableState<Scenario>
}
const ActionsSelectedScenarios: FC<Props> = ({ selectedRows, tableState, setSelectedRows }) => {
  const { visible, closeModal, openModal } = useOpenCloseModal()
  const { movingScenarios, moveScenarios } = useMoveScenarios(tableState)
  const { archiveScenarios } = useArchiveScenarios(tableState)
  const { duplicateScenarios, duplicatingScenarios } = useDuplicateScenarios(tableState)
  const { folderId } = useParams<{ folderId?: string }>()

  useEffect(() => {
    setSelectedRows?.([])
  }, [tableState.filter])

  const onMoveScenario = (folderId: number, folderName: string) => {
    if (selectedRows) {
      moveScenarios(selectedRows, folderId).then(() => {
        closeModal()
        Notification.success({
          message: `Successfully moved to ${folderName} Folder`,
          description: `${selectedRows.length} Scenario is succesfully moved to ${folderName} Folder.`
        })
        if (folderId) {
          setSelectedRows?.([])
        }
      })
    }
  }

  const onClickArchive = () => {
    CustomModalMethod.dangerConfirm({
      title: `Are you sure you want to archive ${selectedRows?.length} Scenario?`,
      content: 'You will not be able to access this scenario again. This Action can not be undone.',
      onOk: async () => {
        return new Promise((resolve, reject) => {
          archiveScenarios(selectedRows || []).then(() => {
            setSelectedRows?.([])
            return resolve(true)
          }).catch(() => reject('error'))
        }).catch(() => console.log('Oops errors!'))
      },
    })
  }

  const onClickDuplicate = () => {
    duplicateScenarios(selectedRows || [])
  }

  const onClickRemoveFromFolder = () => {
    moveScenarios(selectedRows || [], null).then(() => {
      setSelectedRows?.([])
    })
  }

  return (
    <>
      {
        selectedRows && selectedRows.length !== 0 ?
          <>
            <Col>Selected: {selectedRows?.length}</Col>
            {
              !tableState.filter?.deletedAt ?
                <>
                  <Col><StyledButton danger ghost onClick={onClickArchive} >Archive</StyledButton></Col>
                  <Col><StyledButton loading={duplicatingScenarios} type="primary" ghost onClick={onClickDuplicate} >Duplicate</StyledButton></Col>
                  <Col><StyledButton type="primary" ghost onClick={openModal} >Move to Folder</StyledButton></Col>
                  {folderId ? <Col><StyledButton type="primary" ghost loading={movingScenarios} onClick={onClickRemoveFromFolder} >Remove from Folder</StyledButton></Col> : null}
                </> :
                null
            }
          </>
          :
          null
      }
      <ModalMoveToFolder onSubmit={onMoveScenario} submitting={movingScenarios} visible={visible} closeModal={closeModal} />
    </>
  )
}

export default ActionsSelectedScenarios