import { Scenario } from 'db'
import { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

export default function useSetInitialActiveState(scenario?: Scenario): void {
  const { params, url } = useRouteMatch<{ scenarioId: string, stateId: string, isPath?: string }>()
  const { replace } = useHistory()
  useEffect(() => {
    if (params && !params.stateId && scenario?.states) {
      const initActiveStateId = scenario.states[0].testData && !scenario.states[0].testData.error ?
        // if first state valid
        scenario.states.reduce((res, state) => {
          if (!res && !state.testData || state.testData?.error) {
            return state.id
          }
          return res
        }, 0)
        :
      // if first state not valid
        scenario.states.find(state => state.order === 0)?.id
      replace(`${url}/${initActiveStateId || scenario.states[0].id}`)
    }
    // if (params.stateId && !showOutline) {
    //   setShowOutline(!!scenario?.states?.find(state => `${state.id}` === params.stateId)?.stateConditionId || !params.isPath && !!scenario?.states?.find(state => `${state.id}` === params.stateId)?.conditionGroupOrder)
    // }
  }, [params, scenario])
}