import { Application, Scenario } from 'db'
import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'

type ContextValueType = Partial<Scenario> & {
  applications?: (Application | null)[]
}

const SharedValueScenarioContext = createContext<ContextValueType | null>(null)
const SharedValueScenarioSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode
}

const SharedValueScenarioContextProvider: FC<Props> = ({ children }) => {
  const [sharedValueScenario, setSharedValueScenario] = useState<ContextValueType | null>(null)

  return (
    <SharedValueScenarioContext.Provider value={sharedValueScenario}>
      <SharedValueScenarioSetterContext.Provider value={setSharedValueScenario}>
        {children}
      </SharedValueScenarioSetterContext.Provider>
    </SharedValueScenarioContext.Provider>
  )
}


export function useSharedValueScenario(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(SharedValueScenarioContext)
  const setterContext = useContext(SharedValueScenarioSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useSharedValueScenario must be used on child component of SharedValueScenarioContextProvider component'
  }

  return [valueContext, setterContext]
}

export default SharedValueScenarioContextProvider