import { AxiosPromise } from 'axios'
import { State } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  archivedAScenarioState?: State,
  archivingAScenarioState: boolean,
  errorArchiveAScenarioState?: Error,
  archiveAScenarioState: (scenarioId: number, stateId: number, isHardDelete?: boolean) => AxiosPromise<{ state: State }>
}

export default function useArchiveAScenarioState(): Result {
  const { data, error, isValidating, call } = useApi<{ state: State }>()

  return {
    archivedAScenarioState: data?.state,
    archivingAScenarioState: isValidating,
    errorArchiveAScenarioState: error,
    archiveAScenarioState: (scenarioId, stateId, isHardDelete?: boolean) => {
      return call({
        url: `/scenario/${scenarioId}/state/${stateId}${isHardDelete ? '' : '/archive'}`,
        method: 'delete'
      })
    }
  }
}