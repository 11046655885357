import Editor from '@monaco-editor/react'
import { FC, useRef } from 'react'
import styled from 'styled-components'

interface Props {
  id: string,
  value: string, // Ant Form need this for custom input
  onChange: (value: string) => void, // Ant Form need this for custom input
  height?: string | number,
  options?: any,
  defaultLanguage?: string

}

const RichCodeInput: FC<Props> = ({ id, value, onChange, height, defaultLanguage, options }) => {
  const editorRef = useRef<any | null>(null)

  function handleEditorDidMount(editor: any, _: any) {
    editorRef.current = editor
  }

  function onChangeValue(value?: string) {
    onChange?.(value || '')
  }
  return (
    <StyledContainer height={height} id={id}>
      <Editor
        defaultValue={value}
        value={value || ''}
        keepCurrentModel={true}
        defaultLanguage={defaultLanguage || 'javascript'}
        onMount={handleEditorDidMount}
        onChange={onChangeValue}
        theme='vs-dark'
        height="100%"
        options={{
          tabSize: 2,
          quickSuggestions: false,
          minimap: {
            enabled: false,
          },
          ...options
        }}
      />
    </StyledContainer>
  )
}

export default RichCodeInput

const StyledContainer = styled.div<{ height?: string | number }>`
  resize: vertical;
  overflow-y: auto;
  background: #1e1e1e;
  height: ${props => props.height || '360px'};
  min-height: ${props => props.height || '360px'};
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 14px;
    height: 0;
  }
  ::-webkit-resizer {
    /* border-top: none;
    border-left: none;
    border-right: 4px double grey; 
    border-bottom: 4px double blue; */
    background: red;
  }
  scrollbar-width: none;
`