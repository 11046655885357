import { Card, Spin } from 'antd'
import { State } from 'db'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useApplicationByActionType from '../../../../../hooks/application/main/useApplicationByActionType'
import useCreateAScenarioState from '../../../../../hooks/scenario/state/useCreateAScenarioState'
import useUpdateAScenarioState from '../../../../../hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from '../../../../../hooks/useFetch'

interface Props {
  stateConditionId?: number,
  onSuccessCreateNewState: () => void,
  states?: State[],
  scenarioId: number,
  state: State
}
const ChooseConditionEvent: FC<Props> = ({ stateConditionId, states, state, scenarioId, onSuccessCreateNewState }) => {
  const { revalidateAScenario, revalidateAScenarioState } = useRevalidateFetch()
  const { createAScenarioState } = useCreateAScenarioState()
  const [loading, setLoading] = useState(false)
  const { getApplicationByActionType, actionId, fetchingApplication } = useApplicationByActionType()
  const history = useHistory()
  const { updateAScenarioState } = useUpdateAScenarioState()

  useEffect(() => {
    const createStates = async () => {
      setLoading(true)
      // A = 65 in ascii
      const baseCharAsNumber = 64
      const nPathState = states?.filter(state => state.conditionGroupOrder).length || 0
      // create 2 path state
      const state1 = await createAScenarioState(scenarioId, {
        parentId: state.id,
        stateConditionId: stateConditionId,
        actionId: actionId,
        order: state.order + 1,
        conditionGroupOrder: state.order + 1,
        name: `Path ${String.fromCharCode(baseCharAsNumber + nPathState + 1)}`
      })
      const state2 = await createAScenarioState(scenarioId, {
        parentId: state.id,
        stateConditionId: stateConditionId,
        actionId: actionId,
        order: state.order + 1,
        conditionGroupOrder: state.order + 1,
        name: `Path ${String.fromCharCode(baseCharAsNumber + nPathState + 2)}`
      })

      if (state1 && state2) {
        // update childrenIds of parent
        updateAScenarioState(scenarioId, state.id, {
          childrenIds: [state1.data.state.id, state2.data.state.id]
        }).then(() => {
          revalidateAScenarioState(scenarioId, state.id)
          revalidateAScenario(scenarioId)
        }).finally(() => {
          setLoading(false)
        })
        history.replace(`/app/scenario/${scenarioId}/edit/${state1.data.state.id}/path`)
      }
      onSuccessCreateNewState()

    }
    if (actionId) {
      createStates()
    } else {
      // console.log('tes')
      getApplicationByActionType('path')
    }
  }, [actionId])

  return (
    <Spin spinning={loading || fetchingApplication}>
      <StyledCardCreateStateActionForm />
    </Spin>
  )
}

export default ChooseConditionEvent

const StyledCardCreateStateActionForm = styled(Card)`
  width: 100%;
  margin-top: 12px;
`