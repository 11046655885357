import { Version } from 'db'
import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'

type ContextValueType = Partial<Version> | null

const ApplicationVersionContext = createContext<ContextValueType>(null)
const ApplicationVersionSetterContext = createContext<Dispatch<SetStateAction<ContextValueType>>>(() => null)

type Props = {
  children: ReactNode
}

const ApplicationVersionContextProvider: FC<Props> = ({ children }) => {
  const [sharedValueAction, setApplicationVersion] = useState<ContextValueType>(null)

  return (
    <ApplicationVersionContext.Provider value={sharedValueAction}>
      <ApplicationVersionSetterContext.Provider value={setApplicationVersion}>
        {children}
      </ApplicationVersionSetterContext.Provider>
    </ApplicationVersionContext.Provider>
  )
}


export function useApplicationVersionContext(): [ContextValueType, Dispatch<SetStateAction<ContextValueType>>] {
  const valueContext = useContext(ApplicationVersionContext)
  const setterContext = useContext(ApplicationVersionSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useApplicationVersion must be used on child component of ApplicationVersionContextProvider component'
  }

  return [valueContext, setterContext]
}

export default ApplicationVersionContextProvider