import { Action, FieldSchema } from 'db'
import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'

type ContextValueType = Partial<Action> & {
  activeEditFieldForm?: FieldSchema & { idx?: number },
  onAddFieldForm?: boolean
}

const SharedValueActionContext = createContext<ContextValueType | null>(null)
const SharedValueActionSetterContext = createContext<Dispatch<SetStateAction<ContextValueType | null>>>(() => null)

type Props = {
  children: ReactNode
}

const SharedValueActionContextProvider: FC<Props> = ({ children }) => {
  const [sharedValueAction, setSharedValueAction] = useState<ContextValueType | null>(null)

  return (
    <SharedValueActionContext.Provider value={sharedValueAction}>
      <SharedValueActionSetterContext.Provider value={setSharedValueAction}>
        {children}
      </SharedValueActionSetterContext.Provider>
    </SharedValueActionContext.Provider>
  )
}


export function useSharedValueAction(): [ContextValueType | null, Dispatch<SetStateAction<ContextValueType | null>>] {
  const valueContext = useContext(SharedValueActionContext)
  const setterContext = useContext(SharedValueActionSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useSharedValueAction must be used on child component of SharedValueActionContextProvider component'
  }

  return [valueContext, setterContext]
}

export default SharedValueActionContextProvider