import { SorterResult } from 'antd/lib/table/interface'
import { Scenario } from 'db'
import { useEffect, useState } from 'react'
import { Implikasi } from '../../service/Implikasi'
import { TableState } from '../../types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from '../../util/Constant'
import useFetch from '../useFetch'

type Result = {
  scenariosLength?: number,
  scenarios?: Scenario[],
  fetchingScenarios: boolean,
  errorFetchScenarios?: any
}

const usePublicScenarios: (tableState?: TableState<Scenario>) => Result = (tableState) => {
  const key = `publicScenarios${tableState ? JSON.stringify(tableState) : ''}`
  const url = '/scenario/templates'
  const [scenarios, setScenarios] = useState<Scenario[]>()
  const [pageScenarios, setPageScenarios] = useState<Record<number, Scenario[]>>()

  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const sorters: SorterResult<Scenario>[] | undefined = Array.isArray(tableState?.sorter) ? tableState?.sorter : undefined
  const sorterOrder = (tableState?.sorter as SorterResult<Scenario>)?.order
  const sorterField = (tableState?.sorter as SorterResult<Scenario>)?.field
  const filters = tableState?.filter || {}
  const search = (Object.keys(filters) || [])?.filter(field => filters[field]).reduce((res, field) => {
    if (field === 'search') {
      const ids = (filters[field] as string)?.match(/\d+/g) || []
      const searchByIds = ids.length > 0 ? `,scenario.id.in=(${ids.join(',')})` : ''
      return `${res},${searchByIds ? 'or=(' : ''}scenario.name.ilike=${filters[field]}${searchByIds ? `${searchByIds})` : ''}`
    }
    if (field === 'deletedAt'  && filters[field]) {
      return `${res},scenario.${field}.is=not null`
    }
    // if (field === 'applicationId' && filters[field]) {
    //   return `${res} and ("applicationTrigger".id in (${(filters[field] as string[])?.map(val => `'${val}'`).join(', ')}) or "applicationAction".id in (${(filters[field] as string[])?.map(val => `'${val}'`).join(', ')}))`
    // }
    if (filters[field]) {
      return `${res},scenario.${field}.in=(${(filters[field] as string[])?.map(val => `'${val}'`).join(',')})`
    }
    return res
  }, 'true')

  const { data, error, isValidating } = useFetch<{ scenarios: Scenario[], length: number }>(
    `${key}-${url}`, {},
    {
      fetcher: async () => {
        const res = await Implikasi(url,  {
          method: 'get',
          params: {
            orderBy: sorterField && sorterOrder ?
              `scenario.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}`
              :  sorters
              // ?.sort((s1, s2) => s1.field?.toString().localeCompare(s2.field?.toString() || '') || 0)
                ?.reduce((res, sorter, index) => {
                  const sorterField = sorter.field
                  const sorterOrder = sorter.order
                  return `${res}${index === 0 ? '' : ','}scenario.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}`
                }, ''),
            and: `(${search})`,
            expands: 'state',
            skip: (currentPage - 1) * pageSize,
            take: pageSize,
          },
          // data: {
          //   orderBy: sorterField && sorterOrder ? {
          //     [`scenario.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
          //   } :  sorters
          //     // ?.sort((s1, s2) => s1.field?.toString().localeCompare(s2.field?.toString() || '') || 0)
          //     ?.reduce((res, sorter) => {
          //       const sorterField = sorter.field
          //       const sorterOrder = sorter.order
          //       return {
          //         ...res,
          //         [`scenario.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
          //       }
          //     }, {}),
          //   search: search
          // }
        })
        return res.data
      }
    },
  )

  useEffect(() => {
    if (data?.scenarios) {
      setPageScenarios(prevState => {
        if (currentPage === 1) {
          return {
            [currentPage] : data.scenarios
          }
        }
        return {
          ...prevState,
          [currentPage]: data.scenarios
        }
      })
    }
  }, [data?.scenarios])

  useEffect(() => {
    setScenarios(Object.keys(pageScenarios || {}).reduce((res: Scenario[], key) => {
      return [
        ...res,
        ...pageScenarios?.[key]
      ]
    }, []))
  }, [pageScenarios])

  return {
    scenariosLength: data?.length,
    scenarios: scenarios,
    fetchingScenarios: isValidating,
    errorFetchScenarios: error
  }
}

export default usePublicScenarios