import { Version } from 'db'
import { PublicConfiguration } from 'swr/dist/types'
import useFetch from '../../useFetch'


type Result = {
  versionId?: number
}

export default function useVersionName(applicationId?: number | string, versionName?: string, config?: Partial<PublicConfiguration<any>>): Result {
  const url = applicationId && versionName ? `/application/${applicationId}/versions?name=${versionName}` : null
  const { data } = useFetch<{ versions: Version[] }>(url, {}, { revalidateOnFocus: false, ...config })

  return {
    versionId: data?.versions[0]?.id
  }
}
