import { State } from 'db'
import { Error } from '../types'
import useFetch from './useFetch'


type Result = {
  state?: State,
  fetchingState: boolean,
  errorFetchState?: Error
}

export default function useAScenarioState(scenarioId: number, stateId?: number, expandTriggerOrAction?: boolean): Result {
  const url = scenarioId && stateId ? `/scenario/${scenarioId}/state/${stateId}${expandTriggerOrAction ? '?expands=trigger,action' : ''}` : null
  const { data, error, isValidating } = useFetch<{ state: State }>(url, {
  }, { revalidateOnFocus: false })

  return {
    state: data?.state,
    fetchingState: isValidating,
    errorFetchState: error
  }
}
