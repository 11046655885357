import { Scenario, State } from 'db'
import { useEffect, useState } from 'react'
import { Error, TableState } from '../../types'
import { useApiScenario } from '../useAScenario'
import { useRevalidateFetch } from '../useFetch'
import useCreateScenario from './useCreateScenario'

type Result = {
  isCreatingTemplateScenario: boolean,
  createATemplateScenario: (scenarioId: number) => Promise<boolean>,
  errorCreateTemplateScenario?: Error
}

export default function useCreateTemplateScenarioFromScenario(tableState?: TableState<Scenario>): Result {
  const { createScenario, errorCreateScenario } = useCreateScenario()
  const [isCreatingTemplateScenario, setIsCreatingTemplateScenario] = useState(false)
  const { getScenario, errorFetchScenario } = useApiScenario()
  const { revalidateAScenario, revalidateScenarios } = useRevalidateFetch()


  const createATemplateScenario  = (scenarioId: number) => {
    setIsCreatingTemplateScenario(true)
    const duplicateScenario = getScenario(scenarioId, true)
      .then(async ({ data }) => {
        const { states, name } = data.scenario
        return createScenario({ name: name, public: true, published: false, states: states?.map(state => {
          const { params, testData, realTestData, bundleId, conditions, triggerId, actionId, order, name, conditionGroupOrder, stateConditionId, id, parentId, childrenIds } = state
          return {
            name: name || state.trigger?.name || state.action?.name,
            params, testData, realTestData, bundleId, conditions,
            triggerId, actionId, order, conditionGroupOrder, stateConditionId, id, childrenIds, parentId
          }
        }) as State[] })
          .then(() => {
            setIsCreatingTemplateScenario(false)
            // revalidate any component that used Scenario model
            revalidateScenarios(tableState)
            revalidateAScenario(scenarioId)
            return true
          })
      }).catch(() => {
        setIsCreatingTemplateScenario(false)
        return false
      })
    return duplicateScenario
  }

  useEffect(() => {
    if (errorCreateScenario || errorFetchScenario) {
      setIsCreatingTemplateScenario(false)
    }
  }, [errorCreateScenario, errorFetchScenario])

  return {
    errorCreateTemplateScenario: errorCreateScenario || errorFetchScenario,
    isCreatingTemplateScenario,
    createATemplateScenario
  }
}