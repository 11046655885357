import { EnterOutlined, FolderOpenFilled, FolderOutlined, LoadingOutlined } from '@ant-design/icons'
import { Input, Space } from 'antd'
import { FC, MouseEventHandler, useCallback, useState } from 'react'
import { ConnectDragSource } from 'react-dnd'
import OutsideClickHandler from 'react-outside-click-handler'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'
import useRenameFolder from '../../../../../../hooks/folder/useRenameFolder'
import ButtonDragHandle from '../../../../../ButtonDragHandle'
import FolderDropdownMenu from './FolderDropdownMenu'
import useFolderActiveKey from './useFolderActiveKey'
import useNavigateTabLeftPane from './useNavigateTabLeftPane'

let timer

const FolderContent: FC<{ id: number, index: number, name: string, dragRef: ConnectDragSource, isLoading?: boolean }> = ({ id, index, dragRef, name, isLoading }) => {
  const { path } = useRouteMatch()
  const [editing, setEditing] = useState(false)
  const { renameFolder, renamingFolder } = useRenameFolder()
  const { navigateTabLeftPane: navigateTab } = useNavigateTabLeftPane()
  const activeKey = useFolderActiveKey()
  const isAllFolder = id === 0 && index === 0

  const onClickMenu = useCallback(() => {
    navigateTab(`${path.replace(activeKey, '')}/${id === 0 ? '' : id}`)
  }, [activeKey, id])

  const onClickMenuMapping: MouseEventHandler<HTMLParagraphElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    clearTimeout(timer)
    if (e.detail === 1) {
      timer = setTimeout(() => {
        onClickMenu()
      }, 200)
    } else if (e.detail === 2) {
      setEditing(true)
    }
  }
  const onIn = (e: any) => {
    e.stopPropagation()
  }

  const onEnter = (e: any) => {
    onIn(e)
    // console.log(e.target.value)
    renameFolder(id, e.target.value).then(() => {
      setEditing(false)
    })
  }

  // ---

  const onClickRenameFolder = () => {
    setEditing(true)
  }

  return (
    <>
      <StyledSpace>
        {isAllFolder ? <div style={{ width: 14 }} /> : <StyledButtonDragHandle className="folder-drag-handle-btn" ref={dragRef} />}
        { // folder icon
          isAllFolder && activeKey === '' || activeKey === `/${id}` ? // if this is 'All' folder and activeKey is all folder
            <FolderOpenFilled /> :
            <FolderOutlined />
        }
        { // folder name and input
          editing ?
            <OutsideClickHandler onOutsideClick={() => setEditing(false)}>
              <div style={{ width: '90%' }}>
                <Input autoFocus suffix={renamingFolder ? <LoadingOutlined /> : <EnterOutlined />} defaultValue={name} onClick={onIn} onPressEnter={onEnter} onChange={onIn} />
              </div>
            </OutsideClickHandler>
            :
            <StyledP onClick={onClickMenuMapping}>
              {name}
            </StyledP>
            // <Tooltip title={'Double click to rename'} placement="right">
            // </Tooltip>
        }
        {isLoading || renamingFolder ? <LoadingOutlined /> : null}
      </StyledSpace>

      { // folder dropdown menu
        !isAllFolder ? // dropdown action only for any folder except 'All' folder
          <FolderDropdownMenu
            id={id}
            name={name}
            index={index}
            onClickRenameFolder={onClickRenameFolder}
          /> :
          null // no dropdown action for 'All' folder
      }
    </>
  )
}

export default FolderContent

const StyledSpace = styled(Space)`
`

export const StyledButtonDragHandle = styled(ButtonDragHandle)`
  opacity: 0;
`

export const StyledP = styled.p`
  cursor: pointer;
  overflow-x: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
`