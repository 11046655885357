import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import DataSuggestionContextProvider from '../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import useAction from '../../../../../../hooks/application/action/useAction'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import ActionSteps from '../components/ActionStep'
import { useSharedValueAction } from '../components/SharedValueActionContextProvider'

const CreateAction: FC = () => {
  const [values, setValues] = useSharedValueAction()
  const { applicationId } = useParams<{ applicationId: string}>()
  const [version] = useApplicationVersionContext()
  const { action } = useAction(applicationId, values?.id, version?.id)

  useEffect(() => {
    if (action) {
      setValues(action)
    }
  }, [action])

  return (
    <DataSuggestionContextProvider>
      <ActionSteps type="create" />
    </DataSuggestionContextProvider>
  )
}

export default CreateAction