import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Avatar, Col, Empty, Layout, Modal, Row, Space, Table, Tag } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import { ColumnType } from 'antd/lib/table'
import { Application, Version } from 'db'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import useLastApplicationReview from '../../../../../hooks/application/applicationReview/useLastApplicationReview'
import useApplication from '../../../../../hooks/application/main/useApplication'
import useUpdateApplication from '../../../../../hooks/application/main/useUpdateApplication'
import { blue } from '../../../../../util/colors'
import { getInitialName } from '../../../../../util/formatter'
import Notification from '../../../../../util/Notification'
import PageHeader from '../../../../components/PageHeader'
import { StyledCard } from '../../../../components/StyledComponents'

const SubmitApp: FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>()
  const { application, fetchingApplication, revalidateApplication } = useApplication(applicationId)
  const [applications, setApplications] = useState<Application[]>()
  const { updateApplication, updatingApplication, updatedApplication } = useUpdateApplication()
  const { lastApplicationReview } = useLastApplicationReview(applicationId)

  useEffect(() => {
    if (application && lastApplicationReview) {
      setApplications([application])
    } else {
      setApplications([])
    }
  }, [application, lastApplicationReview])

  const columns: ColumnType<Application>[] = [
    { title: '', dataIndex: 'logoUrl', render: (logoUrl, record) => logoUrl ? <img src={logoUrl} width="30px" /> : <Avatar icon={getInitialName(record.name)} /> },
    { title: 'App', dataIndex: 'name' },
    { title: 'Version', dataIndex: 'versions', render: (versions?: Version[]) => versions ? versions[0]?.name : null },
    { title: 'Status', dataIndex: 'id', render: (_, record) => record.publishedAt ?
      <Tag color="green">Accepted</Tag> :
      record.submittedAt ? 'Waiting' : <Tag color="red">Rejected</Tag> },
    { title: 'Action', dataIndex: 'id', render: (_, record) => <Space>
      {
        record.publishedAt && !record.public ? <a onClick={(e) => onPublish(e, record)} style={{ color: blue[6] }} >Publish</a> :
          null
      }
      <a onClick={(e) => onViewFeedback(e, record)} style={{ color: blue[6] }} >View Feedback</a>
    </Space> },
  ]

  const onPublish = (e: MouseEvent<HTMLElement>, _: Application) => {
    e.preventDefault()
    e.stopPropagation()
    confirm({
      title: `Are you sure you want to publish ${application?.name}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Otomatis’ users will be able to use your Application',
      okText: 'Yes, Publish',
      onOk() {
        return new Promise((resolve, reject) => {
          updateApplication(applicationId, {
            public: true,
            publishedAt: new Date()
          }).then(() => {
            resolve(null)
            openNotif('success', `You have successfully published ${application?.name}`, 'User will soon use your Application to create scenario')
          }).catch(() => reject)
        })
          // .catch(() => console.log('Oops errors!'))
          .catch(() => {})
      },
      onCancel() {},
    })
  }

  const onViewFeedback = (e: MouseEvent<HTMLElement> | undefined, _: Application) => {
    e?.preventDefault()
    e?.stopPropagation()
    Modal.info({
      title: `Feedback of ${application?.name}`,
      content: lastApplicationReview?.feedback
    })
  }

  const openNotif = (type: 'success' | 'error', title: string, description: string) => {
    Notification[type]({
      message: title,
      description: description
    })
  }

  useEffect(() => {
    if (updatedApplication) {
      revalidateApplication()
    }
  }, [updatedApplication])

  return (
    <StyledApplication>
      <PageHeader title="Submit App" description="Submit your app to be reviewed by admin." />
      <StyledCard>
        <Row justify="space-between" align="middle">
          <Col>
            <h4>App Submission</h4>
          </Col>
        </Row>
        <br />
        {
          applications?.length === 0 ? <Empty /> :
            <Table
              dataSource={applications}
              loading={fetchingApplication || updatingApplication}
              rowKey="id"
              scroll={{ x: true }}
              columns={columns}
            />
        }
      </StyledCard>
    </StyledApplication>
  )
}

export default SubmitApp

export const StyledApplication = styled(Layout.Content)`
  h4 {
    color: rgba(0, 0, 0, 0.65);
  }
  ${StyledCard} {
    margin: 16px 24px;
  }
`