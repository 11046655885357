import { SorterResult } from 'antd/lib/table/interface'
import { Application } from 'db'
import { Implikasi } from '../../../service/Implikasi'
import { TableState } from '../../../types'
import { DEFAULT_PAGE_CURRENT, DEFAULT_PAGE_SIZE } from '../../../util/Constant'
import { compareString } from '../../../util/functions'
import useFetch from '../../useFetch'


type Result = {
  applicationsLength?: number,
  revalidateApplications: () => void,
  applications?: Application[],
  fetchingApplications: boolean,
  errorFetchApplications?: any
}


const useMyApplications: (userId?: number, tableState?: TableState<Application>) => Result = (userId, tableState) => {
  const url = '/applications'
  const key = userId ? `/applications?expands=version&createdById=${userId}${JSON.stringify(tableState)}` : null

  const pageSize = tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE
  const currentPage = tableState?.pagination?.current || DEFAULT_PAGE_CURRENT
  const filters = tableState?.filter || {}
  const search = (Object.keys(filters) || [])?.filter(field => filters[field] !== undefined && filters[field] !== null).reduce((res, field) => {
    if (field === 'search' && filters[field]) {
      const ids = (filters[field] as string)?.match(/\d+/g) || []
      const searchByIds = ids.length > 0 ? `,application.id.in=(${ids.join(',')})` : ''
      return `${res},${searchByIds ? 'or=(' : ''}application.name.ilike=${filters[field]}${searchByIds ? `${searchByIds})` : ''}`
    }
    if (field === 'public' && filters[field] !== undefined) {
      return `${res},application.public.in=(${filters[field]})`
    }
    return res
  }, 'true')
  const sorters: SorterResult<Application>[] | undefined = Array.isArray(tableState?.sorter) ? tableState?.sorter : undefined
  const sorterOrder = (tableState?.sorter as SorterResult<Application>)?.order
  const sorterField = (tableState?.sorter as SorterResult<Application>)?.field

  const { data, error, isValidating, revalidateFetch } = useFetch<{ applications: Application[], length: number }>(key, {}, {
    fetcher: async () => {
      const res = await Implikasi(url, {
        method: 'get',
        params: {
          orderBy: sorterField && sorterOrder ? `application.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}` :
            sorters?.sort((s1, s2) => compareString(s1.field?.toString(), s2.field?.toString()))?.reduce((res, sorter, index) => {
              const sorterField = sorter.field
              const sorterOrder = sorter.order
              return `${res}${index === 0 ? '' : ','}application.${sorterField}:${sorterOrder === 'ascend' ? 'asc' : 'desc'}`
            }, ''),
          and: `(${search},or=(createdById.eq=${userId},member.userId.eq=${userId}))`,
          expands: 'version',
          // createdById: userId,
          skip: (currentPage - 1) * pageSize,
          take: pageSize,
        },
        data: {
          // orderBy: sorterField && sorterOrder ? {
          //   [`application.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
          // } :  sorters?.sort((s1, s2) => s1.field?.toString().localeCompare(s2.field?.toString() || '') || 0)?.reduce((res, sorter) => {
          //   const sorterField = sorter.field
          //   const sorterOrder = sorter.order
          //   return {
          //     ...res,
          //     [`application.${sorterField}`] : sorterOrder === 'ascend' ? 'asc' : 'desc'
          //   }
          // }, {}),
          // 'search': `${search} and ("createdById" = ${userId} OR "member"."userId" = ${userId})`
        }
      })
      return res.data
    }
  })

  return {
    revalidateApplications: () => revalidateFetch(key),
    applicationsLength: data?.length,
    applications: data?.applications,
    fetchingApplications: isValidating,
    errorFetchApplications: error
  }
}

export default useMyApplications