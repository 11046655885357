import { AxiosPromise } from 'axios'
import { GlobalVariable } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  archivedGlobalVariable?: GlobalVariable,
  archivingGlobalVariable: boolean,
  errorArchiveGlobalVariables?: Error,
  archiveGlobalVariable: (applicationId: number, globalVariableId: number, versionId?: number) => AxiosPromise<{ globalVariable: GlobalVariable }>
}

export default function useArchiveGlobalVariable(): Result {
  const { data, error, isValidating, call } = useApi<{ globalVariable: GlobalVariable }>()

  return {
    archivedGlobalVariable: data?.globalVariable,
    archivingGlobalVariable: isValidating,
    errorArchiveGlobalVariables: error,
    archiveGlobalVariable: (applicationId, globalVariableId, versionId) => {
      return call({
        url: `/application/${applicationId}/globalVariable/${globalVariableId}/archive`,
        params: {
          versionId
        },
        method: 'delete',
      })
    }
  }
}