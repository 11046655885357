import { Calendar, CalendarProps, Input } from 'antd'
import moment from 'moment'
import { FC, InputHTMLAttributes, useState } from 'react'
import { StyledButton } from 'src/pages/components/StyledComponents'
import styled from 'styled-components'

interface Props {
  value?: string,
  onChangeDate: (value: string) => void
}

const DateTimeDataPicker: FC<Props> = ({ value, onChangeDate }) => {
  const [hour, setHour] = useState<number | string | undefined>(moment(value || undefined).get('hour'))
  const [minute, setMinute] = useState<number | string | undefined>(moment(value || undefined).get('minute'))
  const [second, setSecond] = useState<number | string | undefined>(moment(value || undefined).get('second'))
  const [calendar, setCalendar] = useState<moment.Moment | undefined>(value ? moment(value) : undefined)

  const onChangeCalendar: CalendarProps<moment.Moment>['onChange'] = (value) => {
    const newHour = value.get('hour')
    const newMinute = value.get('minute')
    const newSecond = value.get('second')
    setHour(`${newHour}`)
    setMinute(`${newMinute}`)
    setSecond(`${newSecond}`)
    setCalendar(value)
  }

  const onChangeHour: InputHTMLAttributes<HTMLInputElement>['onChange']  = (e) => {
    console.log(e.target.value)
    const newHour = e.target.value
    setHour(newHour)
    if (calendar) {
      const newDate = calendar.set({ hour: parseInt(newHour) })
      console.log(newDate.toISOString())
      setCalendar(newDate)
    }
  }

  const onChangeMinute: InputHTMLAttributes<HTMLInputElement>['onChange']  = (e) => {
    const newMinute = e.target.value
    setMinute(newMinute)
    if (calendar) {
      const newDate = calendar.set({ minute: parseInt(newMinute) })
      setCalendar(newDate)
    }
  }

  const onChangeSecond: InputHTMLAttributes<HTMLInputElement>['onChange']  = (e) => {
    const newSecond = e.target.value
    setSecond(newSecond)
    if (calendar) {
      const newDate = calendar.set({ second: parseInt(newSecond) })
      setCalendar(newDate)
    }
  }

  const onClickOk = () => {
    if (calendar) {
      onChangeDate(calendar.set({ millisecond: 0 }).toISOString())
    }
  }
  return (
    <ContainerCalendar className="p-4">
      <strong>Date</strong>
      <StyledCalendar fullscreen={false} value={calendar} onChange={onChangeCalendar} />
      <strong>Time</strong>
      <Input.Group compact>
        <StyledInput
          onChange={onChangeHour}
          value={hour}
          type="number"
          min="00"
          max="24"
          // addonBefore={<ClockCircleTwoTone twoToneColor="#F3AD3D" />}
          width="fit-content"
          style={{ width: 'fit-content', textAlign: 'right' }}
          placeholder="HH"
        />
        <StyledInput
          onChange={onChangeMinute}
          value={minute}
          type="number"
          min="00"
          max="59"
          addonBefore=":"
          style={{ width: 'fit-content', textAlign: 'right' }}
          placeholder="MM"
        />
        <StyledInput
          onChange={onChangeSecond}
          value={second}
          type="number"
          min="00"
          max="59"
          addonBefore=":"
          style={{ width: 'fit-content', textAlign: 'right' }}
          placeholder="SS"
        />
      </Input.Group>
      <StyledSelectDateTimeBtn htmlType="button" onClick={onClickOk} className="mt-2" disabled={!calendar}>
        {`Select ${calendar ? `'${new Intl.DateTimeFormat('en-ID', {
          hour12: false,
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        }).format(new Date(calendar.toISOString()))}'` : ''}`}
      </StyledSelectDateTimeBtn>
    </ContainerCalendar>
  )
}

export default DateTimeDataPicker

export const ContainerCalendar = styled.div`
  max-width: 350px;
`

export const StyledSelectDateTimeBtn = styled(StyledButton)`
  width: 100%;
  background: #F3AD3D;
  border-color: #F3AD3D;
  color: #000;

  &:hover {
    border-color: #F3AD3D;
    color: #c47900;
  }
`

const StyledInput = styled(Input)`
  .ant-input:focus {
    border-color: #F3AD3D;
    box-shadow: 0 0 0 2px #F3AD3D80;
  }
  .ant-input:hover {
    border-color: #F3AD3D;
  }
`

export const StyledCalendar = styled(Calendar)`
  .ant-picker-calendar-header {
    .ant-select {
      flex-grow: 1;
      &:hover, &:focus {
        .ant-select-selector {
          border-color: #f3ad3d;
          outline-color: #F3AD3D;
        }
      }

      &.ant-select-focused .ant-select-selector{
        box-shadow: 0 0 0 2px #F3AD3D80;
        border-color: #f3ad3d;
      }
    }
    .ant-radio-button-wrapper:hover {
      color: #F3AD3D;
    }
    .ant-radio-button-wrapper-checked {
      border-color: #F3AD3D;
      color: #c47900;
      font-weight: 600;
      &:first-child {
        border-color: #F3AD3D;
      }
      &:before {
        background: #F3AD3D;
      }

    }
  }
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background: #F3AD3D;
    color: #000;
    &:before {
      border-color: #F3AD3D;
    }
  }
  .ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #F3AD3D;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #ffe6be;
  }
`