import { Action } from 'db'
import { useState } from 'react'
import { useGetAction } from './useAction'
import useCreateOrUpdateAction from './useCreateOrUpdateAction'

interface DuplicateAction {
  activeDuplicateActionId?: number,
  duplicateAction: (params: { applicationId: number, versionId?: number, actionId: number }) => Promise<Partial<Action> | undefined>,
  duplicatingAction: boolean
}

export default function useDuplicateAction(): DuplicateAction {
  const { createOrUpdateAction } = useCreateOrUpdateAction()
  const [duplicatingAction, setDuplicatingAction] = useState(false)
  const { getAction } = useGetAction()
  const [activeDuplicateActionId, setActiveDuplicateActionId] = useState<number>()

  const duplicateAction = (params: { applicationId: number, versionId?: number, actionId: number }) => {
    const { applicationId, actionId } = params
    setActiveDuplicateActionId(actionId)
    setDuplicatingAction(true)
    const action = getAction(params)
      .then(async ({ data }) => {
        const { action } = data
        const {  category, description, type, authenticationId, examplePayload, httpRequest, versionId, idempotencyKeyMap, paramSchema, stateKeyMap } = action
        return createOrUpdateAction(applicationId, {
          category, description, type, authenticationId, examplePayload, httpRequest, versionId, idempotencyKeyMap, paramSchema, stateKeyMap,
          isDraft: true,
          name: `Copy of "${action.name}"`
        }).then((data) => {
          setDuplicatingAction(false)
          setActiveDuplicateActionId(undefined)
          return data
        })
      })
    return action
  }

  return {
    activeDuplicateActionId,
    duplicateAction,
    duplicatingAction
  }
}