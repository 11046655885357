import { State } from 'db'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { Edge, MarkerType, Node } from 'react-flow-renderer'
import { useParams } from 'react-router-dom'
import useAScenario from 'src/hooks/useAScenario'
import dagre from 'dagre'
import useSetStateTestData from '../../edit/useSetStateTestData'

interface Params {
  setNodes: Dispatch<SetStateAction<Node<State>[]>>,
  setEdges: Dispatch<SetStateAction<Edge<State>[]>>
}

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeWidth = 172
const nodeHeight = 36

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = isHorizontal ? 'left' : 'top'
    node.sourcePosition = isHorizontal ? 'right' : 'bottom'

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }

    return node
  })

  return { nodes, edges }
}

export function useSetFlowsFromScenario(params: Params): void {
  const { setEdges, setNodes } = params
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const { scenario } = useAScenario(parseInt(scenarioId), true)

  useSetStateTestData(scenario?.states || [], 1)

  useEffect(() => {
    if (scenario?.states) {
      const nodes = scenario.states.map((state, _, states) => {
        const { order, conditionGroupOrder, parentId, stateConditionId } = state
        const parentPathId = states.find(s => s.id === stateConditionId)?.parentId
        const childPosition = conditionGroupOrder ? states.find(s => s.id === parentId)?.childrenIds?.findIndex(id => id === state.id) || 0 :
          stateConditionId ? states.find(s => s.id === parentPathId)?.childrenIds?.findIndex(id => id === stateConditionId) || 0 :
            0
        return {
          id: `${state.id}`,
          type: 'state',
          data: state,
          position: { x: childPosition * 200, y: order * 50 },
        }
      })

      const edges = scenario.states.reduce((res: Edge[], state) => {
        if (state.childrenIds?.length !== 0) {
          return [
            ...res,
            ...[...state.childrenIds || []].map((childId) => ({
              id: `${state.id}-${childId}`,
              data: state,
              type: 'state',
              markerEnd: {
                type: MarkerType.Arrow,
              },
              source: `${state.id}`,
              target: `${childId}`
            }))
          ]
        }
        return [
          ...res
        ]
      }, [])

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges)

      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [scenario])
}