import { Col, DatePicker, Row, Select, SelectProps, Switch } from 'antd'
import { LogJob } from 'db'
import moment, { Moment } from 'moment'
import qs from 'query-string'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useScenariosWithLimitedData } from 'src/hooks/useScenarios'
import { DEFAULT_DATE_RANGE } from 'src/util/Constant'
import Notification from 'src/util/Notification'
import styled from 'styled-components'
import { StyledP1Strong, StyledTag } from '../../../../../pages/components/StyledComponents'
import { TableState } from '../../../../../types'
import useValidationDateFilter from './useValidationDateFilter'
interface Props {
  tableState: TableState<LogJob>,
  setTableState: Dispatch<SetStateAction<TableState<LogJob>>>
}

const JobFilter: FC<Props> = ({ tableState, setTableState }) => {
  // const [searchValue, setSearchValue] = useState('')
  const { search } = useLocation()
  const { scenarios, fetchingScenarios } = useScenariosWithLimitedData()
  const { disabledDate, hackValue, value, onOpenChange, setValue, isValid, setDates } = useValidationDateFilter()

  const onSearch: SelectProps<any>['onChange'] = (value: string) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const onChangeStatus = (value?: string) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, status: value ? [value] : null }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const onChangeDateRange = (value?: Moment[]) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, createdAt: value ? value : null }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const onChangeArchiveSwitch = (value: boolean) => {
    setTableState(prevState => ({ ...prevState, filter: { ...prevState.filter, withDeleted: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  useEffect(() => {
    if (search) {
      const params = qs.parse(search)
      const { status, dateStart, dateEnd, search: scenario, withDeleted, page } = params
      // setSearchValue(scenario as string || '')
      const convertedParams = {
        withDeleted,
        status: status ? [status as string] : null,
        createdAt: dateStart ? [moment(params.dateStart as string), dateEnd ? moment(dateEnd as string) : moment()] : DEFAULT_DATE_RANGE,
        search: scenario
      }
      setTableState(prevState => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            current: parseInt(page?.toString() || '1')
          },
          filter: {
            ...prevState.filter,
            ...convertedParams
          }
        }
      })
    }
  }, [])

  return (
    <>
      <Row align="middle" gutter={[8, 16]} style={{ marginBottom: '12px' }}>
        <Col flex="auto">
          <StyledSelect
            filterOption={(input, option) =>
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%', maxWidth: '25vw' }}
            placeholder="All Scenario"
            onChange={onSearch}
            value={tableState?.filter?.search ? `${tableState?.filter?.search}` : undefined}
            loading={fetchingScenarios}
            allowClear
            showSearch
          >
            {
              scenarios?.map((scenario) => {
                return (
                  <Select.Option title={`${scenario.id} ${scenario.name}${scenario.deletedAt ? ' [archived]' : ''}`} key={`${scenario.id}`} value={`${scenario.id}`} >
                    <StyledTag color={scenario.deletedAt ? 'orange' : undefined} >{scenario.id}</StyledTag>
                    {scenario.name}
                  </Select.Option>
                )
              })
            }
          </StyledSelect>
          {/* <Input.Search value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search Scenario or Job" allowClear onSearch={onSearch}  /> */}
        </Col>
        <Col><StyledP1Strong>Date:</StyledP1Strong></Col>
        <Col>
          <DatePicker.RangePicker
            style={{ width: 295 }}
            // value={tableState.filter?.createdAt ? tableState.filter.createdAt as any : undefined}
            // onChange={(value) => onChangeDateRange(value as Moment[])}
            disabledDate={disabledDate}
            inputReadOnly
            onOpenChange={onOpenChange}
            onCalendarChange={(val) => {
              setDates(val)
            }}
            value={hackValue || value ? hackValue || value : tableState.filter?.createdAt ? tableState.filter.createdAt as any : undefined}
            onChange={(value) => {
              if (isValid(value)) {
                onChangeDateRange(value as Moment[])
                setValue(value)
              } else {
                Notification.error({ message: 'Maximal range is 7 days, back to default: the last 7 days' })
                setValue(DEFAULT_DATE_RANGE)
                onChangeDateRange(DEFAULT_DATE_RANGE)
              }
            }}
            allowClear={false}
            showTime={{
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]
            }} showHour showMinute showSecond={false} minuteStep={30}
          />
        </Col>
        <Col><StyledP1Strong>Status:</StyledP1Strong></Col>
        <Col>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="All Status"
            onChange={onChangeStatus}
            value={tableState.filter?.status as string || undefined}
            allowClear
            options={[{ label: 'Start', value: 'start' }, { label: 'Pending', value: 'pending' }, { label: 'Complete', value: 'complete' },{ label: 'Error', value:'error' }, { label: 'Partial Error', value:'partialError' }]}
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
          </Select>
        </Col>
        <Col><StyledP1Strong>Archived:</StyledP1Strong></Col>
        <Col>
          <Switch
            onChange={onChangeArchiveSwitch}
            checked={tableState.filter?.withDeleted as boolean}
          />
        </Col>
      </Row>
    </>
  )
}

export default JobFilter

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`