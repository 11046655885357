import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Space, Spin, Tag, Typography } from 'antd'
import { Scenario } from 'db'
import { FC, useState } from 'react'
import useUpdateAScenario from '../../../../../hooks/useUpdateAScenario'
import { TableState } from '../../../../../types'
import { blue } from '../../../../../util/colors'
import AnchorWrapper from '../../../../AnchorWrapper'

interface Props {
  tableState?: TableState<Scenario>,
  scenario: Scenario
}
const ScenarioTitle: FC<Props> = ({ tableState, scenario }) => {
  const { updateScenario, updatingScenario } = useUpdateAScenario(tableState)
  const [isEdit, setIsEdit] = useState(false)
  const onClickScenarioTitle = async () => {
    if (!scenario.deletedAt) {
      // history.push(`/app/scenario/${scenario.id}/edit`)
      const a = document.createElement('a')
      a.target = '_blank'
      a.href = `/app/scenario/${scenario.id}/edit`
      a.rel = 'noopener noreferrer'
      a.click()
    }
  }
  return (
    <>
      {
        isEdit ?
          <Typography.Text
            editable={{
              onEnd: () => setIsEdit(false),
              onCancel: () => setIsEdit(false),
              editing: true,
              icon: updatingScenario ? <LoadingOutlined spin style={{ fontSize: 18 }} /> : <EditOutlined />,
              onChange: (value) => updateScenario(scenario.id, { name: value })
            }}
          >{scenario.name || '-'}</Typography.Text> :
          <Space>
            <AnchorWrapper style={{ color: blue[4] }} href={scenario.deletedAt ? undefined : `/app/scenario/${scenario.id}/edit`} onClick={(e) => {
              e.preventDefault()
              onClickScenarioTitle()
            }}>{scenario.name}</AnchorWrapper>
            <EditOutlined style={{ color: blue[4] }} onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setIsEdit(true)
            }} />
            <Tag color="default">ID: {scenario.id}</Tag> {scenario.deletedAt ? <Tag color="orange">archived</Tag> : null}
            <Spin spinning={updatingScenario} />
          </Space>
      }

    </>
  )
}

export default ScenarioTitle