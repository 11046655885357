import { CrownFilled, EyeInvisibleFilled } from '@ant-design/icons'
import { Col, Image, Input, Row, Skeleton, Tooltip } from 'antd'
import { Application } from 'db'
import React, { FC, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import DefaultPNG from '../../../../../../assets/DefaultPNG'
import usePublicApplications from '../../../../../../hooks/application/main/usePublicApplications'
import useStatusPayment from '../../../../../../hooks/payment/useStatusPayment'
import { StyledCard, StyledText } from '../../../../../../pages/components/StyledComponents'
import { blue, orange } from '../../../../../../util/colors'
import { sortApplications } from '../../../../../../util/functions'

interface Props {
  onClickApp: (app: Application) => void,
  selectedAppIds?: Record<string, any>
}

const SearchApplication: FC<Props> = ({ onClickApp, selectedAppIds }) => {
  // const [isShowAll, setIsShowAll] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const inputRef = useRef<any>(null)
  const { publicApplications } = usePublicApplications({ expands: 'action' })
  const { plan } = useStatusPayment()

  const onAddApp = (application: Application) => {
    if (application.isPremiumApp && !plan?.isUsingPremiumApps) {
      return
    }
    onClickApp(application)
  }
  return (
    <SearchApplicationContainer>
      <Input
        ref={inputRef}
        placeholder="Search Application"
        onChange={useCallback((e) => {
          setSearchValue(e.target.value)
        }, [])}
        allowClear
      />

      <ApplicationList gutter={[6, 6]} className={'show-all'} align="top">
        {
          !publicApplications ?
            [...new Array(12)]
              .map((_, i) =>
                <Col key={i} xs={24} sm={12} md={8} lg={4} xl={3} xxl={2} >
                  <AppItem>
                    <Skeleton.Button size="large" shape="circle" active />
                    <Skeleton.Input style={{ width: '50px', marginLeft: 4 }} size="small" active />
                  </AppItem>
                </Col>
              ) :

            publicApplications
              // hide path application
              .filter(application => application.actions?.filter(action => action.type === 'path')?.length === 0)
              // filter base on search
              .filter(application => searchValue !== '' ? application.name.toLowerCase().includes(searchValue.toLowerCase()) : true)
              .sort(sortApplications)
              .map(application => {
                const { name, logoUrl } = application

                // TODO modify this for production. For purpose of the demo of many application, I deliberately use this hack to show more applications on the UI
                // const x = [...new Array(16)].map((_, i) =>
                //   <Tooltip key={`${i}-${application.id}`} title={name}>
                //     <AppItem xs={8} sm={4} md={4} lg={3} xl={2} xxl={1} className="app-item" onClick={() => onClickApp(application)}>
                //       <Image src={logoUrl || DefaultPNG} width={40} height={40} fallback={DefaultPNG} preview={false} />
                //       <StyledText ellipsis style={{ width: '100%' }} title={name}>{name}</StyledText>
                //     </AppItem>
                //   </Tooltip>
                // )
                // return x

                return (
                  <Col xs={24} sm={12} md={8} lg={4} xl={3} xxl={3} >
                    <Tooltip placement="bottom" key={application.id} title={application.isPremiumApp ? !plan?.isUsingPremiumApps ? `Upgrade to Professional plan to use ${name}` : name : name}>
                      <AppItem isActive={selectedAppIds?.[application.id]} disabled={!plan?.isUsingPremiumApps && application.isPremiumApp} className="app-item" onClick={() => onAddApp(application)}>
                        <StyledImage src={logoUrl || '/placeholder.png'} width={'32px'} height={'32px'} fallback={DefaultPNG} preview={false} />
                        <StyledText ellipsis title={name}>{name}</StyledText>
                        {/* {
                          !application.public ?
                            <StyledBadgePrivate ellipsis title={'Private'}>Private</StyledBadgePrivate> :
                            application.isPremiumApp ?
                              <StyledBadgePremium ellipsis><CrownFilled style={{ color: orange[4] }} /> Premium</StyledBadgePremium> :
                              application.isNativeApp ?
                                <StyledBadgeOtomatis>Otomatis</StyledBadgeOtomatis> :
                                <div style={{ height: 22 }} />
                        } */}
                        <ContainerBadge color={application.isPremiumApp ? orange[4] : application.isNativeApp ? blue[4] : !application.public ? '#D9D9D9' : 'transparent'}>
                          {
                            application.isPremiumApp ?
                              <CrownFilled style={{ color: '#FFF' }} /> :
                              application.isNativeApp ?
                                <Image src={'/product-logo/native-badge-icon.png' || '/placeholder.png'} width={12} height={12} fallback={DefaultPNG} preview={false} /> :
                                !application.public ?
                                  <EyeInvisibleFilled style={{ color: 'rgba(0, 0, 0, 0.65)' }} /> :
                                  null
                          }
                        </ContainerBadge>
                      </AppItem>
                    </Tooltip>
                  </Col>
                )
              })
        }
      </ApplicationList>

      {/* <Row align="middle" justify="center">
        <Col>
          <SeeMoreApplicationButton
            onClick={useCallback(() => setIsShowAll(prevState => !prevState), [])}
            type="link"
          >
            Show {isShowAll ? 'Less' : 'More'} {isShowAll ? <CaretUpFilled /> : <CaretDownFilled />}
          </SeeMoreApplicationButton>
        </Col>
      </Row> */}
    </SearchApplicationContainer>
  )
}

export default SearchApplication

const StyledImage = styled(Image)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`
const ContainerBadge = styled.div<{ color?: string }>`
  position: absolute;
  right: 0;
  top: 0;
  .anticon, .ant-image {
    margin-top: 6px;
    margin-right: 4px;
  }
  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent ${({ color }) => color || '#007bff'} transparent transparent;
  }
  
`

export const StyledBadgeOtomatis = styled(StyledText)`
  color: #3E73E3;
  background: #F0FBFF;
  /* Blue/4 */

  border: 1px solid #3E73E3;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
  vertical-align: middle !important;
  line-height: 12px;
`
export const StyledBadgePremium = styled(StyledBadgeOtomatis)`
  color: #82612C;
  background: #F9EBCB;
  /* Orange/3 */

  border: 1px solid #EEC56F;
`

export const StyledBadgePrivate = styled(StyledBadgeOtomatis)`
  color: #8C8C8C;
 /* Neutral/3 */
  background: #F5F5F5;
  /* Neutral/7 */
  border: 1px solid #8C8C8C;
`
const SearchApplicationContainer = styled(StyledCard)`
  padding: 0 !important;

  & > .ant-card-body {
    padding: 12px;
  }
`

const ApplicationList = styled(Row)`
  /* margin: 0 !important; */
  max-height: 198px;
  overflow: auto;
  transition: all .24s ease;
  margin-top: 12px;
`

// const SeeMoreApplicationButton = styled(StyledButton)`
//   bottom: -12px;
// `

const AppItem = styled(Col)<{ disabled?: boolean, isActive?: boolean }>`
  z-index: 1;
  &.app-item {
    cursor: ${props => props.disabled ? 'not-allowed !important' : 'pointer'};
  }
  img {
    filter: ${props => props.disabled ? 'opacity(0.3) grayscale(1)' : 'unset'};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
  border: ${({ isActive }) => isActive ? '1px solid #3E73E3' : '1px solid #D9D9D9'};
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px 0;
  position: relative;
  background: ${({ isActive }) => isActive ? blue[1] : '#FFF' };
  &:hover {
    background: ${blue[1]};
    border: 1px solid #3E73E3;
  }
`
