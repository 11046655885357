import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import { Error } from '../../../types'
import Notification from '../../../util/Notification'

type Result = {
  testAction: (payload?: any, bundleId?: number, stateId?: number) => Promise<any> | undefined,
  result?: any,
  testingAction: boolean,
  errorTestAction?: Error
}

export default function useActionTest(applicationId?: number | string, actionId?: number | string): Result {
  const url = actionId && applicationId ? `/application/${applicationId}/action/${actionId}/test` : null
  const [data, setData] = useState()
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState<Error>()

  const post = async (payload?: any, bundleId?: number, stateId?: number) => {
    setIsValidating(true)
    if (url) {
      const test = await Implikasi.post(bundleId ? `${url}?bundleId=${bundleId}` :
        stateId ? `${url}?stateId=${stateId}` :
          url, payload)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          Notification.error({
            message: 'Something\'s wrong',
            description: response?.data?.error
          })
          setError(response?.data?.error)
        })
      setIsValidating(false)
      setData(test)
      return test
    }
    setIsValidating(false)
  }

  return {
    result: data,
    testingAction: isValidating,
    errorTestAction: error,
    testAction: post
  }
}