import { AxiosPromise } from 'axios'
import { Folder } from 'db'
import { Error } from '../../types'
import { useApi } from '../useFetch'
import useFolders from './useFolders'

type DeleteFolder = {
  deletedFolder?: Folder['name'],
  deletingFolder: boolean,
  errorDeleteFolder?: Error,
  deleteFolder: (folderId: number) => AxiosPromise<{ folder: Folder }>
}

export default function useDeleteFolder(): DeleteFolder {
  const { data, error, isValidating, call } = useApi<{ folder: Folder }>()
  const { revalidateFolders } = useFolders()

  return {
    deletedFolder: data?.folder.name,
    deletingFolder: isValidating,
    errorDeleteFolder: error,
    deleteFolder: (folderId: number) => {
      const put = call({
        url: `/folder/${folderId}/archive`,
        method: 'delete'
      })

      put.then(() => {
        revalidateFolders()
      })

      return put
    }
  }
}