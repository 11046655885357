import { CodeOutlined, DownOutlined } from '@ant-design/icons'
import { Col, Dropdown, FormInstance, Input, Menu, Row, Tabs } from 'antd'
import { HttpRequest } from 'db'
import { cloneElement, FC, ReactElement, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import RichTextInput from '../../../../components/pages/developer/formItems/RichTextInput'
import { StyledButton, StyledCard, StyledLabel } from '../../../components/StyledComponents'
import FormItem from './FormItem'
import FormListKeyValueItem from './FormListKeyValueItem'
import MonacoEditor from './MonacoEditor'

const sampleRequestBodyFunction = `(data, module) => {
  // expands data object
  // request: the request object from Otomatis { url, method, headers }
  // authData: the value from others field in authentication
  // bundle: the response data from auth endpoints will be stored here
  // params: all of the previous fields value (
  const { request, bundle, authData, params } = data

  // eg. return object { text, username } for the request body
  return {
    text: params.text,
    username: authData.username
  }
}`

const defaultRequestCode: Partial<HttpRequest> = {
  url: '',
  method: 'get',
  headers: {},
  body: {},
  auth: {
    username: '',
    password: ''
  }
}

interface Props {
  valuesForm?: any,
  existedValue?: HttpRequest | null,
  onChange?: (id: string, values: any, valuesForm?: any, valuesCode?: any) => void,
  id: string,
  form: FormInstance<any>,
  isRequired?: boolean,
  label?: string,
  description?: ReactNode
}

const FormRequestItem: FC<Props> = ({ isRequired, label, description, form, id, onChange, existedValue, valuesForm }) => {
  const methods = ['get', 'post', 'patch', 'delete', 'put']
  const [method, setMethod] = useState<string>('get')
  const [requestBody, setRequestBody] = useState<string>()
  const [requestCode, setRequestCode] = useState<string | undefined>(JSON.stringify(defaultRequestCode, null, 2))
  const [isCodeModeRequest, setIsCodeModeRequest] = useState(false)
  const [counter, setCounter] = useState(0)
  // const [auth, setAuth] = useState<any>()
  const [requestBodyFunction, setRequestBodyFunction] = useState<string>()

  useEffect(() => {
    if (existedValue && counter <= 1) {
      setRequestCode(JSON.stringify(existedValue))
      convertJSONtoForm(existedValue)
    }
  }, [existedValue])

  const convertJSONtoForm = (valuesCode: any) => {
    try {
      setCounter(counter + 1)
      setRequestBody(valuesCode.body ? JSON.stringify(valuesCode.body, null, 2) : valuesCode.body)
      setRequestBodyFunction(valuesCode.getBodyFunc)
      if (valuesCode.method) {
        setMethod(valuesCode.method)
      }
      if (valuesCode.auth) {
        form.setFieldsValue({
          [`${id}-username`]: valuesCode.auth.username,
          [`${id}-password`]: valuesCode.auth.password,
        })
      }
      const jsonParams: string[] | undefined = valuesCode.url?.split('?')[1]?.split('&') // [tes=tes, tes=a]
      form.setFieldsValue({
        [`${id}-url`]: valuesCode.url?.replace(/\?[\s\S\w.,]+/g, ''),
        [`${id}-headers`]: typeof valuesCode.headers === 'object' ? jsonToArray(valuesCode.headers) : JSON.stringify(valuesCode.headers),
        [`${id}-params`]: jsonParams?.map(param => {
          const [key] = param.split('=')
          return { key: key, value: param.replace(`${key}=`, '') }
        }),
        [`${id}-username`]: valuesCode?.auth?.username,
        [`${id}-password`]: valuesCode?.auth?.password,
      })
    } catch (e) {
      // do nothing
    }
  }

  const toggleCodeModeRequest = () => {
    try {
      const valuesForm = {
        method: method,
        auth: {
          username: form.getFieldValue(`${id}-username`),
          password: form.getFieldValue(`${id}-password`)
        },
        getBodyFunc: requestBodyFunction === sampleRequestBodyFunction ? null : requestBodyFunction || null,
        url: getUrlWithParams(form.getFieldValue(`${id}-url`), form.getFieldValue(`${id}-params`)),
        headers: reduceArrayToJson(form.getFieldValue(`${id}-headers`)),
        body: requestBody ? JSON.parse(requestBody) : null
      }
      if (!isCodeModeRequest) {
        setRequestCode(JSON.stringify(valuesForm, null, 2))
      } else {
        const valuesCode = requestCode ? JSON.parse(requestCode) : {}
        convertJSONtoForm(valuesCode)
      }
      setIsCodeModeRequest(!isCodeModeRequest)
    } catch (e) {
      // do nothing
    }

  }

  const reduceArrayToJson = (dataArray?: string | {key?: string, value?: string}[]) => {
    if (dataArray && Array.isArray(dataArray) && dataArray.length > 0) {
      return dataArray.filter(curr => curr?.key).reduce((res: any, curr) => {
        const { key, value } = curr
        if (key && value) {
          return {
            ...res,
            [key]: value
          }
        }
        return res
      }, {})
    }
    if (dataArray && typeof dataArray === 'string') {
      // console.log(dataArray)
      return JSON.parse(dataArray as any)
    }
    return undefined
  }

  const jsonToArray = (dataJson?: {[key: string]: string}) => {
    if (dataJson) {
      return Object.keys(dataJson).map(key => ({ key: key, value: dataJson[key] }))
    }
    return undefined
  }

  const getUrlWithParams = (url?: string, params?: {key?: string, value?: string}[]) => {
    if (url !== undefined) {
      if (params && params.length > 0) {
        const joinedParams = `?${params.filter(param => param?.key).map(param => `${param?.key}=${param?.value ? param.value : ''}`).join('&')}`
        return `${url.replace(/\?[\s\S\w.,]+/g, '')}${joinedParams}`
      }
      return url
    }
    return undefined
  }

  const onChangeValues = (cancelEffect: boolean) => {

    if (cancelEffect) {
      return
    }

    try {
      const valuesForm = {
        method: method,
        auth: {
          username: form.getFieldValue(`${id}-username`),
          password: form.getFieldValue(`${id}-password`)
        },
        getBodyFunc: requestBodyFunction === sampleRequestBodyFunction ? null : requestBodyFunction || null,
        url: getUrlWithParams(form.getFieldValue(`${id}-url`), form.getFieldValue(`${id}-params`)),
        headers: reduceArrayToJson(form.getFieldValue(`${id}-headers`)) || {},
        body: requestBody ? JSON.parse(requestBody) : null
      }
      if (onChange) {
        if (requestCode && isCodeModeRequest) {
          const valuesCode = JSON.parse(requestCode)
          onChange(id, valuesCode, valuesForm, valuesCode)
        } else if (!isCodeModeRequest) {
          onChange(id, valuesForm, valuesForm)
        }
      }
    } catch (e) {
      // do nothing
    }
  }

  useEffect(() => {
    let cancelEffect = false
    onChangeValues(cancelEffect)
    return () => {
      cancelEffect = true
    }
  }, [isCodeModeRequest, method, requestBody, requestCode, valuesForm, requestBodyFunction])

  const onMenuMethodClick = (e: any) => {
    setMethod(e.key)
  }

  return (
    <StyledFormRequest>
      <StyledLabel className={isRequired ? 'required' : undefined}>{label}</StyledLabel>
      <br />
      {description ? <p>{description}</p> : null}
      <br />
      <StyledCard className={`card-request ${isCodeModeRequest ? 'code-mode' : ''}`} style={{ padding: 0 }}>
        <div className="with-padding url-detail">
          <Row gutter={[16, 16]}>
            <Col lg={3} md={6} sm={24}>
              <Dropdown.Button
                style={{ width: '100%' }}
                buttonsRender={([leftButton, rightButton]) => [
                  cloneElement(leftButton as ReactElement, { children: method, style: { width: '100%' } }),
                  rightButton
                ]}
                icon={<DownOutlined />}
                trigger={['click', 'hover']}
                overlay={<Menu onClick={onMenuMethodClick}>
                  {methods.map(method => <Menu.Item style={{ textTransform: 'uppercase' }} key={method}>{method}</Menu.Item>)}
                </Menu>}>
              </Dropdown.Button>
            </Col>
            <Col lg={21} md={18} sm={24}>
              <StyledFormItem name={`${id}-url`}>
                {
                  // eslint-disable-next-line
                  // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                  <RichTextInput
                    placeholder="Ex: https://www.example.com"
                  />
                }
              </StyledFormItem>
            </Col>
          </Row>
        </div>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="URL Params" key="1">
            <FormListKeyValueItem name={`${id}-params`} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="HTTP Headers" key="2">
            <FormItem shouldUpdate>
              {
                ({ getFieldValue }) => {
                  if (typeof getFieldValue(`${id}-headers`) === 'string') {
                    return <FormItem name={`${id}-headers`}>
                      <MonacoEditor defaultLanguage="json" />
                    </FormItem>
                  }
                  return <FormListKeyValueItem name={`${id}-headers`} />
                }
              }
            </FormItem>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Basic Auth" key="3">
            <Row justify="center" align="middle" >
              <Col span={20}>
                <StyledFormItem name={`${id}-username`} label="Username">
                  {
                    // eslint-disable-next-line
                        // @ts-ignore missing value and onChange props but we don't need to fill it manually, Form.Item will do
                    <RichTextInput />
                  }
                </StyledFormItem>
                <FormItem name={`${id}-password`} label="Password">
                  <Input.Password />
                </FormItem>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Request Body" key="4">
            <MonacoEditor value={requestBody} setValue={setRequestBody} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Request Body Function" key="5">
            <MonacoEditor defaultValue={sampleRequestBodyFunction}
              value={requestBodyFunction} setValue={setRequestBodyFunction} defaultLanguage="javascript" />
          </Tabs.TabPane>
        </Tabs>
        <div className="code-request">
          <MonacoEditor value={requestCode} setValue={setRequestCode}  />
        </div>
        <StyledButton onClick={toggleCodeModeRequest} className="with-padding" icon={isCodeModeRequest ? null : <CodeOutlined />}>
          {isCodeModeRequest ? 'Switch To Form' : 'Switch to Code Mode'}
        </StyledButton>
        <br /><br />
      </StyledCard>
    </StyledFormRequest>
  )
}

export default FormRequestItem

const StyledFormRequest = styled.div`
  margin-bottom: 24px;
  .connection-label {
    display: none;
    &.code-mode {
      display: block;
    }
  }
  .ant-card.card-request {
    background: #FFFFFF;
    /* Neutral/5 */
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
    &.code-mode {
      .url-detail, .ant-tabs {
        display: none;
      }
      .code-request {
        display: block;
        padding: 16px;
      }
    }
    .code-request {
      display: none;
    }
    .ant-dropdown-button .ant-btn {
      text-transform: uppercase;
    }
    .ant-card-body {
      padding: 0;
      .with-padding {
        margin: 16px 16px 0;
      }
      .ant-tabs {
        padding: 0 16px;
      }
      .ant-collapse {
        background: #FFF;
        .ant-collapse-item {
          border: none;
        }
        .ant-collapse-header {
          /* P1 - 14 */
          font-family: 'DM Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
`

// eslint-disable-next-line
const StyledFormItem = styled(({ description, children, ...nativeProps }) => <FormItem description={description} {...nativeProps}>{children}</FormItem>)<{ description: string }>`
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 16px;

  .ant-checkbox-wrapper, .ant-radio-wrapper, .ant-form-item-extra {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
  &.with-extra {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: ${props => props.description ? 0 : '5px'};
  }
  .ant-form-item-label > label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-form-item-optional, label.ant-form-item-required::before {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    text-transform: capitalize;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: '(Required)';
    color: ${'#9C3930'};
    margin-left: 4px;
  }

  &.no-info .ant-form-item-label > label.ant-form-item-required::before {
    content: '';
  }

  .ant-form-item-label > label.ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .ant-form-item-extra {
    padding-bottom: 5px;
  }

  .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-success {
    position: absolute;
    bottom: -8px;
  }

  label {
    padding-bottom: 0
  }

  .CodeMirror {
    height: 32px;
    min-height: 31px;
    padding: 0 11px;
  }
`