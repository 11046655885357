import { AxiosPromise } from 'axios'
import { Invitation, Member } from 'db'
import { useEffect, useState } from 'react'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'
import { MemberAndInvitation } from '../member/useAllMemberAndInvitation'

type Result = {
  createdInvitation?: MemberAndInvitation,
  creatingInvitation: boolean,
  errorCreateInvitation?: Error,
  createInvitation: (applicationId: number, payload: Partial<Invitation>) => AxiosPromise<{ invitation?: Invitation, member: Member }>
}

export default function useCreateInvitation(): Result {
  const [created, setCreated] = useState<MemberAndInvitation>()
  const { data, error, isValidating, call } = useApi<{ invitation?: Invitation, member: Member }>()

  useEffect(() => {
    if (data?.member) {
      setCreated({ ...data?.member, email: data?.member.user?.email })
    }
    if (data?.invitation) {
      setCreated({ ...data.invitation, userId: 0 })
    }
    if (!data) {
      setCreated(undefined)
    }
  }, [data])

  return {
    createdInvitation: created,
    creatingInvitation: isValidating,
    errorCreateInvitation: error,
    createInvitation: (applicationId, payload) => {
      return call({
        url: `/application/${applicationId}/invitation`,
        method: 'post',
        data: {
          invitation: payload
        }
      })
    }
  }
}