import { Scenario, State } from 'db'
import { useState } from 'react'
import { TableState } from '../../types'
import { useRevalidateFetch } from '../useFetch'
import useCreateScenario from './useCreateScenario'

type ImportScenario = {
  importScenario: (file: File, afterSuccess?: () => void) => void,
  importing: boolean
}
export default function useImportScenario(tableState?: TableState<Scenario>): ImportScenario {
  const [importing, setImporting] = useState(false)
  const { createScenario } = useCreateScenario()
  const { revalidateScenarios } = useRevalidateFetch()

  const importScenario = (file: File, afterSuccess?: () => void) => {
    setImporting(true)
    const reader = new FileReader()

    reader.onload = function (event) {
      const result = event.target?.result
      if (result) {
        const scenario = JSON.parse(result.toString()) as Scenario
        createScenario({ name: `Imported ${scenario.name}`, states: scenario.states?.map(state => {
          const { triggerId, actionId, order, name, params, testData, realTestData, conditionGroupOrder, conditions, stateConditionId, id, parentId, childrenIds } = state
          return {
            name: name || state.trigger?.name || state.action?.name, testData, realTestData,
            triggerId, actionId, order, params, conditionGroupOrder, conditions, stateConditionId, id, childrenIds, parentId
          }
        }) as State[] })
          .then(() => {
            setImporting(false)
            revalidateScenarios(tableState)
            afterSuccess?.()
          })
      }
    }

    reader.readAsText(file)
  }

  return {
    importScenario,
    importing
  }
}