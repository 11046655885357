

import { ThunderboltFilled } from '@ant-design/icons'
import { Row } from 'antd'
import { Scenario } from 'db'
import { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useEnableAScenario from '../../../../../hooks/useEnableAScenario'
import { StyledButton } from '../../../../../pages/components/StyledComponents'
import Notification from '../../../../../util/Notification'
import { useStateTestData } from './StateTestDataContext'

interface Props {
  scenario?: Scenario
}
const ButtonLiveFooter: FC<Props> = ({ scenario }) => {
  const [stateTestData] = useStateTestData()
  const disabled = scenario?.states?.reduce((res, state) => {
    if (stateTestData) {
      return res || !stateTestData[state.id]?.testData || stateTestData[state.id]?.testData?.error
    }
    return res || !state.testData || state.testData?.error
  }, false)
  const history = useHistory()
  const { enableScenario, enablingScenario, responseEnablingScenario } = useEnableAScenario()

  const onGoToMyScenario = () => history.push('/app/scenario')

  useEffect(() => {
    if (responseEnablingScenario) {
      Notification.success({
        message: 'Your Scenario is already Live',
        description: 'You can turn off your Scenario at anytime by toggling the switch',
        btn: <StyledButton onClick={onGoToMyScenario} type="primary" >Go to My Scenario</StyledButton>
      })
    }
  }, [responseEnablingScenario])

  const onClickLive = () => {
    if (scenario && !scenario?.active) {
      enableScenario(scenario.id)
    } else {
      Notification.success({
        message: 'Your Scenario is already Live',
        description: 'You can turn off your Scenario at anytime by toggling the switch',
        btn: <StyledButton onClick={onGoToMyScenario} type="primary" >Go to My Scenario</StyledButton>
      })
    }
  }

  return (
    <StyledLayoutFooter justify="center">
      <StyledButton onClick={onClickLive} loading={enablingScenario} type="primary" icon={<ThunderboltFilled />} disabled={disabled}>Save and Go Live</StyledButton>
    </StyledLayoutFooter>
  )
}

export default ButtonLiveFooter

const StyledLayoutFooter = styled(Row)`
  width: 100%;
  z-index: 2;
  background: #FFF;
  padding: 16px;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);
  ${StyledButton} {
    margin: auto;
  }
`