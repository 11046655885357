import { Error } from '../../../types'
import useFetch from '../../useFetch'

type Result = {
  result?: any,
  fetchingAuthenticationCallback: boolean,
  errorFetchAuthenticationCallback?: Error
}

export default function useAuthenticationCallback(applicationId?: number | string, authenticationUuid?: number | string, params?: { [key: string]: string | string[] | null }): Result {
  const url = authenticationUuid && applicationId && params ?
    `/application/${applicationId}/authentication/${authenticationUuid}/callback?${Object.keys(params).filter(param => params[param]).map(param => `${param}=${params[param]}`).join('&')}` : null
  const { data, error, isValidating } = useFetch<any>(url, {}, { revalidateOnFocus: false })

  return {
    result: data,
    fetchingAuthenticationCallback: isValidating,
    errorFetchAuthenticationCallback: error
  }
}