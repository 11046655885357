import { RedoOutlined } from '@ant-design/icons'
import { Collapse, Select, Space, Typography } from 'antd'
import { LogCustomCode } from 'db'
import { DateTime } from 'luxon'
import qs from 'query-string'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { StyledButton } from '../../../../pages/components/StyledComponents'
import DetailLogs from './DetailLogs'


interface Props {
  getLatestLog: () => void,
  title: ReactNode,
  fetchingUniqueLogs?: boolean,
  uniqueLogs?: Record<string, LogCustomCode>,
  loadMore?: () => void
}

const CustomCodeLogs: FC<Props> = ({ getLatestLog, title, uniqueLogs, fetchingUniqueLogs, loadMore }) => {
  const [selectedUuid, setSelectedUuid] = useState<string>()
  const [keysOpened, setKeysOpened] = useState<string[]>([])
  const selectedLogs = uniqueLogs?.[selectedUuid || '']?.customCodes
  const { push } = useHistory()
  const { url } = useRouteMatch()

  const sortUniqueLogs = (uuid1: string, uuid2: string) => {
    if (!uniqueLogs) return 0
    return new Date(uniqueLogs[uuid2].executionDate).getTime() - new Date(uniqueLogs[uuid1].executionDate).getTime()
  }

  useEffect(() => {
    if (uniqueLogs) {
      const params = qs.parse(location.search)
      const uuid = params.invocation as string
      if (!location.search) {
        const newest = Object.keys(uniqueLogs || {}).sort(sortUniqueLogs)[0]
        if (newest) {
          onChangeExecution(newest)
        }
      } else if (uuid && selectedUuid !== uuid) {
        if (uniqueLogs[uuid]) {
          setSelectedUuid(uuid)
        }
      }
    }
  }, [selectedUuid, uniqueLogs, location.search])

  const onChangeExecution = (value: string) => {
    setSelectedUuid(value)
    const params = {
      invocation: `${value}`
    }
    push({
      pathname: url,
      search: qs.stringify(params)
    })
  }
  return (
    <ContainerLog>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Space>
        <p>Execution Time</p>
        <Select
          dropdownRender={menu =>
            <div>
              {menu}
              {loadMore ? <StyledButton onClick={loadMore} type="link">Load More</StyledButton> : null}
            </div>
          }
          style={{ width: 500 }}
          loading={fetchingUniqueLogs}
          disabled={fetchingUniqueLogs}
          placeholder="Execution"
          onChange={onChangeExecution}
          value={selectedUuid}
        >
          {
            uniqueLogs ?
              Object.keys(uniqueLogs || {})
                .sort(sortUniqueLogs)
                .map(key => {
                  const uuid = `${uniqueLogs[key].uuid}`
                  const executionDate = DateTime.fromJSDate(new Date(uniqueLogs[key].executionDate))
                  return (
                    <Select.Option key={key} value={uuid}>
                      <span>{executionDate.toFormat('DD')}, {executionDate.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })} ({uuid})</span>
                    </Select.Option>
                  )
                })
              :
              null
          }
        </Select>
        <StyledButton disabled={fetchingUniqueLogs} icon={<RedoOutlined />} onClick={getLatestLog}>Get Latest Execution</StyledButton>
      </Space>
      <br /><br />
      {
        selectedLogs ?
        // collapse
          <Collapse onChange={(key) => setKeysOpened(key as string[])}>
            {
              selectedLogs.map((log) => {
                const { logLambdaInvocationId, customCodeType } = log
                return (
                  <CustomPanel className="capitalize" key={logLambdaInvocationId} header={customCodeType}>
                    <DetailLogs logId={logLambdaInvocationId} isOpen={keysOpened.includes(`${logLambdaInvocationId}`)} />
                  </CustomPanel>
                )
              })
            }
          </Collapse>
          :
          null
      }
    </ContainerLog>
  )
}

export default CustomCodeLogs

const ContainerLog = styled.div`
  padding: 16px;
  overflow-y: auto;
`
const CustomPanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    background: black;
    color: white;
    * {
      font-family: monospace;
    }
  }
`