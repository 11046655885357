import { Col, Row, Skeleton, Space } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

const TemplateScenarioItemSkeleton: FC = () => {
  return (
    <StyledCardSkeleton>
      <Row style={{ padding: '16px 24px' }} gutter={[16, 16]} justify="space-between" align="middle">
        <Col>
          <Space>
            <Skeleton.Avatar active size="large" style={{ height: 54, width: 54 }} shape="circle" />
            <Space direction="vertical">
              <CustomSkeletonTitle title={{ width: 54 }} paragraph={false} active />
            </Space>
          </Space>
        </Col>
        <Col>
          <Skeleton.Input style={{ width: 124, height: 30 }} active />
        </Col>
      </Row>
    </StyledCardSkeleton>

  )
}

const StyledCardSkeleton = styled.div`
  background: #FFF;
  width: 100%;
  margin: 12px 0;
`
const CustomSkeletonTitle = styled(Skeleton)`
& .ant-skeleton-title {
  margin-top: 0;
  margin-bottom: 0;
}
`

export default TemplateScenarioItemSkeleton