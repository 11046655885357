import { CrownFilled } from '@ant-design/icons'
import { Button, FormInstance, Select, Skeleton, Space, Tooltip } from 'antd'
import { Action, Trigger } from 'db'
import { FC, useEffect, useState } from 'react'
import { StyledBadgeOtomatis, StyledBadgePremium, StyledBadgePrivate } from 'src/components/pages/app/ApplicationsSelectField'
import useAllAction from 'src/hooks/application/action/useAllAction'
import usePublicApplications from 'src/hooks/application/main/usePublicApplications'
import useAllTrigger from 'src/hooks/application/trigger/useAllTrigger'
import useStatusPayment from 'src/hooks/payment/useStatusPayment'
import { StyledBoxIcon } from 'src/pages/components/StyledComponents'
import FormItem from 'src/pages/developer/components/form/FormItem'
import { orange } from 'src/util/colors'
import { sortApplications, sortTriggerActions } from 'src/util/functions'

interface Props {
  form: FormInstance,
  selectedApplicationId: number | undefined,
  type?: 'trigger' | 'action'
}

const EventField: FC<Props> = ({ type, selectedApplicationId }) => {
  const { publicApplications, fetchingPublicApplications } = usePublicApplications({ expands: type || 'action' })
  const { triggers, fetchingAllTriggers } = useAllTrigger(type === 'trigger' ? selectedApplicationId : undefined)
  const { actions, fetchingAllActions } = useAllAction(type === 'action' ? selectedApplicationId : undefined)
  const [options, setOptions] = useState<(Trigger | Action)[]>()
  const { plan } = useStatusPayment()


  useEffect(() => {
    if (triggers) {
      setOptions(triggers.filter(trigger => trigger.visibility === 'visible'))
    }
    if (actions) {
      setOptions(actions)
    }
  }, [actions, triggers])


  return (
    <>
      <FormItem small notUseInfoRequired label="App" name="applicationId" rules={[{ required: true, message: 'App is required' }]}>
        {
          publicApplications ?
            <Select
              size="small"
              filterOption={(input, option) =>
                option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              placeholder="Select App" loading={fetchingPublicApplications}>
              {
                publicApplications?.filter(app =>
                  type === 'trigger' ?
                    app.triggers && app.triggers.length > 0
                    :
                    app.actions && app.actions.length > 0
                    // && app.actions.filter(action => action.type === 'path').length === 0
                ).sort(sortApplications).map(application =>{
                  const { id, logoUrl, name, isPremiumApp } = application
                  return (
                    <Select.Option key={id} value={id} title={name}  disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                      <Tooltip placement="topLeft" title={!plan?.isUsingPremiumApps && isPremiumApp ? `Upgrade to Professional plan to use ${name}` : ''}>
                        <Space style={{ width: '100%' }}>
                          <StyledBoxIcon size="small" disabled={!plan?.isUsingPremiumApps && isPremiumApp}>
                            <img src={logoUrl || '/placeholder.png'} />
                          </StyledBoxIcon>
                          {name}
                          {
                            !application.public ?
                              <StyledBadgePrivate ellipsis title={'Private'}>Private</StyledBadgePrivate> :
                              application.isPremiumApp ?
                                <StyledBadgePremium ellipsis><CrownFilled style={{ color: orange[4] }} /> Premium</StyledBadgePremium> :
                                application.isNativeApp ?
                                  <StyledBadgeOtomatis>Otomatis</StyledBadgeOtomatis> :
                                  null
                          }
                        </Space>
                      </Tooltip>
                    </Select.Option>
                  )
                }
                )
              }
            </Select>
            :
            <Skeleton.Input size="small" active />
        }
      </FormItem>
      <FormItem small notUseInfoRequired label="Event" name={`${type || 'action'}Id`} rules={[{ required: true, message: 'Event is required' }]}>
        {
          options ?
            <Select
              size="small"
              disabled={!selectedApplicationId}
              filterOption={(input, option) =>
                option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              placeholder="Select Event"
              loading={fetchingAllActions || fetchingAllTriggers}
            >
              {
                options?.sort(sortTriggerActions).filter((option: Trigger | Action) => option.id).map((option: Trigger | Action) => {
                  const { id, name } = option
                  return (
                    <Select.Option key={id} value={id} title={name}>
                      {name}
                    </Select.Option>
                  )
                })
              }
            </Select>
            :
            <Skeleton.Input active />
        }
      </FormItem>
      <FormItem shouldUpdate={(prev, next) => prev[`${type}Id`] !== next[`${type}Id`]} >
        {({ getFieldValue, setFieldsValue }) => {
          return <Button onClick={() => {
            const name = options?.find(opt => `${opt.id}` === `${getFieldValue(`${type}Id`)}`)?.name
            setFieldsValue({ name })
          }} type="link">Set the event name as name</Button>
        }}
      </FormItem>
    </>
  )
}

export default EventField