import { Action, HttpRequest } from 'db'
import { useState } from 'react'
import { Implikasi } from '../../../service/Implikasi'
import Notification from '../../../util/Notification'

type Result = {
  creatingAction?: boolean,
  createOrUpdateAction: (applicationId: number | string, payload: Partial<Action>) => Promise<Partial<Action> | undefined>
}

export default function useCreateOrUpdateAction(): Result {
  const [isValidating, setIsValidating] = useState(false)

  const createOrUpdateHttpRequest = async (payload: HttpRequest, id?: number | null) => {
    const urlHttpRequest = '/httpRequest'
    let result: HttpRequest | undefined

    if (!payload.url) {
      return {} as HttpRequest
    }

    if (id) {
      result = await Implikasi.patch(`${urlHttpRequest}/${id}`, {
        httpRequest: payload
      }).then(({ data }) => {
        return data.httpRequest
      }).catch((e) => {
        const error = JSON.stringify(e?.response?.data?.error)
        Notification.error({
          message: 'Something\'s Wrong',
          description: error
        })

      })
      return result
    }
    result = await Implikasi.post(urlHttpRequest, {
      httpRequest: payload
    }).then(({ data }) => {
      return data.httpRequest
    }).catch((e) => {
      const error = JSON.stringify(e?.response?.data?.error)
      Notification.error({
        message: 'Something\'s Wrong',
        description: error
      })

    })
    return result
  }



  const createOrUpdate = async (applicationId: number | string, payload: Partial<Action>) => {
    setIsValidating(true)
    let newAction: Partial<Action> = payload
    let error: string | undefined

    const createAllHttpRequest = async () => {
      const { httpRequest, httpRequestId } = payload
      if (httpRequest) {
        const created = await createOrUpdateHttpRequest(httpRequest, httpRequestId)
        if (created) {
          newAction = { ...newAction, httpRequestId: created?.id || null }
        } else {
          error = 'error on create or update http request'
        }
      }
    }

    if (applicationId) {
      if (payload.id) {
        // update
        // update | create smua httpRequest
        if (payload.type === 'http') {
          await createAllHttpRequest()
        }
        // update action
        if (!error) {
          const { category, idempotencyKeyMap, isDraft, stateKeyMap, versionId, examplePayload, name, paramSchema, type, authenticationId, httpRequestId, description } = newAction
          const action = await Implikasi.patch(`/application/${applicationId}/action/${newAction.id}?versionId=${versionId}`,
            {
              action: {
                category, idempotencyKeyMap, isDraft, stateKeyMap, versionId, examplePayload, name, paramSchema, type, authenticationId, httpRequestId, description
              }
            })
            .then(({ data }) => {
              return data.action
            })
            .catch((e) => {
              const error = JSON.stringify(e?.response?.data?.error)
              Notification.error({
                message: 'Something\'s wrong',
                description: error
              })
            })
          if (action) {
            newAction = { ...newAction, id: action.id }
          }
        }

      } else {
        // create
        // create | update httpRequest
        if (payload.type === 'http') {
          await createAllHttpRequest()
        }
        // create action
        if (!error) {
          const { category, idempotencyKeyMap, isDraft, stateKeyMap, versionId, examplePayload, name, paramSchema, type, authenticationId, httpRequestId, description } = newAction
          const action: Action = await Implikasi.post(`/application/${applicationId}/action?versionId=${versionId}`,
            {
              action: {
                category, idempotencyKeyMap, isDraft, stateKeyMap, versionId, examplePayload, name, paramSchema, type, authenticationId, httpRequestId, description
              }
            })
            .then(({ data }) => {
              return data.action
            })
            .catch((e) => {
              const error = JSON.stringify(e?.response?.data?.error)
              Notification.error({
                message: 'Something\'s Wrong',
                description: error
              })

            })
          if (action) {
            newAction = { ...newAction, id: action.id }
          }
        }
      }
    } else {
      Notification.error({
        message: 'Something\'s wrong',
        description: 'Mo application spesified'
      })
    }
    setIsValidating(false)
    return newAction
  }

  return {
    creatingAction: isValidating,
    createOrUpdateAction: async (applicationId: number | string, payload: Partial<Action>) => {
      return createOrUpdate(applicationId ,payload)
    }
  }
}