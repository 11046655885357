import { AxiosPromise } from 'axios'
import { LogJob } from 'db'
import { useState } from 'react'
import { useApi } from '../useFetch'

type DeleteAJob = {
  activeDeleteId?: number,
  deleteAJob: (jobId: number) => AxiosPromise<{ logJob: LogJob }>,
  deletingAJob: boolean
}

export default function useDeleteAJob(): DeleteAJob {
  const { call, isValidating: deletingAJob } = useApi<{ logJob: LogJob }>()
  const [activeDeleteId, setActiveDelete] = useState<number>()

  const deleteAJob = (jobId: number) => {
    setActiveDelete(jobId)
    const del = call({
      url: `/logJob/${jobId}/archive`,
      method: 'delete'
    })

    del.finally(() => setActiveDelete(undefined))

    return del
  }

  return {
    activeDeleteId,
    deleteAJob,
    deletingAJob
  }
}