import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Alert, Col, ConfigProvider, Empty, Row, Table, Typography } from 'antd'
import Search from 'antd/lib/input/Search'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { Bundle as Account, Bundle } from 'db'
import { DateTime } from 'luxon'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import AvatarApplicationGroups from '../../../../components/pages/app/AvatarApplicationGroups'
import AccountTableActions from '../../../../components/pages/app/scenario/all/account/AccountTableActions'
import ModalAddAccount from '../../../../components/pages/app/scenario/all/account/ModalAddAccount'
import LayoutMyScenarioPage from '../../../../components/pages/app/scenario/all/LayoutMyScenarioPage'
import useConnectedAccounts from '../../../../hooks/bundle/useConnectedAccounts'
import useUpdateABundle from '../../../../hooks/bundle/useUpdateABundle'
import { TableState } from '../../../../types'
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../../util/Constant'
import useOpenCloseModal from '../../../../util/useOpenCloseModal'
import { StyledButton, StyledDescription } from '../../../components/StyledComponents'
const ScenarioAllAccountsPage: FC = () => {
  const { visible, closeModal, openModal } = useOpenCloseModal()
  const [tableState, setTableState] = useState<TableState<Bundle>>({
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE
    },
    sorter: {
      field: 'createdAt',
      order: 'descend'
    }
  })
  const { bundles, fetchingBundles, errorFetchBundles, bundlesLength, revalidateBundles } = useConnectedAccounts(tableState)
  const [value, setValue] = useState<string>()
  const { updateBundle, updatingBundle, activeUpdateBundleId } = useUpdateABundle(tableState)

  const handleChange = (pagination: TablePaginationConfig, filter: Record<string, FilterValue | null>, sorter: SorterResult<Bundle> | SorterResult<Bundle>[], extra: TableCurrentDataSource<Bundle>) => {
    setTableState(prevState => ({ ...prevState, pagination, filter: { ...prevState.filter, ...filter }, sorter, extra }))
  }

  useEffect(() => {
    if (bundles?.length === 0 && (tableState.pagination?.current || 0) > 1) {
      setTableState((prevState) => ({ ...prevState, pagination: {
        ...prevState.pagination,
        current: (prevState.pagination?.current || 2) - 1
      } }))
    }
  }, [tableState, bundles])


  const onSearch = (value: string) => {
    setValue(value)
    setTableState(prevState => ({ ...prevState, filter: { search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const cols: ColumnsType<Account> = [
    {
      title: 'Apps',
      key: 'apps',
      dataIndex: 'apps',
      render: (_, record) => <AvatarApplicationGroups applications={[record.application]} />
    },
    {
      title: 'Account',
      key: 'title',
      dataIndex: 'title',
      render: (_, record) => <Typography.Text
        editable={{
          icon: updatingBundle && activeUpdateBundleId === record.id ? <LoadingOutlined spin style={{ fontSize: 18 }} /> : <EditOutlined />,
          onChange: (value) => updateBundle(record.id, { editableName: value })?.then(() => revalidateBundles())
        }}
      >{record.editableName || record.name || '-'}</Typography.Text>
    },
    {
      title: 'Added',
      key: 'added',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a1, a2) => new Date(a1.createdAt).getTime() - new Date(a2.createdAt).getTime(),
      render: (_, record) => DateTime.fromJSDate(new Date(record.createdAt)).toFormat('FF')
    },
    {
      title: 'Used by',
      key: 'usedby',
      dataIndex: 'scenario',
      render: (_, record) => {
        const distinctScenarioIds: any = record.states?.reduce((res, state) => {
          return {
            ...res,
            [state.scenarioId]: 1
          }
        }, {}) || {}
        return <Typography.Text>{Object.keys(distinctScenarioIds)?.length || 0} scenario(s)</Typography.Text>
      }
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => <AccountTableActions tableState={tableState} account={record} />
    }
  ]

  const onCloseModal = () => {
    revalidateBundles()
    closeModal()
  }

  return (
    <LayoutMyScenarioPage propsPageHeader={{ title: 'Connected Accounts' }}>
      <ScenarioAllAccountContainer>
        <Row gutter={[16, 16]} justify="end" style={{ marginBottom: '12px' }}>
          <Col>
            <StyledButton type="primary" ghost onClick={openModal}>Add New Account</StyledButton>
          </Col>
          <Col span={6}>
            <Search placeholder="search accounts..." allowClear onSearch={onSearch}  />
          </Col>
        </Row>
        {
          errorFetchBundles ?
            <Alert message="Error fetching scenarios. Please refresh this page." type="error" /> :
            <ConfigProvider
              renderEmpty={() => <Empty
                imageStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 200
                }}
                image={fetchingBundles ? null : value ? '/illustrations/notfound.svg' : '/illustrations/start-account.svg'}
                description={fetchingBundles ? null : value ?
                  <>
                    <h5>Sorry, no result found</h5>
                    <StyledDescription>What you searched was unfortunately not found or doesn't exist</StyledDescription>
                  </> :
                  <>
                    <h5>There isn't any connected accounts here yet</h5>
                    <StyledDescription>Start creating your own scenario with easy steps in just a few minutes</StyledDescription>
                  </>}
              />}
            >
              <Table
                onChange={handleChange}
                pagination={{
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: bundlesLength,
                  current: tableState.pagination?.current,
                  pageSize: tableState.pagination?.pageSize
                }}
                columns={cols}
                dataSource={bundles?.filter(bundle => value ? bundle.name?.toLowerCase().includes(value.toLowerCase()) : true)}
                rowKey={record => record.id}
                loading={fetchingBundles}
                scroll={{ x: true }}
              />
            </ConfigProvider>
        }
      </ScenarioAllAccountContainer>
      <ModalAddAccount visible={visible} onCloseModal={onCloseModal} />
    </LayoutMyScenarioPage>
  )
}

export default ScenarioAllAccountsPage

const ScenarioAllAccountContainer = styled.div`
  padding: 16px;
`