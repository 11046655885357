import { MoreOutlined, LoadingOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { State } from 'db'
import { FC } from 'react'
import { StyledButton } from '../../../../../../../pages/components/StyledComponents'
import useArchiveActionState from './useArchiveActionState'
import useArchivePathState from './useArchivePathState'
import useArchiveTriggerState from './useArchiveTriggerState'

interface Props {
  id?: number,
  type: 'trigger' | 'action' | 'path',
  pathStates?: State[],
  onClickEdit: (isAddTrigger?: boolean) => void
}

const StateContextMenu: FC<Props> = ({ type, pathStates, onClickEdit, id }) => {
  const { archiveActionState, archivingActionState } = useArchiveActionState()
  const { archivePathState, archivingPathState } = useArchivePathState({ pathStates, id })
  const { archiveTriggerState } = useArchiveTriggerState()

  const onMouseEnter = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onEdit = (menuInfo: any) => {
    onMouseEnter(menuInfo.domEvent)
    onClickEdit()
  }

  const onDelete = (menuInfo: any) => {
    onMouseEnter(menuInfo.domEvent)
    if (type === 'path') {
      archivePathState()
    } else if (type === 'action') {
      archiveActionState()
    } else {
      // console.log('archive trigger')
      archiveTriggerState(() => onClickEdit(true))
    }
  }

  return (
    <Dropdown
      disabled={false}
      overlay={
        <Menu>
          {type !== 'path' ? <Menu.Item key="edit" onClick={onEdit} disabled={!!pathStates}>Edit...</Menu.Item> : null}
          <Menu.Item key="delete" onClick={onDelete} >Delete...</Menu.Item>
        </Menu>
      }
      trigger={['click']}
      placement='bottomRight'
    >
      <StyledButton loading={archivingActionState || archivingPathState} onClick={onMouseEnter} type='text' icon={
        archivingActionState || archivingPathState ?
          <LoadingOutlined /> :
          <MoreOutlined style={{ color: 'rgba(0, 0, 0, .45)', verticalAlign: 'middle', textAlign: 'center' }} />
      } />
    </Dropdown>
  )
}

export default StateContextMenu