import { createGlobalStyle } from 'styled-components'
import { danger, red } from './colors'

const ModalGlobalStyle = createGlobalStyle`
  .ant-modal.base-custom {
    .ant-modal-confirm-body-wrapper {
      padding: 16px 0;
    }
    .ant-modal-title {
      font-family: 'IBM Plex Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;/* identical to box height, or 140% */
      color: #000000;
    }
    .ant-modal-body {
      padding: 8px 24px;
    }
    .ant-modal-header, .ant-modal-footer {
      border: 0;
    }
    .ant-btn {
      &.ant-btn-primary {
        background: #3E73E3;
        border-color: #3E73E3;
        &[disabled] {
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
        &:hover, &:focus {
          background: #749BEB;
          color: #FFF;
          border-color: #749BEB;
        }
        &.ant-btn-background-ghost {
          color: #3E73E3;
          background: #FFF;
          &:hover, &:focus {
            background: #3E73E3 !important;
            color: #FFF;
            border-color: #3E73E3;
          }
          &[disabled] {
            background: #f5f5f5;
            border-color: #d9d9d9;
            color: #d9d9d9;
            &:hover, &:focus {
              background: #f5f5f5 !important;
              border-color: #d9d9d9;
              color: #d9d9d9;
            }
          }
        }
        &.ant-btn-dangerous {
          background: ${danger};
          border-color: ${danger};
          &:hover, &:focus {
            background: ${red[3]};
            color: #FFF;
            border-color: ${red[3]};
          }
        }
      }
    }
  }
`
export default ModalGlobalStyle