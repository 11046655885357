import { Layout, Row, Col } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as OtomatisIllustration } from '../assets/otomatis-illustration.svg'
import { ReactComponent as OtomatisLogo } from '../assets/otomatis_logo.svg'
import { blue } from '../util/colors'


const SignLayout: FC = (props) => {
  return (
    <Container>
      <StyledRow align="middle" justify="center">
        <StyledCol1 lg={{ span: 6 }} xs={{ span: 20 }} xl={{ span: 5 }}>
          <IllustrationWrapper>
            <Row gutter={16} justify="center" align="middle">
              <Col>
                <Illustration />
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <OtomatisLogo width="148px" height="32px" />
                <StyledDivider />
                <StyledTextCenter style={{ maxWidth: 200 }}>Menghubungkan Aplikasi yang kamu gunakan sehari hari</StyledTextCenter>
              </Col>
            </Row>
          </IllustrationWrapper>
        </StyledCol1>
        <StyledCol2 lg={{ span: 12 }} xs={{ span: 20 }} xl={{ span: 10 }}>
          {props.children}
        </StyledCol2>
      </StyledRow>
    </Container>
  )
}

export default SignLayout

const StyledRow = styled(Row)`
  min-height: 100vh;
  padding: 10px;
  @media (max-width: 992px) {
    flex-flow: column;
  }
`
const Container = styled(Layout.Content)`
  background: ${blue[4]};
  overflow-x: hidden;
`
const Illustration = styled(OtomatisIllustration)`

`
const StyledCol1 = styled(Col)`
  height: 554px;
  background: #FFF;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 1px solid #F0F0F0;
  @media (max-width: 992px) {
    height: 250px;
    width: 100%;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-right: none;
    border-bottom: 1px solid #F0F0F0;
  }
  @media (max-width: 576px) {
    height: unset;
  }
`
const StyledCol2 = styled(Col)`
  height: 554px;
  background: #FFF;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  @media (max-width: 992px) {
    width: 100%;
    height: unset;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
  }
`
const IllustrationWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const StyledDivider = styled.div`
  width: 65px;
  height: 3px;
  background: #F3AD3D;
  margin: 16px auto;
`
export const StyledText = styled.p`
  /* P1 - 14 */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
`

const StyledTextCenter = styled(StyledText)`
  text-align: center;
`
export const StyledTitle = styled.h1`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: #5E6C84;
  margin-bottom: 0;
`