import { FC } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { useLoggedInUser } from '../../../contexts/LoggedInUserContextProvider'
import AdminDashboardPage from './adminDashboard'
import ProfilePage from './profile'
import SecurityPage from './security'
import TwoFactorAuthSetupPage from './security/TwoFactorAuth/setup'

const SettingsPage: FC = () => {
  const { path } = useRouteMatch()
  const [loggedInUser] = useLoggedInUser()
  return (
    <>
      <Switch>
        <Redirect exact from={path} to={`${path}/profile`} />
        <Route path={`${path}/profile`} component={ProfilePage} />
        <Route path={`${path}/security`} exact component={SecurityPage} />
        <Route path={`${path}/security/2fa/setup`} component={TwoFactorAuthSetupPage} />

        { loggedInUser?.isAdmin ? <Route path={`${path}/admin`} component={AdminDashboardPage} /> : null}
      </Switch>
    </>
  )
}

export default SettingsPage