import { Version } from 'db'
import { Error } from '../../../types'
import { useApi } from '../../useFetch'

type Result = {
  clonedVersion?: Version,
  cloningVersion: boolean,
  errorCloneVersion?: Error,
  cloneVersion: (applicationId: number, clonedVersionIdFrom: number, payload: Partial<Version>) => void
}

export default function useCloneVersion(): Result {
  const { data, error, isValidating, call } = useApi<{ version: Version }>()

  return {
    clonedVersion: data?.version,
    cloningVersion: isValidating,
    errorCloneVersion: error,
    cloneVersion: (applicationId, clonnedVersionId, payload) => {
      call({
        url: `/application/${applicationId}/version?clone=${clonnedVersionId}`,
        method: 'post',
        data: {
          version: {
            ...payload,
          }
        }
      })
    }
  }
}