import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Divider, Form, Input, message } from 'antd'
import Axios from 'axios'
import React, { useState } from 'react'
const Recaptcha = require('react-google-invisible-recaptcha').default // ts angy to this lib, import Recaptcha from 'react-google-invisible-recaptcha'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import EmailVerification from '../../components/EmailVerification'
import SignLayout, { StyledText as BaseStyledText, StyledTitle } from '../../components/SignLayout'
import Button from '../../components/StyledButton'
import { CLIENT_ID_GOOGLE, CLIENT_ID_WEB, OTORISASI_API_URL, SITE_KEY_RECAPTCHA } from '../../helpers/Constant'
import { encrypt } from '../../helpers/Crypto'

const Signup: React.FC = () => {
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [verify, setVerify] = useState<boolean>()
  const [startCounter, setStartCounter] = useState<boolean>()
  const [captcha, setCaptcha] = useState<any>()

  const signup = async () => {
    try {
      const token = await captcha?.execute()
      const { data } = await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/google/recaptcha`, { token }, { withCredentials: true })
      if (!data?.isValid) throw new Error()
    } catch (error) {
      return message.error('Robot detected!')
    }
    try {
      await Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/register`, {
        user: {
          username: email,
          name: email?.split('@')[0],
          password: encrypt(password as string),
          email,
          clientId: CLIENT_ID_WEB
        }
      }, { withCredentials: true })
      setVerify(true)
      setStartCounter(true)
      return message.success('Success!')
    } catch (error) {
      return message.error(error?.response?.data?.error || 'Something error, please try again')
    }
  }

  const googleLogin = async () => {
    const { data } = await Axios.get(`${OTORISASI_API_URL}/api/auth/v1/google/authUrl`, {
      params: {
        clientId: CLIENT_ID_GOOGLE,
        ...window.location.search ? { state: decodeURIComponent(window.location.search) } : {}
      }
    })
    window.location.href = data?.auth?.google
  }

  return (
    <SignLayout>
      <Card bordered={false}>
        { verify ?
          <EmailVerification email={email as string} onError={err => message.error(err)} startCounting={!!startCounter} onCounterFinished={() => setStartCounter(false)} />
          :
          <Form onFinish={signup} layout="vertical" requiredMark="optional">
            <StyledTitle>Mulai Buat Pekerjaan Kamu Lebih Otomatis!</StyledTitle>
            <br/>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Email cannot be blank' }]}>
              <Input type="email" onChange={e => setEmail(e.target.value)} placeholder="Email kamu" prefix={<UserOutlined />} value={email} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Password cannot be blank' }]}>
              <Input.Password onChange={e => setPassword(e.target.value)} placeholder="Password kamu" prefix={<LockOutlined />} value={password} />
            </Form.Item>

            <Recaptcha
              ref={(ref: any) => setCaptcha(ref)}
              sitekey={SITE_KEY_RECAPTCHA}
              badge="bottomleft" />

            <Form.Item>
              <Button style={{ width: '100%', background: '#1256DF' }} type="primary" htmlType="submit">Daftar Sekarang</Button>
            </Form.Item>

            <StyledDivider plain dashed style={{ color: '#BAC4CD' }}>Atau</StyledDivider>
            <p style={{ marginBottom: '10px' }}>
              <Button htmlType="button" icon={<img src="/misc/google-icon.svg" alt="google" width="14px" />} block style={{ display: 'grid',gridTemplateColumns: 'max-content auto', alignItems: 'center' }} onClick={googleLogin}>Sign up with Google</Button>
            </p>
            <StyledText2>Sudah memiliki akun? <Link to="/auth/login" style={{ color: '#1256DF' }}>Masuk Disini</Link></StyledText2>
          </Form>
        }
      </Card>
    </SignLayout>
  )
}

export default Signup

const StyledText2 = styled(BaseStyledText)`
  color: #8993A4;
`
const StyledDivider = styled(Divider)`
  text-align: center;
  .ant-divider-inner-text {
      /* P1- Strong */
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: rgba(0, 0, 0, 0.65);
  }
`