import { Tabs } from 'antd'
import React, { forwardRef } from 'react'
import { DataSuggestion } from 'src/components/pages/developer/formItems/DataPickerPopper'
import { DataPickerPopperContainer, DualModeTabsDataPicker } from '../DualModeDataPickerPopper'
import StateTestDataPicker from '../StateTestDataPicker'
import DateTimeDataPicker from './DateTimeDataPicker'

export type DualModeDataPickerPopperDateTimeProps = {
  mode: 'date-time' | 'custom',
  loading?: boolean,
  selectedValue: string,
  statesWithTestData: Record<string, DataSuggestion> | null, // trigger and trigger.application should be expanded
  onChangeDate: (dateTime: any) => void,
  onTagClick: (tagValue: string) => void,
  onModeChange: (mode: 'date-time' | 'custom') => void
}

const DualModeDataPickerPopperDateTime = forwardRef<HTMLDivElement, DualModeDataPickerPopperDateTimeProps>(({ onChangeDate, mode, selectedValue, statesWithTestData, onTagClick, onModeChange }, ref) => {
  const { TabPane } = Tabs

  // dual mode data picker
  return (
    <DataPickerPopperContainer style={{ width: mode === 'date-time' ? 'fit-content' : undefined }} ref={ref}>
      <DualModeTabsDataPicker
        onChange={(activeKey: string) => onModeChange(activeKey as 'date-time' | 'custom')} activeKey={mode}>
        <TabPane tab="Select Date" key="date-time">
          <DateTimeDataPicker onChangeDate={onChangeDate} value={selectedValue} />
        </TabPane>
        <TabPane tab="Custom" key="custom">
          <StateTestDataPicker
            statesWithTestData={statesWithTestData}
            onTagClick={onTagClick}
          />
        </TabPane>
      </DualModeTabsDataPicker>
    </DataPickerPopperContainer>
  )
})

export default DualModeDataPickerPopperDateTime

