import { PricingPlan } from 'db'
import { Error } from '../../types'
import useFetch from '../useFetch'


type Result = {
  pricingPlan?: PricingPlan,
  fetchingPricingPlan: boolean,
  errorFetchPricingPlans?: Error
}

export default function useAPricingPlan(pricingPlanId?: number | string): Result {
  const url = pricingPlanId ? `/pricingPlan/${pricingPlanId}` : null
  const { data, error, isValidating } = useFetch<{ pricingPlan: PricingPlan }>(url, {}, { revalidateOnFocus: false })

  return {
    pricingPlan: data?.pricingPlan,
    fetchingPricingPlan: isValidating,
    errorFetchPricingPlans: error
  }
}