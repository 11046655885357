import { UploadOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useLoggedInUser } from '../../../../contexts/LoggedInUserContextProvider'
import useImportApplication from '../../../../hooks/application/main/useImportApplication'
import { StyledButton } from '../../../components/StyledComponents'

interface Props {
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>
}

const ModalImportApp: FC<Props> = ({ visible, setVisible }) => {
  const [fileList, setFileList] = useState<any[]>()
  const [loggedInUser] = useLoggedInUser()
  const { importApplication, importingApplication } = useImportApplication(loggedInUser?.id)

  const propsUpload = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: file => {
      setFileList([file])
      return false
    },
    fileList,
  }

  const close = () => setVisible(false)

  return (
    <Modal visible={visible}
      title="Import Application by JSON file"
      onOk={() => importApplication(fileList?.[0], close)}
      onCancel={close}
      okButtonProps={{ disabled: !fileList?.[0], loading: importingApplication }}
    >
      <Upload {...propsUpload}>
        <StyledButton icon={<UploadOutlined />}>Select File</StyledButton>
      </Upload>
    </Modal>
  )
}

export default ModalImportApp