import { Space } from 'antd'
import { FC } from 'react'
import SavingSignifier from '../templateScenario/editor/SavingSignifier'
import ScenarioTableActions, { ScenarioTableActionsProps } from './all/ScenarioTableActions'

interface Props extends ScenarioTableActionsProps {

}

const EditScenarioHeaderActions: FC<Props> = (props) => {
  return (
    <Space>
      <SavingSignifier />
      <ScenarioTableActions {...props}/>
    </Space>
  )
}

export default EditScenarioHeaderActions