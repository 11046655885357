import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined } from '@ant-design/icons'
import { Button, Collapse, Divider, Space, Tabs } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import flatten from 'flat'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import DataResponse from '../../../../../../../components/pages/app/history/job/DataResponse'
import { useDataSuggestion } from '../../../../../../../components/pages/developer/formItems/context/DataSuggestionContext'
import { DataSuggestion } from '../../../../../../../components/pages/developer/formItems/DataPickerPopper'
import useAuthenticationTest from '../../../../../../../hooks/application/authentication/useAuthenticationTest'
import useCreateOrUpdateAuthentication from '../../../../../../../hooks/application/authentication/useCreateUpdateAuthentication'
import useApplication from '../../../../../../../hooks/application/main/useApplication'
import { useRevalidateFetch } from '../../../../../../../hooks/useFetch'
import { IMPLIKASI_URL } from '../../../../../../../util/Constant'
import Notification from '../../../../../../../util/Notification'
import { StyledAlert, StyledButton, StyledCard, StyledDescription, StyledFooterForm, StyledLabel } from '../../../../../../components/StyledComponents'
import MonacoEditor from '../../../../../components/form/MonacoEditor'
import FormParamSchema from '../../../../components/FormParamSchema'
import { useApplicationVersionContext } from '../../../../contexts/ApplicationVersionContextProvider'
import { baseApplicationRoute } from '../../../../routes'
import { PropsContentStep } from '../../create/data'
import { useSharedValues } from '../SharedValuesContextProvider'


const TestAuthentication: FC<PropsContentStep> = ({ isActive, info, onPrevious }) => {
  const { revalidateAllAuthentication } = useRevalidateFetch()
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()
  const { application } = useApplication(applicationId)
  const { createOrUpdateAuthentication, creatingAuthentication } = useCreateOrUpdateAuthentication()
  const [values, setValues] = useSharedValues()
  const { testAuthentication, testingAuthentication } = useAuthenticationTest(applicationId, values?.id, values?.versionId)
  const history = useHistory()
  const [testingWithCreating, setTestingWithCreating] = useState(false)
  const [version] = useApplicationVersionContext()
  const [bundles, setBundles] = useState<any>()
  const [response, setResponse] = useState<any>()
  const [form] = useForm()
  const [dataSuggestion, setDataSuggestion] = useDataSuggestion()

  useEffect(() => {
    let suggestion: { [key: string]: DataSuggestion} | null = dataSuggestion
    if (bundles && values?.fieldSchema && values.fieldSchema.length > 0) {
      let flatAuthData: any = {}
      if (bundles.authData) {
        flatAuthData = flatten(bundles.authData)
        form.setFieldsValue(bundles.authData)
      }
      const flat = values?.fieldSchema?.reduce((acc, field) => ({
        ...acc,
        [`authData.${field.key}`]: {
          sample: flatAuthData?.[field.key],
          value: `authData.${field.key}`,
          label: `${field.key}`,
        }
      }), {})
      suggestion = {
        ...suggestion,
        authData: {
          name: 'Auth Data',
          flatTestData: flat,
          testData: bundles.bundle
        }
      }
    }
    if (bundles?.bundle) {
      const flatBundle: any = flatten(bundles.bundle)
      const flat = Object.keys(flatBundle || {}).reduce((acc, key) => ({
        ...acc,
        [`bundle.${key}`]: {
          sample: flatBundle?.[key],
          value: `bundle.${key}`,
          label: `${key}`,
        }
      }), {})
      suggestion = {
        ...suggestion,
        bundle: {
          name: 'Bundle',
          flatTestData: flat,
          testData: bundles.bundle
        }
      }
    }
    setDataSuggestion(prevState => ({ ...prevState, ...suggestion }))
  }, [bundles])

  const onTest = async () => {
    // create or update authentication first
    if (values) {
      setTestingWithCreating(true)
      const create = await createOrUpdateAuthentication(applicationId, { ...values, isDraft: false,  versionId: values.versionId || version?.id })
      if (create) {
        setValues({ ...values, ...create })
        const windowAuth = window.open(`${IMPLIKASI_URL}/${applicationId}/run/auth/${create?.id}?versionId=${version?.id}`, '_blank', 'height=600,width=800,centerscreen,chrome=yes')
        if (windowAuth) {
          windowAuth.onbeforeunload = () => {
            const windowAuthAsAny = windowAuth as any
            if (windowAuthAsAny.bundles) {
              // Old code. It assumes that nameAccountOtomatis always has a value, it isn't.
              // const { nameAccountOtomatis: _name, ...payload } = windowAuthAsAny.bundles

              const { nameAccountOtomatis, ...payload } = windowAuthAsAny.bundles
              const _name = nameAccountOtomatis // possibly undefined because it is optional field

              setBundles({ ...payload })
              windowAuthAsAny.bundles = undefined
            }
          }
        }
      }
      setTestingWithCreating(false)
    }
  }

  useEffect(() => {
    const checkEvent = (event: any) => {
      if (event.data.source === 'otomatis') {
        if (event.data.payload.authData) {
          const { nameAccountOtomatis: _name, ...payload } = event.data.payload
          setBundles({ ...payload })
        }
        if (event.data.payload.bundle) {
          const { nameAccountOtomatis: _name, ...payload } = event.data.payload
          setBundles({
            ...bundles,
            ...payload
          })
        }
      }
    }
    window.addEventListener('message', checkEvent)
    return () => {
      window.removeEventListener('message', checkEvent)
    }
  }, [])

  const test = async () => {
    setResponse(undefined)
    const payload = bundles
    if (bundles) {
      const test = await testAuthentication(payload)
      if (test) {
        setResponse(test)
      }
      if (test?.data?.error) {
        Notification.error({
          message: 'Something\'s Wrong',
          description: JSON.stringify(test.data.error)
        })
      }
    } else {
      Notification.error({
        message: 'Something\'s Wrong',
        description: 'No Account connected, please sign-in first'
      })
    }
  }


  const getStatusRequest = () => {
    const res = response
    if (res) {
      if (res?.success) {
        return (
          <Space>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <p>Request Succeed</p>
          </Space>
        )
      }
      return (
        <Space>
          <CloseCircleTwoTone twoToneColor={'#9C3930'} />
          <p>Request Failed</p>
        </Space>
      )
    }
  }

  const onRemoveAccount = () => {
    setBundles(undefined)
  }

  const onFinish = async () => {
    if (values) {
      await createOrUpdateAuthentication(applicationId, { ...values, isDraft: false,  versionId: values.versionId || version?.id })
      revalidateAllAuthentication(applicationId, values.versionId || version?.id)
      history.push(`${baseApplicationRoute}/${applicationId}/${versionName}/main/authentication`)
    }
  }

  const onClearResponse = () => {
    setResponse(undefined)
  }

  return (
    <StyledTestAuthentication className={isActive ? 'show' : undefined}>
      {info ? <><StyledAlert message={<div dangerouslySetInnerHTML={{ __html: info }} />} type="info" /><br/></> : null}
      <StyledCard style={{ padding: 0 }}>
        <Tabs defaultActiveKey="1" tabBarExtraContent={getStatusRequest()}>
          <Tabs.TabPane tab="Test Setup" key="1">
            <StyledLabel className="no-status">Test your Authentication Configuration</StyledLabel>
            <StyledDescription>Connect to your app using the above settings with a personal or testing account, and Otomatis will use this connection to test API calls as you build the application.</StyledDescription>
            <br />
            {
              bundles ?
                <>
                  <Space align="center">
                    <h5 style={{ margin: 0 }}>Sample Account Connected</h5>
                    <Button icon={<DeleteOutlined />} onClick={onRemoveAccount}>Remove Sample Account</Button>
                  </Space>
                  {
                    (values?.fieldSchema?.length || 0) > 0 ?
                      <Collapse ghost>
                        <Collapse.Panel key={'1'} header={`Detail ${bundles.user?.name || ''} Account`}>
                          <FormParamSchema
                            isDisabledAll
                            form={form}
                            initialParamSchema={values?.fieldSchema}
                          ></FormParamSchema>
                        </Collapse.Panel>
                      </Collapse> :
                      null
                  }
                </>
                :
                <StyledButton style={{ background: '#F3AD3D', color: '#FFF' }} loading={testingWithCreating} onClick={onTest}>Sign in to {application?.name} ({'0.0.1'})</StyledButton>
            }
            <Divider />
            <StyledDescription>Run your Test request using the account you connected to make sure that everything is working. Having trouble connecting?</StyledDescription>
            <Space>
              <StyledButton onClick={onClearResponse}>Clear Response</StyledButton>
              <StyledButton type="primary" ghost onClick={test} loading={testingAuthentication} >Test Authentication</StyledButton>
            </Space>
            {response ? <StyledCardResponse style={{ marginTop: 8 }}><DataResponse data={response?.data || response} /></StyledCardResponse> : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Bundle" key="3">
            {
              bundles ?
                <MonacoEditor options={{ readOnly: true }}  value={JSON.stringify(bundles, null, 2)} />
                :
                'Connect to your account first'
            }
          </Tabs.TabPane>
        </Tabs>
      </StyledCard>
      <br />
      <StyledFooterForm>
        {onPrevious ? <StyledButton htmlType="button" onClick={onPrevious}>Previous</StyledButton> : null}
        <StyledButton type="primary" onClick={onFinish} loading={creatingAuthentication && !testingWithCreating}>{'Save & Finish'}</StyledButton>
      </StyledFooterForm>
    </StyledTestAuthentication>
  )
}

export default TestAuthentication

const StyledTestAuthentication = styled.div`
  &.show {
    display: block;
  }
  display: none;
`
const StyledCardResponse = styled(StyledCard)`
  .ant-card-body {
    padding: 0;
  }
`