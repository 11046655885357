import { FC } from 'react'
import { Route, Switch } from 'react-router'
import NotFound from '../../../../error/NotFound'
import SharedValueTriggerContextProvider from './components/SharedValueTriggerContextProvider'
import CreateTrigger from './create'
import UpdateTrigger from './update'
import TriggerView from './view'

const Trigger: FC = () => {
  const path = '/developer/application/:applicationId/:versionName/main/trigger'

  return (
    <SharedValueTriggerContextProvider>
      <Switch>
        <Route exact path={path} component={TriggerView}></Route>
        <Route exact path={`${path}/create`} component={CreateTrigger}></Route>
        <Route exact path={`${path}/:triggerId`} component={UpdateTrigger}></Route>
        <Route component={NotFound} />
      </Switch>
    </SharedValueTriggerContextProvider>
  )
}

export default Trigger