import { AxiosPromise } from 'axios'
import { User } from 'db'
import { Error } from '../../types'
import { useApi } from '../useFetch'

type Result = {
  updatedUser?: User,
  updatingUser: boolean,
  errorUpdateUsers?: Error,
  updateUser: (userId?: number | string, payload?: Partial<User>) => AxiosPromise<{ user: User }>
}

export default function useUpdateUser(): Result {
  const { data, error, isValidating, call } = useApi<{ user: User }>()

  return {
    updatedUser: data?.user,
    updatingUser: isValidating,
    errorUpdateUsers: error,
    updateUser: async (userId?: number | string, payload?: Partial<User>) => {
      return await call({
        url: `/user/${userId || 'me'}`,
        method: 'patch',
        data: {
          user: payload
        }
      })
    }
  }
}