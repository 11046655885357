import { Spin } from 'antd'
import { FC } from 'react'
import useAllAuthentication from 'src/hooks/application/authentication/useAllAuthentication'
import useAllAuthenticationSchema from 'src/hooks/authenticationSchema/useAllAuthenticationSchema'

interface Props {
  applicationId: number,
  versionId?: number
}

const AuthSchemaField: FC<Props> = ({ applicationId, versionId }) => {
  const { authentications, fetchingAllAuthentications } = useAllAuthentication(applicationId, versionId)
  const { authenticationSchemas } = useAllAuthenticationSchema()
  const authenticationSchemaId = authentications?.[0]?.authenticationSchemaId
  return (
    <>
      {
        fetchingAllAuthentications ?
          <Spin spinning /> :
          authenticationSchemas?.find(authenticationSchema => authenticationSchema.id === authenticationSchemaId)?.name
      }
    </>
  )
}

export default AuthSchemaField