import { MenuOutlined } from '@ant-design/icons'
import { Spin, Table, Tag } from 'antd'
import arrayMove from 'array-move'
import { FieldSchema } from 'db'
import { FC } from 'react'
import { SortableContainer as sortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableContainer = sortableContainer((props: any)=> <tbody {...props} />)

interface Props {
  mapSchema: FieldSchema[],
  loading: boolean,
  onChangeMapSchema: (newMapSchema: FieldSchema[]) => void
}


const NestedTableMapSchema: FC<Props> = ({ mapSchema, loading, onChangeMapSchema }) => {
  const dataSource = mapSchema.map((field, idx) => ({ ...field, idx: idx })) || []

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => loading ? null: <DragHandle />,
    },
    { title: 'Label', dataIndex: 'label', className: 'drag-visible' },
    { title: 'Key', dataIndex: 'key' },
    { title: 'Type', dataIndex: 'type' },
    { title: 'Tag', dataIndex: 'required', render: (required: boolean) => required ? <Tag color="red">Required</Tag> : '' },
  ]

  const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const temp: any[] = []
      const newData = arrayMove(temp.concat(dataSource), oldIndex, newIndex).filter(el => !!el).map(field => ({ ...field, idx: undefined }))
      onChangeMapSchema(newData)
    }
  }

  const DraggableContainer = (props: any) =>
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />


  const DraggableBodyRow = (props: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.idx === props['data-row-key'])
    return <SortableItem index={index} {...props} />
  }

  return (
    <Spin spinning={loading}>
      <div style={{ paddingLeft: 35 }}>
        <Table
          scroll={{ x: true }}
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="idx"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>

    </Spin>
  )

}

export default NestedTableMapSchema