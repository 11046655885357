import { Scenario } from 'db'
import { useState } from 'react'
import { TableState } from '../../types'
import { useApi, useRevalidateFetch } from '../useFetch'

type MoveScenarios = {
  movingScenarios: boolean,
  moveScenarios: (scenarios: Scenario[], folderId: number | null) => Promise<{ scenario: Scenario }[]>
}

export default function useMoveScenarios(tableState?: TableState<Scenario>): MoveScenarios {
  const { call } = useApi<{ scenario: Scenario }>()
  const [movingScenarios, setMovingScenarios] = useState(false)
  const { revalidateScenarios } = useRevalidateFetch()

  const moveScenarios = (scenarios: Scenario[], folderId: number | null) => {
    setMovingScenarios(true)
    const moves = Promise.all(scenarios.map(async (scenario) => {
      const { id } = scenario
      return call({
        url: `/scenario/${id}`,
        method: 'patch',
        data: {
          scenario: {
            folderId
          }
        }
      })
        .then(({ data }) => data)
    }))

    moves.then(() => {
      revalidateScenarios(tableState)
      setMovingScenarios(false)
    })

    return moves
  }

  return {
    movingScenarios,
    moveScenarios
  }
}