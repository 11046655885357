import { Col, DatePicker, Row, Space, Table, Tag } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { LogJob, State } from 'db'
import { DateTime } from 'luxon'
import { FC, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import AvatarApplicationGroups from '../../../../components/pages/app/AvatarApplicationGroups'
import DetailJob from '../../../../components/pages/app/history/job/DetailJob'
import useLogJobs from '../../../../hooks/job/useLogJobs'
import { TableState } from '../../../../types'
import { red, success, warning } from '../../../../util/colors'
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../../util/Constant'
import { StyledInput } from '../../../components/StyledComponents'

interface Props {
  start?: number,
  end?: number
}

const LogJobTable: FC<Props> = ({ start, end }) => {
  const [tableState, setTableState] = useState<TableState<LogJob>>({
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE
    },
    sorter: {
      field: 'createdAt',
      order: 'descend'
    }
  })
  const { logJobs, fetchingLogJobs, logJobsLength } = useLogJobs(tableState, start && end ? { start: start, end: end } : undefined)
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { id, invoiceId } = useParams<{ id?: string, invoiceId?: string }>()

  const columns: ColumnsType<LogJob> = [
    { title: 'Apps',
      render: (_, record) => {
        const applications = (record.statesSnapshots as State[])?.sort((s1, s2) => s1.order - s2.order).map(state => (state.trigger || state.action)?.application)
        return <AvatarApplicationGroups applications={applications} />
      }
    },
    { title: 'Scenario Name', render: (_, record) => {
      return  <>{record.scenario?.name} <Tag color="default">ID: {record.scenarioId}</Tag> {record.scenario?.deletedAt ? <Tag color="orange">archived</Tag> : null}</>
    } },
    { title: 'Executed at', dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      filteredValue: (tableState?.filter?.createdAt) as FilterValue || null,
      filterDropdown: () => <DatePicker.RangePicker
        value={tableState.filter?.createdAt ? tableState.filter.createdAt as any : undefined}
        onChange={(values: any) => handleChange(tableState?.pagination, { ...tableState?.filter, createdAt: values }, tableState?.sorter)}
        allowClear
        minuteStep={30}
      />
      ,
      sorter: (logJob1, logJob2) => new Date(logJob1.createdAt).getTime() - new Date(logJob2.createdAt).getTime(),
      render: (lastExecuted) => lastExecuted ? DateTime.fromJSDate(new Date(lastExecuted)).toFormat('DD t') : '' },
    { title: 'Task', dataIndex: 'runningTasks' },
    { title: 'Status', render: (_, record) => {
      if (record.error){
        return <strong style={{ color: red[4] }}>Failed</strong>
      }
      if (record.status) {
        return <strong style={{ textTransform: 'capitalize', color: record.status === 'complete' ? success : record.status === 'error' || record.status === 'partialError' ? red[4] : warning }}>{record.status}</strong>
      }
    }
    },
    { title: 'Action', dataIndex: 'id', render: (id) => <Space>
      <a onClick={() => push(invoiceId ? `${url}/${id}` : `${url}/current/${id}`)} >See Detail</a>
    </Space> },
  ]

  const onSearch = (value: string) => {
    setTableState(prevState => ({ ...prevState, filter: { search: value }, pagination: { ...prevState.pagination, current: 1 }  }))
  }

  const handleChange = (pagination: TablePaginationConfig | undefined, filter: Record<string, FilterValue | null>, sorter: SorterResult<LogJob> | SorterResult<LogJob>[] | undefined, extra?: TableCurrentDataSource<LogJob>) => {
    setTableState(prevState => ({ ...prevState, pagination, filter: { ...prevState.filter, ...filter }, sorter, extra }))
  }

  return (
    <>
      {
        id ?
          <DetailJob /> :
          <>
            <Row justify="space-between" gutter={[16, 16]} align="middle">
              <Col>
                <h5>Job Executed</h5>
              </Col>
              <Col>
                <StyledInput.Search placeholder="Search Scenario" allowClear onSearch={onSearch} style={{ width: 200 }} />
              </Col>
            </Row>
            <br />
            <Table
              onChange={handleChange}
              pagination={{
                current: tableState.pagination?.current,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                showSizeChanger: true,
                total: logJobsLength,
                pageSize: tableState.pagination?.pageSize
              }}
              columns={columns}
              rowKey="id"
              dataSource={logJobs}
              scroll={{ x: true }}
              loading={fetchingLogJobs}
            />
          </>
      }
    </>
  )
}

export default LogJobTable