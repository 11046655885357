import { FormItemProps } from 'antd'
import { FC } from 'react'
import FormItem from './FormItem'
import MonacoEditor from './MonacoEditor'

interface Props extends FormItemProps {
  description?: string
}

const FormCodeItem: FC<Props> = ({ label, description, extra, name }) => {
  return (
    <FormItem label={label} name={name} description={description} extra={extra}>
      <MonacoEditor defaultLanguage="javascript"/>
    </FormItem>
  )
}

export default FormCodeItem