import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Layout, Row } from 'antd'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CLIENT_ID_WEB, OTORISASI_API_URL } from '../../helpers/Constant'

const Verification: React.FC = () => {
  const history = useHistory()
  const [email, setEmail] = useState<string>()
  const [verified, setVerified] = useState<boolean>()
  const [error, setError] = useState<string | null>()

  useEffect(() => {
    if (!window.location.search) {
      return history.push('/auth/login')
    }
    const params = new URLSearchParams(window.location.search)
    if (!params.get('email') || !params.get('code')) {
      return history.push('/auth/login')
    }
    setEmail(params.get('email') as string)

    setTimeout(() => {
      Axios.post(`${OTORISASI_API_URL}/api/auth/v1/auth/verification`, {
        email: params.get('email'),
        code: params.get('code'),
        clientId: CLIENT_ID_WEB
      }, { withCredentials: true })
        .then(() => {
          setVerified(true)
          return setTimeout(() => history.push('/app'), 3000)
        })
        .catch(err => {
          setVerified(false)
          return setError(err?.response?.data?.error || 'Something error, please reload the browser')
        })
    }, 1500)
  }, [history])

  return (
    <Layout.Content>
      <Row>
        <Col span={24} lg={{ span: 6, offset: 9 }} md={{ span: 12, offset: 6 }}>
          <Card title="Email Verification">
            { error === undefined && !verified ? <p>Please wait... <LoadingOutlined /></p> : '' }
            {
              error === undefined && verified ?
                <>
                  <p>Your email address <strong>({email})</strong> was successfully verified.</p>
                  <p>Redirecting you to login page... <LoadingOutlined /></p>
                </>
                : ''
            }
            { error && !verified ? <p>{error}</p> : '' }
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default Verification