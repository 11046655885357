import { Button, Input, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import useCreateAScenarioState from 'src/hooks/scenario/state/useCreateAScenarioState'
import { useRevalidateFetch } from 'src/hooks/useFetch'
import CustomForm from 'src/pages/developer/components/form/CustomForm'
import FormItem from 'src/pages/developer/components/form/FormItem'
import useOpenCloseModal from 'src/util/useOpenCloseModal'
import styled from 'styled-components'
import EventField from '../StateDetailAndForm/EventForm/EventField'

const AddAction: FC = () => {
  const { openModal, closeModal, visible } = useOpenCloseModal()
  const [selectedApplicationId, setSelectedApplicationId] = useState<number>()
  const [form] = useForm()
  const { revalidateAScenario } = useRevalidateFetch()
  const { scenarioId } = useParams<{ scenarioId: string }>()
  const { createAScenarioState, creatingAScenarioState } = useCreateAScenarioState()

  const onValuesChange = (value: any) => {
    const keys = Object.keys(value)
    if (keys.includes('applicationId')) {
      setSelectedApplicationId(value.applicationId)
      form.setFieldsValue({
        actionId: null
      })
    }
  }

  const onFinish = (values: any) => {
    createAScenarioState(Number(scenarioId), {
      name: values.name,
      actionId: values.actionId,
      order: -1
    }).then(() => {
      revalidateAScenario(Number(scenarioId))
      closeModal()
    })
  }
  return (
    <>
      <Container>
        <Button onClick={openModal}>Add Action Node</Button>
      </Container>
      <Modal footer={null} visible={visible} onCancel={closeModal}>
        <CustomForm form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
          <FormItem small label="Name" name={'name'}>
            <Input size="small" />
          </FormItem>
          <EventField selectedApplicationId={selectedApplicationId} form={form} type={'action'} />
          <br />
          <FormItem shouldUpdate>
            {({ getFieldValue }) => {
              const disabled = !getFieldValue('actionId') || !getFieldValue('name')
              return <Button style={{ float: 'right' }} disabled={disabled} loading={creatingAScenarioState} type="primary" htmlType="submit">Save</Button>
            }}
          </FormItem>
        </CustomForm>
      </Modal>
    </>

  )
}

export default AddAction

const Container = styled.div`
  position: absolute;
  right: 24px;
  bottom: 300px;
  z-index: 100;
`