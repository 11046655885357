import { Action, State } from 'db'
import { Connection, Edge, Node } from 'react-flow-renderer'
import useArchiveAScenarioState from 'src/hooks/scenario/state/useArchiveAScenarioState'
import useUpdateAScenarioState from 'src/hooks/scenario/state/useUpdateAScenarioState'
import { useRevalidateFetch } from 'src/hooks/useFetch'


interface SyncConnectionStates {
  onDeleteNodes: (nodes: Node[], allEdges?: Edge[], allNodes?: Node<State>[]) => void,
  onUpdateEdge: (oldEdge: Edge, newConnection: Connection, allEdges: Edge[], allNodes: Node<State>[]) => void,
  onAddEdge: (connection: Connection, allEdges?: Edge[], allNodes?: Node<State>[]) => void,
  onDeleteEdges: (deletedEdges: Edge[], allEdges?: Edge[], allNodes?: Node<State>[]) => void
}

export default function useSyncConnectionStates(): SyncConnectionStates {
  const { updateAScenarioState  } = useUpdateAScenarioState()
  const { revalidateAScenario } = useRevalidateFetch()
  const { archiveAScenarioState } = useArchiveAScenarioState()


  const onUpdateEdge = (oldEdge: Edge, newConnection: Connection, allEdges: Edge[], allNodes: Node<State>[]) => {
    const source = allNodes?.find(node => `${node.data.id}` === newConnection.source)?.data
    if (newConnection.target && source) {
      const needUpdateOrder: { id: string, order: number, stateConditionId: number | null }[] = []
      const findNextEdge = (id: string, order: number, stateConditionId: number | null) => {
        needUpdateOrder.push({ id, order: order, stateConditionId })
        const currentNode = allNodes.find(node => node.id === id)?.data
        allEdges?.filter(edge => edge.source === id).forEach(edge => {
          findNextEdge(edge.target, order === -1  ? order : order + 1, currentNode?.action?.type === 'path' ? currentNode.id : stateConditionId)
        })
      }
      findNextEdge(newConnection.target, source?.order === -1 ? source.order : (source?.order || 0) + 1, source.action?.type === 'path' ? source.id : source.stateConditionId || null)
      findNextEdge(oldEdge.target, -1, null)
      const { scenarioId, id, childrenIds } = source
      // update childrendIds dri source remove oldEdge.target, + newConnection,
      updateAScenarioState(scenarioId, id, {
        childrenIds: [Number(newConnection.target), ...childrenIds?.filter(childId => `${childId}` !== oldEdge.target) || []]
      }).then(() => {
        // update old edge.target.parentId to null
        updateAScenarioState(scenarioId, Number(oldEdge.target), {
          parentId: null,
        }).then(() => {
          // update newConnectionParentId to source
          updateAScenarioState(scenarioId, Number(newConnection.target), {
            parentId: id
          }).then(() => {
            // update order next dri old edge to -1
            // update order next dri new Connection dynamically
            Promise.all(needUpdateOrder.map(async ({ id, order, stateConditionId }) => {
              const currentStateActionType: Action['type'] | undefined = allNodes.find(node => node.id === id)?.data.action?.type
              return await updateAScenarioState(scenarioId, parseInt(id), {
                order,
                stateConditionId,
                conditionGroupOrder: currentStateActionType === 'path' ? order:  0 // if path -> order : null
              })
            })).then(() => {
              // revalidate scenario
              revalidateAScenario(scenarioId)
            })
          })
        })
      })

    }
  }

  const onAddEdge = (connection: Connection, allEdges?: Edge[], allNodes?: Node<State>[]) => {
    const source = allNodes?.find(node => `${node.data.id}` === connection.source)?.data
    const needUpdateOrder: { id: string, order: number, stateConditionId: number | null }[] = []
    const findNextEdge = (id: string, order: number, stateConditionId: number | null) => {
      needUpdateOrder.push({ id, order: order, stateConditionId })
      const currentNode = allNodes?.find(node => node.id === id)?.data
      allEdges?.filter(edge => edge.source === id).forEach(edge => {
        findNextEdge(edge.target, order === -1  ? order : order + 1, currentNode?.action?.type === 'path' ? currentNode.id : stateConditionId)
      })
    }
    if (connection.target && source) {
      findNextEdge(connection.target, source?.order === -1 ? source.order : (source?.order || 0) + 1, source.action?.type === 'path' ? source.id : source.stateConditionId || null)
      const { scenarioId, id, childrenIds } = source
      // update childrenIds addedEdge.source (add addedEdge.target di state[addedEdge.source].childrenIds)
      updateAScenarioState(scenarioId, id, {
        childrenIds: [...childrenIds || [], parseInt(connection.target)]
      }).then(() => {
        // update parentId addedEdge.target to addedEdge.source
        updateAScenarioState(scenarioId, Number(connection.target), {
          parentId: Number(connection.source)
        }).then(() => {
          // update order target dan all next target dynamically
          Promise.all(needUpdateOrder.map(async ({ id, order, stateConditionId }) => {
            const currentStateActionType: Action['type'] | undefined = allNodes?.find(node => node.id === id)?.data.action?.type
            return await updateAScenarioState(scenarioId, parseInt(id), {
              order,
              stateConditionId,
              conditionGroupOrder: currentStateActionType === 'path' ? order:  0 // if path -> order : null
            })
          })).then(() => {
            // revalidate scenario
            revalidateAScenario(scenarioId)
          })
        })
      })

    }
    // console.log(connection, allEdges, allNodes)
  }

  const onDeleteEdges = (deletedEdges: Edge[], allEdges?: Edge[], nodes?: Node<State>[]) => {
    const deleted = deletedEdges.find((edge) => {
      const otherThanThisEdge = deletedEdges.filter(e => edge.id !== e.id)
      // find parent source ~ target === smua source yg lain
      if (otherThanThisEdge.length > 0) {
        if (edge.target === otherThanThisEdge[0].source) {
          return true
        } else {
          return false
        }
      }
      return true
    }) || deletedEdges[0]
    const source = nodes?.find(node => `${node.data.id}` === deleted.source)?.data
    const needUpdateOrder: { id: string, stateConditionId: number | null }[] = []
    const findNextEdge = (id: string, stateConditionId: number | null) => {
      needUpdateOrder.push({ id, stateConditionId })
      const currentNode = nodes?.find(node => node.id === id)?.data
      allEdges?.filter(edge => edge.source === id).forEach(edge => {
        findNextEdge(edge.target, currentNode?.action?.type === 'path' ? currentNode.id : stateConditionId)
      })
    }
    findNextEdge(deleted.target, null)
    // console.log('deleted', deletedEdges, 'update order', needUpdateOrder)
    if (source && deleted) {
      const { scenarioId, childrenIds, id } = source
      // update childrenIds deletedEdge.source (remove deletedEdge.target di state[deletedEdge.source].childrenIds)
      const newChildrenIds = childrenIds?.filter(childId => `${childId}` !== deleted.target)
      updateAScenarioState(scenarioId, id, {
        childrenIds: newChildrenIds?.length === 0 ? null : newChildrenIds
      }).then(() => {
        // update parentId deletedEdge.target to null
        updateAScenarioState(scenarioId, parseInt(deleted.target), {
          parentId: null,
          stateConditionId: null
        }).then(() => {
          // update order dari all next of deletedEdge.target to -1
          Promise.all(needUpdateOrder.map(async ({ id, stateConditionId }) => {
            return await updateAScenarioState(scenarioId, parseInt(id), {
              order: -1,
              stateConditionId,
              conditionGroupOrder: 0
            })
          })).then(() => {
            if (deletedEdges.length > 1) {
              const otherThanDeleted = deletedEdges.filter(edge => edge.id !== deleted.id)
              Promise.all(otherThanDeleted.map(async (current) => {
                await updateAScenarioState(scenarioId, parseInt(current.target), {
                  parentId: null,
                  stateConditionId: null
                })
              })).then(() => revalidateAScenario(scenarioId))
            } else {
              // revalidate scenario
              revalidateAScenario(scenarioId)
            }
          })
        })
      })
    }
    // pertanyaan?
    // 1. gimana nanti tampilan buat test, dan suggestionsnya, kn dihasil test ada number order nya tuuh -- abaikan ini dlu
    // 2. di edit page yg skrg, order -1 itu d tampilinnya gmn
  }

  const onDeleteNodes = (nodes: Node<State>[], _allEdges?: Edge[], _allNodes?: Node<State>[]) => {
    // archive state
    const deleted = nodes[0]
    archiveAScenarioState(deleted.data.scenarioId, deleted.data.id).then(() => {
      revalidateAScenario(deleted.data.scenarioId)
    })
  }

  return {
    onUpdateEdge,
    onAddEdge,
    onDeleteEdges,
    onDeleteNodes
  }
}