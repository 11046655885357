import { Layout, Space, Spin, Steps } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import useAllAuthentication from '../../../../../../hooks/application/authentication/useAllAuthentication'
import useAuthenticationSchema from '../../../../../../hooks/authenticationSchema/useAuthenticationSchema'
import PageHeader from '../../../../../components/PageHeader'
import { useApplicationVersionContext } from '../../../contexts/ApplicationVersionContextProvider'
import { baseApplicationRoute } from '../../../routes'
import { useSharedValues } from '../components/SharedValuesContextProvider'
import StepContent from '../components/StepContent'

const CreateAuthenticationSteps: FC = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const { applicationId, versionName } = useParams<{ applicationId: string, versionName: string }>()
  const [values, setValues] = useSharedValues()
  const { authenticationSchema, fetchingAuthenticationSchema } = useAuthenticationSchema(values?.authenticationSchemaId)
  const history = useHistory()
  const [version] = useApplicationVersionContext()
  const { authentications } = useAllAuthentication(version ? applicationId : undefined, values?.versionId || version?.id)
  const authentication = authentications?.[0]

  useEffect(() => {
    if (authentication) {
      setValues(authentication)
    }
  }, [authentication])

  const onChange = (current: number) => {
    if (values?.id) {
      setCurrentStep(current)
    }
  }

  useEffect(() => {
    if (!values?.authenticationSchemaId) {
      history.push(`${baseApplicationRoute}/${applicationId}/${versionName}/main/authentication`)
    }
  }, [values?.authenticationSchemaId])

  const { Step } = Steps

  if (values?.onAddFieldForm || values?.activeEditFieldForm) {
    return null
  }

  const onContinue = (idx: number) => {
    setCurrentStep(idx + 1)
  }

  return (
    <StyledStep>
      <PageHeader
        title={authenticationSchema?.name}
        description={authenticationSchema?.description}
      />
      <Space direction="vertical">
        {
          authenticationSchema?.steps ?
            <Steps size="small" current={currentStep} onChange={onChange}>
              {
                authenticationSchema.steps.filter(step => !step.fields.includes('{{examplePayload}}')).map(step => {
                  const { label } = step
                  return <Step title={label} key={label} />
                })
              }
            </Steps>
            :
            fetchingAuthenticationSchema ?
              <Spin spinning={fetchingAuthenticationSchema}>
                <div />
              </Spin>
              :<h5>Not Available Yet</h5>
        }
        {
          authenticationSchema?.steps?.filter(step => !step.fields.includes('{{examplePayload}}'))?.map((step, idx, steps) => {
            const { fields, info, label } = step
            return <StepContent info={info} isSaveOnContinue={steps[idx + 1]?.fields[0] === '{{test}}'} schemaFields={authenticationSchema.fields} fields={fields} key={label} onPrevious={idx > 0 ? () => setCurrentStep(idx - 1) : undefined} onContinue={() => onContinue(idx)} isActive={idx === currentStep} />
          })
        }
      </Space>
    </StyledStep>
  )
}

export default CreateAuthenticationSteps

const StyledStep = styled(Layout.Content)`
  .ant-steps-item-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-steps-icon {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-steps, .ant-card {
    background: #FFFFFF;
    /* Blue/1 */
    border: 1px solid #F0FBFF;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
  }

  .ant-space-vertical {
    width: 100%;
    padding: 16px 24px;
  }
`