import { Scenario } from 'db'
import { useState } from 'react'
import { TableState } from '../../types'
import { useRevalidateFetch } from '../useFetch'
import useDuplicateAScenario from './useDuplicateAScenario'

type DuplicateScenarios = {
  duplicatingScenarios: boolean,
  duplicateScenarios: (scenarios: Scenario[]) => Promise<boolean[]>
}

export default function useDuplicateScenarios(tableState: TableState<Scenario>): DuplicateScenarios {
  const { duplicateAScenario } = useDuplicateAScenario()
  const [duplicatingScenarios, setDuplicatingScenarios] = useState(false)
  const { revalidateScenarios } = useRevalidateFetch()

  const duplicateScenarios = (scenarios: Scenario[]) => {
    setDuplicatingScenarios(true)
    const duplicates = Promise.all(scenarios.map(async (scenario) => {
      const { id } = scenario
      return await duplicateAScenario(id).then(() => true)
    }))

    duplicates.then(() => {
      revalidateScenarios(tableState)
      setDuplicatingScenarios(false)
    })

    return duplicates
  }

  return {
    duplicatingScenarios,
    duplicateScenarios
  }
}