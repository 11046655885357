import { Action, Application, Trigger } from 'db'
import { ResponseTest } from 'src/hooks/scenario/state/useStateTest'
import { getFlatDataTest } from 'src/util/formatter'
import { useStateTestData } from '../../../../edit/StateTestDataContext'

type SaveTestData = {
  saveTestData: (testData: ResponseTest, params: {
    stateId: number,
    scenarioId: number,
    stateOrder: number,
    triggerActionName: string | undefined,
    type: Trigger['type'] | Action['type'] | undefined,
    stateName: string,
    application: Application | undefined
  }) => void
}

export default function useSaveTestData(): SaveTestData {
  const [, setStateTestData] = useStateTestData()

  const saveTestData = (testData: ResponseTest, params: {
    stateId: number,
    scenarioId: number,
    stateOrder: number,
    triggerActionName: string | undefined,
    type: Trigger['type'] | Action['type'] | undefined,
    stateName: string,
    application: Application | undefined
  }) => {
    const { scenarioId, stateId, stateOrder, type, triggerActionName, stateName, application } = params
    if (testData && scenarioId && stateId) {
      const rawTestData: Record<string, any> | (Record<string, any>)[] =  typeof testData.error === 'string' ? testData.data || testData : testData.error || testData.data || testData
      let triggerActionTestResult: Record<string, any>

      if (Array.isArray(rawTestData)) {
        triggerActionTestResult = { ...rawTestData[0] }
      } else {
        triggerActionTestResult = { ...rawTestData }
      }

      setStateTestData(prevState => {
        const prevStateWithTestData = prevState && prevState[`${stateId}`] ?
          prevState[`${stateId}`] :
          {
            id: stateId,
            order: stateOrder,
            triggerActionName: triggerActionName,
            stateName,
            application: application
          }

        return {
          ...prevState,
          [`${stateId}`]: {
            ...prevStateWithTestData,
            type: type,
            realTestData: {
              ...testData,
              success: !testData.error
            },
            testData: triggerActionTestResult,
            flatTestData: getFlatDataTest({ testData: triggerActionTestResult, stateOrder, stateId, application })
          }
        }
      })
    }
  }

  return {
    saveTestData
  }
}