import { AxiosPromise } from 'axios'
import { Error } from '../../../types'
import { useApi, useRevalidateFetch } from '../../useFetch'

export type ResponseTest = { success: boolean, status: number, data: any, error?: any }
type Result = {
  testState: (scenarioId: number, stateId: number) => AxiosPromise<ResponseTest>,
  resultDataTest?: any,
  testingState: boolean,
  errorTestState?: Error
}
export default function useStateTest(): Result {
  const { data, isValidating, call, error } = useApi<ResponseTest>()
  const { revalidateAScenarioState } = useRevalidateFetch()

  return {
    testState: (scenarioId, stateId) => {
      const test = call({
        url: `/scenario/${scenarioId}/state/${stateId}/test`,
        method: 'post'
      })
      test.then(() => revalidateAScenarioState(scenarioId, stateId))
      return test
    },
    resultDataTest: data,
    testingState: isValidating,
    errorTestState: error
  }

}