import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { Bundle, Scenario } from 'db'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr'
import { Implikasi } from '../service/Implikasi'
import { Error, TableState } from '../types'
import Notification from '../util/Notification'

export type ResponseInterface<Data> = {
  data?: Data,
  error?: Error,
  isValidating: boolean
}

const useFetch = <Data = any>(url: string | null, config?: AxiosRequestConfig & { hideNotifError?: boolean }, configInterface?: SWRConfiguration): ResponseInterface<Data> & { revalidateFetch: (key: string | null) => void } => {
  const { mutate } = useSWRConfig()
  const { data, error, isValidating } = useSWR<Data, any>(
    url,
    {
      fetcher: (url: string) => {
        return Implikasi(url, { method: 'get', ...config }).then(({ data }) => data)
      },
      ...configInterface
    }
  )

  useEffect(() => {
    if (error?.response?.status === 401) {
      return
    } else if (error) {
      // TODO notify sentry here before returning
      if (config?.hideNotifError) {
        return
      }
      return Notification.error({
        message: 'Something\'s wrong',
        description: error?.response?.data?.error
      })

    }
  }, [error])


  return {
    revalidateFetch: (key: string | null) => {
      mutate(key)
    },
    data, error: error && { status: error?.response?.status || 500, error: error?.response?.data?.error || 'Network error'  }, isValidating }
}

export default useFetch

type ResponseUseApi<Data> = ResponseInterface<Data> & {
  getUrlWithApplicationId: (url: string) => string,
  call: (config: AxiosRequestConfig & { hideNotifError?: boolean }) => AxiosPromise<Data>
}
export const useApi = <Data = any>(isUseAppId?: boolean, isLimited?: boolean): ResponseUseApi<Data> => {
  const [data, setData] = useState<Data>()
  const [error, setError] = useState<Error>()
  const [isValidating, setIsValidating] = useState(false)
  const history = useHistory()
  const { applicationId } = useParams<{ applicationId?: string }>()

  useEffect(() => {
    if (isUseAppId && !applicationId) {
      history.push('/application')
    }
  }, [applicationId])

  useEffect(() => {
    if (data && isLimited) {
      setTimeout(() => {
        setData(undefined)
      }, 100)
    }
  }, [data])

  const call = async (config: AxiosRequestConfig & { hideNotifError?: boolean }) => {
    setData(undefined)
    setError(undefined)
    try {
      setIsValidating(true)
      const fetch = await Implikasi(config)
      setIsValidating(false)
      if (fetch.status === 200 || fetch.status === 202) {
        setData(fetch.data)
      }
      return fetch
    } catch (e) {
      const { response } = e as any
      setError({ status: response?.status || 500, error: response?.data?.error || 'Network error'  })
      setIsValidating(false)
      if (response?.status === 401) {
        history.replace('/auth/login')
      }
      if (!config.hideNotifError) {
        Notification.error({
          message: 'Something\'s wrong',
          description: response?.data?.error || 'Network error'
        })
      }

      return response
    }
  }

  return { data, error, isValidating, call, getUrlWithApplicationId: (url) => `/${applicationId}/${url}` }
}

type RevalidateFetch = {
  revalidateScenarioNewestLogWebhooks: (scenarioId: number) => void,
  revalidateWebhookUrl: (scenarioId: number) => void,
  revalidatePricingPlan: (pricingPlanId: number) => void,
  revalidateStatusPayment: () => void,
  revalidateLogJob: (logJobId?: number | string) => void,
  revalidateBundlesByApplicationId: (applicationId: number) => void,
  revalidateAllVersion: (applicationId: number) => void,
  revalidateTrigger: (applicationId?: number | string, triggerId?: number | string, versionId?: number) => void,
  revalidateAllTrigger: (applicationId?: number | string, versionId?: number | null) => void,
  revalidateMyApplications: (userId?: number, params?: { [param: string]: string }) => void,
  revalidateApplication: (applicationId: number) => void,
  revalidateAllAuthentication: (applicationId?: number | string, versionId?: number | null) => void,
  revalidateAllAction: (applicationId?: number | string, versionId?: number | null) => void,
  revalidateAction: (applicationId?: number | string, actionId?: number | string, versionId?: number) => void,
  revalidateScenarios: (tableState?: TableState<Scenario>) => void,
  revalidateABundle: (bundleId: number) => void,
  revalidateAScenario: (scenarioId: number) => void,
  revalidateAScenarioState: (scenarioId: number, stateId: number) => void,
  revalidateBundles: (tableState?: TableState<Bundle>) => void
}

export function useRevalidateFetch(): RevalidateFetch {
  const { mutate } = useSWRConfig()

  return {
    revalidateScenarioNewestLogWebhooks: (scenarioId: number) => {
      mutate(`/logWebhooks?scenarioId=${scenarioId}&take=1&orderBy=logWebhook.createdAt:desc`)
    },
    revalidateWebhookUrl: (scenarioId: number) => {
      mutate(`/scenario/${scenarioId}/webhookUrl`)
    },
    revalidatePricingPlan: (pricingPlanId: number) => {
      mutate(`/pricingPlan/${pricingPlanId}`)
    },
    revalidateStatusPayment: () => {
      mutate('/payment/status')
    },
    revalidateLogJob: (logJobId?: number | string) => {
      mutate(`/logJob/${logJobId}?withDeleted=true&expands=scenario`)
    },
    revalidateBundlesByApplicationId: (applicationId: number) => {
      mutate(`/bundles?applicationId=${applicationId}`)
    },
    revalidateAllVersion: (applicationId: number) => {
      mutate(`/application/${applicationId}/versions`)
    },
    revalidateTrigger: (applicationId?: number | string, triggerId?: number | string, versionId?: number) => {
      const url = triggerId && applicationId ? `/application/${applicationId}/trigger/${triggerId}${versionId ? `?versionId=${versionId}` : ''}` : null
      mutate(url)
    },
    revalidateAllTrigger: (applicationId?: number | string, versionId?: number | null) => {
      const url = applicationId ? `/application/${applicationId}/triggers${versionId ? `?versionId=${versionId}` : ''}` : null
      mutate(url)
    },
    revalidateMyApplications: (userId?: number, params?: { [param: string]: string }) => {
      const url = userId ?
        `/applications?expands=version&createdById=${userId}${params ? Object.keys(params || []).reduce((res, param, i, arrKey) => {
          if (params[param]) {
            return res +`${param}=${params[param]}${i < arrKey.length - 1 ? '&' : ''}`
          }
          return res
        }, '&') : ''}` : null
      mutate(url)
    },
    revalidateApplication: (applicationId: number) => {
      mutate(`/application/${applicationId}?expands=version`)
    },
    revalidateAllAuthentication: (applicationId?: number | string, versionId?: number | null) => {
      mutate(applicationId ? `/application/${applicationId}/authentications${versionId ? `?versionId=${versionId}&expands=testHttpRequest,authHttpRequest,reqTokenHttpRequest,refreshTokenHttpRequest` : ''}` : null)
    },
    revalidateAllAction: (applicationId?: number | string, versionId?: number | null) => {
      const url = applicationId ? `/application/${applicationId}/actions${versionId ? `?versionId=${versionId}` : ''}` : null
      mutate(url)
    },
    revalidateAction: (applicationId?: number | string, actionId?: number | string, versionId?: number) => {
      const url = actionId && applicationId ? `/application/${applicationId}/action/${actionId}${versionId ? `?versionId=${versionId}` : ''}` : null
      mutate(url)
    },
    revalidateScenarios: (tableState?: TableState<Scenario>) => {
      mutate('/scenarios')
      mutate(`/scenarios-state--${tableState ? JSON.stringify(tableState) : ''}`)
      mutate(`/scenarios-state-withDeleted-${tableState ? JSON.stringify(tableState) : ''}`)
      mutate(`/scenarios---${tableState ? JSON.stringify(tableState) : ''}`)
      mutate('/scenarios?expands=state')
      // template scenarios
      mutate('publicScenarios-/scenario/templates')
      mutate(`publicScenarios${tableState ? JSON.stringify(tableState) : ''}-/scenario/templates`)
    },
    revalidateABundle: (bundleId: number) => mutate(`/bundle/${bundleId}`),
    revalidateAScenario: (scenarioId: number) => mutate(`/scenario/${scenarioId}`),
    revalidateAScenarioState: (scenarioId: number, stateId: number, expandTriggerOrAction = true) => {
      mutate(`/scenario/${scenarioId}/state/${stateId}${expandTriggerOrAction ? '?expands=trigger,action' : ''}`)
      mutate(`/scenario/${scenarioId}/state/${stateId}`)
    },
    revalidateBundles: (tableState?: TableState<Bundle>) => mutate(`/bundles${tableState ? JSON.stringify(tableState) : ''}`)
  }
}
