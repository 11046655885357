import { CheckSquareFilled, CloseSquareFilled } from '@ant-design/icons'
import { Progress, Typography } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useStatusPayment from '../../../../hooks/payment/useStatusPayment'
import { StyledSecondaryButton } from '../../../../pages/components/StyledComponents'
import { danger, success } from '../../../../util/colors'

const UserBillingPlanSider: FC = () => {
  const history = useHistory()
  const { maxScenario, maxTask, scenariosCount, totalUsage, plan } = useStatusPayment()
  const [error, setError] = useState<'task' | 'scenario'>()
  const tpdm = plan?.limiter?.max
  const isUsingPremiumApp = plan?.isUsingPremiumApps
  const isUsingConditionalPath = plan?.isMultipleStepsScenario

  useEffect(() => {
    if (maxScenario && scenariosCount && maxScenario !== 0 && scenariosCount >= maxScenario) {
      setError('scenario')
    // } else if (maxTask && totalUsage && totalUsage >= maxTask) {
    //   setError('task')
    } else {
      setError(undefined)
    }
  }, [maxScenario, maxTask, scenariosCount, totalUsage])
  const _onCheckPlan = () => {
    history.push('/app/pricing-plan')
  }
  return (
    <UserBillingPlanContainer>
      <UserBillingPlanHeader>
        <Typography.Title level={5} style={{ color: '#FFF' }}>Package</Typography.Title>
        {/* <Alert type="success" message={planName} style={{ padding: '2px 4px', fontSize: '10px', width: '100%' }} /> */}
      </UserBillingPlanHeader>

      <UserBillingPlanContent>
        {/* <StyledDivider />
        <UserUsageProgress isError={error === 'task'} title="Monthly Task" label="Monthly" usage={totalUsage || 0} limit={maxTask || 0} /> */}
        {/* <StyledDivider /> */}
        <UserUsageProgress isError={error === 'scenario'} title="Scenario" usage={scenariosCount || 0} limit={maxScenario !== 0 ? maxScenario || 0 : 'unlimited'} />
        <UserUsageProgress isError={false} title="TPDM" usage={0} limit={tpdm !== 0 ? tpdm || 0 : 'unlimited'} />
        <UserUsageProgress isError={false} title="Premium Apps" usage={0} limit={isUsingPremiumApp || false} />
        <UserUsageProgress isError={false} title="Conditional Path" usage={0} limit={isUsingConditionalPath || false} />
        {/* <StyledDivider /> */}
      </UserBillingPlanContent>

      {/* <UserBillingPlanFooter>
        <StyledButton onClick={onCheckPlan}>Check Plan</StyledButton>
      </UserBillingPlanFooter> */}
    </UserBillingPlanContainer>
  )
}

const UserUsageProgress: FC<{ isError?: boolean, title: string, label?: string, usage: number, limit: boolean | number | 'unlimited' }> = ({ isError, title, limit }) => {
  return (
    // limit === 'unlimited' ?
    <UserUsageProgressContainer isError={isError}>
      {/* <Typography.Text strong>{title}</Typography.Text> */}
      <UserUsageProgressHeader>
        <Typography.Text>{title}</Typography.Text>
        <Typography.Text>{typeof limit === 'boolean' ?
          limit ? <CheckSquareFilled style={{ color: success }} /> : <CloseSquareFilled style={{ color: danger }} />
          : limit}</Typography.Text>
      </UserUsageProgressHeader>
    </UserUsageProgressContainer>
  // :
  // <UserUsageProgressContainer isError={isError}>
  //   {/* <Typography.Text strong>{title}</Typography.Text> */}
  //   <UserUsageProgressHeader>
  //     <Typography.Text>{title}</Typography.Text>
  //     <Typography.Text>{limit}</Typography.Text>
  //   </UserUsageProgressHeader>
  //   {/* <CustomProgress isError={isError} percent={usage/limit*100} showInfo={false} size="small" /> */}
  // </UserUsageProgressContainer>
  )
}

const UserUsageProgressContainer = styled.div<{ isError?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${props => props.isError ? '#F7EAE9' : 'unset'};
  margin: ${props => props.isError ? '-8px' : 'unset'};
  padding: ${props => props.isError ? '8px' : 'unset'};
  .ant-typography {
    color: ${props => props.isError ? danger : '#FFF'};
  }

`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomProgress = styled(({ isError, ...nativeProps }) => <Progress {...nativeProps} />)<{ isError?: boolean }>`
  & .ant-progress-inner {
    background-color: #3E73E3;
  }

  & .ant-progress-bg {
    background-color: ${props => props.isError ? danger : '#FFF'};
  }
`

const UserUsageProgressHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const UserBillingPlanContainer = styled.div`
  margin: 0 12px 12px 12px;
  padding: 8px;
  background-color: #1C4190;
  border-radius: 4px;
  color: #FFF;

  font-size: .72rem;
`
const UserBillingPlanHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  /* margin-bottom: 12px; */
  min-width: 0;

  & .ant-typography {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`
// const StyledDivider = styled(Divider)`
//   background: #749BEB;
//   margin: 8px -8px;
//   width: calc(100% + 16px);
// `
const _StyledButton = styled(StyledSecondaryButton)`
  width: 100%;
`

const _UserBillingPlanFooter = styled.div`
  width: 100%;
`

const UserBillingPlanContent = styled.div``

export default UserBillingPlanSider