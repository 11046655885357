import { Divider, Steps } from 'antd'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { currentStepAtom } from './recoil/scenario'

const StepsEditor: FC = () => {
  const [current, setCurrent] = useRecoilState(currentStepAtom)
  return (
    <Container>
      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <Steps progressDot current={current} style={{ padding: '12px 16px', maxWidth: '600px', display: 'flex', margin: 'auto' }}>
        <Steps.Step onClick={() => setCurrent(0)} title={<StepTitle active={current === 0} >Set up Scenario</StepTitle>} />
        <Steps.Step onClick={() => setCurrent(1)} title={<StepTitle active={current === 1} >Fill in Information</StepTitle>} />
      </Steps>
    </Container>
  )
}

export default StepsEditor

const Container = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  /* padding: 24px 16px 16px; */
  background: #FFF;
  z-index: 1;

  .ant-steps-item-content {
    margin-top: 0;
  }
`

const StepTitle = styled.span<{ active: boolean }>`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ active }) => active ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)'};
  cursor: pointer;
`