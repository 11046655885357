import { FolderAddOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Divider, Input, Spin, Tabs, Tooltip } from 'antd'
import { Folder } from 'db'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import useCreateFolder from '../../../../../../hooks/folder/useCreateFolder'
import useFolders from '../../../../../../hooks/folder/useFolders'
import { StyledButton } from '../../../../../../pages/components/StyledComponents'
import { blue } from '../../../../../../util/colors'
import AnchorWrapper from '../../../../../AnchorWrapper'
import ScenarioAndFolderDragLayer from '../ScenarioAndFolderDragLayer'
import FolderTab from './FolderTab'

const Leftpane: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { folderId } = useParams<{ folderId?: string }>()
  const { createFolder, creatingFolder } = useCreateFolder()
  const { folders, fetchingFolders } = useFolders()
  const [filteredFolders, setFilteredFolders] = useState<Folder[]>([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (folders) {
      setFilteredFolders(folders.filter(folder => folder.name.includes(searchValue)))
    }
  }, [folders, searchValue])

  const navigateTab = (location: string) => {
    // console.log(location, activeKey)
    return history.push(location.replace('/:folderId', '').replace('/job/:id?', ''))
  }

  const activeKey = history.location.pathname.includes('/accounts') ? '/accounts' :
    history.location.pathname.includes('history') ? '/history' : folderId ? `/${folderId}` : ''

  const onClickCreateFolder = (e?: any) => {
    e?.stopPropagation()
    createFolder({
      displayOrder: (folders?.length || 0) + 1
    })
  }

  return (
    <ContainerTabs>
      <ScenarioAndFolderDragLayer />
      <Input.Search allowClear placeholder="Search Folder" onChange={(e) => setSearchValue(e.target.value)} />
      <StyledButton className="text-left pl-5" onClick={onClickCreateFolder} icon={<FolderAddOutlined />} type="link">Add New Folder</StyledButton>
      <ContainerFolders>
        <Spin spinning={fetchingFolders || creatingFolder}>
          <StyledMenu>
            <FolderTab
              id={0}
              name="All"
              index={0}
            />
            {
              filteredFolders?.sort((f1, f2) => (f1.displayOrder || 0) - (f2.displayOrder || 0))
                .map((folder, idx) => {
                  return <FolderTab
                    index={idx + 1}
                    key={folder.id}
                    id={folder.id}
                    name={folder.name}
                  />
                })
            }
          </StyledMenu>
        </Spin>
        <Tooltip overlay={'Add new Folder'} placement="bottom">
          <div onClick={onClickCreateFolder} style={{ height: 'max-content' }}>
            <StyledDivider>
              <PlusCircleFilled style={{ color: blue[4] }} />
            </StyledDivider>
          </div>

        </Tooltip>
      </ContainerFolders>
      <CustomTabs tabPosition={'left'} onChange={(newKey: string) => navigateTab(`${path.replace(activeKey, '')}${newKey}`)} activeKey={activeKey}>
        <Tabs.TabPane key="/accounts" tab={<AnchorWrapper href="/app/scenario/all/accounts" >Connected Accounts</AnchorWrapper>} />
        <Tabs.TabPane key="/history" tab={<AnchorWrapper href="/app/scenario/all/history" >Job History</AnchorWrapper>} />
      </CustomTabs>
    </ContainerTabs>
  )
}

export default Leftpane



const StyledMenu = styled.div`
  /* max-height: 150px; */
  overflow-y: unset;
  border: 0;

  .ant-menu-item {
    max-width: 200px;
    text-overflow: unset;
    overflow: unset;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    &.ant-menu-item-active {
      background: #F5F5F5;
      color: rgba(0, 0, 0, 0.85);
      border-bottom: 1px solid ${blue[4]};
    }
    &:hover {
      &:after {
        opacity: 0;
      }
    }
    &.ant-menu-item-selected {
      background: #F0FBFF;
      :after {
        opacity: 1;
        transform: scaleY(1);
      }
    }
  } 
`

const StyledDivider = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text {
    margin: 12px 0;  
  }
  margin: 16px 0;
  cursor: pointer;
  position: relative;
  .ant-divider-inner-text {
    padding: 0;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    display: none;
  }
  &:hover {
    border-color: ${blue[4]};
    .ant-divider-inner-text {
      display: block;
    }
  }
  &:active {
    transform: scaleY(1.2);
  }
`
const CustomTabs = styled(Tabs)`
  width: 200px;
  padding-bottom: 40px;
  .ant-tabs-nav {
    width: 100%;
    max-height: 150px;
    .ant-tabs-nav-operations {
      display: none;
    }
  }
  & .ant-tabs-tab {
    font-size: 14px !important;
    font-weight: normal !important;
    &.ant-tabs-tab-active {
      background: #F0FBFF;
    }
    margin-top: 0 !important;
  }
  .ant-tabs-content-holder {
    display: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`
const ContainerTabs = styled.div`
  background: white;
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
  overflow-y: hidden;

  .ant-input-wrapper {
    width: 200px;
    padding: 16px;
    .ant-input-affix-wrapper {
      height: 32px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`
const ContainerFolders = styled.div`
  overflow-y: hidden;
  display: grid;
  grid-template-rows: auto max-content;

  .ant-spin-nested-loading {
    overflow-y: auto;
    .ant-spin-container {
      height: 100%;
    }
  }
`