import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Collapse, Form, Input, Radio, Select, Space, Switch } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { FormListProps } from 'antd/lib/form'
import { Trigger, User } from 'db'
import { FC } from 'react'
import styled from 'styled-components'
import { StyledButton, StyledCheckbox, StyledLabel, StyledTag } from '../../../../../pages/components/StyledComponents'
import FormItem from '../../../../../pages/developer/components/form/FormItem'
import MonacoEditor from '../../../../../pages/developer/components/form/MonacoEditor'
import MarkdownRenderer from '../../../../MarkdownRenderer'
import RichKeyValueInput from '../../../app/scenario/edit/RichKeyValueInput'

interface Props extends Pick<FormListProps, 'name'> {
  user?: User,
  hiddenTriggers?: Trigger[]
}

const FormListFieldSchema: FC<Props> = ({ name, user, hiddenTriggers }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        const nameFormList = name as string
        return (
          <>
            <Collapse>
              {fields.map((field, idx) =>
                <StyledCollapsePanel
                  key={field.key}
                  header={
                    <FormItem style={{ display: 'flex', height: 'max-content', margin: 0 }} shouldUpdate={(prev, next) => prev[nameFormList][idx]?.label !== next[nameFormList][idx]?.label}>
                      {({ getFieldValue }) => {
                        const label  = getFieldValue([nameFormList, field.name, 'label'])
                        return label || `field ${idx + 1}`
                      }}
                    </FormItem>
                  }
                  extra={[
                    <DeleteOutlined key={1} disabled={fields.length === 1} onClick={() => fields.length !== 1 && remove(idx)} />
                  ]}
                >
                  <FormItem label="Key"
                    {...field}
                    name={[field.name, 'key']}
                    fieldKey={[field.fieldKey, 'key']}
                    description="Enter the word or phrase your API uses to reference this field or parameter. Not seen by users."
                    rules={[{ required: true },
                      ({ getFieldValue }: any) => ({
                        async validator(fields: any, value: any) {
                          const name = fields.field.replace(`.${idx}.key`, '').split('.')
                          const allKey = getFieldValue(name)?.map((v: any) => v?.key) || []
                          const isUnique = allKey.filter((v: string) => v === value).length === 1

                          if (value === '_value' || value === '_useArrayFromPrevAction') {
                            return Promise.reject(new Error(`Key cant be ${value}`))
                          }
                          if (isUnique) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error('Key must unique'))
                        },
                      }),
                    ]} extra={<Space>
                      <p>Example:</p>
                      <StyledTag>first_name</StyledTag>
                      <StyledTag>nama_depan</StyledTag>
                    </Space>} >
                    <Input />
                  </FormItem>
                  <FormItem label="Label" name={[field.name, 'label']}
                    fieldKey={[field.fieldKey, 'label']} description="Enter the field's friendly name for users." rules={[{ required: true }]} extra={<Space>
                      <p>Example:</p>
                      <StyledTag>First Name</StyledTag>
                      <StyledTag>Nama Depan</StyledTag>
                    </Space>}>
                    <Input />
                  </FormItem>
                  <FormItem label="Help Text" name={[field.name, 'helpText']}
                    fieldKey={[field.fieldKey, 'helpText']} description="Explain to users what to include in this field, especially for API keys and other hard to find info. Include directions to find the data and links to your app settings or help docs." >
                    <MonacoEditor defaultLanguage="markdown" height="150px" />
                  </FormItem>
                  <Form.Item shouldUpdate={(prev, next) => {
                    return prev[nameFormList][idx]?.helpText !== next[nameFormList][idx]?.helpText
                  }}>
                    {({ getFieldValue }) => {
                      const helpText = getFieldValue([nameFormList, field.name, 'helpText'])
                      if (helpText) {
                        return (
                          <>
                            Preview Help Text:
                            <StyledMarkdown>{helpText}</StyledMarkdown>
                          </>
                        )
                      }
                      return null
                    }}
                  </Form.Item>
                  <FormItem label="Type" name={[field.name, 'type']}
                    fieldKey={[field.fieldKey, 'type']} description="Select the field type. Use String (default) for most text input, or Password to obscure text for secret values." >
                    <Select>
                      <Select.Option value="string" >String</Select.Option>
                      <Select.Option value="password" >Password</Select.Option>
                      <Select.Option value="json" >JSON</Select.Option>
                      <Select.Option value="key-value" >{'<Key, Value>'} Pair</Select.Option>
                      {
                        user?.isAdmin ?
                          <>
                            <Select.Option value="code" >Code</Select.Option>
                          </>
                          : null
                      }
                      <Select.Option value="image" >Image URL</Select.Option>
                    </Select>
                  </FormItem>
                  <Form.Item shouldUpdate={(prev, next) => prev[nameFormList][idx]?.type !== next[nameFormList][idx]?.type}>
                    {({ getFieldValue }) => {
                      console.log(getFieldValue(name), getFieldValue([nameFormList, field.name, 'type']))
                      const type = getFieldValue([nameFormList, field.name, 'type'])
                      if (type === 'key-value') {
                        return (
                          <RichKeyValueInput name={[field.name, 'default']}
                            label="Default Value" description="Include a default value for this field as a fallback. For optional fields, the default value is set on initial creation and used instead of missing or null values every time the Connectioon runs. For required fields, this value is used during Connection creation, but not when the Connection runs (Otomatis raises an error for missing/null values instead)." />
                        )
                      }
                      return (
                        <FormItem label="Default Value" name={[field.name, 'default']}
                          fieldKey={[field.fieldKey, 'default']} description="Include a default value for this field as a fallback. For optional fields, the default value is set on initial creation and used instead of missing or null values every time the Connectioon runs. For required fields, this value is used during Connection creation, but not when the Connection runs (Otomatis raises an error for missing/null values instead)." >
                          {
                            type === 'code' ?
                              <MonacoEditor defaultLanguage="javascript" /> :
                              <Input />
                          }
                        </FormItem>
                      )
                    }}
                  </Form.Item>
                  <FormItem label="Computed Value" name={[field.name, 'getValueFunc']}
                    fieldKey={[field.fieldKey, 'getValueFunc']} description="Get value from others input" >
                    <MonacoEditor theme="vs-dark" defaultLanguage='javascript' />
                  </FormItem>
                  <FormItem name={[field.name, 'allowEditByUser']}
                    fieldKey={[field.fieldKey, 'allowEditByUser']} valuePropName="checked" label="Show Computed Value" description="Enable to make computed value result editable">
                    <Switch />
                  </FormItem>
                  <FormItem label="Constant Value" name={[field.name, 'constantValue']}
                    fieldKey={[field.fieldKey, 'constantValue']} description="Include a constant value for this field as a fallback. User will cannot change the value of this field." >
                    <Input />
                  </FormItem>
                  <StyledLabel>Options</StyledLabel>
                  <FormItem name={[field.name, 'required']}
                    fieldKey={[field.fieldKey, 'required']} valuePropName="checked" style={{ marginBottom: 0 }} >
                    <Checkbox>Required</Checkbox>
                  </FormItem>
                  <FormItem name={[field.name, 'isDropdown']}
                    fieldKey={[field.fieldKey, 'isDropdown']} valuePropName="checked" style={{ marginBottom: 0 }} >
                    <StyledCheckbox>Dropdown</StyledCheckbox>
                  </FormItem>
                  <Form.Item shouldUpdate={(prev, next) => {
                    return prev[nameFormList][idx]?.isDropdown !== next[nameFormList][idx]?.isDropdown
                  }}>
                    {
                      ({ getFieldValue }) => {
                        const useDropdown = getFieldValue([nameFormList, field.name, 'isDropdown'])
                        if (useDropdown) {
                          return <FormItem label="Dropdown Type" name={[field.name, 'dropdownType']}
                            fieldKey={[field.fieldKey, 'dropdownType']} rules={[{ required: true }]}>
                            <Radio.Group>
                              <Radio value={'static'}>Static</Radio>
                              <Radio value={'dynamic'}>Dynamic</Radio>
                            </Radio.Group>
                          </FormItem>
                        }
                      }
                    }
                  </Form.Item>
                  <Form.Item shouldUpdate={(prev, next) => prev[nameFormList][idx]?.dropdownType !== next[nameFormList][idx]?.dropdownType}>
                    {
                      ({ getFieldValue }) => {
                        const useDropdown = getFieldValue([nameFormList, field.name, 'isDropdown'])
                        const dropdownType = getFieldValue([nameFormList, field.name, 'dropdownType'])
                        if (useDropdown && dropdownType === 'static') {
                          return <FormItem label="Static Dropdown" rules={[{ required: true }]}
                            description="List the dropdown choices."
                            extra={<>Examples: ["username", "password"] or {'[{ "key": "userId", "label": "User ID" }]'}</>}
                            name={[field.name, 'stringOptions']}
                            fieldKey={[field.fieldKey, 'stringOptions']}
                          >
                            <MonacoEditor height="100px" theme="vs-dark" />
                          </FormItem>
                        } else if (useDropdown && dropdownType === 'dynamic') {
                          return (
                            <FormItem
                              name={[field.name, 'triggerId']}
                              fieldKey={[field.fieldKey, 'triggerId']}
                              label="Dropdown Source" rules={[{ required: true, message: 'Trigger is required' }]}>
                              <Select
                                filterOption={(input, option) =>
                                  option?.title?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {hiddenTriggers?.map(trigger => <Select.Option key={trigger.id} title={trigger.name} value={trigger.id}>{trigger.name}</Select.Option>)}
                              </Select>
                            </FormItem>
                          )
                        }
                        return null
                      }
                    }
                  </Form.Item>
                </StyledCollapsePanel>
              )}
            </Collapse>
            <FormItem>
              <StyledButton type="dashed" onClick={() => add({ type: 'string' })} block icon={<PlusOutlined />}>
                Add Item Field
              </StyledButton>
            </FormItem>
          </>
        )
      }
      }
    </Form.List>
  )
}

export default FormListFieldSchema

const StyledMarkdown = styled(MarkdownRenderer)`
  height: 200px;
  overflow-y: auto;
`
const StyledCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    display: grid;
    grid-template-columns: max-content max-content auto max-content;
    align-items: center;
  }
`